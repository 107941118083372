import actions from './actions';
const initState = {
  idToken: null,
  error: false,
  errorInfo: '',
  redirectToReferrer: false,
  loginRequestSuccessful: false,
  signupStatus: null
};

export default function authReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.LOGIN_REQUEST:
      return {
        ...state,
        redirectToReferrer: true,
        loginRequestSuccessful: false
      };
    case actions.LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        redirectToReferrer: false,
        idToken: action.idToken,
        loginRequestSuccessful: true
      };
    case actions.PASSWORD_RESET_LINK:
      return {
        ...state,
        redirectToReferrer: true,
      };
    case actions.PASSWORD_RESET_LINK_SUCCESSFULLY_SENT:
      return {
        ...state,
        redirectToReferrer: false,
      };
    case actions.FAILED_REQUEST:
      return {
        ...state,
        error: true,
        errorInfo: action.errorInfo,
        redirectToReferrer: false
      };
    case actions.SIGNUP_REQUEST:
      return {
        ...state,
        loginRequestSuccessful: false
      };
    case actions.SIGNUP_REQUEST_SUCCESS:
      return {
        ...state,
        loginRequestSuccessful: true,
        signupStatus: action.signupStatus
      };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
