import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Spin } from "antd";
import componentLoader from "../../componentLoader";

const childRoutes = [
  {
    path: "timeline",
    component: React.lazy(() =>
      componentLoader(() => import("../LandingPage/parentLandingPage"), 3)
    ),
  },
  {
    path: "godMode",
    component: React.lazy(() =>
      componentLoader(() => import("../Page/godMode"), 3)
    ),
  },
  {
    path: "teacherMode",
    component: React.lazy(() =>
      componentLoader(() => import("../LandingPage/parentModeSwitchPage"), 3)
    ),
  },
  {
    path: "studentLesson",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentLessonView"), 3)
    ),
  },
  {
    path: "assignmentNotification",
    component: React.lazy(() =>
      componentLoader(
        () => import("../StudentLessonView/assignmentNotification"),
        3
      )
    ),
  },
  {
    path: "comments",
    component: React.lazy(() =>
      componentLoader(() => import("../Comment/parentComment"), 3)
    ),
  },
  {
    path: "submissionForm",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentLessonView/submissionForm"), 3)
    ),
  },
  {
    path: "studentSubmission",
    component: React.lazy(() =>
      componentLoader(
        () => import("../StudentLessonView/studentActivityView"),
        3
      )
    ),
  },
  {
    path: "notifications",
    component: React.lazy(() =>
      componentLoader(() => import("../Notifications"), 3)
    ),
  },
  {
    path: "singleActivity",
    component: React.lazy(() =>
      componentLoader(
        () => import("../Notifications/StudentNotificationActivityView"),
        3
      )
    ),
  },
  {
    path: "virtualClassManagement",
    component: React.lazy(() =>
      componentLoader(() => import("../OnlineClassManagement"), 3)
    ),
  },
  {
    path: "classManagement",
    component: React.lazy(() =>
      componentLoader(() => import("../OnlineClassManagement/manageClass"), 3)
    ),
  },
  {
    path: "virtualClass",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentVirtualClass"), 3)
    ),
  },
  {
    path: "schoolMessages",
    component: React.lazy(() =>
      componentLoader(() => import("../ParentMessage"), 3)
    ),
  },
  {
    path: "pdfViewer",
    component: React.lazy(() =>
      componentLoader(() => import("../../components/CustomPdfViewer"), 3)
    ),
  },
  {
    path: "customlearningplan",
    component: React.lazy(() =>
      componentLoader(() => import("../CustomLearningPlan"), 3)
    ),
  },
  {
    path: "studentInvoices",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentOnlineClassInvoice"), 3)
    ),
  },
  {
    path: "stripePayment",
    component: React.lazy(() =>
      componentLoader(() => import("../StripePaymentMethod"), 3)
    ),
  },
  {
    path: "enrollmentDetail",
    component: React.lazy(() =>
      componentLoader(() => import("../EnrollmentDetail"), 3)
    ),
  },
  {
    path: "InvoiceAdditionalDetail",
    component: React.lazy(() =>
      componentLoader(
        () => import("../StudentOnlineClassInvoice/invoiceAdditionalDetail"),
        3
      )
    ),
  },
  {
    path: "studentProfile",
    component: React.lazy(() =>
      componentLoader(() => import("../Student/studentDetailedView"), 3)
    ),
  },
  {
    path: "schedulePlaydate",
    component: React.lazy(() =>
      componentLoader(() => import("../SchedulePlayDate"), 3)
    ),
  },
  {
    path: "contactUs",
    component: React.lazy(() =>
      componentLoader(() => import("../ContactUs"), 3)
    ),
  },
  {
    path: "virtualLibrary",
    component: React.lazy(() =>
      componentLoader(() => import("../VirtualLibrary"), 3)
    ),
  },
  {
    path: "singleLesson",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentLessonView/singleLesson"), 3)
    ),
  },
];

const routes = [
  {
    path: "",
    component: React.lazy(() =>
      componentLoader(() => import("../LandingPage"), 3)
    ),
  },
  // {
  //   path: "multiCenterDashboard",
  //   component: React.lazy(() =>
  //     componentLoader(() => import("../MultiCenterDashboard"), 3)
  //   ),
  // },
  {
    path: "scheduleDemo",
    component: React.lazy(() =>
      componentLoader(() => import("../DemoWidget"), 3)
    ),
  },
  {
    path: "godMode",
    component: React.lazy(() =>
      componentLoader(() => import("../Page/godMode"), 3)
    ),
  },
  {
    path: "activities",
    component: React.lazy(() =>
      componentLoader(() => import("../Activities"), 3)
    ),
  },
  {
    path: "studentLevelView",
    component: React.lazy(() =>
      componentLoader(() => import("../Activities/studentLevelActivity"), 3)
    ),
  },
  {
    path: "newActivity",
    component: React.lazy(() =>
      componentLoader(() => import("../Activities/newActivity"), 3)
    ),
  },
  {
    path: "studentAttendance",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentAttendance"), 3)
    ),
  },
  {
    path: "staffAttendance",
    component: React.lazy(() =>
      componentLoader(() => import("../StaffAttendance"), 3)
    ),
  },
  {
    path: "students",
    component: React.lazy(() => componentLoader(() => import("../Student"), 3)),
  },
  {
    path: "studentDetailedView",
    component: React.lazy(() =>
      componentLoader(() => import("../Student/studentDetailedView"), 3)
    ),
  },
  {
    path: "rooms",
    component: React.lazy(() =>
      componentLoader(() => import("../Classroom"), 3)
    ),
  },
  {
    path: "staff",
    component: React.lazy(() => componentLoader(() => import("../Teacher"), 3)),
  },
  {
    path: "teacherDetailedView",
    component: React.lazy(() =>
      componentLoader(() => import("../Teacher/teacherDetailedView"), 3)
    ),
  },
  {
    path: "tags",
    component: React.lazy(() => componentLoader(() => import("../Tag"), 3)),
  },
  {
    path: "lessons",
    component: React.lazy(() => componentLoader(() => import("../Lesson"), 3)),
  },
  {
    path: "lessonPlans",
    component: React.lazy(() =>
      componentLoader(() => import("../WeeklyPlan"), 3)
    ),
  },
  {
    path: "lessonView",
    component: React.lazy(() =>
      componentLoader(() => import("../Lesson/viewLesson"), 3)
    ),
  },
  {
    path: "assessments",
    component: React.lazy(() =>
      componentLoader(() => import("../Assessment"), 3)
    ),
  },
  {
    path: "milestones",
    component: React.lazy(() =>
      componentLoader(() => import("../Milestone"), 3)
    ),
  },
  {
    path: "selectedStudentAssessment",
    component: React.lazy(() =>
      componentLoader(
        () => import("../Assessment/selectedStudentAssessment"),
        3
      )
    ),
  },
  {
    path: "savedAssessments",
    component: React.lazy(() =>
      componentLoader(() => import("../Assessment/savedAssessmentView"), 3)
    ),
  },
  {
    path: "program",
    component: React.lazy(() => componentLoader(() => import("../Program"), 3)),
  },
  {
    path: "feeStructure",
    component: React.lazy(() => componentLoader(() => import("../Fee"), 3)),
  },
  {
    path: "detailedFeePlan/:id",
    component: React.lazy(() =>
      componentLoader(() => import("../DetailedFeePlan"), 3)
    ),
  },
  {
    //path: "detailedProgram/:id",
    path: "detailedProgram",
    component: React.lazy(() =>
      componentLoader(() => import("../DetailedProgram"), 3)
    ),
  },
  {
    path: "onboarding",
    component: React.lazy(() =>
      componentLoader(() => import("../Onboarding"), 3)
    ),
  },
  {
    path: "setting",
    component: React.lazy(() =>
      componentLoader(() => import("../UserSetting"), 3)
    ),
  },
  {
    path: "invoice",
    component: React.lazy(() => componentLoader(() => import("../Invoice"), 3)),
  },
  {
    path: "studentBilling",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentBilling"), 3)
    ),
  },
  {
    path: "studentInvoice",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentBilling/studentInvoices"), 3)
    ),
  },
  {
    path: "leads",
    component: React.lazy(() => componentLoader(() => import("../Leads"), 3)),
  },
  {
    path: "leadsDetailedView",
    component: React.lazy(() =>
      componentLoader(() => import("../LeadsDetailedView"), 3)
    ),
  },
  {
    path: "document",
    component: React.lazy(() =>
      componentLoader(() => import("../TeacherDocument"), 3)
    ),
  },
  {
    path: "draftNewsLetter",
    component: React.lazy(() =>
      componentLoader(() => import("../Newsletter"), 3)
    ),
  },
  {
    path: "sentNewsletter",
    component: React.lazy(() =>
      componentLoader(() => import("../Newsletter/Sent"), 3)
    ),
  },
  {
    path: "invoiceTemplate",
    component: React.lazy(() =>
      componentLoader(() => import("../InvoiceTemplate"), 3)
    ),
  },
  {
    path: "connect",
    component: React.lazy(() => componentLoader(() => import("../Connect"), 3)),
  },
  {
    path: "complains",
    component: React.lazy(() =>
      componentLoader(() => import("../Complains"), 3)
    ),
  },
  {
    path: "leaves",
    component: React.lazy(() => componentLoader(() => import("../Leaves"), 3)),
  },
  {
    path: "staffLeaves",
    component: React.lazy(() =>
      componentLoader(() => import("../StaffLeaves"), 3)
    ),
  },
  {
    path: "staffActivities",
    component: React.lazy(() =>
      componentLoader(() => import("../Activities/staffActivities"), 3)
    ),
  },
  {
    path: "staffMessages",
    component: React.lazy(() =>
      componentLoader(() => import("../StaffQueryList"), 3)
    ),
  },
  {
    path: "parentNotes",
    component: React.lazy(() =>
      componentLoader(() => import("../ParentNotes"), 3)
    ),
  },
  {
    path: "holiday",
    component: React.lazy(() => componentLoader(() => import("../Holiday"), 3)),
  },
  {
    path: "events",
    component: React.lazy(() => componentLoader(() => import("../Events"), 3)),
  },
  {
    path: "schoolDetails",
    component: React.lazy(() =>
      componentLoader(() => import("../SchoolDetails"), 3)
    ),
  },
  {
    path: "schedule",
    component: React.lazy(() =>
      componentLoader(() => import("../Schedule"), 3)
    ),
  },
  {
    path: "zoomConfiguration",
    component: React.lazy(() =>
      componentLoader(() => import("../Activities/zoomConfigurationPage"), 3)
    ),
  },
  {
    path: "zoomPlayer",
    component: React.lazy(() =>
      componentLoader(() => import("../Activities/zoomPlay"), 3)
    ),
  },
  {
    path: "parentMode",
    component: React.lazy(() =>
      componentLoader(() => import("../LandingPage/parentModeSwitchPage"), 3)
    ),
  },
  {
    path: "virtualClasses",
    component: React.lazy(() =>
      componentLoader(() => import("../VirtualClass"), 3)
    ),
  },
  {
    path: "assignments",
    component: React.lazy(() =>
      componentLoader(() => import("../LessonAssignment/allAssignment"), 3)
    ),
  },
  {
    path: "submissionStats",
    component: React.lazy(() =>
      componentLoader(() => import("../LessonAssignment/allSubmissionStats"), 3)
    ),
  },
  {
    path: "assignmentList",
    component: React.lazy(() =>
      componentLoader(() => import("../LessonAssignment/allAssignment"), 3)
    ),
  },
  {
    path: "submission",
    component: React.lazy(() =>
      componentLoader(() => import("../LessonAssignment/submission"), 3)
    ),
  },
  {
    path: "submissionList",
    component: React.lazy(() =>
      componentLoader(() => import("../LessonAssignment/allSubmission"), 3)
    ),
  },
  {
    path: "weeklyPlanSubmission",
    component: React.lazy(() =>
      componentLoader(
        () => import("../LessonAssignment/weeklyPlanSubmission"),
        3
      )
    ),
  },
  {
    path: "comments",
    component: React.lazy(() => componentLoader(() => import("../Comment"), 3)),
  },
  {
    path: "installations",
    component: React.lazy(() =>
      componentLoader(() => import("../Installations"), 3)
    ),
  },
  {
    path: "minorSettings",
    component: React.lazy(() =>
      componentLoader(() => import("../MinorSettings"), 3)
    ),
  },
  {
    path: "shareHistory",
    component: React.lazy(() =>
      componentLoader(() => import("../Lesson/lessonShareHistory"), 3)
    ),
  },
  {
    path: "queryList",
    component: React.lazy(() =>
      componentLoader(() => import("../QueryList"), 3)
    ),
  },
  {
    path: "singleChat",
    component: React.lazy(() =>
      componentLoader(() => import("../QueryList/singleChat"), 3)
    ),
  },
  {
    path: "enableActivity",
    component: React.lazy(() =>
      componentLoader(() => import("../MinorSettings/enableActivity"), 3)
    ),
  },
  {
    path: "permissions",
    component: React.lazy(() =>
      componentLoader(() => import("../Permissions"), 3)
    ),
  },
  {
    path: "notifications",
    component: React.lazy(() =>
      componentLoader(() => import("../Notifications"), 3)
    ),
  },
  {
    path: "singleActivity",
    component: React.lazy(() =>
      componentLoader(
        () => import("../Notifications/NotificationActivityView"),
        3
      )
    ),
  },
  {
    path: "singleConcern",
    component: React.lazy(() =>
      componentLoader(() => import("../Notifications/NotificationComplaint"), 3)
    ),
  },
  {
    path: "singleLeave",
    component: React.lazy(() =>
      componentLoader(() => import("../Notifications/NotificationLeave"), 3)
    ),
  },
  {
    path: "singleNote",
    component: React.lazy(() =>
      componentLoader(() => import("../Notifications/NotificationNote"), 3)
    ),
  },
  {
    path: "singleEvent",
    component: React.lazy(() =>
      componentLoader(() => import("../Notifications/NotificationEvent"), 3)
    ),
  },
  {
    path: "notificationDoc",
    component: React.lazy(() =>
      componentLoader(() => import("../Notifications/NotificationDocument"), 3)
    ),
  },
  {
    path: "creditHistory",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentBilling/creditHistory"), 3)
    ),
  },
  {
    path: "help",
    component: React.lazy(() =>
      componentLoader(() => import("../HelpBlog"), 3)
    ),
  },
  {
    path: "studentActivities",
    component: React.lazy(() =>
      componentLoader(
        () => import("../Activities/individualStudentActivity"),
        3
      )
    ),
  },
  {
    path: "totalCreditHistory",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentBilling/allCreditHistory"), 3)
    ),
  },
  {
    path: "pdfViewer",
    component: React.lazy(() =>
      componentLoader(() => import("../../components/CustomPdfViewer"), 3)
    ),
  },
  {
    path: "bookingReport",
    component: React.lazy(() =>
      componentLoader(() => import("../BookingReport"), 3)
    ),
  },
  {
    path: "InvoiceAdditionalDetails",
    component: React.lazy(() =>
      componentLoader(
        () => import("../StudentOnlineClassInvoice/invoiceAdditionalDetail"),
        3
      )
    ),
  },
  {
    path: "StripePaymentMethod",
    component: React.lazy(() =>
      componentLoader(
        () => import("../StripePaymentMethod/schoolSidePayment"),
        3
      )
    ),
  },
  {
    path: "meetingReport",
    component: React.lazy(() =>
      componentLoader(() => import("../BookingReport/meetingReport"), 3)
    ),
  },
  {
    path: "studentSubmissionStats",
    component: React.lazy(() =>
      componentLoader(() => import("../LessonAssignment/allSubmissionStats"), 3)
    ),
  },
  {
    path: "meetingSummary",
    component: React.lazy(() =>
      componentLoader(() => import("../BookingReport/activeStudentReport"), 3)
    ),
  },
  {
    path: "contactUs",
    component: React.lazy(() =>
      componentLoader(() => import("../ContactUs"), 3)
    ),
  },
  {
    path: "virtualLibrary",
    component: React.lazy(() =>
      componentLoader(() => import("../VirtualLibrary"), 3)
    ),
  },
  {
    path: "customLabels",
    component: React.lazy(() =>
      componentLoader(() => import("../CustomLabels"), 3)
    ),
  },
  {
    path: "support",
    component: React.lazy(() => componentLoader(() => import("../Support"), 3)),
  },
  {
    path: "singleLesson",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentLessonView/singleLesson"), 3)
    ),
  },
  {
    path: "reports",
    component: React.lazy(() => componentLoader(() => import("../Reports"), 3)),
  },
];

class AppRouter extends Component {
  render() {
    const { url, style, firebase } = this.props;
    if (
      firebase &&
      firebase.selectedMode &&
      firebase.selectedMode.toLowerCase() === "parent" &&
      firebase.user.studentId
    ) {
      return (
        <div style={style}>
          {childRoutes.map((singleRoute) => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
              <React.Suspense
                key={singleRoute.path}
                fallback={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      height: window.innerHeight / 2,
                    }}
                  >
                    <Spin />
                  </div>
                }
              >
                <Route
                  exact={exact === false ? false : true}
                  key={singleRoute.path}
                  path={`${url}/${singleRoute.path}`}
                  {...otherProps}
                />
              </React.Suspense>
            );
          })}
        </div>
      );
    } else {
      return (
        <div style={style}>
          {routes.map((singleRoute) => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
              <React.Suspense
                key={singleRoute.path}
                fallback={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      height: window.innerHeight / 2,
                    }}
                  >
                    <Spin />
                  </div>
                }
              >
                <Route
                  exact={exact === false ? false : true}
                  key={singleRoute.path}
                  path={`${url}/${singleRoute.path}`}
                  {...otherProps}
                />
              </React.Suspense>
            );
          })}
        </div>
      );
    }
  }
}

export default AppRouter;
