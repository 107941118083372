import "@firebase/firestore"; // 👈 If you're using firestore
import { eventChannel } from "redux-saga";
import notification from "../components/notification";

function getUserSchoolDetails(firebase) {
  var schoolName = firebase.sbDbName;
  var x = {};
  var userSettingPromise = new Promise(function(resolve, reject) {
    firebase.db
      .ref("schools/" + schoolName)
      .once("value")
      .then(function(snap) {
        if (snap.val() !== null) {
          x = snap.val();
        }
        resolve(x);
      });
  });
  return userSettingPromise;
}

function resetZoomSchoolDetails(firebase) {
  var schoolName = firebase.sbDbName;
  firebase.db
    .ref("schools/" + schoolName)
    .child("zoomAccessToken")
    .set(null);
  firebase.db
    .ref("schools/" + schoolName)
    .child("zoomGrantCode")
    .set(null);
  firebase.db
    .ref("schools/" + schoolName)
    .child("zoomRefreshToken")
    .set(null);
}
function resetZoomUserDetail(firebase) {
  var teacher = firebase.teacher;
  firebase.db
    .ref("teacher/" + teacher.id)
    .child("zoomAccessToken")
    .set(null);
  firebase.db
    .ref("schools/" + teacher.id)
    .child("zoomGrantCode")
    .set(null);
  firebase.db
    .ref("schools/" + teacher.id)
    .child("zoomRefreshToken")
    .set(null);
}

function updateSchoolLogoToStorage(file, firebase) {
  let branchPath = firebase.sbp;

  var uploadedImageUrl = [];
  if (file !== undefined) {
    var fileName = new Date().getTime() + file.name;
    var metadata = {
      contentType: file.type,
    };
    const storage = firebase.secondaryStorage;
    const storageRef = storage.ref();
    const attachmentRef = storageRef.child(branchPath + "/media/" + fileName);
    var uploadPromise = new Promise(function(resolve, reject) {
      attachmentRef
        .put(file, metadata)
        .then(function(snapshot) {
          attachmentRef.getDownloadURL().then((url) => {
            uploadedImageUrl.push(url);
            resolve(uploadedImageUrl);
          });
        })
        .catch((error) => {
          console.log("failed to upload logo", error);
          reject(error);
        });
    });
  }
  return uploadPromise;
}

function updateUserSchoolDetail(schoolName, logoUrl, firebase) {
  firebase.db.ref("schools/" + schoolName).update({
    logoUrl: logoUrl,
  });
}

function updateUserInfo(userInfo, schoolName, firebase) {
  firebase.db.ref("schools/" + schoolName).update({
    address: userInfo.address,
    adminEmail: userInfo.email,
    schoolName: userInfo.schoolName,
    invoicePrefix: userInfo.invoicePrefix ? userInfo.invoicePrefix : null,
    receiptPrefix: userInfo.receiptPrefix ? userInfo.receiptPrefix : null,
  });
}

function getUserDetail(firebase) {
  let teacher = {};
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var myPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/teachers/" + firebase.user.id)
      .once("value")
      .then(function(snap) {
        if (snap.val() !== null) {
          teacher = snap.val();
        }
        resolve(teacher);
      });
  });
  return myPromise;
}

function updateTeacherDetail(value, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/teachers/" + firebase.user.id).update({
    name: value.name,
    email: value.email,
  });
}

function getAllCameraData(firebase) {
  let teacher = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var myPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/cameraRefrence")
      .once("value")
      .then(function(snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            teacher.push(snap.val());
          }
        });
        resolve(teacher);
      });
  });
  return myPromise;
}

function updateCameraToClass(values, cameraId, firebase) {
  let newClassrooms = values.classroom;
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/cameraRefrence/" + cameraId).update({
    classroom: newClassrooms,
  });
}

function getUserSchoolConfig(firebase) {
  var schoolName = firebase.sbDbName;
  var x = {};
  return eventChannel((emitter) => {
    firebase.db.ref("schools/" + schoolName).on("value", (snap) => {
      if (snap.val() !== null) {
        x = snap.val();
      }
      emitter(x);
    });
    return () => {
      firebase.db.ref("schools/" + schoolName).off();
      console.log("END");
    };
  });
}

function fetchTeacher(teacherId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emitter) => {
    rsf.ref(branchPath + "/teachers/" + teacherId).on("value", (snap) => {
      var teacher = {};
      if (snap.val() !== null) {
        teacher = snap.val();
      }
      emitter(teacher);
    });
    return () => {
      rsf.ref(branchPath + "/teachers/" + teacherId).off();
      console.log("END");
    };
  });
}

function updateSchoolConfig(config, firebase) {
  let schoolName = firebase.sbDbName;
  firebase.db.ref("schools/" + schoolName).set(config, (error) => {
    if (error) {
      notification("error", "Session expired. Please re-login");
      firebase.signOut();
    } else {
      // Data saved successfully!
    }
  });
}

function getSchoolConfiguration(firebase, sbDbName) {
  let schoolName = sbDbName ? sbDbName : firebase.sbDbName;
  let x = {};

  var myPromise = new Promise(function(resolve, reject) {
    firebase.db
      .ref("schools/" + schoolName)
      .once("value")
      .then(function(snap) {
        if (snap.val() !== null) {
          x = snap.val();
        }
        resolve(x);
      });
  });
  return myPromise;
}

function getPermissionList(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var permRef = rsf.ref(branchPath + "/permissionList");

  return eventChannel((emitter) => {
    permRef.on("value", (snapshot) => {
      let permissions = new Map();
      let roles = [];
      let permissionTypes = [];
      snapshot.forEach((snap) => {
        if (snap.val() !== null && snap.val().type) {
          roles = [...roles, ...snap.val().roles];
          permissionTypes = [...permissionTypes, snap.val().type];

          let key;
          if (snap.val().permissionName) {
            key = snap.val().type + " " + snap.val().permissionName;
          } else {
            key = snap.val().type;
          }
          let pValue = snap.val();
          permissions.set(key, pValue);
        }
      });

      let exportedVal = {};
      exportedVal.permissions = permissions;
      exportedVal.roles = [...new Set(roles)];
      exportedVal.permissionTypes = [...new Set(permissionTypes)];
      emitter(exportedVal);
    });
    return () => {
      permRef.off();
      console.log("permission channel closed");
    };
  });
}

function savePermissions(permissions, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/permissionList").set(permissions);
}

function saveActiveActivies(activities, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/schoolSettingActivity").set(activities);
}

function updateDefCapacity(schoolName, capacity, firebase) {
  firebase.db.ref("schools/" + schoolName).update({
    defaultMeetingCapacity: capacity ? Number(capacity) : null,
  });
}

export const UserSettingApi = {
  getUserSchoolDetails,
  updateSchoolLogoToStorage,
  updateUserSchoolDetail,
  updateUserInfo,
  getUserDetail,
  updateTeacherDetail,
  getAllCameraData,
  updateCameraToClass,
  resetZoomSchoolDetails,
  resetZoomUserDetail,
  getUserSchoolConfig,
  fetchTeacher,
  updateSchoolConfig,
  getSchoolConfiguration,
  getPermissionList,
  savePermissions,
  saveActiveActivies,
  updateDefCapacity,
};
