const actions = {
  LIST_LEADS: "LIST_LEADS",
  LIST_LEADS_SUCCESSFULL: "LIST_LEADS_SUCCESSFULL",

  GET_FEE_PROGRAM_FOR_LEADS: "GET_FEE_PROGRAM_FOR_LEADS",
  GET_FEE_PROGRAM_FOR_LEADS_SUCCESSFUL: "GET_FEE_PROGRAM_FOR_LEADS_SUCCESSFUL",

  SAVE_NEW_LEAD: "SAVE_NEW_LEAD",
  SAVE_NEW_LEAD_SUCCESSFUL: "SAVE_NEW_LEAD_SUCCESSFUL",

  UPDATE_LEAD: "UPDATE_LEAD",
  UPDATE_LEAD_SUCCESSFUL: "UPDATE_LEAD_SUCCESSFUL",

  ADD_STATUS: "ADD_STATUS",
  ADD_STATUS_SUCCESSFUL: "ADD_STATUS_SUCCESSFUL",

  GET_STATUS: "GET_STATUS",
  GET_STATUS_SUCCESSFUL: "GET_STATUS_SUCCESSFUL",

  GET_LEAD_DETAIL: "GET_LEAD_DETAIL",
  GET_LEAD_DETAIL_SUCCESSFUL: "GET_LEAD_DETAIL_SUCCESSFUL",

  ADD_ACTIVITY: "ADD_ACTIVITY",
  ADD_ACTIVITY_SUCCESSFUL: "ADD_ACTIVITY_SUCCESSFUL",

  GET_LEAD_ACTIVITY: "GET_LEAD_ACTIVITY",
  GET_LEAD_ACTIVITY_SUCCESSFUL: "GET_LEAD_ACTIVITY_SUCCESSFUL",

  UPDATE_LEAD_STATUS_CHANGE: "UPDATE_LEAD_STATUS_CHANGE",
  UPDATE_LEAD_STATUS_CHANGE_SUCCESSFUL: "UPDATE_LEAD_STATUS_CHANGE_SUCCESSFUL",

  LEAD_REQUEST_FAILED: "LEAD_REQUEST_FAILED",

  RESET_LEADS_OPERATION_TYPE: "RESET_LEADS_OPERATION_TYPE",

  CREATE_NEW_ACCOUNT: "CREATE_NEW_ACCOUNT",
  CREATE_NEW_ACCOUNT_SUCCESS: "CREATE_NEW_ACCOUNT_SUCCESS",

  DELETE_LEAD: "DELETE_LEAD",
  DELETE_LEAD_SUCCESS: "DELETE_LEAD_SUCCESS",

  DELETE_LEAD_STATUS: "DELETE_LEAD_STATUS",
  DELETE_LEAD_STATUS_SUCCESS: "DELETE_LEAD_STATUS_SUCCESS",

  GET_LEADS_ADDITIONAL_FIELD: "GET_LEADS_ADDITIONAL_FIELD",
  GET_LEADS_ADDITIONAL_FIELD_SUCCESS: "GET_LEADS_ADDITIONAL_FIELD_SUCCESS",

  DOWNLOAD_LEADS_EXCEL: "DOWNLOAD_LEADS_EXCEL",
  DOWNLOAD_LEADS_EXCEL_SUCCESS: "DOWNLOAD_LEADS_EXCEL_SUCCESS",

  DELETE_LEAD_ACTIVITY: "DELETE_LEAD_ACTIVITY",
  DELETE_LEAD_ACTIVITY_SUCCESS: "DELETE_LEAD_ACTIVITY_SUCCESS",

  initLeadsData: (startDate, endDate, firebase, frequency, initialCall) => ({
    type: actions.LIST_LEADS,
    startDate,
    endDate,
    firebase,
    frequency,
    initialCall,
  }),

  getFeeProgram: (firebase) => ({
    type: actions.GET_FEE_PROGRAM_FOR_LEADS,
    firebase,
  }),

  getStatus: (firebase) => ({
    type: actions.GET_STATUS,
    firebase,
  }),

  saveNewLead: (leadsValue, firebase, leadAdditionalField) => ({
    type: actions.SAVE_NEW_LEAD,
    leadsValue,
    firebase,
    leadAdditionalField,
  }),

  updateLead: (leadsValue, record, firebase) => ({
    type: actions.UPDATE_LEAD,
    leadsValue,
    record,
    firebase,
  }),

  addStatus: (statusInput, firebase) => ({
    type: actions.ADD_STATUS,
    statusInput,
    firebase,
  }),

  fetchSelectedLeadDetail: (selectedLead, firebase) => ({
    type: actions.GET_LEAD_DETAIL,
    selectedLead,
    firebase,
  }),

  addActivity: (value, leadRecord, firebase) => ({
    type: actions.ADD_ACTIVITY,
    value,
    leadRecord,
    firebase,
  }),

  fetchSelectedLeadActivity: (selectedLead, firebase) => ({
    type: actions.GET_LEAD_ACTIVITY,
    selectedLead,
    firebase,
  }),

  updateLeadStatusChange: (selectedLeadStatus, lead, firebase) => ({
    type: actions.UPDATE_LEAD_STATUS_CHANGE,
    selectedLeadStatus,
    lead,
    firebase,
  }),

  resetLeadsOperationType: () => ({
    type: actions.RESET_LEADS_OPERATION_TYPE,
  }),

  createNewAccount: (record, firebase) => ({
    type: actions.CREATE_NEW_ACCOUNT,
    record,
    firebase,
  }),

  deleteLead: (record, firebase) => ({
    type: actions.DELETE_LEAD,
    record,
    firebase,
  }),

  deleteSelectedStatus: (record, firebase) => ({
    type: actions.DELETE_LEAD_STATUS,
    record,
    firebase,
  }),

  getLeadsAdditionalField: (firebase) => ({
    type: actions.GET_LEADS_ADDITIONAL_FIELD,
    firebase,
  }),

  downloadLeadsExcel: (firebase, values) => ({
    type: actions.DOWNLOAD_LEADS_EXCEL,
    firebase,
    values,
  }),

  deleteLeadActivity: (item, leadId, firebase) => ({
    type: actions.DELETE_LEAD_ACTIVITY,
    item,
    leadId,
    firebase,
  }),
};
export default actions;
