import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment";
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import notification from "../../components/notification";
import { NotificationApi } from "../../firestore-api/notification";
import { StaffAttendanceApi } from "../../firestore-api/staffAttendance";
import { StudentAttendanceApi } from "../../firestore-api/studentAttendance";
import actions from "./actions";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ComplainsApi } from "../../firestore-api/consult";

function* fetchStaffAttendance({ date, firebase, operationType }) {
  const chan = yield call(
    StaffAttendanceApi.getStaffAttendance,
    date,
    firebase
  );
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.FETCH_STAFF_ATTENDANCE_SUCCESSFUL,
        staffAttendance: data,
        staffAttendanceChannel: chan,
        operationType: operationType,
        // operationType: "INITIAL_STAFF_ATD_FETCH"
      });
    }
  } finally {
    console.log("end staff attendance channel");
  }
}

function* getStaffs({ firebase, date }) {
  try {
    let startTime = moment(date)
      .startOf("day")
      .valueOf();
    let endTime = moment(date)
      .endOf("day")
      .valueOf();

    let leaveIds = yield call(
      ComplainsApi.getCurrentDayStaffLeaves,
      firebase,
      startTime,
      endTime
    );

    let data = JSON.parse(localStorage.getItem("teacherList"));
    if (data && data.length > 0) {
      data = data.filter((d) => {
        return !leaveIds.includes(d.id);
      });
    }

    if (data) {
      yield put({
        type: actions.FETCH_ALL_STAFF_SUCCESSFUL,
        allStaffs: data,
      });
    }
  } catch (error) {
    console.log("failed to fetch staffs", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* getClassrooms({ firebase }) {
  try {
    // let data = yield call(TeacherApi.getClassroomsForTeacher, firebase);
    let data = JSON.parse(localStorage.getItem("classList"));
    if (data) {
      yield put({
        type: actions.FETCH_ALL_CLASSROOMS_FOR_STAFF_ATTENDANCE_SUCCESSFUL,
        classrooms: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch classrooms", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* markStaffsPresent({
  selectedStaff,
  date,
  time,
  firebase,
  pending,
  checkInOutStatus,
  temperature,
}) {
  try {
    for (let i = 0; i < selectedStaff.length; i++) {
      // let nodeId = yield call(StaffAttendanceApi.createUniqueNodeInStaffCheckInOutReference, date, firebase);
      let nodeId = selectedStaff[i].id;

      var checkInOutObject = {
        absent: false,
        checkInEpoch: moment(time).valueOf(),
        checkOutEpoch: 0,
        checkInTime: moment(time).format("h:mm a"),
        className: selectedStaff[i].className
          ? selectedStaff[i].className
          : null,
        classrooms: selectedStaff[i].classList
          ? selectedStaff[i].classList
          : null,
        createdBy: firebase.teacher.name,
        date: moment(date).valueOf(),
        deactivated: selectedStaff[i].deactivated,
        gender: selectedStaff[i].gender,
        id: nodeId,
        userId: selectedStaff[i].id,
        userName: selectedStaff[i].name,
        role: selectedStaff[i].role ? selectedStaff[i].role : null,
        late: checkInOutStatus && checkInOutStatus === "late" ? true : false,
        lateCheckout: false,
        platform: "web",
        updatedBy: [firebase.teacher.name],
        temperature: temperature ? temperature : null,
      };

      yield call(
        StaffAttendanceApi.markPresentToCheckInOutReference,
        date,
        selectedStaff[i].id,
        checkInOutObject,
        firebase
      );
      yield call(
        StaffAttendanceApi.markPresentToTeacherAttendanceUpdates,
        date,
        selectedStaff[i].id,
        checkInOutObject,
        firebase
      );
      yield call(
        StaffAttendanceApi.addRecordToStaffAttendance,
        date,
        checkInOutObject,
        selectedStaff[i],
        firebase
      );
      yield fork(
        StudentAttendanceApi.updatedAttendanceRecordApi,
        checkInOutObject,
        firebase,
        "updateAttendanceStaff"
      );

      let dateFormat = moment(time).format(" DD[-]MM[-]YY");
      yield fork(
        StaffAttendanceApi.updateTeacherLastAtd,
        selectedStaff[i].id,
        dateFormat,
        firebase
      );
    }

    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "staff-attendance",
      date
    );
    yield fork(
      NotificationApi.sendStats,
      date,
      "updateAttendanceStatsStaff",
      firebase
    );
    yield put({
      type: actions.MARK_STAFF_PRESENT_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to mark staff present", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* markStaffsCheckoutTime({
  selectedStaff,
  date,
  time,
  firebase,
  checkInOutStatus,
}) {
  try {
    for (let i = 0; i < selectedStaff.length; i++) {
      yield call(
        StaffAttendanceApi.updateCheckOutTimeInStaffCheckInOutReference,
        selectedStaff[i],
        date,
        time,
        firebase,
        checkInOutStatus
      );
      yield call(
        StaffAttendanceApi.updateCheckOutTimeInTeacherAttendanceUpdate,
        selectedStaff[i],
        date,
        time,
        firebase,
        checkInOutStatus
      );
      yield call(
        StaffAttendanceApi.updateCheckOutTimeToStaffAttendance,
        selectedStaff[i],
        date,
        time,
        firebase,
        checkInOutStatus
      );

      let dateFormat = moment(time).format(" DD[-]MM[-]YY");
      yield fork(
        StaffAttendanceApi.updateTeacherLastAtdCheckout,
        selectedStaff[i].id,
        dateFormat,
        firebase
      );
    }

    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "staff-attendance",
      date
    );
    yield fork(
      NotificationApi.sendStats,
      date,
      "updateAttendanceStatsStaff",
      firebase
    );
    yield fork(updateCheckoutStaffRecord, selectedStaff, firebase, date);
    yield put({
      type: actions.MARK_STAFF_CHECKOUT_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to mark staff checkout time", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* updateCheckoutStaffRecord(selectedStaffs, firebase, date) {
  try {
    for (let index in selectedStaffs) {
      let data = yield call(
        StaffAttendanceApi.getStaffAttendanceById,
        date,
        selectedStaffs[index].id,
        firebase
      );
      if (data) {
        yield fork(
          StudentAttendanceApi.updatedAttendanceRecordApi,
          data,
          firebase,
          "updateAttendanceStaff"
        );
      }
    }
  } catch (err) {
    console.log(
      "failed to call and  update checked out staff attendance record api",
      err
    );
    bugsnagClient.notify(err);
  }
}

function* markStaffsAbsent({ selectedStaff, date, firebase }) {
  try {
    for (let i = 0; i < selectedStaff.length; i++) {
      yield call(
        StaffAttendanceApi.removeStaffRecordFromStaffCheckInOutReference,
        selectedStaff[i],
        date,
        firebase
      );

      let nodeId = selectedStaff[i].id;
      var checkInOutObject = {
        absent: true,
        checkInEpoch: 0,
        checkOutEpoch: 0,
        checkInTime: null,
        className: selectedStaff[i].className
          ? selectedStaff[i].className
          : null,
        classrooms: selectedStaff[i].classList
          ? selectedStaff[i].classList
          : null,
        createdBy: firebase.teacher.name,
        date: moment(date).valueOf(),
        deactivated: selectedStaff[i].deactivated,
        gender: selectedStaff[i].gender,
        id: nodeId,
        userId: selectedStaff[i].id,
        userName: selectedStaff[i].name,
        late: false,
        lateCheckout: false,
        platform: "web",
        updatedBy: [firebase.teacher.name],
      };

      yield call(
        StaffAttendanceApi.updateRecordToTeacherAttendanceUpdates,
        selectedStaff[i],
        date,
        firebase,
        checkInOutObject
      );
      yield call(
        StaffAttendanceApi.updateRecordToStaffAttendance,
        selectedStaff[i],
        date,
        firebase,
        checkInOutObject
      );

      yield fork(
        StaffAttendanceApi.updateTeacherLastAtd,
        selectedStaff[i].id,
        undefined,
        firebase
      );

      yield fork(
        StaffAttendanceApi.updateTeacherLastAtdCheckout,
        selectedStaff[i].id,
        undefined,
        firebase
      );
    }

    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "staff-attendance",
      date
    );
    yield fork(
      NotificationApi.sendStats,
      date,
      "updateAttendanceStatsStaff",
      firebase
    );
    yield fork(updateCheckoutStaffRecord, selectedStaff, firebase, date);
    yield put({
      type: actions.MARK_STAFF_ABSENT_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to mark staff absent", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* getStaffByClassroomName({ className, firebase, date }) {
  try {
    // let data = yield call(StaffAttendanceApi.getStaffByClassName, className, firebase);
    let startTime = moment(date)
      .startOf("day")
      .valueOf();
    let endTime = moment(date)
      .endOf("day")
      .valueOf();

    let leaveIds = yield call(
      ComplainsApi.getCurrentDayStaffLeaves,
      firebase,
      startTime,
      endTime
    );

    let teachers = JSON.parse(localStorage.getItem("teacherList"));
    let data = teachers.filter((ele) => {
      if (
        ele.classList &&
        ele.classList.includes(className) &&
        !leaveIds.includes(ele.id)
      ) {
        return ele;
      }
    });
    if (data) {
      yield put({
        type: actions.FETCH_STAFF_BY_CLASSNAME_SUCCESSFUL,
        allStaffs: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch staff from classname", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* fetchStaffAttendanceByClassName({ className, date, firebase }) {
  const chan = yield call(
    StaffAttendanceApi.getStaffAttendanceByClassName,
    className,
    date,
    firebase
  );
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.FETCH_STAFF_ATTENDANCE_BY_CLASSNAME_SUCCESSFUL,
        staffAttendance: data,
        staffAttendanceChannel: chan,
      });
    }
  } finally {
    console.log("end staff attendance channel");
  }
}

// function* requestStaffAttendanceEmail({ value, atdType, firebase }) {
//   try {
//     yield call(
//       NotificationApi.sendAttendanceEmail,
//       value.dateRange[0],
//       value.dateRange[1],
//       atdType,
//       firebase
//     );
//     // if (response && response.status === 200) {
//     notification("success", "Email sent");
//     // } else {
//     //     notification('error', "Failed to send email");
//     // }
//   } catch (err) {
//     console.log("failed to send staff attendance email", err);
//     bugsnagClient.notify(err);
//   }
// }

function* downloadStaffAttendanceDaywise({
  value,
  atdType,
  downloadType,
  firebase,
}) {
  let startDate = value.dateRange[0];
  let endDate = value.dateRange[1];

  if (downloadType === "checkInOut") {
    yield fork(downloadStaffCheckInOutDaywise, startDate, endDate, firebase);
  } else {
    try {
      var report = [];
      var checkInRef = firebase.secondaryDb.ref(
        firebase.sbp + "/teacherAttendanceUpdates"
      );

      checkInRef.once("value", function(snapshot) {
        if (snapshot) {
          // console.log(
          //   "No of days record are there in checkincheckout:" +
          //     snapshot.numChildren()
          // );

          snapshot.forEach(function(dateSnap) {
            dateSnap.forEach(function(data) {
              // console.log("staff record", data.val());
              if (data.val() && data.val().id) {
                processStaffCheckInForDate(
                  data,
                  report,
                  startDate,
                  endDate,
                  firebase.schoolConfig.timezone,
                  snapshot.key
                );
              }
            });
          });
          var reportLeave = [];
          var p = processStaffLeaveData(
            firebase.secondaryDb,
            reportLeave,
            startDate,
            endDate,
            firebase.schoolConfig.timezone,
            firebase
          );

          p.then((results) => {
            report = report.concat(reportLeave);
            // console.log("Final Report", report);
            const fields = [
              "Classname",
              "StaffName",
              "AttendanceDate",
              "CheckInTime",
              "CheckOutTime",
              "Attendance",
              "Temperature",
            ];

            const fileType =
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            const fileExtension = ".xlsx";
            const fileName =
              "StaffAttendance" +
              moment(startDate).format("DD-MMM-YY") +
              "-" +
              moment(endDate).format("DD-MMM-YY");
            var ws = XLSX.utils.json_to_sheet(report, { header: fields });

            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, {
              bookType: "xlsx",
              type: "array",
            });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);

            //   const workbook = new ExcelJS.Workbook();
            //   workbook.creator = 'Illumine';
            //   workbook.lastModifiedBy = 'Illumine';
            //   workbook.created = new Date();
            //   workbook.modified = new Date();
            //   // create a sheet with the first row and column frozen
            // const worksheet = workbook.addWorksheet('Attendance Sheet');
            // worksheet.addRows(report);
            // writeBufferToFile(workbook,fileType,fileName,fileExtension)
          });
        }
      });
    } catch (err) {
      console.log("failed to download monthly attendance", err);
      bugsnagClient.notify(err);
      yield put({
        type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
      });
    }
  }
}

function* downloadStaffCheckInOutDaywise(startDate, endDate, firebase) {
  try {
    const fields = ["staffName", "className"];
    var report = [];
    for (
      var m = moment(startDate);
      m.diff(endDate, "days") <= 0;
      m.add(1, "days")
    ) {
      console.log(m.format("YYYY-MM-DD"));
      fields.push(m.format("YYYY-MM-DD") + "-IN");
      fields.push(m.format("YYYY-MM-DD") + "-OUT");
    }
    console.log(
      "Processing Attendance Report for Date range: " +
        startDate +
        " - " +
        endDate
    );
    var checkInRef = firebase.secondaryDb.ref(
      firebase.sbp + "/staffAttendanceReference"
    );
    checkInRef.once("value", function(snapshot) {
      if (snapshot) {
        console.log(
          "No of days record are there in checkincheckout:" +
            snapshot.numChildren()
        );
        //iterate for each student now
        snapshot.forEach(function(stuSnap) {
          processAttendanceForStaff(
            startDate,
            endDate,
            stuSnap,
            firebase.schoolConfig.timezone,
            report
          );
        });
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const fileName =
          "StaffCheckInOutAttendance" +
          moment(startDate).format("DD-MMM-YY") +
          "-" +
          moment(endDate).format("DD-MMM-YY");

        var ws = XLSX.utils.json_to_sheet(report, { header: fields });

        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        //var p = processLeaveData(firebase.secondaryApp,report, startDate, endDate, timezone);

        // p.then(results =>{
        //     console.log("Final Report", report);
        //     const parser = new Json2csvParser(opts);
        //     const csv = parser.parse(report);
        //     console.log(csv);
        //     sendEmailCSVReport(email, ccEmail, csv);
        //     response.status(200).send();
        // })
      }
    });
  } catch (err) {
    console.log("failed to download monthly attendance", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
    });
  }
}
function processAttendanceForStaff(
  startDate,
  endDate,
  staffAttendanceSnapList,
  timezone,
  report
) {
  var staffRow = {};
  staffAttendanceSnapList.forEach((monthSnap) => {
    //console.log("month key",monthSnap.key);
    monthSnap.forEach((attSnap) => {
      //  console.log("student",attSnap.numChildren());
      var attRecord = attSnap.val();
      if (attRecord && attRecord.id) {
        if (
          moment
            .tz(attRecord.date, timezone)
            .isBetween(
              moment.tz(startDate, timezone),
              moment.tz(endDate, timezone),
              "day",
              "[]"
            )
        ) {
          staffRow["staffName"] = attRecord.userName;
          staffRow["className"] = attRecord.classrooms
            ? attRecord.classrooms.toString()
            : "";
          var dateString = moment
            .tz(attRecord.date, timezone)
            .format("YYYY-MM-DD");
          staffRow[dateString + "-IN"] = attRecord.checkInTime
            ? attRecord.checkInTime
            : "";
          staffRow[dateString + "-OUT"] = attRecord.checkOutTime
            ? attRecord.checkOutTime
            : "";
          // console.log("student");
        }
      }
    });
  });
  console.log("staff row", staffRow);
  if (JSON.stringify(staffRow) != JSON.stringify({})) report.push(staffRow);
}
function processStaffCheckInForDate(
  checkInRecordSnap,
  report,
  startDate,
  endDate,
  timezone,
  dateString
) {
  var data1 = checkInRecordSnap.val();
  console.log(
    "Checkin Date",
    data1,
    moment.tz(data1.date, timezone).format("DD-MM-YYYY")
  );
  if (
    moment
      .tz(data1.date, timezone)
      .isBetween(
        moment.tz(startDate, timezone),
        moment.tz(endDate, timezone),
        "day",
        "[]"
      )
  ) {
    var checkIn = {};
    checkIn.Classname = data1.classrooms ? data1.classrooms.toString() : "";
    checkIn.StaffName = data1.userName;
    checkIn.AttendanceDate = moment
      .tz(data1.date, timezone)
      .format("DD/MM/YYYY");
    if (!data1.absent) {
      checkIn.CheckInTime = data1.checkInTime;
      checkIn.CheckOutTime = data1.checkOutTime;
      checkIn.Attendance = "Present";
      checkIn.Temperature = data1.temperature;
    } else {
      checkIn.Attendance = "Absent";
      checkIn.Temperature = "NA";
    }
    report.push(checkIn);
  }
  console.log("Final Report", report);
}

function processStaffLeaveData(
  secondaryDb,
  report,
  startDate,
  endDate,
  timezone,
  firebase
) {
  return secondaryDb
    .ref(firebase.sbp + "/staffLeaveReference")
    .once("value")
    .then((snap) => {
      if (snap.val()) {
        console.log("got leaves data", snap.numChildren());
        snap.forEach((sn1) => {
          var record = sn1.val();
          if (
            moment
              .tz(record.startDate, timezone)
              .isBetween(
                moment.tz(startDate, timezone),
                moment.tz(endDate, timezone),
                "days",
                "[]"
              )
          ) {
            var diffDays = moment
              .tz(record.startDate, timezone)
              .diff(moment.tz(record.endDate, timezone), "days");
            for (var i = 0; i <= diffDays; i++) {
              var leaveDate = moment
                .tz(record.startDate, timezone)
                .add(i, "day");
              if (leaveDate.isAfter(moment.tz(endDate, timezone))) {
                //console.log("ignore as date is not indate range");
              } else {
                var checkIn = {};
                checkIn.Classname = record.classRoom;
                checkIn.StaffName = record.student;
                checkIn.AttendanceDate = leaveDate.format("DD/MM/YYYY");
                checkIn.Attendance = "Leave";
                checkIn.Temperature = "NA";
                report.push(checkIn);
                //console.log("adding leave", checkIn);
              }
            }
          } else {
            // console.log(
            //   "leave is not between start & end",
            //   moment.tz(startDate, timezone).format("DD/MM/YY"),
            //   moment.tz(endDate, timezone).format("DD/MM/YY"),
            //   moment.tz(record.startDate, timezone).format("DD/MM/YY")
            // );
          }
        });
      }
    });
}

function* fetchStaffMonthlyAttendance({ date, firebase }) {
  try {
    let atdRecord = [];
    let data = yield call(
      StaffAttendanceApi.getStaffAttendanceByMonth,
      date,
      firebase
    );
    if (data) {
      for (let [k, value] of data) {
        console.log(k);
        let data = {};
        let daysAttended = 0;
        let hoursAttended = 0;
        let daysUnattended = 0;
        let lateCheckInCount = 0;
        let lateCheckOutCount = 0;

        for (let index in value) {
          let eleVal = value[index];

          data.name = eleVal.userName;
          data.className = eleVal.classrooms;
          data.gender = eleVal.gender;
          data.userId = eleVal.userId;
          if (
            !eleVal.absent &&
            eleVal.checkInEpoch &&
            eleVal.checkInEpoch > 0
          ) {
            daysAttended++;
          } else if (eleVal.absent) {
            daysUnattended++;
          }

          if (eleVal.late !== undefined && eleVal.late === true) {
            lateCheckInCount++;
          }

          if (
            eleVal.lateCheckout !== undefined &&
            eleVal.lateCheckout === true
          ) {
            lateCheckOutCount++;
          }

          if (eleVal.checkInEpoch && eleVal.checkOutEpoch && !eleVal.absent) {
            let a = moment(eleVal.checkOutEpoch);
            let b = moment(eleVal.checkInEpoch);
            let diff = moment.duration(a.diff(b)).as("hours");
            hoursAttended = hoursAttended + diff;
          }

          data.daysAttended = daysAttended;
          data.hoursAttended = hoursAttended;
          data.daysUnattended = daysUnattended;
          data.lateCheckInCount = lateCheckInCount;
          data.lateCheckOutCount = lateCheckOutCount;
        }
        atdRecord.push(data);
      }
    }
    yield put({
      type: actions.GET_STAFF_MONTHLY_ATD_SUCCESS,
      staffMonthlyAttendance: atdRecord,
    });
  } catch (err) {
    console.log("failed to fetch staff monthly attendance", err);
    bugsnagClient.notify(
      "failed to fetch staff monthly attendance" + err.message
        ? err.message
        : err
    );
  }
}

function* markStaffsPending({ selectedStaff, date, firebase }) {
  try {
    for (let i = 0; i < selectedStaff.length; i++) {
      let data = yield call(
        StaffAttendanceApi.getStaffAttendanceById,
        date,
        selectedStaff[i].id,
        firebase
      );
      if (data) {
        yield fork(
          StudentAttendanceApi.updatedAttendanceRecordApi,
          data,
          firebase,
          "updateAttendanceStaff",
          "markPending"
        );
      }

      yield call(
        StaffAttendanceApi.markStaffPending,
        selectedStaff[i],
        date,
        firebase
      );
      yield call(
        StaffAttendanceApi.removeStaffRecordFromStaffCheckInOutReference,
        selectedStaff[i],
        date,
        firebase
      );

      yield fork(
        StaffAttendanceApi.updateTeacherLastAtd,
        selectedStaff[i].id,
        undefined,
        firebase
      );

      yield fork(
        StaffAttendanceApi.updateTeacherLastAtdCheckout,
        selectedStaff[i].id,
        undefined,
        firebase
      );
      //fetch and add this call incase of pending
      //yield fork(StudentAttendanceApi.updatedAttendanceRecordApi, checkInOutObject, firebase, "updateAttendanceStaff");
    }
    yield put({
      type: actions.MARK_STAFF_PENDING_SUCCESS,
    });
  } catch (err) {
    console.log("failed to mark staffs back to pending list", err);
    bugsnagClient.notify(err);
  }
}

function* downloadAttendanceMonthly({ record, firebase }) {
  try {
    console.log(":record", record);
    const fields = [
      "name",
      "classroom",
      "present",
      "absent",
      "lateCheckIn",
      "lateCheckOut",
      "hoursAttended",
    ];
    let report = [];
    for (let index in record) {
      let singleRecord = record[index];
      var row = {};
      row.name = singleRecord.name;
      row.classroom = singleRecord.className
        ? singleRecord.className.toString()
        : "";
      row.present = singleRecord.daysAttended;
      row.absent = singleRecord.daysUnattended;
      row.lateCheckIn = singleRecord.lateCheckInCount;
      row.lateCheckOut = singleRecord.lateCheckOutCount;
      row.hoursAttended = singleRecord.hoursAttended;
      report.push(row);
    }

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "StaffMonthlyAttendance";

    var ws = XLSX.utils.json_to_sheet(report, { header: fields });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  } catch (err) {
    console.log("failed to download staff attendance monthly", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.FETCH_STAFF_ATTENDANCE, fetchStaffAttendance),
    yield takeLatest(actions.FETCH_ALL_STAFF, getStaffs),
    yield takeLatest(
      actions.FETCH_ALL_CLASSROOMS_FOR_STAFF_ATTENDANCE,
      getClassrooms
    ),
    yield takeLatest(actions.MARK_STAFF_PRESENT, markStaffsPresent),
    yield takeLatest(actions.MARK_STAFF_CHECKOUT, markStaffsCheckoutTime),
    yield takeLatest(actions.MARK_STAFF_ABSENT, markStaffsAbsent),
    yield takeLatest(actions.FETCH_STAFF_BY_CLASSNAME, getStaffByClassroomName),
    yield takeLatest(
      actions.FETCH_STAFF_ATTENDANCE_BY_CLASSNAME,
      fetchStaffAttendanceByClassName
    ),
    yield takeLatest(
      actions.EMAIL_STAFF_ATTENDANCE,
      downloadStaffAttendanceDaywise
    ),
    yield takeLatest(
      actions.GET_STAFF_MONTHLY_ATD,
      fetchStaffMonthlyAttendance
    ),
    yield takeLatest(actions.MARK_STAFF_PENDING, markStaffsPending),
    yield takeLatest(
      actions.DOWNLOAD_STAFF_ATD_MONTHLY,
      downloadAttendanceMonthly
    ),
  ]);
}
