import { all, put, call, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import '@firebase/firestore' // 👈 If you're using firestore
import { AssessmentApi } from '../../firestore-api/studentAssessment';
import bugsnagClient from '@bugsnag/js';

function* fetchStudentAssessment({ firebase }) {
    try {
        let data = yield call(AssessmentApi.savedAssessmentList, firebase);
        if (data) {
            yield put({
                type: actions.LIST_ASSESSMENT_STUDENT_WITH_MILESTONES_SUCCESSFUL,
                studentAssessment: data.dataMap,
            })
        }
    } catch (err) {
        console.log("failed to fetch saved assessments", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.ASSESSMENT_REQUEST_FAILED,
        })
    }
}

function* fetchAllMilestones({ firebase }) {
    try {
        let data = new Map(JSON.parse(localStorage.getItem("milestones")));
        if (data) {
            yield put({
                type: actions.LIST_ALL_MILESTONES_SUCCESSFUL,
                allMilestones: data
            })
        }
    } catch (err) {
        console.log("failed to fetch milestones", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.ASSESSMENT_REQUEST_FAILED,
        })
    }
}

function* fetchAllStudents({ firebase }) {
    try {
        let data = JSON.parse(localStorage.getItem("studentList"));
        let students = [];
        if (data && data.length > 0) {
            students = data.filter(std => {
                return !std.status || std.status.toLowerCase() === "active"
            })
        }
        if (data) {
            yield put({
                type: actions.GET_ALL_STUDENTS_SUCCESSFUL,
                allStudents: students
            })
        }
    } catch (err) {
        console.log("failed to fetch students", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.ASSESSMENT_REQUEST_FAILED,
        })
    }
}

function* fetchDevelopmentAreas({ firebase }) {
    try {
        let data = JSON.parse(localStorage.getItem("developmentAreas"));
        if (data) {
            yield put({
                type: actions.GET_DEVELOPMENT_AREAS_SUCCESS,
                developmentAreas: data
            })
        }
    } catch (err) {
        console.log("failed to fetch development areas", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.ASSESSMENT_REQUEST_FAILED,
        })
    }
}



export default function* rootSaga() {
    yield all([
        yield takeLatest(actions.LIST_ASSESSMENT_STUDENT_WITH_MILESTONES, fetchStudentAssessment),
        yield takeLatest(actions.LIST_ALL_MILESTONES, fetchAllMilestones),
        yield takeLatest(actions.GET_ALL_STUDENTS, fetchAllStudents),
        yield takeLatest(actions.GET_DEVELOPMENT_AREAS, fetchDevelopmentAreas),
    ])
}