import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import '@firebase/firestore' // 👈 If you're using firestore
import { CenterApi } from '../../firestore-api/center';

export function* listCenters() {
  let fullCenterData = [];
  try {
    fullCenterData = yield call(CenterApi.getAllCenters);
  } catch (error) {
    yield put({
      type: actions.CENTER_REQUEST_FAILED,
      errorInfo: error
    })
  }
  yield put({
    type: actions.LIST_CENTER,
    centers: fullCenterData
  });
}


export function* addCenter({ name, address }) {
  try {
    yield call(CenterApi.addNewCenter, name, address);
  } catch (error) {
    console.log(error);
    yield put({
      type: actions.CENTER_REQUEST_FAILED,
      errorInfo: error
    });
  }
  const centerList = yield* listCenters();
  yield put({
    type: actions.ADD_CENTER_SUCCESSFUL,
    centerList
  })
}

export function* updateCenter({ updatedName, updatedAddress, docId }) {
  try {
    yield call(CenterApi.updateExistingCenter, updatedName, updatedAddress, docId);
  } catch (error) {
    console.log(error);
    yield put({
      type: actions.CENTER_REQUEST_FAILED,
      errorInfo: error
    });
  }
  const centerList = yield* listCenters();
  yield put({
    type: actions.UPDATE_CENTER_SUCCESSFUL,
    centerList
  })
}

export function* toggleCenterUpdate({ disableStatus, docId }) {
  try {
    yield call(CenterApi.disableCenter, disableStatus, docId);
  } catch (error) {
    console.log(error);
    yield put({
      type: actions.CENTER_REQUEST_FAILED,
      errorInfo: error
    });
  }
  const centerList = yield* listCenters();
  yield put({
    type: actions.TOGGLE_CENTER_DATA_SUCCESSFUL,
    centerList
  })
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.LIST_CENTERS, listCenters),
    yield takeEvery(actions.ADD_CENTER, addCenter),
    yield takeEvery(actions.UPDATE_CENTER, updateCenter),
    yield takeEvery(actions.TOGGLE_CENTER_DATA, toggleCenterUpdate)
  ]);
}
