import "@firebase/firestore"; // 👈 If you're using firestore
import { all, call, put, takeLatest, take, fork } from "redux-saga/effects";
import { ComplainsApi } from "../../firestore-api/consult";
import { StudentApi } from "../../firestore-api/student";
import { NotificationApi } from "../../firestore-api/notification";
import { CommentApi } from "../../firestore-api/comment";
import actions from "./actions";
import { lessonAssignmentApi } from '../../firestore-api/lessonAssignment';
import bugsnagClient from '@bugsnag/js';
import moment from 'moment-timezone';

function* fetchAllParentNotes({ firebase, startDate, endDate, initialCall, selectedFrequency }) {
    const chan = yield call(ComplainsApi.getAllParentNotes, firebase, startDate, endDate, selectedFrequency);
    try {
        while (true) {
            let data = yield take(chan);
            let x = data;
            x.sort(function (a, b) {
                var dateA = (a.appliedDate), dateB = (b.appliedDate)
                return dateB - dateA
            })
            yield put({
                type: actions.GET_ALL_PARENT_NOTES_SUCCESSFUL,
                parentNotes: x,
                parentNotesChannel: chan,
                operationType: initialCall ? "INITIAL_CALL" : undefined
            })
        }
    } finally {
        console.log("end parent notes channel")
    }
}

function* fetchNotificationNote({ id, firebase }) {
    const chan = yield call(ComplainsApi.getParentNoteById, id, firebase);
    try {
        while (true) {
            let data = yield take(chan);
            yield put({
                type: actions.GET_ALL_PARENT_NOTES_SUCCESSFUL,
                parentNotes: data,
                parentNotesChannel: chan,
                operationType: undefined
            })
        }
    } finally {
        console.log("terminating fetch notification note");
    }
}

function* fetchCommentsForSelectedParentNote({ notes, firebase }) {
    try {
        let activities = notes;
        var commentsCount = new Map();
        for (const item in activities) {
            var commentCountObj = yield call(lessonAssignmentApi.getCommentsCount, activities[item].id, firebase);
            if (commentCountObj) {
                commentsCount.set(activities[item].id, commentCountObj);
            }
        }
        yield put({
            type: actions.GET_COMMENT_FOR_PARENT_NOTE_SUCCESSFUL,
            notesComment: commentsCount
        });

    } catch (error) {
        console.log("failed to fetch comment for selected parent note", error);
        bugsnagClient.notify(error);
        yield put({
            type: actions.PARENT_NOTE_REQUEST_FAILED
        })
    }
}

function* resolveSelectedNote({ record, firebase }) {
    try {
        let activityId = record.id;
        let activityName = "Note";
        let body = record.consultTo + " Note Marked Completed";
        let task = record;

        task.status = "approved";
        yield fork(ComplainsApi.updateNote, task, firebase);
        let selectedStudent = yield call(StudentApi.getStudentById, record.studentId, firebase);
        if (selectedStudent && selectedStudent.id) {

            if (selectedStudent.fatherProfileId) {
                let alertNode = yield call(
                    NotificationApi.createAlertReferenceNode,
                    selectedStudent.fatherProfileId,
                    firebase
                );
                yield fork(
                    NotificationApi.createAlertNotification,
                    activityName,
                    activityId,
                    selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
                    body,
                    alertNode,
                    selectedStudent.ios_fatherUUid
                        ? selectedStudent.ios_fatherUUid
                        : null,
                    selectedStudent.id,
                    selectedStudent.fatherProfileId,
                    firebase
                );

                if (selectedStudent.fatherUUid !== undefined || selectedStudent.ios_fatherUUid !== undefined) {
                    yield fork(
                        NotificationApi.sendPushNotification,
                        activityName,
                        activityId,
                        selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
                        body,
                        alertNode,
                        selectedStudent.ios_fatherUUid
                            ? selectedStudent.ios_fatherUUid
                            : null,
                        selectedStudent.id,
                        selectedStudent.fatherProfileId,
                        firebase
                    );
                }
            }

            if (selectedStudent.motherProfileId) {
                let alertNode = yield call(
                    NotificationApi.createAlertReferenceNode,
                    selectedStudent.motherProfileId,
                    firebase
                );
                yield fork(
                    NotificationApi.createAlertNotification,
                    activityName,
                    activityId,
                    selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
                    body,
                    alertNode,
                    selectedStudent.ios_motherUUid
                        ? selectedStudent.ios_motherUUid
                        : null,
                    selectedStudent.id,
                    selectedStudent.motherProfileId,
                    firebase
                );

                if (selectedStudent.motherUUid !== undefined || selectedStudent.ios_motherUUid !== undefined) {
                    yield fork(
                        NotificationApi.sendPushNotification,
                        activityName,
                        activityId,
                        selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
                        body,
                        alertNode,
                        selectedStudent.ios_motherUUid
                            ? selectedStudent.ios_motherUUid
                            : null,
                        selectedStudent.id,
                        selectedStudent.motherProfileId,
                        firebase
                    );
                }
            }
        }

        yield fork(NotificationApi.callDashboardRefreshApi, firebase, "communication", moment());
        yield put({
            type: actions.RESOLVE_NOTE_SUCCESSFUL
        })

    } catch (err) {
        console.log("failed to resolve note", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.PARENT_NOTE_REQUEST_FAILED
        })
    }
}

function* addNoteComment({ comment, dataSource, firebase }) {
    try {
        var commentObject = {
            author: firebase.teacher.name,
            authorId: firebase.teacher.id,
            id: '',
            gender: firebase.teacher.gender ? firebase.teacher.gender : null,
            inverseTimestamp: -(moment().valueOf()),
            sourceId: dataSource.id,
            src: "COMMENT",
            text: comment.comment,
            timestamp: moment().valueOf(),
            userType: "TEACHER"
        }
        let nodeId = yield call(CommentApi.createCommentNode, dataSource.id, firebase);
        commentObject.id = nodeId;
        yield call(CommentApi.updateComment, commentObject, dataSource.id, nodeId, firebase);
        yield put({
            type: actions.SAVE_NOTE_COMMENT_SUCCESSFUL
        })

    } catch (err) {
        console.log("failed to add note comment", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.PARENT_NOTE_REQUEST_FAILED
        })
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(actions.GET_ALL_PARENT_NOTES, fetchAllParentNotes),
        yield takeLatest(actions.GET_COMMENT_FOR_PARENT_NOTE, fetchCommentsForSelectedParentNote),
        yield takeLatest(actions.RESOLVE_NOTE, resolveSelectedNote),
        yield takeLatest(actions.SAVE_NOTE_COMMENT, addNoteComment),
        yield takeLatest(actions.GET_NOTIFICATION_NOTE, fetchNotificationNote)
    ])
}