import "@firebase/firestore"; // 👈 If you're using firestore
import { eventChannel } from "redux-saga";
import moment from "moment-timezone";

function createChatNode(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/conversations").push().key;
  return key;
}

function getParentChatList(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let chatListRef = rsf
    .ref(branchPath + "/chatList")
    .orderByChild("inverseDate");
  return eventChannel((emitter) => {
    chatListRef.on("value", (snap) => {
      var allChatListModel = [];
      snap.forEach((item) => {
        if (item.val() !== null) {
          let classList =
            firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            if (classList.length > 0) {
              if (item.val().chatListModel && item.val().chatListModel.id) {
                let parentId = item.val().chatListModel.id;
                let studentId = item.val().chatListModel.studentId
                  ? item.val().chatListModel.studentId
                  : undefined;
                let allStudents = JSON.parse(
                  localStorage.getItem("studentList")
                );
                let student = allStudents.filter((s) => {
                  if (studentId && s.id === studentId) {
                    return s;
                  } else if (
                    s.fatherProfileId &&
                    s.fatherProfileId === parentId
                  ) {
                    return s;
                  } else if (
                    s.motherProfileId &&
                    s.motherProfileId === parentId
                  ) {
                    return s;
                  }
                });

                if (student && student.length > 0) {
                  let studentClass = student[0].classList;
                  for (let i in classList) {
                    if (studentClass && studentClass.includes(classList[i])) {
                      allChatListModel.push(item.val());
                      break;
                    }
                  }
                }
              } else {
                allChatListModel.push(item.val());
              }
            } else {
              allChatListModel.push(item.val());
            }
          } else {
            allChatListModel.push(item.val());
          }
        }
      });
      emitter(allChatListModel);
    });
    return () => {
      chatListRef.off();
      console.log("unsubscribing channel");
    };
  });
}

function getUnreadMessageKey(parentId, teacherId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let msgRef = rsf
    .ref(branchPath + "/unreadKey/" + parentId)
    .orderByChild("teacherId")
    .equalTo(teacherId);

  let chatVal = new Map();

  let promise = new Promise((resolve, reject) => {
    msgRef.once("value").then((snapshot) => {
      snapshot.forEach((snap) => {
        if (snap.val() !== null && snap.val().count && snap.val().count > 0) {
          chatVal.set(parentId, snap.val().count);
        }
      });
      resolve(chatVal);
    });
  });
  return promise;
}

function resetTeacherUnread(teacherId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/totalUnread/" + teacherId).set(0);
}

function getParentChats(parentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let messageRef = rsf
    .ref(branchPath + "/conversations/" + parentId)
    .orderByChild("inversdate");
  return eventChannel((emit) => {
    messageRef.on("value", (snap) => {
      var x = [];
      snap.forEach((item) => {
        if (item.val() !== null) {
          x.push(item.val());
        }
      });
      emit(x);
    });
    return () => {
      messageRef.off();
      console.log("unsubscribing query");
    };
  });
}

function putChatMessage(parentId, message, nodeKey, urls, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var messageDate = new Date();
  // var message = message;
  let createdAt = {
    date: messageDate.getDate(),
    day: messageDate.getDay(),
    hours: messageDate.getHours(),
    minutes: messageDate.getMinutes(),
    month: messageDate.getMonth(),
    seconds: messageDate.getSeconds(),
    time: messageDate.getTime(),
    timezoneOffset: messageDate.getTimezoneOffset(),
    year: messageDate.getFullYear(),
  };

  rsf.ref(branchPath + "/conversations/" + parentId + "/" + nodeKey).update({
    createdAt: createdAt,
    id: firebase.teacher.id,
    name: firebase.teacher.name,
    inversdate: -Math.abs(messageDate.getTime()),
    senderId: "school",
    status: "Sent",
    imageUrl: urls.length > 0 ? urls[0] : null,
    text: message,
  });
}

function getChatListOfSelectedParent(parentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var promise = new Promise(function(resolve, reject) {
    let chatList = {};
    rsf
      .ref(branchPath + "/chatList/" + parentId)
      .once("value")
      .then((snap) => {
        chatList = snap.val();
        resolve(chatList);
      });
  });
  return promise;
}

function updateUnreadCount(chatListObject, parentId, message, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var newChatListObject = {
    chatListModel: chatListObject.chatListModel
      ? chatListObject.chatListModel
      : null,
    inverseDate: -moment().valueOf(),
    lastMessage: message,
    lastSenderId: "school",
    parentUnreadMessageCount: chatListObject.parentUnreadMessageCount
      ? chatListObject.parentUnreadMessageCount + 1
      : 1,
    teacherUnreadMessageCount: 0,
  };

  rsf.ref(branchPath + "/chatList/" + parentId).update(newChatListObject);
}

function resetTeacherUnreadMessageCount(parentId, chatListObject, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let newChatListObject = chatListObject;
  newChatListObject.teacherUnreadMessageCount = 0;

  rsf.ref(branchPath + "/chatList/" + parentId).update(newChatListObject);
}

function resetUnreadMessageKey(parentId, teacherId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/unreadKey/" + parentId + "/" + teacherId)
    .update({ count: 0 });
}

function putChatMessageByParent(parentId, message, nodeKey, urls, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var messageDate = new Date();

  let studentData = firebase.student;

  let createdAt = {
    date: messageDate.getDate(),
    day: messageDate.getDay(),
    hours: messageDate.getHours(),
    minutes: messageDate.getMinutes(),
    month: messageDate.getMonth(),
    seconds: messageDate.getSeconds(),
    time: messageDate.getTime(),
    timezoneOffset: messageDate.getTimezoneOffset(),
    year: messageDate.getFullYear(),
  };

  rsf.ref(branchPath + "/conversations/" + parentId + "/" + nodeKey).update({
    createdAt: createdAt,
    id: parentId,
    name:
      parentId === studentData.fatherProfileId
        ? studentData.fatherName
        : studentData.motherName,
    inversdate: -Math.abs(messageDate.getTime()),
    senderId: parentId,
    status: "Sent",
    imageUrl: urls.length > 0 ? urls[0] : null,
    text: message,
  });
}

function updateUnreadCountByParent(
  chatListObject,
  parentId,
  message,
  firebase
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var count = 0;
  if (
    chatListObject !== undefined &&
    chatListObject.teacherUnreadMessageCount !== undefined
  ) {
    count = chatListObject.teacherUnreadMessageCount;
    count = count + 1;
  } else {
    count = count + 1;
  }

  var newChatListObject = {
    chatListModel: chatListObject.chatListModel
      ? chatListObject.chatListModel
      : null,
    inverseDate: -moment().valueOf(),
    lastMessage: message,
    lastSenderId: parentId,
    parentUnreadMessageCount: 0,
    teacherUnreadMessageCount: count,
  };

  rsf.ref(branchPath + "/chatList/" + parentId).update(newChatListObject);
}

function resetParentUnreadMessageInChatlist(
  parentId,
  chatListObject,
  firebase
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let newChatListObject = chatListObject;
  newChatListObject.parentUnreadMessageCount = 0;
  rsf.ref(branchPath + "/chatList/" + parentId).update(newChatListObject);
}

function updateTeacherUnreadKey(parentId, teacherId, obj, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/unreadKey/" + parentId + "/" + teacherId).update(obj);
}

function getParentSideChats(parentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let messageRef = rsf
    .ref(branchPath + "/conversations/" + parentId)
    .orderByChild("inversdate");
  return eventChannel((emit) => {
    messageRef.on("value", (snap) => {
      var x = [];
      snap.forEach((item) => {
        if (item.val() !== null) {
          x.push(item.val());
        }
      });
      // var x = [];
      // snap.forEach(item => {
      //     if (item.val() !== null) {
      //         let data = {};
      //         let dataUri = {};
      //         data.position = item.val().senderId === "school" ? "right" : "left";
      //         data.type = item.val().imageUrl ? "photo" : "text";
      //         data.text = item.val().text;
      //         data.date = new Date(Math.abs(item.val().inversdate));
      //         data.name = item.val().name ? (item.val().senderId === "school" ? "School" : item.val().name) : "";

      //         if (item.val().imageUrl) {
      //             dataUri = {
      //                 uri: item.val().imageUrl,
      //                 status: {
      //                     click: true,
      //                     loading: 0,
      //                 }
      //             }
      //         }

      //         if (dataUri.uri) {
      //             data.data = dataUri;
      //         }
      //         x.push(data);
      //     }
      // })
      emit(x);
    });
    return () => {
      messageRef.off();
      console.log("unsubscribing query");
    };
  });
}

function createChatListByParent(parentId, message, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let student = firebase.student;
  let parentName;
  if (student.motherProfileId && student.motherProfileId === parentId) {
    parentName = student.motherName;
  } else if (student.fatherProfileId && student.fatherProfileId === parentId) {
    parentName = student.fatherName;
  }

  let chatListModel = {
    id: parentId,
    inverseDate: 0,
    name: parentName,
    parentunreadMessageCount: 0,
    relation: "Mother",
    studentName: student.name,
    unreadMessageCount: 0,
    studentId: student.id,
  };

  var newChatListObject = {
    chatListModel: chatListModel,
    inverseDate: -moment().valueOf(),
    lastMessage: message,
    lastSenderId: parentId,
    parentUnreadMessageCount: 0,
    teacherUnreadMessageCount: 1,
  };

  rsf.ref(branchPath + "/chatList/" + parentId).update(newChatListObject);
}

function getStaffChatList(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let teacher = firebase.teacher;
  let teacherId = teacher.id;

  let messageRef = rsf.ref(branchPath + "/teacherChatList/" + teacherId);
  return eventChannel((emit) => {
    messageRef.on("value", (snap) => {
      let x = [];
      snap.forEach((item) => {
        if (item.val() !== null) {
          x.push(item.val());
        }
      });
      emit(x);
    });
    return () => {
      messageRef.off();
      console.log("unsubscribing staff query");
    };
  });
}

function getTeacherChats(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let messageRef = rsf
    .ref(branchPath + "/teacherConversationsReference/" + id)
    .orderByChild("inverseDate");

  return eventChannel((emit) => {
    messageRef.on("value", (snap) => {
      var x = [];
      snap.forEach((item) => {
        if (item.val() !== null) {
          x.push(item.val());
        }
      });
      emit(x);
    });
    return () => {
      messageRef.off();
      console.log("unsubscribing query");
    };
  });
}

function getTeacherConversationUniqueNodeId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/teacherConversationsReference").push().key;
  return key;
}

function putStaffChatMessage(
  nodeKey,
  teacherId,
  message,
  uniqueNode,
  urls,
  firebase
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var messageDate = new Date();

  var singleMessage = message[0];

  let createdAt = {
    date: messageDate.getDate(),
    day: messageDate.getDay(),
    hours: messageDate.getHours(),
    minutes: messageDate.getMinutes(),
    month: messageDate.getMonth(),
    seconds: messageDate.getSeconds(),
    time: messageDate.getTime(),
    timezoneOffset: messageDate.getTimezoneOffset(),
    year: messageDate.getFullYear(),
  };

  rsf
    .ref(
      branchPath +
        "/teacherConversationsReference/" +
        nodeKey +
        "/" +
        uniqueNode
    )
    .update({
      createdAt: createdAt,
      id: firebase.teacher.id,
      name: firebase.teacher.name,
      inversdate: -Math.abs(messageDate.getTime()),
      senderId: teacherId,
      status: "Sent",
      imageUrl: urls.length > 0 ? urls[0] : null,
      text: singleMessage,
      read: false,
    });
}

function updateStaffChatListModal(senderId, receiverId, chatObj, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/teacherChatList/" + senderId + "/" + receiverId)
    .update(chatObj);
}

function getTeacherChatListById(teacherId, senderId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var messageRef = rsf.ref(
    branchPath + "/teacherChatList/" + teacherId + "/" + senderId
  );

  let chatVal = {};
  let promise = new Promise(function(resolve, reject) {
    messageRef.once("value").then(function(snapshot) {
      if (snapshot.val() !== null) {
        chatVal = snapshot.val();
      }
      resolve(chatVal);
    });
  });
  return promise;
}

function setReceiverReadStatus(teacherId, senderId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var messageRef = rsf.ref(
    branchPath + "/teacherChatList/" + teacherId + "/" + senderId
  );
  messageRef.update({ read: true });
}

function setSenderUnreadCount(teacherId, senderId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var messageRef = rsf.ref(
    branchPath + "/teacherChatList/" + teacherId + "/" + senderId
  );
  messageRef.update({ unreadCount: 0 });
}

export const QueryApi = {
  getParentChatList,
  getUnreadMessageKey,
  resetTeacherUnread,
  getParentChats,
  createChatNode,
  putChatMessage,
  getChatListOfSelectedParent,
  updateUnreadCount,
  resetTeacherUnreadMessageCount,
  resetUnreadMessageKey,
  putChatMessageByParent,
  updateUnreadCountByParent,
  resetParentUnreadMessageInChatlist,
  updateTeacherUnreadKey,
  getParentSideChats,
  createChatListByParent,
  getStaffChatList,
  getTeacherChats,
  getTeacherConversationUniqueNodeId,
  putStaffChatMessage,
  updateStaffChatListModal,
  getTeacherChatListById,
  setReceiverReadStatus,
  setSenderUnreadCount,
};
