const prodConfig = 
{
  apiKey: "AIzaSyA6_fms1k2M_xeyAvruFkD2DzTy9SXelN4",
  authDomain: "masterproject-a832a.firebaseapp.com",
  databaseURL: "https://masterproject-a832a.firebaseio.com",
  projectId: "masterproject-a832a",
  storageBucket: "masterproject-a832a.appspot.com",
  messagingSenderId: "256844178257",
  appId: "1:256844178257:web:42af49209473c426a5e970",
  measurementId: "G-1076Y449TH"
};
const devConfig = {
  clientId:
    "149065016378-cir02n45ivnh1dnmtv51s62pvggee5cp.apps.googleusercontent.com",
  appId: "1:149065016378:android:5a5663308df29da5",
  apiKey: "AIzaSyAiPFxGnXpF0miuDHjQWND5XLrlWJ_c8pI",
  databaseURL: "https://masterdevv1.firebaseio.com",
  storageBucket: "masterdevv1.appspot.com",
  messagingSenderId: "149065016378",
  projectId: "masterdevv1",
  authDomain: "masterdevv1.firebaseapp.com"
};


export const devEndPointUrl =
  "https://us-central1-masterdevv1.cloudfunctions.net/";
export const prodEndPointUrl =
  "https://us-central1-masterproject-a832a.cloudfunctions.net/";

export const zoomDevEndPointUrl =
  "https://zoom.us/oauth/authorize?client_id=KcOoMa1TSCUAAzFU9kuQ&response_type=code&redirect_uri=https%3A%2F%2Fdev.illumine.app%2Fdashboard%2FzoomConfiguration";
export const zoomProdEndPointUrl =
  "https://zoom.us/oauth/authorize?response_type=code&client_id=X0oDqkRWQ6ix5CT0_CCKpA&redirect_uri=https://school.illumine.app/dashboard/zoomConfiguration";
export const zoomGetReadyEndPointUrl =
  "https://zoom.us/oauth/authorize?response_type=code&client_id=X0oDqkRWQ6ix5CT0_CCKpA&redirect_uri=https://class.preschoolfromhome.com/dashboard/zoomConfiguration";

export const zoomEndPointUrl =
  process.env.REACT_APP_BUILD_ENV === "production"
    ? zoomProdEndPointUrl
    : (process.env.REACT_APP_BUILD_ENV === "getready" ? zoomGetReadyEndPointUrl : zoomDevEndPointUrl);

export const endPointUrl =
  process.env.REACT_APP_BUILD_ENV === "production"
    ? prodEndPointUrl
    : (process.env.REACT_APP_BUILD_ENV === "getready" ? prodEndPointUrl : devEndPointUrl);

const zoomDevMeetingUrl =
  "http://dev.illumine.app/zoom.html"
const zoomProdMeetingUrl =
  "http://school.illumine.app/zoom.html"
const zoomGetReadyMeetingUrl =
  "http://class.preschoolfromhome.com/zoom.html"


export const zoomMeetingUrl =
  process.env.REACT_APP_BUILD_ENV === "production"
    ? zoomProdMeetingUrl
    : (process.env.REACT_APP_BUILD_ENV === "getready" ? zoomGetReadyMeetingUrl : zoomDevMeetingUrl);


export const zoomDevLeaveUrl =
  "http://dev.illumine.app/"
export const zoomProdLeaveUrl =
  "http://school.illumine.app/"
export const zoomGetReadyLeaveUrl =
  "http://class.preschoolfromhome.com/"


export const zoomLeaveUrl = process.env.REACT_APP_BUILD_ENV === "production"
  ? zoomProdLeaveUrl
  : (process.env.REACT_APP_BUILD_ENV === "getready" ? zoomGetReadyLeaveUrl : zoomDevLeaveUrl);
export const firebaseConfig =
  process.env.REACT_APP_BUILD_ENV === "production" ? prodConfig : (process.env.REACT_APP_BUILD_ENV === "getready" ? prodConfig : devConfig);



const devRedirectUrl = 'https://dev.illumine.app/dashboard/zoomConfiguration'
const getReadyRedirectUrl = 'https://class.preschoolfromhome.com/dashboard/zoomConfiguration'
const prodRedirectUrl = 'https://school.illumine.app/dashboard/zoomConfiguration'

export const redirectUrl = process.env.REACT_APP_BUILD_ENV === "production"
  ? prodRedirectUrl
  : (process.env.REACT_APP_BUILD_ENV === "getready" ? getReadyRedirectUrl : devRedirectUrl);