import actions from './actions';

const initState = {
  curriculums: [],
  initialCurriculums: false,
  isLoading: false,
  addRequestSucessful: false,
  updateRequestSucessful: false,
  toggleRequestSuccessful: false
};

export default function curriculumReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.LIST_CURRICULUMS:
      return {
        ...state,
        isLoading: true
      };
    case actions.LIST_CURRICULUM:
      return {
        ...state,
        curriculums: action.curriculums,
        initialCurriculums: true,
        isLoading: false
      };
      case actions.ADD_CURRICULUM:
      return {
        ...state,
        addRequestSucessful: false,
        isLoading: true
      };
      case actions.ADD_CURRICULUM_SUCCESFUL:
      return {
        ...state,
        addRequestSucessful: true,
        isLoading: false
      };
      case actions.UPDATE_CURRICULUM:
      return {
        ...state,
        updateRequestSucessful: false,
        isLoading: true
      };
      case actions.UPDATE_CURRICULUM_SUCCESSFUL:
      return {
        ...state,
        updateRequestSucessful: true,
        isLoading: false
      };
      case actions.TOGGLE_CURRICULUM:
      return {
        ...state,
        toggleRequestSucessful: false,
        isLoading: true
      };
      case actions.TOGGLE_CURRICULUM_SUCCESSFUL:
      return {
        ...state,
        toggleRequestSucessful: true,
        isLoading: false
      };
    default:
      return state;
  }
}
