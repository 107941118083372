const actions = {
    GET_SCHOOL_DETAILS: 'GET_SCHOOL_DETAILS',
    GET_SCHOOL_DETAILS_SUCCESSFUL: 'GET_SCHOOL_DETAILS_SUCCESSFUL',

    SAVE_SCHOOL_DETAILS: "SAVE_SCHOOL_DETAILS",
    SAVE_SCHOOL_DETAILS_SUCCESSFUL: "SAVE_SCHOOL_DETAILS_SUCCESSFUL",

    SCHOOL_DETAILS_REQUEST_FAILED: 'SCHOOL_DETAILS_REQUEST_FAILED',

    getSchoolDetails: (firebase) => ({
        type: actions.GET_SCHOOL_DETAILS,
        firebase
    }),

    saveSchoolDetails: (values, firebase) => ({
        type: actions.SAVE_SCHOOL_DETAILS,
        values,
        firebase
    })
}
export default actions;