import "@firebase/firestore"; // 👈 If you're using firestore
import {
  all,
  call,
  put,
  takeLatest,
  takeEvery,
  take,
  fork,
} from "redux-saga/effects";
import { HolidayApi } from "../../firestore-api/holiday";
import { NotificationApi } from "../../firestore-api/notification";
import actions from "./actions";
import bugsnagClient from "@bugsnag/js";
import moment from "moment-timezone";

function* fetchAllHolidays({ firebase, initialCall }) {
  const chan = yield call(HolidayApi.getHolidayList, firebase);
  try {
    while (true) {
      const data = yield take(chan);
      console.log("holiday chan data ------", data);
      let holidayList = data[0];
      let holidayClassMapArray = data[1];
      console.log("holidayClassMapArray --", holidayClassMapArray);

      yield put({
        type: actions.GET_ALL_HOLIDAYS_SUCCESSFUL,
        holidayList: holidayList,
        holidayClassMapArray: holidayClassMapArray,
        holidaysChannel: chan,
        operationType: initialCall ? "INITIAL_CALL" : undefined,
      });
    }
  } finally {
    console.log("end holidays channel");
  }
}

function* fetchAllClassrooms({ firebase }) {
  try {
    let data = JSON.parse(localStorage.getItem("classList"));
    if (data) {
      yield put({
        type: actions.GET_ALL_CLASSROOMS_FOR_HOLIDAY_SUCCESSFUL,
        classrooms: data,
      });
    }
  } catch (error) {
    console.log("failed to fetch classrooms", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.HOLIDAY_REQUEST_FAILED,
    });
  }
}

function* addNewHoliday({ formValue, firebase, classrooms }) {
  let startDate = moment(formValue.dateRange[0])
    .startOf("day")
    .valueOf();
  let modStartDate = new Date(startDate);

  let lastDate = moment(formValue.dateRange[1])
    .endOf("day")
    .valueOf();
  let modEndDate = new Date(lastDate);

  let endDate;
  let fromDate;

  fromDate = {
    date: modStartDate.getDate(),
    day: modStartDate.getDay(),
    hours: modStartDate.getHours(),
    minutes: modStartDate.getMinutes(),
    month: modStartDate.getMonth(),
    seconds: modStartDate.getSeconds(),
    time: modStartDate.getTime(),
    timezoneOffset: modStartDate.getTimezoneOffset(),
    year: modStartDate.getFullYear() - 1900,
  };

  if (modEndDate !== undefined && modEndDate !== modStartDate) {
    endDate = {
      date: modEndDate.getDate(),
      day: modEndDate.getDay(),
      hours: modEndDate.getHours(),
      minutes: modEndDate.getMinutes(),
      month: modEndDate.getMonth(),
      seconds: modEndDate.getSeconds(),
      time: modEndDate.getTime(),
      timezoneOffset: modEndDate.getTimezoneOffset(),
      year: modEndDate.getFullYear() - 1900,
    };
  }
  try {
    let classToBeUpdated = [];
    if (formValue.classrooms.includes("All Classrooms")) {
      classToBeUpdated.push("All Classrooms");
      classrooms.map((c) => {
        classToBeUpdated.push(c.className);
      });
    } else {
      classToBeUpdated = formValue.classrooms;
    }

    for (let i = 0; i < classToBeUpdated.length; i++) {
      let holidayObj = {};

      let key = yield call(HolidayApi.createHolidayNode, firebase);
      holidayObj = {
        classroomName: classToBeUpdated[i],
        endDate: endDate,
        fromDate: fromDate,
        id: key,
        name: formValue.name,
        timestamp: modEndDate.getTime(),
      };

      yield fork(
        HolidayApi.addHoliday,
        classToBeUpdated[i],
        key,
        holidayObj,
        firebase
      );

      yield fork(
        sendHolidayNotification,
        classToBeUpdated[i],
        firebase,
        "add",
        formValue.name,
        startDate,
        key
      );
    }
    yield put({
      type: actions.ADD_HOLIDAY_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to add holiday", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.HOLIDAY_REQUEST_FAILED,
    });
  }
}

function* updateExistingHoliday({
  formValue,
  selectedRecord,
  classrooms,
  holidayClassMapArray,
  firebase,
}) {
  try {
    let selectedHoliday = selectedRecord;
    let allClassMap = holidayClassMapArray;

    let formClassrooms = [];
    if (formValue.classrooms.includes("All Classrooms")) {
      formClassrooms.push("All Classrooms");
      classrooms.map((c) => {
        formClassrooms.push(c.className);
      });
    } else {
      formClassrooms = formValue.classrooms;
    }

    let startDate = moment(formValue.dateRange[0])
      .startOf("day")
      .valueOf();
    let modStartDate = new Date(startDate);

    let lastDate = moment(formValue.dateRange[1])
      .endOf("day")
      .valueOf();
    let modEndDate = new Date(lastDate);

    let endDate;
    let fromDate;

    fromDate = {
      date: modStartDate.getDate(),
      day: modStartDate.getDay(),
      hours: modStartDate.getHours(),
      minutes: modStartDate.getMinutes(),
      month: modStartDate.getMonth(),
      seconds: modStartDate.getSeconds(),
      time: modStartDate.getTime(),
      timezoneOffset: modStartDate.getTimezoneOffset(),
      year: modStartDate.getFullYear() - 1900,
    };

    if (modEndDate !== undefined && modEndDate !== modStartDate) {
      endDate = {
        date: modEndDate.getDate(),
        day: modEndDate.getDay(),
        hours: modEndDate.getHours(),
        minutes: modEndDate.getMinutes(),
        month: modEndDate.getMonth(),
        seconds: modEndDate.getSeconds(),
        time: modEndDate.getTime(),
        timezoneOffset: modEndDate.getTimezoneOffset(),
        year: modEndDate.getFullYear() - 1900,
      };
    }

    let classMap = allClassMap;
    for (let [key, val] of classMap) {
      if (formClassrooms.includes(key)) {
        let mapValue = val;
        if (mapValue) {
          for (let k in mapValue) {
            if (
              mapValue[k].name === selectedHoliday.name &&
              mapValue[k].fromDate.time === selectedHoliday.fromDate.time
            ) {
              let holidayObj = {};

              let id = mapValue[k].id;
              holidayObj = {
                classroomName: key,
                endDate: endDate,
                fromDate: fromDate,
                id: id,
                name: formValue.name,
                timestamp: modEndDate.getTime(),
              };

              yield fork(HolidayApi.addHoliday, key, id, holidayObj, firebase);

              yield fork(
                sendHolidayNotification,
                key,
                firebase,
                "update",
                formValue.name,
                startDate,
                id
              );
            }
          }
        } else {
          let id = yield call(HolidayApi.createHolidayNode, firebase);
          let holidayObj = {
            classroomName: key,
            endDate: endDate,
            fromDate: fromDate,
            id: id,
            name: formValue.name,
            timestamp: modEndDate.getTime(),
          };

          yield fork(HolidayApi.addHoliday, key, id, holidayObj, firebase);
          yield fork(
            sendHolidayNotification,
            key,
            firebase,
            "update",
            formValue.name,
            startDate,
            id
          );
        }
      }
    }
    yield put({
      type: actions.UPDATE_HOLIDAY_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to update holiday", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.HOLIDAY_REQUEST_FAILED,
    });
  }
}

function* deleteExistingHoliday({
  selectedRecord,
  holidayClassMapArray,
  firebase,
  deleteAll,
}) {
  try {
    if (!deleteAll) {
      // yield call(
      //   HolidayApi.deleteHoliday,
      //   selectedRecord.classroomName,
      //   selectedRecord.id,
      //   firebase
      // );
      let selectedHoliday = selectedRecord;
      let classMap = holidayClassMapArray;
      for (let [key, val] of classMap) {
        console.log(key);
        let mapValue = val;
        for (let k in mapValue) {
          if (
            mapValue[k].name === selectedHoliday.name &&
            mapValue[k].fromDate.time === selectedHoliday.fromDate.time
          ) {
            yield call(
              HolidayApi.deleteHoliday,
              mapValue[k].classroomName,
              mapValue[k].id,
              firebase
            );
          }
        }
      }
      yield put({
        type: actions.DELETE_HOLIDAY_SUCCESS,
      });
    } else if (deleteAll) {
      let selectedHoliday = selectedRecord;
      let allClassMap = holidayClassMapArray;

      let classMap = allClassMap;
      for (let [key, val] of classMap) {
        console.log(key);
        let mapValue = val;
        for (let k in mapValue) {
          if (
            mapValue[k].name === selectedHoliday.name &&
            mapValue[k].fromDate.time === selectedHoliday.fromDate.time
          ) {
            yield call(
              HolidayApi.deleteHoliday,
              mapValue[k].classroomName,
              mapValue[k].id,
              firebase
            );
          }
        }
      }

      yield put({
        type: actions.DELETE_HOLIDAY_SUCCESS,
      });
    }
  } catch (err) {
    console.log("failed to delete holiday ", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.HOLIDAY_REQUEST_FAILED,
    });
  }
}

function* sendHolidayNotification(
  className,
  firebase,
  operationType,
  holidayName,
  holidayStart,
  activityId
) {
  let message;
  if (operationType === "add") {
    message =
      "Holiday added: " +
      holidayName +
      " : " +
      moment(new Date(holidayStart)).format("DD[, ]MMMM[, ]YYYY");
  } else if (operationType === "update") {
    message =
      "Holiday updated: " +
      holidayName +
      " : " +
      moment(new Date(holidayStart)).format("DD[, ]MMMM[, ]YYYY");
  }

  let selectedActivity = "Holiday";
  let allStudents = JSON.parse(localStorage.getItem("studentList"));
  let selectedStudent = allStudents.filter((s) => {
    return s.classroomName === className;
  });

  if (selectedStudent !== undefined && selectedStudent.length > 0) {
    for (let i = 0; i < selectedStudent.length; i++) {
      if (selectedStudent[i].fatherProfileId) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          selectedStudent[i].fatherProfileId,
          firebase
        );
        yield fork(
          NotificationApi.createAlertNotification,
          selectedActivity,
          activityId,
          selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
          message,
          alertNode,
          selectedStudent[i].ios_fatherUUid
            ? selectedStudent[i].ios_fatherUUid
            : null,
          selectedStudent[i].id,
          selectedStudent[i].fatherProfileId,
          firebase
        );

        if (
          selectedStudent[i].fatherUUid !== undefined ||
          selectedStudent[i].ios_fatherUUid !== undefined
        ) {
          yield fork(
            NotificationApi.sendPushNotification,
            selectedActivity,
            activityId,
            selectedStudent[i].fatherUUid
              ? selectedStudent[i].fatherUUid
              : null,
            message,
            alertNode,
            selectedStudent[i].ios_fatherUUid
              ? selectedStudent[i].ios_fatherUUid
              : null,
            selectedStudent[i].id,
            selectedStudent[i].fatherProfileId,
            firebase
          );
        }
      }

      if (selectedStudent[i].motherProfileId) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          selectedStudent[i].motherProfileId,
          firebase
        );
        yield fork(
          NotificationApi.createAlertNotification,
          selectedActivity,
          activityId,
          selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
          message,
          alertNode,
          selectedStudent[i].ios_motherUUid
            ? selectedStudent[i].ios_motherUUid
            : null,
          selectedStudent[i].id,
          selectedStudent[i].motherProfileId,
          firebase
        );

        if (
          selectedStudent[i].motherUUid !== undefined ||
          selectedStudent[i].ios_motherUUid !== undefined
        ) {
          yield fork(
            NotificationApi.sendPushNotification,
            selectedActivity,
            activityId,
            selectedStudent[i].motherUUid
              ? selectedStudent[i].motherUUid
              : null,
            message,
            alertNode,
            selectedStudent[i].ios_motherUUid
              ? selectedStudent[i].ios_motherUUid
              : null,
            selectedStudent[i].id,
            selectedStudent[i].motherProfileId,
            firebase
          );
        }
      }
    }
  }
}

export default function* rootSaga() {
  yield all([yield takeLatest(actions.GET_ALL_HOLIDAYS, fetchAllHolidays)]);
  yield all([
    yield takeEvery(actions.GET_ALL_CLASSROOMS_FOR_HOLIDAY, fetchAllClassrooms),
    yield takeLatest(actions.ADD_HOLIDAY, addNewHoliday),
    yield takeLatest(actions.UPDATE_HOLIDAY, updateExistingHoliday),
    yield takeLatest(actions.DELETE_HOLIDAY, deleteExistingHoliday),
  ]);
}
