const actions = {
    LIST_ASSESSMENT_MILESTONES: 'LIST_ASSESSMENT_MILESTONES',
    LIST_ASSESSMENT_MILESTONES_SUCCESSFUL: 'LIST_ASSESSMENT_MILESTONES_SUCCESSFUL',
    GENERATE_STUDENT_ASSESSMENT_NODE_ID: 'GENERATE_STUDENT_ASSESSMENT_NODE_ID',
    GENERATE_STUDENT_ASSESSMENT_NODE_ID_SUCCESSFUL: 'GENERATE_STUDENT_ASSESSMENT_NODE_ID_SUCCESSFUL',
    SAVE_SELECTED_DOMAIN_TO_STUDENT_ASSESSMENT: 'SAVE_SELECTED_DOMAIN_TO_STUDENT_ASSESSMENT',
    SAVE_SELECTED_DOMAIN_TO_STUDENT_ASSESSMENT_SUCCESSFUL: 'SAVE_SELECTED_DOMAIN_TO_STUDENT_ASSESSMENT_SUCCESSFUL',
    SAVE_SELECTED_MILESTONE_TO_STUDENT_ASSESSMENT: 'SAVE_SELECTED_MILESTONE_TO_STUDENT_ASSESSMENT',
    SAVE_SELECTED_MILESTONE_TO_STUDENT_ASSESSMENT_SUCCESSFUL: 'SAVE_SELECTED_MILESTONE_TO_STUDENT_ASSESSMENT_SUCCESSFUL',
    GET_LABELS_FOR_ASSESSMENT: 'GET_LABELS_FOR_ASSESSMENT',
    GET_LABELS_FOR_ASSESSMENT_SUCCESSFFUL: 'GET_LABELS_FOR_ASSESSMENT_SUCCESSFFUL',
    GET_STUDENT_ASSESSMENT: 'GET_STUDENT_ASSESSMENT',
    GET_STUDENT_ASSESSMENT_SUCCESSFUL: 'GET_STUDENT_ASSESSMENT_SUCCESSFUL',
    STUDENT_ASSESSMENT_REQUEST_FAILED: 'STUDENT_ASSESSMENT_REQUEST_FAILED',
    SAVE_STUDENT_ASSESSMENT: "SAVE_STUDENT_ASSESSMENT",
    SAVE_STUDENT_ASSESSMENT_SUCCESSFUL: "SAVE_STUDENT_ASSESSMENT_SUCCESSFUL",
    RESET_OPERATION_TYPE: "RESET_OPERATION_TYPE",

    GET_SAVED_ASSESSMENT_RECORD: "GET_SAVED_ASSESSMENT_RECORD",
    GET_SAVED_ASSESSMENT_RECORD_SUCCESS: "GET_SAVED_ASSESSMENT_RECORD_SUCCESS",

    SAVE_STUDENT_ASSESSMENT_NOTE: "SAVE_STUDENT_ASSESSMENT_NOTE",
    SAVE_STUDENT_ASSESSMENT_NOTE_SUCCESS: "SAVE_STUDENT_ASSESSMENT_NOTE_SUCCESS",

    SAVE_STUDENT_ASSESSMENT_ATTACHMENT: "SAVE_STUDENT_ASSESSMENT_ATTACHMENT",
    SAVE_STUDENT_ASSESSMENT_ATTACHMENT_SUCCESS: "SAVE_STUDENT_ASSESSMENT_ATTACHMENT_SUCCESS",

    GET_STD_ASS_DEV_AREAS: "GET_STD_ASS_DEV_AREAS",
    GET_STD_ASS_DEV_AREAS_SUCCESS: "GET_STD_ASS_DEV_AREAS_SUCCESS",

    RESET_SAVED_ASSESSMENT_ID: "RESET_SAVED_ASSESSMENT_ID",

    DELETE_SAVED_ASSESSMENT: "DELETE_SAVED_ASSESSMENT",
    DELETE_SAVED_ASSESSMENT_SUCCESS: "DELETE_SAVED_ASSESSMENT_SUCCESS",

    SAVE_PROGRESS_PARAM: "SAVE_PROGRESS_PARAM",
    SAVE_PROGRESS_PARAM_SUCCESS: "SAVE_PROGRESS_PARAM_SUCCESS",

    GET_PROGRESS_PARAM: "GET_PROGRESS_PARAM",
    GET_PROGRESS_PARAM_SUCCESS: "GET_PROGRESS_PARAM_SUCCESS",

    DELETE_PROGRESS_PARAM: "DELETE_PROGRESS_PARAM",
    DELETE_PROGRESS_PARAM_SUCCESS: "DELETE_PROGRESS_PARAM_SUCCESS",

    RESET_LAST_ASSESSMENT_FETCHED: "RESET_LAST_ASSESSMENT_FETCHED",

    SAVE_ASSESSMENT_TITLE: "SAVE_ASSESSMENT_TITLE",
    SAVE_ASSESSMENT_TITLE_SUCCESS: "SAVE_ASSESSMENT_TITLE_SUCCESS",




    getAssessmentMilestones: (firebase, assessmentMilestones) => ({
        type: actions.LIST_ASSESSMENT_MILESTONES,
        firebase,
        assessmentMilestones,
    }),
    generateStudentAssessmentNodeId: (firebase) => ({
        type: actions.GENERATE_STUDENT_ASSESSMENT_NODE_ID,
        firebase
    }),

    saveSelectedDomainToStudentAssessment: (selectedStatus, selectedDomain, selectedStudent, uniqueNodeId, assessmentMilestones, firebase) => ({
        type: actions.SAVE_SELECTED_DOMAIN_TO_STUDENT_ASSESSMENT,
        selectedStatus,
        selectedDomain,
        selectedStudent,
        uniqueNodeId,
        assessmentMilestones,
        firebase
    }),

    updateSelectedDomainToStudentAssessment: (selectedStatus, selectedDomain, secondaryItem, selectedStudent, assessmentMilestones, uniqueNodeId, firebase) => ({
        type: actions.SAVE_SELECTED_MILESTONE_TO_STUDENT_ASSESSMENT,
        selectedStatus,
        selectedDomain,
        secondaryItem,
        selectedStudent,
        assessmentMilestones,
        uniqueNodeId,
        firebase
    }),

    getLabel: (firebase) => ({
        type: actions.GET_LABELS_FOR_ASSESSMENT,
        firebase
    }),

    getStudentAssessment: (studentId, savedAssessmentId, firebase) => ({
        type: actions.GET_STUDENT_ASSESSMENT,
        studentId,
        savedAssessmentId,
        firebase
    }),

    saveChildAssessment: (assessmentObj, firebase, savedAssessmentId) => ({
        type: actions.SAVE_STUDENT_ASSESSMENT,
        assessmentObj,
        firebase,
        savedAssessmentId
    }),

    resetOperationType: () => ({
        type: actions.RESET_OPERATION_TYPE
    }),

    getSavedAssessments: (studentId, firebase) => ({
        type: actions.GET_SAVED_ASSESSMENT_RECORD,
        studentId,
        firebase
    }),

    saveStudentAssessmentNotes: (assessment, firebase, studentId, savedAssessmentId) => ({
        type: actions.SAVE_STUDENT_ASSESSMENT_NOTE,
        assessment,
        firebase,
        studentId,
        savedAssessmentId
    }),

    saveStudentAssessmentAttachment: (fileList, allMilestones, selectedItem, firebase, selectedType, mainIndex, studentId, savedAssessmentId) => ({
        type: actions.SAVE_STUDENT_ASSESSMENT_ATTACHMENT,
        fileList,
        allMilestones,
        selectedItem,
        firebase,
        selectedType,
        mainIndex,
        studentId,
        savedAssessmentId
    }),

    getAssessmentDevAreas: (firebase) => ({
        type: actions.GET_STD_ASS_DEV_AREAS,
        firebase
    }),

    resetSavedAssessmentId: () => ({
        type: actions.RESET_SAVED_ASSESSMENT_ID
    }),

    deleteSavedAssessment: (record, firebase) => ({
        type: actions.DELETE_SAVED_ASSESSMENT,
        record,
        firebase
    }),

    saveProgressParam: (name, firebase) => ({
        type: actions.SAVE_PROGRESS_PARAM,
        name,
        firebase
    }),

    getProgressParam: (firebase) => ({
        type: actions.GET_PROGRESS_PARAM,
        firebase
    }),

    deleteProgressParam: (record, firebase) => ({
        type: actions.DELETE_PROGRESS_PARAM,
        record,
        firebase
    }),

    resetLastAssessment: () => ({
        type: actions.RESET_LAST_ASSESSMENT_FETCHED
    }),

    savedAssessmentTitle: (record, firebase) => ({
        type: actions.SAVE_ASSESSMENT_TITLE,
        record,
        firebase
    })
}
export default actions;