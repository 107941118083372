import actions from "./actions";
const initState = {
    complains: [],
    complainsChannel: undefined,
    isLoading: false,
    operationType: undefined,
    error: false,
    complaintComment: new Map(),
    pendingQueries: {}
};

export default function complainsReducer(
    state = initState,
    { type, ...action }
) {
    switch (type) {
        case actions.GET_ALL_COMPLAINS:
            return {
                ...state,
                isLoading: true,
                operationType: undefined,
                complains: []
            };
        case actions.GET_ALL_COMPLAINS_SUCCESSFUL:
            return {
                ...state,
                complains: action.complains,
                complainsChannel: action.complainsChannel,
                isLoading: false,
                operationType: action.operationType
            };

        case actions.GET_NOTIFICATION_COMPLAINT:
            return {
                ...state,
                isLoading: true,
                operationType: undefined,
                complains: []
            };

        case actions.GET_COMMENT_FOR_COMPLAIN:
            return {
                ...state,
                operationType: undefined
            };
        case actions.GET_COMMENT_FOR_COMPLAIN_SUCCESSFUL:
            return {
                ...state,
                complaintComment: action.complaintComment,
            };

        case actions.COMPLAIN_REQUEST_FAILED:
            return {
                ...state,
                error: true,
                isLoading: false,
            };

        case actions.RESOLVE_COMPLAINT:
            return {
                ...state,
                isLoading: true,
                operationType: undefined
            };
        case actions.RESOLVE_COMPLAINT_SUCCESSFUL:
            return {
                ...state,
                operationType: undefined,
                isLoading: false,
            };

        case actions.SAVE_COMPLAINT_COMMENT:
            return {
                ...state,
                isLoading: true,
                operationType: undefined
            };
        case actions.SAVE_COMPLAINT_COMMENT_SUCCESSFUL:
            return {
                ...state,
                operationType: "COMMENT_SAVED",
                isLoading: false,
            };

        case actions.RESET_COMPLAINT_OPERATION_TYPE:
            return {
                ...state,
                operationType: undefined,
                isLoading: false,
            };

        case actions.GET_PENDING_QUERIES:
            return {
                ...state,
                isLoading: true,
                operationType: undefined
            };
        case actions.GET_PENDING_QUERIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                pendingQueries: action.pendingQueries
            };

        default:
            return state;
    }
}