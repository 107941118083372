import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment-timezone";
import { eventChannel } from "redux-saga";
import bugsnagClient from "@bugsnag/js";
import PermissionStrings from "../Utility/PermissionStrings";
const superagent = require("superagent");

function createAlertReferenceNode(userId, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var key = rsf.ref(branchPath + "/alertReference/" + userId).push().key;
  return key;
}

function createAlertNotification(
  activityName,
  activityNodeId,
  parentUid,
  bodyMessage,
  nodeId,
  parentIosUid,
  studentId,
  parentProfileId,
  firebase,
  bPath,
  planShareDate,
  planStartDate,
  planEndDate,
  lessonId
) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  let type;
  if (activityName === "Attendance") {
    type = "Attendance";
  } else if (activityName === "Events") {
    type = "Events";
  } else if (activityName === "Lesson") {
    type = "LESSON";
  } else if (activityName === "StudentLearning") {
    type = "StudentLearning";
  } else if (activityName === "Weekly lesson") {
    type = "Weekly lesson";
  } else if (activityName === "StudentLessonPlanShared") {
    type = "StudentLessonPlanShared";
  } else if (activityName === "COMMENT") {
    type = "COMMENT";
  } else if (activityName === "Payment") {
    type = "Payment";
  } else if (activityName === "Message") {
    type = "Message";
  } else if (activityName === "CONCERN") {
    type = "CONCERN";
  } else if (activityName === "LEAVE") {
    type = "LEAVE";
  } else if (activityName === "Note") {
    type = "NOTE";
  } else if (activityName === "Holiday") {
    type = "Holiday";
  } else {
    type = "Activity";
  }
  var alertObject = {};
  alertObject = {
    activityName: activityName,
    androidId: parentUid,
    body: bodyMessage,
    id: nodeId,
    inverseTime: -moment().valueOf(),
    iosId: parentIosUid,
    nodeId: activityNodeId,
    read: false,
    senderName: firebase.teacher.name,
    studentId: studentId,
    type: type,
    userType: "student",
    shareDate: planShareDate ? planShareDate : null, //lesson share date
    startDate: planStartDate ? planStartDate : null, // lesson plan start date
    endDate: planEndDate ? planEndDate : null, // lesson plan end date
    lessonId: lessonId ? lessonId : null,
  };

  rsf
    .ref(branchPath + "/alertReference/" + parentProfileId + "/" + nodeId)
    .update(alertObject);
}

function sendPushNotification(
  activityName,
  activityNodeId,
  parentUid,
  bodyMessage,
  nodeId,
  parentIosUid,
  studentId,
  parentProfileId,
  firebase,
  bPath,
  planShareDate,
  planStartDate,
  planEndDate,
  lessonId
) {
  let endPointUrl = firebase.endPointUrl;
  let sendNotifficationEndPointUrl = endPointUrl + "sendNotificationToDevice";

  let schoolConfiguration = localStorage.getItem("schoolConfig");
  let config = JSON.parse(schoolConfiguration);

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  let type;
  if (activityName === "Attendance") {
    type = "Attendance";
  } else if (activityName === "Events") {
    type = "Events";
  } else if (activityName === "Lesson") {
    type = "LESSON";
  } else if (activityName === "StudentLearning") {
    type = "StudentLearning";
  } else if (activityName === "Weekly lesson") {
    type = "Weekly lesson";
  } else if (activityName === "Payment") {
    type = "Payment";
  } else if (activityName === "Message") {
    type = "Message";
  } else if (activityName === "CONCERN") {
    type = "CONCERN";
  } else if (activityName === "LEAVE") {
    type = "LEAVE";
  } else if (activityName === "Note") {
    type = "NOTE";
  } else if (activityName === "Holiday") {
    type = "Holiday";
  } else if (activityName === "StudentLessonPlan") {
    type = "StudentLessonPlan";
  } else if (activityName === "StudentLessonPlanShared") {
    type = "StudentLessonPlanShared";
  } else {
    type = "Activity";
  }

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(sendNotifficationEndPointUrl)
      .send({
        schoolName: config.schoolName,
        schoolAdress: config.address,
        bucket: config.storageBucket,
        schoolLogoImage: config.logoUrl,
        ccemail: config.adminEmail,
        branchPath: bPath ? bPath : firebase.sbp,
        accountName: firebase.sbDbName,
        timezone: localTimezone,
        platform: "web",

        email: config.adminEmail,
        message: {
          activityName: activityName,
          androidId: parentUid,
          body: bodyMessage,
          id: nodeId,
          inverseTime: -moment().valueOf(),
          iosId: parentIosUid,
          nodeId: activityNodeId,
          read: false,
          senderName: firebase.teacher.name,
          studentId: studentId,
          type: type,
          userType: "student",
          shareDate: planShareDate ? planShareDate : null, //lesson share date
          startDate: planStartDate ? planStartDate : null, // lesson plan start date
          endDate: planEndDate ? planEndDate : null, // lesson plan end date
          lessonId: lessonId ? lessonId : null,
        },
      })
      .set("accept", "json")
      .end((err, res) => {
        //console.log("res", res.status);
        resolve(res);
      });
  });
  return p1;
}

function sendActivityEmails(
  htmlContent,
  subject,
  parentEmails,
  firebase,
  reminderTimestamps,
  parentNumbers,
  sendTextMessage
) {
  let endPointUrl = firebase.endPointUrl;
  let sendNewsletterEmailUrl = endPointUrl + "sendNewsletter ";

  let schoolConfiguration = localStorage.getItem("schoolConfig");
  let config = JSON.parse(schoolConfiguration);

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(sendNewsletterEmailUrl)
      .send({
        schoolName: firebase.schoolName,
        ccemail: config.adminEmail,
        html: htmlContent,
        subject: subject,
        parentEmails: parentEmails,
        timezone: localTimezone,
        parentNumbers: parentNumbers,
        sendTextMessage: sendTextMessage, //boolean
        reminderTimestamps: reminderTimestamps,
      })
      .set("accept", "json")

      .end((err, res) => {
        console.log("sendActivityEmails response", res);
        resolve(res);
      });
  });
  return p1;
}

function sendStats(date, endpoint, firebase, bPath) {
  let endPointUrl = firebase.endPointUrl;
  let sendNewsletterEmailUrl = endPointUrl + endpoint;

  let schoolConfiguration = localStorage.getItem("schoolConfig");
  let config = JSON.parse(schoolConfiguration);

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(sendNewsletterEmailUrl)
      .send({
        schoolName: firebase.schoolName,
        branchPath: bPath ? bPath : config.sbp,
        accountName: firebase.sbDbName,
        startdate: new Date(date).getTime(),
        timezone: localTimezone,
      })
      .set("accept", "json")

      .end((err, res) => {
        //console.log("res", res.status);
        resolve(res);
      });
  });
  return p1;
}

function getAlertNotification(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var x = [];
  let userType = "teacher";
  if (
    firebase.selectedMode &&
    firebase.selectedMode.toLowerCase() === "parent"
  ) {
    userType = "student";
  }

  // var planPromise = new Promise(function(resolve, reject) {
  //   rsf
  //     .ref(branchPath + "/alertReference/" + firebase.teacher.id)
  //     .orderByChild("inverseTime")
  //     .limitToFirst(15)
  //     .once("value")
  //     .then(function(snap) {
  //       snap.forEach((element) => {
  //         if (element.val() !== null) {
  //           if (element.val().userType) {
  //             if (
  //               element.val().userType.toLowerCase() === userType &&
  //               element.val().read === false &&
  //               element.val().activityName !== "Report" &&
  //               element.val().activityName !== "Attendance" &&
  //               element.val().activityName !== "Bus Tracking" &&
  //               element.val().activityName !== "Message" &&
  //               element.val().activityName !== "Staff_Message"
  //             ) {
  //               x.push(element.val());
  //             }
  //           } else if (
  //             element.val().read === false &&
  //             element.val().activityName !== "Report" &&
  //             element.val().activityName !== "Attendance" &&
  //             element.val().activityName !== "Bus Tracking" &&
  //             element.val().activityName !== "Message" &&
  //             element.val().activityName !== "Staff_Message"
  //           ) {
  //             x.push(element.val());
  //           }
  //         }
  //       });
  //       resolve(x);
  //     });
  // });
  // return planPromise;

  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/alertReference/" + firebase.teacher.id)
      .orderByChild("inverseTime")
      .limitToFirst(15)
      .on("value", (snap) => {
        var x = [];
        snap.forEach((element) => {
          if (element.val() !== null) {
            if (element.val().userType) {
              if (
                element.val().userType.toLowerCase() === userType &&
                element.val().read === false &&
                element.val().activityName !== "Report" &&
                element.val().activityName !== "Attendance" &&
                element.val().activityName !== "Bus Tracking" &&
                element.val().activityName !== "Message" &&
                element.val().activityName !== "Staff_Message"
              ) {
                x.push(element.val());
              }
            } else if (
              element.val().read === false &&
              element.val().activityName !== "Report" &&
              element.val().activityName !== "Attendance" &&
              element.val().activityName !== "Bus Tracking" &&
              element.val().activityName !== "Message" &&
              element.val().activityName !== "Staff_Message"
            ) {
              x.push(element.val());
            }
          }
        });
        emit(x);
      });
    return () => {
      if (firebase && firebase.teacher && firebase.teacher.id) {
        rsf.ref(branchPath + "/alertReference/" + firebase.teacher.id).off();
        console.log("unsubscribe alert reference");
      }
    };
  });
}

function updateNotificationReadStatus(notification, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  for (var i = 0; i < notification.length; i++) {
    if (notification[i].read === false) {
      rsf
        .ref(
          branchPath +
            "/alertReference/" +
            firebase.teacher.id +
            "/" +
            notification[i].id
        )
        .update({
          read: true,
        });
    }
  }
}

function getAllAlerts(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let userType = "teacher";
  if (
    firebase.selectedMode &&
    firebase.selectedMode.toLowerCase() === "parent"
  ) {
    userType = "student";
  }

  let alerts = [];

  const myRef = rsf
    .ref(branchPath + "/alertReference/" + firebase.user.id)
    .orderByChild("inverseTime")
    .limitToFirst(20);
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((element) => {
        if (element.val() !== null) {
          if (element.val().userType) {
            if (
              element.val().userType.toLowerCase() === userType &&
              element.val().activityName !== "Report" &&
              element.val().activityName !== "Attendance" &&
              element.val().activityName !== "Bus Tracking" &&
              element.val().activityName !== "Message" &&
              element.val().activityName !== "Staff_Message"
            ) {
              alerts.push(element.val());
            }
          }
          // else {
          //   alerts.push(element.val());
          // }
        }
      });
      resolve(alerts);
    });
  });
  return promise1;
}

function sendWelcomeMessageToParent(contact, firebase) {
  superagent
    .get("https://api.msg91.com/api/sendhttp.php")
    .query("authkey=270687Arum6WMZz2dp5ca475da")
    .query("mobiles=" + contact)
    .query("country=91")
    .query(
      "message= Hello! Thank you for showing interest in one of the best pre-schools in India. We are absolutely thrilled to show you around our school. Hope to meet you soon."
    )
    .query("sender=Ilumne")
    .query("route=4")
    .send()
    .set("accept", "json")

    .end((err, res) => {});
}

function sendReminderMessage(contact, message) {
  superagent
    .get("https://api.msg91.com/api/sendhttp.php")
    .query("authkey=270687Arum6WMZz2dp5ca475da")
    .query("mobiles=" + contact)
    .query("country=91")
    .query("message=" + message)
    .query("sender=Ilumne")
    .query("route=4")
    .send()
    .set("accept", "json")

    .end((err, res) => {});
}

function createSimpleAlertReferenceNode(userId, bPath, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath;
  var key = rsf.ref(branchPath + "/alertReference/" + userId).push().key;
  return key;
}

function createSimpleAlertNotification(
  activityName,
  activityNodeId,
  teacherUid,
  bodyMessage,
  nodeId,
  teacherIosUid,
  studentId,
  teacherProfileId,
  alertType,
  bPath,
  firebase,
  creator,
  userType,
  incomingBranchPath
) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;

  var alertObject = {};

  alertObject = {
    activityName: activityName,
    androidId: teacherUid,
    body: bodyMessage,
    id: nodeId,
    inverseTime: -moment().valueOf(),
    iosId: teacherIosUid,
    nodeId: activityNodeId,
    read: false,
    senderName: creator
      ? creator
      : firebase.teacher
      ? firebase.teacher.name
      : "",
    studentId: studentId ? studentId : null,
    type: alertType,
    userType: userType ? userType : null,
    branchPath: incomingBranchPath ? incomingBranchPath : null,
  };

  rsf
    .ref(branchPath + "/alertReference/" + teacherProfileId + "/" + nodeId)
    .update(alertObject);
}

function sendSimplePushNotification(
  activityName,
  activityNodeId,
  teacherUid,
  bodyMessage,
  nodeId,
  teacherIosUid,
  studentId,
  parentProfileId,
  type,
  bPath,
  firebase,
  creator,
  userType,
  incomingBranchPath
) {
  let endPointUrl = firebase.endPointUrl;
  let sendNotifficationEndPointUrl = endPointUrl + "sendNotificationToDevice";

  let schoolConfiguration = localStorage.getItem("schoolConfig");
  let config = JSON.parse(schoolConfiguration);

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(sendNotifficationEndPointUrl)
      .send({
        schoolName: config.schoolName,
        schoolAdress: config.address,
        bucket: config.storageBucket,
        schoolLogoImage: config.logoUrl,
        ccemail: config.adminEmail,
        branchPath: bPath ? bPath : firebase.sbp,
        accountName: firebase.sbDbName,
        timezone: localTimezone,

        email: config.adminEmail,
        message: {
          activityName: activityName,
          androidId: teacherUid,
          body: bodyMessage,
          id: nodeId,
          inverseTime: -moment().valueOf(),
          iosId: teacherIosUid,
          nodeId: activityNodeId,
          read: false,
          // senderName: creator ? creator : firebase.teacher.name,
          senderName: creator
            ? creator
            : firebase.teacher
            ? firebase.teacher.name
            : "",
          studentId: studentId ? studentId : null,
          type: type,
          userType: userType ? userType : null,
          branchPath: incomingBranchPath ? incomingBranchPath : null,
        },
      })
      .set("accept", "json")
      .end((err, res) => {
        //console.log("res", res.status);
        resolve(res);
      });
  });
  return p1;
}

function sendAttendanceEmail(start, end, userType, firebase) {
  let endPointUrl = firebase.endPointUrl;
  let sendAttendanceUrl;
  if (userType === "student") {
    sendAttendanceUrl = endPointUrl + "emailAttendanceReportStudent";
  } else if (userType === "teacher") {
    sendAttendanceUrl = endPointUrl + "emailAttendanceReportStaff";
  }

  let config = firebase.schoolConfig;
  let schoolName = config.schoolName;

  let accountName = firebase.sbDbName;

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(sendAttendanceUrl)
      //.set('Authorization', Utility.messagingToken)
      .send({
        schoolName: schoolName,
        ccEmail: config.adminEmail,
        branchPath: firebase.sbp,
        accountName: accountName,
        timezone: localTimezone,
        email: firebase.teacher.email,
        startdate: new Date(start).getTime(),
        enddate: new Date(end).getTime(),
      })
      .set("accept", "json")
      .end((err, res) => {
        console.log("error -----", err);
        console.log("res -----", res);
        resolve(res);
      });
  });
  return p1;
}

function updateJoinedMeeting(
  activity,
  student,
  firebase,
  eventType,
  bookingRef,
  selectedTimezone
) {
  let endPointUrl = firebase.endPointUrl;
  let joinedMeetingUrl;
  joinedMeetingUrl = endPointUrl + "joinedMeeting";

  let config = firebase.schoolConfig;
  let schoolName = config.schoolName;
  let accountName = firebase.sbDbName;
  let parentUser = firebase.user;
  let localTimezone = moment.tz.guess();
  let parentEmails = [];

  if (student.fatherEmail) {
    parentEmails.push(student.fatherEmail);
  }

  if (student.motherEmail) {
    parentEmails.push(student.motherEmail);
  }

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(joinedMeetingUrl)
      .send({
        schoolName: schoolName,
        ccEmail: config.adminEmail,
        branchPath: firebase.sbp,
        accountName: accountName,
        timezone: selectedTimezone ? selectedTimezone : localTimezone,
        studentName: student.name,
        studentId: student.id,
        teacherName: activity.createdBy,
        //meetingTime: activity.meetingTime,
        meetingTime: bookingRef ? bookingRef.time : null,
        event: eventType,
        parentEmails: parentEmails,
      })
      .set("accept", "json")
      .end((err, res) => {
        console.log("error -----", err);
        console.log("res joined meeting -----", res);
        resolve(res);
      });
  });
  return p1;
}

function callStatusChangeWebHook(student, firebase, event) {
  if (firebase.dbName == "GetReadyEdu_Master-Branch") {
    var studentBody = {};
    let parentEmail;
    let parentName;
    let parentNumber;

    if (student.fatherProfileId) {
      parentEmail = student.fatherEmail ? student.fatherEmail : undefined;
      parentName = student.fatherName;
      parentNumber = student.fatherNumber;
    } else if (student.motherProfileId) {
      parentEmail = student.motherEmail ? student.motherEmail : undefined;
      parentName = student.motherName;
      parentNumber = student.motherNumber;
    }

    if (!parentEmail) {
      return;
    }

    studentBody.studentId = student.id;
    studentBody.studentName = student.name;
    studentBody.classroomName = student.classroomName;
    studentBody.classList = student.classList;
    if (event == "CHANGE_STATUS" && student.status.toLowerCase() != "active") {
      studentBody.event = "MANUAL_ACCOUNT_DISABLED";
    } else {
      studentBody.event = "MANUAL_ENROLLED";
    }
    studentBody.trialStartDate = moment().valueOf();
    studentBody.trialStartDateString = moment().toString();
    studentBody.email = parentEmail;
    studentBody.phoneNumber = parentNumber;
    studentBody.parentName = parentName;
    studentBody.meetingTime = student.meetingDate
      ? student.meetingDate
      : undefined;
    // studentBody.teacherName = firebase.teacher.name; // should be createdBy

    let endPoint =
      "https://hook.integromat.com/fsqrbavgku7hmtabdnx9rxy959lhhmox";

    var p1 = new Promise(function(resolve, reject) {
      superagent
        .post(endPoint)
        .send(studentBody)
        .set("accept", "json")
        .end((err, res) => {
          console.log("error status change -----", err);
          if (err) {
            bugsnagClient.notify(err);
          }
          console.log("res status change -----", res);
          resolve(res);
        });
    });
    return p1;
  }
}

function callProfileUpdateWebHook(student, firebase, event, formValues) {
  if (firebase.dbName == "GetReadyEdu_Master-Branch") {
    var studentBody = {};
    let parentEmail;
    let parentName;
    let parentNumber;

    if (student.fatherProfileId) {
      parentEmail = student.fatherEmail ? student.fatherEmail : undefined;
      parentName = student.fatherName;
      parentNumber = student.fatherNumber;
    } else if (student.motherProfileId) {
      parentEmail = student.motherEmail ? student.motherEmail : undefined;
      parentName = student.motherName;
      parentNumber = student.motherNumber;
    }

    studentBody.studentId = student.id;
    studentBody.studentName = formValues.name;
    studentBody.classroomName = formValues.classroom;
    studentBody.classList = formValues.classroom;
    studentBody.event = event;
    studentBody.email = parentEmail;
    studentBody.phoneNumber = parentNumber;
    studentBody.parentName = parentName;
    studentBody.shippingAddress = formValues.address ? formValues.address : "";

    let endPoint =
      "https://hook.integromat.com/fsqrbavgku7hmtabdnx9rxy959lhhmox";

    var p1 = new Promise(function(resolve, reject) {
      superagent
        .post(endPoint)
        .send(studentBody)
        .set("accept", "json")
        .end((err, res) => {
          console.log("error profile update -----", err);
          if (err) {
            bugsnagClient.notify(err);
          }
          console.log("res profile update -----", res);
          resolve(res);
        });
    });
    return p1;
  }
}

function invoiceGenerationRequest(feePlan, firebase) {
  let endPointUrl = firebase.endPointUrl + "createInvoiceFromFeePlan";

  let config = firebase.schoolConfig;
  let schoolName = config.schoolName;

  let accountName = firebase.sbDbName;

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(endPointUrl)
      .send({
        schoolName: schoolName,
        ccemail: config.adminEmail,
        branchPath: firebase.sbp,
        accountName: accountName,
        bucket: config.storageBucket,
        schoolLogoImage: config.logoUrl,
        schoolAdress: config.address,
        invoicePrefix: config.invoicePrefix,
        feePlan: feePlan,
        timezone: config.timezone ? config.timezone : moment.tz.guess(),
      })
      .set("accept", "json")
      .end((err, res) => {
        console.log("error -----", err);
        console.log("res create invoice from fee plan -----", res);
        resolve(res);
      });
  });
  return p1;
}

function addStudentToMeeting(
  activity,
  bookingRef,
  studentIds,
  firebase,
  selectedTimezone
) {
  let endPointUrl = firebase.endPointUrl + "addStudentVirtualClass";

  let config = firebase.schoolConfig;
  let schoolName = config.schoolName;

  let accountName = firebase.sbDbName;
  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(endPointUrl)
      .send({
        schoolName: schoolName,
        branchPath: firebase.sbp,
        accountName: accountName,
        timezone: selectedTimezone
          ? selectedTimezone
          : config.timezone
          ? config.timezone
          : moment.tz.guess(),
        activity: activity,
        bookingRef: bookingRef,
        meetingDetails: activity,
        studentIds: studentIds,
      })
      .set("accept", "json")
      .end((err, res) => {
        console.log("error adding student -----", err);
        console.log("add student to meeting response -----", res);
        if (res) {
          resolve(res);
        } else {
          if (err) {
            reject(err);
          }
        }
      });
  });
  return p1;
}

function callAutoChargePaymentApi(invoice, firebase) {
  let endPointUrl = firebase.endPointUrl + "autoChargePayment";
  let config = firebase.schoolConfig;
  let schoolName = config.schoolName;
  let accountName = firebase.sbDbName;

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(endPointUrl)
      .send({
        schoolName: schoolName,
        branchPath: firebase.sbp,
        accountName: accountName,
        timezone: config.timezone ? config.timezone : moment.tz.guess(),
        invoice: invoice,
      })
      .set("accept", "json")
      .end((err, res) => {
        console.log("error auto charge -----", err);
        console.log("auto charge payment response -----", res);
        resolve(res);
        // if (res) {
        //   resolve(res);
        // } else {
        //   if (err) {
        //     notification('error', "Auto payment failed");
        //     reject(err)
        //   }
        // }
      });
  });
  return p1;
}

function updateEmailInStripe(cId, email, studentId, firebase) {
  let endPointUrl = firebase.endPointUrl + "updateStripeCustomer";

  let config = firebase.schoolConfig;
  let schoolName = config.schoolName;
  let accountName = firebase.sbDbName;

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(endPointUrl)
      .send({
        schoolName: schoolName,
        branchPath: firebase.sbp,
        accountName: accountName,
        email: email,
        customerId: cId,
        studentId: studentId,
      })
      .set("accept", "json")
      .end((err, res) => {
        console.log("update email error -----", err);
        console.log("update email response -----", res);
        if (res) {
          resolve(res);
        } else {
          if (err) {
            reject(err);
          }
        }
      });
  });
  return p1;
}

function callStatusChangeApi(student, firebase) {
  let endPointUrl = firebase.endPointUrl + "removeStudentVirtualClass";

  let config = firebase.schoolConfig;
  let schoolName = config.schoolName;
  let accountName = firebase.sbDbName;

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(endPointUrl)
      .send({
        schoolName: schoolName,
        branchPath: firebase.sbp,
        accountName: accountName,
        studentId: student.id,
        timezone: config.timezone ? config.timezone : moment.tz.guess(),
      })
      .set("accept", "json")
      .end((err, res) => {
        console.log("removeStudentVirtualClass error -----", err);
        console.log("removeStudentVirtualClass response -----", res);
        if (res) {
          resolve(res);
        } else {
          if (err) {
            reject(err);
          }
        }
      });
  });
  return p1;
}

function callFeePlanStudentAssignWebHook(
  student,
  plan,
  startTime,
  endTime,
  firebase,
  event
) {
  if (firebase.dbName == "GetReadyEdu_Master-Branch") {
    var studentBody = {};
    let parentEmail;
    let parentName;
    let parentNumber;

    if (student.fatherProfileId) {
      parentEmail = student.fatherEmail ? student.fatherEmail : undefined;
      parentName = student.fatherName;
      parentNumber = student.fatherNumber;
    } else if (student.motherProfileId) {
      parentEmail = student.motherEmail ? student.motherEmail : undefined;
      parentName = student.motherName;
      parentNumber = student.motherNumber;
    }

    studentBody.parentName = parentName;
    studentBody.parentEmail = parentEmail;
    studentBody.parentNumber = parentNumber;
    studentBody.studentId = student.id;
    studentBody.studentName = student.name;
    studentBody.classroomName = student.classroomName;
    studentBody.classList = student.classList;
    studentBody.event = event;

    let total = 0;
    let studentFeePlan = plan.studentFeeComponent
      ? plan.studentFeeComponent
      : [];
    for (let index in studentFeePlan) {
      let p = studentFeePlan[index];
      if (p.discountType.toLowerCase() === "number") {
        total = total + Number(p.amount - (p.discount ? p.discount : 0));
      } else if (p.discountType.toLowerCase() === "percentage") {
        total = total + Number(p.amount - (p.amount * p.discount) / 100);
      }
    }

    studentBody.subscriptionAmount = total;
    studentBody.interval = plan.frequency;
    studentBody.startDate = startTime;
    studentBody.endDate = endTime;
    console.log("studentBody", studentBody);

    let endPoint =
      "https://hook.integromat.com/fsqrbavgku7hmtabdnx9rxy959lhhmox";

    var p1 = new Promise(function(resolve, reject) {
      superagent
        .post(endPoint)
        .send(studentBody)
        .set("accept", "json")
        .end((err, res) => {
          console.log("error assign student in fee plan -----", err);
          if (err) {
            bugsnagClient.notify(err);
          }
          console.log("res assign student in fee plan change -----", res);
          resolve(res);
        });
    });
    return p1;
  }
}

function callDashboardRefreshApi(firebase, endPoint, date) {
  let endPointUrl = firebase.endPointUrl + "dashboardRefreshApi2/" + endPoint;

  let accountName = firebase.sbDbName;

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(endPointUrl)
      .send({
        accountName: accountName,
        date: moment(date).format("YYYY[-]MM[-]DD"),
      })
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
          console.log(
            "dashboard refresh api response -----" + endPoint + " ->",
            res
          );
        } else {
          if (err) {
            console.log("dashboard refresh api error -----", err);
          }
        }
      });
  });
  return p1;
}

function receiveNotificationPermission(activityType, activityName, teacher) {
  let permissions = new Map(JSON.parse(localStorage.getItem("permissionList")));
  let role = teacher.role ? teacher.role : undefined;
  let notificationStatus = false;
  if (role) {
    if (activityType === "COMMENT" && activityName === "Concern") {
      let key = PermissionStrings.CONCERN_VIEW_COMMENT;
      notificationStatus = getPermissionByKey(key, permissions, role);
    } else if (activityType === "COMMENT" && activityName === "LEAVE") {
      let key = PermissionStrings.LEAVES_Comment;
      notificationStatus = getPermissionByKey(key, permissions, role);
    } else if (activityType === "COMMENT" && activityName === "NOTE") {
      let key = PermissionStrings.NOTES_Comment;
      notificationStatus = getPermissionByKey(key, permissions, role);
    } else if (activityType === "EVENT") {
      let key = PermissionStrings.EVENTS_VIEW;
      notificationStatus = getPermissionByKey(key, permissions, role);
    } else if (activityType === "StudentLearning") {
      let key = PermissionStrings.LESSON_VIEW;
      notificationStatus = getPermissionByKey(key, permissions, role);
    } else if (activityType === "Activity") {
      let key = PermissionStrings.POST_ACTIVITY_VIEW;
      notificationStatus = getPermissionByKey(key, permissions, role);
    } else if (activityType === "CONCERN") {
      let key = PermissionStrings.CONCERN_VIEW_COMMENT;
      notificationStatus = getPermissionByKey(key, permissions, role);
    } else if (activityType === "File Upload") {
      let key = PermissionStrings.SM_Document_Access;
      notificationStatus = getPermissionByKey(key, permissions, role);
    } else if (activityType === "LEAVE") {
      let key = PermissionStrings.LEAVES_VIEW;
      notificationStatus = getPermissionByKey(key, permissions, role);
    } else if (activityType === "Message") {
      let key = PermissionStrings.QUERIES_VIEW_REPLY;
      notificationStatus = getPermissionByKey(key, permissions, role);
    } else if (activityType === "Assignment Submission") {
      let key = PermissionStrings.LESSON_VIEW;
      notificationStatus = getPermissionByKey(key, permissions, role);
    } else if (activityType === "NOTE") {
      let key = PermissionStrings.NOTES_View_Mark_Done;
      notificationStatus = getPermissionByKey(key, permissions, role);
    } else {
      notificationStatus = true;
    }
  }
  return notificationStatus;
}

function getPermissionByKey(key, permissions, role) {
  if (permissions.has(key)) {
    let value = permissions.get(key);
    if (value.roles && value.roles.includes(role)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function checkIfStudentInGroup(teacher, student) {
  if (teacher.groups) {
    let teacherGroup = teacher.groups;
    let studentGroup = student.tags ? student.tags : [];
    let isPresent = false;
    for (let index in studentGroup) {
      let grp = studentGroup[index];
      if (teacherGroup.includes(grp.name)) {
        isPresent = true;
        break;
      }
    }
    return isPresent;
  } else {
    return true;
  }
}

export const NotificationApi = {
  getAlertNotification,
  updateNotificationReadStatus,
  createAlertReferenceNode,
  createAlertNotification,
  sendPushNotification,
  sendActivityEmails,
  sendStats,
  getAllAlerts,
  sendWelcomeMessageToParent,
  sendReminderMessage,
  createSimpleAlertNotification,
  sendSimplePushNotification,
  createSimpleAlertReferenceNode,
  sendAttendanceEmail,
  updateJoinedMeeting,
  callStatusChangeWebHook,
  invoiceGenerationRequest,
  addStudentToMeeting,
  callProfileUpdateWebHook,
  callAutoChargePaymentApi,
  updateEmailInStripe,
  callStatusChangeApi,
  callFeePlanStudentAssignWebHook,
  callDashboardRefreshApi,
  receiveNotificationPermission,
  checkIfStudentInGroup,
};
