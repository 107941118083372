import actions from "./actions";
const initState = {
  studentAttendance: [],
  allStudents: [],
  classrooms: [],
  isLoading: false,
  error: false,
  attendanceChannel: undefined,
  operationType: undefined,
  studentMonthlyAttendance: [],
  programList: [],
};
export default function studentAttendanceReducer(
  state = initState,
  { type, ...action }
) {
  switch (type) {
    case actions.FETCH_STUDENT_ATTENDANCE:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.FETCH_STUDENT_ATTENDANCE_SUCCESSFUL:
      return {
        ...state,
        studentAttendance: action.studentAttendance,
        isLoading: false,
        attendanceChannel: action.attendanceChannel,
        operationType: action.operationType,
      };

    case actions.FETCH_STUDENT_ATTENDANCE_BY_CLASSNAME:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.FETCH_STUDENT_ATTENDANCE_BY_CLASSNAME_SUCCESSFUL:
      return {
        ...state,
        studentAttendance: action.studentAttendance,
        isLoading: false,
        attendanceChannel: action.attendanceChannel,
        operationType: undefined,
      };

    case actions.FETCH_ALL_STUDENT:
      return {
        ...state,
        // isLoading: true,
        operationType: undefined,
      };
    case actions.FETCH_ALL_STUDENT_SUCCESSFUL:
      return {
        ...state,
        allStudents: action.allStudents,
        // isLoading: false,
        operationType: undefined,
      };

    case actions.MARK_PRESENT:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.MARK_PRESENT_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "REFRESH_COMPONENT",
      };

    case actions.MARK_ABSENT:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.MARK_ABSENT_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "REFRESH_COMPONENT",
      };

    case actions.MARK_CHECKOUT:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.MARK_CHECKOUT_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "REFRESH_COMPONENT",
      };

    case actions.FETCH_ALL_CLASSROOMS:
      return {
        ...state,
        // isLoading: true,
      };
    case actions.FETCH_ALL_CLASSROOMS_SUCCESSFUL:
      return {
        ...state,
        // isLoading: false,
        classrooms: action.classrooms,
      };

    case actions.GET_PROGRAMS_LIST:
      return {
        ...state,
        programList: action.programList,
      };

    case actions.FETCH_STUDENT_BY_CLASSNAME:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.FETCH_STUDENT_BY_CLASSNAME_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        allStudents: action.allStudents,
        operationType: undefined,
      };

    case actions.STUDENT_ATTENDANCE_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case actions.RESET_STUDENT_ATD_OPERATION:
      return {
        ...state,
        isLoading: false,
        operationType: undefined,
      };

    case actions.EMAIL_STUDENT_ATTENDANCE:
      return {
        ...state,
        isLoading: false,
        operationType: undefined,
      };

    case actions.GET_MONTHLY_ATD:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };

    case actions.GET_MONTHLY_ATD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        studentMonthlyAttendance: action.studentMonthlyAttendance,
      };

    case actions.MARK_STUDENT_PENDING:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.MARK_STUDENT_PENDING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "REFRESH_COMPONENT",
      };

    default:
      return state;
  }
}
