const actions = {
    GET_LESSON_STATS: "GET_LESSON_STATS",
    GET_LESSON_STATS_SUCCESSFUL: "GET_LESSON_STATS_SUCCESSFUL",

    LESSON_STAT_REQUEST_FAIL: "LESSON_STAT_REQUEST_FAIL",

    getLessonStats: firebase => ({
        type: actions.GET_LESSON_STATS,
        firebase
    }),
};

export default actions;