import { call } from "redux-saga/effects";
import firebase from "firebase";
import "@firebase/firestore"; // 👈 If you're using firestore
import ReduxSagaFirebase from "redux-saga-firebase";
import moment from "moment-timezone";
import { eventChannel } from "redux-saga";
import FilterPermission from "../Utility/FilterPermission";
import PermissionStrings from "../Utility/PermissionStrings";
const superagent = require("superagent");

function getAllDraftNewsletter(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/newsletterDraft/")
      .orderByChild("inverseDate")
      .on("value", (snap) => {
        var x = [];
        snap.forEach((element) => {
          if (element.val() !== null) {
            x.push(element.val());
          }
        });
        emit(x);
      });
    return () => {
      rsf.ref(branchPath + "/newsletterDraft").off();
      console.log("unsubscribe newsletterDraft");
    };
  });
}

function getAllTemplates(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/newsletterTemplates/")
      .orderByChild("inverseDate")
      .on("value", (snap) => {
        var x = [];
        snap.forEach((element) => {
          if (element.val() !== null) {
            x.push(element.val());
          }
        });
        emit(x);
      });
    return () => {
      rsf.ref(branchPath + "/newsletterTemplates").off();
      console.log("unsubscribe newsletterTemplates");
    };
  });
}

function saveNewsletter(newsletter, nodeId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var createdOn = moment().valueOf();
  var modifiedOn = moment().valueOf();
  rsf.ref(branchPath + "/newsletterDraft/" + nodeId).update({
    id: nodeId,
    createdOn: createdOn,
    modifiedOn: modifiedOn,
    name: newsletter.name,
    status: newsletter.status,
    json: JSON.stringify(newsletter.json.design),
    html: newsletter.json.html,
    inverseDate: -new Date(),
    createdBy: firebase.teacher.name,
  });
}

function updateNewsletter(newsletter, firebase, uploadFileUrl) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var modifiedOn = moment().valueOf();
  rsf.ref(branchPath + "/newsletterDraft/" + newsletter.id).update({
    name: newsletter.name,
    modifiedOn: modifiedOn,
    json: JSON.stringify(newsletter.json.design),
    html: newsletter.json.html,
    status: newsletter.status,
    inverseDate: -new Date(),
    banner: uploadFileUrl ? uploadFileUrl : null,
  });
}

function saveTemplate(newsletter, nodeId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var createdOn = moment().valueOf();
  var modifiedOn = moment().valueOf();
  rsf.ref(branchPath + "/newsletterTemplates/" + nodeId).update({
    id: nodeId,
    createdOn: createdOn,
    modifiedOn: modifiedOn,
    name: newsletter.name,
    json: JSON.stringify(newsletter.json.design),
    html: newsletter.json.html,
    inverseDate: -new Date(),
    createdBy: firebase.teacher.name,
  });
}

function updateTemplate(newsletter, firebase, uploadFileUrl) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var modifiedOn = moment().valueOf();
  rsf.ref(branchPath + "/newsletterTemplates/" + newsletter.id).update({
    name: newsletter.name,
    modifiedOn: modifiedOn,
    json: JSON.stringify(newsletter.json.design),
    html: newsletter.json.html,
    inverseDate: -new Date(),
    banner: uploadFileUrl ? uploadFileUrl : null,
  });
}

function* sendNewsletter(newsletter) {
  const firebaseName = localStorage.getItem("projectId");
  const rsf = new ReduxSagaFirebase(firebase.app(firebaseName));
  var modifiedOn = new Date();
  var createdOn = new Date(newsletter.createdOn);
  yield call(rsf.firestore.updateDocument, "newsletter/" + newsletter.id, {
    name: newsletter.name,
    createdOn: createdOn,
    modifiedOn: modifiedOn,
    json: JSON.stringify(newsletter.json),
    html: newsletter.html,
    studentIds: newsletter.selectedStudents,
    status: "SENDING",
  });
}

function uploadImageAttachment(file, firebase) {
  var uploadTimestamp = new Date().getTime();
  var fileName = new Date().getTime() + file.name;
  var metadata = {
    contentType: file.type,
  };

  let branchPath = firebase.sbp;
  const storage = firebase.secondaryStorage;
  const storageRef = storage.ref();
  let attachmentRef = storageRef.child(
    branchPath + "/media/images/" + fileName + ":" + uploadTimestamp
  );

  var p1 = new Promise(function(resolve, reject) {
    attachmentRef.put(file, metadata).then(function(snapshot) {
      attachmentRef.getDownloadURL().then((url) => {
        console.log("found url", url);
        resolve(url);
      });
    });
  });
  return p1;
}

function createNodeIdForNewsletterDraft(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/newsletterDraft").push().key;
  return key;
}

function createNodeIdForNewsletterTemplate(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/newsletterTemplates").push().key;
  return key;
}
function createNodeIdForNewActivity(firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var key = rsf.ref(branchPath + "/activities").push().key;
  return key;
}

function addActivity(newsletter, activityId, firebase, studentIds, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var activityDate = new Date();
  let classrooms = newsletter.selectedClassrooms;
  var newClassroom = [];
  classrooms.forEach((item) => {
    newClassroom.push(item.className);
  });

  let createdOn = {
    date: activityDate.getDate(),
    day: activityDate.getDay(),
    hours: activityDate.getHours(),
    minutes: activityDate.getMinutes(),
    month: activityDate.getMonth(),
    seconds: activityDate.getSeconds(),
    time: activityDate.getTime(),
    timezoneOffset: activityDate.getTimezoneOffset(),
    year: activityDate.getFullYear(),
  };

  rsf.ref(branchPath + "/activities/" + activityId).update({
    activityType: "Newsletter",
    approved: FilterPermission.checkIfPermission(
      PermissionStrings.POST_ACTIVITY_POST_WITHOUT_APPROVAL,
      firebase
    ),
    classNames: newClassroom,
    createdBy: firebase.teacher.name,
    date: createdOn,
    dateString: moment(activityDate).format("YYYY[-]MM[-]DD"),
    deleted: false,
    dirty: false,
    html: newsletter.html,
    id: activityId,
    inverseDate: -activityDate.getTime(),
    likesCount: 0,
    longDate: 0,
    message: newsletter.name,
    name: "Newsletter",
    staffOnly: false,
    teacherId: firebase.teacher.id,
    studentIds: studentIds,
  });
}

function getStudentFromClassroom(className, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let student = [];
  var studentPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/students")
      .orderByChild("deleted")
      .equalTo(false)
      .once("value")
      .then(function(snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val() !== null && snap.val().classList.includes(className)) {
            student.push(snap.val());
          }
        });
        resolve(student);
      });
  });
  return studentPromise;
}

function getTimelineOfStudent(studentId, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var searchableDate = moment(new Date()).format("D[ ]MMMM[ ]YYYY");
  var timelineActivity = {};
  var timelineActivityPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/timeline/" + studentId + "/" + searchableDate)
      .once("value")
      .then(function(snapshot) {
        if (snapshot.val() !== null) {
          timelineActivity = snapshot.val();
        }
        resolve(timelineActivity);
      });
  });
  return timelineActivityPromise;
}

function updateTimelineActivityOfStudent(
  studentId,
  updatedTimelineObject,
  firebase,
  bPath
) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var searchableDate = moment(new Date()).format("D[ ]MMMM[ ]YYYY");
  rsf
    .ref(branchPath + "/timeline/" + studentId + "/" + searchableDate)
    .update(updatedTimelineObject);
}

function createNewsletterNodeId(studentId, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var key = rsf.ref(branchPath + "/Newsletter/" + studentId).push().key;
  return key;
}

function updateNewsletterNode(
  studentId,
  activityId,
  newsletterUniqueNodeId,
  firebase,
  bPath
) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var activityDate = new Date();

  let createdOn = {
    date: activityDate.getDate(),
    day: activityDate.getDay(),
    hours: activityDate.getHours(),
    minutes: activityDate.getMinutes(),
    month: activityDate.getMonth(),
    seconds: activityDate.getSeconds(),
    time: activityDate.getTime(),
    timezoneOffset: activityDate.getTimezoneOffset(),
    year: activityDate.getFullYear(),
  };

  rsf
    .ref(
      branchPath +
        "/" +
        "Newsletter/" +
        studentId +
        "/" +
        newsletterUniqueNodeId
    )
    .update({
      activityId: activityId,
      createdBy: firebase.teacher.name,
      date: createdOn,
      newDate: 0,
    });
}

function sendNewsletterEmails(newsletter, parentEmails, firebase, schoolName) {
  let endPointUrl = firebase.endPointUrl;
  let sendNewsletterEmailUrl = endPointUrl + "sendNewsletter ";

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(sendNewsletterEmailUrl)
      .send({
        schoolName: schoolName ? schoolName : firebase.schoolName,
        ccemail: firebase.schoolConfig.adminEmail,
        html: newsletter.html,
        subject: newsletter.name,
        parentEmails: parentEmails,
        timezone: localTimezone,
      })
      .set("accept", "json")

      .end((err, res) => {
        console.log("res", res.status);
        resolve(res);
      });
  });
  return p1;
}

function getSentNewsletter(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/activities")
      .orderByChild("activityType")
      .equalTo("Newsletter")
      .on("value", (snap) => {
        var x = [];
        snap.forEach((element) => {
          if (element.val() !== null && element.val().deleted === false) {
            let classList =
              firebase && firebase.teacher && firebase.teacher.classList
                ? firebase.teacher.classList
                : [];
            if (firebase.teacher && !firebase.teacher.superUser) {
              if (classList.length > 0) {
                let activityClasses = element.val().classNames;
                for (let index in classList) {
                  if (activityClasses) {
                    let filteredVal = activityClasses.filter((f) => {
                      return f.toLowerCase() === classList[index].toLowerCase();
                    });

                    if (filteredVal && filteredVal.length > 0) {
                      x.push(element.val());
                      break;
                    }
                  }
                }
              } else {
                x.push(element.val());
              }
            } else {
              x.push(element.val());
            }
          }
        });
        emit(x);
      });
    return () => {
      rsf.ref(branchPath + "/activities").off();
      console.log("unsubscribe newsletterDraft");
    };
  });
}

export const NewsletterApi = {
  getAllDraftNewsletter,
  getAllTemplates,
  saveNewsletter,
  updateNewsletter,
  saveTemplate,
  updateTemplate,
  sendNewsletter,
  uploadImageAttachment,
  createNodeIdForNewsletterDraft,
  createNodeIdForNewsletterTemplate,
  createNodeIdForNewActivity,
  addActivity,
  getStudentFromClassroom,
  getTimelineOfStudent,
  updateTimelineActivityOfStudent,
  createNewsletterNodeId,
  updateNewsletterNode,
  sendNewsletterEmails,
  getSentNewsletter,
};
