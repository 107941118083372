import { all, put, call, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { StudentApi } from "../../firestore-api/student";
import { TeacherApi } from "../../firestore-api/teacher";
import { ScheduleApi } from "../../firestore-api/schedule";
import notification from "../../components/notification";
import moment from "moment-timezone";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

// function* onboardStudents({ studentCsvData, firebase }) {
//   for (let i = 0; i < studentCsvData.length; i++) {
//     var fatherNumberStatus = { status: false };
//     var motherNumberStatus = { status: false };
//     let fatherNodeId = undefined;
//     let motherNodeId = undefined;
//     var studentNodeId = yield call(StudentApi.createStudentNodeId, firebase);

//     if (studentCsvData[i].fatherNumber) {
//       fatherNumberStatus = yield call(
//         TeacherApi.checkIfUserExistInNewUser,
//         studentCsvData[i].fatherNumber,
//         firebase
//       );
//     }
//     console.log(
//       "father number status",
//       fatherNumberStatus.status,
//       studentCsvData[i].fatherNumber
//     );
//     if (studentCsvData[i].motherNumber) {
//       motherNumberStatus = yield call(
//         TeacherApi.checkIfUserExistInNewUser,
//         studentCsvData[i].motherNumber,
//         firebase
//       );
//     }
//     console.log(
//       "mother number status",
//       motherNumberStatus.status,
//       studentCsvData[i].motherNumber
//     );

//     let classroom = yield call(
//       StudentApi.getClassroomByName,
//       studentCsvData[i].classroom,
//       firebase
//     );

//     if (classroom.length > 0) {
//       if (fatherNumberStatus.status === false && motherNumberStatus.status === false) {

//         if (studentCsvData[i].fatherName && studentCsvData[i].fatherNumber) {
//           fatherNodeId = yield call(StudentApi.generateUserNode, firebase);

//           console.log("fatherNodeId", fatherNodeId);
//           yield call(
//             StudentApi.addToNewUser,
//             fatherNodeId,
//             studentCsvData[i].fatherNumber,
//             studentNodeId,
//             "STUDENT",
//             firebase
//           );

//           /**create email node if father email */
//           if (studentCsvData[i].fatherEmail && studentCsvData[i].fatherEmail !== "") {
//             yield fork(createParentEmailLogin, studentCsvData[i].fatherEmail, studentCsvData[i].fatherNumber, firebase)
//           }
//         }
//         /**creating mother profile */
//         if (studentCsvData[i].motherName && studentCsvData[i].motherNumber) {
//           motherNodeId = yield call(StudentApi.generateUserNode, firebase);

//           console.log("motherNodeId", motherNodeId);
//           yield call(
//             StudentApi.addToNewUser,
//             motherNodeId,
//             studentCsvData[i].motherNumber,
//             studentNodeId,
//             'STUDENT',
//             firebase
//           );

//           /**create email node if mother email */
//           if (studentCsvData[i].motherEmail && studentCsvData[i].motherEmail !== "") {
//             yield fork(createParentEmailLogin, studentCsvData[i].motherEmail, studentCsvData[i].motherNumber, firebase)
//           }
//         }
//         /**creating student profile */
//         if (fatherNodeId || motherNodeId) {

//           yield call(
//             StudentApi.addStudentFromCsv,
//             studentCsvData[i],
//             classroom,
//             studentNodeId,
//             fatherNodeId,
//             motherNodeId,
//             firebase
//           );
//         } else {
//           console.log("failed to generate parent's profile");
//           yield put({
//             type: actions.ONBOARDINGG_REQUEST_FAILED,
//             errorMessage: "failed to generate parent's profile"
//           });
//         }
//       } else if (fatherNumberStatus.status === true && motherNumberStatus.status === true) {
//         let existingStudentData = yield call(StudentApi.getStudentById, fatherNumberStatus.studentId, firebase);
//         if (existingStudentData) {
//           if (
//             fatherNumberStatus.phoneNumber === Number(studentCsvData[i].fatherNumber) &&
//             fatherNumberStatus.studentId !== studentNodeId &&
//             !fatherNumberStatus.child2Id &&
//             fatherNumberStatus.schoolName === firebase.sbDbName
//           ) {
//             fatherNumberStatus.child2Id = studentNodeId;
//             fatherNumberStatus.child2inactive = false;
//             fatherNumberStatus.child1inactive = false;
//             yield call(StudentApi.updateSecondChildToNewUser, fatherNumberStatus, firebase);
//             /**create email node if father email */
//             if (studentCsvData[i].fatherEmail && studentCsvData[i].fatherEmail !== "") {
//               yield fork(createParentEmailLogin, studentCsvData[i].fatherEmail, studentCsvData[i].fatherNumber, firebase);
//             }
//           } else {
//             notification('error', "Failed_" + studentCsvData[i].fatherNumber + ". Case of sibling. Possible reasons may be difference in father number or school name");
//           }

//           if (
//             motherNumberStatus.phoneNumber === Number(studentCsvData[i].motherNumber) &&
//             motherNumberStatus.studentId !== studentNodeId &&
//             !motherNumberStatus.child2Id &&
//             motherNumberStatus.schoolName === firebase.sbDbName
//           ) {
//             motherNumberStatus.child2Id = studentNodeId;
//             motherNumberStatus.child2inactive = false;
//             motherNumberStatus.child1inactive = false;
//             yield call(StudentApi.updateSecondChildToNewUser, motherNumberStatus, firebase);
//             /**create email node if mother email */
//             if (studentCsvData[i].motherEmail && studentCsvData[i].motherEmail !== "") {
//               yield fork(createParentEmailLogin, studentCsvData[i].motherEmail, studentCsvData[i].motherNumber, firebase)
//             }
//           } else {
//             notification('error', "Failed_" + studentCsvData[i].motherNumber + ". Case of sibling. Possible reasons may be difference in mother number or school name");
//           }

//           // /**checking case of twins in father's profile */
//           // if (fatherNumberStatus.studentId && fatherNumberStatus.userType === 'STUDENT') {
//           //   fatherNumberStatus.child2Id = studentNodeId;
//           //   fatherNumberStatus.child2inactive = false;
//           //   fatherNumberStatus.child1inactive = false;
//           //   yield call(StudentApi.updateSecondChildToNewUser, fatherNumberStatus, firebase);
//           // }
//           // if (motherNumberStatus.studentId && fatherNumberStatus.userType === 'STUDENT') {
//           //   motherNumberStatus.child2Id = studentNodeId;
//           //   motherNumberStatus.child2inactive = false;
//           //   motherNumberStatus.child1inactive = false;
//           //   yield call(StudentApi.updateSecondChildToNewUser, motherNumberStatus, firebase);
//           // }
//           // let existingStudentData = yield call(StudentApi.getStudentById, fatherNumberStatus.studentId, firebase);

//           yield call(
//             StudentApi.addSecondChildOfExistingParentUsingCsv,
//             studentCsvData[i],
//             classroom,
//             studentNodeId,
//             fatherNumberStatus.id,
//             motherNumberStatus.id,
//             existingStudentData.name,
//             existingStudentData.id,
//             firebase
//           );
//           yield call(StudentApi.addSibling, existingStudentData, studentNodeId, studentCsvData[i].studentName, firebase);
//         }
//       } else {
//         console.log("failed to onboard student data");
//         yield put({
//           type: actions.ONBOARDINGG_REQUEST_FAILED,
//           errorMessage: "failed to onboard student data. Either father's or mother's number already exist."
//         });
//       }
//     } else {
//       console.log("classroom not found");
//       yield put({
//         type: actions.ONBOARDINGG_REQUEST_FAILED,
//         errorMessage: "classroom not found"
//       });
//     }
//   }
//   yield put({
//     type: actions.UPLOAD_STUDENT_CSV_SUCCESSFUL
//   });
// }

function* onboardStudents({ studentCsvData, firebase }) {
  let fields = [];
  fields = [
    "Name",
    "Father_Added",
    "Father_Failed",
    "Father_Error",
    "Mother_Added",
    "Mother_Failed",
    "Mother_Error",
  ];

  let studentList = [];
  for (let i = 0; i < studentCsvData.length; i++) {
    var row = {};
    row.Name = studentCsvData[i].studentName;

    let classroom = yield call(
      StudentApi.getClassroomByName,
      studentCsvData[i].classroom.trim(),
      firebase
    );
    if (classroom.length > 0) {
      let siblingRequest = false;
      var studentNodeId = yield call(StudentApi.createStudentNodeId, firebase);
      let admissionCounter = yield call(
        StudentApi.getAdmissionCounter,
        firebase
      );

      let admissionNumber;
      if (
        firebase.schoolConfig &&
        firebase.schoolConfig.admissionNumberPrefix
      ) {
        admissionNumber = (
          firebase.schoolConfig.admissionNumberPrefix +
          "-" +
          (admissionCounter + 1)
        ).toString();
      } else {
        admissionNumber = (admissionCounter + 1).toString();
      }

      let values = studentCsvData[i];

      let formattedDOB = moment(values.birthDate, [
        "DD/MM/YY",
        "DD-MM-YYYY",
        "DD, MMMM, YYYY",
        "DD.MM.yyyy",
        "YYYY-MM-DD",
        "DD[-]MM[-]YYYY",
      ]).toDate();

      let studentObj = {
        birthDate: moment(new Date(formattedDOB)).format("DD[, ]MMMM[, ]YYYY"),
        address: values.address ? values.address : null,
        classroomName: values.classroom.trim(),
        classList: [values.classroom],
        dateCreated: new Date().getTime(),
        deactivated: false,
        deactivationDate: 0,
        deleted: false,
        deletedOn: 0,
        gender: values.gender,
        id: studentNodeId,
        name: values.studentName,
        napEndTime: 0,
        napStartTime: 0,
        present: false,
        section: false,
        platform: "web_onboarding",
        status: "Active",
        updatedBy: firebase.teacher.name,
        updatedOn: new Date().getTime(),
        admissionNumber: admissionNumber ? admissionNumber : null,
        preferredName: values.preferredName ? values.preferredName : null,
      };

      yield call(
        StudentApi.updateStudentWithUpdatedFormFields,
        studentObj,
        firebase
      );

      if (values.fatherNumber || values.fatherEmail) {
        let addResponse = yield call(
          StudentApi.addParentApi,
          firebase,
          values.studentName,
          studentNodeId,
          values.fatherName,
          values.fatherNumber ? Number(values.fatherNumber) : null,
          values.fatherEmail ? values.fatherEmail : null,
          "parent2",
          studentObj
        );

        if (addResponse && addResponse.status === 200) {
          // father number added successfully
          row.Father_Added = "Yes";
          row.Father_Failed = "";
          row.Father_Error = "";
        } else if (addResponse && addResponse.status === 201) {
          let delResponse = yield call(
            StudentApi.deleteStudentApi,
            firebase,
            studentObj
          );

          console.log("delResponse --->", delResponse);

          if (addResponse && addResponse.body && addResponse.body.sibling) {
            let existingStudent = addResponse.body.sibling;
            let sibResponse = yield call(
              StudentApi.addSiblingApi,
              firebase,
              studentObj,
              existingStudent.id,
              existingStudent.name
            );

            if (
              sibResponse &&
              sibResponse.status &&
              sibResponse.status === 200
            ) {
              console.log("sibling added successfully");
              row.Father_Added = "Yes";
              row.Father_Failed = "";
              row.Father_Error = "";
            } else {
              row.Father_Added = "";
              row.Father_Failed = "Yes";
              row.Father_Error =
                sibResponse.body && sibResponse.body.message
                  ? sibResponse.body.message
                  : "Failed to add";
            }
            siblingRequest = true;
          } else {
            console.log(
              "can't add a sibling as the existing student is missing"
            );
            row.Father_Added = "";
            row.Father_Failed = "Yes";
            row.Father_Error =
              addResponse.body && addResponse.body.message
                ? addResponse.body.message
                : "Failed to add";
          }
        } else {
          row.Father_Added = "";
          row.Father_Failed = "Yes";
          row.Father_Error =
            addResponse.body && addResponse.body.message
              ? addResponse.body.message
              : "Failed to add";
        }
      }

      if (values.motherNumber || values.motherEmail) {
        let addResponse = yield call(
          StudentApi.addParentApi,
          firebase,
          values.studentName,
          studentNodeId,
          values.motherName,
          values.motherNumber ? Number(values.motherNumber) : null,
          values.motherEmail ? values.motherEmail : null,
          "parent1",
          studentObj
        );

        if (addResponse && addResponse.status === 200) {
          // mother number added successfully
          row.Mother_Added = "Yes";
          row.Mother_Failed = "";
          row.Mother_Error = "";
        } else if (
          addResponse &&
          addResponse.status === 201 &&
          !siblingRequest
        ) {
          let delResponse = yield call(
            StudentApi.deleteStudentApi,
            firebase,
            studentObj
          );
          console.log("delResponse --->", delResponse);

          if (addResponse && addResponse.body && addResponse.body.sibling) {
            let existingStudent = addResponse.body.sibling;
            let sibResponse = yield call(
              StudentApi.addSiblingApi,
              firebase,
              studentObj,
              existingStudent.id,
              existingStudent.name
            );

            if (
              sibResponse &&
              sibResponse.status &&
              sibResponse.status === 200
            ) {
              console.log("sibling added successfully");
              row.Mother_Added = "Yes";
              row.Mother_Failed = "";
              row.Mother_Error = "";
            } else {
              // log error
              row.Mother_Added = "";
              row.Mother_Failed = "Yes";
              row.Mother_Error =
                sibResponse.body && sibResponse.body.message
                  ? sibResponse.body.message
                  : "Failed to add";
            }
          } else {
            console.log(
              "can't add a sibling as the existing student is missing"
            );
            row.Mother_Added = "";
            row.Mother_Failed = "Yes";
            row.Mother_Error =
              addResponse.body && addResponse.body.message
                ? addResponse.body.message
                : "Failed to add";
          }
        } else {
          // log error
          row.Mother_Added = "";
          row.Mother_Failed = "Yes";
          row.Mother_Error =
            addResponse.body && addResponse.body.message
              ? addResponse.body.message
              : "Failed to add";
        }
      }
      studentList.push(row);
    } else {
      row.Mother_Failed = "Yes";
      row.Mother_Error = "Classroom name mismatch";
      row.Father_Failed = "Yes";
      row.Father_Error = "Classroom name mismatch";
      studentList.push(row);

      console.log("classroom not found");
      yield put({
        type: actions.ONBOARDINGG_REQUEST_FAILED,
        errorMessage: "classroom not found",
      });
    }
  }

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const fileName = "Status_Student_Onboarding_";

  var ws = XLSX.utils.json_to_sheet(studentList, {
    header: fields,
    dateNF: "DD-MMM-YYYY",
  });

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const newData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(newData, fileName + fileExtension);

  yield put({
    type: actions.UPLOAD_STUDENT_CSV_SUCCESSFUL,
  });
}

function* createParentEmailLogin(parentEmail, contact, firebase) {
  let email = parentEmail.trim();
  let editedId = replaceAll(email, "@", "%40");
  let encodedEmail = replaceAll(editedId, ".", "%2E");

  let existingUser = yield call(
    TeacherApi.checkIfUserExistInNewUser,
    contact,
    firebase
  );
  if (existingUser.status) {
    let existingEmailUser = yield call(
      TeacherApi.checkIfUserExistInNewUser,
      encodedEmail,
      firebase
    );
    let ifUpdateNeeded = false;
    if (existingEmailUser.status && existingEmailUser.id === existingUser.id) {
      ifUpdateNeeded = true;
    } else if (!existingEmailUser.status) {
      ifUpdateNeeded = true;
    }

    if (ifUpdateNeeded) {
      let existingObj = JSON.parse(JSON.stringify(existingUser));
      existingObj.status = null;
      yield call(StudentApi.updateNewUser, encodedEmail, existingObj, firebase);
      addFirebaseAuth(email, firebase);
      // yield fork(StudentApi.sendLoginInviteToParents, [parentEmail], firebase);
      /**send invite  */
    }
  }
}

function addFirebaseAuth(email, firebase) {
  var username = email.split("@")[0];
  var password = username.substr(0, 8);
  if (password.length < 8) {
    password = password + "123456";
  }

  firebase.auth.createUserWithEmailAndPassword(email, password).then((res) => {
    console.log("auth ---", res);
  });
}

function replaceAll(str, term, replacement) {
  return str.replace(new RegExp(escapeRegExp(term), "g"), replacement);
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function* uploadHolidays({ holidayCsvData, classrooms, firebase }) {
  for (let i = 0; i < classrooms.length; i++) {
    for (let j = 0; j < holidayCsvData.length; j++) {
      var holidayNodeId = yield call(
        StudentApi.generateUniqueCalendarReferenceId,
        classrooms[i],
        firebase
      );
      yield call(
        StudentApi.addCsvHolidayToClass,
        classrooms[i],
        holidayNodeId,
        holidayCsvData[j],
        firebase
      );
    }
  }
  // for (let j = 0; j < holidayCsvData.length; j++) {
  //   let allClass = "All Classrooms";
  //   var holidayNodeId = yield call(
  //     StudentApi.generateUniqueCalendarReferenceId,
  //     allClass,
  //     firebase
  //   );

  //   yield call(
  //     StudentApi.addCsvHolidayToClass,
  //     allClass,
  //     holidayNodeId,
  //     holidayCsvData[j],
  //     firebase
  //   );
  // }

  yield put({
    type: actions.UPLOAD_HOLIDAY_CSV_SUCCESSFUL,
  });
}

function* fetchOnboardingClassroom({ firebase }) {
  try {
    //let classrooms = yield call(StudentApi.getAutoCompleteClassrooms, firebase);
    let classrooms = JSON.parse(localStorage.getItem("classList"));
    if (classrooms) {
      yield put({
        type: actions.GET_ONBOARDING_CLASSROOM_SUCCESSFUL,
        classrooms: classrooms,
      });
    }
  } catch (err) {
    console.log("failed to fetch onboarding classrooms", err);
    yield put({
      type: actions.ONBOARDINGG_REQUEST_FAILED,
    });
  }
}

function* onboardTeachers({ teacherCsvData, firebase }) {
  try {
    let classroomData = yield call(
      StudentApi.getAutoCompleteClassrooms,
      firebase
    );
    let classNames = [];
    if (classroomData) {
      classroomData.map((c) => {
        classNames.push(c.className);
      });
    }

    if (classNames && classNames.length > 0) {
      for (let i = 0; i < teacherCsvData.length; i++) {
        var status = yield call(
          TeacherApi.checkIfUserExistInNewUser,
          teacherCsvData[i].phoneNumber,
          firebase
        );
        console.log(
          "teacher phoneNumber status",
          teacherCsvData[i].phoneNumber + "-->>" + status.status
        );
        if (status.status === false) {
          var nodeId = yield call(TeacherApi.createTeacherNode, firebase);

          if (nodeId) {
            yield call(
              TeacherApi.addTeacherEmailToNewUser,
              teacherCsvData[i].phoneNumber,
              nodeId,
              teacherCsvData[i].phoneNumber,
              "TEACHER",
              firebase
            );

            yield call(
              TeacherApi.addNewTeacherCsv,
              teacherCsvData[i].teacherName,
              teacherCsvData[i].gender,
              teacherCsvData[i].classroom,
              teacherCsvData[i].phoneNumber,
              teacherCsvData[i].address,
              teacherCsvData[i].emailAddress,
              teacherCsvData[i].role,
              teacherCsvData[i].approvalRequired,
              teacherCsvData[i].staffAttendancePermission,
              teacherCsvData[i].financePermission,
              teacherCsvData[i].studentAttendance,
              teacherCsvData[i].userManagementPermission,
              teacherCsvData[i].messagePermission,
              teacherCsvData[i].parentQueryNotification,
              firebase,
              nodeId,
              classNames
            );

            if (teacherCsvData[i].emailAddress) {
              yield fork(
                updateTeacherEmailLogin,
                teacherCsvData[i].emailAddress,
                teacherCsvData[i].phoneNumber,
                firebase
              );
            }
          }

          yield put({
            type: actions.UPLOAD_TEACHER_CSV_SUCCESSFUL,
          });
        } else {
          yield put({
            type: actions.ONBOARDINGG_REQUEST_FAILED,
            errorMessage: "Number already registered",
          });
        }
      }
    } else {
      yield put({
        type: actions.ONBOARDINGG_REQUEST_FAILED,
        errorMessage: "Class list could not be fetched or is empty",
      });
    }
  } catch (error) {
    console.log("failed to upload teacher csv data", error);
    yield put({
      type: actions.ONBOARDINGG_REQUEST_FAILED,
    });
  }
}

function* updateTeacherEmailLogin(teacherEmail, contact, firebase) {
  let email = teacherEmail.trim();
  let editedId = replaceAll(email, "@", "%40");
  let encodedEmail = replaceAll(editedId, ".", "%2E");

  let existingUser = yield call(
    TeacherApi.checkIfUserExistInNewUser,
    contact,
    firebase
  );

  if (existingUser.status) {
    let existingEmailUser = yield call(
      TeacherApi.checkIfUserExistInNewUser,
      encodedEmail,
      firebase
    );
    let ifUpdateNeeded = false;
    if (existingEmailUser.status && existingEmailUser.id === existingUser.id) {
      ifUpdateNeeded = true;
    } else if (!existingEmailUser.status) {
      ifUpdateNeeded = true;
    }

    if (ifUpdateNeeded) {
      let existingObj = JSON.parse(JSON.stringify(existingUser));
      existingObj.status = null;
      yield call(StudentApi.updateNewUser, encodedEmail, existingObj, firebase);
      yield fork(TeacherApi.sendInviteToTeacher, [email], firebase);
    }
  }
}

function* uploadSchedules({ scheduleCsvData, formValue, firebase }) {
  let startDate = new Date();
  let endDate = new Date();
  try {
    let classrooms = formValue.classroom;
    if (formValue.scheduleFrequency.trim() === "Multiple Day") {
      startDate = new Date(formValue.date[0]);
      endDate = new Date(formValue.date[1]);
    } else {
      startDate = new Date(formValue.date);
    }

    var weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";

    for (let i = 0; i < classrooms.length; i++) {
      let loop = new Date(startDate);
      while (loop <= endDate) {
        // console.log("date in loop", loop.getDate());

        for (let j = 0; j < scheduleCsvData.length; j++) {
          var x = loop.getDay();
          if (weekday[x].trim() === scheduleCsvData[j].day.trim()) {
            var nodeId = yield call(
              ScheduleApi.createNewScheduleNode,
              classrooms[i],
              loop,
              firebase
            );
            yield call(
              ScheduleApi.uploadScheduleCsv,
              classrooms[i],
              scheduleCsvData[j],
              loop,
              nodeId,
              firebase
            );
          }
        }

        var newDate = loop.setDate(loop.getDate() + 1);
        loop = new Date(newDate);
      }
    }
    yield put({
      type: actions.UPLOAD_SCHEDULE_CSV_SUCCESSFUL,
    });
  } catch (error) {
    console.log("failed to upload schedule", error);
    yield put({
      type: actions.ONBOARDINGG_REQUEST_FAILED,
    });
  }
}

function* uploadSchedulesForSingleDay({
  scheduleCsvData,
  formValue,
  firebase,
}) {
  try {
    let classrooms = formValue.classroom;
    var selectedDate = new Date(formValue.date);
    var endDate;

    if (formValue.repeatScheduleFrequencyFor7Day) {
      endDate = new Date(selectedDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    } else if (formValue.repeatScheduleFrequencyFor15Day) {
      endDate = new Date(selectedDate.getTime() + 15 * 24 * 60 * 60 * 1000);
    } else if (formValue.repeatScheduleFrequencyFor30Day) {
      endDate = new Date(selectedDate.getTime() + 30 * 24 * 60 * 60 * 1000);
    } else {
      endDate = selectedDate;
    }

    var weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";

    for (let i = 0; i < classrooms.length; i++) {
      let loop = new Date(selectedDate);

      while (loop <= endDate) {
        var x = loop.getDay();
        if (!formValue.saturdaySelect) {
          if (x !== 0 && x !== 6) {
            for (let j = 0; j < scheduleCsvData.length; j++) {
              var nodeId = yield call(
                ScheduleApi.createNewScheduleNode,
                classrooms[i],
                loop,
                firebase
              );
              yield call(
                ScheduleApi.uploadScheduleCsv,
                classrooms[i],
                scheduleCsvData[j],
                loop,
                nodeId,
                firebase
              );
            }
          }
        } else {
          if (x !== 0) {
            for (let j = 0; j < scheduleCsvData.length; j++) {
              let nodeId = yield call(
                ScheduleApi.createNewScheduleNode,
                classrooms[i],
                loop,
                firebase
              );
              yield call(
                ScheduleApi.uploadScheduleCsv,
                classrooms[i],
                scheduleCsvData[j],
                loop,
                nodeId,
                firebase
              );
            }
          }
        }
        var newDate = loop.setDate(loop.getDate() + 1);
        loop = new Date(newDate);
      }
    }
    yield put({
      type: actions.UPLOAD_SCHEDULE_CSV_SUCCESSFUL,
    });
  } catch (error) {
    console.log("failed to upload schedule", error);
    yield put({
      type: actions.ONBOARDINGG_REQUEST_FAILED,
    });
  }
}

function* uploadSchedulesForSpecificDateRange({
  scheduleCsvData,
  formValue,
  firebase,
}) {
  try {
    let classrooms = formValue.classroom;

    for (let i = 0; i < classrooms.length; i++) {
      for (let j = 0; j < scheduleCsvData.length; j++) {
        var nodeId = yield call(
          ScheduleApi.createNewScheduleNode,
          classrooms[i],
          scheduleCsvData[i].date,
          firebase
        );
        yield call(
          ScheduleApi.uploadScheduleCsv,
          classrooms[i],
          scheduleCsvData[j],
          scheduleCsvData[j].date,
          nodeId,
          firebase
        );
      }
    }
    yield put({
      type: actions.UPLOAD_SCHEDULE_CSV_SUCCESSFUL,
    });
  } catch (error) {
    console.log("failed to upload schedule", error);
    yield put({
      type: actions.ONBOARDINGG_REQUEST_FAILED,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.UPLOAD_STUDENT_CSV, onboardStudents),
    yield takeLatest(actions.UPLOAD_HOLIDAY_CSV, uploadHolidays),
    yield takeLatest(
      actions.GET_ONBOARDING_CLASSROOM,
      fetchOnboardingClassroom
    ),
    yield takeLatest(actions.UPLOAD_TEACHER_CSV, onboardTeachers),
    yield takeLatest(actions.UPLOAD_SCHEDULE_CSV, uploadSchedules),
    yield takeLatest(
      actions.UPLOAD_SINGLE_DAY_SCHEDULE_CSV,
      uploadSchedulesForSingleDay
    ),
    yield takeLatest(
      actions.UPLOAD_SPECIFIC_DATE_SCHEDULE_CSV,
      uploadSchedulesForSpecificDateRange
    ),
  ]);
}
