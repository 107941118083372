import actions from "./actions";
const initState = {
  classrooms: [],
  students: [],
  staffs: [],
  activityTemplate: [],
  activitySetting: [],
  isLoading: false,
  newActivityOperationType: undefined,
  staffActivityOperation: undefined,
  error: false,
  errorMessage: undefined,
  medicalFields: [],
  pottyFields: [],
  todaysFoodMenu: new Map(),
  centerClasses: new Map(),
  commentSetting: new Map(),
  activityLabel: [],
};
export default function newActivityReducer(
  state = initState,
  { type, ...action }
) {
  switch (type) {
    case actions.GET_STUDENT_TO_POST_NEW_ACTIVITY:
      return {
        ...state,
        isLoading: true,
        newActivityOperationType: undefined,
      };
    case actions.GET_STUDENT_TO_POST_NEW_ACTIVITY_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        newActivityOperationType: undefined,
        students: action.students,
      };

    case actions.GET_CLASSROOM_FOR_NEW_ACTIVITY:
      return {
        ...state,
        isLoading: true,
        newActivityOperationType: undefined,
      };
    case actions.GET_CLASSROOM_FOR_NEW_ACTIVITY_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        newActivityOperationType: undefined,
        classrooms: action.classrooms,
      };

    case actions.GET_ACTIVITY_TEMPLATE:
      return {
        ...state,
        // isLoading: true,
        newActivityOperationType: undefined,
      };
    case actions.GET_ACTIVITY_TEMPLATE_SUCCESSFUL:
      return {
        ...state,
        // isLoading: false,
        newActivityOperationType: undefined,
        activityTemplate: action.activityTemplate,
      };

    case actions.POST_ACTIVITY:
      return {
        ...state,
        isLoading: true,
        newActivityOperationType: "ACTIVITY_POST_INITIATED",
      };
    case actions.POST_ACTIVITY_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        newActivityOperationType: action.newActivityOperationType,
        staffActivityOperation: action.staffActivityOperation,
      };

    case actions.GET_STAFF_FOR_NEW_ACTIVITY:
      return {
        ...state,
        newActivityOperationType: undefined,
      };
    case actions.GET_STAFF_FOR_NEW_ACTIVITY_SUCCESSFUL:
      return {
        ...state,
        staffs: action.staffs,
        newActivityOperationType: undefined,
      };

    case actions.GET_ACTIVITY_SETTING:
      return {
        ...state,
        newActivityOperationType: undefined,
      };
    case actions.GET_ACTIVITY_SETTING_SUCCESSFUL:
      return {
        ...state,
        newActivityOperationType: undefined,
        activitySetting: action.activitySetting,
      };

    case actions.NEW_ACTIVITY_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actions.POST_ACTIVITY_TO_ALL_CENTER:
      return {
        ...state,
        isLoading: true,
        newActivityOperationType: undefined,
      };
    case actions.POST_ACTIVITY_TO_ALL_CENTER_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        newActivityOperationType: "ALL_CENTER_POST_ACTIVITY_SUCCESS",
      };

    case actions.RESET_ERROR:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: undefined,
        newActivityOperationType: undefined,
        staffActivityOperation: undefined,
      };

    case actions.RESET_STAFF_ACTIVITY:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: undefined,
        newActivityOperationType: undefined,
        staffActivityOperation: undefined,
      };

    case actions.GET_MEDICAL_FIELD:
      return {
        ...state,
      };
    case actions.GET_MEDICAL_FIELD_SUCCESS:
      return {
        ...state,
        medicalFields: action.medicalFields,
      };

    case actions.GET_POTTY_FIELD:
      return {
        ...state,
      };
    case actions.GET_POTTY_FIELD_SUCCESS:
      return {
        ...state,
        pottyFields: action.pottyFields,
      };

    case actions.GET_FOOD_MENU:
      return {
        ...state,
      };
    case actions.GET_FOOD_MENU_SUCCESS:
      return {
        ...state,
        todaysFoodMenu: action.todaysFoodMenu,
      };

    case actions.GET_CENTER_BASED_CLASSES:
      return {
        ...state,
        isLoading: false,
      };
    case actions.GET_CENTER_BASED_CLASSES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        centerClasses: action.centerClasses,
      };

    case actions.POST_ACTIVITY_ALL_CENTER:
      return {
        ...state,
        isLoading: true,
        newActivityOperationType: "ACTIVITY_POST_INITIATED",
      };
    case actions.POST_ACTIVITY_ALL_CENTER_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        newActivityOperationType: "POST_ACTIVITY_SUCCESS",
      };

    case actions.GET_COMMENT_SETTING:
      return {
        ...state,
      };
    case actions.GET_COMMENT_SETTING_SUCCESS:
      return {
        ...state,
        commentSetting: action.commentSetting,
      };

    case actions.GET_ACTIVITY_CUSTOM_LABEL_SUCCESS:
      return {
        ...state,
        activityLabel: action.activityLabel,
      };

    case actions.GET_INITIAL_VALUE:
      return {
        ...state,
      };

    case actions.GET_INITIAL_VALUE_SUCCESS:
      return {
        ...state,
        newActivityOperationType: "INIT_VALUE",
      };

    default:
      return state;
  }
}
