const actions = {
  GET_QUERYLIST: "GET_QUERYLIST",
  GET_QUERYLIST_SUCCESS: "GET_QUERYLIST_SUCCESS",

  QUERYLIST_REQUEST_FAILED: "QUERYLIST_REQUEST_FAILED",

  RESET_QUERYLIST_OPERATION: "RESET_QUERYLIST_OPERATION",

  GET_UNREAD_MESSAGE_COUNT: "GET_UNREAD_MESSAGE_COUNT",

  GET_PARENT_CHAT: "GET_PARENT_CHAT",
  GET_PARENT_CHAT_SUCCESS: "GET_PARENT_CHAT_SUCCESS",

  SEND_MESSAGE: "SEND_MESSAGE",
  SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",

  RESET_TEACHER_UNREAD_COUNT: "RESET_TEACHER_UNREAD_COUNT",

  GET_SCHOOL_MSG: "GET_SCHOOL_MSG",
  GET_SCHOOL_MSG_SUCCESS: "GET_SCHOOL_MSG_SUCCESS",

  SEND_SCHOOL_MSG: "SEND_SCHOOL_MSG",
  SEND_SCHOOL_MSG_SUCCESS: "SEND_SCHOOL_MSG_SUCCESS",

  GET_STAFF_QUERYLIST: "GET_STAFF_QUERYLIST",
  GET_STAFF_QUERYLIST_SUCCESS: "GET_STAFF_QUERYLIST_SUCCESS",

  GET_TEACHER_CHAT: "GET_TEACHER_CHAT",
  GET_TEACHER_CHAT_SUCCESS: "GET_TEACHER_CHAT_SUCCESS",

  SEND_TEACHER_MESSAGE: "SEND_TEACHER_MESSAGE",
  SEND_TEACHER_MESSAGE_SUCCESS: "SEND_TEACHER_MESSAGE_SUCCESS",

  RESET_STAFF_CHAT_UNREAD_COUNT: "RESET_STAFF_CHAT_UNREAD_COUNT",

  getQueryList: (firebase) => ({ type: actions.GET_QUERYLIST, firebase }),

  resetQueryOperation: () => ({
    type: actions.RESET_QUERYLIST_OPERATION,
  }),

  getParentChat: (parentId, firebase) => ({
    type: actions.GET_PARENT_CHAT,
    parentId,
    firebase,
  }),

  sendMessage: (message, parentId, chatListModel, firebase, img) => ({
    type: actions.SEND_MESSAGE,
    message,
    parentId,
    chatListModel,
    firebase,
    img,
  }),

  resetTeacherUnreadCount: (parentId, firebase) => ({
    type: actions.RESET_TEACHER_UNREAD_COUNT,
    parentId,
    firebase,
  }),

  getSchoolMessage: (parentId, student, firebase) => ({
    type: actions.GET_SCHOOL_MSG,
    parentId,
    student,
    firebase,
  }),

  sendSchoolMessage: (message, parentId, firebase, img) => ({
    type: actions.SEND_SCHOOL_MSG,
    message,
    parentId,
    firebase,
    img,
  }),

  getStaffQueryList: (firebase) => ({
    type: actions.GET_STAFF_QUERYLIST,
    firebase,
  }),

  getTeacherChat: (item, firebase) => ({
    type: actions.GET_TEACHER_CHAT,
    item,
    firebase,
  }),

  sendTeacherMessage: (message, chatListModel, firebase, img) => ({
    type: actions.SEND_TEACHER_MESSAGE,
    message,
    chatListModel,
    firebase,
    img,
  }),

  resetStaffChatUnreadCount: (chatList, firebase) => ({
    type: actions.RESET_STAFF_CHAT_UNREAD_COUNT,
    chatList,
    firebase,
  }),
};
export default actions;
