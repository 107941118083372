import { all, put, call, take, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import '@firebase/firestore' // 👈 If you're using firestore
import { zoomSettingApi } from "../../firestore-api/zoomSettingApi";
import { UserSettingApi } from "../../firestore-api/userSetting";
import bugsnagClient from '@bugsnag/js';

function* updateZoomConfiguration({ authToken, firebase }) {
    try {
        let response = yield call(zoomSettingApi.getZoomAccessAndRefreshToken, authToken, firebase);
        console.log("zoom response ", response);
        if (response.status && response.status === 200) {

            let schoolConfig = yield call(UserSettingApi.getUserSchoolDetails, firebase);
            console.log("School COnfig", schoolConfig)
            if (schoolConfig) {
                localStorage.setItem("schoolConfig", JSON.stringify(schoolConfig));
                firebase.schoolConfig = schoolConfig;
                let teacher = yield call(UserSettingApi.getUserDetail, firebase);
                console.log("Teacher object", teacher)
                if (teacher) {
                    localStorage.setItem("teacher", JSON.stringify(teacher));
                    firebase.teacher = teacher;
                }


            }
            yield put({
                type: actions.ADD_ZOOM_CONFIG_SUCCESSFUL,
                status: response.status
            })
        } else {
            yield put({
                type: actions.REQUEST_FAILED
            })
        }
    } catch (err) {
        bugsnagClient.notify(err);
        console.log("failed to get auth and refresh token");
    }
}
function* disconnectZoomConfiguration({ firebase }) {
    //check if individual account


    if (firebase.schoolConfig.personalZoom) {
        yield call(UserSettingApi.resetZoomUserDetail, firebase);
        console.log("Reset Teacher object")
    } else {
        yield call(UserSettingApi.resetZoomSchoolDetails, firebase);
        console.log("Reset School Config")
    }
    let schoolConfig = yield call(UserSettingApi.getUserSchoolDetails, firebase);
    console.log("School Config", schoolConfig)
    if (schoolConfig) {
        localStorage.setItem("schoolConfig", JSON.stringify(schoolConfig));
        firebase.schoolConfig = schoolConfig;
        let teacher = yield call(UserSettingApi.getUserDetail, firebase);
        console.log("Teacher object", teacher)
        if (teacher) {
            localStorage.setItem("teacher", JSON.stringify(teacher));
            firebase.teacher = teacher;
        }


    }
    yield put({
        type: actions.ADD_ZOOM_CONFIG_SUCCESSFUL,
        status: "disconnected"
    })

}

function* initFirebaseConfiguration({ firebase }) {
    const chan = yield call(UserSettingApi.getUserSchoolConfig, firebase);
    try {
        while (true) {
            let data = yield take(chan);
            console.log("school config data", data);
            if (data) {
                localStorage.setItem("schoolConfig", JSON.stringify(data));
                firebase.schoolConfig = data;
            }
            yield put({
                type: actions.INIT_FIREBASE_CONFIG_SUCCESS,
                configChan: chan
            });
        }
    } finally {
        console.log("close channel");
    }
}

function* refreshTeacherObject({ firebase }) {
    const chan = yield call(UserSettingApi.fetchTeacher, firebase.teacher.id, firebase);
    try {
        while (true) {
            let data = yield take(chan);
            console.log("teacher data", data);
            if (data) {
                localStorage.setItem("teacher", JSON.stringify(data));
                firebase.teacher = data;
            }
            yield put({
                type: actions.GET_TEACHER_SUCCESS,
                teacherChan: chan
            });
        }
    } finally {
        console.log("close channel");
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(actions.ADD_ZOOM_CONFIG, updateZoomConfiguration),
        yield takeLatest(actions.DISCONNECT_ZOOM_CONFIG, disconnectZoomConfiguration),
        yield takeLatest(actions.INIT_FIREBASE_CONFIG, initFirebaseConfiguration),
        yield takeLatest(actions.GET_TEACHER, refreshTeacherObject),
    ])
}