const actions = {
  LIST_INVOICE_TEMPLATE: "LIST_INVOICE_TEMPLATE",
  LIST_INVOICE_TEMPLATE_SUCCESSFUL: "LIST_INVOICE_TEMPLATE_SUCCESSFUL",

  ADD_INVOICE_TEMPLATE: "ADD_INVOICE_TEMPLATE",
  ADD_INVOICE_TEMPLATE_SUCCESSFUL: "ADD_INVOICE_TEMPLATE_SUCCESSFUL",

  UPDATE_INVOICE_TEMPLATE: "UPDATE_INVOICE_TEMPLATE",
  UPDATE_INVOICE_TEMPLATE_SUCCESSFUL: "UPDATE_INVOICE_TEMPLATE_SUCCESSFUL",

  GET_STUDENT_FOR_TEMPLATE: "GET_STUDENT_FOR_TEMPLATE",
  GET_STUDENT_FOR_TEMPLATE_SUCCESSFFUL: "GET_STUDENT_FOR_TEMPLATE_SUCCESSFFUL",

  ASSIGN_STUDENT_TO_TEMPLATE: "ASSIGN_STUDENT_TO_TEMPLATE",
  ASSIGN_STUDENT_TO_TEMPLATE_SUCCESSFUL:
    "ASSIGN_STUDENT_TO_TEMPLATE_SUCCESSFUL",

  DELETE_INVOICE_TEMPLATE: "DELETE_INVOICE_TEMPLATE",
  DELETE_INVOICE_TEMPLATE_SUCCESSFFUL: "DELETE_INVOICE_TEMPLATE_SUCCESSFFUL",

  TEMPLATE_REQUEST_FAILED: "TEMPLATE_REQUEST_FAILED",

  RESET_INVOICE_TEMPLATE_OPERATION: "RESET_INVOICE_TEMPLATE_OPERATION",

  FETCH_TEMPLATE_TAX_REF: "FETCH_TEMPLATE_TAX_REF",
  FETCH_TEMPLATE_TAX_REF_SUCCESS: "FETCH_TEMPLATE_TAX_REF_SUCCESS",

  GET_TEMPLATE_LABEL: "GET_TEMPLATE_LABEL",
  GET_TEMPLATE_LABEL_SUCCESS: "GET_TEMPLATE_LABEL_SUCCESS",

  initInvoiceTemplateData: (firebase) => ({
    type: actions.LIST_INVOICE_TEMPLATE,
    firebase,
  }),

  getTemplateLabel: (firebase) => ({
    type: actions.GET_TEMPLATE_LABEL,
    firebase,
  }),

  addNewTemplate: (values, rows, firebase, taxDescription, tax) => ({
    type: actions.ADD_INVOICE_TEMPLATE,
    values,
    rows,
    firebase,
    taxDescription,
    tax,
  }),

  updateExistingTemplate: (
    values,
    rows,
    id,
    firebase,
    taxDescription,
    tax
  ) => ({
    type: actions.UPDATE_INVOICE_TEMPLATE,
    values,
    rows,
    id,
    firebase,
    taxDescription,
    tax,
  }),

  getStudentsToAssignTemplate: (firebase) => ({
    type: actions.GET_STUDENT_FOR_TEMPLATE,
    firebase,
  }),

  assignStudentToFeeTemplate: (record, selectedStudentCheckbox, firebase) => ({
    type: actions.ASSIGN_STUDENT_TO_TEMPLATE,
    record,
    selectedStudentCheckbox,
    firebase,
  }),

  deleteSelectedTemplate: (record, firebase) => ({
    type: actions.DELETE_INVOICE_TEMPLATE,
    record,
    firebase,
  }),

  resetInvoiceTemplateOperation: () => ({
    type: actions.RESET_INVOICE_TEMPLATE_OPERATION,
  }),

  fetchTemplateTaxRef: (firebase) => ({
    type: actions.FETCH_TEMPLATE_TAX_REF,
    firebase,
  }),
};
export default actions;
