import actions from "./actions";
const initState = {
  invoice: [],
  invoiceTemplates: [],
  aggregatedInvoice: [],
  studentData: [],
  invoiceDownloadUrl: {},
  isLoading: false,
  error: false,
  operationType: "",
  receiptList: [],
  studentAggregatedInvoice: [],
  studentAggregatedInvoiceChan: undefined,
  creditHistory: [],
  totalCreditHistory: [],
  studentOnlineClassInvoice: [],
  transactionId: undefined,
  feePlanDetail: undefined,
  savedCardDetail: undefined,
  studentFeePlanMap: new Map(),
  feePlanLoading: false,
  taxReference: [],
  creditPdf: undefined,
  invoicePrograms: [],
};
export default function invoiceReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.LIST_INVOICE:
      return {
        ...state,
        isLoading: true,
        operationType: "",
        invoiceDownloadUrl: {},
      };
    case actions.LIST_INVOICE_SUCCESSFULL:
      return {
        ...state,
        invoice: action.invoice,
        isLoading: false,
        operationType: "",
        invoiceDownloadUrl: {},
      };

    case actions.GET_INVOICE_AGGREGATED:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_INVOICE_AGGREGATED_SUCCESSFULL:
      return {
        ...state,
        aggregatedInvoice: action.aggregatedInvoice,
        isLoading: false,
        operationType: "",
      };

    case actions.GET_INVOICE_TEMPLATES:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_INVOICE_TEMPLATES_SUCCESSFUL:
      return {
        ...state,
        invoiceTemplates: action.invoiceTemplates,
        isLoading: false,
        operationType: "",
      };

    case actions.GET_INVOICE_DOWNLOAD_URL:
      return {
        ...state,
        isLoading: true,
        invoiceDownloadUrl: {},
        operationType: "",
      };
    case actions.GET_INVOICE_DOWNLOAD_URL_SUCCESSFUL:
      return {
        ...state,
        invoiceDownloadUrl: action.invoiceDownloadUrl,
        isLoading: false,
        operationType: "DOWNLOAD_URL",
      };

    case actions.GET_STUDENT_FOR_INVOICE:
      return {
        ...state,
        isLoading: true,
        operationType: "",
      };
    case actions.GET_STUDENT_FOR_INVOICE_SUCCESSFUL:
      return {
        ...state,
        studentData: action.studentData,
        isLoading: false,
        operationType: "",
      };

    case actions.GET_PROGRAMS_FOR_INVOICE_SUCCESSFUL:
      return {
        ...state,
        invoicePrograms: action.invoicePrograms,
      };

    case actions.ADD_NEW_INVOICE:
      return {
        ...state,
        isLoading: true,
        operationType: "",
      };
    case actions.ADD_NEW_INVOICE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "ADD_INVOICE",
      };

    case actions.UPDATE_SELECTED_INVOICE:
      return {
        ...state,
        isLoading: true,
        operationType: "",
      };
    case actions.UPDATE_SELECTED_INVOICE_SUCCESSFFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "UPDATE_INVOICE",
      };

    case actions.RECEIVE_PAYMENT:
      return {
        ...state,
        isLoading: true,
        operationType: "",
      };
    case actions.RECEIVE_PAYMENT_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "RECEIVE_PAYMENT",
      };

    case actions.DELETE_INVOICE:
      return {
        ...state,
        isLoading: true,
        operationType: "",
      };
    case actions.DELETE_INVOICE_SUCCESSFFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "DELETE_INVOICE",
      };

    case actions.INVOICE_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case actions.RESET_INVOICE_URL:
      return {
        ...state,
        operationType: undefined,
        invoiceDownloadUrl: {},
        error: false,
        isLoading: false,
        feePlanLoading: false,
      };

    case actions.DOWNLOAD_INVOICE_EXCEL_SHEET:
      return {
        ...state,
        isLoading: true,
      };

    case actions.DOWNLOAD_INVOICE_EXCEL_SHEET_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
      };

    case actions.SEND_PAYMENT_REMINDER:
      return {
        ...state,
      };

    case actions.SEND_PAYMENT_REMINDER_SUCCESSFUL:
      return {
        ...state,
        operationType: "PAYMENT_REMINDER",
      };

    case actions.DELETE_PAYMENT_RECORD:
      return {
        ...state,
        isLoading: true,
      };

    case actions.DELETE_PAYMENT_RECORD_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "DELETE_SINGLE_RECORD",
      };

    case actions.REFUND_AMT:
      return {
        ...state,
        isLoading: true,
        operationType: "",
      };
    case actions.REFUND_AMT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "REFUND_PAYMENT",
      };

    case actions.GET_RECEIPT_LIST:
      return {
        ...state,
        isLoading: true,
        operationType: "",
        receiptList: [],
      };
    case actions.GET_RECEIPT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        receiptList: action.receiptList,
      };

    case actions.GET_STUDENT_AGGREAGTED_INVOICES:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_STUDENT_AGGREAGTED_INVOICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        studentAggregatedInvoice: action.studentAggregatedInvoice,
        studentAggregatedInvoiceChan: action.studentAggregatedInvoiceChan,
        operationType: action.operationType,
      };

    case actions.GET_SINGLE_STUDENT_INVOICES:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
        invoiceDownloadUrl: {},
        invoice: [],
      };
    case actions.GET_SINGLE_STUDENT_INVOICES_SUCCESS:
      return {
        ...state,
        invoice: action.invoice,
        isLoading: false,
        operationType: undefined,
        invoiceDownloadUrl: {},
      };

    case actions.GET_SINGLE_STUDENT_AGGREGATED_INVOICE:
      return {
        ...state,
      };
    case actions.GET_SINGLE_STUDENT_AGGREGATED_INVOICE_SUCCESS:
      return {
        ...state,
        aggregatedInvoice: action.aggregatedInvoice,
        operationType: "",
      };

    case actions.IGNORE_LATE_FEE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.IGNORE_LATE_FEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: undefined,
      };

    case actions.REMOVE_REFUND:
      return {
        ...state,
        isLoading: true,
        operationType: "",
      };
    case actions.REMOVE_REFUND_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "REFUND_REMOVED",
      };

    case actions.SAVE_CREDIT:
      return {
        ...state,
        isLoading: true,
        operationType: "",
      };
    case actions.SAVE_CREDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "CREDIT_UPDATE",
      };

    case actions.GET_CREDIT_HISTORY:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_CREDIT_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        creditHistory: action.creditHistory,
      };

    case actions.GET_ALL_CREDIT_HISTORY:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_ALL_CREDIT_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        totalCreditHistory: action.totalCreditHistory,
      };

    case actions.DOWNLOAD_ALL_CREDIT_HISTORY:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DOWNLOAD_ALL_CREDIT_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actions.DELETE_CREDIT:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_CREDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "CREDIT_DELETE",
      };

    case actions.DELETE_CREDIT_DENIED:
      return {
        ...state,
        isLoading: false,
      };

    case actions.GET_STUDENT_ONLINE_CLASS_INVOICE:
      return {
        ...state,
        isLoading: true,
        operationType: "",
        invoiceDownloadUrl: {},
      };
    case actions.GET_STUDENT_ONLINE_CLASS_INVOICE_SUCCESS:
      return {
        ...state,
        studentOnlineClassInvoice: action.studentOnlineClassInvoice,
        isLoading: false,
        operationType: "",
        invoiceDownloadUrl: {},
      };

    case actions.GENERATE_PAYMENT_TRANSACTION_ID:
      return {
        ...state,
        transactionId: undefined,
      };

    case actions.GENERATE_PAYMENT_TRANSACTION_ID_SUCCESS:
      return {
        ...state,
        transactionId: action.transactionId,
      };

    case actions.SETTLE_PAYMENT:
      return {
        ...state,
        isLoading: true,
      };

    case actions.SETTLE_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "PAYMENT_SUCCESS",
      };

    case actions.UPDATED_FAILED_PAYMENT:
      return {
        ...state,
        isLoading: true,
      };

    case actions.UPDATED_FAILED_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "PAYMENT_FAILED",
      };

    case actions.GET_ONLINE_CLASS_PLAN_DETAIL:
      return {
        ...state,
        isLoading: true,
        feePlanDetail: undefined,
      };

    case actions.GET_ONLINE_CLASS_PLAN_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        feePlanDetail: action.feePlanDetail,
      };

    case actions.SAVE_CARD_DETAILS:
      return {
        ...state,
      };

    case actions.FETCH_CARD_DETAILS:
      return {
        ...state,
        savedCardDetail: undefined,
      };

    case actions.FETCH_CARD_DETAILS_SUCCESS:
      return {
        ...state,
        savedCardDetail: action.savedCardDetail,
      };

    case actions.AUTO_CHARGE_PAYMENT:
      return {
        ...state,
        isLoading: true,
      };

    case actions.AUTO_CHARGE_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "RECEIVE_PAYMENT",
      };

    case actions.GET_STUDENTS_FEE_PLAN:
      return {
        ...state,
        feePlanLoading: true,
      };

    case actions.GET_STUDENTS_FEE_PLAN_SUCCESS:
      return {
        ...state,
        feePlanLoading: false,
        studentFeePlanMap: action.studentFeePlanMap,
      };

    case actions.GET_TAX_REF_SUCCESS:
      return {
        ...state,
        taxReference: action.taxReference,
      };

    case actions.REFRESH_PDF:
      return {
        ...state,
        isLoading: true,
      };

    case actions.REFRESH_PDF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "REFRESH_PDF",
      };

    case actions.GET_CREDIT_PDF:
      return {
        ...state,
        // isLoading: true,
        creditPdf: undefined,
      };

    case actions.GET_CREDIT_PDF_SUCCESS:
      return {
        ...state,
        // isLoading: false,
        operationType: "CREDIT_PDF",
        creditPdf: action.creditPdf,
      };

    default:
      return state;
  }
}
