const actions = {
  GET_ALL_SENT_NEWSLETTER: "GET_ALL_SENT_NEWSLETTER",
  GET_ALL_SENT_NEWSLETTER_SUCCESS: "GET_ALL_SENT_NEWSLETTER_SUCCESS",

  initNewsletterData: firebase => ({
    type: actions.GET_ALL_SENT_NEWSLETTER,
    firebase
  }),

  getAllSentNewsletter: () => ({
    type: actions.GET_ALL_SENT_NEWSLETTER
  })
};
export default actions;
