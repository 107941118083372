import { all, put, call, take, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore";
import bugsnagClient from "@bugsnag/js";
import { QueryApi } from "../../firestore-api/query";
import { NotificationApi } from "../../firestore-api/notification";
import { ActivityApi } from "../../firestore-api/activity";
import { lessonAssignmentApi } from "../../firestore-api/lessonAssignment";
import moment from "moment-timezone";

function* fetchQueryList({ firebase }) {
  const chan = yield call(QueryApi.getParentChatList, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_QUERYLIST_SUCCESS,
        queryList: data,
        queryListChannel: chan,
      });

      yield fork(getUnreadCountKey, data, firebase);
      yield fork(resetParentUnreadNode, firebase);
    }
  } finally {
    console.log("end query list channel");
  }
}

function* getUnreadCountKey(chatList, firebase) {
  try {
    let chats = chatList;
    let teacher = firebase.teacher;

    let unreadMessages = new Map();
    let chatTask = [];
    if (teacher && teacher.id) {
      let teacherId = teacher.id;
      for (let index in chats) {
        if (chats[index].chatListModel && chats[index].chatListModel.id) {
          let parentId = chats[index].chatListModel.id;
          let task = call(
            QueryApi.getUnreadMessageKey,
            parentId,
            teacherId,
            firebase
          );
          if (task) {
            chatTask.push(task);
          }
        }
      }

      let newVal = yield all([chatTask]);
      for (let i in newVal[0]) {
        let data = newVal[0][i];
        unreadMessages = new Map([...unreadMessages, ...data]);
      }

      yield put({
        type: actions.GET_UNREAD_MESSAGE_COUNT,
        unreadMessages: unreadMessages,
      });
    }
  } catch (err) {
    console.log("failed to fetch unread msg count", err);
    bugsnagClient.notify(err);
  }
}

function* resetParentUnreadNode(firebase) {
  try {
    let teacher = firebase.teacher;
    let teacherId = teacher.id;
    if (teacherId) {
      yield fork(QueryApi.resetTeacherUnread, teacherId, firebase);
    }
  } catch (err) {
    console.log("failed to reset teacher unread key", err);
    bugsnagClient.notify(err);
  }
}

function* fetchParentChat({ parentId, firebase }) {
  try {
    const chan = yield call(QueryApi.getParentChats, parentId, firebase);
    while (true) {
      let data = yield take(chan);
      let messages = data;
      yield put({
        type: actions.GET_PARENT_CHAT_SUCCESS,
        parentChat: messages,
        parentChatChannel: chan,
      });
    }
  } finally {
    console.log("terminating parent chat channel");
  }
}

function* sendChatMessage({ message, parentId, chatListModel, firebase, img }) {
  try {
    let urls = [];
    let nodeId = yield call(QueryApi.createChatNode, firebase);

    if (img) {
      let storagePath = firebase.sbp + "/media/images/";
      let mediaPaths = yield call(
        ActivityApi.getMediaPath,
        storagePath,
        img,
        firebase
      );
      if (mediaPaths) {
        urls = mediaPaths;
      }
    }

    yield call(
      QueryApi.putChatMessage,
      parentId,
      message,
      nodeId,
      urls,
      firebase
    );

    let chatList = yield call(
      QueryApi.getChatListOfSelectedParent,
      parentId,
      firebase
    );

    let freshChatList = chatListModel;
    if (chatList) {
      freshChatList = chatList;
    }

    if (freshChatList) {
      yield fork(
        QueryApi.updateUnreadCount,
        freshChatList,
        parentId,
        message,
        firebase
      );

      let activityName = "Message";
      let activityId = nodeId;
      let body =
        "New Message received from " + firebase.teacher.name + ": " + message;
      let students = JSON.parse(localStorage.getItem("studentList"));

      let selectedStudent = students.filter((i) => {
        return (
          (i.fatherProfileId && i.fatherProfileId === parentId) ||
          (i.motherProfileId && i.motherProfileId === parentId)
        );
      });

      // let relation = freshChatList.chatListModel.relation;
      // let selectedStudent;
      // if (relation.toLowerCase() === "father") {
      //     selectedStudent = students.filter(i => { return i.fatherProfileId === parentId });
      // } else if (relation.toLowerCase() === "mother") {
      //     selectedStudent = students.filter(i => { return i.motherProfileId === parentId });
      // }

      if (
        selectedStudent &&
        selectedStudent[0] &&
        selectedStudent[0].fatherProfileId
      ) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          selectedStudent[0].fatherProfileId,
          firebase
        );

        yield fork(
          NotificationApi.createAlertNotification,
          activityName,
          activityId,
          selectedStudent[0].fatherUUid ? selectedStudent[0].fatherUUid : null,
          body,
          alertNode,
          selectedStudent[0].ios_fatherUUid
            ? selectedStudent[0].ios_fatherUUid
            : null,
          selectedStudent[0].id,
          selectedStudent[0].fatherProfileId,
          firebase
        );

        if (
          selectedStudent[0].fatherUUid !== undefined ||
          selectedStudent[0].ios_fatherUUid !== undefined
        ) {
          yield fork(
            NotificationApi.sendPushNotification,
            activityName,
            activityId,
            selectedStudent[0].fatherUUid
              ? selectedStudent[0].fatherUUid
              : null,
            body,
            alertNode,
            selectedStudent[0].ios_fatherUUid
              ? selectedStudent[0].ios_fatherUUid
              : null,
            selectedStudent[0].id,
            selectedStudent[0].fatherProfileId,
            firebase
          );
        }
      }

      if (
        selectedStudent &&
        selectedStudent[0] &&
        selectedStudent[0].motherProfileId
      ) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          selectedStudent[0].motherProfileId,
          firebase
        );

        yield fork(
          NotificationApi.createAlertNotification,
          activityName,
          activityId,
          selectedStudent[0].motherUUid ? selectedStudent[0].motherUUid : null,
          body,
          alertNode,
          selectedStudent[0].ios_motherUUid
            ? selectedStudent[0].ios_motherUUid
            : null,
          selectedStudent[0].id,
          selectedStudent[0].motherProfileId,
          firebase
        );

        if (
          selectedStudent[0].motherUUid !== undefined ||
          selectedStudent[0].ios_motherUUid !== undefined
        ) {
          yield fork(
            NotificationApi.sendPushNotification,
            activityName,
            activityId,
            selectedStudent[0].motherUUid
              ? selectedStudent[0].motherUUid
              : null,
            body,
            alertNode,
            selectedStudent[0].ios_motherUUid
              ? selectedStudent[0].ios_motherUUid
              : null,
            selectedStudent[0].id,
            selectedStudent[0].motherProfileId,
            firebase
          );
        }
      }
    }
    yield put({
      type: actions.SEND_MESSAGE_SUCCESS,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.QUERYLIST_REQUEST_FAILED,
      errorMessage: err.message
        ? err.message
        : "Failed to send chat message. Try again or contact Illumine",
    });
  }
}

function* resetUnreadMessageCountTeacher({ parentId, firebase }) {
  try {
    // check if unread key is being reset
    let chatList = yield call(
      QueryApi.getChatListOfSelectedParent,
      parentId,
      firebase
    );
    if (chatList && chatList.chatListModel) {
      yield fork(
        QueryApi.resetTeacherUnreadMessageCount,
        parentId,
        chatList,
        firebase
      );
    }

    if (firebase.teacher && firebase.teacher.id) {
      yield fork(
        QueryApi.resetUnreadMessageKey,
        parentId,
        firebase.teacher.id,
        firebase
      );
    }
  } catch (err) {
    bugsnagClient.notify(err);
  }
}

function* fetchSchoolMessage({ parentId, student, firebase }) {
  const chan = yield call(QueryApi.getParentSideChats, parentId, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      let messages = data;
      yield put({
        type: actions.GET_SCHOOL_MSG_SUCCESS,
        schoolChat: messages,
        schoolChatChannel: chan,
      });

      yield fork(resetSchoolUnreadMessage, firebase);
    }
  } finally {
    console.log("terminating fetch school message");
  }
}

function* resetSchoolUnreadMessage(firebase) {
  try {
    let parentId = firebase.user.id;
    let chatList = yield call(
      QueryApi.getChatListOfSelectedParent,
      parentId,
      firebase
    );
    if (chatList && chatList.parentUnreadMessageCount > 0 && !firebase.isGod) {
      yield call(
        QueryApi.resetParentUnreadMessageInChatlist,
        parentId,
        chatList,
        firebase
      );
    }
  } catch (err) {
    console.log("failed to reset school unread message", err);
    bugsnagClient.notify(err);
  }
}

function* postSchoolMessage({ message, parentId, firebase, img }) {
  try {
    let urls = [];
    let nodeId = yield call(QueryApi.createChatNode, firebase);

    if (img) {
      let storagePath = firebase.sbp + "/media/images/";
      let mediaPaths = yield call(
        ActivityApi.getMediaPath,
        storagePath,
        img,
        firebase
      );
      if (mediaPaths) {
        urls = mediaPaths;
      }
    }

    yield call(
      QueryApi.putChatMessageByParent,
      parentId,
      message,
      nodeId,
      urls,
      firebase
    );

    let chatList = yield call(
      QueryApi.getChatListOfSelectedParent,
      parentId,
      firebase
    );
    let freshChatList = chatList;
    if (freshChatList) {
      yield fork(
        QueryApi.updateUnreadCountByParent,
        freshChatList,
        parentId,
        message,
        firebase
      );
    } else {
      yield fork(QueryApi.createChatListByParent, parentId, message, firebase);
    }

    yield fork(updateSchoolTeacherUnreadCount, firebase, nodeId, message);
  } catch (err) {
    console.log("failed to post message send by parent", err);
    bugsnagClient.notify(err);
  }
}

function* updateSchoolTeacherUnreadCount(firebase, nodeId, message) {
  try {
    let studentDetail = firebase.student;
    let classroom = studentDetail.classList
      ? studentDetail.classList[0]
      : studentDetail.classroomName;
    let parentId = firebase.user.id;

    let teachers = yield call(
      lessonAssignmentApi.getTeachersByStudentClass,
      classroom,
      firebase
    );
    if (teachers && teachers.length > 0) {
      for (let index in teachers) {
        let data = yield call(
          QueryApi.getUnreadMessageKey,
          parentId,
          teachers[index].id,
          firebase
        );
        let unreadKeyObj = {};
        if (data && data.teacherId && data.count) {
          unreadKeyObj = data;
          unreadKeyObj.count = data.count + 1;
          yield fork(
            QueryApi.updateTeacherUnreadKey,
            parentId,
            teachers[index].id,
            unreadKeyObj,
            firebase
          );
        } else {
          unreadKeyObj.count = 1;
          unreadKeyObj.teacherId = teachers[index].id;
          unreadKeyObj.studentId = studentDetail.id;
          yield fork(
            QueryApi.updateTeacherUnreadKey,
            parentId,
            teachers[index].id,
            unreadKeyObj,
            firebase
          );
        }
      }

      yield fork(
        sendNotificationToTeachers,
        teachers,
        firebase,
        nodeId,
        message
      );
    }
  } catch (err) {
    console.log("failed to update school teacher unread count", err);
    bugsnagClient.notify(err);
  }
}

function* sendNotificationToTeachers(teachers, firebase, nodeId, message) {
  let student = firebase.student;
  let studentId = student.id;
  let parentId = firebase.user.id;
  let parentName;
  if (student.fatherProfileId && student.fatherProfileId === parentId) {
    parentName = student.fatherName;
  } else if (student.motherProfileId && student.motherProfileId === parentId) {
    parentName = student.motherName;
  }

  let activityName = "Message";
  let activityType = "Message";
  let activityId = nodeId;
  let alertMessage = "New Message received from " + parentName + ": " + message;

  for (let index in teachers) {
    let teacher = teachers[index];
    let permissionStatus = yield call(
      NotificationApi.receiveNotificationPermission,
      activityName,
      activityType,
      teacher
    );
    if (permissionStatus) {
      let alertNode = yield call(
        NotificationApi.createAlertReferenceNode,
        teacher.id,
        firebase
      );

      yield fork(
        NotificationApi.createSimpleAlertNotification,
        activityName,
        activityId,
        teacher.uid ? teacher.uid : null,
        alertMessage,
        alertNode,
        teacher.ios_uid ? teacher.ios_uid : null,
        studentId,
        teacher.id,
        activityType,
        undefined,
        firebase,
        parentName
      );

      if (teacher.uid !== undefined || teacher.ios_uid !== undefined) {
        yield fork(
          NotificationApi.sendSimplePushNotification,
          activityName,
          activityId,
          teacher.uid ? teacher.uid : null,
          alertMessage,
          alertNode,
          teacher.ios_uid ? teacher.ios_uid : null,
          studentId,
          teacher.id,
          activityType,
          undefined,
          firebase,
          parentName
        );
      }
    }
  }
}

function* fetchStaffQueryList({ firebase }) {
  const chan = yield call(QueryApi.getStaffChatList, firebase);
  try {
    while (true) {
      let data = yield take(chan);

      yield put({
        type: actions.GET_STAFF_QUERYLIST_SUCCESS,
        staffQueryList: data,
        staffQueryListChannel: chan,
      });
    }
  } finally {
    console.log("end query list channel");
  }
}

function* fetchTeacherChat({ item, firebase }) {
  let nodeId = item.senderId + "**" + item.receiverId;
  let teacherId = firebase.teacher.id;

  try {
    const chan = yield call(QueryApi.getTeacherChats, nodeId, firebase);
    while (true) {
      let x = yield take(chan);

      let msg = [];
      for (var i = x.length - 1; i >= 0; i--) {
        var chatDate = moment.utc(x[i].createdAt.time);
        msg.push({
          id: Math.round(Math.random() * 1000000),
          text: x[i].text,
          image: x[i].imageUrl ? x[i].imageUrl : null,
          createdAt: new Date(chatDate),
          user: {
            id: x[i].id === teacherId ? 1 : 2,
            lastIndex: i === x.length - 1 ? true : false,
          },
        });
      }

      yield put({
        type: actions.GET_TEACHER_CHAT_SUCCESS,
        teacherChat: msg,
        teacherChatChannel: chan,
      });
    }
  } finally {
    console.log("terminating teacher chat channel");
  }
}

function* sendTeacherChatMessage({ message, chatListModel, firebase, img }) {
  try {
    let chatList = chatListModel;
    let nodeId = chatList.senderId + "**" + chatList.receiverId;
    let teacherId = firebase.teacher.id;
    let receiverId = chatList.teacherId;
    let urls = [];

    let uniqueNode = yield call(
      QueryApi.getTeacherConversationUniqueNodeId,
      firebase
    );
    if (nodeId) {
      if (img) {
        let storagePath = firebase.sbp + "/media/images/";
        let mediaPaths = yield call(
          ActivityApi.getMediaPath,
          storagePath,
          img,
          firebase
        );
        if (mediaPaths) {
          urls = mediaPaths;
        }
      }

      //put msg
      yield call(
        QueryApi.putStaffChatMessage,
        nodeId,
        teacherId,
        message,
        uniqueNode,
        urls,
        firebase
      );

      let singleMessage = message[0];
      //case 1
      //update personal node (sendId/receiverid)

      let senderChatList = chatList;
      senderChatList.lastMessage = singleMessage;
      senderChatList.lastSenderId = teacherId;
      senderChatList.inverseDate = -new Date().getTime();
      senderChatList.read = false;
      yield fork(
        QueryApi.updateStaffChatListModal,
        teacherId,
        receiverId,
        senderChatList,
        firebase
      );

      //case 2 (receiver id/ senderId)
      let receiversChatList = yield call(
        QueryApi.getTeacherChatListById,
        receiverId,
        teacherId,
        firebase
      );

      let tempReceiversChatList = {};
      if (receiversChatList && receiversChatList.teacherId) {
        tempReceiversChatList = receiversChatList;
        tempReceiversChatList.unreadCount = receiversChatList.unreadCount
          ? receiversChatList.unreadCount + 1
          : 1;
        tempReceiversChatList.lastMessage = singleMessage;
        tempReceiversChatList.lastSenderId = teacherId;
        tempReceiversChatList.inverseDate = -new Date().getTime();
        tempReceiversChatList.read = true;

        yield fork(
          QueryApi.updateStaffChatListModal,
          receiverId,
          teacherId,
          tempReceiversChatList,
          firebase
        );
      } else {
        let teacher = firebase.teacher;

        if (teacher && teacher.id) {
          let receivingStaff = teacher;
          tempReceiversChatList.gender = receivingStaff.gender;
          tempReceiversChatList.inverseDate = -new Date().getTime();
          tempReceiversChatList.iosId = receivingStaff.ios_uid
            ? receivingStaff.ios_uid
            : null;
          tempReceiversChatList.lastAccess = receivingStaff.lastAccess
            ? receivingStaff.lastAccess
            : null;
          tempReceiversChatList.lastMessage = singleMessage;
          tempReceiversChatList.lastSenderId = teacherId;
          tempReceiversChatList.name = receivingStaff.name;
          tempReceiversChatList.profileImageUrl = receivingStaff.profileImageUrl
            ? receivingStaff.profileImageUrl
            : null;
          tempReceiversChatList.read = true;
          tempReceiversChatList.receiverId = senderChatList.receiverId;
          tempReceiversChatList.senderId = senderChatList.senderId;
          tempReceiversChatList.teacherId = receivingStaff.id;
          tempReceiversChatList.uid = receivingStaff.uid
            ? receivingStaff.uid
            : null;
          tempReceiversChatList.unreadCount = 1;

          yield fork(
            QueryApi.updateStaffChatListModal,
            receiverId,
            teacherId,
            tempReceiversChatList,
            firebase
          );
        }
      }

      //notification flow
      let allTeachers = JSON.parse(localStorage.getItem("teacherList"));
      let senderName = firebase.teacher.name;
      let receiverProfile = allTeachers.filter((t) => {
        return t.id === receiverId;
      });

      if (receiverProfile && receiverProfile.length > 0) {
        let profile = receiverProfile[0];
        let activityName = "Staff_Message";

        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          profile.id,
          firebase
        );

        let obj = {
          activityName: activityName,
          androidId: profile.uid ? profile.uid : null,
          iosId: profile.ios_uid ? profile.ios_uid : null,
          body:
            "New Message received from " + senderName + " : " + singleMessage,
          id: alertNode,
          inverseTime: -new Date().getTime(),
          nodeId: uniqueNode,
          read: false,
          senderName: senderName,
          type: activityName,
          userType: "teacher",
          teacherId: profile.id,
          branchPath: firebase.sbp,
        };

        yield fork(
          NotificationApi.createSimpleAlertNotification,
          obj.activityName,
          obj.nodeId,
          obj.androidId,
          obj.body,
          alertNode,
          obj.iosId,
          undefined,
          obj.teacherId,
          obj.type,
          undefined,
          firebase,
          undefined,
          obj.userType,
          obj.branchPath
        );

        if (obj.androidId !== undefined || obj.iosId !== undefined) {
          yield fork(
            NotificationApi.sendSimplePushNotification,
            obj.activityName,
            obj.nodeId,
            obj.androidId,
            obj.body,
            alertNode,
            obj.iosId,
            undefined,
            obj.teacherId,
            obj.type,
            undefined,
            firebase,
            undefined,
            obj.userType,
            obj.branchPath
          );
        }
      }
    }
  } catch (err) {
    console.log("failed to put teacher chat message", err);
    bugsnagClient.notify(err);
  }
}

function* resetStaffUnreadMessageCount({ chatList, firebase }) {
  try {
    if (chatList && chatList.receiverId && chatList.senderId) {
      let teacherId = firebase.teacher.id;
      //set current teacherId/chatList.teacherId - unread = 0;
      let sendersChatList = yield call(
        QueryApi.getTeacherChatListById,
        teacherId,
        chatList.teacherId,
        firebase
      );
      if (sendersChatList && sendersChatList.teacherId) {
        yield fork(
          QueryApi.setSenderUnreadCount,
          teacherId,
          chatList.teacherId,
          firebase
        );
      }

      //set chatList.teacher/teacherId - read = true;
      let receiversChatList = yield call(
        QueryApi.getTeacherChatListById,
        chatList.teacherId,
        teacherId,
        firebase
      );
      if (receiversChatList && receiversChatList.teacherId) {
        yield fork(
          QueryApi.setReceiverReadStatus,
          chatList.teacherId,
          teacherId,
          firebase
        );
      }
    }
  } catch (err) {
    console.log("failed to reset staff unread message count", err);
    bugsnagClient.notify(err);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_QUERYLIST, fetchQueryList),
    yield takeLatest(actions.GET_PARENT_CHAT, fetchParentChat),
    yield takeLatest(actions.SEND_MESSAGE, sendChatMessage),
    yield takeLatest(
      actions.RESET_TEACHER_UNREAD_COUNT,
      resetUnreadMessageCountTeacher
    ),
    yield takeLatest(actions.GET_SCHOOL_MSG, fetchSchoolMessage),
    yield takeLatest(actions.SEND_SCHOOL_MSG, postSchoolMessage),
    yield takeLatest(actions.GET_STAFF_QUERYLIST, fetchStaffQueryList),
    yield takeLatest(actions.GET_TEACHER_CHAT, fetchTeacherChat),
    yield takeLatest(actions.SEND_TEACHER_MESSAGE, sendTeacherChatMessage),
    yield takeLatest(
      actions.RESET_STAFF_CHAT_UNREAD_COUNT,
      resetStaffUnreadMessageCount
    ),
  ]);
}
