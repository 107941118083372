import "@firebase/firestore"; // 👈 If you're using firestore
import { eventChannel } from "redux-saga";
import moment from "moment-timezone";
const superagent = require("superagent");

function getStaffPosts(startDate, endDate, firebase) {
  let startTime = moment(startDate)
    .startOf("day")
    .valueOf();
  let endTime = moment(endDate)
    .endOf("day")
    .valueOf();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf
    .ref(branchPath + "/staffActivities")
    .orderByChild("inverseDate")
    .startAt(-endTime)
    .endAt(-startTime);

  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      var x = [];

      snap.forEach((element) => {
        if (
          element.val() !== null &&
          !element.val().deleted &&
          element.val().activityType.toLowerCase() === "notification"
        ) {
          let elementVal = element.val();
          if (elementVal.activityType.toLowerCase() === "assignment") {
            if (elementVal.name.toLowerCase() === "check in form") {
              elementVal.activityType = "Check in Form";
            } else {
              return;
            }
          }

          x.push(elementVal);
        }
      });

      emit(x);
    });
    return () => {
      myRef.off();
      console.log("unsubscribe todays activity post");
    };
  });
}

function getStudentAttendance(firebase) {
  let attendance = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/student-attendance");
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((element) => {
        element.forEach((a) => {
          attendance.push(a.val());
        });
      });
      resolve(attendance);
    });
  });
  return promise1;
}

function getAllStaff(firebase) {
  let teacher = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/teachers");
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((element) => {
        if (
          element !== null &&
          element.val().deleted === false &&
          element.val().deactivated === false
        ) {
          teacher.push(element.val());
        }
      });
      resolve(teacher);
    });
  });
  return promise1;
}

function getStaffAttendance(firebase) {
  let attendance = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/staffAttendanceReference");
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((element) => {
        element.forEach((a) => {
          attendance.push(a.val());
        });
      });
      resolve(attendance);
    });
  });
  return promise1;
}

function getDailyReport(firebase) {
  let report = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/dailyReports");
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((element) => {
        if (element !== null) {
          report.push(element.val());
        }
      });
      resolve(report);
    });
  });
  return promise1;
}

function getTodaysPost(startDate, endDate, firebase, activityFetchType) {
  let startTime = moment(startDate)
    .startOf("day")
    .valueOf();
  let endTime = moment(endDate)
    .endOf("day")
    .valueOf();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf
    .ref(branchPath + "/activities")
    .orderByChild("inverseDate")
    .startAt(-endTime)
    .endAt(-startTime);

  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      var x = [];
      let activityMap = new Map();
      snap.forEach((element) => {
        if (
          element.val() !== null &&
          !element.val().deleted &&
          element.val().activityType.toLowerCase() !== "attendance"
        ) {
          let elementVal = element.val();
          if (elementVal.activityType.toLowerCase() === "assignment") {
            if (elementVal.name.toLowerCase() === "check in form") {
              elementVal.activityType = "Check in Form";
            } else {
              return;
            }
          }

          let classList =
            firebase && firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            if (classList.length > 0) {
              let activityClasses = elementVal.classNames;

              if (activityClasses) {
                for (let index in classList) {
                  let filteredVal = activityClasses.filter((f) => {
                    return f.toLowerCase() === classList[index].toLowerCase();
                  });

                  if (filteredVal && filteredVal.length > 0) {
                    if (elementVal.activityType === "Virtual Class") {
                      if (elementVal.meetingId) {
                        /**creating map of activityType */
                        activityMap = checkIfActivityAdded(
                          activityMap,
                          elementVal
                        );
                        x.push(elementVal);
                      }
                    } else {
                      /**creating map of activityType */
                      activityMap = checkIfActivityAdded(
                        activityMap,
                        elementVal
                      );
                      x.push(elementVal);
                    }
                    break;
                  }
                }
              } else {
                /**creating map of activityType */
                activityMap = checkIfActivityAdded(activityMap, elementVal);
                x.push(elementVal);
              }
            } else {
              if (elementVal.activityType === "Virtual Class") {
                if (elementVal.meetingId) {
                  /**creating map of activityType */
                  activityMap = checkIfActivityAdded(activityMap, elementVal);
                  x.push(elementVal);
                }
              } else {
                /**creating map of activityType */
                activityMap = checkIfActivityAdded(activityMap, elementVal);
                x.push(elementVal);
              }
            }
          } else {
            if (elementVal.activityType === "Virtual Class") {
              if (elementVal.meetingId) {
                /**creating map of activityType */
                activityMap = checkIfActivityAdded(activityMap, elementVal);
                x.push(elementVal);
              }
            } else {
              /**creating map of activityType */
              activityMap = checkIfActivityAdded(activityMap, elementVal);
              x.push(elementVal);
            }
          }
        }
      });

      if (activityFetchType) {
        emit(activityMap);
      } else {
        emit(x);
      }
    });
    return () => {
      rsf.ref(branchPath + "/activities").off();
      console.log("unsubscribe todays activity post");
    };
  });
}

function checkIfActivityAdded(activityMap, activity) {
  if (activityMap.has(activity.activityType)) {
    let activityMapVal = activityMap.get(activity.activityType);
    if (activityMapVal) {
      activityMapVal.push(activity);
    }
    activityMap.set(activity.activityType, activityMapVal);
  } else {
    activityMap.set(activity.activityType, [activity]);
  }
  return activityMap;
}

function getActivityPostById(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/activities/" + id);

  return eventChannel((emit) => {
    myRef.on("value", (element) => {
      var x = [];

      if (element.val() !== null && !element.val().deleted) {
        let classList =
          firebase && firebase.teacher && firebase.teacher.classList
            ? firebase.teacher.classList
            : [];
        if (firebase.teacher && !firebase.teacher.superUser) {
          if (classList.length > 0) {
            let activityClasses = element.val().classNames;

            if (activityClasses) {
              for (let index in classList) {
                let filteredVal = activityClasses.filter((f) => {
                  return f.toLowerCase() === classList[index].toLowerCase();
                });

                if (filteredVal && filteredVal.length > 0) {
                  if (element.val().activityType === "Virtual Class") {
                    if (element.val().meetingId) {
                      x.push(element.val());
                    }
                  } else {
                    x.push(element.val());
                  }
                  break;
                }
              }
            } else {
              x.push(element.val());
            }
          } else {
            if (element.val().activityType === "Virtual Class") {
              if (element.val().meetingId) {
                x.push(element.val());
              }
            } else {
              x.push(element.val());
            }
          }
        } else {
          if (element.val().activityType === "Virtual Class") {
            if (element.val().meetingId) {
              x.push(element.val());
            }
          } else {
            x.push(element.val());
          }
        }
      }

      emit(x);
    });
    return () => {
      myRef.off();
    };
  });
}

function getTodaysPostFilteredByClass(classroom, startDate, endDate, firebase) {
  let startTime = moment(startDate)
    .startOf("day")
    .valueOf();
  let endTime = moment(endDate)
    .endOf("day")
    .valueOf();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf
    .ref(branchPath + "/activities")
    .orderByChild("inverseDate")
    .startAt(-endTime)
    .endAt(-startTime);

  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      var post = [];
      snap.forEach((element) => {
        if (
          element.val() !== null &&
          !element.val().deleted &&
          element.val().activityType.toLowerCase() !== "assignment" &&
          element.val().activityType.toLowerCase() !== "attendance" &&
          element.val().classNames &&
          element.val().classNames.includes(classroom)
        ) {
          post.push(element.val());
        }
      });
      emit(post);
    });
    return () => {
      rsf.ref(branchPath + "/activities").off();
      console.log("unsubscribe todays activity post");
    };
  });
}

function getActivityStats(date, firebase) {
  var searchableDateString = moment(date).format("DD[-]MM[-]YY");
  let post = {};
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(
    branchPath + "/dashboard/activities/" + searchableDateString
  );
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      if (snap.val() !== null) {
        post = snap.val();
      }
      resolve(post);
    });
  });
  return promise1;
}

function getAllConcerns(startDate, endDate, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let post = [];
  const myRef = rsf
    .ref(branchPath + "/complaints")
    .orderByChild("appliedDate")
    .startAt(startDate)
    .endAt(endDate);
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((item) => {
        if (item.val() !== null) {
          post.push(item.val());
        }
      });
      resolve(post);
    });
  });
  return promise1;
}

function getAllNotes(startDate, endDate, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let post = [];
  const myRef = rsf
    .ref(branchPath + "/noteReference")
    .orderByChild("appliedDate")
    .startAt(startDate)
    .endAt(endDate);
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((item) => {
        if (item.val() !== null) {
          post.push(item.val());
        }
      });
      resolve(post);
    });
  });
  return promise1;
}

function getAllChatlist(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let post = [];
  const myRef = rsf.ref(branchPath + "/chatList");
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((item) => {
        if (item.val() !== null) {
          post.push(item.val());
        }
      });
      resolve(post);
    });
  });
  return promise1;
}

function getStudentAttendanceByClass(date, firebase) {
  var searchableDateString = moment(date).format("DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/dashboard/class/student/" + searchableDateString)
      .on("value", (snap) => {
        var x = [];
        snap.forEach((element) => {
          if (element.val() !== null) {
            x.push(element.val());
          }
        });
        emit(x);
      });
    return () => {
      rsf
        .ref(branchPath + "/dashboard/class/student/" + searchableDateString)
        .off();
      console.log("unsubscribe dashboard student attendance");
    };
  });
}

function getStaffAttendanceByClass(date, firebase) {
  var searchableDateString = moment(date).format("DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/dashboard/class/staff/" + searchableDateString)
      .on("value", (snap) => {
        var x = [];
        snap.forEach((element) => {
          if (element.val() !== null) {
            x.push(element.val());
          }
        });
        emit(x);
      });
    return () => {
      rsf
        .ref(branchPath + "/dashboard/class/staff/" + searchableDateString)
        .off();
      console.log("unsubscribe dashboard staff attendance");
    };
  });
}

function getDashboardData(startDate, endDate, firebase, branches) {
  let startTime = moment(startDate)
    .startOf("day")
    .valueOf();
  let endTime = moment(endDate)
    .endOf("day")
    .valueOf();

  let selectedBranchLists = [];
  branches.map((ele) => {
    selectedBranchLists.push({
      branchPath: ele.name,
    });
  });

  let endPointUrl = firebase.endPointUrl;
  let dashboardStatUrl = endPointUrl + "multiCenterReportAgg";
  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(dashboardStatUrl)
      .send({
        accountName: firebase.sbDbName,
        selectedBranchList: selectedBranchLists,
        startDate: startTime,
        endDate: endTime,
        timezone: localTimezone,
      })
      .set("accept", "json")
      .end((err, res) => {
        console.log("err", err);
        console.log("res", res);
        if (res) {
          resolve(res);
        } else if (err) {
          reject(err);
        }
      });
  });
  return p1;
}

function getAllSubmissionActivity(startDate, endDate, firebase) {
  let rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let startTime = moment(startDate)
    .startOf("day")
    .valueOf();
  let endTime = moment(endDate)
    .endOf("day")
    .valueOf();

  var myRef;
  if (startDate && endDate) {
    myRef = rsf
      .ref(branchPath + "/activities")
      .orderByChild("inverseDate")
      .startAt(-endTime)
      .endAt(-startTime);
  } else {
    myRef = rsf
      .ref(branchPath + "/activities")
      .orderByChild("activityType")
      .equalTo("Assignment");
  }

  var x = [];
  var promise = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((element) => {
        if (
          element.val() !== null &&
          !element.val().deleted &&
          element.val().activityType === "Assignment"
        ) {
          let classList =
            firebase && firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            if (classList.length > 0) {
              let activityClasses = element.val().classNames;
              for (let index in classList) {
                let filteredVal = activityClasses.filter((f) => {
                  return f.toLowerCase() === classList[index].toLowerCase();
                });

                if (filteredVal && filteredVal.length > 0) {
                  x.push(element.val());
                  break;
                }
              }
            } else {
              x.push(element.val());
            }
          } else {
            x.push(element.val());
          }
        }
      });
      resolve(x);
    });
  });
  return promise;

  // return eventChannel(emit => {
  //   myRef.on('value', snap => {
  //     var x = [];
  //     snap.forEach(element => {
  //       if (element.val() !== null && !element.val().deleted && element.val().activityType === "Assignment") {
  //         x.push(element.val());
  //       }
  //     });
  //     emit(x);
  //   })
  //   return () => {
  //     rsf.ref(branchPath + "/activities").off();
  //     console.log("unsubscribe todays activity post");
  //   }
  // })
}

function updateTeacherLastAccess(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/teachers/" + firebase.teacher.id).update({
    lastAccess: moment().valueOf(),
  });
}

function fetchNewDashboardStats(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/dashboard-stats");

  return eventChannel((emit) => {
    myRef.on("value", (snapshot) => {
      emit(snapshot);
    });
    return () => {
      myRef.off();
    };
  });
}

function fetchNewDashboardActivityStatsByCategory(category, date, firebase) {
  let dateFormat = moment(date).format("YYYY[-]MM[-]DD");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(
    branchPath + "/dashboard-stats/" + category + "/" + dateFormat
  );

  let stats = {};
  return eventChannel((emit) => {
    myRef.on("value", (snapshot) => {
      if (snapshot.val()) {
        emit(snapshot.val());
      } else {
        emit(stats);
      }
    });
    return () => {
      myRef.off();
    };
  });
}

function fetchNewDashboardActivityStats(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/dashboard-stats/activity");

  return eventChannel((emit) => {
    myRef.on("value", (snapshot) => {
      emit(snapshot);
    });
    return () => {
      myRef.off();
    };
  });
}

function fetchNewDashboardLearningStats(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/dashboard-stats/lessons");
  return eventChannel((emit) => {
    myRef.on("value", (snapshot) => {
      emit(snapshot);
    });
    return () => {
      myRef.off();
    };
  });
}

function fetchNewDashboardLeadsStats(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/dashboard-stats/leads");

  return eventChannel((emit) => {
    myRef.on("value", (snapshot) => {
      emit(snapshot);
    });
    return () => {
      myRef.off();
    };
  });
}

function getNewDashboardLessonPlan(classroom, date, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let lessonPlan = {};
  const myRef = rsf.ref(branchPath + "/weeklyPlan/" + classroom);
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snapshot) {
      snapshot.forEach((snap) => {
        if (snap.key === moment(date).format("ddd[ ]MMM[ ]DD[ ]YYYY")) {
          lessonPlan = snap.val();
        }
      });
      resolve(lessonPlan);
    });
  });
  return promise1;
}

function fetchNewDashboardFinanceStats(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/dashboard-stats/finance");

  return eventChannel((emit) => {
    myRef.on("value", (snapshot) => {
      emit(snapshot);
    });
    return () => {
      myRef.off();
    };
  });
}

function fetchNewDashboardStatsPromise(firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  const myRef = rsf.ref(branchPath + "/dashboard-stats");
  let promise = new Promise(function(resolve, reject) {
    myRef.once("value").then((snapshot) => {
      resolve(snapshot);
    });
  });
  return promise;
}

function fetchNewDashboardActivityStatsPromise(firebase, activity, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  const myRef = rsf.ref(branchPath + "/dashboard-stats/" + activity);

  let promise = new Promise(function(resolve, reject) {
    myRef.once("value").then((snapshot) => {
      resolve(snapshot);
    });
  });
  return promise;
}

export const DashboardApi = {
  getStudentAttendance,
  getAllStaff,
  getStaffAttendance,
  getDailyReport,
  getTodaysPost,
  getStaffPosts,
  getTodaysPostFilteredByClass,
  getActivityStats,
  getAllConcerns,
  getAllNotes,
  getAllChatlist,
  getStudentAttendanceByClass,
  getStaffAttendanceByClass,
  getDashboardData,
  getAllSubmissionActivity,
  updateTeacherLastAccess,
  getActivityPostById,
  fetchNewDashboardStats,
  fetchNewDashboardActivityStats,
  fetchNewDashboardLearningStats,
  fetchNewDashboardLeadsStats,
  getNewDashboardLessonPlan,
  fetchNewDashboardFinanceStats,
  fetchNewDashboardStatsPromise,
  fetchNewDashboardActivityStatsPromise,
  fetchNewDashboardActivityStatsByCategory,
};
