import { all, put, call, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { lessonAssignmentApi } from "../../firestore-api/lessonAssignment";
import { LearningApi } from "../../firestore-api/learning";
import { TeacherApi } from "../../firestore-api/teacher";
import bugsnagClient from "@bugsnag/js";

// function* fetchAssignments({ firebase, classroom, limit, initialCall }) {
//     const chan = yield call(lessonAssignmentApi.getAllAssignment, firebase, classroom, limit);

//     try {
//         while (true) {
//             let data = yield take(chan);
//             data.sort(function (a, b) {
//                 var dateA = (a.shareOn), dateB = (b.shareOn)
//                 return dateB - dateA
//             });
//             var lessons = [];
//             for (let index in data) {
//                 let lesson = yield call(LearningApi.getLessonById, data[index].lessonId, firebase);
//                 if (lesson && lesson.id) {
//                     data[index].lessonName = lesson.name;
//                     data[index].lessonDescription = lesson.description ? lesson.description : null;
//                     data[index].lessonCategory = lesson.category ? lesson.category : null;
//                     lessons.push(lesson);
//                 }
//             }

//             yield put({
//                 type: actions.GET_ASSIGNMENT_SUCCESSFUL,
//                 assignments: data,
//                 assignmentLessons: lessons,
//                 assignmentChannel: chan,
//                 assignmentOperation: initialCall
//             });
//         }
//     } finally {
//         console.log("terminating school assignment");
//     }
// }

function* fetchAssignments({
  firebase,
  classroom,
  limit,
  initialCall,
  prevAssignmentsSchool,
  startDate,
  endDate,
  assignmentId,
}) {
  try {
    let data;
    /**fetch student for table */
    let students = JSON.parse(localStorage.getItem("studentList"));
    let filteredStudent = [];
    if (students && students.length > 0) {
      filteredStudent = students.filter((s) => {
        return !s.status || (s.status && s.status.toLowerCase() === "active");
      });
    }

    if (assignmentId) {
      let myData = yield call(
        lessonAssignmentApi.getSchoolAssignmentById,
        assignmentId,
        firebase
      );
      if (myData && myData.id) {
        data = [myData];
      } else {
        data = [];
      }
    } else {
      data = yield call(
        lessonAssignmentApi.getAllSchoolAssignment,
        firebase,
        classroom,
        limit,
        startDate,
        endDate
      );
    }

    if (data) {
      data.sort(function(a, b) {
        var dateA = a.shareOn,
          dateB = b.shareOn;
        return dateB - dateA;
      });

      var lessons = [];
      yield put({
        type: actions.GET_SCHOOL_ASSIGNMENT_SUCCESSFUL,
        assignmentsSchool: data,
        assignmentLessons: lessons,
        assignmentOperation: initialCall,
        showLoader:
          prevAssignmentsSchool && prevAssignmentsSchool.length !== data.length
            ? true
            : false,
        students: filteredStudent,
      });

      let assignmentWithExistingLesson = [];
      for (let index in data) {
        let lesson = yield call(
          LearningApi.getLessonById,
          data[index].lessonId,
          firebase
        );
        if (lesson && lesson.id) {
          data[index].lessonName = lesson.name;
          data[index].lessonDescription = lesson.description
            ? lesson.description
            : null;
          data[index].lessonCategory = lesson.category ? lesson.category : null;
          assignmentWithExistingLesson.push(data[index]);
          lessons.push(lesson);
        }
      }

      yield put({
        type: actions.GET_SCHOOL_ASSIGNMENT_SUCCESSFUL,
        assignmentsSchool: assignmentWithExistingLesson,
        assignmentLessons: lessons,
        assignmentOperation: undefined,
        lessonNameLoading: false,
        showLoader:
          prevAssignmentsSchool && prevAssignmentsSchool.length !== data.length
            ? true
            : false,
        students: filteredStudent,
      });
    }
  } catch (err) {
    console.log("failed to fetch all assignments", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAIL,
    });
  }
}

function* fetchSubmission({ assignments, firebase }) {
  try {
    var submission = new Map();
    for (let index in assignments) {
      let lessonSub = yield call(
        lessonAssignmentApi.getLessonSubmission,
        assignments[index].id,
        firebase
      );
      if (lessonSub && lessonSub.length > 0) {
        submission.set(assignments[index].id, lessonSub);
      }
    }
    yield put({
      type: actions.GET_SUBMISSION_SUCCESSFUL,
      lessonSubmission: submission,
    });
  } catch (err) {
    console.log("failed to fetch assignment submission", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAIL,
    });
  }
}

function* fetchComment({ assignments, firebase }) {
  try {
    let activities = assignments;
    var commentsCount = new Map();
    for (const item in activities) {
      var commentCountObj = yield call(
        lessonAssignmentApi.getCommentsCount,
        activities[item].id,
        firebase
      );
      if (commentCountObj) {
        commentsCount.set(activities[item].id, commentCountObj);
      }
    }
    yield put({
      type: actions.GET_ASSIGNMENT_COMMENT_SUCCESSFUL,
      lessonComment: commentsCount,
    });
  } catch (err) {
    console.log("failed to fetch assignment lesson comment", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAIL,
    });
  }
}

function* getClassroomDropDown({ firebase }) {
  try {
    const data = yield call(TeacherApi.getClassroomsForTeacher, firebase);
    if (data) {
      yield put({
        type: actions.GET_CLASS_DROP_DOWN_SUCCESS,
        classDropDown: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch assignment classroom dropdown", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAIL,
    });
  }
}

function* deleteSchoolAssignmentRecord({ record, firebase }) {
  try {
    let assignment = record;
    assignment.lessonName = null;
    assignment.lessonDescription = null;
    assignment.lessonCategory = null;
    assignment.delete = true;
    assignment.deletedBy = firebase.teacher.name;
    yield call(
      lessonAssignmentApi.updateSchoolAssignment,
      assignment,
      firebase
    );
    yield put({
      type: actions.DELETE_SCHOOL_ASSIGNMENT_SUCCESS,
    });
  } catch (err) {
    console.log("failed to delete school assignment", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAIL,
    });
  }
}

function* fetchAssignmentByLessonId({ lessonId, firebase }) {
  try {
    let data = yield call(
      lessonAssignmentApi.getAssignmentByLessonId,
      lessonId,
      firebase
    );
    if (data) {
      yield put({
        type: actions.GET_ASSIGNMENT_BY_LESSON_ID_SUCCESS,
        sharedAssignment: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch shared  assignment by lesson id", err);
    bugsnagClient.notify(
      "failed to fetch shared  assignment by lesson id --->>" + err.message
        ? err.message
        : err
    );
  }
}

function* fetchSharedAssignmentSubmission({ assignments, firebase }) {
  try {
    var submission = new Map();
    for (let index in assignments) {
      let lessonSub = yield call(
        lessonAssignmentApi.getLessonSubmission,
        assignments[index].id,
        firebase
      );
      if (lessonSub && lessonSub.length > 0) {
        submission.set(assignments[index].id, lessonSub);
      }
    }
    yield put({
      type: actions.GET_SHARED_ASSIGNMENT_SUBMISSION_SUCCESS,
      sharedLessonSubmission: submission,
    });
  } catch (err) {
    console.log("failed to fetch shared assignment submission", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAIL,
    });
  }
}

function* fetchSharedAssignmentComment({ assignments, firebase }) {
  try {
    let activities = assignments;
    var commentsCount = new Map();
    for (const item in activities) {
      var commentCountObj = yield call(
        lessonAssignmentApi.getCommentsCount,
        activities[item].id,
        firebase
      );
      if (commentCountObj) {
        commentsCount.set(activities[item].id, commentCountObj);
      }
    }
    yield put({
      type: actions.GET_SHARED_ASSIGNMENT_COMMENT_SUCCESS,
      sharedLessonComment: commentsCount,
    });
  } catch (err) {
    console.log("failed to fetch shared assignment lesson comment", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAIL,
    });
  }
}

function* deleteSharedSchoolAssignmentRecord({ record, firebase }) {
  try {
    let assignment = record;
    assignment.lessonName = null;
    assignment.lessonDescription = null;
    assignment.lessonCategory = null;
    assignment.delete = true;
    assignment.deletedBy = firebase.teacher.name;
    yield call(
      lessonAssignmentApi.updateSchoolAssignment,
      assignment,
      firebase
    );
    yield put({
      type: actions.DELETE_SHARED_SCHOOL_ASSIGNMENT_SUCCESS,
    });
  } catch (err) {
    console.log("failed to delete shared school assignment", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAIL,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_SCHOOL_ASSIGNMENT, fetchAssignments),
    yield takeLatest(actions.GET_SUBMISSION, fetchSubmission),
    yield takeLatest(actions.GET_ASSIGNMENT_COMMENT, fetchComment),
    yield takeLatest(actions.GET_CLASS_DROP_DOWN, getClassroomDropDown),
    yield takeLatest(
      actions.DELETE_SCHOOL_ASSIGNMENT,
      deleteSchoolAssignmentRecord
    ),
    yield takeLatest(
      actions.GET_ASSIGNMENT_BY_LESSON_ID,
      fetchAssignmentByLessonId
    ),
    yield takeLatest(
      actions.GET_SHARED_ASSIGNMENT_SUBMISSION,
      fetchSharedAssignmentSubmission
    ),
    yield takeLatest(
      actions.GET_SHARED_ASSIGNMENT_COMMENT,
      fetchSharedAssignmentComment
    ),
    yield takeLatest(
      actions.DELETE_SHARED_SCHOOL_ASSIGNMENT,
      deleteSharedSchoolAssignmentRecord
    ),
  ]);
}
