import "@firebase/firestore"; // 👈 If you're using firestore
import { all, call, put, takeLatest, take, fork } from "redux-saga/effects";
import { DashboardApi } from "../../firestore-api/dashboard";
import { TeacherApi } from "../../firestore-api/teacher";
import { ActivityApi } from "../../firestore-api/activity";
import { StudentApi } from "../../firestore-api/student";
import { NotificationApi } from "../../firestore-api/notification";
import { lessonAssignmentApi } from "../../firestore-api/lessonAssignment";
import { TimelineApi } from "../../firestore-api/timeline";
import actions from "./actions";
import bugsnagClient from "@bugsnag/js";
import moment from "moment-timezone";
import notification from "../../components/notification";
import { UserSettingApi } from "../../firestore-api/userSetting";
import { StudentAttendanceApi } from "../../firestore-api/studentAttendance";
const superagent = require("superagent");

function* fetchStaffPost({ startDate, endDate, firebase, todaysPost, nodeId }) {
  if (nodeId) {
    let data = yield call(ActivityApi.getStaffActivityById, nodeId, firebase);
    if (data) {
      yield put({
        type: actions.GET_STAFF_POSTS_SUCCESS,
        staffPosts: data && data.id ? [data] : [],
        staffPostChan: undefined,
      });
    }
  } else {
    try {
      let teacherId = firebase.teacher.id;

      const chan = yield call(
        TimelineApi.getStaffTimelineByLimit,
        teacherId,
        firebase,
        startDate,
        endDate
      );

      while (true) {
        let data = yield take(chan);

        let activityIds = data;

        let activities = [];
        let activitiesTask = [];

        for (const item in activityIds) {
          let task = call(
            ActivityApi.getStaffActivityById,
            activityIds[item],
            firebase
          );
          activitiesTask.push(task);
        }

        let newVal = yield all([activitiesTask]);

        for (let i in newVal[0]) {
          let singleActivity = newVal[0][i];
          if (singleActivity && singleActivity.id) {
            activities.push(singleActivity);
          }
        }

        activities.sort(function(a, b) {
          var dateA = a.date.time,
            dateB = b.date.time;
          return dateB - dateA;
        });
        yield put({
          type: actions.GET_STAFF_POSTS_SUCCESS,
          staffPosts: activities,
          staffPostChan: chan,
        });
      }
    } finally {
      console.log("terminating staff activity post");
    }
  }
}

function* fetchTodaysPost({ startDate, endDate, firebase, todaysPost }) {
  if (todaysPost && todaysPost.length > 0) {
    yield put({
      type: actions.GET_TODAYS_POST_FOR_ACTIVITY_SUCCESSFUL,
      todaysPost: todaysPost,
      todaysPostChannel: undefined,
      operationType: "INITIAL_FETCH",
    });
  }

  try {
    const chan = yield call(
      DashboardApi.getTodaysPost,
      startDate,
      endDate,
      firebase
    );
    while (true) {
      let data = yield take(chan);
      var activities = data;
      activities.sort(function(a, b) {
        var dateA = a.date.time,
          dateB = b.date.time;
        return dateB - dateA;
      });
      yield put({
        type: actions.GET_TODAYS_POST_FOR_ACTIVITY_SUCCESSFUL,
        todaysPost: activities,
        todaysPostChannel: chan,
        operationType: "INITIAL_FETCH",
      });
      yield fork(fetchSeenCount, activities, firebase);
      yield fork(fetchComments, activities, firebase);
      if (
        firebase &&
        firebase.schoolConfig &&
        firebase.schoolConfig.classBookingEnabled
      ) {
        yield fork(fetchTrialStudentCount, activities, firebase);
      }
    }
  } finally {
    console.log("terminating todays activity post");
  }
}

function* fetchMeetingReport({ startDate, firebase }) {
  try {
    let bookings = yield call(
      TimelineApi.getOnlineBookings,
      startDate,
      firebase,
      "singleDate"
    );
    if (bookings) {
      let meetingTimeArr = [];
      let teacherMap = new Map();
      let activityMap = new Map();
      const activitiesTask = [];
      for (let index in bookings) {
        let activityId = bookings[index].activityId;
        let task = call(
          TimelineApi.fetchAltTimelineActivities,
          activityId,
          firebase
        );
        activitiesTask.push(task);

        //meeting time array for table column
        // let meetingTime = moment(bookings[index].time).format("hh:mm A");
        // if (!meetingTimeArr.includes(meetingTime)) {
        //   meetingTimeArr.push(meetingTime);
        // }

        //teacher name map
        if (teacherMap.has(bookings[index].createdBy)) {
          let val = teacherMap.get(bookings[index].createdBy);
          val.push(bookings[index]);
          teacherMap.set(bookings[index].createdBy, val);
        } else {
          let val = [];
          val.push(bookings[index]);
          teacherMap.set(bookings[index].createdBy, val);
        }
      }

      let newVal = yield all([activitiesTask]);
      for (let i in newVal[0]) {
        let val = newVal[0][i];
        if (val && val.id && val.meetingBookable) {
          activityMap.set(val.id, val);
        }
      }

      let meetingData = [];
      for (let [key, value] of teacherMap) {
        let data = {};
        data.name = key;

        for (let i in value) {
          if (activityMap.has(value[i].activityId)) {
            data[moment(value[i].time).format("hh:mm A")] = {
              activity: activityMap.has(value[i].activityId)
                ? activityMap.get(value[i].activityId)
                : undefined,
              booking: value[i],
            };

            let meetingTime = moment(value[i].time).format("hh:mm A");
            if (!meetingTimeArr.includes(meetingTime)) {
              meetingTimeArr.push(meetingTime);
            }
          }
        }
        meetingData.push(data);
      }

      meetingTimeArr.sort(function(a, b) {
        return new Date("1970/01/01 " + a) - new Date("1970/01/01 " + b);
      });

      yield put({
        type: actions.GET_MEETING_REPORT_SUCCESS,
        meetingTime: meetingTimeArr,
        meetingReport: meetingData,
      });
    }
  } catch (err) {
    console.log("failed to get online bookings", err);
    bugsnagClient.notify(err);
  }
}

function* fetchActivityMap({ startDate, endDate, firebase }) {
  try {
    const chan = yield call(
      DashboardApi.getTodaysPost,
      startDate,
      endDate,
      firebase,
      "activityMap"
    );
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_ACTIVITY_MAP_SUCCESS,
        activityMap: data,
        todaysPostChannel: chan,
        operationType: "INITIAL_FETCH",
      });
    }
  } finally {
    console.log("terminating todays activity post map");
  }
}

function* fetchNotificationActivity({ nodeId, firebase }) {
  const chan = yield call(DashboardApi.getActivityPostById, nodeId, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_TODAYS_POST_FOR_ACTIVITY_SUCCESSFUL,
        todaysPost: data,
        todaysPostChannel: chan,
        operationType: undefined,
      });
      yield fork(fetchSeenCount, data, firebase);
      yield fork(fetchComments, data, firebase);
      if (
        firebase &&
        firebase.schoolConfig &&
        firebase.schoolConfig.classBookingEnabled
      ) {
        yield fork(fetchTrialStudentCount, data, firebase);
      }
    }
  } finally {
    console.log("terminating get post by id");
  }
}

function* fetchSeenCount(activities, firebase) {
  try {
    let seenCount = [];
    let seenCountTask = [];
    for (let index in activities) {
      let task = call(ActivityApi.getSeenCount, activities[index].id, firebase);
      seenCountTask.push(task);
    }

    let newVal = yield all([seenCountTask]);
    for (let i in newVal[0]) {
      let data = newVal[0][i];
      seenCount = [...seenCount, ...data];
    }

    yield put({
      type: actions.GET_SEEN_COUNT_SUCCESSFUL,
      seenCount: seenCount,
    });
  } catch (err) {
    console.log("failed to fetch seen count", err);
    bugsnagClient.notify(err);
  }
}

function* fetchComments(activityList, firebase) {
  try {
    let activities = activityList;
    let commentTaskList = [];
    var commentsCount = new Map();
    for (const item in activities) {
      let task = call(
        lessonAssignmentApi.getCommentsCount,
        activities[item].id,
        firebase
      );
      commentTaskList.push(task);
      commentsCount.set(activities[item].id, []);
    }

    let newVal = yield all([commentTaskList]);
    for (let i in newVal[0]) {
      let val = newVal[0][i];
      for (let j in val) {
        if (commentsCount.has(val[j].sourceId)) {
          commentsCount.set(val[j].sourceId, val);
          break;
        }
      }
    }
    yield put({
      type: actions.GET_ACTIVITY_COMMENT_SUCCESSFUL,
      activityComments: commentsCount,
    });
  } catch (err) {
    console.log("failed to fetch activity comment", err);
    bugsnagClient.notify(err);
  }
}

function* fetchClassroom({ firebase }) {
  try {
    let data = yield call(TeacherApi.getClassroomsForTeacher, firebase);
    if (data) {
      yield put({
        type: actions.GET_CLASSROOM_FOR_ACTIVITY_SUCCESSFUL,
        classrooms: data,
      });
    }
  } catch (error) {
    console.log("failed to fetch classrooms", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.ACTIVITY_REQUEST_FAILED,
    });
  }
}

function* fetchActivityByClassroom({
  classroom,
  startDate,
  endDate,
  firebase,
}) {
  try {
    const chan = yield call(
      DashboardApi.getTodaysPostFilteredByClass,
      classroom,
      startDate,
      endDate,
      firebase
    );
    while (true) {
      let data = yield take(chan);
      var activities = data;
      activities.sort(function(a, b) {
        var dateA = a.date.time,
          dateB = b.date.time;
        return dateB - dateA;
      });
      yield put({
        type: actions.GET_TODAYS_POST_FOR_ACTIVITY_SUCCESSFUL,
        todaysPost: activities,
        todaysPostChannel: chan,
        operationType: undefined,
      });
    }
  } finally {
    console.log("terminating todays post filter by classs");
  }
}

function* fetchTrialStudentCount(activities, firebase) {
  try {
    let bookingRefTask = [];
    let trialStudentMap = new Map();

    for (let index in activities) {
      let activity = activities[index];
      if (activity.activityType.toLowerCase() === "virtual class") {
        let meetingTime = activity.meetingTime;
        let task = call(
          ActivityApi.getBookingRefByTime,
          activity.id,
          meetingTime,
          firebase
        );
        bookingRefTask.push(task);
      }
    }

    let newVal = yield all([bookingRefTask]);
    for (let i in newVal[0]) {
      let val = newVal[0][i];
      trialStudentMap.set(
        val.activityId,
        val.studentIds ? val.studentIds.length : 0
      );
    }

    yield put({
      type: actions.GET_TRIAL_STUDENT_COUNT,
      trialStudentMap: trialStudentMap,
    });
  } catch (err) {
    console.log("failed to fetch trial student count", err);
    bugsnagClient.notify(err);
  }
}

function* fetchTaggedStudent({
  activityId,
  firebase,
  trial,
  record,
  staffActivity,
}) {
  try {
    // let data = yield call(ActivityApi.getStudentUpdated, activityId, firebase);
    let students = JSON.parse(localStorage.getItem("studentList"));
    let teachers = JSON.parse(localStorage.getItem("teacherList"));
    var namesMap = [];

    if (staffActivity) {
      if (record && record.studentIds) {
        teachers.filter((s) => {
          if (record.studentIds.includes(s.id)) {
            //namesMap.push(s.name);
            namesMap.push(s);
          }
        });
      }
    } else {
      if (record && record.studentIds) {
        students.filter((s) => {
          if (record.studentIds.includes(s.id)) {
            //namesMap.push(s.name);
            namesMap.push(s);
          }
        });
      }
    }

    if (trial) {
      let bookingRef = yield call(
        ActivityApi.getBookingRefByTime,
        activityId,
        record.meetingTime,
        firebase
      );
      let activityStudentIds = [];
      if (bookingRef && bookingRef.studentIds) {
        activityStudentIds = bookingRef.studentIds;
      }

      let studentNames = [];
      students.filter((s) => {
        if (activityStudentIds.includes(s.id)) {
          // studentNames.push(s.name)
          studentNames.push(s);
        }
      });

      yield put({
        type: actions.GET_TAGGED_STUDENT_DETAIL_SUCCESSFUL,
        taggedStudent: studentNames,
      });
    } else {
      yield put({
        type: actions.GET_TAGGED_STUDENT_DETAIL_SUCCESSFUL,
        taggedStudent: namesMap,
      });
    }
  } catch (error) {
    console.log("failed to fetch tagged student detail", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.ACTIVITY_REQUEST_FAILED,
    });
  }
}

function* requestThumbNail(file, activityId, type, firebase) {
  let endPointUrl = firebase.endPointUrl;
  let thumbEndPointUrl = endPointUrl + "generateThumbnail";
  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(thumbEndPointUrl)
      .send({
        schoolName: firebase.schoolName,
        branchPath: firebase.sbp,
        accountName: firebase.sbDbName,
        fileObject: file,
        type: type,
        activityId: activityId,
        timezone: localTimezone,
      })
      .set("accept", "json")

      .end((err, res) => {
        console.log("thumb res --->>>>>", res);
        resolve(res);
      });
  });
  return p1;
}

function* approveActivity({ item, firebase }) {
  try {
    yield call(ActivityApi.approveSelectedActivity, item, firebase);

    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "activity",
      new Date()
    );

    let selectedActivity = item.activityType;
    let studentIds = item.studentIds;
    let activityId = item.id;
    let parentEmails = [];
    let mediaProfiles = item.mediaProfiles ? item.mediaProfiles : [];

    for (let j in mediaProfiles) {
      if (
        mediaProfiles[j].name &&
        mediaProfiles[j].type &&
        mediaProfiles[j].type.toLowerCase() === "video"
      ) {
        var thumbObj = {};
        thumbObj.name = mediaProfiles[j].name;
        thumbObj.contentType = "video/mp4";
        thumbObj.bucket = firebase.schoolConfig.storageBucket;
        thumbObj.position = j;
        thumbObj.downloadUrl = mediaProfiles[j].path;
        thumbObj.studentIds = studentIds ? studentIds : undefined;
        yield fork(
          requestThumbNail,
          thumbObj,
          activityId,
          "activity",
          firebase
        );
      }
    }

    yield fork(
      updateStudentMedia,
      mediaProfiles,
      studentIds,
      firebase,
      undefined,
      activityId
    );

    for (let index in studentIds) {
      let selectedStudent = yield call(
        StudentApi.getStudentById,
        studentIds[index],
        firebase
      );
      if (selectedStudent && selectedStudent.id && selectedStudent.name) {
        if (selectedStudent.fatherEmail) {
          parentEmails.push(selectedStudent.fatherEmail);
        }
        if (selectedStudent.motherEmail) {
          parentEmails.push(selectedStudent.motherEmail);
        }

        if (selectedStudent.fatherProfileId) {
          let alertNode = yield call(
            NotificationApi.createAlertReferenceNode,
            selectedStudent.fatherProfileId,
            firebase
          );
          yield fork(
            NotificationApi.createAlertNotification,
            selectedActivity,
            activityId,
            selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
            selectedActivity + " activity added for " + selectedStudent.name,
            alertNode,
            selectedStudent.ios_fatherUUid
              ? selectedStudent.ios_fatherUUid
              : null,
            selectedStudent.id,
            selectedStudent.fatherProfileId,
            firebase
          );

          if (
            selectedStudent.fatherUUid !== undefined ||
            selectedStudent.ios_fatherUUid !== undefined
          ) {
            yield fork(
              NotificationApi.sendPushNotification,
              selectedActivity,
              activityId,
              selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
              selectedActivity + " activity added for " + selectedStudent.name,
              alertNode,
              selectedStudent.ios_fatherUUid
                ? selectedStudent.ios_fatherUUid
                : null,
              selectedStudent.id,
              selectedStudent.fatherProfileId,
              firebase
            );
          }
        }

        if (selectedStudent.motherProfileId) {
          let alertNode = yield call(
            NotificationApi.createAlertReferenceNode,
            selectedStudent.motherProfileId,
            firebase
          );
          yield fork(
            NotificationApi.createAlertNotification,
            selectedActivity,
            activityId,
            selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
            selectedActivity + " activity added for " + selectedStudent.name,
            alertNode,
            selectedStudent.ios_motherUUid
              ? selectedStudent.ios_motherUUid
              : null,
            selectedStudent.id,
            selectedStudent.motherProfileId,
            firebase
          );

          if (
            selectedStudent.motherUUid !== undefined ||
            selectedStudent.ios_motherUUid !== undefined
          ) {
            yield fork(
              NotificationApi.sendPushNotification,
              selectedActivity,
              activityId,
              selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
              selectedActivity + " activity added for " + selectedStudent.name,
              alertNode,
              selectedStudent.ios_motherUUid
                ? selectedStudent.ios_motherUUid
                : null,
              selectedStudent.id,
              selectedStudent.motherProfileId,
              firebase
            );
          }
        }
      }
    }
    yield put({
      type: actions.APPROVE_ACTIVITY_SUCCESSFUL,
    });
  } catch (error) {
    console.log("failed to approve activity", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.ACTIVITY_REQUEST_FAILED,
    });
  }
}

function* updateStudentMedia(urls, students, firebase, bPath, activityId) {
  try {
    for (let index in students) {
      for (let i in urls) {
        if (urls[i].type && urls[i].type.toLowerCase() !== "file") {
          let nodeId = yield call(
            ActivityApi.createStudentMediaNode,
            firebase,
            bPath
          );
          let photoDate = new Date();
          let singleUrl = urls[i];
          let localMediaType;
          if (singleUrl.type.toLowerCase() === "image") {
            localMediaType = "photo";
          } else {
            localMediaType = singleUrl.type;
          }

          let createdAt = {
            date: photoDate.getDate(),
            day: photoDate.getDay(),
            hours: photoDate.getHours(),
            minutes: photoDate.getMinutes(),
            month: photoDate.getMonth(),
            seconds: photoDate.getSeconds(),
            time: photoDate.getTime(),
            timezoneOffset: photoDate.getTimezoneOffset(),
            year: photoDate.getFullYear(),
          };

          let photoObj = {
            date: createdAt,
            downloadUrl: singleUrl.path,
            id: nodeId,
            inverseDate: -photoDate.getTime(),
            mediaType: localMediaType.toUpperCase(),
            activityId: activityId ? activityId : null,
          };

          if (
            photoObj.mediaType &&
            photoObj.mediaType.toLowerCase() !== "video"
          ) {
            yield fork(
              ActivityApi.updateStudentMediaAttachment,
              photoObj,
              nodeId,
              students[index],
              firebase,
              bPath
            );
          }
        }
      }
    }
  } catch (err) {
    console.log("failed to upload activity media to student", err);
    bugsnagClient.notify("failed to upload activity media to student", err);
  }
}

function* updateSelectedActivity({ values, record, firebase }) {
  try {
    yield call(ActivityApi.updateActivity, values, record, firebase);
    yield put({
      type: actions.UPDATE_ACTIVITY_SUCCESSFUL,
    });
  } catch (error) {
    console.log("failed to update activity", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.ACTIVITY_REQUEST_FAILED,
    });
  }
}

function* deleteSelectedActivity({ record, firebase }) {
  try {
    yield call(ActivityApi.deleteActivity, record, firebase);
    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "activity",
      new Date()
    );
    yield put({
      type: actions.DELETE_ACTIVITY_SUCCESSFUL,
    });
    if (record.activityType === "Virtual Class") {
      yield fork(deleteMeetingRef, record.id, firebase);
      yield fork(deleteBookingRef, record, firebase);
    }

    if (
      record.activityType === "Check in Form" ||
      record.activityType === "Checkout Form"
    ) {
      yield fork(deleteCheckInOutRefs, record, firebase);
    }
  } catch (error) {
    console.log("failed to delete activity", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.ACTIVITY_REQUEST_FAILED,
    });
  }
}

function* deleteBookingRef(record, firebase) {
  try {
    // let bookingRef = yield call(ActivityApi.getBookingRefByTime, record.id, record.meetingTime, firebase);
    // if (bookingRef && bookingRef.id) {
    //   yield call(ActivityApi.deleteBookingRef, bookingRef, firebase);
    // }
    let bookingRef = yield call(
      ActivityApi.getBookingRefByActivityId,
      record.id,
      firebase
    );
    if (bookingRef && bookingRef.length > 0) {
      for (let index in bookingRef) {
        yield call(ActivityApi.deleteBookingRef, bookingRef[index], firebase);
      }
    }
  } catch (err) {
    console.log("failed to delete booking reference", err);
    bugsnagClient.notify(err);
  }
}

function* deleteCheckInOutRefs(record, firebase) {
  try {
    let date = record.date.time;
    let formattedDate = moment(date).format("YYYY[-]MM[-]DD");
    let studentId =
      record.studentIds && record.studentIds.length > 0
        ? record.studentIds[0]
        : undefined;
    let activityName = record.activityType;

    if (studentId) {
      let inFormVal = yield call(
        ActivityApi.getCheckInOutRefVal,
        activityName,
        formattedDate,
        studentId,
        firebase
      );

      if (inFormVal && inFormVal.id) {
        yield call(
          ActivityApi.deleteInOutRef,
          activityName,
          inFormVal,
          studentId,
          firebase
        );
        yield call(ActivityApi.resetStudentLastAtd, studentId, firebase);
      }
    } else {
      bugsnagClient.notify(new Error("Student id missing"));
      yield put({
        type: actions.ACTIVITY_REQUEST_FAILED,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.ACTIVITY_REQUEST_FAILED,
    });
  }
}

function* deleteMeetingRef(activityId, firebase) {
  try {
    let data = yield call(ActivityApi.getMeetingRefById, activityId, firebase);
    if (data && data.length > 0) {
      for (let index in data) {
        if (data[index].activityId) {
          yield fork(
            ActivityApi.deleteMeetingReference,
            data[index].id,
            firebase
          );
        }
      }
    }
  } catch (err) {
    console.log("failed to delete meeting ref", err);
    bugsnagClient.notify(err);
  }
}

function* fetchMeetingReminder({ startDate, endDate, firebase }) {
  try {
    const chan = yield call(
      ActivityApi.getMeetingRef,
      startDate,
      endDate,
      firebase
    );
    while (true) {
      let data = yield take(chan);
      let activities = [];
      let activityIds = {};
      for (let index in data) {
        let val = yield call(
          ActivityApi.getActivityById,
          data[index].activityId,
          firebase
        );

        if (
          val.id &&
          val.createdBy.toLowerCase() === firebase.teacher.name.toLowerCase() &&
          !activityIds[val.id]
        ) {
          activities.push(val);
        }
        activityIds[val.id] = val;
      }
      yield put({
        type: actions.GET_MEETING_REMINDER_SUCCESSFUL,
        meetingReminder: activities,
        meetingReminderChan: chan,
      });
    }
  } finally {
    console.log("terminating upcoming meeting");
  }
}

function* stopVirtualClassRepeat({ record, firebase, repeatVirtualClass }) {
  try {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;

    let activity = record;
    activity.updatedBy = firebase.teacher.name;
    activity.updatedOn = moment().valueOf();
    activity.repeatEndDate = 0;
    activity.repeatStartDate = 0;
    activity.repeat = false;

    rsf.ref(branchPath + "/activities/" + activity.id).update({
      updatedBy: activity.updatedBy,
      updatedOn: activity.updatedOn,
      repeatEndDate: activity.repeatEndDate,
      repeatStartDate: activity.repeatStartDate,
      repeat: activity.repeat,
    });

    if (repeatVirtualClass) {
      let repeatClassId;
      if (activity.parentActivityId) {
        repeatClassId = activity.parentActivityId;
      } else {
        repeatClassId = activity.id;
      }

      if (repeatClassId) {
        let repeatClass = yield call(
          ActivityApi.getRepeatClassById,
          repeatClassId,
          firebase
        );
        if (repeatClass && repeatClass.id) {
          repeatClass.updatedBy = firebase.teacher.name;
          repeatClass.updatedOn = moment().valueOf();
          repeatClass.repeatEndDate = 0;
          repeatClass.repeatStartDate = 0;
          repeatClass.repeat = false;

          rsf.ref(branchPath + "/repeatClasses/" + repeatClass.id).update({
            updatedBy: repeatClass.updatedBy,
            updatedOn: repeatClass.updatedOn,
            repeatEndDate: repeatClass.repeatEndDate,
            repeatStartDate: repeatClass.repeatStartDate,
            repeat: repeatClass.repeat,
          });

          rsf.ref(branchPath + "/activities/" + repeatClass.id).update({
            updatedBy: repeatClass.updatedBy,
            updatedOn: repeatClass.updatedOn,
            repeatEndDate: repeatClass.repeatEndDate,
            repeatStartDate: repeatClass.repeatStartDate,
            repeat: repeatClass.repeat,
          });
        }
      }
    }
    yield put({
      type: actions.STOP_CLASS_REPETITION_SUCCESS,
    });
  } catch (err) {
    console.log("failed to stop virtual class", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.ACTIVITY_REQUEST_FAILED,
    });
  }
}

function* fetchStudentList({ date, firebase }) {
  try {
    let studentAttendance = yield call(
      StudentAttendanceApi.fetchAttendanceData,
      date,
      firebase
    );

    let filteredStudent = [];

    if (studentAttendance && studentAttendance.size > 0) {
      let students = JSON.parse(localStorage.getItem("studentList"));
      if (students && students.length > 0) {
        students.forEach((std) => {
          if (
            (!std.status || std.status.toLowerCase() === "active") &&
            studentAttendance.has(std.id)
          ) {
            filteredStudent.push(std);
          }
        });
        // filteredStudent = students.filter(s => {
        //   return !s.status || s.status.toLowerCase() === "active"
        // });
      }
    }

    yield put({
      type: actions.GET_STUDENTS_LIST_FOR_ACTIVITY_LEVEL_VIEW_SUCCESS,
      studentList: filteredStudent,
    });
  } catch (err) {
    bugsnagClient.notify(err);
  }
}

function* getIndStudentActivities({ activities, requestType, firebase }) {
  if (!requestType) {
    yield put({
      type: actions.SET_IND_STUDENT_ACTIVITIES_SUCCESS,
      individualStudentActivities: activities,
    });
  } else {
    let refreshedActivities = [];
    let activityTask = [];
    for (let index in activities) {
      let task = call(
        ActivityApi.getActivityById,
        activities[index].id,
        firebase
      );
      activityTask.push(task);
    }

    let newVal = yield all([activityTask]);
    for (let i in newVal[0]) {
      let val = newVal[0][i];
      if (val && val.id) {
        refreshedActivities.push(val);
      }
    }
    yield put({
      type: actions.SET_IND_STUDENT_ACTIVITIES_SUCCESS,
      individualStudentActivities: refreshedActivities,
    });
  }
}

function* removeStudentFromOnlineClass({
  student,
  activity,
  studentList,
  firebase,
  trial,
  activityList,
}) {
  try {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;

    if (student.status && student.status.toLowerCase() === "trial") {
      let studentBookings = yield call(
        ActivityApi.getStudentBookingById,
        student.id,
        firebase
      );
      if (studentBookings) {
        for (let index in studentBookings) {
          let bookingRefId = studentBookings[index].bookingRefId;
          let bookingRef = yield call(
            ActivityApi.getBookingRefById,
            bookingRefId,
            firebase
          );
          if (bookingRef && bookingRef.id) {
            let bookingStudentIds = bookingRef.studentIds
              ? bookingRef.studentIds
              : [];
            let filteredBookingStudentIds = bookingStudentIds.filter((b) => {
              return b !== student.id;
            });
            bookingRef.studentIds = filteredBookingStudentIds;
            yield call(ActivityApi.updatedBookingRef, bookingRef, firebase);
            yield call(
              NotificationApi.updateJoinedMeeting,
              activity,
              student,
              firebase,
              "TRIAL_CANCELLED",
              bookingRef
            );
          }
        }
      } else {
        yield call(
          NotificationApi.updateJoinedMeeting,
          activity,
          student,
          firebase,
          "TRIAL_CANCELLED"
        );
      }

      yield fork(
        ActivityApi.removeCompleteStudentBookingRef,
        student.id,
        firebase
      );
      yield fork(TimelineApi.cancelMeetingReminder, student.id, firebase);

      student.meetingDate = null;
      student.meetingDuration = null;
      student.activityId = null;
      student.trialCount = student.trialCount
        ? Number(student.trialCount + 1)
        : 1;
      student.meetingJoinedDate = null;
      student.platform = "web";
      student.updatedOn = moment().valueOf();
      student.updatedBy = firebase.teacher.name;
      student.timezone = null;
      yield fork(
        StudentApi.updateStudentWithUpdatedFormFields,
        student,
        firebase
      );
    } else {
      activity.platform = "web";
      activity.updatedBy = firebase.teacher.name;

      let activityStudentIds = activity.studentIds;
      let filteredActivityStudentIds = activityStudentIds.filter((a) => {
        return a !== student.id;
      });
      activity.studentIds = filteredActivityStudentIds;

      rsf.ref(branchPath + "/activities/" + activity.id).update({
        platform: activity.platform,
        updatedBy: activity.updatedBy,
        studentIds: activity.studentIds,
      });

      let repeatVirtualClass =
        activity.repeatStartDate > 0 && activity.repeatEndDate > 0
          ? true
          : false;
      if (repeatVirtualClass) {
        let repeatClassId;
        if (activity.parentActivityId) {
          repeatClassId = activity.parentActivityId;
        } else {
          repeatClassId = activity.id;
        }

        if (repeatClassId) {
          let repeatClass = yield call(
            ActivityApi.getRepeatClassById,
            repeatClassId,
            firebase
          );
          if (repeatClass && repeatClass.id) {
            repeatClass.updatedBy = firebase.teacher.name;
            repeatClass.updatedOn = moment().valueOf();
            repeatClass.studentIds = activity.studentIds
              ? activity.studentIds
              : [];

            rsf.ref(branchPath + "/repeatClasses/" + repeatClass.id).update({
              updatedBy: repeatClass.updatedBy,
              updatedOn: repeatClass.updatedOn,
              studentIds: repeatClass.studentIds,
            });
          }

          rsf
            .ref(branchPath + "/activities/" + repeatClassId)
            .child("studentIds")
            .set(activity.studentIds ? activity.studentIds : []);
        }
      }

      //remove student from filter node
      yield fork(removeStudentFromFilterNode, student, activity, firebase);
    }
    studentList = studentList.filter((st) => {
      return st.id !== student.id;
    });

    yield put({
      type: actions.REMOVE_STUDENT_FROM_V_CLASS_SUCCESS,
    });

    yield put({
      type: actions.GET_TAGGED_STUDENT_DETAIL_SUCCESSFUL,
      taggedStudent: studentList,
    });

    yield fork(fetchTrialStudentCount, activityList, firebase);
  } catch (err) {
    console.log("failed to remove student from virtual class", err);
    bugsnagClient.notify(err);
  }
}

function* removeStudentBookingRef(bookingRef, student, firebase) {
  try {
    let studentBookingRef = yield call(
      ActivityApi.getStudentBookingRef,
      student.id,
      bookingRef.id,
      firebase
    );

    if (studentBookingRef && studentBookingRef.id) {
      yield call(
        ActivityApi.removeStudentBookingRef,
        student.id,
        studentBookingRef.id,
        firebase
      );
    }
  } catch (err) {
    console.log("failed to remove student booking reference", err);
    bugsnagClient.notify(err);
  }
}

function* removeStudentFromFilterNode(student, activity, firebase) {
  try {
    let activityType = "Virtual Class";
    let data = [];
    data = yield call(
      ActivityApi.getStudentFilterNodeByActivityId,
      activityType,
      student.id,
      activity.id,
      firebase
    );

    if (activity.parentActivityId) {
      let parentData = yield call(
        ActivityApi.getStudentFilterNodeByActivityId,
        activityType,
        student.id,
        activity.parentActivityId,
        firebase
      );
      if (parentData) {
        data = [...data, ...parentData];
      }
    }

    if (data && data.length > 0) {
      for (let index in data) {
        yield call(
          ActivityApi.removeFilterNode,
          student.id,
          data[index].id,
          activityType,
          firebase
        );
      }
    }
  } catch (err) {
    console.log("failed to remove student from the filter node", err);
    bugsnagClient.notify(err);
  }
}

function* getBookingActivity({ students, firebase }) {
  try {
    let activityMap = new Map();
    let activityTask = [];
    for (let index in students) {
      if (students[index].activityId) {
        let activityId = students[index].activityId;
        let task = call(ActivityApi.getActivityById, activityId, firebase);
        activityTask.push(task);
      }
    }

    let newVal = yield all([activityTask]);
    for (let i in newVal[0]) {
      let val = newVal[0][i];

      if (val && val.id) {
        activityMap.set(val.id, val);
      }
    }

    yield put({
      type: actions.GET_BOOKING_ACTIVITY_SUCCESS,
      bookingActivities: activityMap,
    });
  } catch (err) {
    console.log("failed to fetch booking acitivity", err);
    bugsnagClient.notify(err);
  }
}

function* putNewStudentToMeeting({
  activity,
  students,
  firebase,
  selectedTimezone,
}) {
  try {
    let studentIds = [];
    let existingStudentIds = activity.studentIds ? activity.studentIds : [];
    for (let index in students) {
      let id = students[index].id;
      if (!existingStudentIds.includes(id)) {
        studentIds.push(id);
      }
    }

    let bookingRef = undefined;
    let response = yield call(
      NotificationApi.addStudentToMeeting,
      activity,
      bookingRef,
      studentIds,
      firebase,
      selectedTimezone
    );
    if (response && response.status && response.status === 200) {
      notification("success", "Student added to virtual class");
      yield put({
        type: actions.ADD_NEW_STUDENT_TO_MEETING_SUCCESS,
      });
    } else {
      yield put({
        type: actions.VIRTUAL_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to add student to meeting", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.ACTIVITY_REQUEST_FAILED,
    });
  }
}

function* removeSelectedTrialStudent({ student, firebase }) {
  try {
    let studentBookings = yield call(
      ActivityApi.getStudentBookingById,
      student.id,
      firebase
    );
    if (studentBookings) {
      console.log("studentBookings ----", studentBookings);
      for (let index in studentBookings) {
        let bookingRefId = studentBookings[index].bookingRefId;
        let bookingRef = yield call(
          ActivityApi.getBookingRefById,
          bookingRefId,
          firebase
        );
        if (bookingRef && bookingRef.id) {
          let item = yield call(
            ActivityApi.getActivityById,
            student.activityId,
            firebase
          );
          let bookingStudentIds = bookingRef.studentIds
            ? bookingRef.studentIds
            : [];
          let filteredBookingStudentIds = bookingStudentIds.filter((b) => {
            return b !== student.id;
          });
          bookingRef.studentIds = filteredBookingStudentIds;
          yield call(ActivityApi.updatedBookingRef, bookingRef, firebase);
          yield call(
            NotificationApi.updateJoinedMeeting,
            item,
            student,
            firebase,
            "TRIAL_CANCELLED",
            bookingRef
          );
        }
      }
    }

    student.meetingDate = null;
    student.meetingDuration = null;
    student.activityId = null;
    student.trialCount = student.trialCount
      ? Number(student.trialCount + 1)
      : 1;
    student.meetingJoinedDate = null;
    student.platform = "web";
    student.updatedOn = moment().valueOf();
    student.updatedBy = firebase.teacher.name;
    student.timezone = null;
    yield fork(
      StudentApi.updateStudentWithUpdatedFormFields,
      student,
      firebase
    );
    yield fork(
      ActivityApi.removeCompleteStudentBookingRef,
      student.id,
      firebase
    );
    yield fork(TimelineApi.cancelMeetingReminder, student.id, firebase);
    yield put({
      type: actions.REMOVE_TRIAL_STUDENT_SUCCESS,
    });
  } catch (err) {
    console.log("failed to remove selected trial student", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.ACTIVITY_REQUEST_FAILED,
    });
  }
}

function* fetchMeetingActivity({ date, firebase }) {
  try {
    let meetingRefs = yield call(
      ActivityApi.getVirtualClassActivityRef,
      undefined,
      firebase,
      date
    );
    if (meetingRefs) {
      let meetingTimeArr = [];
      let teacherMap = new Map();
      let activityMap = new Map();
      let existingActivityIds = [];
      const activitiesTask = [];
      for (let index in meetingRefs) {
        let activityId = meetingRefs[index].activityId;
        if (!existingActivityIds.includes(activityId)) {
          let task = call(
            TimelineApi.fetchAltTimelineActivities,
            activityId,
            firebase
          );
          activitiesTask.push(task);
          existingActivityIds.push(activityId);
        }
      }

      let newVal = yield all([activitiesTask]);
      for (let i in newVal[0]) {
        let val = newVal[0][i];
        if (val && val.id && val.meetingId && !val.meetingBookable) {
          activityMap.set(val.id, val);

          if (teacherMap.has(val.createdBy)) {
            let teacherVal = teacherMap.get(val.createdBy);
            teacherVal.push(val);
            teacherMap.set(val.createdBy, teacherVal);
          } else {
            let teacherVal = [];
            teacherVal.push(val);
            teacherMap.set(val.createdBy, teacherVal);
          }
        }
      }

      let meetingData = [];
      for (let [key, value] of teacherMap) {
        let data = {};
        data.name = key;

        for (let i in value) {
          if (activityMap.has(value[i].id)) {
            data[moment(value[i].meetingTime).format("hh:mm A")] = {
              activity: activityMap.has(value[i].id)
                ? activityMap.get(value[i].id)
                : undefined,
            };

            let meetingTime = moment(value[i].meetingTime).format("hh:mm A");
            if (!meetingTimeArr.includes(meetingTime)) {
              meetingTimeArr.push(meetingTime);
            }
          }
        }
        meetingData.push(data);
      }

      meetingTimeArr.sort(function(a, b) {
        return new Date("1970/01/01 " + a) - new Date("1970/01/01 " + b);
      });

      // console.log("meetingTimeArr ---", meetingTimeArr);
      // console.log("meetingData", meetingData);
      yield put({
        type: actions.GET_MEETING_ACTIVITY_SUCCESS,
        meetingActivityTime: meetingTimeArr,
        meetingActivityReport: meetingData,
      });
    }
  } catch (err) {
    console.log("failed to fetch meeting activity", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.ACTIVITY_REQUEST_FAILED,
    });
  }
}

function* updateMeetingDefaultCapacity({ capacity, firebase }) {
  try {
    var schoolName = localStorage.getItem("selectedDBName");
    yield call(
      UserSettingApi.updateDefCapacity,
      schoolName,
      capacity,
      firebase
    );
    yield put({
      type: actions.UPDATE_CAPACITY_SUCCESS,
    });
  } catch (err) {
    console.log("failed to update meeting default capacity", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.ACTIVITY_REQUEST_FAILED,
    });
  }
}

function* fetchCustomLabels({ firebase, category }) {
  try {
    const chan = yield call(ActivityApi.getCustomLabels, firebase, category);
    while (true) {
      let data = yield take(chan);
      console.log("fetchCustomLabels", data);
      yield put({
        type: actions.GET_CUSTOM_LABELS_SUCCESS,
        customLabelsVal: data,
        customLabelsChan: chan,
      });
    }
  } finally {
    console.log("terminating custom labels");
  }
}

function* editSelectedCustomLabel({ record, firebase }) {
  try {
    yield call(ActivityApi.updateCustomLabels, record, firebase);
    yield put({
      type: actions.EDIT_CUSTOM_LABELS_SUCCESS,
    });
  } catch (err) {
    console.log("failed to edit custom labels", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.ACTIVITY_REQUEST_FAILED,
    });
  }
}

function* deleteSelectedCustomLabel({ record, firebase }) {
  try {
    yield call(ActivityApi.updateCustomLabels, record, firebase);
    yield put({
      type: actions.DELETE_CUSTOM_LABELS_SUCCESS,
    });
  } catch (err) {
    console.log("failed to delete custom labels", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.ACTIVITY_REQUEST_FAILED,
    });
  }
}

function* addNewCustomLabelCategory({ val, label, firebase }) {
  try {
    let record = { name: val, labels: label };
    console.log("addNewCustomLabelCategory", record);
    yield call(ActivityApi.updateCustomLabels, record, firebase);
    yield put({
      type: actions.ADD_CUSTOM_LABEL_CATEGORY_SUCCESS,
    });
  } catch (err) {
    console.log("failed to add new custom label category", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.ACTIVITY_REQUEST_FAILED,
    });
  }
}

function* updateLastSelectedActivityList({ list }) {
  try {
    yield put({
      type: actions.UPDATE_LAST_STUDENT_LEVEL_ACTIVITY_SUCCESS,
      studentLevelActivityList: list,
    });
  } catch (err) {
    console.log("failed to update last selected activity", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.ACTIVITY_REQUEST_FAILED,
    });
  }
}

function* fetchActivityLabel({ firebase }) {
  try {
    let data = yield call(
      ActivityApi.getCustomLabelByCategory,
      "Activity",
      firebase
    );
    if (data) {
      yield put({
        type: actions.GET_ACTIVITY_LABEL_SUCCESS,
        activityLabels: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch activity labels", err);
    bugsnagClient.notify(err);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_TODAYS_POST_FOR_ACTIVITY, fetchTodaysPost),
    yield takeLatest(actions.GET_STAFF_POSTS, fetchStaffPost),
    yield takeLatest(actions.GET_ACTIVITY_MAP, fetchActivityMap),
    yield takeLatest(actions.GET_CLASSROOM_FOR_ACTIVITY, fetchClassroom),
    yield takeLatest(
      actions.GET_ACTIVITIES_BY_CLASSROOM,
      fetchActivityByClassroom
    ),
    yield takeLatest(actions.GET_TAGGED_STUDENT_DETAIL, fetchTaggedStudent),
    yield takeLatest(actions.APPROVE_ACTIVITY, approveActivity),
    yield takeLatest(actions.UPDATE_ACTIVITY, updateSelectedActivity),
    yield takeLatest(actions.DELETE_ACTIVITY, deleteSelectedActivity),
    yield takeLatest(actions.GET_MEETING_REMINDER, fetchMeetingReminder),
    yield takeLatest(actions.STOP_CLASS_REPETITION, stopVirtualClassRepeat),
    yield takeLatest(
      actions.GET_NOTIFICATION_ACTIVITY,
      fetchNotificationActivity
    ),
    yield takeLatest(
      actions.GET_STUDENTS_LIST_FOR_ACTIVITY_LEVEL_VIEW,
      fetchStudentList
    ),
    yield takeLatest(
      actions.SET_IND_STUDENT_ACTIVITIES,
      getIndStudentActivities
    ),
    yield takeLatest(
      actions.REMOVE_STUDENT_FROM_V_CLASS,
      removeStudentFromOnlineClass
    ),
    yield takeLatest(actions.GET_BOOKING_ACTIVITY, getBookingActivity),
    yield takeLatest(
      actions.ADD_NEW_STUDENT_TO_MEETING,
      putNewStudentToMeeting
    ),
    yield takeLatest(actions.REMOVE_TRIAL_STUDENT, removeSelectedTrialStudent),
    yield takeLatest(actions.GET_MEETING_REPORT, fetchMeetingReport),
    yield takeLatest(actions.GET_MEETING_ACTIVITY, fetchMeetingActivity),
    yield takeLatest(actions.UPDATE_CAPACITY, updateMeetingDefaultCapacity),
    yield takeLatest(actions.GET_CUSTOM_LABELS, fetchCustomLabels),
    yield takeLatest(actions.EDIT_CUSTOM_LABELS, editSelectedCustomLabel),
    yield takeLatest(actions.DELETE_CUSTOM_LABELS, deleteSelectedCustomLabel),
    yield takeLatest(
      actions.ADD_CUSTOM_LABEL_CATEGORY,
      addNewCustomLabelCategory
    ),
    yield takeLatest(
      actions.UPDATE_LAST_STUDENT_LEVEL_ACTIVITY,
      updateLastSelectedActivityList
    ),
    yield takeLatest(actions.GET_ACTIVITY_LABEL, fetchActivityLabel),
  ]);
}
