import actions from "./actions";
const initState = {
    comments: [],
    isLoading: false,
    error: false,
    errorMessage: undefined,
    operationType: undefined,
    commentsChannel: undefined
};

export default function commentsReducer(state = initState, { type, ...action }) {
    switch (type) {
        case actions.GET_COMMENTS:
            return {
                ...state,
                isLoading: true,
            };
        case actions.GET_COMMENTS_SUCCESSFULL:
            return {
                ...state,
                comments: action.comments,
                isLoading: false,
                commentsChannel: action.commentsChannel,
            };

        case actions.ADD_COMMENT:
            return {
                ...state,
            };
        case actions.ADD_COMMENT_SUCCESS:
            return {
                ...state,
            };

        case actions.ADD_PARENT_COMMENT:
            return {
                ...state,
            };
        case actions.ADD_PARENT_COMMENT_SUCCESS:
            return {
                ...state,
            };

        case actions.COMMENT_REQ_FAILED:
            return {
                ...state,
                error: true,
                errorMessage: action.errorMessage
            };

        case actions.DELETE_COMMENT:
            return {
                ...state,
            };

        default:
            return state;
    }
}