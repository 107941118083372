import colors from "../../Utility/colorFactory";
import virtualClass from "../../image/sidebar-virtualClass.png";
import mySchool from "../../image/sidebar_dashboard.png";
import learning from "../../image/sidebar_learning.png";
import parentCommunication from "../../image/sidebar_parentCommunication.png";
import billing from "../../image/sidebar_billing.png";
import dashboardIcon from "../../image/sidebar_dashboard_1.png";

const parentOptions = [
    {
        key: "teacherMode",
        label: "sidebar.teacherMode",
        leftImage: dashboardIcon
    },
    {
        key: "godMode",
        label: "sidebar.godMode",
        leftIcon: "ion-stats-bars"
    },
    {
        key: "virtualClassManagement",
        label: "sidebar.manageVirtualClass",
        leftImage: virtualClass,
    },
    {
        key: "timeline",
        label: "sidebar.parentDashboard",
        leftImage: mySchool,
    },
    {
        key: "virtualClass",
        label: "sidebar.virtualClass",
        leftImage: virtualClass,
    },
    {
        key: "studentLesson",
        label: "sidebar.studentLesson",
        leftImage: learning,
    },
    {
        key: "schoolMessages",
        label: "sidebar.messages",
        leftImage: parentCommunication,
    },
    {
        key: "studentInvoices",
        label: "sidebar.studentInvoices",
        leftImage: billing,
    },
    // {
    //     key: "customlearningplan",
    //     label: "sidebar.getready",
    //     leftIcon: "ion-ios-book"
    // },
    {
        key: "studentProfile",
        label: "sidebar.studentProfile",
        leftIcon: "ion-android-person",
    },
    // {
    //     key: "schedulePlaydate",
    //     label: "sidebar.schedulePlayDate",
    //     leftIcon: "ion-android-happy"
    // },
    {
        key: "virtualLibrary",
        label: "sidebar.virtualLibrary",
        leftImage: virtualClass,
    },
    // {
    //     key: "contactUs",
    //     label: "sidebar.contactUs",
    //     leftIcon: "ion-ios-telephone",
    // }
];
export default parentOptions;
