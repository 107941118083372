import React, { Component } from "react";
import { connect } from "react-redux";
import clone from "clone";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import options from "./options";
import parentOptions from "./parentOptions";
import Scrollbars from "../../components/utility/customScrollBar.js";
import Menu from "../../components/uielements/menu";
import IntlMessages from "../../components/utility/intlMessages";
import SidebarWrapper from "./sidebar.style";
import appActions from "../../redux/app/actions";
import Logo from "../../components/utility/logo";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";
import { compose } from "redux";
import { withFirebase } from "../../components/firebase/context";
import smallIllumineLogo from "../../image/illumine_logo.png";
import FilterPermission from "../../Utility/FilterPermission";
import PermissionStrings from "../../Utility/PermissionStrings";
import colors from "../../Utility/colorFactory";
import { dir } from "../../index";
const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed,
} = appActions;
const stripTrailingSlash = (str) => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }

  handleClick(e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === "MobileView") {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }
  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      (key) => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      (key) => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = (key) => {
    const map = {
      sub3: ["sub2"],
    };
    return map[key] || [];
  };

  checkIfPermission(key) {
    const { firebase } = this.props;
    var permission = true;
    switch (key) {
      case "timeline":
        permission =
          firebase.schoolConfig &&
          firebase.student &&
          firebase.student.status &&
          firebase.student.status.toLowerCase() !== "trial";
        break;

      case "students":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.SM_View_Student_List,
          firebase
        );
        break;

      case "staff":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.STAFF_M_Staff_List,
          firebase
        );
        break;

      case "rooms":
        permission = true;
        break;

      case "tags":
        permission = true;
        break;

      case "multiCenterDashboard":
        permission =
          firebase && firebase.teacher && firebase.teacher.newBranches
            ? true
            : false;
        break;

      case "studentAttendance":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.SM_RECORD_ATTENDANCE,
          firebase
        );
        break;

      case "staffAttendance":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.STAFF_M_RECORD_ATTENDANCE,
          firebase
        );
        break;

      case "fee":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.BILLING_View,
          firebase
        );
        break;

      case "feeStructure":
        if (
          firebase &&
          !firebase.isParent &&
          firebase.schoolConfig.billingMode &&
          firebase.schoolConfig.billingMode === "Fee Plan" &&
          FilterPermission.checkIfPermission(
            PermissionStrings.BILLING_Create_Edit_Fee_Template,
            firebase
          )
        ) {
          permission = true;
          break;
        } else {
          permission = false;
          break;
        }

      case "invoiceTemplate":
        if (
          !firebase.isParent &&
          !firebase.schoolConfig.billingMode &&
          FilterPermission.checkIfPermission(
            PermissionStrings.BILLING_View,
            firebase
          )
        ) {
          permission = true;
          break;
        } else {
          permission = false;
          break;
        }

      case "schoolDetails":
        permission = true;
        break;

      case "parentMode":
        if (
          firebase &&
          firebase.user &&
          firebase.user.studentId &&
          firebase.selectedMode &&
          firebase.selectedMode === "teacher"
        ) {
          permission = true;
          break;
        } else {
          permission = false;
          break;
        }

      case "teacherMode":
        if (
          this.props.firebase &&
          this.props.firebase.selectedMode &&
          this.props.firebase.selectedMode === "parent" &&
          this.props.firebase.user.userType.toLowerCase() === "teacher"
        ) {
          permission = true;
          break;
        } else {
          permission = false;
          break;
        }

      case "godMode":
        if (this.props.firebase && this.props.firebase.isGod) {
          permission = true;
          break;
        } else {
          permission = false;
          return;
        }

      case "activities":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.POST_ACTIVITY_VIEW,
          firebase
        );
        break;

      case "mySchool":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.POST_ACTIVITY_VIEW,
          firebase
        );
        break;

      case "studentLevelView":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.POST_ACTIVITY_VIEW,
          firebase
        );
        break;

      case "events":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.EVENTS_VIEW,
          firebase
        );
        break;

      case "holiday":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.HOLIDAYS_VIEW,
          firebase
        );
        break;

      case "lessons":
        permission =
          FilterPermission.checkIfPermission(
            PermissionStrings.LESSON_VIEW,
            firebase
          ) ||
          FilterPermission.checkIfPermission(
            PermissionStrings.LESSON_VIEW_W_PLAN,
            firebase
          );
        break;

      case "lessonPlans":
        permission =
          FilterPermission.checkIfPermission(
            PermissionStrings.LESSON_VIEW,
            firebase
          ) ||
          FilterPermission.checkIfPermission(
            PermissionStrings.LESSON_VIEW_W_PLAN,
            firebase
          );
        break;

      case "lessonAssignments":
        permission =
          FilterPermission.checkIfPermission(
            PermissionStrings.LESSON_VIEW,
            firebase
          ) ||
          FilterPermission.checkIfPermission(
            PermissionStrings.LESSON_VIEW_W_PLAN,
            firebase
          );
        break;

      case "schedule":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.SCHEDULE_VIEW,
          firebase
        );
        break;

      case "permissions":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.SETTINGS_VIEW_CHANGE,
          firebase
        );
        break;

      case "assessments":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.ASSESSMENT_VIEW,
          firebase
        );
        break;

      case "program":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.PROGRAM_View,
          firebase
        );
        break;

      case "leads":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.LEAD_View,
          firebase
        );
        break;

      case "studentBilling":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.BILLING_View,
          firebase
        );
        break;

      case "virtualClassManagement":
        permission =
          firebase &&
          firebase.schoolConfig &&
          firebase.schoolConfig.classBookingEnabled &&
          firebase.student &&
          firebase.student.status &&
          firebase.student.status.toLowerCase() === "trial";
        break;

      case "customlearningplan":
        permission =
          firebase &&
          firebase.schoolConfig &&
          firebase.schoolConfig.classBookingEnabled &&
          firebase.student &&
          firebase.student.status.toLowerCase() === "trial";
        break;

      case "bookingReport":
        permission =
          firebase &&
          firebase.schoolConfig &&
          firebase.schoolConfig.classBookingEnabled &&
          firebase.selectedMode &&
          firebase.selectedMode === "teacher";
        break;

      case "meetingReport":
        permission =
          firebase &&
          firebase.schoolConfig &&
          firebase.schoolConfig.classBookingEnabled &&
          firebase.selectedMode &&
          firebase.selectedMode === "teacher";
        break;

      case "studentLesson":
        permission =
          firebase.student &&
          firebase.student.status &&
          firebase.student.status.toLowerCase() !== "trial";
        break;

      case "virtualClass":
        permission =
          firebase.student &&
          firebase.student.status &&
          firebase.student.status.toLowerCase() !== "trial";
        break;

      case "document":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.STAFF_M_CURRICULUM_DOC_VIEW,
          firebase
        );
        break;

      case "schoolMessages":
        if (
          firebase &&
          firebase.student &&
          firebase.student.status &&
          firebase.student.status.toLowerCase() !== "trial"
        ) {
          permission = true;
          break;
        } else {
          permission = false;
          break;
        }

      case "studentProfile":
        if (
          firebase &&
          firebase.student &&
          firebase.student.status &&
          firebase.student.status.toLowerCase() !== "trial"
        ) {
          permission = true;
          break;
        } else {
          permission = false;
          break;
        }

      // case "schedulePlaydate":
      //   if (firebase && firebase.schoolConfig && firebase.schoolConfig.classBookingEnabled && firebase.student && firebase.student.status && firebase.student.status.toLowerCase() === "active") {
      //     permission = true;
      //     break;
      //   } else {
      //     permission = false;
      //     break;
      //   }

      // case "contactUs":
      //   if (
      //     firebase &&
      //     firebase.schoolConfig &&
      //     firebase.schoolConfig.classBookingEnabled &&
      //     firebase.dbName == 'GetReadyEdu_Master-Branch' &&
      //     firebase.student &&
      //     firebase.student.status &&
      //     firebase.student.status.toLowerCase() === "active"
      //   ) {
      //     permission = true;
      //     break;
      //   } else {
      //     permission = false;
      //     break;
      //   }

      case "virtualLibrary":
        if (
          firebase &&
          firebase.schoolConfig &&
          firebase.schoolConfig.classBookingEnabled &&
          firebase.dbName == "GetReadyEdu_Master-Branch" &&
          firebase.student &&
          firebase.student.status &&
          firebase.student.status.toLowerCase() === "active"
        ) {
          permission = true;
          break;
        } else {
          permission = false;
          break;
        }

      case "staffLeaves":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.STAFF_M_VIEW_STAFF_LEAVES,
          firebase
        );
        break;

      case "staffCommunication":
        permission = true;
        break;

      case "complains":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.CONCERN_VIEW_COMMENT,
          firebase
        );
        break;

      case "queryList":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.QUERIES_VIEW_REPLY,
          firebase
        );
        break;

      case "leaves":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.LEAVES_VIEW,
          firebase
        );
        break;

      case "parentNotes":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.NOTES_View_Mark_Done,
          firebase
        );
        break;

      default:
        permission = true;
    }
    return permission;
  }
  getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
    const {
      key,
      label,
      leftIcon,
      children,
      iconColor,
      leftImage,
    } = singleOption;
    if (this.checkIfPermission(key)) {
      const url = stripTrailingSlash(this.props.url);
      if (children) {
        return (
          <SubMenu
            key={key}
            title={
              <span className="isoMenuHolder" style={submenuColor}>
                {leftImage ? (
                  <img
                    src={leftImage}
                    alt="icon"
                    style={{
                      height: 20,
                      width: 20,
                      marginRight: dir === "rtl" ? 0 : 20,
                      marginLeft: dir === "rtl" ? 20 : 0,
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <i className={leftIcon} style={{ color: iconColor }} />
                )}
                <span className="nav-text">
                  <IntlMessages id={label} />
                </span>
              </span>
            }
          >
            {children.map((child) => {
              if (this.checkIfPermission(child.key)) {
                const linkTo = child.withoutDashboard
                  ? `/${child.key}`
                  : `${url}/${child.key}`;
                return (
                  <Menu.Item style={submenuStyle} key={child.key}>
                    <Link style={submenuColor} to={linkTo}>
                      <IntlMessages id={child.label} />
                    </Link>
                  </Menu.Item>
                );
              }
            })}
          </SubMenu>
        );
      }
      return (
        <Menu.Item key={key}>
          <Link to={key ? `${url}/${key}` : `${url}`}>
            <span className="isoMenuHolder" style={submenuColor}>
              {leftImage ? (
                <img
                  src={leftImage}
                  alt="icon"
                  style={{
                    height: 20,
                    width: 20,
                    marginRight: dir === "rtl" ? 0 : 20,
                    marginLeft: dir === "rtl" ? 20 : 0,
                    objectFit: "contain",
                  }}
                />
              ) : (
                <i className={leftIcon} style={{ color: iconColor }} />
              )}
              <span className="nav-text">
                <IntlMessages id={label} />
              </span>
            </span>
          </Link>
        </Menu.Item>
      );
    }
  };

  getOptions() {
    const { firebase } = this.props;
    const customizedTheme = themes[themeConfig.theme];
    const submenuStyle = {
      //backgroundColor: 'rgba(0,0,0,0.3)',
      backgroundColor: colors.v2_base,
      color: colors.v2_Cerulean,
      //color: customizedTheme.textColor
    };
    const submenuColor = {
      //color: customizedTheme.textColor
      color: colors.v2_Cerulean,
    };

    if (
      firebase &&
      firebase.selectedMode &&
      firebase.selectedMode.toLowerCase() === "parent"
    ) {
      return parentOptions.map((singleOption) =>
        this.getMenuItem({ submenuStyle, submenuColor, singleOption })
      );
    } else {
      return options.map((singleOption) =>
        this.getMenuItem({ submenuStyle, submenuColor, singleOption })
      );
    }
  }

  render() {
    const { app, toggleOpenDrawer, height, firebase, noDisplay } = this.props;
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    const mode = collapsed === true ? "vertical" : "inline";
    const onMouseEnter = (event) => {
      if (openDrawer === false) {
        toggleOpenDrawer();
      }
      return;
    };
    const onMouseLeave = () => {
      if (openDrawer === true) {
        toggleOpenDrawer();
      }
      return;
    };
    const customizedTheme = themes[themeConfig.theme];
    const styling = {
      // backgroundColor: customizedTheme.backgroundColor
      backgroundColor: "white",
      display: noDisplay ? "none" : undefined,
    };

    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width={240}
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo
            schoolLogoUrl={
              firebase && firebase.schoolConfig && firebase.schoolConfig.logoUrl
                ? firebase.schoolConfig.logoUrl
                : smallIllumineLogo
            }
            collapsed={collapsed}
          />

          <Scrollbars style={{ height: height - 70 }}>
            <Menu
              onClick={this.handleClick}
              theme="dark"
              className="isoDashboardMenu"
              mode={mode}
              openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={app.current}
              onOpenChange={this.onOpenChange}
              // defaultOpenKeys={['students']}
              // defaultSelectedKeys={['management']}
            >
              {this.getOptions()}
            </Menu>
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

const a = compose(
  connect(
    (state) => ({
      app: state.App,
      height: state.App.height,
    }),
    { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed }
  ),
  withFirebase
);

export default a(Sidebar);
const styles = {
  sideIcon: {
    height: 20,
    width: 20,
    marginRight: dir === "rtl" ? 0 : 20,
    marginLeft: dir === "rtl" ? 20 : 0,
    objectFit: "contain",
  },
};
