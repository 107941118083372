import actions from "./actions";
const initState = {
    permissionList: [],
    isLoading: false,
    operationType: undefined,
    permissionListChan: undefined,
    permissionRoles: [],
    permissionTypes: [],
    error: false,
    errorMessage: undefined
};

export default function permissionsReducer(
    state = initState,
    { type, ...action }
) {
    switch (type) {
        case actions.GET_PERMISSIONS:
            return {
                ...state,
                isLoading: true,
                operationType: undefined,
                permissionList: [],
                permissionRoles: [],
                permissionTypes: []
            };
        case actions.GET_PERMISSIONS_SUCCESS:
            return {
                ...state,
                permissionList: action.permissionList,
                permissionListChan: action.permissionListChan,
                permissionRoles: action.permissionRoles,
                permissionTypes: action.permissionTypes,
                isLoading: false,
                operationType: action.operationType
            };

        case actions.GET_PERMISSIONS_CALL_COMPLETE:
            return {
                ...state,
                isLoading: false,
            };

        case actions.SAVE_PERMISSIONS:
            return {
                ...state,
                isLoading: true,
            };
        case actions.SAVE_PERMISSIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                operationType: "SAVE"
            };

        case actions.RESET_PERMISSION_OPERATION_TYPE:
            return {
                ...state,
                operationType: undefined,
                errorMessage: undefined
            };

        case actions.PERMISSION_REQUEST_FAIL:
            return {
                ...state,
                error: true,
                errorMessage: action.errorMessage
            };

        default:
            return state;
    }
}