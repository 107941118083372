import app from "firebase/app";
import auth from "firebase/auth";
import ReduxSagaFirebase from "redux-saga-firebase";
import notification from "../../components/notification";
import { Mixpanel } from "../mixpanel/mixpanel";
import { store } from "../../redux/store";
import studentActions from "../../redux/student/actions";
import classroomActions from "../../redux/classroom/actions";
import teachersAction from "../../redux/teacher/actions";
import groupsAction from "../../redux/tag/actions";
import userAction from "../../redux/permissions/actions";
import learningAction from "../../redux/learning/actions";
import studentAssessmentAction from "../../redux/selectedStudentAssessment/actions";
import moment from "moment";
import bugsnagClient from "@bugsnag/js";

import {
  firebaseConfig,
  zoomLeaveUrl,
  zoomMeetingUrl,
  zoomEndPointUrl,
  endPointUrl,
} from "./fbconfig";
import initData from "./../../containers/Topbar/Topbar";
import { registerNotificationWorker } from "./../../serviceWorker";

console.log(
  "config",
  firebaseConfig.projectId,
  process.env.REACT_APP_BUILD_ENV
);

class Firebase {
  constructor() {
    var defaultProject;
    try {
      defaultProject = app.initializeApp(firebaseConfig);
      app.auth().setPersistence("local");
    } catch (error) {
      console.log("failed to init primary firebase", error);
      bugsnagClient.notify(error);
    }

    console.log(defaultProject.name);
    this.isLoggedIn = false;
    this.endPointUrl = endPointUrl;
    this.zoomEndPointUrl = zoomEndPointUrl;
    this.zoomMeetingUrl = zoomMeetingUrl;
    this.zoomLeaveUrl = zoomLeaveUrl;
    this.auth = app.auth();
    this.db = app.database();
    if (app.messaging.isSupported()) {
      const messaging = app.messaging();
      messaging.usePublicVapidKey(
        "BEdE_qhIwOUtxiJu4qXpLZ1g6VeMYselj0Erq3ehiFTb5YNgpbP039atOXDoky0T2mPwGzi96CqbDLJUZetLTic"
      );
    }

    // const appCheck = app.appCheck();
    // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
    // key is the counterpart to the secret key you set in the Firebase console.
    // appCheck.activate("6LfXSc0bAAAAANOI8tzhao2rRWVvGcXcw7RSMxiz", true);

    this.channels = [];
    this.secondaryApp = null;
    this.secondaryDb = null;
    this.secondaryStorage = null;
    this.user = null;
    this.teacher = null;
    this.rsf = null;
    this.schoolConfig = null;
    this.isParent = false;
    this.selectedMode = "";
    this.loggedInEmail = null;
    this.app = defaultProject;

    //selected branch name of selected school, use this for all data operation
    this.sbp = "";
    //use this only when changing logged in user object
    this.branchPath = "";
    this.branchList = null;
    //logged in account school config name
    this.dbName = "";
    //name of school config in master for selected branch
    this.sbDbName = "";
    this.schoolName = "";
    this.isGod = false;
    this.studentsMap = new Map();
    var mainThis = this;
    try {
      if (defaultProject.auth()) {
        if (defaultProject.auth()) {
          this.attachAuthListener(defaultProject);
        } else {
          console.log("current user not defined");
          bugsnagClient.notify(
            new Error("Logged out from master, so triggering signout")
          );
          notification("error", "Session expired(1), please signin again!");
          // this.signOut();
        }
      } else {
        console.log("firebase auth for default app not defined");
        notification("error", "Session expired(2), please signin again!");
        bugsnagClient.notify(
          new Error("Logged out from master, so triggering signout")
        );
        this.signOut();
        //it means master is not inititalized properly
      }

      this.loggedInEmail = localStorage.getItem("loggedInEmail");
      if (!this.loggedInEmail) {
        this.loggedInEmail = localStorage.getItem("phoneNumber");
      }
      var existingUser = localStorage.getItem("user");
      if (existingUser) {
        return this.initUserData();
      } else {
        this.signOut();
        return new Promise(function(resolve, reject) {
          resolve(mainThis);
        });
      }
    } catch (e) {
      console.log("Failed to init", e);
      return this.signOut();
    }
  }

  getUserId() {
    this.loggedInEmail = localStorage.getItem("loggedInEmail");
    if (!this.loggedInEmail) {
      this.loggedInEmail = localStorage.getItem("phoneNumber");
    }
    return this.loggedInEmail;
  }

  initUserData() {
    console.log("called init user data---------------->>>");
    var mainThis = this;
    mainThis.sbp = localStorage.getItem("selectedBranchPath");
    if (!mainThis.sbp) {
      mainThis.sbp = localStorage.getItem("branchPath");
    }
    this.isGod = localStorage.getItem("isGod");
    console.log("Encoded Email or phone number", this.loggedInEmail);
    this.dbName = localStorage.getItem("schoolDBName");
    this.branchPath = localStorage.getItem("branchPath");
    var secondaryConfig = JSON.parse(localStorage.getItem("secondaryConfig"));
    var school = JSON.parse(localStorage.getItem("schoolConfig"));
    this.schoolConfig = school;
    if (this.schoolConfig && this.schoolConfig.deactivate) {
      this.signOut().then((res) => {
        notification("error", "School Account is deactivated or deleted");
        return this;
      });
    }
    this.updateSchoolConfig(this.dbName);
    this.sbp = localStorage.getItem("selectedBranchPath");
    this.sbDbName = localStorage.getItem("selectedDBName");
    this.selectedMode = localStorage.getItem("selectedMode");
    this.schoolName = school.schoolName;
    this.isLoggedIn = true;
    try {
      this.secondaryApp = app(this.dbName);
    } catch (e) {
      console.log("Db was not Initialised ", this.dbName);
      this.secondaryApp = app.initializeApp(secondaryConfig, this.dbName);
    }
    // const appCheckSecondary = this.secondaryApp.appCheck();
    // appCheckSecondary.activate(
    //   '6LfXSc0bAAAAANOI8tzhao2rRWVvGcXcw7RSMxiz',true)
    if (this.secondaryApp.auth().currentUser == null) {
      console.log("Secondary db not logged in ", this.dbName);
      return this.secondaryApp
        .auth()
        .signInWithEmailAndPassword(school.username, school.password)
        .then((res) => {
          return mainThis.fetchFromSecondaryDb();
        });
    } else {
      return mainThis.fetchFromSecondaryDb();
    }
  }

  async fetchFromSecondaryDb() {
    var mainThis = this;
    mainThis.secondaryDb = mainThis.secondaryApp.database();
    mainThis.secondaryStorage = mainThis.secondaryApp.storage();
    mainThis.switchOffChannels();
    mainThis.rsf = new ReduxSagaFirebase(mainThis.secondaryApp);
    mainThis.user = JSON.parse(localStorage.getItem("user"));
    mainThis.teacher = JSON.parse(localStorage.getItem("teacher"));
    mainThis.student = JSON.parse(localStorage.getItem("student"));
    var teacherPromise = new Promise(function(resolve, reject) {
      return mainThis.continueFromUserType(mainThis.user, resolve, reject);
    });
    await teacherPromise;
    console.log("called async fetch user");
    return mainThis;
  }

  signOut() {
    var aThis = this;
    return this.app
      .auth()
      .signOut()
      .then(function() {
        //localStorage.clear();
        localStorage.removeItem("loggedInEmail");
        localStorage.removeItem("loggedInEncodedEmail");
        localStorage.removeItem("user");
        localStorage.removeItem("schoolDBName");
        localStorage.removeItem("selectedDBName");
        localStorage.removeItem("schoolConfig");
        localStorage.removeItem("schoolName");
        localStorage.removeItem("secondaryConfig");
        localStorage.removeItem("selectedBranchPath");
        localStorage.removeItem("selectedMode");
        localStorage.removeItem("student");
        localStorage.removeItem("teacher");
        localStorage.removeItem("studentList");
        localStorage.removeItem("classList");
        localStorage.removeItem("teacherList");
        localStorage.removeItem("groupList");
        localStorage.removeItem("permissionList");
        localStorage.removeItem("permissionRoles");
        localStorage.removeItem("permissionTypes");
        localStorage.removeItem("developmentAreas");
        localStorage.removeItem("milestones");
        localStorage.removeItem("isGod");
        localStorage.removeItem("timezone");
        localStorage.removeItem("branchList");
        localStorage.removeItem("classmap");
        localStorage.removeItem("groupmap");
        localStorage.removeItem("searchedString");
        localStorage.removeItem("unfilteredClassList");

        // history.replace('/signin', null);
        // history.entries = [];
        // history.index = -1;
        // history.push('/signin');
        aThis.user = undefined;
        aThis.teacher = undefined;
        aThis.student = undefined;
        aThis.isParent = false;
        aThis.branchList = null;
        aThis.selectedMode = "";
        aThis.loggedInEmail = null;
        aThis.isGod = false;
        aThis.studentsMap = new Map();
      });
  }

  attachAuthListener(defaultProject) {
    var aThis = this;
    defaultProject.auth().onAuthStateChanged(function(user) {
      if (user) {
        console.log("auth state changed", user);
        // aThis.initUserData();
        // return new Promise(function (resolve, reject) {
        //   resolve();
        // });
      } else {
        // if (aThis.isLoggedIn) {
        //   notification('error', 'Session expired(0), please sigin again!');
        //   aThis.signOut();
        // }
      }
    });
  }

  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }
  replaceAll(str, term, replacement) {
    return str.replace(new RegExp(this.escapeRegExp(term), "g"), replacement);
  }

  isDeletedOrDeactivated() {
    if (!this.isParent) {
      if (this.teacher && (this.teacher.deleted || this.teacher.deactivated)) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        this.student.deleted ||
        this.student.deactivated ||
        (this.student.status &&
          this.student.status.toLowerCase() !== "active" &&
          this.student.status.toLowerCase() !== "trial" &&
          this.student.status.toLowerCase() !== "on hold")
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
  fetchCachedData() {
    return this.getAllStudentsNew()
      .then(this.getAllClassrooms())
      .then(this.getAllTeachers())
      .then(this.getAllGroups())
      .then(this.getPermissions());
    // then(this.getDevelopmentAreas()).
    // then(this.getMilestones());
  }

  updateSchoolConfig(schoolName) {
    var mainThis = this;
    var sChannel = this.db.ref(`schools/` + schoolName);
    sChannel.on("value", function(snapshot1) {
      var school = snapshot1.val();
      if (!school) {
        console.log("School Account Missing");
        mainThis.isLoggedIn = false;
        mainThis.schoolConfig = null;
        localStorage.clear();
        return;
      }
      mainThis.schoolConfig = school;
      //store school config
      localStorage.setItem("schoolConfig", JSON.stringify(school));
    });
    mainThis.channels.push(sChannel);
  }

  doSignInWithEmailAndPassword = (email, password) => {
    var mainThis = this;
    var promise1 = new Promise(function(resolve, reject) {
      mainThis.auth
        .signInWithEmailAndPassword(email, password)
        .then((authUser) => {
          mainThis.initFirebaseConfig(email, resolve, reject);
        })
        .catch(function(error) {
          console.log(error);
          // notification("error", error.message);
          reject(error.message);
          return;
        });
    });
    return promise1;
  };

  doSignInWithEmailLink = (email, link) => {
    var mainThis = this;
    var promise1 = new Promise(function(resolve, reject) {
      mainThis.auth
        .signInWithEmailLink(email, link)
        .then((authUser) => {
          // mainThis.isLoggedIn = true;
          // localStorage.setItem("loggedInEmail", email);
          //store uid
          console.log("Email", email);

          // var credential = mainThis.app.auth.EmailAuthProvider.credentialWithLink(
          //   email, link);

          // mainThis.auth().currentUser.linkWithCredential(credential)
          //   .then(function (usercred) {
          //     console.log("linked successfully", usercred);
          //   })
          //   .catch(function (error) {
          //     console.log("link error", error);
          //   });

          // var encodedEmail = mainThis.replaceAll(email, "@", "%40");
          // encodedEmail = mainThis.replaceAll(encodedEmail, ".", "%2E");
          // console.log("Encoded Email", encodedEmail);
          // localStorage.setItem("loggedInEncodedEmail", encodedEmail);
          mainThis.initFirebaseConfig(email, resolve, reject);
        })
        .catch(function(error) {
          console.log(error);
          // notification("error", error.message);
          reject(error.message);
          return;
        });
    });
    return promise1;
  };

  dosignInWithPhoneNumber = (phoneNumber, appVerifier) => {
    var promise = new Promise(function(resolve, reject) {
      app
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then(function(confirmationResult) {
          console.log("confirmation result in firebase class -->>");
          resolve(confirmationResult);
          return;
        })
        .catch(function(error) {
          console.log("sign in with phone err", error);
          reject(error.message);
        });
    });
    return promise;
  };

  verifyCaptcha = () => {
    return app;
  };

  submitPhoneNumberAuthCode(confirmationResult, verificationCode, phoneNumber) {
    var mainThis = this;
    var promise = new Promise(function(resolve, reject) {
      confirmationResult
        .confirm(verificationCode)
        .then(function(result) {
          //store uid
          localStorage.setItem("phoneNumber", phoneNumber);
          //  mainThis.isLoggedIn = true;
          mainThis.initFirebaseConfig(phoneNumber, resolve, reject);
        })
        .catch(function(error) {
          console.log("verification failure handler", error);
          reject(error.message);
          return;
        });
    });
    return promise;
  }

  sendResetPasswordLink = (email) => {
    var promise = new Promise(function(resolve, reject) {
      app
        .auth()
        .sendPasswordResetEmail(email)
        .then(function(user) {
          console.log("reset password link sennd -->>", user);
          resolve(user);
          return;
        })
        .catch(function(error) {
          console.log("reset password link err", error);
          reject(error.message);
        });
    });
    return promise;
  };

  async setupFirebaseMessaging() {
    if (!app.messaging.isSupported()) {
      return;
    }
    var mainThis = this;
    // Retrieve Firebase Messaging object.
    const messaging = app.messaging();

    const token = await messaging
      .getToken()
      .then((currentToken) => {
        if (currentToken) {
          var user = mainThis.app.auth().currentUser;
          console.log("got the token-----", currentToken, user);

          if (user && user.uid) {
            mainThis.secondaryDb
              .ref()
              .child(mainThis.branchPath + "/webInstaceId")
              .child(user.uid)
              .set(currentToken);
          }
          registerNotificationWorker();
        } else {
          // Show permission request.
          console.log(
            "No Instance ID token available. Request permission to generate one."
          );
        }
        return currentToken;
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        //  showToken('Error retrieving Instance ID token. ', err);
        // setTokenSentToServer(false);
      });
    messaging.onMessage((payload) => {
      console.log("Message received in firebase.js", payload);
    });

    //   messaging.onTokenRefresh(() => {
    //     messaging.getToken().then((refreshedToken) => {
    //       console.log('Token refreshed.');
    //       if (refreshedToken) {
    //         var user = mainThis.app.auth().currentUser;
    //         console.log("got the token-----", refreshedToken, user);

    //         if (user && user.uid) {
    //           mainThis.secondaryDb.ref().child(mainThis.branchPath + '/webInstaceId').child(user.uid).set(refreshedToken);
    //         }
    //     }
    //   }).catch((err) => {
    //       console.log('Unable to retrieve refreshed token ', err);

    //     });

    // });
  }

  initFirebaseConfig(emailOrNumber, resolve, reject) {
    var mainThis = this;
    mainThis.isLoggedIn = true;
    localStorage.setItem("loggedInEmail", emailOrNumber);
    console.log("Email", emailOrNumber);
    mainThis.loggedInEmail = emailOrNumber;
    var encodedEmail = mainThis.replaceAll(emailOrNumber, "@", "%40");
    encodedEmail = mainThis.replaceAll(encodedEmail, ".", "%2E");
    console.log("Encoded Email", encodedEmail);
    localStorage.setItem("loggedInEncodedEmail", encodedEmail);

    return mainThis.db
      .ref(`newUser/` + encodedEmail)
      .once("value")
      .then(function(snapshot) {
        const userObject = snapshot.val();
        if (!userObject) {
          reject("User profile missing");
          return;
        }

        mainThis.user = userObject;
        localStorage.setItem("user", JSON.stringify(userObject));
        //check for godmode
        if (userObject.god) {
          localStorage.setItem("isGod", true);
          mainThis.isGod = true;
          resolve();
          return;
        }

        bugsnagClient.setUser({
          id: mainThis.user.id,
          name: mainThis.user.schoolName,
          email: emailOrNumber,
        });
        mainThis.dbName = userObject.schoolName;
        mainThis.sbDbName = userObject.schoolName;
        //store loggegin user dbname
        localStorage.setItem("schoolDBName", userObject.schoolName);
        //store selectedDBName
        localStorage.setItem("selectedDBName", userObject.schoolName);

        return mainThis.db
          .ref(`schools/` + userObject.schoolName)
          .on("value", function(snapshot1) {
            if (!snapshot1.val()) {
              reject("School Account Missing");
              return mainThis.signOut().then((result) => {
                return new Promise(function(resolve, reject) {
                  notification("error", "School Account is not created");
                  reject("School Account is not created");
                });
              });
            }
            mainThis.schoolConfig = snapshot1.val();
            if (mainThis.schoolConfig.deactivate) {
              return mainThis.signOut().then((result) => {
                return new Promise(function(resolve, reject) {
                  notification(
                    "error",
                    "School Account is deactivated or deleted"
                  );
                  reject("School Account is deactivated or deleted");
                });
              });
            }

            mainThis.branchPath = mainThis.schoolConfig.branchPath;
            localStorage.setItem("branchPath", mainThis.branchPath);
            //store school config
            localStorage.setItem(
              "schoolConfig",
              JSON.stringify(mainThis.schoolConfig)
            );

            //store school name
            localStorage.setItem(
              "schoolName",
              mainThis.schoolConfig.schoolName
            );
            mainThis.schoolName = mainThis.schoolConfig.schoolName;
            if (mainThis.schoolConfig.timezone) {
              localStorage.setItem("timezone", mainThis.schoolConfig.timezone);
            }

            const secondaryConfig = {
              clientId: mainThis.schoolConfig.clientID,
              appId: mainThis.schoolConfig.applicationId,
              apiKey: mainThis.schoolConfig.apiKey,
              databaseURL: mainThis.schoolConfig.databaseUrl,
              storageBucket: mainThis.schoolConfig.storageBucket,
              messagingSenderId: mainThis.schoolConfig.gcmSenderID,
              projectId: mainThis.schoolConfig.projectId,
            };
            localStorage.setItem(
              "secondaryConfig",
              JSON.stringify(secondaryConfig)
            );
            try {
              mainThis.secondaryApp = app.initializeApp(
                secondaryConfig,
                userObject.schoolName
              );
            } catch (e) {
              console.log("Already init done", userObject.schoolName);
              mainThis.secondaryApp = app.app(userObject.schoolName);
            }
            return mainThis.secondaryApp
              .auth()
              .signInWithEmailAndPassword(
                mainThis.schoolConfig.username,
                mainThis.schoolConfig.password
              )
              .then(function() {
                console.log("Secondary db Successful login");
                mainThis.rsf = new ReduxSagaFirebase(mainThis.secondaryApp);
                mainThis.sbp = mainThis.branchPath;
                //store selected branch path
                localStorage.setItem("selectedBranchPath", mainThis.sbp);
                mainThis.secondaryDb = mainThis.secondaryApp.database();
                mainThis.secondaryStorage = mainThis.secondaryApp.storage();

                mainThis.continueFromUserType(userObject, resolve, reject);
              })
              .catch(function(error) {
                console.log("Login error" + error);
                notification("error", error.message);
                reject(error.message);
              });
          });
      });
  }

  continueFromUserType(userObject, resolve, reject) {
    var mainThis = this;
    mainThis.setupFirebaseMessaging();
    bugsnagClient.setUser({
      id: mainThis.loggedInEmail,
      name: mainThis.schoolName,
      email: mainThis.loggedInEmail,
      type: userObject.userType,
    });
    if (userObject.studentId && userObject.userType != "TEACHER") {
      mainThis.isParent = true;
      mainThis.selectedMode = "parent";
      localStorage.setItem("selectedMode", mainThis.selectedMode);
      return mainThis.fetchParent(
        mainThis.secondaryDb,
        userObject,
        resolve,
        reject
      );
    } else {
      mainThis.isParent = false;
      mainThis.selectedMode = "teacher";
      localStorage.setItem("selectedMode", mainThis.selectedMode);
      if (userObject.studentId) {
        mainThis.fetchParent(mainThis.secondaryDb, userObject, null, reject);
      }
      let timezone = localStorage.getItem("timezone");
      if (timezone) {
        moment.tz.setDefault(timezone);
      }
      return mainThis.fetchTeacher(
        mainThis.secondaryDb,
        userObject,
        resolve,
        reject
      );
    }
  }

  fetchTeacher(secondaryDb, userObject, resolve, reject) {
    var mainThis = this;
    return secondaryDb
      .ref(mainThis.branchPath + "/teachers/" + userObject.id)
      .on("value", function(teacherSnapshot) {
        var teacher = teacherSnapshot.val();
        if (!teacher) {
          if (!mainThis.isGod) {
            mainThis.signOut();
          }
          if (reject) reject("Teacher Profile Missing");
          return;
        }
        mainThis.teacher = teacher;
        console.log("called teacher fetch ", teacher);
        localStorage.setItem("teacher", JSON.stringify(teacher));
        if (mainThis.isDeletedOrDeactivated()) {
          if (!mainThis.isGod) {
            mainThis.signOut();
          }
          if (reject) {
            reject("Account is deactivated or deleted");
          }
        }
        console.log("branchList", teacher.newBranches);

        let classPromise;
        if (teacher.newBranches) {
          localStorage.setItem(
            "branchList",
            JSON.stringify(teacher.newBranches)
          );
          mainThis.branchList = teacher.newBranches;
          mainThis.getAllStudentsNew();

          classPromise = mainThis.getAllClassrooms();

          mainThis.getAllTeachers();
          mainThis.getAllGroups();
          mainThis.getPermissions();
          mainThis.getAllCenterClassrooms(teacher.newBranches);
          mainThis.getAllCenterGroups(teacher.newBranches);
        } else {
          mainThis.getAllStudentsNew();

          classPromise = mainThis.getAllClassrooms();

          mainThis.getAllTeachers();
          mainThis.getAllGroups();
          mainThis.getPermissions();
        }
        classPromise.then((c) => {
          if (resolve) {
            resolve(teacher);
          } else {
            return teacher;
          }
        });
      });
  }

  async fetchStudent(secondaryDb, branchPath, studentId) {
    return new Promise((resole, reject) => {
      secondaryDb
        .ref(branchPath + "/students/" + studentId)
        .on("value", function(studentSnapshot) {
          var student = studentSnapshot.val();
          resole(student);
        });
    });
  }

  async fetchParent(secondaryDb, userObject, resolve, reject) {
    var mainThis = this;
    if (userObject.childProfiles) {
      var isStudentExist = false;
      for (var i in userObject.childProfiles) {
        var userNode = userObject.childProfiles[i];
        var student = await mainThis.fetchStudent(
          secondaryDb,
          mainThis.branchPath,
          userNode.studentId
        );

        if (!student) {
          if (!mainThis.isGod) {
            mainThis.signOut();
          }
          if (reject) reject("Student Profile Missing");
          return;
        }
        mainThis.student = student;
        localStorage.setItem("student", JSON.stringify(student));
        if (!mainThis.isDeletedOrDeactivated()) {
          isStudentExist = true;
          mainThis.getAllClassrooms();
          mainThis.getAllGroups();
          mainThis.getPermissions();
          if (resolve) resolve(student);
          break;
        }
      }
      if (!isStudentExist) {
        if (!mainThis.isGod) {
          mainThis.signOut();
        }
        if (reject) reject("All Students are deleted or deactivated");
        return;
      }
    } else {
      return secondaryDb
        .ref(mainThis.branchPath + "/students/" + userObject.studentId)
        .on("value", function(studentSnapshot) {
          var student = studentSnapshot.val();
          if (!student) {
            if (!mainThis.isGod) {
              mainThis.signOut();
            }
            if (reject) reject("Student Profile Missing");
            return;
          }
          mainThis.student = student;
          localStorage.setItem("student", JSON.stringify(student));
          if (mainThis.isDeletedOrDeactivated()) {
            if (userObject.child2Id) {
              // fetch student 2
              // if chld 2 is deleted or deactivated fetch child 3
              return secondaryDb
                .ref(
                  userObject.child2Branch + "/students/" + userObject.child2Id
                )
                .on("value", function(studentSnapshot) {
                  var child2 = studentSnapshot.val();
                  if (!child2) {
                    if (!mainThis.isGod) {
                      mainThis.signOut();
                    }
                    if (reject) reject("Student Profile Missing");
                    return;
                  }
                  mainThis.student = child2;
                  localStorage.setItem("student", JSON.stringify(child2));

                  if (child2.deactivated || child2.deleted) {
                    if (userObject.child3Id) {
                      // fetch student 2
                      // if chld 2 is deleted or deactivated fetch child 3
                      return secondaryDb
                        .ref(
                          userObject.child3Branch +
                            "/students/" +
                            userObject.child3Id
                        )
                        .on("value", function(studentSnapshot) {
                          var child3 = studentSnapshot.val();
                          if (!child3) {
                            if (!mainThis.isGod) {
                              mainThis.signOut();
                            }
                            if (reject) reject("Student Profile Missing");
                            return;
                          }
                          mainThis.student = child3;
                          localStorage.setItem(
                            "student",
                            JSON.stringify(child3)
                          );

                          if (child3.deactivated || child3.deleted) {
                            if (!mainThis.isGod) {
                              mainThis.signOut();
                            }
                            if (reject) {
                              reject("Account is deactivated or deleted");
                            }
                            return;
                          } else {
                            mainThis.getAllClassrooms();
                            mainThis.getAllGroups();
                            mainThis.getPermissions();
                            if (resolve) {
                              resolve(student);
                            }
                            return;
                          }
                        });
                    }
                  } else {
                    mainThis.getAllClassrooms();
                    mainThis.getAllGroups();
                    mainThis.getPermissions();
                    if (resolve) {
                      resolve(student);
                    }
                    return;
                  }
                });
            } else {
              if (!mainThis.isGod) {
                mainThis.signOut();
              }
              if (reject) {
                reject("Account is deactivated or deleted");
              }
            }
          }
          mainThis.getAllClassrooms();
          mainThis.getAllGroups();
          mainThis.getPermissions();
          if (resolve) resolve(student);
        });
    }
  }

  closeMilestoneAndDevAreaChannel() {
    this.secondaryApp
      .database()
      .ref(this.sbp + "/curriculum-milestone")
      .off();
    this.secondaryApp
      .database()
      .ref(this.sbp + "/developmentAreas")
      .off();
  }

  async switchBranch(branchName) {
    //here branch name is branchPath
    if (branchName && branchName.length > 0) {
      localStorage.setItem("studentList", JSON.stringify([]));
      localStorage.setItem("teacherList", JSON.stringify([]));
      localStorage.setItem("classList", JSON.stringify([]));
      localStorage.setItem("groupList", JSON.stringify([]));

      this.switchOffChannels();
      this.studentsMap.clear();

      this.sbp = branchName;

      localStorage.setItem("selectedBranchPath", this.sbp);
      var a = this.branchList.filter((element) => {
        return element.name == branchName;
      });

      this.sbDbName = a[0].dbName;
      localStorage.setItem("selectedDBName", this.sbDbName);

      let studentPromise = this.getAllStudentsNew();
      let teacherPromise = this.getAllTeachers();
      let classPromise = this.getAllClassrooms();
      let groupPromise = this.getAllGroups();
      let schoolConfigPromise = this.getSchoolConfig(this.sbDbName);
      // let getDevelopmentAreasPromise = this.getDevelopmentAreas();
      // let milestonesPromise = this.getMilestones();
      let permissionPromise = this.getPermissions("reload");

      return Promise.all([
        studentPromise,
        teacherPromise,
        classPromise,
        groupPromise,
        schoolConfigPromise,
        permissionPromise,
      ]);
    }
  }

  switchChildBranch(branchPath) {
    //here branch name is branchPath
    this.sbp = branchPath;
    localStorage.setItem("selectedBranchPath", this.sbp);
  }

  switchMode(mode) {
    //called when entering or existing parent mode
    this.isParent = !this.isParent;
    this.selectedMode = mode;
    localStorage.setItem("selectedMode", this.selectedMode);
    this.sbp = this.schoolConfig.branchPath;

    localStorage.setItem("selectedBranchPath", this.sbp);

    this.sbDbName = this.user.schoolName;
    localStorage.setItem("selectedDBName", this.sbDbName);
  }

  getAllCenterClassrooms(branchList) {
    var mainThis = this;
    var classmap = {};
    var promiseList = [];
    branchList.forEach((branch) => {
      var p = new Promise(function(resolve, reject) {
        return mainThis.secondaryDb
          .ref(branch.name + "/classrooms")
          .once("value")
          .then((snapshot) => {
            if (snapshot.val()) {
              var classroomList = [];
              snapshot.forEach((classSnap) => {
                var classObj = classSnap.val();
                classroomList.push(classObj);
              });
              classmap[branch.name] = classroomList;
            }
            resolve();
          });
      });
      promiseList.push(p);
    });
    Promise.all(promiseList)
      .then((a) => {
        localStorage.setItem("classmap", JSON.stringify(classmap));
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  getAllCenterGroups(branchList) {
    var mainThis = this;
    var groupmap = {};
    var promiseList = [];
    branchList.forEach((branch) => {
      var p = new Promise(function(resolve, reject) {
        return mainThis.secondaryDb
          .ref(branch.name + "/tags")
          .once("value")
          .then((snapshot) => {
            if (snapshot.val()) {
              var groupList = [];
              snapshot.forEach((groupSnap) => {
                var groupObj = groupSnap.val();
                groupObj.className = groupSnap.val().name;
                groupList.push(groupObj);
              });
              groupmap[branch.name] = groupList;
            }
            resolve();
          });
      });
      promiseList.push(p);
    });
    Promise.all(promiseList)
      .then((a) => {
        localStorage.setItem("groupmap", JSON.stringify(groupmap));
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  switchOffChannels() {
    try {
      if (this.channels) {
        this.channels.forEach((ch) => {
          ch.off();
        });
      }

      if (this.secondaryDb) {
        this.secondaryDb.ref(this.sbp + "/teachers").off();
        this.secondaryDb.ref(this.sbp + "/students").off();
        this.secondaryDb.ref(this.sbp + "/classrooms").off();
        this.secondaryDb.ref(this.sbp + "/tags").off();
        this.secondaryDb.ref(this.sbp + "/permissionList").off();
        this.secondaryDb.ref(this.sbp + "/curriculum-milestone").off();
        this.secondaryDb.ref(this.sbp + "/developmentAreas").off();
      }
    } catch (err) {
      console.log("no channels to close");
    }
  }

  getAllStudents() {
    store.dispatch({
      type: studentActions.START_STUDENT_LOADER,
    });

    var mainThis = this;
    //once, child_added,child_remvoved,child_changed
    let p1 = new Promise(function(resolve, reject) {
      var chanStu = mainThis.secondaryDb
        .ref(mainThis.sbp + "/students")
        .orderByChild("deleted")
        .equalTo(false)
        .on("value", (snapshot) => {
          if (snapshot.val()) {
            let students = [];
            snapshot.forEach((snap) => {
              if (snap.val() !== null && !snap.val().deleted && snap.val().id) {
                if (mainThis.teacher && mainThis.teacher.classList) {
                  if (
                    (mainThis.teacher.superUser !== undefined &&
                      mainThis.teacher.superUser === true) ||
                    mainThis.teacher.newBranches
                  ) {
                    students.push(snap.val());
                  } else {
                    let classList = mainThis.teacher.classList;
                    for (let i in classList) {
                      if (
                        snap.val().classList &&
                        snap.val().classList.includes(classList[i])
                      ) {
                        students.push(snap.val());
                        break;
                      }
                    }
                  }
                } else {
                  students.push(snap.val());
                }
              }
            });
            // console.log("student list inside firebase.js", students);
            students = students.sort((a, b) => a.name.localeCompare(b.name));
            localStorage.setItem("studentList", JSON.stringify(students));

            //mainThis.checkEmailLogIn(students);
            store.dispatch({
              type: studentActions.LIST_STUDENT_SUCCESSFUL,
              students: students,
            });
            resolve(students);
          } else {
            let emptyStudentArr = [];
            localStorage.setItem(
              "studentList",
              JSON.stringify(emptyStudentArr)
            );
            store.dispatch({
              type: studentActions.LIST_STUDENT_SUCCESSFUL,
              students: emptyStudentArr,
            });
          }
        });
    });
    return p1;
  }

  checkEmailLogIn(students) {
    if (this.schoolName == "Ready Education Group") {
      var counter = 0;
      students.forEach((stu) => {
        if (!stu.motherEmail) {
          return;
        }
        var username = stu.motherEmail.split("@")[0];
        var password = username.substr(0, 8);
        if (password.length < 8) {
          password = password + "123456";
        }

        this.auth
          .createUserWithEmailAndPassword(stu.motherEmail, password)
          .then(function(authUser) {
            console.log("added", authUser);
          })
          .catch(function(error) {
            counter++;
            console.log("error", error, counter);
          });
      });
    }
  }

  getAllTeachers() {
    store.dispatch({
      type: teachersAction.START_TEACHER_LOADER,
    });

    var mainThis = this;
    let p1 = new Promise(function(resolve, reject) {
      var chanT = mainThis.secondaryDb
        .ref(mainThis.sbp + "/teachers")
        .orderByChild("deleted")
        .equalTo(false);

      chanT.on("value", (snapshot) => {
        // console.log("teacher snap", snapshot.val());
        let teachers = [];
        snapshot.forEach((snap) => {
          if (snap.val() !== null && !snap.val().deleted && snap.val().id) {
            if (mainThis.teacher && mainThis.teacher.classList) {
              if (
                (mainThis.teacher.superUser !== undefined &&
                  mainThis.teacher.superUser === true) ||
                mainThis.teacher.newBranches
              ) {
                teachers.push(snap.val());
              } else {
                let classList = mainThis.teacher.classList;
                for (let i in classList) {
                  if (
                    snap.val().classList &&
                    snap.val().classList.includes(classList[i])
                  ) {
                    teachers.push(snap.val());
                    break;
                  }
                }
              }
            } else {
              teachers.push(snap.val());
            }
          }
        });
        localStorage.setItem("teacherList", JSON.stringify(teachers));
        store.dispatch({
          type: teachersAction.LIST_TEACHER_SUCCESSFUL,
          teachers: teachers,
        });
        resolve(teachers);
      });
      mainThis.channels.push(chanT);
    });
    return p1;
  }

  getAllClassrooms() {
    store.dispatch({
      type: classroomActions.START_CLASS_LOADER,
    });

    var mainThis = this;
    let p1 = new Promise(function(resolve, reject) {
      var chanC = mainThis.secondaryDb.ref(mainThis.sbp + "/classrooms");
      chanC.on("value", (snapshot) => {
        let classrooms = [];
        let unfilteredClassrooms = [];
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            //maintain unfiltered classroom list for holiday
            unfilteredClassrooms.push(snap.val());

            if (mainThis.teacher && mainThis.teacher.classList) {
              if (
                (mainThis.teacher.superUser !== undefined &&
                  mainThis.teacher.superUser === true) ||
                mainThis.teacher.newBranches
              ) {
                classrooms.push(snap.val());
              } else {
                let classList = mainThis.teacher.classList;
                if (classList.includes(snap.val().className)) {
                  classrooms.push(snap.val());
                }
              }
            } else {
              classrooms.push(snap.val());
            }
          }
        });

        console.log("mainThis teacher -------", mainThis.teacher);
        if (
          (mainThis.teacher &&
            mainThis.teacher.superUser !== undefined &&
            mainThis.teacher.superUser === true) ||
          (mainThis.teacher && mainThis.teacher.newBranches)
        ) {
          mainThis.updateTeacherClassList(classrooms);
        }

        localStorage.setItem("classList", JSON.stringify(classrooms));
        localStorage.setItem(
          "unfilteredClassList",
          JSON.stringify(unfilteredClassrooms)
        );
        store.dispatch({
          type: classroomActions.LIST_CLASSROOM,
          classrooms: classrooms,
        });
        resolve(classrooms);
      });
      mainThis.channels.push(chanC);
    });
    return p1;
  }

  updateTeacherClassList(classrooms) {
    var mainThis = this;

    let newClassList = [];
    classrooms.map((c) => {
      newClassList.push(c.className);
    });

    let newTeacher = mainThis.teacher;

    newTeacher.classList = newClassList;
    localStorage.setItem("teacher", JSON.stringify(newTeacher));
  }

  getAllGroups() {
    store.dispatch({
      type: groupsAction.START_TAG_LOADER,
    });

    var mainThis = this;
    let p1 = new Promise(function(resolve, reject) {
      var chanG = mainThis.secondaryDb.ref(mainThis.sbp + "/tags");
      chanG.on("value", (snapshot) => {
        let tags = [];
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            tags.push(snap.val());
          }
        });
        localStorage.setItem("groupList", JSON.stringify(tags));
        store.dispatch({
          type: groupsAction.LIST_TAGS_SUCCESSFUL,
          tags: tags,
        });
        resolve(tags);
      });
      mainThis.channels.push(chanG);
    });
    return p1;
  }

  getSchoolConfig(dbName) {
    var mainThis = this;
    let p1 = new Promise(function(resolve, reject) {
      mainThis.db.ref(`schools/` + dbName).on("value", function(snapshot1) {
        let school = snapshot1.val();
        if (school) {
          mainThis.schoolConfig = school;
          mainThis.schoolName = school.schoolName;
          localStorage.setItem("schoolConfig", JSON.stringify(school));
          if (school.timezone)
            localStorage.setItem("timezone", school.timezone);
        }
        resolve(school);
      });
    });
    return p1;
  }

  getDevelopmentAreas() {
    var mainThis = this;
    let p1 = new Promise(function(resolve, reject) {
      var chanD = mainThis.secondaryDb.ref(mainThis.sbp + "/developmentAreas");
      chanD.on("value", (snapshot) => {
        let devAreas = [];
        let developmentAreasMap = new Map();
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            devAreas.push(snap.val());
            developmentAreasMap.set(snap.val().id, snap.val());
          }
        });

        localStorage.setItem("developmentAreas", JSON.stringify(devAreas));
        store.dispatch({
          type: learningAction.LIST_DEV_AREA_SUCCESS,
          developmentAreas: devAreas,
          developmentAreasMap: developmentAreasMap,
        });
        resolve(devAreas);
      });
      mainThis.channels.push(chanD);
    });
    return p1;
  }

  getMilestones() {
    var mainThis = this;
    let p1 = new Promise(function(resolve, reject) {
      var chanM = mainThis.secondaryDb.ref(
        mainThis.sbp + "/curriculum-milestone"
      );
      chanM.on("value", (snapshot) => {
        let milestonesMap = new Map();
        snapshot.forEach((snap) => {
          if (snap.val() !== null && !snap.val().deleted) {
            milestonesMap.set(snap.val().id, snap.val());
          }
        });

        localStorage.setItem(
          "milestones",
          JSON.stringify(Array.from(milestonesMap))
        );
        store.dispatch({
          type: learningAction.LIST_MILESTONES_SUCCESSFUL,
          milestones: milestonesMap,
        });

        store.dispatch({
          type: studentAssessmentAction.LIST_ASSESSMENT_MILESTONES_SUCCESSFUL,
          assessmentMilestones: milestonesMap,
        });

        resolve(milestonesMap);
      });
      mainThis.channels.push(chanM);
    });
    return p1;
  }

  getPermissions(reload) {
    var mainThis = this;
    let p1 = new Promise(function(resolve, reject) {
      var chanP = mainThis.secondaryDb.ref(mainThis.sbp + "/permissionList");
      chanP.on("value", (snapshot) => {
        let permissions = new Map();
        let roles = [];
        let permissionTypes = [];
        let disabledList = [];

        snapshot.forEach((snp) => {
          if (snp.val() !== null && snp.val().type) {
            if (snp.val().disabled && !disabledList.includes(snp.val().type)) {
              disabledList.push(snp.val().type);
            }
          }
        });

        snapshot.forEach((snap) => {
          if (snap.val() !== null && snap.val().type) {
            if (!disabledList.includes(snap.val().type)) {
              if (snap.val().roles) {
                roles = [...roles, ...snap.val().roles];
              }
              permissionTypes = [...permissionTypes, snap.val().type];

              let key;
              if (snap.val().permissionName) {
                key = snap.val().type + " " + snap.val().permissionName;
              } else {
                key = snap.val().type;
              }
              let pValue = snap.val();
              permissions.set(key, pValue);
            }
          }
        });

        store.dispatch({
          type: userAction.GET_PERMISSIONS_SUCCESS,
          permissionList: permissions,
          permissionListChan: undefined,
          permissionRoles: [...new Set(roles)],
          permissionTypes: [...new Set(permissionTypes)],
          operationType: reload ? "REFRESH" : undefined,
        });

        resolve(permissions);
        // console.log("firebase permissions -----", permissions);
        localStorage.setItem(
          "permissionList",
          JSON.stringify(Array.from(permissions))
        );
        localStorage.setItem(
          "permissionRoles",
          JSON.stringify([...new Set(roles)])
        );
        localStorage.setItem(
          "permissionTypes",
          JSON.stringify([...new Set(permissionTypes)])
        );
      });
      mainThis.channels.push(chanP);
    });
    return p1;
  }

  // getNewUser(students) {
  //   var mainThis = this;
  //   let counter = 0;
  //   students.forEach(std => {
  //     // if (std.fatherEmail) {

  //     //   mainThis.db.ref('newUser/' + encodedEmail).once("value").then(function (snapshot) {
  //     //     if (snapshot.val() && std.fatherProfileId && snapshot.val().id !== std.fatherProfileId) {
  //     //       console.log("found" + std.name + ' -> ' + std.fatherEmail);
  //     //       // mainThis.db.ref('newUser/' + encodedEmail).child('id').set(std.fatherProfileId);
  //     //     } else if (snapshot.val() && !std.fatherProfileId) {
  //     //       console.log("found else case" + std.name + ' -> ' + std.fatherEmail);
  //     //     }
  //     //   })
  //     // };

  //     if (std.motherEmail) {
  //       let email = std.motherEmail;
  //       var encodedEmail = mainThis.replaceAll(email, "@", "%40");
  //       encodedEmail = mainThis.replaceAll(encodedEmail, ".", "%2E");

  //       mainThis.db.ref('newUser/' + encodedEmail).once("value").then(function (snapshot) {

  //         if (snapshot.val() && std.motherProfileId && snapshot.val().id !== std.motherProfileId) {

  //           // console.log("snapshot", snapshot.val());
  //           console.log("found" + std.name + ' -> ' + std.motherEmail + " id ->" + std.motherProfileId);
  //           // mainThis.db.ref('newUser/' + encodedEmail).child('id').set(std.motherProfileId);

  //           // mainThis.secondaryDb.ref(mainThis.sbp + "/students")
  //           //   .child(std.id)
  //           //   .child("motherProfileId")
  //           //   .set(std.motherProfileId);

  //           // if (snapshot.val().child2Id) {
  //           //   mainThis.secondaryDb.ref(mainThis.sbp + "/students")
  //           //     .child(snapshot.val().child2Id)
  //           //     .child("motherProfileId")
  //           //     .set(std.motherProfileId);
  //           // }

  //         } else if (snapshot.val() && !std.motherProfileId) {
  //           console.log("found else" + std.name + ' -> ' + std.motherEmail);
  //         }
  //       }).catch(err => {
  //         console.log("student catch", std);
  //       })
  //     }
  //   });

  //   console.log("counter val", counter);
  // }
  getAllStudentsNew() {
    store.dispatch({
      type: studentActions.START_STUDENT_LOADER,
    });

    var mainThis = this;
    var chanStu = mainThis.secondaryDb
      .ref(mainThis.sbp + "/students")
      .orderByChild("deleted")
      .equalTo(false);

    let p1 = new Promise(function(resolve, reject) {
      mainThis.refreshStudents(resolve, reject);
    });

    var c2 = chanStu.on("child_changed", (data) => {
      if (data.val()) {
        console.log("child_changed", data.val());
        mainThis.studentsMap.set(data.val().id, data.val());
        mainThis.convertStudentMapToList(mainThis.studentsMap);
      }
    });

    var c3 = chanStu.on("child_removed", (data) => {
      if (data.val()) {
        mainThis.studentsMap.delete(data.val().id);
        mainThis.convertStudentMapToList(mainThis.studentsMap);
      }
    });

    // var c3 = chanStu.on("child_added", (data) => {
    //   if (data.val()) {
    //     console.log("child_added", data.val());
    //     mainThis.studentsMap.set(data.val().id, data.val());
    //     mainThis.convertStudentMapToList(mainThis.studentsMap);
    //   }
    // });

    mainThis.channels.push(chanStu);
    var chStu = mainThis.secondaryDb
      .ref(mainThis.sbp + "/lastUpdate")
      .on("value", function(snapshot1) {
        if (snapshot1.val()) {
          mainThis.refreshStudents();
        }
      });
    mainThis.channels.push(chStu);
    return p1;
  }

  convertStudentMapToList(studentsMap) {
    var studentList = [];
    studentsMap.forEach(function(value, key) {
      studentList.push(value);
    });

    localStorage.setItem("studentList", JSON.stringify(studentList));
    store.dispatch({
      type: studentActions.LIST_STUDENT_SUCCESSFUL,
      students: studentList,
    });

    return studentList;
  }

  refreshStudents(resolve, reject) {
    var mainThis = this;
    mainThis.secondaryDb
      .ref(mainThis.sbp + "/students")
      .orderByChild("deleted")
      .equalTo(false)
      .once("value", (snapshot1) => {
        if (snapshot1.val()) {
          let students = [];
          snapshot1.forEach((snap) => {
            if (snap.val() !== null && snap.val().id) {
              /**update classList */
              if (snap.val().classroomName && !snap.val().classList) {
                mainThis.secondaryDb
                  .ref(mainThis.sbp + "/students/" + snap.val().id)
                  .update({ classList: [snap.val().classroomName] });
              }
              //--------------
              if (mainThis.teacher && mainThis.teacher.classList) {
                if (
                  (mainThis.teacher.superUser !== undefined &&
                    mainThis.teacher.superUser === true) ||
                  mainThis.teacher.newBranches
                ) {
                  mainThis.studentsMap.set(snap.val().id, snap.val());
                  students.push(snap.val());
                } else {
                  let classList = mainThis.teacher.classList;

                  for (let i in classList) {
                    if (
                      snap.val().classList &&
                      snap.val().classList.includes(classList[i])
                    ) {
                      if (
                        mainThis.checkIfStudentInGroup(
                          mainThis.teacher,
                          snap.val()
                        )
                      ) {
                        students.push(snap.val());
                        mainThis.studentsMap.set(snap.val().id, snap.val());
                      }
                      break;
                    }
                  }
                }
              } else {
                //check for groups
                if (
                  mainThis.checkIfStudentInGroup(mainThis.teacher, snap.val())
                ) {
                  students.push(snap.val());
                  mainThis.studentsMap.set(snap.val().id, snap.val());
                }
              }
            }
          });
          // console.log("student list inside firebase.js", students);
          students = students.sort((a, b) => a.name.localeCompare(b.name));
          localStorage.setItem("studentList", JSON.stringify(students));

          store.dispatch({
            type: studentActions.LIST_STUDENT_SUCCESSFUL,
            students: students,
          });
          if (resolve) resolve(students);
        } else {
          let emptyStudentArr = [];
          localStorage.setItem("studentList", JSON.stringify(emptyStudentArr));
          store.dispatch({
            type: studentActions.LIST_STUDENT_SUCCESSFUL,
            students: emptyStudentArr,
          });
          if (resolve) resolve(emptyStudentArr);
        }
      });
  }

  checkIfStudentInGroup(teacher, student) {
    if (teacher.superUser || teacher.newBranches) {
      return true;
    } else {
      if (teacher.groups) {
        let teacherGroup = teacher.groups;
        let studentGroup = student.tags ? student.tags : undefined;
        let isPresent = false;
        if (studentGroup) {
          for (let index in studentGroup) {
            let grp = studentGroup[index];
            if (teacherGroup.includes(grp.name)) {
              isPresent = true;
              break;
            }
          }
        } else {
          isPresent = true;
        }
        return isPresent;
      } else {
        return true;
      }
    }
  }
}

export default Firebase;
