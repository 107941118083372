import "@firebase/firestore"; // 👈 If you're using firestore
import { eventChannel } from "redux-saga";
import moment from "moment-timezone";
import notification from "../components/notification";
const superagent = require("superagent");

function createTeacherNode(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/teachers").push().key;
  return key;
}

function getAllTeachers(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/teachers");
  return eventChannel((emitter) => {
    myRef.on("value", (snap) => {
      const teacherData = [];
      snap.forEach((element) => {
        if (element.val() !== null) {
          teacherData.push(element.val());
        }
      });
      emitter(teacherData);
    });
    return () => {
      rsf.ref(branchPath + "/teachers").off();
      console.log("unsubscribe");
    };
  });
}

function getClassroomsForTeacher(firebase, bPath) {
  let classroomData = [];
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  const myRef = rsf.ref(branchPath + "/classrooms");
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((element) => {
        if (element.val() !== null) {
          classroomData.push(element.val());
        }
      });
      resolve(classroomData);
    });
  });
  return promise1;
}

function addNewTeacher(
  name,
  gender,
  classroom,
  contact,
  address,
  email,
  role,
  firebase,
  nodeId,
  countryCode,
  newBranches
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/teachers/" + nodeId).set({
    name: name.charAt(0).toUpperCase() + name.slice(1),
    gender: gender,
    className: classroom[0],
    classList: classroom,
    phoneNumber: Number(contact),
    role: role,
    address: address ? address : null,
    email: email ? email : null,
    adminFlag: false,
    approvalFlag: false,
    attendancePermission: false,
    chatPermission: false,
    deactivated: false,
    deleted: false,
    financeControl: false,
    id: nodeId,
    notification: false,
    staffAttendance: false,
    studentAttendance: false,
    platform: "web",
    code: countryCode ? countryCode : null,
    newBranches: newBranches && newBranches.length > 0 ? newBranches : null,
  });
}

function updateExistingTeacher(
  name,
  gender,
  classroom,
  contact,
  address,
  email,
  role,
  record,
  firebase,
  countryCode,
  newBranches
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/teachers/" + record.id).update({
    name: name.charAt(0).toUpperCase() + name.slice(1),
    gender: gender,
    className: classroom[0],
    classList: classroom,
    phoneNumber: Number(contact),
    role: role,
    address: address ? address : null,
    email: email ? email : null,
    platform: "web",
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
    code: countryCode ? countryCode : null,
    newBranches: newBranches && newBranches.length > 0 ? newBranches : null,
  });
}

function deleteTeacher(teacherRecord, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/teachers/" + teacherRecord.id).update({
    deleted: true,
    platform: "web",
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
  });
}

function addNewTeacherCsv(
  name,
  gender,
  classroom,
  contact,
  address,
  email,
  role,
  approvalRequired,
  staffAttendancePermission,
  financePermission,
  studentAttendance,
  userManagementPermission,
  messagePermission,
  parentQueryNotification,
  firebase,
  nodeId,
  classNames
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/teachers/" + nodeId).set({
    name: name.charAt(0).toUpperCase() + name.slice(1),
    gender: gender.charAt(0).toUpperCase() + gender.slice(1),
    classList: classNames,
    className: classNames[0],
    phoneNumber: Number(contact),
    address: address ? address : null,
    email: email ? email : null,
    adminFlag: userManagementPermission === "TRUE" ? true : false,
    approvalFlag: approvalRequired === "TRUE" ? true : false,
    attendancePermission: false,
    chatPermission: messagePermission === "TRUE" ? true : false,
    deactivated: false,
    deleted: false,
    financeControl: financePermission === "TRUE" ? true : false,
    id: nodeId,
    role: role.charAt(0).toUpperCase() + role.slice(1),
    notification: parentQueryNotification === "TRUE" ? true : false,
    staffAttendance: staffAttendancePermission === "TRUE" ? true : false,
    studentAttendance: studentAttendance === "TRUE" ? true : false,
    source: "onboarding_web",
  });
}

function createEmailLogin(email, password, firebase) {
  var p1 = new Promise(function(resolve, reject) {
    firebase.auth
      .createUserWithEmailAndPassword(email, password)
      .then(function(authUser) {
        resolve(authUser);
      })
      .catch(function(error) {
        console.log("error", error);
        reject(error);
      });
  });
  return p1;
}

function addTeacherEmailToNewUser(
  encodedEmail,
  teacherNodeId,
  contact,
  userType,
  firebase
) {
  // console.log("ffirebase -------", firebase);
  const rsf = firebase.db;
  rsf.ref("/newUser/" + encodedEmail).set(
    {
      id: teacherNodeId,
      phoneNumber: Number(contact),
      schoolName: firebase.sbDbName,
      userType: userType,
      platform: "web",
    },
    (error) => {
      if (error) {
        notification("error", "Session expired. Please re-login");
        firebase.signOut();
      } else {
        // Data saved successfully!
      }
    }
  );
}

function getTeacherDetail(teacherId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emitter) => {
    rsf.ref(branchPath + "/teachers/" + teacherId).on("value", (snap) => {
      const teacherData = [];
      if (snap.val() !== null) {
        teacherData.push(snap.val());
      }
      emitter(teacherData);
    });
    return () => {
      rsf.ref(branchPath + "/teachers/" + teacherId).off();
      console.log("END");
    };
  });
}

function changeStaffPermission(category, status, teacherId, firebase) {
  var update = {
    [category]: status === "true" ? true : false,
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
  };
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/teachers/" + teacherId).update(update);
}

function changeStaffRole(role, teacherId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/teachers/" + teacherId).update({
    role: role,
    platform: "web",
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
  });
}

function checkIfUserExistInNewUser(number, firebase) {
  const rsf = firebase.db;
  let data = {};
  var promiseIfPresent = new Promise(function(resolve, reject) {
    rsf
      .ref("/newUser/" + number)
      .once("value")
      .then(function(snap) {
        // console.log("new user snap val", snap.val());
        if (snap.val() !== null) {
          data = snap.val();
          data.status = true;
        } else {
          data.status = false;
        }
        resolve(data);
      });
  });
  return promiseIfPresent;
}

function deleteUserFromNewUser(encodedEmail, firebase) {
  const rsf = firebase.db;
  rsf.ref("/newUser/" + encodedEmail).set(null);
}

function getTeacherById(teacherId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var teacher = {};
  var teacherPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/teachers/" + teacherId)
      .once("value")
      .then(function(snapshot) {
        if (snapshot.val() !== null) {
          teacher = snapshot.val();
        }
        resolve(teacher);
      });
  });
  return teacherPromise;
}

function getAllStaffByCenter(bPath, firebase) {
  let teacher = [];
  const rsf = firebase.secondaryDb;
  let branchPath = bPath;
  const myRef = rsf.ref(branchPath + "/teachers");
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((element) => {
        if (
          element !== null &&
          element.val().deleted === false &&
          element.val().deactivated === false
        ) {
          teacher.push(element.val());
        }
      });
      resolve(teacher);
    });
  });
  return promise1;
}

function updateTeacherObject(additionalObj, teacherId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/teachers/" + teacherId).update(additionalObj);
}

function sendInviteToTeacher(emails, firebase) {
  let schoolName = localStorage.getItem("schoolName");
  let accountName = firebase.sbDbName;
  let teacherEmail = firebase.schoolConfig.adminEmail;
  let inviteEndPointUrl = firebase.endPointUrl + "sendTeacherInvite";

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(inviteEndPointUrl)
      .send({
        parentEmails: emails,
        ccEmail: teacherEmail,
        schoolName: schoolName,
        timezone: localTimezone,
        accountName: accountName,
      })
      .set("accept", "json")

      .end((err, res) => {
        console.log("res ------->>>>", res);
        resolve(res);
      });
  });
  return p1;
}

function getTeacherAttendance(month, teacherId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let searchableMonth = moment(month).format("MMMM[ ]YYYY");

  var staffAttendance = [];
  var presentCount = 0;
  var absentCount = 0;
  var lateCheckIn = 0;
  var lateCheckOut = 0;

  var staffAttendancePromise = new Promise(function(resolve, reject) {
    rsf
      .ref(
        branchPath +
          "/staffAttendanceReference/" +
          teacherId +
          "/" +
          searchableMonth
      )
      .once("value")
      .then(function(snapshot) {
        snapshot.forEach((item) => {
          if (item.val() !== null && item.val().id) {
            staffAttendance.push(item.val());

            if (item.val().absent === false) {
              presentCount++;

              if (item.val().late !== undefined && item.val().late === true) {
                lateCheckIn++;
              }

              if (
                item.val().lateCheckout !== undefined &&
                item.val().lateCheckout === true
              ) {
                lateCheckOut++;
              }
            } else {
              absentCount++;
            }
          }
        });

        let finalData = {};
        finalData.staffAttendance = staffAttendance;
        finalData.presentCount = presentCount;
        finalData.absentCount = absentCount;
        finalData.lateCheckIn = lateCheckIn;
        finalData.lateCheckOut = lateCheckOut;
        resolve(finalData);
      });
  });
  return staffAttendancePromise;
}

function deleteUserAuth(uid, firebase) {
  firebase.auth
    .deleteUser(uid)
    .then(function() {
      console.log("Successfully deleted user from auth");
    })
    .catch(function(error) {
      console.log("Error deleting user:", error);
    });
}

function getSelectedTeacherLeave(firstDay, lastDay, studentId, firebase) {
  var firstDayTime = moment(firstDay).valueOf();
  var lastDayTime = moment(lastDay).valueOf();
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var teacherLeave = [];
  var teacherLeavePromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/staffLeaveReference/")
      .orderByChild("startDate")
      .startAt(firstDayTime)
      .endAt(lastDayTime)
      .once("value")
      .then(function(snapshot) {
        snapshot.forEach((item) => {
          if (
            item.val() !== null &&
            item.val().studentId === studentId &&
            item.val().status.toLowerCase() === "approved"
          ) {
            teacherLeave.push(item.val());
          }
        });
        resolve(teacherLeave);
      });
  });
  return teacherLeavePromise;
}

function addTeacherApi(
  firebase,
  name,
  phoneNumber,
  email,
  gender,
  classroom,
  role,
  address,
  countryCode,
  newBranches,
  groups,
  birthDate
) {
  let endPointUrl = firebase.endPointUrl + "userApi/addTeacher";

  let accountName = firebase.sbDbName;
  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(endPointUrl)
      .send({
        accountName: accountName,
        branchPath: firebase.sbp,
        name: name,
        phoneNumber: phoneNumber,
        email: email,
        gender: gender,
        classList: classroom,
        role: role,
        address: address,
        newBranches: newBranches,
        countryCode: countryCode,
        groups: groups && groups.length > 0 ? groups : null,
        birthDate: birthDate
          ? moment(new Date(birthDate)).format("DD[, ]MMMM[, ]YYYY")
          : null,
      })
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("add teacher error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function addTeacherChildApi(
  firebase,
  teacherId,
  student,
  branchPath,
  parentType
) {
  let endPointUrl = firebase.endPointUrl + "userApi/addTeacherChild";

  let accountName = firebase.sbDbName;
  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(endPointUrl)
      .send({
        accountName: accountName,
        branchPath: branchPath ? branchPath : firebase.sbp,
        teacherId: teacherId,
        student: student,
        parentType: parentType,
      })
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("add teacher error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function updateTeacherApi(firebase, teacherObj, oldPhoneNumber, oldEmail) {
  let endPointUrl = firebase.endPointUrl + "userApi/updateTeacher";

  let accountName = firebase.sbDbName;
  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(endPointUrl)
      .send({
        accountName: accountName,
        branchPath: firebase.sbp,
        oldPhoneNumber: oldPhoneNumber,
        oldEmail: oldEmail,
        teacher: teacherObj,
      })
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("add teacher error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function deleteTeacherApi(firebase, teacherObj) {
  let endPointUrl = firebase.endPointUrl + "userApi/deleteTeacher";

  let accountName = firebase.sbDbName;
  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(endPointUrl)
      .send({
        accountName: accountName,
        branchPath: firebase.sbp,
        teacher: teacherObj,
        platform: "web",
        updatedBy: firebase.teacher.name,
        updatedOn: moment().valueOf(),
      })
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("delete teacher error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

export const TeacherApi = {
  checkIfUserExistInNewUser,
  createTeacherNode,
  getAllTeachers,
  addNewTeacher,
  updateExistingTeacher,
  getClassroomsForTeacher,
  deleteTeacher,
  addNewTeacherCsv,
  createEmailLogin,
  addTeacherEmailToNewUser,
  getTeacherDetail,
  changeStaffPermission,
  changeStaffRole,
  deleteUserFromNewUser,
  getTeacherById,
  getAllStaffByCenter,
  updateTeacherObject,
  sendInviteToTeacher,
  getTeacherAttendance,
  deleteUserAuth,
  getSelectedTeacherLeave,
  addTeacherApi,
  addTeacherChildApi,
  updateTeacherApi,
  deleteTeacherApi,
};
