// import clone from 'clone';
import actions from "./actions";

const initState = {
  teachers: [],
  teacherDetail: [],
  initialTeachers: false,
  isLoading: true,
  enableEditView: false,
  autoCompleteCenter: [],
  teachersClassroomData: [],
  error: false,
  addTeacherRequestSuccessful: false,
  updateTeacherRequestSuccessful: false,
  teacherChannel: undefined,
  teacherDetailChannel: undefined,
  errorMessage: '',
  staffOperation: undefined,
  teacherDoc: [],
  teacherAttendance: [],
  presentCount: 0,
  absentCount: 0,
  leavesCount: 0,
  lateCheckIn: 0,
  lateCheckOut: 0,
  teacherLeaves: []
};

export default function teacherReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.LIST_TEACHER:
      return {
        ...state,
        isLoading: true
      };
    case actions.LIST_TEACHER_SUCCESSFUL:
      return {
        ...state,
        teachers: action.teachers,
        initialTeachers: true,
        enableEditView: false,
        isLoading: false,
        teacherChannel: action.teacherChannel,
        staffOperation: action.staffOperation
        //staffOperation: "INITIAL_STAFF_FETCH"
      };
    case actions.SEARCH_TEACHER:
      return {
        ...state,
        isLoading: true
      };
    case actions.SEARCH_TEACHER_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        teachers: action.teachers
      };
    case actions.FILTER_TEACHER_BY_CLASSROOM:
      return {
        ...state,
        isLoading: true
      };
    case actions.FILTER_TEACHER_BY_CLASSROOM_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        teachers: action.teachers
      };
    case actions.FILTER_TEACHER_BY_CENTER:
      return {
        ...state,
        isLoading: true
      };
    case actions.FILTER_TEACHER_BY_CENTER_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        teachers: action.teachers
      };
    case actions.ADD_TEACHER:
      return {
        ...state,
        isLoading: true,
        addTeacherRequestSuccessful: false
      };
    case actions.ADD_TEACHER_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        addTeacherRequestSuccessful: true
      };
    case actions.UPDATE_TEACHER:
      return {
        ...state,
        isLoading: true,
        updateTeacherRequestSuccessful: false
      };
    case actions.UPDATE_TEACHER_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        updateTeacherRequestSuccessful: true
      };
    case actions.TEACHER_REQUEST_FAILED:
      return {
        ...state,
        error: true,
        isLoading: false,
        errorMessage: action.errorMessage ? action.errorMessage : "Request Failed"
      };
    case actions.GET_AUTOCOMPLETE_CENTER_DATA:
      return {
        ...state,
        isLoading: false
      };
    case actions.GET_AUTOCOMPLETE_CENTER_DATA_SUCCESSFUL:
      return {
        ...state,
        autoCompleteCenter: action.autoCompleteCenter
      };
    case actions.GET_TEACHER_CLASSROOM_DATA:
      return {
        ...state
      };
    case actions.GET_TEACHER_CLASSROOM_DATA_SUCCESSFUL:
      return {
        ...state,
        teachersClassroomData: action.teachersClassroomData
      };
    case actions.DELETE_TEACHER:
      return {
        ...state,
        isLoading: true
      };
    case actions.DELETE_TEACHER_SUCCESSFUL:
      return {
        ...state,
        isLoading: false
      };
    case actions.GET_TEACHER_DETAIL:
      return {
        ...state,
        isLoading: true
      };
    case actions.GET_TEACHER_DETAIL_SUCCESSFUL:
      return {
        ...state,
        teacherDetail: action.teacherDetail,
        teacherDetailChannel: action.teacherDetailChannel,
        isLoading: false
      };
    case actions.CHANGE_STAFF_ROLE:
      return {
        ...state,
        isLoading: true
      };
    case actions.CHANGE_STAFF_ROLE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false
      };

    case actions.CHANGE_PERMISSION:
      return {
        ...state,
        isLoading: true
      };
    case actions.CHANGE_PERMISSION_SUCCESSFUL:
      return {
        ...state,
        isLoading: false
      };

    case actions.RESET_STAFF_OPERATION_TYPE:
      return {
        ...state,
        staffOperation: undefined,
        error: false,
        isLoading: false,
        updateTeacherRequestSuccessful: false
      }

    case actions.UPLOAD_TEACHER_PROFILE:
      return {
        ...state,
        isLoading: true
      };

    case actions.UPLOAD_TEACHER_PROFILE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false
      };

    case actions.GET_TEACHER_FILES:
      return {
        ...state,
        isLoading: true
      };

    case actions.GET_TEACHER_FILES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        teacherDoc: action.teacherDoc
      };

    case actions.UPLOAD_TEACHER_FILE:
      return {
        ...state,
        isLoading: true
      };

    case actions.UPLOAD_TEACHER_FILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        staffOperation: "UPLOAD_DOC"
      };

    case actions.DELETE_TEACHER_FILE:
      return {
        ...state,
        isLoading: true
      };

    case actions.DELETE_TEACHER_FILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        staffOperation: "DELETE_DOC"
      };

    case actions.SET_TEMP_TEACHER_DETAIL_SUCCESS:
      return {
        ...state,
        teacherDetail: action.teacherDetail,
      };

    case actions.START_TEACHER_LOADER:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_TEACHER_ATD:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_TEACHER_ATD_SUCCESS:
      return {
        ...state,
        teacherAttendance: action.teacherAttendance,
        presentCount: action.presentCount,
        absentCount: action.absentCount,
        leavesCount: action.leavesCount,
        lateCheckIn: action.lateCheckIn,
        lateCheckOut: action.lateCheckOut,
        isLoading: false,
        teacherLeaves: action.teacherLeaves
      };

    case actions.UPDATE_TEACHER_STATUS:
      return {
        ...state,
        isLoading: true,
      };

    case actions.UPDATE_TEACHER_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.SEND_TEACHER_INVITE:
      return {
        ...state,
        isLoading: true,
      };

    case actions.SEND_TEACHER_INVITE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.SAVE_TEACHER_CHILD:
      return {
        ...state,
        isLoading: true,
      };

    case actions.SAVE_TEACHER_CHILD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        staffOperation: "ADD_CHILD"
      };

    case actions.ADD_DOC_FOLDER_TEACHER:
      return {
        ...state,
        isLoading: true,
      };

    case actions.ADD_DOC_FOLDER_TEACHER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        staffOperation: "ADD_DOC_FOLDER"
      };

    case actions.RENAME_TEACHER_DOC:
      return {
        ...state,
        isLoading: true,
      };

    case actions.RENAME_TEACHER_DOC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        staffOperation: "RENAME_DOC"
      };


    default:
      return state;
  }
}
