import { call } from 'redux-saga/effects';
import firebase from 'firebase'
import '@firebase/firestore' // 👈 If you're using firestore
import ReduxSagaFirebase from 'redux-saga-firebase';

export function* getAllCurriculums() {
    const firebaseName = localStorage.getItem('projectId');
    const rsf = new ReduxSagaFirebase(firebase.app(firebaseName));
    const fullCurriculum = [];
    const snapshot = yield call(rsf.firestore.getCollection, 'curriculum');
    snapshot.forEach(item => {
        var data = {};
        data.id = item.id
        data.isDisable = item.data().isDisable
        data.date = item.data().date
        data.title = item.data().title
        data.developmentArea = item.data().developmentArea
        data.subArea = item.data().subArea
        data.imageUrl = item.data().images
        data.videoUrl = item.data().videos
        data.duration = item.data().duration
        data.materialRequired = item.data().materialRequired
        data.description = item.data().description
        data.instruction = item.data().instruction
        fullCurriculum.push(data);
    });
    return fullCurriculum;
};

export function* getMediaUrl(file) {
    const firebaseName = localStorage.getItem('projectId');
    const rsf = new ReduxSagaFirebase(firebase.app(firebaseName));
    let newFile = file.name;
    let filePath = 'curriculum/' + newFile;
    let url = '';

    yield call(rsf.storage.uploadFile, filePath, file);
    url = yield call(rsf.storage.getDownloadURL, filePath);

    return url;
};

export function* addNewCurriculum(developmentArea, subArea, curriculumDate, title, imageUrl, videoUrl, duration, materialRequired, description, instruction) {
    const firebaseName = localStorage.getItem('projectId');
    const rsf = new ReduxSagaFirebase(firebase.app(firebaseName));

    yield call(rsf.firestore.addDocument, 'curriculum',
        {
            developmentArea: developmentArea,
            subArea: subArea,
            date: new Date(curriculumDate).toDateString(),
            title: title,
            duration: duration,
            materialRequired: materialRequired,
            description: description,
            instruction: instruction,
            images: [imageUrl],
            videos: [videoUrl],
            isDisable: false
        }
    );
};

export function* updateExistingCurriculum(id, developmentArea, subArea, curriculumDate, title, imageUrl, videoUrl, duration, materialRequired, description, instruction) {
    const firebaseName = localStorage.getItem('projectId');
    const rsf = new ReduxSagaFirebase(firebase.app(firebaseName));

    yield call(rsf.firestore.updateDocument, 'curriculum/'+id,
        {
            developmentArea: developmentArea,
            subArea: subArea,
            date: new Date(curriculumDate).toDateString(),
            title: title,
            duration: duration,
            materialRequired: materialRequired,
            description: description,
            instruction: instruction,
            images: imageUrl,
            videos: videoUrl
        }
    );
};

function* disableCurriculumData(disableStatus, docId) {
    const firebaseName = localStorage.getItem('projectId');
    const rsf = new ReduxSagaFirebase(firebase.app(firebaseName));
    if (disableStatus === false) {
        yield call(rsf.firestore.updateDocument, 'curriculum/' + docId, 'isDisable', true);
    } else {
        yield call(rsf.firestore.updateDocument, 'curriculum/' + docId, 'isDisable', false);
    }
};

export const CurriculumApi = {
    getAllCurriculums,
    getMediaUrl,
    addNewCurriculum,
    updateExistingCurriculum,
    disableCurriculumData
};