import "@firebase/firestore";
import { all, call, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import bugsnagClient from '@bugsnag/js';
import { UserSettingApi } from "../../firestore-api/userSetting";

function* fetchPermissionList({ firebase }) {
    let permissionList = new Map(JSON.parse(localStorage.getItem("permissionList")));
    let permissionRoles = JSON.parse(localStorage.getItem("permissionRoles"));
    let permissionTypes = JSON.parse(localStorage.getItem("permissionTypes"));

    // console.log("permissionTypes", permissionTypes);

    yield put({
        type: actions.GET_PERMISSIONS_SUCCESS,
        permissionList: permissionList,
        permissionRoles: permissionRoles,
        permissionTypes: permissionTypes,
        permissionListChan: undefined
    })
}

function* savePermissions({ permissions, firebase }) {
    try {
        yield call(UserSettingApi.savePermissions, permissions, firebase);
        yield put({
            type: actions.SAVE_PERMISSIONS_SUCCESS
        })
    } catch (err) {
        console.log("failed to update permissions", err);
        yield put({
            type: actions.PERMISSION_REQUEST_FAIL,
            errorMessage: err.message ? err.message : "Failed to save permissions"
        });
        bugsnagClient.notify(err);
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(actions.GET_PERMISSIONS, fetchPermissionList),
        yield takeLatest(actions.SAVE_PERMISSIONS, savePermissions),
    ])
}