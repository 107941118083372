const actions = {
  GET_ALL_EVENTS: "GET_ALL_EVENTS",
  GET_ALL_EVENTS_SUCCESSFUL: "GET_ALL_EVENTS_SUCCESSFUL",

  GET_COMMENT_FOR_EVENT: "GET_COMMENT_FOR_EVENT",
  GET_COMMENT_FOR_EVENT_SUCCESSFUL: "GET_COMMENT_FOR_EVENT_SUCCESSFUL",

  ADD_EVENT: 'ADD_EVENT',
  ADD_EVENT_SUCCESSFUL: 'ADD_EVENT_SUCCESSFUL',

  ADD_EVENT_IMAGES: 'ADD_EVENT_IMAGES',
  ADD_EVENT_IMAGES_SUCCESSFUL: 'ADD_EVENT_IMAGES_SUCCESSFUL',

  ADD_EVENT_FOR_ALL_CENTERS: 'ADD_EVENT_FOR_ALL_CENTERS',
  ADD_EVENT_FOR_ALL_CENTERS_SUCCESSFUL: 'ADD_EVENT_FOR_ALL_CENTERS_SUCCESSFUL',

  UPDATE_EVENT: 'UPDATE_EVENT',
  UPDATE_EVENT_SUCCESSFUL: 'UPDATE_EVENT_SUCCESSFUL',

  GET_PHOTOS_FOR_EVENT: 'GET_PHOTOS_FOR_EVENT',
  GET_PHOTOS_FOR_EVENT_SUCCESSFUL: 'GET_PHOTOS_FOR_EVENT_SUCCESSFUL',

  EVENT_REQUEST_FAILED: "EVENT_REQUEST_FAILED",

  RESET_EVENT_OPERATION_TYPE: "RESET_EVENT_OPERATION_TYPE",

  DELETE_EVENT: "DELETE_EVENT",
  DELETE_EVENT_SUCCESS: "DELETE_EVENT_SUCCESS",

  GET_EVENT_NOTIFICATION: "GET_EVENT_NOTIFICATION",

  getAllEvents: firebase => ({
    type: actions.GET_ALL_EVENTS,
    firebase
  }),

  getCommentsForSelectedEvent: (recordId, firebase) => ({
    type: actions.GET_COMMENT_FOR_EVENT,
    recordId,
    firebase
  }),

  addNewEvent: (formValue, firebase) => ({
    type: actions.ADD_EVENT,
    formValue,
    firebase
  }),

  updateEvent: (formValue, firebase, selectedEvent, eventPhotos) => ({
    type: actions.UPDATE_EVENT,
    formValue,
    firebase,
    selectedEvent,
    eventPhotos
  }),

  getPhotosForEvents: (recordId, firebase) => ({
    type: actions.GET_PHOTOS_FOR_EVENT,
    recordId,
    firebase
  }),

  addNewEventToAllCenters: (formValue, firebase, branches) => ({
    type: actions.ADD_EVENT_FOR_ALL_CENTERS,
    formValue,
    firebase,
    branches
  }),

  resetEventOperationType: () => ({
    type: actions.RESET_EVENT_OPERATION_TYPE
  }),

  addEventImage: (attachment, dataSource, sendNotification, firebase) => ({
    type: actions.ADD_EVENT_IMAGES,
    attachment,
    dataSource,
    sendNotification,
    firebase
  }),

  deleteEvent: (event, firebase) => ({
    type: actions.DELETE_EVENT,
    event,
    firebase
  }),

  getNotificationEvent: (id, firebase) => ({
    type: actions.GET_EVENT_NOTIFICATION,
    id,
    firebase
  })
};
export default actions;
