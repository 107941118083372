import actions from "./actions";
const initState = {
  invoiceTemplate: [],
  isLoading: false,
  error: false,
  operationType: "",
  invoiceTemplateChannel: undefined,
  students: [],
  templateTaxRef: [],
  templateLabel: [],
};
export default function invoiceTemplateReducer(
  state = initState,
  { type, ...action }
) {
  switch (type) {
    case actions.LIST_INVOICE_TEMPLATE:
      return {
        ...state,
        isLoading: true,
        operationType: "",
      };
    case actions.LIST_INVOICE_TEMPLATE_SUCCESSFUL:
      return {
        ...state,
        invoiceTemplate: action.invoiceTemplate,
        invoiceTemplateChannel: action.invoiceTemplateChannel,
        isLoading: false,
        operationType: "",
      };

    case actions.GET_TEMPLATE_LABEL:
      return {
        ...state,
      };
    case actions.GET_TEMPLATE_LABEL_SUCCESS:
      return {
        ...state,
        templateLabel: action.templateLabel,
      };

    case actions.ADD_INVOICE_TEMPLATE:
      return {
        ...state,
        isLoading: true,
        operationType: "",
      };
    case actions.ADD_INVOICE_TEMPLATE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "ADD_TEMPLATE",
      };

    case actions.UPDATE_INVOICE_TEMPLATE:
      return {
        ...state,
        isLoading: true,
        operationType: "",
      };
    case actions.UPDATE_INVOICE_TEMPLATE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "UPDATE_TEMPLATE",
      };

    case actions.GET_STUDENT_FOR_TEMPLATE:
      return {
        ...state,
        isLoading: true,
        operationType: "",
      };
    case actions.GET_STUDENT_FOR_TEMPLATE_SUCCESSFFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "",
        students: action.students,
      };

    case actions.ASSIGN_STUDENT_TO_TEMPLATE:
      return {
        ...state,
        isLoading: true,
        operationType: "",
      };
    case actions.ASSIGN_STUDENT_TO_TEMPLATE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "ASSIGN_STUDENT",
      };

    case actions.DELETE_INVOICE_TEMPLATE:
      return {
        ...state,
        isLoading: true,
        operationType: "",
      };
    case actions.DELETE_INVOICE_TEMPLATE_SUCCESSFFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "DELETE_TEMPLATE",
      };

    case actions.TEMPLATE_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case actions.RESET_INVOICE_TEMPLATE_OPERATION:
      return {
        ...state,
        isLoading: false,
        operationType: "",
      };

    case actions.FETCH_TEMPLATE_TAX_REF:
      return {
        ...state,
      };

    case actions.FETCH_TEMPLATE_TAX_REF_SUCCESS:
      return {
        ...state,
        templateTaxRef: action.templateTaxRef,
      };

    default:
      return state;
  }
}
