import { all, put, call, take, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { FeeApi } from "../../firestore-api/fee";
import { InvoiceApi } from "../../firestore-api/invoice";
import { ProgramApi } from "../../firestore-api/program";
import { NotificationApi } from "../../firestore-api/notification";
import bugsnagClient from "@bugsnag/js";
import moment from "moment-timezone";

function* fetchSelectedPlanFeeComponent({ selectedFeePlanId, firebase }) {
  const chan = yield call(
    FeeApi.getSelectedFeePlan,
    selectedFeePlanId,
    firebase
  );
  try {
    while (true) {
      let data = yield take(chan);

      let componentTask = [];
      let assignedFeeComponent = [];
      if (data && data.feeComponent) {
        let componentIds = data.feeComponent;

        for (let index in componentIds) {
          let task = call(
            FeeApi.getAssignedFeeComponentById,
            componentIds[index],
            firebase
          );
          componentTask.push(task);
        }

        let newVal = yield all([componentTask]);
        for (let i in newVal[0]) {
          let val = newVal[0][i];
          if (val && val.id) {
            assignedFeeComponent.push(val);
          }
        }
      }

      yield put({
        type: actions.LIST_SELECTED_FEE_PLAN_FEE_COMPONENTS_SUCCESSFFUL,
        feePlanComponent: assignedFeeComponent,
        feePlanComponentChannel: chan,
        feePlan: data,
      });

      // let assignedFeeComponent = yield call(
      //   FeeApi.getAssignedFeeComponent,
      //   data,
      //   firebase
      // );
      // if (assignedFeeComponent) {
      //   yield put({
      //     type: actions.LIST_SELECTED_FEE_PLAN_FEE_COMPONENTS_SUCCESSFFUL,
      //     feePlanComponent: assignedFeeComponent,
      //     feePlanComponentChannel: chan,
      //     feePlan: data
      //   });
      // }
    }
  } finally {
    console.log("end channel");
  }
}

function* addNewFeeComponentToFeePlan({ value, selectedFeePlan, firebase }) {
  try {
    var nodeId = yield call(FeeApi.createNewFeeComponentNode, firebase);
    yield call(FeeApi.addFeeComponent, value, nodeId, firebase);
    yield call(
      FeeApi.updateNewFeeComponentToFeePlan,
      selectedFeePlan,
      nodeId,
      firebase
    );

    if (selectedFeePlan.student) {
      let students = selectedFeePlan.student;
      let feePlanId = selectedFeePlan.id;
      for (let index in students) {
        let stdFeePlan = yield call(
          FeeApi.getStudentFeePlanByPlanId,
          students[index].studentId,
          feePlanId,
          firebase
        );
        if (stdFeePlan) {
          let newStudentFeePlan = stdFeePlan;
          let prevStudentFeeComponent = newStudentFeePlan.studentFeeComponent
            ? newStudentFeePlan.studentFeeComponent
            : [];
          var data = {};
          data.amount = Number(value.amount);
          data.discount =
            value.discount !== undefined ? Number(value.discount) : 0;
          data.discountType =
            value.discountType !== undefined ? value.discountType : null;
          data.id = nodeId;
          data.inverseDate = -moment().valueOf();
          data.isRefundable =
            value.paymentSchedule === "One Time"
              ? value.paymentFrequency
              : "Non Refundable";
          data.name = value.name.charAt(0).toUpperCase() + value.name.slice(1);
          data.paymentFrequency = value.paymentFrequency;
          data.paymentSchedule = value.paymentSchedule;
          prevStudentFeeComponent.push(data);
          newStudentFeePlan.studentFeeComponent = prevStudentFeeComponent;
          yield call(
            FeeApi.updateStudentFeePlan,
            students[index].studentId,
            newStudentFeePlan,
            firebase
          );
        }
      }
    }
    yield put({
      type: actions.ADD_FEE_COMPONENT_TO_FEE_PLAN_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to add new fee component to fee plan", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.DETAILED_FEE_PLAN_REQUEST_FAILED,
    });
  }
}

function* updateFeeComponent({ value, editableFeeComponent, firebase }) {
  try {
    if (
      value !== undefined &&
      editableFeeComponent !== undefined &&
      firebase !== undefined
    ) {
      yield call(
        FeeApi.updateFeeComponent,
        value,
        editableFeeComponent,
        firebase
      );
    }
    yield put({
      type: actions.UPDATE_EXISTING_FEE_COMPONENT_SUCCESSFFUL,
    });
  } catch (error) {
    console.log("failed to update existing fee component", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.DETAILED_FEE_PLAN_REQUEST_FAILED,
    });
  }
}

function* fetchStudentForDetailedFeePlan({ firebase }) {
  try {
    //var students = yield call(AssessmentApi.getAllStudents, firebase);
    let students = JSON.parse(localStorage.getItem("studentList"));
    if (students) {
      yield put({
        type: actions.DETAILED_FEE_FETCH_STUDENT_SUCCESSFFUL,
        students: students,
      });
    }
  } catch (error) {
    console.log("failed to fetch student for program", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.DETAILED_FEE_PLAN_REQUEST_FAILED,
    });
  }
}

function* assignStudentToDetailedPlan({
  values,
  selectedStudentCheckbox,
  selectedPlan,
  rows,
  total,
  showGeneratedInvoice,
  firebase,
}) {
  console.log("formValue", values);
  console.log("studentList", selectedStudentCheckbox);
  console.log("selectedFeePlan", selectedPlan);
  console.log("rows", rows);
  console.log("total", total);
  console.log("firebase", firebase);
  try {
    let studentList = selectedStudentCheckbox;
    var invoiceStudentArr = [];
    var studentArray = [];
    if (selectedPlan.student !== undefined) {
      studentArray = selectedPlan.student;
    }

    for (let index in studentList) {
      let filterVal = studentArray.filter((i) => {
        return i.studentId === studentList[index].id;
      });

      if (filterVal === undefined || filterVal.length === 0) {
        studentArray.push({
          name: studentList[index].name,
          studentId: studentList[index].id,
          startDate: moment(values.dateRange[0]).valueOf(),
          endDate: moment(values.dateRange[1]).valueOf(),
          classroomName: studentList[index].classroomName,
          classList: studentList[index].classList
            ? studentList[index].classList
            : [],
          active: true,
        });

        invoiceStudentArr.push({
          name: studentList[index].name,
          studentId: studentList[index].id,
          startDate: moment(values.dateRange[0]).valueOf(),
          endDate: moment(values.dateRange[1]).valueOf(),
          classroomName: studentList[index].classroomName,
          classList: studentList[index].classList
            ? studentList[index].classList
            : [],
          active: true,
        });
      }
    }

    let presentFeePlan = JSON.parse(JSON.stringify(selectedPlan));
    presentFeePlan.student = studentArray;
    yield call(FeeApi.assignStudentsToFeePlan, presentFeePlan, firebase);

    let lineItems = [];
    for (let ind in rows) {
      lineItems.push({
        amount: Number(rows[ind].grossValue),
        discount: Number(rows[ind].discount),
        discountType: rows[ind].discountType,
        id: rows[ind].id,
        inverseDate: -new Date(),
        isRefundable: rows[ind].isRefundable,
        name: rows[ind].description,
        paymentFrequency: rows[ind].paymentFrequency,
        paymentSchedule: rows[ind].paymentSchedule,
      });
    }

    if (
      selectedPlan.paymentMode &&
      selectedPlan.paymentMode === "Subscription"
    ) {
      presentFeePlan.generationDate = moment(values.dateRange[0]).valueOf();
    }
    presentFeePlan.studentFeeComponent = lineItems;
    for (let i in studentList) {
      yield call(
        FeeApi.updateStudentFeePlan,
        studentList[i].id,
        presentFeePlan,
        firebase
      );
      yield call(
        NotificationApi.callFeePlanStudentAssignWebHook,
        studentList[i],
        presentFeePlan,
        moment(values.dateRange[0]).valueOf(),
        moment(values.dateRange[1]).valueOf(),
        firebase,
        "Subscription_Added"
      );
    }

    // call with presentFeePlan of start date is equal to current date
    if (showGeneratedInvoice) {
      let feePlanObject = JSON.parse(JSON.stringify(presentFeePlan));
      feePlanObject.student = invoiceStudentArr;
      yield call(
        NotificationApi.invoiceGenerationRequest,
        feePlanObject,
        firebase
      );
    }

    yield put({
      type: actions.ASSIGN_STUDENT_TO_DETAILED_FEE_PLAN_SUCCESSFUL,
    });
  } catch (error) {
    console.log("failed to assign student to detailed fee plan", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.DETAILED_FEE_PLAN_REQUEST_FAILED,
    });
  }
}

function* deleteStudentFromFeePlan({
  studentRecord,
  selectedFeePlan,
  firebase,
}) {
  try {
    yield call(
      FeeApi.deleteStudentFromFeePlan,
      studentRecord,
      selectedFeePlan,
      firebase
    );

    if (firebase.dbName === "GetReadyEdu_Master-Branch") {
      let studentList = JSON.parse(localStorage.getItem("studentList"));
      let existingStudent = studentList.filter((s) => {
        return s.id === studentRecord.studentId;
      });

      if (existingStudent && existingStudent.length > 0) {
        let filteredStudent = existingStudent[0];
        let data = yield call(
          FeeApi.getStudentFeePlanByPlanId,
          filteredStudent.id,
          selectedFeePlan.id,
          firebase
        );
        if (data) {
          yield call(
            NotificationApi.callFeePlanStudentAssignWebHook,
            filteredStudent,
            data,
            studentRecord.startDate,
            studentRecord.endDate,
            firebase,
            "Subscription_Removed"
          );
        }
      }
    }

    yield call(
      FeeApi.deleteStudentFromStudentFeePlan,
      studentRecord.studentId,
      selectedFeePlan.id,
      firebase
    );
  } catch (err) {
    console.log("failed to delete student from detailed fee plan", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.DETAILED_FEE_PLAN_REQUEST_FAILED,
    });
  }
}

function* fetchAllFeeComponent({ firebase }) {
  try {
    let data = yield call(FeeApi.getAllFeeComponent, firebase);
    if (data) {
      yield put({
        type: actions.GET_ALL_FEE_COMPONENT_TO_ASSIGN_SUCCESSFUL,
        allFeeComponent: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch all fee components", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.DETAILED_FEE_PLAN_REQUEST_FAILED,
    });
  }
}

function* saveNewAttachedComponent({ selectedComponent, feePlan, firebase }) {
  try {
    yield call(
      FeeApi.attachFeeComponentToFeePlan,
      selectedComponent,
      feePlan.id,
      firebase
    );

    let record = feePlan;
    if (record.student) {
      let students = record.student;
      let feePlanId = record.id;
      for (let index in students) {
        let stdFeePlan = yield call(
          FeeApi.getStudentFeePlanByPlanId,
          students[index].studentId,
          feePlanId,
          firebase
        );
        if (stdFeePlan) {
          let newStudentFeePlan = stdFeePlan;
          let prevStudentFeeComponent = [];

          for (let i in selectedComponent) {
            let feeComponent = yield call(
              FeeApi.getFeeComponentById,
              selectedComponent[i],
              firebase
            );
            if (feeComponent && feeComponent.id) {
              var data = {};
              data.amount = feeComponent.amount;
              data.discount = feeComponent.discount;
              data.discountType = feeComponent.discountType;
              data.id = feeComponent.id;
              data.inverseDate = -moment().valueOf();
              data.isRefundable = feeComponent.isRefundable;
              data.name = feeComponent.name;
              data.paymentFrequency = feeComponent.paymentFrequency;
              data.paymentSchedule = feeComponent.paymentSchedule;
              prevStudentFeeComponent.push(data);
            }
          }
          newStudentFeePlan.studentFeeComponent = prevStudentFeeComponent;
          newStudentFeePlan.feeComponent = selectedComponent;
          yield call(
            FeeApi.updateStudentFeePlan,
            students[index].studentId,
            newStudentFeePlan,
            firebase
          );
        }
      }
    }

    yield put({
      type: actions.SAVE_ATTACHED_FEE_COMPONENT_SUCCESSFUL,
    });
  } catch (error) {
    console.log("failed to attach fee component", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.DETAILED_FEE_PLAN_REQUEST_FAILED,
    });
  }
}

function* fetchFeePlanInvoice({ feePlanId, firebase }) {
  try {
    let data = yield call(
      InvoiceApi.getInvoiceByFeePlanId,
      feePlanId,
      firebase
    );
    console.log("data fee plan invoices ---", data);
    if (data) {
      yield put({
        type: actions.GET_FEE_PLAN_INVOICE_SUCCESSFUL,
        feePlanInvoice: data,
      });
    }
  } catch (err) {
    console.log("failed to attach fee plan invoices", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.DETAILED_FEE_PLAN_REQUEST_FAILED,
    });
  }
}

function* fetchPlanInvoiceDownloadUrl({ record, firebase }) {
  try {
    let data;
    if (record.pending === 0) {
      data = yield call(
        InvoiceApi.getInvoiceReceiptDownloadUrl,
        record,
        firebase
      );
    } else if (record.pending !== 0 && record.paid !== 0) {
      data = yield call(
        InvoiceApi.getInvoiceReceiptDownloadUrl,
        record,
        firebase
      );
    } else {
      data = yield call(InvoiceApi.getInvoiceDownloadUrl, record, firebase);
    }
    if (data) {
      console.log("plan invoice url ----", data);
      yield put({
        type: actions.GET_PLAN_INVOICE_DOWNLOAD_URL_SUCCESSFUL,
        planInvoiceDownloadUrl: data,
      });
    }
  } catch (error) {
    console.log("failed to fetch plan invoice download url", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.DETAILED_FEE_PLAN_REQUEST_FAILED,
    });
  }
}

function* updateStudentFeePlanStatus({ record, feePlan, firebase }) {
  try {
    let studentRecord = JSON.parse(JSON.stringify(record));
    let studentsArr = JSON.parse(JSON.stringify(feePlan.student));
    for (let index in studentsArr) {
      if (studentsArr[index].studentId === studentRecord.studentId) {
        studentsArr[index].active = !studentsArr[index].active;
      }
    }

    feePlan.student = studentsArr;
    yield call(FeeApi.assignStudentsToFeePlan, feePlan, firebase);

    let stdFeePlan = yield call(
      FeeApi.getStudentFeePlanByPlanId,
      studentRecord.studentId,
      feePlan.id,
      firebase
    );
    if (stdFeePlan) {
      let stdArr = JSON.parse(JSON.stringify(stdFeePlan.student));
      for (let index in stdArr) {
        if (stdArr[index].studentId === studentRecord.studentId) {
          stdArr[index].active = !stdArr[index].active;
        }
      }

      stdFeePlan.student = stdArr;
      yield call(
        FeeApi.updateStudentFeePlan,
        studentRecord.studentId,
        stdFeePlan,
        firebase
      );
    }
  } catch (err) {
    console.log("failed to update student fee plan status", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.DETAILED_FEE_PLAN_REQUEST_FAILED,
    });
  }
}

function* deleteFeeComponentFromPlan({ record, feePlan, firebase }) {
  try {
    let presentFeePlan = JSON.parse(JSON.stringify(feePlan));
    let prevComponents = presentFeePlan.feeComponent;

    let freshComponents = prevComponents.filter((i) => {
      return i !== record.id;
    });
    presentFeePlan.feeComponent = freshComponents;
    yield call(FeeApi.assignStudentsToFeePlan, presentFeePlan, firebase);

    if (feePlan.student) {
      let feePlanId = feePlan.id;
      let students = feePlan.student;
      for (let index in students) {
        let stdFeePlan = yield call(
          FeeApi.getStudentFeePlanByPlanId,
          students[index].studentId,
          feePlanId,
          firebase
        );
        if (stdFeePlan) {
          let newStudentFeePlan = stdFeePlan;
          let prevStudentFeeComponent = newStudentFeePlan.studentFeeComponent;
          let newStudentFeeComponent = prevStudentFeeComponent.filter((c) => {
            return c.id !== record.id;
          });
          newStudentFeePlan.studentFeeComponent =
            newStudentFeeComponent && newStudentFeeComponent.length > 0
              ? newStudentFeeComponent
              : null;
          yield call(
            FeeApi.updateStudentFeePlan,
            students[index].studentId,
            newStudentFeePlan,
            firebase
          );
        }
      }
    }
    yield put({
      type: actions.DELETE_COMPONENT_FROM_FEE_PLAN_SUCCESS,
    });
  } catch (err) {
    console.log("failed to delete fee component from fee plan", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.DETAILED_FEE_PLAN_REQUEST_FAILED,
    });
  }
}

function* fetchProgramById({ programId, firebase }) {
  try {
    let data = yield call(ProgramApi.fetchProgramById, programId, firebase);
    if (data) {
      yield put({
        type: actions.GET_PROGRAM_BY_ID_SUCCESS,
        planProgram: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch fee plan program", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.DETAILED_FEE_PLAN_REQUEST_FAILED,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(
      actions.ADD_FEE_COMPONENT_TO_FEE_PLAN,
      addNewFeeComponentToFeePlan
    ),
    yield takeLatest(actions.UPDATE_EXISTING_FEE_COMPONENT, updateFeeComponent),
    yield takeLatest(
      actions.LIST_SELECTED_FEE_PLAN_FEE_COMPONENTS,
      fetchSelectedPlanFeeComponent
    ),
    yield takeLatest(
      actions.DETAILED_FEE_FETCH_STUDENT,
      fetchStudentForDetailedFeePlan
    ),
    yield takeLatest(
      actions.ASSIGN_STUDENT_TO_DETAILED_FEE_PLAN,
      assignStudentToDetailedPlan
    ),
    yield takeLatest(
      actions.DELETE_STUDENT_FROM_DETAILED_FEE_PLAN,
      deleteStudentFromFeePlan
    ),
    yield takeLatest(
      actions.GET_ALL_FEE_COMPONENT_TO_ASSIGN,
      fetchAllFeeComponent
    ),
    yield takeLatest(
      actions.SAVE_ATTACHED_FEE_COMPONENT,
      saveNewAttachedComponent
    ),
    yield takeLatest(actions.GET_FEE_PLAN_INVOICE, fetchFeePlanInvoice),
    yield takeLatest(
      actions.GET_PLAN_INVOICE_DOWNLOAD_URL,
      fetchPlanInvoiceDownloadUrl
    ),
    yield takeLatest(
      actions.UPDATE_STUDENT_PLAN_STATUS,
      updateStudentFeePlanStatus
    ),
    yield takeLatest(
      actions.DELETE_COMPONENT_FROM_FEE_PLAN,
      deleteFeeComponentFromPlan
    ),
    yield takeLatest(actions.GET_PROGRAM_BY_ID, fetchProgramById),
  ]);
}
