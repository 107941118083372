// import { food } from "./reducer";

const actions = {
  LIST_FOODS: 'LIST_FOODS',
  LIST_FOOD: 'LIST_FOOD',
  UPDATE_FOOD: 'UPDATE_FOOD',
  UPDATE_FOOD_SUCCESSFUL: 'UPDATE_FOOD_SUCCESSFUL',
  SELECT_CURRENT_FOOD: 'SELECT_CURRENT_FOOD',
  TOGGLE_VIEW: 'FOOD_TOGGLE_VIEW',
  UPDATE_EDIT_FOOD: 'FOOD_UPDATE_EDIT_FOOD',
  ADD_FOOD: 'ADD_FOOD',
  ADD_FOOD_SUCCESSFUL: 'ADD_FOOD_SUCCESSFUL',
  ADD_CSV_MENU: 'ADD_CSV_MENU',
  ADD_FOOD_COMPLETED: 'ADD_FOOD_COMPLETED',
  FOOD_MENU_REQUEST_FAILED: 'FOOD_MENU_REQUEST_FAILED',

  initFoodData: () => ({ type: actions.LIST_FOODS }),

  addFood: (category, date, foodItem, nutrient, allergy) => ({
    type: actions.ADD_FOOD,
    category,
    date,
    foodItem,
    nutrient,
    allergy
  }),

  addCSV: (csvData) => ({
    type: actions.ADD_CSV_MENU,
    csvData
  }),

  updateFood: (updatedCategory, updatedDate, updatedFoodItem, updatedNutrient, updatedAllergy, docId) => ({
    type: actions.UPDATE_FOOD,
    updatedCategory,
    updatedDate,
    updatedFoodItem,
    updatedNutrient,
    updatedAllergy,
    docId
  })

};

export default actions;
