import React from 'react';
import { Link } from 'react-router-dom';
import smallIllumineLogo from '../../image/illumine_logo.png';

export default ({ collapsed, schoolLogoUrl }) => {
  return (
    <div
      className="isoLogoWrapper"
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center"
      }}
    >
      {collapsed ? (
        <div>
          <h3 style={{ marginTop: 0 }}>
            <Link to="/dashboard">
              <img src={smallIllumineLogo} alt="logo" style={{ 'width': '30px', 'heigth': '30px' }} />
            </Link>
          </h3>
        </div>
      ) : (
          <div>
            <Link to="/dashboard">
              <img src={schoolLogoUrl}
                alt="illumineLogo"
                style={{
                  width: "auto",
                  height: "80px",
                  padding: "10px"
                }}
              />
            </Link>
          </div>
        )}
    </div>
  );
};
const styles = {
  logoStyle: {
    height: "70px",
    width: "100%",
    paddingTop: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    // alignItems: "center"
    alignItems: "stretch"
  }
};
