const actions = {
    GET_ALL_LEAVES: "GET_ALL_LEAVES",
    GET_ALL_LEAVES_SUCCESSFUL: "GET_ALL_LEAVES_SUCCESSFUL",

    GET_COMMENT_FOR_LEAVE: 'GET_COMMENT_FOR_LEAVE',
    GET_COMMENT_FOR_LEAVE_SUCCESSFUL: 'GET_COMMENT_FOR_LEAVE_SUCCESSFUL',

    LEAVE_REQUEST_FAILED: 'LEAVE_REQUEST_FAILED',

    RESOLVE_LEAVE: "RESOLVE_LEAVE",
    RESOLVE_LEAVE_SUCCESSFUL: "RESOLVE_LEAVE_SUCCESSFUL",

    SAVE_LEAVE_COMMENT: "SAVE_LEAVE_COMMENT",
    SAVE_LEAVE_COMMENT_SUCCESSFUL: "SAVE_LEAVE_COMMENT_SUCCESSFUL",

    RESET_LEAVE_OPERATION_TYPE: "RESET_LEAVE_OPERATION_TYPE",

    GET_NOTIFICATION_LEAVE: "GET_NOTIFICATION_LEAVE",

    GET_STUDENTS_ON_LEAVE: "GET_STUDENTS_ON_LEAVE",
    GET_STUDENTS_ON_LEAVE_SUCCESS: "GET_STUDENTS_ON_LEAVE_SUCCESS",

    GET_ALL_STAFF_LEAVES: "GET_ALL_STAFF_LEAVES",
    GET_ALL_STAFF_LEAVES_SUCCESSFUL: "GET_ALL_STAFF_LEAVES_SUCCESSFUL",

    RESOLVE_STAFF_LEAVE: "RESOLVE_STAFF_LEAVE",
    RESOLVE_STAFF_LEAVE_SUCCESSFUL: "RESOLVE_STAFF_LEAVE_SUCCESSFUL",

    GET_STAFFS_ON_LEAVE: "GET_STAFFS_ON_LEAVE",
    GET_STAFFS_ON_LEAVE_SUCCESS: "GET_STAFFS_ON_LEAVE_SUCCESS",

    getAllLeaves: (firebase, startDate, endDate, initialCall, selectedFrequency) => ({
        type: actions.GET_ALL_LEAVES,
        firebase,
        startDate,
        endDate,
        initialCall,
        selectedFrequency
    }),

    getLeaveComments: (leaves, firebase) => ({
        type: actions.GET_COMMENT_FOR_LEAVE,
        leaves,
        firebase
    }),

    resolveLeave: (record, status, firebase) => ({
        type: actions.RESOLVE_LEAVE,
        record,
        status,
        firebase
    }),

    saveLeaveComment: (comment, dataSource, firebase) => ({
        type: actions.SAVE_LEAVE_COMMENT,
        comment,
        dataSource,
        firebase
    }),

    resetLeaveOperationType: () => ({
        type: actions.RESET_LEAVE_OPERATION_TYPE
    }),

    getNotificationLeave: (id, firebase) => ({
        type: actions.GET_NOTIFICATION_LEAVE,
        id,
        firebase
    }),

    studentLeaveStatus: (firebase) => ({
        type: actions.GET_STUDENTS_ON_LEAVE,
        firebase
    }),

    getAllStaffLeaves: (firebase, startDate, endDate, initialCall, selectedFrequency) => ({
        type: actions.GET_ALL_STAFF_LEAVES,
        firebase,
        startDate,
        endDate,
        initialCall,
        selectedFrequency
    }),

    resolveStaffLeave: (record, status, firebase) => ({
        type: actions.RESOLVE_STAFF_LEAVE,
        record,
        status,
        firebase
    }),

    staffLeaveStatus: (firebase) => ({
        type: actions.GET_STAFFS_ON_LEAVE,
        firebase
    })
};
export default actions;    