import actions from "./actions";

const initState = {
  students: [],
  completeStudentDetail: [],
  studentDetail: [],
  initialStudents: false,
  isLoading: true,
  enableEditView: false,
  autoCompleteCenter: [],
  classroomData: [],
  error: false,
  requestSuccessful: false,
  groups: [],
  studentsChannel: undefined,
  studentDetailChannel: undefined,
  addStudentRequest: false,
  updateStudentReuest: false,
  addParentRequest: false,
  errorMessage: null,
  userVersion: [],
  sendInviteRequest: false,
  studentAttendance: [],
  presentCount: 0,
  absentCount: 0,
  leavesCount: 0,
  lateCheckIn: 0,
  lateCheckOut: 0,
  selectedStudent: [],
  operationType: undefined,
  observedSibling: undefined,
  observedTeacher: undefined,
  originalStudent: undefined,
  relation: undefined,
  studentFeePlanList: [],
  studentDoc: [],
  studentPrograms: [],
  oneTimeComponent: [],
  oneTimeComponentLoading: false,
  studentProgramList: [],
  studentNotes: [],
  studentNotesChan: undefined,
  studentLeaves: [],
  existingDetail: undefined,
  isApiLoading: false,
};

export default function studentReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.LIST_STUDENT:
      return {
        ...state,
        isLoading: true,
        selectedStudent: [],
      };
    case actions.LIST_STUDENT_SUCCESSFUL:
      return {
        ...state,
        // students: JSON.parse(localStorage.getItem("studentList")),
        students: action.students,
        enableEditView: false,
        isLoading: false,
        initialStudents: true,
        studentsChannel: action.studentsChannel,
        selectedStudent: [],
        operationType: action.operationType,
      };
    case actions.FETCH_STUDENT_DETAILS:
      return {
        ...state,
        isLoading: true,
        studentDetail: [],
      };
    case actions.FETCH_STUDENT_DETAILS_SUCCESSFUL:
      return {
        ...state,
        studentDetail: action.studentDetail,
        studentDetailChannel: action.studentDetailChannel,
        isLoading: false,
      };
    case actions.SUBMIT_STUDENT:
      return {
        ...state,
        addStudentRequest: false,
        isLoading: true,
      };
    case actions.SUBMIT_STUDENT_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        addStudentRequest: true,
      };

    case actions.UPDATE_STUDENT:
      return {
        ...state,
        updateStudentReuest: false,
        isLoading: true,
      };
    case actions.UPDATE_STUDENT_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        updateStudentReuest: true,
      };

    /**newly added code */
    case actions.INVITE_PARENT:
      return {
        ...state,
        addParentRequest: false,
        isLoading: true,
      };
    case actions.INVITE_PARENT_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        addParentRequest: true,
      };

    case actions.REQUEST_FAILED:
      return {
        ...state,
        error: true,
        isLoading: false,
        errorMessage: action.errorMessage
          ? action.errorMessage
          : "Request Failed",
        oneTimeComponentLoading: false,
      };
    case actions.GET_STUDENT_CLASSROOM_DATA:
      return {
        ...state,
      };
    case actions.GET_STUDENT_CLASSROOM_DATA_SUCCESSFUL:
      return {
        ...state,
        classroomData: action.classroomData,
      };
    case actions.GET_TAGS:
      return {
        ...state,
      };
    case actions.GET_TAGS_SUCCESSFUL:
      return {
        ...state,
        groups: action.groups,
      };
    case actions.DELETE_STUDENT:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_STUDENT_SUCCESSFFUL:
      return {
        ...state,
        isLoading: false,
      };
    case actions.GET_PARENT_DETAIL_OF_SELECTED_STUDENT:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_PARENT_DETAIL_OF_SELECTED_STUDENT_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        completeStudentDetail: action.completeStudentDetail,
      };
    case actions.GET_USER_VERSION:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_USER_VERSION_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        userVersion: action.userVersion,
      };
    case actions.SEND_INVITE:
      return {
        ...state,
        isLoading: true,
        sendInviteRequest: false,
      };
    case actions.SEND_INVITE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        sendInviteRequest: true,
        status: action.status,
      };

    case actions.GET_SELECTED_STUDENT_ATTENDANCE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_SELECTED_STUDENT_ATTENDANCE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        studentAttendance: action.studentAttendance,
        presentCount: action.presentCount,
        absentCount: action.absentCount,
        leavesCount: action.leavesCount,
        lateCheckIn: action.lateCheckIn,
        lateCheckOut: action.lateCheckOut,
        studentLeaves: action.studentLeaves,
      };

    case actions.UPDATE_STUDENT_MEDICATION:
      return {
        ...state,
        isLoading: true,
        updateStudentReuest: false,
      };
    case actions.UPDATE_STUDENT_MEDICATION_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        updateStudentReuest: true,
      };

    case actions.UPDATE_STUDENT_ALLERGY:
      return {
        ...state,
        isLoading: true,
        updateStudentReuest: false,
      };
    case actions.UPDATE_STUDENT_ALLERGY_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        updateStudentReuest: true,
      };

    case actions.UPDATE_BLOOD_GROUP:
      return {
        ...state,
        isLoading: true,
        updateStudentReuest: false,
      };
    case actions.UPDATE_BLOOD_GROUP_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        updateStudentReuest: true,
      };

    case actions.UPDATE_ADMISSION_NUMBER:
      return {
        ...state,
        isLoading: true,
        updateStudentReuest: false,
      };
    case actions.UPDATE_ADMISSION_NUMBER_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        updateStudentReuest: true,
      };

    case actions.UPDATE_STUDENT_ACTIVE_STATUS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_STUDENT_ACTIVE_STATUS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
      };

    case actions.SAVE_PARENT_EXTRA_DETAILS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SAVE_PARENT_EXTRA_DETAILS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "PARENT_DETAIL_SAVED",
      };

    case actions.REFRESH_FORM_FIELDS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.REFRESH_FORM_FIELDS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        selectedStudent: action.selectedStudent,
      };

    case actions.RESET_SELECTED_STUDENT:
      return {
        ...state,
      };
    case actions.RESET_SELECTED_STUDENT_SUCCESSFUL:
      return {
        ...state,
        selectedStudent: [],
      };

    case actions.REFRESH_CHILD_FORM_FIELDS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.REFRESH_CHILD_FORM_FIELDS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        selectedStudent: action.selectedStudent,
      };

    case actions.NUMBER_ALREADY_EXISTS:
      return {
        ...state,
        isLoading: false,
        operationType: "ASK_IF_SIBLING",
        observedSibling: action.sibling,
        originalStudent: action.originalStudent,
      };

    case actions.NUMBER_ALREADY_EXISTS_FOR_TEACHER:
      return {
        ...state,
        isLoading: false,
        operationType: "ASK_IF_CHILD_OF_TEACHER",
        observedTeacher: action.teacher,
        relation: action.relation,
        originalStudent: action.originalStudent,
      };

    case actions.RESET_STUDENT_OPERATION:
      return {
        ...state,
        operationType: undefined,
        observedSibling: undefined,
        observedTeacher: undefined,
        originalStudent: undefined,
        relation: undefined,
        errorMessage: null,
        error: false,
        addParentRequest: false,
        updateStudentReuest: false,
      };

    case actions.SAVE_PARENT_IF_SIBLING:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SAVE_PARENT_IF_SIBLING_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        addParentRequest: true,
      };

    case actions.SAVE_PARENT_IF_TEACHER:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SAVE_PARENT_IF_TEACHER_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        addParentRequest: true,
      };

    case actions.NUMBER_FAILED_TO_UPDATE:
      return {
        ...state,
        operationType: "NUMBER_FAILED_TO_UPDATE",
        errorMessage: action.errorMessage,
        isLoading: false,
      };

    case actions.ADD_SIBLING:
      return {
        ...state,
        isLoading: true,
      };

    case actions.ADD_SIBLING_SUCCESSFUL:
      return {
        ...state,
        operationType: "ADD_SIBLING",
        isLoading: false,
      };

    case actions.STUDENT_CLASS_CHANGE:
      return {
        ...state,
        isLoading: true,
      };

    case actions.STUDENT_CLASS_CHANGE_SUCCESSFUL:
      return {
        ...state,
        operationType: action.operationType,
        isLoading: false,
      };

    case actions.UPLOAD_PROFILE:
      return {
        ...state,
        isLoading: true,
      };

    case actions.UPLOAD_PROFILE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
      };

    case actions.DOWNLOAD_STUDENT_EXCEL_SHEET:
      return {
        ...state,
        isLoading: true,
      };

    case actions.DOWNLOAD_STUDENT_EXCEL_SHEET_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
      };

    case actions.DOWNLOAD_STUDENT_ALLERGY_EXCEL_SHEET:
      return {
        ...state,
        isLoading: true,
      };

    case actions.DOWNLOAD_STUDENT_ALLERGY_EXCEL_SHEET_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
      };

    case actions.GET_STUDENT_FEE_PLAN:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_STUDENT_FEE_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        studentFeePlanList: action.studentFeePlanList,
      };

    case actions.GET_STUDENT_DOC:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_STUDENT_DOC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        studentDoc: action.studentDoc,
      };

    case actions.UPLOAD_STUDENT_DOC:
      return {
        ...state,
        isLoading: true,
      };

    case actions.UPLOAD_STUDENT_DOC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "UPLOAD_DOC",
      };

    case actions.DELETE_STUDENT_DOC:
      return {
        ...state,
        isLoading: true,
      };

    case actions.DELETE_STUDENT_DOC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "DELETE_DOC",
      };

    case actions.CHANGE_STUDENT_STATUS:
      return {
        ...state,
        isLoading: true,
      };

    case actions.CHANGE_STUDENT_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "STATUS_CHANGE",
      };

    case actions.GET_STUDENT_PROGRAMS:
      return {
        ...state,
      };

    case actions.GET_STUDENT_PROGRAMS_SUCCESS:
      return {
        ...state,
        studentPrograms: action.studentPrograms,
      };

    case actions.SEND_INVITE_SMS_REMINDER:
      return {
        ...state,
      };

    case actions.GET_STUDENT_ONE_TIME_FEE_COMPONENT:
      return {
        ...state,
        oneTimeComponentLoading: true,
      };

    case actions.GET_STUDENT_ONE_TIME_FEE_COMPONENT_SUCCESS:
      return {
        ...state,
        oneTimeComponentLoading: false,
        oneTimeComponent: action.oneTimeComponent,
      };

    case actions.SAVE_AUTHORISED_PICKUP:
      return {
        ...state,
        isLoading: true,
      };

    case actions.SAVE_AUTHORISED_PICKUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "PICKUP",
      };

    case actions.EDIT_AUTHORISED_PICKUP:
      return {
        ...state,
        isLoading: true,
      };

    case actions.EDIT_AUTHORISED_PICKUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "PICKUP",
      };

    case actions.START_STUDENT_LOADER:
      return {
        ...state,
        isLoading: true,
      };

    case actions.DOWNLOAD_TERMS_CONDITIONS_REPORT:
      return {
        ...state,
        isLoading: true,
      };

    case actions.DOWNLOAD_TERMS_CONDITIONS_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.UPDATE_TRIAL_COUNT:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_TRIAL_COUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "UPDATE_TRIAL_COUNT",
      };

    case actions.UPDATE_STUDENT_COMMUNICATION_EMAIL:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_STUDENT_COMMUNICATION_EMAIL_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "UPDATE_COMMUNICATION",
      };

    case actions.GET_PROGRAM_BY_STUDENT_ID:
      return {
        ...state,
        studentProgramList: action.studentProgramList,
      };

    case actions.ADD_STUDENT_NOTE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.ADD_STUDENT_NOTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.GET_STUDENT_NOTE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_STUDENT_NOTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        studentNotes: action.studentNotes,
        studentNotesChan: action.studentNotesChan,
      };

    case actions.DELETE_STUDENT_SELECTED_FEE_PLAN:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_STUDENT_SELECTED_FEE_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "FEE_PLAN_DELETE",
      };

    case actions.DELETE_AUTHORISED_PICKUP:
      return {
        ...state,
        isLoading: true,
      };

    case actions.DELETE_AUTHORISED_PICKUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "DELETE_AUTH_PICK",
      };

    case actions.ADD_DOC_FOLDER:
      return {
        ...state,
        isLoading: true,
      };

    case actions.ADD_DOC_FOLDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "ADD_DOC_FOLDER",
      };

    case actions.RENAME_STUDENT_DOC_FOLDER:
      return {
        ...state,
        isLoading: true,
      };

    case actions.RENAME_STUDENT_DOC_FOLDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "RENAME_DOC",
      };

    case actions.DELETE_PARENT:
      return {
        ...state,
        isLoading: true,
      };

    case actions.DELETE_PARENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "DELETE_PARENT",
      };

    case actions.UNLINK_PARENT:
      return {
        ...state,
        isLoading: true,
      };

    case actions.UNLINK_PARENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "UNLINK_PARENT",
      };

    case actions.LINK_FOUND:
      return {
        ...state,
        isLoading: false,
        operationType: "LINK_FOUND",
        existingDetail: action.existingDetail,
      };

    case actions.RESET_EXISTING_DETAIL:
      return {
        ...state,
        isLoading: false,
        existingDetail: undefined,
      };

    case actions.LINK_PARENT:
      return {
        ...state,
        isLoading: false,
        existingDetail: undefined,
      };

    case actions.LINK_PARENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addParentRequest: true,
      };

    case actions.FORCE_LOADER_START:
      return {
        ...state,
        isApiLoading: true,
      };

    case actions.FORCE_LOADER_STOP:
      return {
        ...state,
        isApiLoading: false,
      };

    default:
      return state;
  }
}
