import { all, put, call, take, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { StudentAttendanceApi } from "../../firestore-api/studentAttendance";
import { NotificationApi } from "../../firestore-api/notification";
import moment from "moment";
import notification from "../../components/notification";
import { ActivityApi } from "../../firestore-api/activity";
import { ProgramApi } from "../../firestore-api/program";
import bugsnagClient from "@bugsnag/js";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { debug } from "logrocket";
import { ComplainsApi } from "../../firestore-api/consult";
// import * as ExcelJS from "exceljs";

function* fetchStudentAttendance({ date, firebase, operationType }) {
  const chan = yield call(StudentAttendanceApi.getAttendance, date, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.FETCH_STUDENT_ATTENDANCE_SUCCESSFUL,
        studentAttendance: data,
        attendanceChannel: chan,
        operationType: operationType,
        //operationType: "INITIAL_STUDENT_ATD_FETCH"
      });
    }
  } finally {
    console.log("end tag channel");
  }
}

function* getStudents({ firebase, date }) {
  try {
    //let data = yield call(AssessmentApi.getAllStudents, firebase);
    let startTime = moment(date)
      .startOf("day")
      .valueOf();
    let endTime = moment(date)
      .endOf("day")
      .valueOf();

    let leaveIds = yield call(
      ComplainsApi.getCurrentDateStudentLeave,
      firebase,
      startTime,
      endTime
    );

    let students = JSON.parse(localStorage.getItem("studentList"));
    let data = students.filter((item) => {
      return (
        (!item.status || item.status.toLowerCase() === "active") &&
        !item.deactivated &&
        !leaveIds.includes(item.id)
      );
    });

    if (data) {
      yield put({
        type: actions.FETCH_ALL_STUDENT_SUCCESSFUL,
        allStudents: data,
      });
    }
  } catch (error) {
    console.log("failed to fetch studens", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* markStudentsPresent({
  selectedStudent,
  date,
  time,
  firebase,
  pending,
  checkInOutStatus,
  temperature,
}) {
  try {
    for (let i = 0; i < selectedStudent.length; i++) {
      // let nodeId = yield call(StudentAttendanceApi.createUniqueNode, date, firebase);
      let nodeId = selectedStudent[i].id;

      var checkInOutObject = {
        absent: false,
        checkInTime: moment(time).format("h:mm a"),
        checkInEpoch: moment(time).valueOf(),
        checkOutEpoch: 0,
        className: selectedStudent[i].classroomName
          ? selectedStudent[i].classroomName
          : selectedStudent[i].classList
          ? selectedStudent[i].classList[0]
          : null,
        classList: selectedStudent[i].classList
          ? selectedStudent[i].classList
          : [],
        createdBy: firebase.teacher.name,
        date: moment(date).valueOf(),
        deactivated: false,
        fatherProfileId: selectedStudent[i].fatherProfileId
          ? selectedStudent[i].fatherProfileId
          : null,
        fatherUuid: selectedStudent[i].fatherUUid
          ? selectedStudent[i].fatherUUid
          : null,
        motherProfileId: selectedStudent[i].motherProfileId
          ? selectedStudent[i].motherProfileId
          : null,
        motherUuid: selectedStudent[i].motherUUid
          ? selectedStudent[i].motherUUid
          : null,
        gender: selectedStudent[i].gender,
        id: nodeId,
        profileImageUrl: selectedStudent[i].profileImageUrl
          ? selectedStudent[i].profileImageUrl
          : null,
        late: checkInOutStatus && checkInOutStatus === "late" ? true : false,
        lateCheckout: false,
        tags: selectedStudent[i].tags ? selectedStudent[i].tags : null,
        userId: selectedStudent[i].id,
        userName: selectedStudent[i].name,
        platform: "web",
        updatedBy: [firebase.teacher.name],
        temperature: temperature ? temperature : null,
      };
      yield fork(
        StudentAttendanceApi.markPresentToCheckInOut,
        selectedStudent[i],
        date,
        time,
        nodeId,
        firebase,
        checkInOutObject
      );
      yield fork(
        StudentAttendanceApi.addRecordToStudentAttendance,
        date,
        checkInOutObject,
        selectedStudent[i],
        firebase
      );
      yield fork(
        StudentAttendanceApi.markPresentToAttendanceUpdates,
        selectedStudent[i],
        date,
        time,
        nodeId,
        firebase,
        checkInOutObject
      );

      yield fork(
        StudentAttendanceApi.updatedAttendanceRecordApi,
        checkInOutObject,
        firebase,
        "updateAttendanceStudent"
      );

      let dateFormat = moment(time).format(" DD[-]MM[-]YY");
      yield fork(
        StudentAttendanceApi.updateStudentLastAtd,
        selectedStudent[i].id,
        dateFormat,
        firebase
      );

      if (
        selectedStudent[i].fatherProfileId &&
        !firebase.schoolConfig.noNotificationAttendance
      ) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          selectedStudent[i].fatherProfileId,
          firebase
        );
        yield fork(
          NotificationApi.createAlertNotification,
          "Attendance",
          null,
          selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
          selectedStudent[i].name + " was marked present",
          alertNode,
          selectedStudent[i].ios_fatherUUid
            ? selectedStudent[i].ios_fatherUUid
            : null,
          selectedStudent[i].id,
          selectedStudent[i].fatherProfileId,
          firebase
        );

        if (
          selectedStudent[i].fatherUUid !== undefined ||
          selectedStudent[i].ios_fatherUUid !== undefined
        ) {
          yield fork(
            NotificationApi.sendPushNotification,
            "Attendance",
            null,
            selectedStudent[i].fatherUUid
              ? selectedStudent[i].fatherUUid
              : null,
            selectedStudent[i].name + " was marked present",
            alertNode,
            selectedStudent[i].ios_fatherUUid
              ? selectedStudent[i].ios_fatherUUid
              : null,
            selectedStudent[i].id,
            selectedStudent[i].fatherProfileId,
            firebase
          );
        }
      }

      if (
        selectedStudent[i].motherProfileId &&
        !firebase.schoolConfig.noNotificationAttendance
      ) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          selectedStudent[i].motherProfileId,
          firebase
        );
        yield fork(
          NotificationApi.createAlertNotification,
          "Attendance",
          null,
          selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
          selectedStudent[i].name + " was marked present",
          alertNode,
          selectedStudent[i].ios_motherUUid
            ? selectedStudent[i].ios_motherUUid
            : null,
          selectedStudent[i].id,
          selectedStudent[i].motherProfileId,
          firebase
        );

        if (
          selectedStudent[i].motherUUid !== undefined ||
          selectedStudent[i].ios_motherUUid !== undefined
        ) {
          yield fork(
            NotificationApi.sendPushNotification,
            "Attendance",
            null,
            selectedStudent[i].motherUUid
              ? selectedStudent[i].motherUUid
              : null,
            selectedStudent[i].name + " was marked present",
            alertNode,
            selectedStudent[i].ios_motherUUid
              ? selectedStudent[i].ios_motherUUid
              : null,
            selectedStudent[i].id,
            selectedStudent[i].motherProfileId,
            firebase
          );
        }
      }

      if (
        firebase &&
        firebase.schoolConfig &&
        firebase.schoolConfig.attendanceUpdate
      ) {
        yield fork(
          sendAttendanceUpdateToParent,
          selectedStudent[i],
          time,
          firebase
        );
      }
    }

    yield put({
      type: actions.MARK_PRESENT_SUCCESSFUL,
    });
    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "attendance",
      date
    );
    yield fork(
      NotificationApi.sendStats,
      date,
      "updateDailyPostStats",
      firebase
    );
    yield fork(StudentAttendanceApi.lastUpdateTimestamp, time, firebase);

    /**no need to auto update as upsent */
    // yield call(updateUnSelectedStudentAttendance, pending, date, firebase);
  } catch (err) {
    console.log("failed to mark student present", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* sendAttendanceUpdateToParent(student, time, firebase) {
  try {
    let activityId = yield call(ActivityApi.generateNewActivityNode, firebase);
    let activityDate = new Date(time);
    let activityType = "Attendance";
    let classNames = student.classList
      ? student.classList
      : [student.classroomName];
    let mediaPaths = [];
    let meetingId = null;
    let meetingTime = null;
    let message = student.name + " was marked present";
    let name = "Attendance";
    let templateMessage = null;
    let staffOnly = false;
    let studentIds = [student.id];
    let foodSource = null;
    let meal = null;
    let quantity = null;
    let foodMenu = null;
    let pottyDestination = null;
    let pottyType = null;
    let napStart = null;
    let meetingUrl = null;
    let joinUrl = null;
    let mediaFormat = null;
    let thumbNail = null;
    let youtubeUrlCode = null;
    let repeatStartDate = null;
    let repeatEndDate = null;
    let includeSaturday = false;
    let daysOfWeek = null;
    let meetingCapacity = null;
    let meetingBookable = null;
    let meetingDuration = null;
    let htmlText = null;
    let enableParentComments = false;
    let studentId = student.id;

    yield call(
      ActivityApi.addNewActivityToActivities,
      activityDate,
      activityType,
      classNames,
      mediaPaths,
      meetingId,
      meetingTime,
      message,
      name,
      templateMessage,
      staffOnly,
      studentIds,
      activityId,
      foodSource,
      meal,
      quantity,
      foodMenu,
      pottyDestination,
      pottyType,
      napStart,
      firebase,
      undefined,
      meetingUrl,
      joinUrl,
      mediaFormat,
      thumbNail,
      youtubeUrlCode,
      repeatStartDate,
      repeatEndDate,
      includeSaturday,
      daysOfWeek,
      meetingCapacity,
      meetingBookable,
      meetingDuration,
      htmlText,
      enableParentComments,
      null
    );

    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "activity",
      activityDate
    );

    let studentTimeline = yield call(
      ActivityApi.getStudentTimeline,
      activityDate,
      studentId,
      firebase
    );

    if (studentTimeline) {
      let newActivityIds =
        studentTimeline.activityIds && studentTimeline.activityIds.length > 0
          ? studentTimeline.activityIds
          : [];
      newActivityIds.push(activityId);

      let newTimelineData = {
        activityIds: newActivityIds,
        date: moment().valueOf(),
        inverseDate: -moment().valueOf(),
      };

      yield call(
        ActivityApi.updateStudentTimeline,
        activityDate,
        studentId,
        newTimelineData,
        firebase
      );
    }

    let activityTypeNodeId = yield call(
      ActivityApi.generateActivityTypeNode,
      activityType,
      studentId,
      firebase
    );

    if (activityTypeNodeId) {
      let creator = firebase.teacher.name;
      yield call(
        ActivityApi.updateActivityType,
        activityDate,
        studentId,
        activityId,
        activityTypeNodeId,
        activityType,
        firebase,
        undefined,
        undefined,
        creator
      );
    }

    let names = [
      {
        className: student.classroomName
          ? student.classroomName
          : student.classList
          ? student.classList[0]
          : null,
        classList: student.classList ? student.classList : [],
        date: moment().valueOf(),
        fatherAndroidId: student.fatherUUid ? student.fatherUUid : null,
        fatherId: student.fatherProfileId ? student.fatherProfileId : null,
        fatherIosId: student.ios_fatherUUid ? student.ios_fatherUUid : null,
        gender: student.gender ? student.gender : "Male",
        motherId: student.motherProfileId ? student.motherProfileId : null,
        parentName: firebase.teacher.name,
        profileImageUrl: student.profileImageUrl
          ? student.profileImageUrl
          : null,
        studentId: student.id,
        studentName: student.name,
      },
    ];

    yield fork(ActivityApi.addUpdatedStudent, activityId, names, firebase);
  } catch (err) {
    console.log("failed to send attendance update to parent", err);
    bugsnagClient.notify(err);
  }
}

// function* updateUnSelectedStudentAttendance(students, atdDate, firebase) {
//     try {
//         let selectedStudent = students;
//         let date = atdDate;

//         for (let i = 0; i < selectedStudent.length; i++) {
//             if (selectedStudent[i].id) {
//                 // let nodeId = yield call(activityApi.createUniqueAttendanceNode, date);
//                 let nodeId = selectedStudent[i].id;
//                 var checkInOutObject = {
//                     absent: false,
//                     checkInTime: null,
//                     checkInEpoch: 0,
//                     checkOutEpoch: 0,
//                     className: selectedStudent[i].classroomName,
//                     createdBy: firebase.teacher.name,
//                     date: new Date(date).getTime(),
//                     deactivated: false,
//                     fatherProfileId: selectedStudent[i].fatherProfileId ? selectedStudent[i].fatherProfileId : null,
//                     fatherUuid: selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
//                     motherProfileId: selectedStudent[i].motherProfileId ? selectedStudent[i].motherProfileId : null,
//                     motherUuid: selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
//                     gender: selectedStudent[i].gender,
//                     id: nodeId,
//                     profileImageUrl: selectedStudent[i].profileImageUrl ? selectedStudent[i].profileImageUrl : null,
//                     tags: selectedStudent[i].tags ? selectedStudent[i].tags : null,
//                     userId: selectedStudent[i].id,
//                     userName: selectedStudent[i].name,
//                     late: false,
//                     lateCheckout: false,
//                     platform: "web",
//                     updatedBy: [firebase.teacher.name]
//                 }
//                 yield fork(StudentAttendanceApi.addRecordToStudentAttendance, date, checkInOutObject, selectedStudent[i], firebase);
//             }
//         }
//     } catch (err) {
//         console.log("failed to update unselected student attendance status", err);
//         bugsnagClient.notify(err);
//     }
// }

function* markStudentsAbsent({ selectedStudent, date, firebase }) {
  try {
    for (let i = 0; i < selectedStudent.length; i++) {
      yield fork(
        StudentAttendanceApi.removeStudentRecordFromCheckInOut,
        selectedStudent[i],
        date,
        firebase
      );

      let nodeId = selectedStudent[i].id;
      var checkInOutObject = {
        absent: true,
        checkInTime: null,
        checkInEpoch: 0,
        checkOutEpoch: 0,
        className: selectedStudent[i].classList
          ? selectedStudent[i].classList[0]
          : selectedStudent[i].classroomName,
        classList: selectedStudent[i].classList
          ? selectedStudent[i].classList
          : [],
        createdBy: firebase.teacher.name,
        date: moment(date).valueOf(),
        deactivated: false,
        fatherProfileId: selectedStudent[i].fatherProfileId
          ? selectedStudent[i].fatherProfileId
          : null,
        fatherUuid: selectedStudent[i].fatherUUid
          ? selectedStudent[i].fatherUUid
          : null,
        motherProfileId: selectedStudent[i].motherProfileId
          ? selectedStudent[i].motherProfileId
          : null,
        motherUuid: selectedStudent[i].motherUUid
          ? selectedStudent[i].motherUUid
          : null,
        gender: selectedStudent[i].gender,
        id: nodeId,
        profileImageUrl: selectedStudent[i].profileImageUrl
          ? selectedStudent[i].profileImageUrl
          : null,
        tags: selectedStudent[i].tags ? selectedStudent[i].tags : null,
        userId: selectedStudent[i].id,
        userName: selectedStudent[i].name,
        late: false,
        lateCheckout: false,
        platform: "web",
        updatedBy: [firebase.teacher.name],
      };
      yield fork(
        StudentAttendanceApi.updateRecordToStudentAttendance,
        selectedStudent[i],
        date,
        firebase,
        checkInOutObject
      );
      yield fork(
        StudentAttendanceApi.markPresentToAttendanceUpdates,
        selectedStudent[i],
        date,
        undefined,
        nodeId,
        firebase,
        checkInOutObject
      );
      yield fork(
        StudentAttendanceApi.updateStudentLastAtd,
        selectedStudent[i].id,
        undefined,
        firebase,
        checkInOutObject
      );

      yield fork(
        StudentAttendanceApi.updateStudentLastAtdCheckout,
        selectedStudent[i].id,
        undefined,
        firebase
      );
    }
    yield put({
      type: actions.MARK_ABSENT_SUCCESSFUL,
    });

    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "attendance",
      date
    );
    yield call(
      attendanceCountUpdate,
      selectedStudent,
      date,
      firebase,
      "markPending"
    );
    yield fork(
      NotificationApi.sendStats,
      date,
      "updateDailyPostStats",
      firebase
    );
    yield fork(StudentAttendanceApi.lastUpdateTimestamp, date, firebase);
  } catch (err) {
    console.log("failed to mark student absent", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* attendanceCountUpdate(students, date, firebase, atdAction) {
  for (let index in students) {
    let data = yield call(
      StudentAttendanceApi.getStudentAttendanceById,
      date,
      students[index].id,
      firebase
    );
    // in case of pending send a flag
    yield fork(
      StudentAttendanceApi.updatedAttendanceRecordApi,
      data,
      firebase,
      "updateAttendanceStudent",
      atdAction
    );
  }
}

function* getClassrooms({ firebase }) {
  try {
    //let data = yield call(TeacherApi.getClassroomsForTeacher, firebase);
    let data = JSON.parse(localStorage.getItem("classList"));
    if (data) {
      yield put({
        type: actions.FETCH_ALL_CLASSROOMS_SUCCESSFUL,
        classrooms: data,
      });
    }

    yield fork(fetchPrograms, firebase);
  } catch (err) {
    console.log("failed to fetch classrooms", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* fetchPrograms(firebase) {
  try {
    let programs = yield call(ProgramApi.fetchPrograms, firebase);
    if (programs) {
      yield put({
        type: actions.GET_PROGRAMS_LIST,
        programList: programs,
      });
    }
  } catch (err) {
    console.log("failed to fetch programs", err);
    bugsnagClient.notify(err);
  }
}

function* getStudentByClassroomName({ className, firebase, date }) {
  try {
    //let data = yield call(StudentAttendanceApi.getStudentByClassName, className, firebase);
    let startTime = moment(date)
      .startOf("day")
      .valueOf();
    let endTime = moment(date)
      .endOf("day")
      .valueOf();
    let leaveIds = yield call(
      ComplainsApi.getCurrentDateStudentLeave,
      firebase,
      startTime,
      endTime
    );
    let students = JSON.parse(localStorage.getItem("studentList"));
    let data = students.filter((item) => {
      return (
        item.classList.includes(className) &&
        (!item.status || item.status.toLowerCase() === "active") &&
        !item.deactivated &&
        !leaveIds.includes(item.id)
      );
    });

    if (data) {
      yield put({
        type: actions.FETCH_STUDENT_BY_CLASSNAME_SUCCESSFUL,
        allStudents: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch student from classname", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* fetchStudentAttendanceByClassName({ className, date, firebase }) {
  const chan = yield call(
    StudentAttendanceApi.getStudentAttendanceByClassName,
    className,
    date,
    firebase
  );
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.FETCH_STUDENT_ATTENDANCE_BY_CLASSNAME_SUCCESSFUL,
        studentAttendance: data,
        attendanceChannel: chan,
      });
    }
  } finally {
    console.log("end student attendance channel");
  }
}

function* markStudentsCheckoutTime({
  selectedStudent,
  date,
  time,
  firebase,
  checkInOutStatus,
}) {
  try {
    for (let i = 0; i < selectedStudent.length; i++) {
      yield fork(
        StudentAttendanceApi.updateCheckOutTimeInCheckInOut,
        selectedStudent[i],
        date,
        time,
        firebase,
        checkInOutStatus
      );
      yield fork(
        StudentAttendanceApi.updateCheckOutTimeToStudentAttendance,
        selectedStudent[i],
        date,
        time,
        firebase,
        checkInOutStatus
      );
      yield fork(
        StudentAttendanceApi.updateCheckOutTimeInAttendanceUpdates,
        selectedStudent[i],
        date,
        time,
        firebase,
        checkInOutStatus
      );

      let dateFormat = moment(time).format(" DD[-]MM[-]YY");
      yield fork(
        StudentAttendanceApi.updateStudentLastAtdCheckout,
        selectedStudent[i].id,
        dateFormat,
        firebase
      );

      if (
        selectedStudent[i].fatherProfileId &&
        !firebase.schoolConfig.noNotificationAttendance
      ) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          selectedStudent[i].fatherProfileId,
          firebase
        );
        yield fork(
          NotificationApi.createAlertNotification,
          "Attendance",
          null,
          selectedStudent[i].fatherUuid ? selectedStudent[i].fatherUuid : null,
          selectedStudent[i].userName + " left school",
          alertNode,
          selectedStudent[i].ios_fatherUUid
            ? selectedStudent[i].ios_fatherUUid
            : null,
          selectedStudent[i].id,
          selectedStudent[i].fatherProfileId,
          firebase
        );

        if (
          selectedStudent[i].fatherUuid !== undefined ||
          selectedStudent[i].ios_fatherUuid !== undefined
        ) {
          yield fork(
            NotificationApi.sendPushNotification,
            "Attendance",
            null,
            selectedStudent[i].fatherUuid
              ? selectedStudent[i].fatherUuid
              : null,
            selectedStudent[i].userName + " left school",
            alertNode,
            selectedStudent[i].ios_fatherUuid
              ? selectedStudent[i].ios_fatherUuid
              : null,
            selectedStudent[i].id,
            selectedStudent[i].fatherProfileId,
            firebase
          );
        }
      }

      if (
        selectedStudent[i].motherProfileId &&
        !firebase.schoolConfig.noNotificationAttendance
      ) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          selectedStudent[i].motherProfileId,
          firebase
        );
        yield fork(
          NotificationApi.createAlertNotification,
          "Attendance",
          null,
          selectedStudent[i].motherUuid ? selectedStudent[i].motherUuid : null,
          selectedStudent[i].userName + " left school",
          alertNode,
          selectedStudent[i].ios_motherUuid
            ? selectedStudent[i].ios_motherUuid
            : null,
          selectedStudent[i].id,
          selectedStudent[i].motherProfileId,
          firebase
        );

        if (
          selectedStudent[i].motherUuid !== undefined ||
          selectedStudent[i].ios_motherUuid !== undefined
        ) {
          yield fork(
            NotificationApi.sendPushNotification,
            "Attendance",
            null,
            selectedStudent[i].motherUuid
              ? selectedStudent[i].motherUuid
              : null,
            selectedStudent[i].userName + " left school",
            alertNode,
            selectedStudent[i].ios_motherUuid
              ? selectedStudent[i].ios_motherUuid
              : null,
            selectedStudent[i].id,
            selectedStudent[i].motherProfileId,
            firebase
          );
        }
      }
    }
    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "attendance",
      date
    );
    yield fork(
      NotificationApi.sendStats,
      date,
      "updateDailyPostStats",
      firebase
    );
    yield fork(updateCheckoutStudentRecord, selectedStudent, firebase, date);
    yield fork(StudentAttendanceApi.lastUpdateTimestamp, date, firebase);

    yield put({
      type: actions.MARK_CHECKOUT_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to mark student checkout time", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* updateCheckoutStudentRecord(selectedStudents, firebase, date) {
  try {
    for (let index in selectedStudents) {
      let data = yield call(
        StudentAttendanceApi.getStudentAttendanceById,
        date,
        selectedStudents[index].id,
        firebase
      );
      if (data) {
        yield fork(
          StudentAttendanceApi.updatedAttendanceRecordApi,
          data,
          firebase,
          "updateAttendanceStudent"
        );
      }
    }
  } catch (err) {
    console.log(
      "failed to call and  update checked out student attendance record api",
      err
    );
    bugsnagClient.notify(err);
  }
}

function* requestStudentAttendance({ value, atdType, firebase }) {
  try {
    yield call(
      NotificationApi.sendAttendanceEmail,
      value.dateRange[0],
      value.dateRange[1],
      atdType,
      firebase
    );
    // if (response && response.status === 200) {
    notification("success", "Email sent");
    // } else {
    //     notification('error', "Failed to send email");
    // }
  } catch (err) {
    console.log("failed to send student attendance email", err);
    bugsnagClient.notify(err);
  }
}

function* fetchStudentMonthlyAttendance({ date, firebase }) {
  try {
    let atdRecord = [];
    let data = yield call(
      StudentAttendanceApi.getStudentAttendanceByMonth,
      date,
      firebase
    );
    if (data) {
      for (let [k, value] of data) {
        console.log(k);
        let data = {};
        let daysAttended = 0;
        let hoursAttended = 0;
        let daysUnattended = 0;
        let lateCheckInCount = 0;
        let lateCheckOutCount = 0;

        for (let index in value) {
          let eleVal = value[index];

          data.name = eleVal.userName;
          data.className = eleVal.className;
          data.classList = eleVal.classList ? eleVal.classList : [];
          data.gender = eleVal.gender;
          data.userId = eleVal.userId;
          if (
            !eleVal.absent &&
            eleVal.checkInEpoch &&
            eleVal.checkInEpoch > 0
          ) {
            daysAttended++;
          } else if (eleVal.absent) {
            daysUnattended++;
          }

          if (eleVal.late !== undefined && eleVal.late === true) {
            lateCheckInCount++;
          }

          if (
            eleVal.lateCheckout !== undefined &&
            eleVal.lateCheckout === true
          ) {
            lateCheckOutCount++;
          }

          if (eleVal.checkInEpoch && eleVal.checkOutEpoch && !eleVal.absent) {
            let a = moment(eleVal.checkOutEpoch);
            let b = moment(eleVal.checkInEpoch);
            let diff = moment.duration(a.diff(b)).as("hours");
            hoursAttended = hoursAttended + diff;
          }

          data.daysAttended = daysAttended;
          data.hoursAttended = hoursAttended;
          data.daysUnattended = daysUnattended;
          data.lateCheckInCount = lateCheckInCount;
          data.lateCheckOutCount = lateCheckOutCount;
        }
        atdRecord.push(data);
      }
    }
    yield put({
      type: actions.GET_MONTHLY_ATD_SUCCESS,
      studentMonthlyAttendance: atdRecord,
    });
  } catch (err) {
    console.log("failed to fetch student monthly attendance", err);
    bugsnagClient.notify(
      "failed to fetch student monthly attendance" + err.message
        ? err.message
        : err
    );
  }
}

function* markStudentsPending({ selectedStudent, date, firebase }) {
  try {
    for (let i = 0; i < selectedStudent.length; i++) {
      let data = yield call(
        StudentAttendanceApi.getStudentAttendanceById,
        date,
        selectedStudent[i].id,
        firebase
      );
      if (data) {
        yield fork(
          StudentAttendanceApi.updatedAttendanceRecordApi,
          data,
          firebase,
          "updateAttendanceStudent",
          "markPending"
        );
      }
      yield call(
        StudentAttendanceApi.markStudentPending,
        selectedStudent[i],
        date,
        firebase
      );
      yield fork(
        StudentAttendanceApi.removeStudentRecordFromCheckInOut,
        selectedStudent[i],
        date,
        firebase
      );

      yield fork(
        StudentAttendanceApi.updateStudentLastAtd,
        selectedStudent[i].id,
        undefined,
        firebase
      );

      yield fork(
        StudentAttendanceApi.updateStudentLastAtdCheckout,
        selectedStudent[i].id,
        undefined,
        firebase
      );
    }
    yield put({
      type: actions.MARK_STUDENT_PENDING_SUCCESS,
    });

    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "attendance",
      date
    );
    yield fork(
      NotificationApi.sendStats,
      date,
      "updateDailyPostStats",
      firebase
    );
  } catch (err) {
    console.log("failed to mark student absent", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* downloadStudentAttendanceDaywise({
  value,
  atdType,
  downloadType,
  firebase,
}) {
  console.log("value atd ----", value);
  let startDate = value.dateRange[0];
  let endDate = value.dateRange[1];

  if (downloadType === "checkInOut") {
    yield fork(downloadCheckInOutDaywise, startDate, endDate, firebase);
  } else {
    try {
      var report = [];
      var checkInRef = firebase.secondaryDb.ref(
        firebase.sbp + "/attendanceUpdates"
      );
      checkInRef.once("value", function(snapshot) {
        if (snapshot) {
          console.log(
            "No of days record are there in checkincheckout:" +
              snapshot.numChildren()
          );
          snapshot.forEach(function(dateSnap) {
            dateSnap.forEach(function(data) {
              console.log("student record", data.val());
              if (data.val() && data.val().id) {
                processCheckInForDate(
                  data,
                  report,
                  startDate,
                  endDate,
                  firebase.schoolConfig.timezone,
                  snapshot.key
                );
              }
            });
          });
          var p = processLeaveData(
            firebase.secondaryDb,
            report,
            startDate,
            endDate,
            firebase.schoolConfig.timezone,
            firebase
          );

          p.then((results) => {
            console.log("Final Report", report);
            const fields = [
              "Classname",
              "StudentName",
              "AttendanceDate",
              "CheckInTime",
              "CheckOutTime",
              "Attendance",
              "Temperature",
            ];

            const fileType =
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            const fileExtension = ".xlsx";
            const fileName =
              "StudentAttendance" +
              moment(startDate).format("DD-MMM-YY") +
              "-" +
              moment(endDate).format("DD-MMM-YY");
            var ws = XLSX.utils.json_to_sheet(report, { header: fields });

            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, {
              bookType: "xlsx",
              type: "array",
            });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);

            //   const workbook = new ExcelJS.Workbook();
            //   workbook.creator = 'Illumine';
            //   workbook.lastModifiedBy = 'Illumine';
            //   workbook.created = new Date();
            //   workbook.modified = new Date();
            //   // create a sheet with the first row and column frozen
            // const worksheet = workbook.addWorksheet('Attendance Sheet');
            // worksheet.addRows(report);
            // writeBufferToFile(workbook,fileType,fileName,fileExtension)
          });
        }
      });
    } catch (err) {
      console.log("failed to download monthly attendance", err);
      bugsnagClient.notify(err);
      yield put({
        type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
      });
    }
  }
}

function* downloadCheckInOutDaywise(startDate, endDate, firebase) {
  try {
    const fields = ["studentName", "className"];
    var report = [];
    for (
      var m = moment(startDate);
      m.diff(endDate, "days") <= 0;
      m.add(1, "days")
    ) {
      console.log(m.format("YYYY-MM-DD"));
      fields.push(m.format("YYYY-MM-DD") + "-IN");
      fields.push(m.format("YYYY-MM-DD") + "-OUT");
    }
    console.log(
      "Processing Attendance Report for Date range: " +
        startDate +
        " - " +
        endDate
    );
    var checkInRef = firebase.secondaryDb.ref(
      firebase.sbp + "/student-attendance"
    );
    checkInRef.once("value", function(snapshot) {
      if (snapshot) {
        console.log(
          "No of days record are there in checkincheckout:" +
            snapshot.numChildren()
        );
        //iterate for each student now
        snapshot.forEach(function(stuSnap) {
          processAttendanceForStudent(
            startDate,
            endDate,
            stuSnap,
            firebase.schoolConfig.timezone,
            report
          );
        });
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const fileName =
          "StudentCheckInOutAttendance_" +
          moment(startDate).format("DD-MMM-YY") +
          "-" +
          moment(endDate).format("DD-MMM-YY");

        var ws = XLSX.utils.json_to_sheet(report, { header: fields });

        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        //var p = processLeaveData(firebase.secondaryApp,report, startDate, endDate, timezone);

        // p.then(results =>{
        //     console.log("Final Report", report);
        //     const parser = new Json2csvParser(opts);
        //     const csv = parser.parse(report);
        //     console.log(csv);
        //     sendEmailCSVReport(email, ccEmail, csv);
        //     response.status(200).send();
        // })
      }
    });
  } catch (err) {
    console.log("failed to download monthly attendance", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function processAttendanceForStudent(
  startDate,
  endDate,
  studentAttendanceSnapList,
  timezone,
  report
) {
  var studentRow = {};
  studentAttendanceSnapList.forEach((monthSnap) => {
    //console.log("month key",monthSnap.key);
    monthSnap.forEach((attSnap) => {
      //  console.log("student",attSnap.numChildren());
      var attRecord = attSnap.val();
      if (attRecord && attRecord.id) {
        if (
          moment
            .tz(attRecord.date, timezone)
            .isBetween(
              moment.tz(startDate, timezone),
              moment.tz(endDate, timezone),
              "day",
              "[]"
            )
        ) {
          studentRow["studentName"] = attRecord.userName;
          studentRow["className"] = attRecord.className;
          var dateString = moment
            .tz(attRecord.date, timezone)
            .format("YYYY-MM-DD");
          studentRow[dateString + "-IN"] = attRecord.checkInTime
            ? attRecord.checkInTime
            : "";
          studentRow[dateString + "-OUT"] = attRecord.checkOutTime
            ? attRecord.checkOutTime
            : "";
          console.log("student");
        }
      }
    });
  });
  console.log("student row", studentRow);
  if (JSON.stringify(studentRow) != JSON.stringify({})) report.push(studentRow);
}

function processCheckInForDate(
  checkInRecordSnap,
  report,
  startDate,
  endDate,
  timezone,
  dateString
) {
  //  console.log("Checkin Date", dateString);
  var data1 = checkInRecordSnap.val();
  if (
    moment
      .tz(data1.date, timezone)
      .isBetween(
        moment.tz(startDate, timezone),
        moment.tz(endDate, timezone),
        "day",
        "[]"
      )
  ) {
    var checkIn = {};
    checkIn.Classname = data1.className;
    checkIn.StudentName = data1.userName;
    checkIn.AttendanceDate = moment
      .tz(data1.date, timezone)
      .format("DD/MM/YYYY");
    if (!data1.absent) {
      checkIn.CheckInTime = data1.checkInTime;
      checkIn.CheckOutTime = data1.checkOutTime;
      checkIn.Attendance = "Present";
      checkIn.Temperature = data1.temperature;
    } else {
      checkIn.Attendance = "Absent";
      checkIn.Temperature = "NA";
    }
    report.push(checkIn);
  }
  //console.log("Final Report", report);
}

function processLeaveData(
  secondaryDb,
  report,
  startDate,
  endDate,
  timezone,
  firebase
) {
  return secondaryDb
    .ref(firebase.sbp + "/leaves")
    .once("value")
    .then((snap) => {
      if (snap.val()) {
        console.log("got leaves data", snap.numChildren());
        snap.forEach((sn1) => {
          var record = sn1.val();
          if (
            moment
              .tz(record.startDate, timezone)
              .isBetween(
                moment.tz(startDate, timezone),
                moment.tz(endDate, timezone),
                "days",
                "[]"
              )
          ) {
            var diffDays = moment
              .tz(record.startDate, timezone)
              .diff(moment.tz(record.endDate, timezone), "days");
            for (var i = 0; i <= diffDays; i++) {
              var leaveDate = moment
                .tz(record.startDate, timezone)
                .add(i, "day");
              if (leaveDate.isAfter(moment.tz(endDate, timezone))) {
                console.log("ignore as date is not indate range");
              } else {
                var checkIn = {};
                checkIn.Classname = record.classRoom;
                checkIn.StudentName = record.student;
                checkIn.AttendanceDate = leaveDate.format("DD/MM/YYYY");
                checkIn.Attendance = "Leave";
                checkIn.Temperature = "NA";
                report.push(checkIn);
                console.log("adding leave", checkIn);
              }
            }
          } else {
            console.log(
              "leave is not between start & end",
              moment.tz(startDate, timezone).format("DD/MM/YY"),
              moment.tz(endDate, timezone).format("DD/MM/YY"),
              moment.tz(record.startDate, timezone).format("DD/MM/YY")
            );
          }
        });
      }
    });
}

async function writeBufferToFile(workbook, fileType, fileName, fileExtension) {
  const excelBuffer1 = await workbook.xlsx.writeBuffer();
  const data1 = new Blob([excelBuffer1], { type: fileType });
  FileSaver.saveAs(data1, "new" + fileName + fileExtension);
}

function* downloadAttedanceMonthly({ record, firebase }) {
  try {
    const fields = [
      "name",
      "classroom",
      "present",
      "absent",
      "lateCheckIn",
      "lateCheckOut",
      "hoursAttended",
    ];
    let report = [];
    for (let index in record) {
      let singleRecord = record[index];
      var row = {};
      row.name = singleRecord.name;
      row.classroom = singleRecord.className;
      row.present = singleRecord.daysAttended;
      row.absent = singleRecord.daysUnattended;
      row.lateCheckIn = singleRecord.lateCheckInCount;
      row.lateCheckOut = singleRecord.lateCheckOutCount;
      row.hoursAttended = singleRecord.hoursAttended;
      report.push(row);
    }

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "StudentMonthlyAttendance";

    var ws = XLSX.utils.json_to_sheet(report, { header: fields });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  } catch (err) {
    console.log("failed to download monthly attendance", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.FETCH_STUDENT_ATTENDANCE, fetchStudentAttendance),
    yield takeLatest(actions.FETCH_ALL_STUDENT, getStudents),
    yield takeLatest(actions.FETCH_ALL_CLASSROOMS, getClassrooms),
    yield takeLatest(actions.MARK_PRESENT, markStudentsPresent),
    yield takeLatest(actions.MARK_ABSENT, markStudentsAbsent),
    yield takeLatest(actions.MARK_CHECKOUT, markStudentsCheckoutTime),
    yield takeLatest(
      actions.FETCH_STUDENT_BY_CLASSNAME,
      getStudentByClassroomName
    ),
    yield takeLatest(
      actions.FETCH_STUDENT_ATTENDANCE_BY_CLASSNAME,
      fetchStudentAttendanceByClassName
    ),
    yield takeLatest(
      actions.EMAIL_STUDENT_ATTENDANCE,
      downloadStudentAttendanceDaywise
      // requestStudentAttendance
    ),
    yield takeLatest(actions.GET_MONTHLY_ATD, fetchStudentMonthlyAttendance),
    yield takeLatest(actions.MARK_STUDENT_PENDING, markStudentsPending),
    yield takeLatest(
      actions.DOWNLOAD_MONTHLY_ATTENDANCE,
      downloadAttedanceMonthly
      // downloadStudentAttendanceDaywise
    ),
  ]);
}
