import actions from "./actions";
const initState = {
  // milestones: [],
  milestones: new Map(),
  developmentAreas: [],
  developmentAreasMap: new Map(),
  lessons: [],
  isLoading: false,
  labels: [],
  categories: [],
  classrooms: [],
  weeklyPlan: [],
  students: [],
  weeklyPlanLessonChannel: undefined,
  saveMilestoneDomainSuccessful: false,
  saveMilestoneSuccessful: false,
  addLabelSuccessful: false,
  updateMilestoneDomainSuccessful: false,
  updateMilestoneSuccessful: false,
  updateLessonSuccessful: false,
  error: false,
  addCategorySuccessful: false,
  addLessonSuccessful: false,
  addWeeklyPlanSuccessful: false,
  learningMilestoneChannel: undefined,
  learningLessonChannel: undefined,
  learningOperationType: "",
  labelChannel: undefined,
  feedbackMessage: undefined,
  uploadStatus: undefined,
  assessmentProgressParams: [],
  shareHistoryMap: new Map(),
  planComment: new Map(),
  planSubmission: new Map(),
};
export default function learningReducer(
  state = initState,
  { type, ...action }
) {
  switch (type) {
    case actions.LIST_MILESTONES:
      return {
        ...state,
      };
    case actions.LIST_MILESTONES_SUCCESSFUL:
      return {
        ...state,
        milestones: action.milestones,
        learningMilestoneChannel: action.learningMilestoneChannel,
      };

    case actions.LIST_DEV_AREA:
      return {
        ...state,
      };
    case actions.LIST_DEV_AREA_SUCCESS:
      return {
        ...state,
        developmentAreas: action.developmentAreas,
        developmentAreasMap: action.developmentAreasMap,
      };

    case actions.LIST_LESSONS:
      return {
        ...state,
        isLoading: true,
        learningOperationType: "",
        uploadStatus: undefined,
      };
    case actions.LIST_LESSONS_SUCCESSFUL:
      return {
        ...state,
        lessons: action.lessons,
        learningLessonChannel: action.learningLessonChannel,
        isLoading: false,
        learningOperationType: action.learningOperationType,
      };
    case actions.SAVE_MILESTONE_DOMAIN:
      return {
        ...state,
        isLoading: true,
        saveMilestoneDomainSuccessful: false,
      };
    case actions.SAVE_MILESTONE_DOMAIN_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        saveMilestoneDomainSuccessful: true,
        learningOperationType: "SAVE_MILESTONE_DOMAIN",
      };

    case actions.ADD_SUB_DOMAIN:
      return {
        ...state,
        isLoading: true,
      };
    case actions.ADD_SUB_DOMAIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "SAVE_MILESTONE_SUB_DOMAIN",
      };

    case actions.UPDATE_SUB_DOMAIN:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_SUB_DOMAIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "SAVE_MILESTONE_SUB_DOMAIN",
      };

    case actions.SAVE_MILESTONE:
      return {
        ...state,
        isLoading: true,
        saveMilestoneSuccessful: false,
      };
    case actions.SAVE_MILESTONE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        saveMilestoneSuccessful: true,
        learningOperationType: "SAVE_MILESTONE",
      };
    case actions.ADD_LABEL:
      return {
        ...state,
        isLoading: true,
        addLabelSuccessful: false,
      };
    case actions.ADD_LABEL_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        addLabelSuccessful: true,
        learningOperationType: "ADD_LABEL",
      };
    case actions.GET_LABEL:
      return {
        ...state,
      };
    case actions.GET_LABEL_SUCCESSFUL:
      return {
        ...state,
        labels: action.labels,
        labelChannel: action.labelChannel,
      };
    case actions.UPDATE_MILESTONE_DOMAIN:
      return {
        ...state,
        isLoading: true,
        updateMilestoneDomainSuccessful: false,
      };
    case actions.UPDATE_MILESTONE_DOMAIN_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        updateMilestoneDomainSuccessful: true,
        learningOperationType: "UPDATE_MILESTONE_DOMAIN",
      };
    case actions.UPDATE_MILESTONE:
      return {
        ...state,
        isLoading: true,
        updateMilestoneSuccessful: false,
      };
    case actions.UPDATE_MILESTONE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        updateMilestoneSuccessful: true,
        learningOperationType: "UPDATE_MILESTONE",
      };
    case actions.LEARNING_REQUEST_FAILED:
      return {
        ...state,
        error: true,
        isLoading: false,
        errorMessage: action.errorMessage,
        uploadStatus: "failed",
      };
    case actions.ADD_CATEGORY:
      return {
        ...state,
        isLoading: true,
        addCategorySuccessful: false,
      };
    case actions.ADD_CATEGORY_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        addCategorySuccessful: true,
      };
    case actions.GET_CATEGORY:
      return {
        ...state,
      };
    case actions.GET_CATEGORY_SUCCESSFUL:
      return {
        ...state,
        categories: action.categories,
      };
    case actions.GET_LEARNING_CLASSROOM:
      return {
        ...state,
      };
    case actions.GET_LEARNING_CLASSROOM_SUCCESSFUL:
      return {
        ...state,
        classrooms: action.classrooms,
      };
    case actions.ADD_LESSON:
      return {
        ...state,
        isLoading: true,
        addLessonSuccessful: false,
      };
    case actions.ADD_LESSON_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        addLessonSuccessful: true,
        learningOperationType: "ADD_LESSON",
      };

    case actions.ADD_LESSON_TO_ALL_CENTERS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.ADD_LESSON_TO_ALL_CENTERS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "ADD_LESSON_TO_ALL_CENTER",
      };
    case actions.UPDATE_LESSON:
      return {
        ...state,
        isLoading: true,
        updateLessonSuccessful: false,
      };
    case actions.UPDATE_LESSON_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        updateLessonSuccessful: true,
        learningOperationType: "UPDATE_LESSON",
      };

    case actions.UPDATE_LESSON_TO_ALL_CENTERS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_LESSON_TO_ALL_CENTERS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "UPDATE_LESSON_TO_ALL_CENTER",
      };

    case actions.ADD_WEEKLY_PLAN:
      return {
        ...state,
        isLoading: true,
        addWeeklyPlanSuccessful: false,
      };
    case actions.ADD_WEEKLY_PLAN_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        addWeeklyPlanSuccessful: true,
        learningOperationType: "ADD_WEEKLY_PLAN",
      };
    case actions.GET_WEEKLY_PLAN:
      return {
        ...state,
        isLoading: true,
        learningOperationType: "",
        weeklyPlan: [],
      };
    case actions.GET_WEEKLY_PLAN_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        weeklyPlan: action.weeklyPlan,
        weeklyPlanLessonChannel: action.weeklyPlanLessonChannel,
        learningOperationType: "WEEKLY_PLAN_FETCHED",
      };
    case actions.DELETE_PLAN:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_PLAN_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
      };
    case actions.DELETE_LESSON_RECORD:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_LESSON_RECORD_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "DELETE_LESSON_TO_SINGLE_CENTER",
      };

    case actions.DELETE_LESSON_RECORD_FOR_ALL_CENTER:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_LESSON_RECORD_FOR_ALL_CENTER_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "DELETE_LESSON_TO_ALL_CENTER",
      };
    case actions.DELETE_SINGLE_MILESTONE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_SINGLE_MILESTONE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
      };
    case actions.DELETE_SINGLE_DOMAIN:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_SINGLE_DOMAIN_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
      };

    case actions.RESET_LEARNING_OPERATION_TYPE:
      return {
        ...state,
        isLoading: false,
        error: false,
        learningOperationType: "",
        feedbackMessage: undefined,
        errorMessage: undefined,
      };

    case actions.GET_STUDENT_FOR_LESSON:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_STUDENT_FOR_LESSON_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        students: action.students,
      };

    case actions.SHARE_LESSON:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SHARE_LESSON_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "LESSON_SHARED",
      };

    case actions.UPLOAD_LESSON_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPLOAD_LESSON_DATA_SUCCESSFUl:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "LESSON_UPLOADED",
        feedbackMessage: action.feedbackMessage,
      };

    case actions.UPLOAD_START:
      return {
        ...state,
        uploadStatus: "started",
      };

    case actions.UPLOAD_FINISH:
      return {
        ...state,
        uploadStatus: "finished",
      };

    case actions.UPLOAD_FAILED:
      return {
        ...state,
        uploadStatus: "failed",
      };

    case actions.UPLOAD_RESET:
      return {
        ...state,
        uploadStatus: undefined,
      };

    case actions.UPDATE_CATEGORY:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "CATEGORY_UPDATE",
      };

    case actions.DELETE_CATEGORY:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "CATEGORY_DELETE",
      };

    case actions.GET_ASSESSMENT_PROGRESS_PARAM:
      return {
        ...state,
      };
    case actions.GET_ASSESSMENT_PROGRESS_PARAM_SUCCESS:
      return {
        ...state,
        assessmentProgressParams: action.assessmentProgressParams,
      };

    case actions.SAVE_ASS_PROGRESS_PARAM:
      return {
        ...state,
      };

    case actions.SAVE_ASS_PROGRESS_PARAM_SUCCESS:
      return {
        ...state,
        learningOperationType: "PROGRESS_PARAM_ADD",
      };

    case actions.DELETE_ASS_PROGRESS_PARAM:
      return {
        ...state,
      };

    case actions.DELETE_ASS_PROGRESS_PARAM_SUCCESS:
      return {
        ...state,
        learningOperationType: "PROGRESS_PARAM_DELETE",
      };

    case actions.DELETE_LABEL:
      return {
        ...state,
      };

    case actions.DELETE_LABEL_SUCCESS:
      return {
        ...state,
      };

    case actions.FETCH_COMPLETE_SHARE_HISTORY:
      return {
        ...state,
      };

    case actions.FETCH_COMPLETE_SHARE_HISTORY_SUCCESS:
      return {
        ...state,
        shareHistoryMap: action.shareHistoryMap,
      };

    case actions.UPDATE_SHARE_STATUS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_SHARE_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "UPDATE_SHARE_STATUS",
      };

    case actions.GET_PLAN_EXTRA_DETAIL:
      return {
        ...state,
      };

    case actions.GET_PLAN_COMMENT_SUCCESS:
      return {
        ...state,
        planComment: action.planComment,
      };

    case actions.GET_PLAN_SUBMISSION_SUCCESS:
      return {
        ...state,
        planSubmission: action.planSubmission,
      };

    case actions.COPY_LESSON:
      return {
        ...state,
        isLoading: true,
      };
    case actions.COPY_LESSON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "COPY_LESSON_SUCCESS",
      };

    case actions.REPLACE_STUDENT:
      return {
        ...state,
        isLoading: true,
        students: [],
      };
    case actions.REPLACE_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        students: action.students,
      };

    default:
      return state;
  }
}
