import { all, takeEvery, put, call, take, fork } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { NewsletterApi } from "../../firestore-api/newsletter";
import { NotificationApi } from "../../firestore-api/notification";
import { ActivityApi } from "../../firestore-api/activity";
import { StudentApi } from "../../firestore-api/student";
import { UserSettingApi } from "../../firestore-api/userSetting";
import bugsnagClient from "@bugsnag/js";

export function* getAllDraftNewsletter({ firebase }) {
  const chan = yield call(NewsletterApi.getAllDraftNewsletter, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_ALL_DRAFT_NEWSLETTER_SUCCESS,
        allDraftNewspaper: data,
        newsletterDraftChannel: chan,
      });
    }
  } finally {
    console.log("end draft newsletter channel");
  }
}

export function* getAllNewsTemplates({ firebase }) {
  const chan = yield call(NewsletterApi.getAllTemplates, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_ALL_NS_TEMPLATES_SUCCESS,
        allTemplates: data,
        templateChannel: chan,
      });
    }
  } finally {
    console.log("end draft newsletter channel");
  }
}

export function* saveNewsletter({ newsletter, firebase }) {
  try {
    var uniqueNodeId = yield call(
      NewsletterApi.createNodeIdForNewsletterDraft,
      firebase
    );
    if (uniqueNodeId) {
      yield call(
        NewsletterApi.saveNewsletter,
        newsletter,
        uniqueNodeId,
        firebase
      );
    }
    yield put({
      type: actions.NEWSLETTER_SAVE_REQUEST_SUCCESS,
    });
  } catch (error) {
    console.log(error);
    bugsnagClient.notify(
      "Failed to save newsletter ---->>>>" + error.message
        ? error.message
        : error
    );
    yield put({
      type: actions.NEWSLETTER_REQUEST_FAILED,
      errorInfo: "Request failed",
    });
  }
}

export function* updateNewsletter({ newsletter, firebase, uploadFileUrl }) {
  try {
    yield call(
      NewsletterApi.updateNewsletter,
      newsletter,
      firebase,
      uploadFileUrl
    );
    yield put({
      type: actions.NEWSLETTER_UPDATE_REQUEST_SUCCESS,
    });
  } catch (error) {
    console.log(error);
    bugsnagClient.notify(
      "Failed to update newsletter ---->>>>" + error.message
        ? error.message
        : error
    );
    yield put({
      type: actions.NEWSLETTER_REQUEST_FAILED,
      errorInfo: "Request failed",
    });
  }
}

export function* saveTemplate({ newsletter, firebase }) {
  try {
    var uniqueNodeId = yield call(
      NewsletterApi.createNodeIdForNewsletterTemplate,
      firebase
    );
    yield call(NewsletterApi.saveTemplate, newsletter, uniqueNodeId, firebase);
    yield put({
      type: actions.NS_TEMPLATE_SAVE_REQUEST_SUCCESS,
    });
  } catch (error) {
    console.log(error);
    bugsnagClient.notify(
      "Failed to save template ---->>>>" + error.message ? error.message : error
    );
    yield put({
      type: actions.NEWSLETTER_REQUEST_FAILED,
      errorInfo: "Request failed",
    });
  }
}

export function* updateTemplate({ newsletter, firebase, uploadFileUrl }) {
  try {
    yield call(
      NewsletterApi.updateTemplate,
      newsletter,
      firebase,
      uploadFileUrl
    );
    yield put({
      type: actions.NS_TEMPLATE_UPDATE_REQUEST_SUCCESS,
    });
  } catch (error) {
    console.log(error);
    bugsnagClient.notify(
      "Failed to update template ---->>>>" + error.message
        ? error.message
        : error
    );
    yield put({
      type: actions.NEWSLETTER_REQUEST_FAILED,
      errorInfo: "Request failed",
    });
  }
}

export function* getStudentList({ firebase }) {
  try {
    // let data = yield call(AssessmentApi.getAllStudents, firebase);
    let data = JSON.parse(localStorage.getItem("studentList"));
    if (data) {
      yield put({
        type: actions.GET_STUDENT_DATA_SUCCESSFUL,
        studentList: data,
        autoCompleteClassroom: [],
      });
    }
  } catch (error) {
    console.log("failed to fetch students for newsletter", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.NEWSLETTER_REQUEST_FAILED,
    });
  }
}

export function* getStudentListByCenterName({ centerName, option }) {}

export function* getStudentListByRoomName({ roomName, option }) {}

export function* getSearchedStudent({ studentName }) {}

export function* sendNewsletter({ newsletter, firebase }) {
  let parentEmails = [];
  try {
    var classrooms = newsletter.selectedClassrooms;
    let groups = newsletter.selectedGroups;

    var activityId = yield call(
      NewsletterApi.createNodeIdForNewActivity,
      firebase
    );

    let students = [];
    let studentIds = [];
    let allStudents = JSON.parse(localStorage.getItem("studentList"));
    for (let i = 0; i < classrooms.length; i++) {
      let student = allStudents.filter((s) => {
        return s.classList.includes(classrooms[i].className) && !s.deactivated;
      });

      if (groups && groups.length > 0) {
        for (let index in student) {
          let studentGroup = student[index].tags
            ? student[index].tags
            : undefined;

          if (studentGroup) {
            for (let k in groups) {
              let val = studentGroup.filter((g) => {
                return g.id === groups[k].id;
              });

              if (val && val.length > 0) {
                students.push(student[index]);
                studentIds.push(student[index].id);
                break;
              }
            }
          }
        }
      } else {
        if (student && student.length > 0) {
          students = [...students, ...student];
          for (let index in student) {
            studentIds.push(student[index].id);
          }
        }
      }
    }

    console.log("students in class  ->", students);
    console.log("student ids -------", studentIds);

    let names = [];
    if (students.length > 0) {
      yield call(
        NewsletterApi.addActivity,
        newsletter,
        activityId,
        firebase,
        studentIds
      );
      for (let j = 0; j < students.length; j++) {
        let x = students[j];
        if (x.fatherEmail) {
          parentEmails.push(x.fatherEmail);
        }
        if (x.motherEmail) {
          parentEmails.push(x.motherEmail);
        }

        names.push({
          className: x.classroomName,
          classList: x.classList ? x.classList : [],
          date: new Date().getTime(),
          fatherAndroidId: x.fatherUUid ? x.fatherUUid : null,
          fatherId: x.fatherProfileId ? x.fatherProfileId : null,
          fatherIosId: x.ios_fatherUUid ? x.ios_fatherUUid : null,
          gender: x.gender,
          motherId: x.motherProfileId ? x.motherProfileId : null,
          parentName: firebase.teacher.name,
          profileImageUrl: x.profileImageUrl ? x.profileImageUrl : null,
          studentId: x.id,
          studentName: x.name,
        });

        let timeline = yield call(
          NewsletterApi.getTimelineOfStudent,
          x.id,
          firebase
        );
        if (timeline) {
          console.log("timeline activity", timeline);
          let newActivityIds =
            timeline.activityIds && timeline.activityIds.length > 0
              ? timeline.activityIds
              : [];
          newActivityIds.push(activityId);

          let newTimelineData = {
            activityIds: newActivityIds,
            date: new Date().getTime(),
            inverseDate: -new Date().getTime(),
          };
          yield call(
            NewsletterApi.updateTimelineActivityOfStudent,
            x.id,
            newTimelineData,
            firebase
          );
        }

        let newsletterUniqueNodeId = yield call(
          NewsletterApi.createNewsletterNodeId,
          x.id,
          firebase
        );

        yield call(
          NewsletterApi.updateNewsletterNode,
          x.id,
          activityId,
          newsletterUniqueNodeId,
          firebase
        );

        if (x.fatherProfileId) {
          let alertNode = yield call(
            NotificationApi.createAlertReferenceNode,
            x.fatherProfileId,
            firebase
          );
          yield fork(
            NotificationApi.createAlertNotification,
            "Newsletter",
            activityId,
            x.fatherUUid ? x.fatherUUid : null,
            "Newsletter" + " activity added for " + x.name,
            alertNode,
            x.ios_fatherUUid ? x.ios_fatherUUid : null,
            x.id,
            x.fatherProfileId,
            firebase
          );

          yield fork(
            NotificationApi.sendPushNotification,
            "Newsletter",
            activityId,
            x.fatherUUid ? x.fatherUUid : null,
            "Newsletter" + " activity added for " + x.name,
            alertNode,
            x.ios_fatherUUid ? x.ios_fatherUUid : null,
            x.id,
            x.fatherProfileId,
            firebase
          );
        }

        if (x.motherProfileId) {
          let alertNode = yield call(
            NotificationApi.createAlertReferenceNode,
            x.motherProfileId,
            firebase
          );
          yield fork(
            NotificationApi.createAlertNotification,
            "Newsletter",
            activityId,
            x.motherUUid ? x.motherUUid : null,
            "Newsletter" + " activity added for " + x.name,
            alertNode,
            x.ios_motherUUid ? x.ios_motherUUid : null,
            x.id,
            x.motherProfileId,
            firebase
          );

          yield fork(
            NotificationApi.sendPushNotification,
            "Newsletter",
            activityId,
            x.motherUUid ? x.motherUUid : null,
            "Newsletter" + " activity added for " + x.name,
            alertNode,
            x.ios_motherUUid ? x.ios_motherUUid : null,
            x.id,
            x.motherProfileId,
            firebase
          );
        }
      }
      let status = yield call(
        NewsletterApi.sendNewsletterEmails,
        newsletter,
        parentEmails,
        firebase
      );
      console.log("status of newsletter letter email delivery", status);

      yield fork(ActivityApi.addUpdatedStudent, activityId, names, firebase);
      yield put({
        type: actions.SEND_NEWSLETTER_SUCCESS,
      });
    } else {
      yield put({
        type: actions.NEWSLETTER_REQUEST_FAILED,
        errorInfo: "No students found",
      });
    }
  } catch (err) {
    console.log(err);
    bugsnagClient.notify(
      "Failed to save newsletter ---->>>>" + err.message ? err.message : err
    );
    yield put({
      type: actions.NEWSLETTER_REQUEST_FAILED,
      errorInfo: "Request failed",
    });
  }
}

function* uploadImageToStorage({ fileUrl, firebase, newsletterType }) {
  try {
    let url = yield call(
      NewsletterApi.uploadImageAttachment,
      fileUrl,
      firebase
    );
    if (url) {
      yield put({
        type: actions.FILE_UPLOAD_COMPLETED,
        uploadFileUrl: url,
        newsletterType: newsletterType,
      });
    }
  } catch (error) {
    console.log("failed to upload newsletter image attachment", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.NEWSLETTER_REQUEST_FAILED,
      errorInfo: error,
    });
  }
}

function* getAllClassrooms({ firebase }) {
  try {
    //const data = yield call(TeacherApi.getClassroomsForTeacher, firebase);
    let data = JSON.parse(localStorage.getItem("classList"));
    if (data) {
      yield put({
        type: actions.FETCH_CLASSROOM_SUCCESSFUL,
        classrooms: data,
      });
    }
  } catch (error) {
    console.log("failed to fetch all classroom", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.NEWSLETTER_REQUEST_FAILED,
    });
  }
}

function* sendNewsletterAllCenter({ newsletter, firebase, filteredBranch }) {
  let parentEmails = [];
  try {
    let newBranches = firebase.teacher.newBranches;
    let centerClasses = JSON.parse(localStorage.getItem("classmap"));
    let branches = filteredBranch;
    let tempBranchMap = new Map();
    for (let i in branches) {
      let branchClassName = branches[i];
      if (centerClasses[branchClassName]) {
        let allClasses = centerClasses[branchClassName];
        tempBranchMap.set(branchClassName, allClasses);
      } else {
        let singleClass = branchClassName.split("*")[0];
        let branchName = branchClassName.split("*")[1];
        if (tempBranchMap.has(branchName)) {
          let allClasses = tempBranchMap.get(branchName);
          allClasses.push({ className: singleClass });
          tempBranchMap.set(branchName, allClasses);
        } else {
          let allClasses = [];
          allClasses.push({ className: singleClass });
          tempBranchMap.set(branchName, allClasses);
        }
      }
    }

    for (let [key, val] of tempBranchMap) {
      let bPath = key;
      let dbName;
      let databaseName = newBranches.filter((nb) => {
        return nb.name === bPath;
      });

      if (databaseName && databaseName.length > 0) {
        dbName = databaseName[0].dbName;
      }
      console.log("dbName --------", dbName);

      newsletter.selectedClassrooms = val;
      var classrooms = val;
      var activityId = yield call(
        NewsletterApi.createNodeIdForNewActivity,
        firebase,
        bPath
      );

      let students = [];
      let studentIds = [];
      for (let i = 0; i < classrooms.length; i++) {
        let student = yield call(
          StudentApi.getStudentByClassroomName,
          classrooms[i].className,
          firebase,
          bPath
        );

        if (student && student.length > 0) {
          students = [...students, ...student];

          for (let index in student) {
            studentIds.push(student[index].id);
          }
        }
      }

      console.log("students in class  ->", students);
      console.log("student ids -------", studentIds);

      let names = [];
      if (students.length > 0) {
        yield call(
          NewsletterApi.addActivity,
          newsletter,
          activityId,
          firebase,
          studentIds,
          bPath
        );
        for (let j = 0; j < students.length; j++) {
          let x = students[j];
          if (x.fatherEmail) {
            parentEmails.push(x.fatherEmail);
          }
          if (x.motherEmail) {
            parentEmails.push(x.motherEmail);
          }

          names.push({
            className: x.classroomName,
            classList: x.classList ? x.classList : [],
            date: new Date().getTime(),
            fatherAndroidId: x.fatherUUid ? x.fatherUUid : null,
            fatherId: x.fatherProfileId ? x.fatherProfileId : null,
            fatherIosId: x.ios_fatherUUid ? x.ios_fatherUUid : null,
            gender: x.gender,
            motherId: x.motherProfileId ? x.motherProfileId : null,
            parentName: firebase.teacher.name,
            profileImageUrl: x.profileImageUrl ? x.profileImageUrl : null,
            studentId: x.id,
            studentName: x.name,
          });

          let timeline = yield call(
            NewsletterApi.getTimelineOfStudent,
            x.id,
            firebase,
            bPath
          );
          if (timeline) {
            console.log("timeline activity", timeline);
            let newActivityIds =
              timeline.activityIds && timeline.activityIds.length > 0
                ? timeline.activityIds
                : [];
            newActivityIds.push(activityId);

            let newTimelineData = {
              activityIds: newActivityIds,
              date: new Date().getTime(),
              inverseDate: -new Date().getTime(),
            };
            yield call(
              NewsletterApi.updateTimelineActivityOfStudent,
              x.id,
              newTimelineData,
              firebase,
              bPath
            );
          }
          let newsletterUniqueNodeId = yield call(
            NewsletterApi.createNewsletterNodeId,
            x.id,
            firebase,
            bPath
          );
          yield call(
            NewsletterApi.updateNewsletterNode,
            x.id,
            activityId,
            newsletterUniqueNodeId,
            firebase,
            bPath
          );

          if (x.fatherProfileId) {
            let alertNode = yield call(
              NotificationApi.createAlertReferenceNode,
              x.fatherProfileId,
              firebase,
              bPath
            );
            yield fork(
              NotificationApi.createAlertNotification,
              "Newsletter",
              activityId,
              x.fatherUUid ? x.fatherUUid : null,
              "Newsletter" + " activity added for " + x.name,
              alertNode,
              x.ios_fatherUUid ? x.ios_fatherUUid : null,
              x.id,
              x.fatherProfileId,
              firebase,
              bPath
            );

            yield fork(
              NotificationApi.sendPushNotification,
              "Newsletter",
              activityId,
              x.fatherUUid ? x.fatherUUid : null,
              "Newsletter" + " activity added for " + x.name,
              alertNode,
              x.ios_fatherUUid ? x.ios_fatherUUid : null,
              x.id,
              x.fatherProfileId,
              firebase,
              bPath
            );
          }

          if (x.motherProfileId) {
            let alertNode = yield call(
              NotificationApi.createAlertReferenceNode,
              x.motherProfileId,
              firebase,
              bPath
            );
            yield fork(
              NotificationApi.createAlertNotification,
              "Newsletter",
              activityId,
              x.motherUUid ? x.motherUUid : null,
              "Newsletter" + " activity added for " + x.name,
              alertNode,
              x.ios_motherUUid ? x.ios_motherUUid : null,
              x.id,
              x.motherProfileId,
              firebase,
              bPath
            );

            yield fork(
              NotificationApi.sendPushNotification,
              "Newsletter",
              activityId,
              x.motherUUid ? x.motherUUid : null,
              "Newsletter" + " activity added for " + x.name,
              alertNode,
              x.ios_motherUUid ? x.ios_motherUUid : null,
              x.id,
              x.motherProfileId,
              firebase,
              bPath
            );
          }
        }

        let selectedDbSchoolName;
        if (dbName) {
          let data = yield call(
            UserSettingApi.getSchoolConfiguration,
            firebase,
            dbName
          );
          if (data) {
            selectedDbSchoolName = data.schoolName;
          }
        }

        let status = yield call(
          NewsletterApi.sendNewsletterEmails,
          newsletter,
          parentEmails,
          firebase,
          selectedDbSchoolName
        );
        console.log("status of newsletter letter email delivery", status);
        yield fork(
          ActivityApi.addUpdatedStudent,
          activityId,
          names,
          firebase,
          bPath
        );
      }
    }
    yield put({
      type: actions.SEND_NEWSLETTER_SUCCESS,
    });
  } catch (err) {
    console.log(err);
    bugsnagClient.notify(
      "Failed to save newsletter ---->>>>" + err.message ? err.message : err
    );
    yield put({
      type: actions.NEWSLETTER_REQUEST_FAILED,
      errorInfo: "Request failed",
    });
  }
}

export function* getAllSentNewsletter({ firebase }) {
  const chan = yield call(NewsletterApi.getSentNewsletter, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      var activities = data;
      activities.sort(function(a, b) {
        var dateA = a.date.time,
          dateB = b.date.time;
        return dateB - dateA;
      });
      yield put({
        type: actions.GET_ALL_SENT_NEWSLETTER_SUCCESS,
        allSentNewspaper: activities,
        sentNewsletterChannel: chan,
      });
    }
  } finally {
    console.log("end tag channel");
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_ALL_DRAFT_NEWSLETTER, getAllDraftNewsletter),
    yield takeEvery(actions.GET_ALL_NS_TEMPLATES, getAllNewsTemplates),
    yield takeEvery(actions.SAVE_NEWSLETTER, saveNewsletter),
    yield takeEvery(actions.UPDATE_NEWSLETTER, updateNewsletter),
    yield takeEvery(actions.SAVE_NS_TEMPLATE, saveTemplate),
    yield takeEvery(actions.UPDATE_NS_TEMPLATE, updateTemplate),
    yield takeEvery(actions.GET_STUDENT_BY_ROOM_NAME, getStudentListByRoomName),
    yield takeEvery(
      actions.GET_STUDENT_BY_CENTER_NAME,
      getStudentListByCenterName
    ),
    yield takeEvery(actions.GET_SEARCHED_STUDENT, getSearchedStudent),
    yield takeEvery(actions.GET_STUDENT_DATA, getStudentList),
    yield takeEvery(actions.SEND_NEWSLETTER, sendNewsletter),
    yield takeEvery(actions.FILE_UPLOAD_START, uploadImageToStorage),
    yield takeEvery(actions.FETCH_CLASSROOM, getAllClassrooms),
    yield takeEvery(
      actions.SEND_NEWSLETTER_ALL_CENTER,
      sendNewsletterAllCenter
    ),
    yield takeEvery(actions.GET_ALL_SENT_NEWSLETTER, getAllSentNewsletter),
  ]);
}
