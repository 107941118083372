const actions = {
  DELETE_STUDENT_FROM_PROGRAM: "DELETE_STUDENT_FROM_PROGRAM",
  DELETE_STUDENT_FROM_PROGRAM_SUCCESSFFUL:
    "DELETE_STUDENT_FROM_PROGRAM_SUCCESSFFUL",
  DETAILED_PROGRAM_FETCH_STUDENT: "DETAILED_PROGRAM_FETCH_STUDENT",
  DETAILED_PROGRAM_FETCH_STUDENT_SUCCESSFFUL:
    "DETAILED_PROGRAM_FETCH_STUDENT_SUCCESSFFUL",
  ASSIGN_STUDENT_FROM_DETAILED_PROGRAM: "ASSIGN_STUDENT_FROM_DETAILED_PROGRAM",
  ASSIGN_STUDENT_FROM_DETAILED_PROGRAM_SUCCESSFUL:
    "ASSIGN_STUDENT_FROM_DETAILED_PROGRAM_SUCCESSFUL",
  DETAILED_PROGRAM_FETCH_FEE_PLAN: "DETAILED_PROGRAM_FETCH_FEE_PLAN",
  DETAILED_PROGRAM_FETCH_FEE_PLAN_SUCCESSFUL:
    "DETAILED_PROGRAM_FETCH_FEE_PLAN_SUCCESSFUL",
  ADD_FEE_PLAN_FROM_DETAILED_VIEW: "ADD_FEE_PLAN_FROM_DETAILED_VIEW",
  ADD_FEE_PLAN_FROM_DETAILED_VIEW_SUCCESSFUL:
    "ADD_FEE_PLAN_FROM_DETAILED_VIEW_SUCCESSFUL",
  DETAILED_PROGRAM_REQUEST_FAILED: "DETAILED_PROGRAM_REQUEST_FAILED",
  'DOWNLOAD_SINGLE_PROGRAM_EXCEL': 'DOWNLOAD_SINGLE_PROGRAM_EXCEL',
  'DOWNLOAD_SINGLE_PROGRAM_EXCEL_SUCCESS': 'DOWNLOAD_SINGLE_PROGRAM_EXCEL_SUCCESS',
  GET_SELECTED_PROGRAM_DETAIL: "GET_SELECTED_PROGRAM_DETAIL",
  GET_SELECTED_PROGRAM_DETAIL_SUCCESS: "GET_SELECTED_PROGRAM_DETAIL_SUCCESS",

  deleteStudentFromProgram: (selectedProgram, firebase) => ({
    type: actions.DELETE_STUDENT_FROM_PROGRAM,
    selectedProgram,
    firebase
  }),

  getStudentsToAssignProgram: firebase => ({
    type: actions.DETAILED_PROGRAM_FETCH_STUDENT,
    firebase
  }),

  assignStudentToProgram: (
    values,
    selectedStudentCheckbox,
    selectedProgram,
    firebase
  ) => ({
    type: actions.ASSIGN_STUDENT_FROM_DETAILED_PROGRAM,
    values,
    selectedStudentCheckbox,
    selectedProgram,
    firebase
  }),

  getFeePlanToAssignToProgram: firebase => ({
    type: actions.DETAILED_PROGRAM_FETCH_FEE_PLAN,
    firebase
  }),

  addFeePlanToProgram: (selectedProgram, firebase) => ({
    type: actions.ADD_FEE_PLAN_FROM_DETAILED_VIEW,
    selectedProgram,
    firebase
  }),

  excelDownload: (selectedProgram, firebase) => ({
    type: actions.DOWNLOAD_SINGLE_PROGRAM_EXCEL,
    selectedProgram,
    firebase
  }),

  getSelectedProgram: (id, firebase, selectedProgram) => ({
    type: actions.GET_SELECTED_PROGRAM_DETAIL,
    id,
    firebase,
    selectedProgram
  })
};
export default actions;
