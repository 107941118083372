import {
  all,
  takeEvery,
  put,
  call,
  takeLatest,
  fork,
} from "redux-saga/effects";
import { delay } from "redux-saga";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { TeacherApi } from "../../firestore-api/teacher";
import { StudentApi } from "../../firestore-api/student";
import { ActivityApi } from "../../firestore-api/activity";
import notification from "../../components/notification";
import { StudentAttendanceApi } from "../../firestore-api/studentAttendance";
import bugsnagClient from "@bugsnag/js";
import moment from "moment-timezone";

function* listTeachers({ firebase, initialCall }) {
  let data = JSON.parse(localStorage.getItem("teacherList"));
  yield put({
    type: actions.LIST_TEACHER_SUCCESSFUL,
    teachers: data,
    teacherChannel: undefined,
    staffOperation: initialCall ? "INITIAL_STAFF_FETCH" : undefined,
  });
}

export function* getAllClassromsForTeacher({ firebase }) {
  try {
    let data = JSON.parse(localStorage.getItem("classList"));
    if (data) {
      yield put({
        type: actions.GET_TEACHER_CLASSROOM_DATA_SUCCESSFUL,
        teachersClassroomData: data,
      });
    }
  } catch (error) {
    console.log("failed to fetch classroom data for teacher ", error);
    bugsnagClient.notify(error);
  }
}

function* updateTeacherUserEmail(contact, staffObj, firebase, type, formEmail) {
  try {
    if (staffObj.email || formEmail) {
      let email = formEmail
        ? formEmail.toLowerCase()
        : staffObj.email.toLowerCase();
      let editedId = replaceAll(email, "@", "%40");
      let encodedEmail = replaceAll(editedId, ".", "%2E");

      let existingUser = yield call(
        TeacherApi.checkIfUserExistInNewUser,
        Number(contact),
        firebase
      );

      if (existingUser.status) {
        let existingEmailUser = yield call(
          TeacherApi.checkIfUserExistInNewUser,
          encodedEmail,
          firebase
        );
        let ifUpdateNeeded = false;
        if (
          existingEmailUser.status &&
          existingEmailUser.id === existingUser.id
        ) {
          ifUpdateNeeded = true;
        } else if (!existingEmailUser.status) {
          ifUpdateNeeded = true;
        }

        if (ifUpdateNeeded) {
          let existingObj = JSON.parse(JSON.stringify(existingUser));
          existingObj.status = null;
          yield call(
            StudentApi.updateNewUser,
            encodedEmail,
            existingObj,
            firebase
          );

          if (type && type === "add") {
            addFirebaseAuth(email, firebase);
          } else if (
            formEmail &&
            (!staffObj.email || staffObj.email !== formEmail)
          ) {
            alert("auth created for" + email);
            addFirebaseAuth(email, firebase);
          }

          if (type && type === "add") {
            yield fork(TeacherApi.sendInviteToTeacher, [email], firebase);
          } else if (
            formEmail &&
            (!staffObj.email || staffObj.email !== formEmail)
          ) {
            alert("emailnode created for" + email);
            yield fork(TeacherApi.sendInviteToTeacher, [email], firebase);
          }
        }
      } else {
        bugsnagClient.notify(
          new Error(
            "Unable to create teacher's email login as no data was found with the registered number"
          )
        );
      }
    }
  } catch (err) {
    notification(
      "error",
      "Failed to update email login",
      err.message ? err.message : ""
    );
    console.log("failed to upload teacher user email", err);
    bugsnagClient.notify(
      "failed to upload teacher email in new user ----->>>" + err.message
        ? err.message
        : err
    );
  }
}

function addFirebaseAuth(email, firebase) {
  var username = email.split("@")[0];
  var password = username.substr(0, 8);
  if (password.length < 8) {
    password = password + "123456";
  }

  firebase.auth.createUserWithEmailAndPassword(email, password).then((res) => {
    console.log("auth ---", res);
  });
}

function replaceAll(str, term, replacement) {
  return str.replace(new RegExp(escapeRegExp(term), "g"), replacement);
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export function* addTeacher({
  name,
  gender,
  classroom,
  contact,
  address,
  email,
  role,
  encodedEmail,
  password,
  firebase,
  countryCode,
  branchAccess,
  groups,
  birthDate,
}) {
  try {
    let genericMessage =
      "Failed to add details. Please contact school or Illumine";
    let newBranches = [];
    let allBranches =
      firebase && firebase.branchList ? firebase.branchList : undefined;
    if (
      branchAccess &&
      branchAccess.length > 0 &&
      allBranches &&
      allBranches.length > 0
    ) {
      newBranches = getBranchesObject(branchAccess, allBranches);
    }

    if (newBranches && newBranches.length === 1) {
      if (newBranches[0].name.toLowerCase() === firebase.sbp.toLowerCase()) {
        newBranches = [];
      }
    }

    let response = yield call(
      TeacherApi.addTeacherApi,
      firebase,
      name,
      Number(contact),
      email,
      gender,
      classroom,
      role,
      address,
      countryCode,
      newBranches,
      groups,
      birthDate
    );
    if (response && response.status) {
      if (response.status === 200 || response.status === 201) {
        yield put({
          type: actions.ADD_TEACHER_SUCCESSFUL,
        });
      } else {
        yield put({
          type: actions.TEACHER_REQUEST_FAILED,
          errorMessage:
            response.body && response.body.message
              ? response.body.message
              : genericMessage,
        });
      }
    }
  } catch (err) {
    console.log("failed to add teacher", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* updateTeacher({
  name,
  gender,
  classroom,
  contact,
  address,
  email,
  role,
  record,
  firebase,
  countryCode,
  branchAccess,
  groups,
  birthDate,
}) {
  try {
    let genericMessage =
      "Failed to update details. Please contact school or Illumine";
    let oldPhoneNumber = record.phoneNumber;
    let oldEmail = record.email ? record.email : null;
    let teacherObj = JSON.parse(JSON.stringify(record));
    let newBranches = [];
    let allBranches =
      firebase && firebase.branchList ? firebase.branchList : undefined;
    if (
      branchAccess &&
      branchAccess.length > 0 &&
      allBranches &&
      allBranches.length > 0
    ) {
      newBranches = getBranchesObject(branchAccess, allBranches);
    }

    if (newBranches && newBranches.length === 1) {
      if (newBranches[0].name.toLowerCase() === firebase.sbp.toLowerCase()) {
        newBranches = [];
      }
    }

    teacherObj.name = name;
    teacherObj.gender = gender;
    teacherObj.classList = classroom;
    teacherObj.phoneNumber = Number(contact);
    teacherObj.address = address ? address : null;
    teacherObj.email = email ? email : null;
    teacherObj.role = role;
    teacherObj.code = countryCode;
    teacherObj.newBranches =
      newBranches && newBranches.length > 0 ? newBranches : null;
    teacherObj.groups = groups && groups.length > 0 ? groups : null;
    teacherObj.birthDate = birthDate
      ? moment(new Date(birthDate)).format("DD[, ]MMMM[, ]YYYY")
      : null;

    let response = yield call(
      TeacherApi.updateTeacherApi,
      firebase,
      teacherObj,
      oldPhoneNumber,
      oldEmail
    );
    if (response && response.status) {
      if (response.status === 200) {
        yield put({
          type: actions.UPDATE_TEACHER_SUCCESSFUL,
        });
      } else {
        yield put({
          type: actions.TEACHER_REQUEST_FAILED,
          errorMessage:
            response.body && response.body.message
              ? response.body.message
              : genericMessage,
        });
      }
    }
  } catch (err) {
    console.log("failed to update teacher detail", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function getBranchesObject(branchAccess, allBranches) {
  let newBranches = [];
  for (let index in branchAccess) {
    let branchName = branchAccess[index];
    let filteredBranch = allBranches.filter((b) => {
      return b.name === branchName;
    });

    if (filteredBranch && filteredBranch.length > 0) {
      newBranches = [...newBranches, ...filteredBranch];
    }
  }
  return newBranches;
}

function* deleteSelectedTeacher({ teacherRecord, firebase }) {
  try {
    let genericMessage =
      "Failed to delete teacher. Please contact school or Illumine";
    let response = yield call(
      TeacherApi.deleteTeacherApi,
      firebase,
      teacherRecord
    );
    if (response && response.status) {
      if (response.status === 200) {
        yield put({
          type: actions.DELETE_TEACHER_SUCCESSFUL,
        });
      } else {
        yield put({
          type: actions.TEACHER_REQUEST_FAILED,
          errorMessage:
            response.body && response.body.message
              ? response.body.message
              : genericMessage,
        });
      }
    }
  } catch (err) {
    console.log("failed to delete teacher", err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* fetchSelectedTeacherDetail({ teacherId, firebase }) {
  let teachers = JSON.parse(localStorage.getItem("teacherList"));
  let data = teachers.filter((t) => {
    return t.id === teacherId;
  });
  yield put({
    type: actions.GET_TEACHER_DETAIL_SUCCESSFUL,
    teacherDetail: data,
    teacherDetailChannel: undefined,
  });
}

function* refreshTeacherDetail(teacherId) {
  let teachers = JSON.parse(localStorage.getItem("teacherList"));
  let data = teachers.filter((t) => {
    return t.id === teacherId;
  });

  yield put({
    type: actions.GET_TEACHER_DETAIL_SUCCESSFUL,
    teacherDetail: data,
    teacherDetailChannel: undefined,
  });
}

function* updateStaffRole({ role, teacherId, firebase }) {
  try {
    yield call(TeacherApi.changeStaffRole, role, teacherId, firebase);
    yield put({
      type: actions.CHANGE_STAFF_ROLE_SUCCESSFUL,
    });
    yield call(refreshTeacherDetail, teacherId);

    yield fork(StudentAttendanceApi.lastUpdateTimestamp, moment(), firebase);
  } catch (err) {
    console.log("failed to change role", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* updateStaffPermission({ category, status, teacherId, firebase }) {
  try {
    yield call(
      TeacherApi.changeStaffPermission,
      category,
      status,
      teacherId,
      firebase
    );
    yield put({
      type: actions.CHANGE_PERMISSION_SUCCESSFUL,
    });
    yield call(refreshTeacherDetail, teacherId);

    yield fork(StudentAttendanceApi.lastUpdateTimestamp, moment(), firebase);
  } catch (err) {
    console.log("failed to change permission", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* updateTeacherDisplayPic({ file, teacherDetail, firebase }) {
  try {
    let storagePath = firebase.sbp + "/media/profileimages/";
    let urls = yield call(
      ActivityApi.getMediaPath,
      storagePath,
      file,
      firebase
    );
    if (urls) {
      if (urls.length > 0) {
        teacherDetail[0].profileImageUrl = urls[0];
        let profileImageUrlObj = {
          profileImageUrl: urls[0],
          platform: "web",
          updatedBy: firebase.teacher.name,
          updatedOn: moment().valueOf(),
        };
        yield call(
          TeacherApi.updateTeacherObject,
          profileImageUrlObj,
          teacherDetail[0].id,
          firebase
        );

        yield fork(
          StudentAttendanceApi.lastUpdateTimestamp,
          moment(),
          firebase
        );
        yield put({
          type: actions.UPLOAD_TEACHER_PROFILE_SUCCESSFUL,
        });

        if (teacherDetail[0] && teacherDetail[0].id) {
          yield call(refreshTeacherDetail, teacherDetail[0].id);
        }
      }
    } else {
      yield put({
        type: actions.TEACHER_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to upload display pic", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* fetchTeacherFiles({ id, firebase }) {
  try {
    let data = yield call(StudentApi.getUserDocument, id, firebase);
    if (data) {
      yield put({
        type: actions.GET_TEACHER_FILES_SUCCESS,
        teacherDoc: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch teacher files", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* uploadTeacherDoc({ fileList, mediaType, id, firebase, filePath }) {
  try {
    let storagePath;
    if (mediaType === "File") {
      storagePath = firebase.sbp + "/media/file/";
    } else {
      storagePath = firebase.sbp + "/media/images/";
    }
    let file = { fileList: fileList };
    let urls = yield call(
      ActivityApi.getMediaPath,
      storagePath,
      file,
      firebase
    );
    if (urls) {
      let nodeId = yield call(StudentApi.getStudentDocNode, firebase);

      let obj = {
        filePath: filePath ? filePath : null,
        createdBy: firebase.teacher.name,
        createdOn: moment().valueOf(),
        downloadUrl: urls[0],
        id: nodeId,
        inverseDate: -moment().valueOf(),
        name: fileList[0].name,
        nameWithExtension: fileList[0].name,
        type: mediaType === "File" ? "doc" : "image",
      };
      yield call(StudentApi.uploadDocument, id, obj, firebase);

      yield fork(StudentAttendanceApi.lastUpdateTimestamp, moment(), firebase);
      yield put({
        type: actions.UPLOAD_TEACHER_FILE_SUCCESS,
      });
    } else {
      yield put({
        type: actions.TEACHER_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to upload teacher document", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* deleteTeacherDoc({ record, id, firebase }) {
  try {
    yield call(StudentApi.deleteDocument, record, id, firebase);
    yield put({
      type: actions.DELETE_TEACHER_FILE_SUCCESS,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* setTempTeacherDetail({ teacherDetail }) {
  yield put({
    type: actions.SET_TEMP_TEACHER_DETAIL_SUCCESS,
    teacherDetail: teacherDetail,
  });
}

function* fetchTeacherAttendance({ date, teacherId, firebase }) {
  try {
    var month1 = new Date(date).getMonth();
    var year = new Date(date).getFullYear();
    var firstDay = new Date(year, month1, 1);
    var lastDay = new Date(year, month1 + 1, 0);

    let data = yield call(
      TeacherApi.getTeacherAttendance,
      date,
      teacherId,
      firebase
    );
    if (data) {
      console.log("staffAttendance ----", data);
      let staffAttendance = data.staffAttendance;
      staffAttendance.sort(function(a, b) {
        var dateA = a.date,
          dateB = b.date;
        return dateB - dateA;
      });

      let leaves = yield call(
        TeacherApi.getSelectedTeacherLeave,
        firstDay,
        lastDay,
        teacherId,
        firebase
      );

      let leavesCount = 0;
      for (let i = 0; i < leaves.length; i++) {
        let loop = new Date(leaves[i].startDate);
        let endDate = new Date(leaves[i].endDate);

        while (loop <= endDate) {
          leavesCount++;
          var newDate = loop.setDate(loop.getDate() + 1);
          loop = new Date(newDate);
        }
      }

      yield put({
        type: actions.GET_TEACHER_ATD_SUCCESS,
        teacherAttendance: staffAttendance,
        presentCount: data.presentCount,
        absentCount: data.absentCount,
        leavesCount: leavesCount,
        lateCheckIn: data.lateCheckIn,
        lateCheckOut: data.lateCheckOut,
        teacherLeaves: leaves,
      });
    }
  } catch (err) {
    console.log("failed to get teacher attendance", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* updateTeacherStatus({ teacher, firebase }) {
  try {
    let obj = {
      deactivated: teacher.deactivated,
      deactivationDate: teacher.deactivationDate
        ? teacher.deactivationDate
        : null,
      platform: "web",
      updatedOn: moment().valueOf(),
      updatedBy: firebase.teacher.name,
    };
    yield call(TeacherApi.updateTeacherObject, obj, teacher.id, firebase);
    yield put({
      type: actions.UPDATE_TEACHER_STATUS_SUCCESS,
    });
  } catch (err) {
    console.log("failed to update teacher status", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* requestTeacherInvite({ teacher, firebase }) {
  try {
    let status = yield call(
      TeacherApi.sendInviteToTeacher,
      [teacher.email],
      firebase
    );
    if (status) {
      if (status.status === 400) {
        let error = JSON.parse(status.text);
        notification("error", error.error);
      } else if (status.status === 500) {
        let error = JSON.parse(status.text);
        notification("error", error.error);
      } else if (status.status === 200) {
        notification("success", "Invite sent to teacher");
      }
    }

    yield put({
      type: actions.SEND_TEACHER_INVITE_SUCCESS,
    });
  } catch (err) {
    console.log("failed to send teacher invite", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* addTeacherChild({ values, teacher, firebase }) {
  try {
    let newStudentObj = {
      name: values.studentName,
      classroomName: values.classroom[0],
      classList: values.classroom,
      status: values.status,
      gender: values.gender,
    };
    let newStudentBranchPath = undefined;
    let genericMessage =
      "Failed to add child details. Please contact school or Illumine";
    let parentType = values.gender === "Male" ? "parent2" : "parent1";

    let response = yield call(
      TeacherApi.addTeacherChildApi,
      firebase,
      teacher.id,
      newStudentObj,
      newStudentBranchPath,
      parentType
    );
    if (response && response.status === 200) {
      firebase.refreshStudents();
      yield put({ type: actions.SAVE_TEACHER_CHILD_SUCCESS });
    } else {
      yield put({
        type: actions.TEACHER_REQUEST_FAILED,
        errorMessage:
          response.body && response.body.message
            ? response.body.message
            : genericMessage,
      });
    }
  } catch (err) {
    console.log("failed to add teacher's child", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* addDocumentFolderTeacher({ folderName, filePath, firebase, id }) {
  try {
    let nodeId = yield call(StudentApi.createNewNoteId, firebase);
    yield call(
      StudentApi.addDocumentFolder,
      nodeId,
      id,
      folderName,
      filePath,
      firebase
    );
    yield put({ type: actions.ADD_DOC_FOLDER_TEACHER_SUCCESS });
  } catch (err) {
    console.log("failed to add document folder", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* renameDocTeacher({ docName, record, firebase, id }) {
  try {
    yield call(StudentApi.renameDoc, docName, record.id, firebase, id);
    yield put({ type: actions.RENAME_TEACHER_DOC_SUCCESS });
  } catch (err) {
    console.log("failed to rename teacher document", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.LIST_TEACHERS, listTeachers),
    yield takeLatest(
      actions.GET_TEACHER_CLASSROOM_DATA,
      getAllClassromsForTeacher
    ),
    yield takeEvery(actions.ADD_TEACHER, addTeacher),
    yield takeEvery(actions.UPDATE_TEACHER, updateTeacher),
    yield takeEvery(actions.DELETE_TEACHER, deleteSelectedTeacher),
    yield takeEvery(actions.GET_TEACHER_DETAIL, fetchSelectedTeacherDetail),
    yield takeEvery(actions.CHANGE_STAFF_ROLE, updateStaffRole),
    yield takeEvery(actions.CHANGE_PERMISSION, updateStaffPermission),
    yield takeEvery(actions.UPLOAD_TEACHER_PROFILE, updateTeacherDisplayPic),
    yield takeEvery(actions.GET_TEACHER_FILES, fetchTeacherFiles),
    yield takeEvery(actions.UPLOAD_TEACHER_FILE, uploadTeacherDoc),
    yield takeEvery(actions.DELETE_TEACHER_FILE, deleteTeacherDoc),
    yield takeEvery(actions.SET_TEMP_TEACHER_DETAIL, setTempTeacherDetail),
    yield takeEvery(actions.GET_TEACHER_ATD, fetchTeacherAttendance),
    yield takeEvery(actions.UPDATE_TEACHER_STATUS, updateTeacherStatus),
    yield takeEvery(actions.SEND_TEACHER_INVITE, requestTeacherInvite),
    yield takeEvery(actions.SAVE_TEACHER_CHILD, addTeacherChild),
    yield takeEvery(actions.ADD_DOC_FOLDER_TEACHER, addDocumentFolderTeacher),
    yield takeEvery(actions.RENAME_TEACHER_DOC, renameDocTeacher),
  ]);
}
