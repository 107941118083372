import { all, put, call, takeLatest, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import '@firebase/firestore' // 👈 If you're using firestore
import { UserSettingApi } from '../../firestore-api/userSetting';
import { StudentApi } from "../../firestore-api/student";
import bugsnagClient from '@bugsnag/js';
import { ActivityApi } from "../../firestore-api/activity";
import notification from "../../components/notification";


// function* fetchUserSettingDetail({ firebase }) {
//     const chan = yield call(UserSettingApi.getUserSchoolDetails, firebase);
//     try {
//         while (true) {
//             let data = yield take(chan);
//             yield put({
//                 type: actions.GET_SCHOOL_SETTING_DETAILS_SUCCESSFUL,
//                 schoolSettingDetails: data,
//                 schoolUserChannel: chan
//             })
//         }
//     } finally {
//         console.log("SCHOOL USER END");
//     }
// }

function* fetchUserSettingDetail({ firebase }) {
    try {
        let data = yield call(UserSettingApi.getUserSchoolDetails, firebase);
        if (data) {
            yield put({
                type: actions.GET_SCHOOL_SETTING_DETAILS_SUCCESSFUL,
                schoolSettingDetails: data,
                // schoolUserChannel: chan
            })
        }
    } catch (err) {
        console.log("failed to fetch user setting", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.SCHOOL_SETTING_REQUEST_FAILED
        })
    }
}

function* uploadSchoolLogo({ file, firebase }) {
    try {
        var schoolName = localStorage.getItem('selectedDBName');
        var url = yield call(UserSettingApi.updateSchoolLogoToStorage, file, firebase);
        if (url) {
            yield call(UserSettingApi.updateUserSchoolDetail, schoolName, url[0], firebase);
            yield put({
                type: actions.UPLOAD_SCHOOL_LOGO_SUCCESSFUL
            })
        }
    } catch (err) {
        bugsnagClient.notify(err);
        yield put({
            type: actions.SCHOOL_SETTING_REQUEST_FAILED
        });
    }
}

function* updateUserSettingInfo({ userInfo, firebase }) {

    try {
        var schoolName = localStorage.getItem('selectedDBName');
        yield call(UserSettingApi.updateUserInfo, userInfo, schoolName, firebase);
        // firebase.updateSchoolName(userInfo.schoolName);
        yield put({
            type: actions.UPDATE_USER_INFO_SUCCESSFUL
        })
    } catch (err) {
        bugsnagClient.notify(err);
        yield put({
            type: actions.SCHOOL_SETTING_REQUEST_FAILED
        })
    }
}

function* fetchUserDetail({ firebase }) {
    try {
        let data = yield call(UserSettingApi.getUserDetail, firebase);
        if (data) {
            yield put({
                type: actions.GET_USER_DETAILS_SUCCESSFUL,
                userDetail: data
            })
        }
    } catch (err) {
        bugsnagClient.notify(err);
        yield put({
            type: actions.SCHOOL_SETTING_REQUEST_FAILED
        })
    }
}

function* updateTeacherInfo({ value, firebase }) {
    try {
        yield call(UserSettingApi.updateTeacherDetail, value, firebase);
        yield put({
            type: actions.UPDATE_TEACHER_INFO_SUCCESSFUL
        })
    } catch (err) {
        bugsnagClient.notify(err);
        yield put({
            type: actions.SCHOOL_SETTING_REQUEST_FAILED
        })
    }
}

function* getCameraData({ firebase }) {
    try {
        let data = yield call(UserSettingApi.getAllCameraData, firebase);
        if (data) {
            yield put({
                type: actions.GET_CAMERA_DETAILS_SUCCESSFUL,
                cameraDetails: data
            })
        }
    } catch (err) {
        bugsnagClient.notify(err);
        yield put({
            type: actions.SCHOOL_SETTING_REQUEST_FAILED
        })
    }
}

function* getAllClassroomForCameraAssign({ firebase }) {
    try {
        const classroomData = yield call(
            StudentApi.getAutoCompleteClassrooms,
            firebase
        );
        if (classroomData) {
            yield put({
                type: actions.GET_CLASSROOM_FOR_CAMERA_ASSIGN_SUCCESSFFUL,
                classroomList: classroomData
            });
        }
    } catch (err) {
        bugsnagClient.notify(err);
        console.log("failed to fetch  classroom", err);
        yield put({
            type: actions.SCHOOL_SETTING_REQUEST_FAILED
        });
    }
}

function* assignCameraToClassroom({ values, cameraId, firebase }) {
    try {
        yield call(UserSettingApi.updateCameraToClass, values, cameraId, firebase);
        yield put({
            type: actions.UPDATE_CAMERA_TO_CLASS_SUCCESSFUL
        })
    } catch (err) {
        bugsnagClient.notify(err);
        yield put({
            type: actions.SCHOOL_SETTING_REQUEST_FAILED
        });
    }
}

function* updateSchoolConfig({ config, firebase }) {
    try {
        yield call(UserSettingApi.updateSchoolConfig, config, firebase);
        yield put({
            type: actions.SAVE_CONFIG_SETTING_SUCCESSFUL
        });
    } catch (err) {
        bugsnagClient.notify(err);
        console.log("failed to update school config setting", err);
        yield put({
            type: actions.SCHOOL_SETTING_REQUEST_FAILED
        });
    }
}

function* fetchSchoolConfig({ firebase }) {
    try {
        let data = yield call(UserSettingApi.getSchoolConfiguration, firebase);
        yield put({
            type: actions.GET_SCHOOL_CONFIG_SUCCESS,
            schoolConfig: data
        });
    } catch (err) {
        bugsnagClient.notify(err);
        console.log("failed to fetch school config", err);
        yield put({
            type: actions.SCHOOL_SETTING_REQUEST_FAILED
        });
    }
}

function* submitParentHandbook({ attachedFile, config, firebase }) {
    try {
        let storagePath = firebase.sbp + "/media/file/";
        let mediaPaths = yield call(
            ActivityApi.getMediaPath,
            storagePath,
            attachedFile,
            firebase
        );
        if (mediaPaths) {
            let schoolConfig = config;
            schoolConfig.handbookUrl = mediaPaths[0];
            yield call(UserSettingApi.updateSchoolConfig, schoolConfig, firebase);

            yield put({
                type: actions.SUBMIT_HANDBOOK_SUCCESS
            });
        }
    } catch (err) {
        console.log("failed to submit parent handbook", err);
        bugsnagClient.notify(err);
    }
}
function* submitTermsConditions({ attachedFile, config, firebase }) {
    try {
        let storagePath = firebase.sbp + "/media/file/";
        let mediaPaths = yield call(
            ActivityApi.getMediaPath,
            storagePath,
            attachedFile,
            firebase
        );
        if (mediaPaths) {
            let schoolConfig = config;
            schoolConfig.termsConditions = mediaPaths[0];
            yield call(UserSettingApi.updateSchoolConfig, schoolConfig, firebase);

            yield put({
                type: actions.SUBMIT_TERMS_SUCCESS
            });
        }
    } catch (err) {
        console.log("failed to submit terms & conditions document", err);
        bugsnagClient.notify(err);
    }
}


function* fetchActiveActivity({ firebase }) {
    yield fork(fetchAllActiveActivities, firebase);
}

function* fetchAllActiveActivities(firebase) {
    try {
        let data = yield call(ActivityApi.getActivitySetting, firebase);
        if (data) {
            yield put({
                type: actions.GET_ACTIVE_ACTIVITY_SUCCESS,
                activeActivities: data
            });
        }
    } catch (err) {
        console.log("failed to fetch active activities", err);
        bugsnagClient.notify(err);
    }
}

function* saveAllActiveActivies({ activities, firebase }) {
    try {
        yield call(UserSettingApi.saveActiveActivies, activities, firebase);
        notification('success', 'Saved');
        yield fork(fetchAllActiveActivities, firebase);
    } catch (err) {
        console.log("failed to save active activities", err);
        bugsnagClient.notify(err);
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(actions.UPLOAD_SCHOOL_LOGO, uploadSchoolLogo),
        yield takeEvery(actions.GET_SCHOOL_SETTING_DETAILS, fetchUserSettingDetail),
        yield takeLatest(actions.UPDATE_USER_INFO, updateUserSettingInfo),
        yield takeLatest(actions.GET_USER_DETAILS, fetchUserDetail),
        yield takeLatest(actions.UPDATE_TEACHER_INFO, updateTeacherInfo),
        yield takeLatest(actions.GET_CAMERA_DETAILS, getCameraData),
        yield takeLatest(actions.GET_CLASSROOM_FOR_CAMERA_ASSIGN, getAllClassroomForCameraAssign),
        yield takeLatest(actions.UPDATE_CAMERA_TO_CLASS, assignCameraToClassroom),
        yield takeLatest(actions.SAVE_CONFIG_SETTING, updateSchoolConfig),
        yield takeLatest(actions.GET_SCHOOL_CONFIG, fetchSchoolConfig),
        yield takeLatest(actions.SUBMIT_HANDBOOK, submitParentHandbook),
        yield takeLatest(actions.SUBMIT_TERMS, submitTermsConditions),
        yield takeLatest(actions.GET_ACTIVE_ACTIVITY, fetchActiveActivity),
        yield takeLatest(actions.SAVE_ACTIVE_ACTVITIES, saveAllActiveActivies),
    ])
}