const actions = {
  FETCH_STAFF_ATTENDANCE: "FETCH_STAFF_ATTENDANCE",
  FETCH_STAFF_ATTENDANCE_SUCCESSFUL: "FETCH_STAFF_ATTENDANCE_SUCCESSFUL",

  FETCH_STAFF_ATTENDANCE_BY_CLASSNAME: "FETCH_STAFF_ATTENDANCE_BY_CLASSNAME",
  FETCH_STAFF_ATTENDANCE_BY_CLASSNAME_SUCCESSFUL:
    "FETCH_STAFF_ATTENDANCE_BY_CLASSNAME_SUCCESSFUL",

  FETCH_ALL_STAFF: "FETCH_ALL_STAFF",
  FETCH_ALL_STAFF_SUCCESSFUL: "FETCH_ALL_STAFF_SUCCESSFUL",

  MARK_STAFF_PRESENT: "MARK_STAFF_PRESENT",
  MARK_STAFF_PRESENT_SUCCESSFUL: "MARK_STAFF_PRESENT_SUCCESSFUL",

  MARK_STAFF_ABSENT: "MARK_STAFF_ABSENT",
  MARK_STAFF_ABSENT_SUCCESSFUL: "MARK_STAFF_ABSENT_SUCCESSFUL",

  MARK_STAFF_CHECKOUT: "MARK_STAFF_CHECKOUT",
  MARK_STAFF_CHECKOUT_SUCCESSFUL: "MARK_STAFF_CHECKOUT_SUCCESSFUL",

  FETCH_ALL_CLASSROOMS_FOR_STAFF_ATTENDANCE:
    "FETCH_ALL_CLASSROOMS_FOR_STAFF_ATTENDANCE",
  FETCH_ALL_CLASSROOMS_FOR_STAFF_ATTENDANCE_SUCCESSFUL:
    "FETCH_ALL_CLASSROOMS_FOR_STAFF_ATTENDANCE_SUCCESSFUL",

  FETCH_STAFF_BY_CLASSNAME: "FETCH_STAFF_BY_CLASSNAME",
  FETCH_STAFF_BY_CLASSNAME_SUCCESSFUL: "FETCH_STAFF_BY_CLASSNAME_SUCCESSFUL",

  STAFF_ATTENDANCE_REQUEST_FAILED: "STAFF_ATTENDANCE_REQUEST_FAILED",

  RESET_STAFF_ATD_OPERATION: "RESET_STAFF_ATD_OPERATION",

  EMAIL_STAFF_ATTENDANCE: "EMAIL_STAFF_ATTENDANCE",

  GET_STAFF_MONTHLY_ATD: "GET_STAFF_MONTHLY_ATD",
  GET_STAFF_MONTHLY_ATD_SUCCESS: "GET_STAFF_MONTHLY_ATD_SUCCESS",

  MARK_STAFF_PENDING: "MARK_STAFF_PENDING",
  MARK_STAFF_PENDING_SUCCESS: "MARK_STAFF_PENDING_SUCCESS",

  DOWNLOAD_STAFF_ATD_MONTHLY: "DOWNLOAD_STAFF_ATD_MONTHLY",

  initStaffAttendanceData: (date, firebase, operationType) => ({
    type: actions.FETCH_STAFF_ATTENDANCE,
    date,
    firebase,
    operationType,
  }),

  getAllStaffs: (firebase, date) => ({
    type: actions.FETCH_ALL_STAFF,
    firebase,
    date,
  }),

  markStaffPresent: (
    selectedStaff,
    date,
    time,
    firebase,
    pending,
    checkInOutStatus,
    temperature
  ) => ({
    type: actions.MARK_STAFF_PRESENT,
    selectedStaff,
    date,
    time,
    firebase,
    pending,
    checkInOutStatus,
    temperature,
  }),

  markStaffAbsent: (selectedStaff, date, firebase) => ({
    type: actions.MARK_STAFF_ABSENT,
    selectedStaff,
    date,
    firebase,
  }),

  getAllClassroomsForStaffAttendance: (firebase) => ({
    type: actions.FETCH_ALL_CLASSROOMS_FOR_STAFF_ATTENDANCE,
    firebase,
  }),

  getStaffByClassname: (className, firebase, date) => ({
    type: actions.FETCH_STAFF_BY_CLASSNAME,
    className,
    firebase,
    date,
  }),

  getStaffAttendanceData: (className, date, firebase) => ({
    type: actions.FETCH_STAFF_ATTENDANCE_BY_CLASSNAME,
    className,
    date,
    firebase,
  }),

  markStaffCheckoutTime: (
    selectedStaff,
    date,
    time,
    firebase,
    checkInOutStatus
  ) => ({
    type: actions.MARK_STAFF_CHECKOUT,
    selectedStaff,
    date,
    time,
    firebase,
    checkInOutStatus,
  }),

  resetStaffAtdOperation: () => ({
    type: actions.RESET_STAFF_ATD_OPERATION,
  }),

  emailAttendanceSheet: (value, atdType, downloadType, firebase) => ({
    type: actions.EMAIL_STAFF_ATTENDANCE,
    value,
    atdType,
    downloadType,
    firebase,
  }),

  getStaffMonthlyAttendance: (date, firebase) => ({
    type: actions.GET_STAFF_MONTHLY_ATD,
    date,
    firebase,
  }),

  markStaffPending: (selectedStaff, date, firebase) => ({
    type: actions.MARK_STAFF_PENDING,
    selectedStaff,
    date,
    firebase,
  }),

  downloadMonthlyAttendanceStaff: (record, firebase) => ({
    type: actions.DOWNLOAD_STAFF_ATD_MONTHLY,
    record,
    firebase,
  }),
};
export default actions;
