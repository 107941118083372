import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import '@firebase/firestore' // 👈 If you're using firestore
import { CurriculumApi } from '../../firestore-api/curriculum';

export function* listCurriculums() {
  let fullCurriculum = [];
  try {
    fullCurriculum = yield call(CurriculumApi.getAllCurriculums);

    yield put({
      type: actions.LIST_CURRICULUM,
      curriculums: fullCurriculum,
    });
  } catch (error) {
    console.log(error);
  }
}

export function* addNewCurriculum({ developmentArea, subArea, date, title, image, video, duration, materialRequired, description, instruction }) {
  let imageUrl = '';
  let videoUrl = '';
  try {
    imageUrl = yield call(CurriculumApi.getMediaUrl, image);
    videoUrl = yield call(CurriculumApi.getMediaUrl, video);

    yield call(CurriculumApi.addNewCurriculum, developmentArea, subArea, date, title, imageUrl, videoUrl, duration, materialRequired, description, instruction);
    yield put({
      type: actions.ADD_CURRICULUM_SUCCESFUL
    });
  } catch (error) {
    console.log(error);
  }
}

export function* updateCurriculum({ id, developmentArea, subArea, date, title, image, video, duration, materialRequired, description, instruction }) {
  let imageUrl = '';
  let videoUrl = '';
  try {
    if (image.name === undefined) {
      imageUrl = image;
    } else {
      imageUrl = yield call(CurriculumApi.getMediaUrl, image);
    }

    if (video.name === undefined) {
      videoUrl = video;
    } else {
      videoUrl = yield call(CurriculumApi.getMediaUrl, video);
    }

    yield call(CurriculumApi.updateExistingCurriculum, id, developmentArea, subArea, date, title, imageUrl, videoUrl, duration, materialRequired, description, instruction);
    const curriculumList = yield* listCurriculums();
    yield put({
      curriculumList,
      type: actions.UPDATE_CURRICULUM_SUCCESSFUL
    })
  } catch (error) {
    console.log(error);
  }
}

export function* toggleCurriculum({ status, id }) {
  try {
    yield call(CurriculumApi.disableCurriculumData, status, id);
    const curriculumList = yield* listCurriculums();
    yield put({
      curriculumList,
      type: actions.TOGGLE_CURRICULUM_SUCCESSFUL
    });
  } catch (error) {
    console.log(error);
  }
};

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.LIST_CURRICULUMS, listCurriculums),
    yield takeEvery(actions.ADD_CURRICULUM, addNewCurriculum),
    yield takeEvery(actions.UPDATE_CURRICULUM, updateCurriculum),
    yield takeEvery(actions.TOGGLE_CURRICULUM, toggleCurriculum)
  ]);
}
