import "@firebase/firestore"; // 👈 If you're using firestore
import { eventChannel } from "redux-saga";
//import moment from 'moment';
import moment from "moment-timezone";
import UserImageAction from "../Utility/userImageAction";
const superagent = require("superagent");

function getAllInvoice(firebase) {
  let studentIds = getStudentListArr();
  let invoices = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/invoices");
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((element) => {
        element.forEach((newEle) => {
          let classList =
            firebase && firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            if (classList.length > 0) {
              // let invoiceClass = newEle.val().classname;

              if (
                UserImageAction.ifClassAccess(classList, newEle.val().studentId)
              ) {
                if (studentIds.includes(newEle.val().studentId)) {
                  invoices.push(newEle.val());
                }
              }
            } else {
              if (studentIds.includes(newEle.val().studentId)) {
                invoices.push(newEle.val());
              }
            }
          } else {
            if (studentIds.includes(newEle.val().studentId)) {
              invoices.push(newEle.val());
            }
          }
        });
      });
      resolve(invoices);
    });
  });
  return promise1;
}

function getStudentListArr() {
  let students = JSON.parse(localStorage.getItem("studentList"));
  let studentIds = [];
  if (students && students.length > 0) {
    students.forEach((std) => {
      studentIds.push(std.id);
    });
  }
  return studentIds;
}

function getAllAggregatedInvoice(firebase) {
  let studentIds = getStudentListArr();
  let aggregatedInvoices = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/aggregratedInvoices");
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((element) => {
        if (element !== null) {
          let classList =
            firebase && firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            if (classList.length > 0) {
              if (
                UserImageAction.ifClassAccess(
                  classList,
                  element.val().studentId
                )
              ) {
                if (studentIds.includes(element.val().studentId)) {
                  aggregatedInvoices.push(element.val());
                }
              }
            } else {
              if (studentIds.includes(element.val().studentId)) {
                aggregatedInvoices.push(element.val());
              }
            }
          } else {
            if (studentIds.includes(element.val().studentId)) {
              aggregatedInvoices.push(element.val());
            }
          }
        }
      });
      resolve(aggregatedInvoices);
    });
  });
  return promise1;
}

function getInvoiceDownloadUrl(record, firebase) {
  let downloadUrl = {};
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/invoicePdf/" + record.id);
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      if (snap.val() !== null) {
        downloadUrl = snap.val();
      }
      resolve(downloadUrl);
    });
  });
  return promise1;
}

function generateInvoiceNode(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/invoices/" + studentId).push().key;
  return key;
}

function addNewInvoice(invoiceObject, studentData, nodeId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/invoices/" + studentData.id + "/" + nodeId)
    .update(invoiceObject);
}

function getAggregatedInvoice(studentId, firebase) {
  let aggregatedInvoice = {};
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/aggregratedInvoices/" + studentId);
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      if (snap.val() !== null) {
        aggregatedInvoice = snap.val();
      } else {
        aggregatedInvoice = false;
      }
      resolve(aggregatedInvoice);
    });
  });
  return promise1;
}

function updatedAmountToAggregatedInvoice(
  aggregatedInvoice,
  studentId,
  totalAmount,
  firebase,
  invoiceActivityType,
  receivedPayment
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var record = {};
  if (invoiceActivityType === "addInvoice") {
    record = {
      paid: Number(aggregatedInvoice.paid),
      pending: Number(aggregatedInvoice.pending + totalAmount),
      total: Number(aggregatedInvoice.total + totalAmount),
    };
  } else if (invoiceActivityType === "receivePayment") {
    record = {
      paid: Number(aggregatedInvoice.paid + receivedPayment),
      pending: Number(aggregatedInvoice.pending - receivedPayment),
    };
  } else if (invoiceActivityType === "deleteInvoice") {
    record = {
      paid: Number(aggregatedInvoice.paid - receivedPayment),
      pending: Number(
        aggregatedInvoice.pending - (totalAmount - receivedPayment)
      ),
      total: Number(aggregatedInvoice.total - totalAmount),
    };
  } else if (invoiceActivityType === "refund") {
    let refundAmt = aggregatedInvoice.refund ? aggregatedInvoice.refund : 0;
    record = {
      refund: Number(refundAmt + receivedPayment),
    };
  }
  rsf.ref(branchPath + "/aggregratedInvoices/" + studentId).update(record);
}

function addAmountToNewAggregatedInvoice(
  studentData,
  nodeId,
  totalAmount,
  firebase
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/aggregratedInvoices/" + studentData.id).update({
    classList: studentData.classList ? studentData.classList : [],
    classname: studentData.classroomName,
    fatherNumber: studentData.fatherNumber ? studentData.fatherNumber : 0,
    gender: studentData.gender,
    id: nodeId,
    motherNumber: studentData.motherNumber ? studentData.motherNumber : 0,
    paid: 0,
    pending: Number(totalAmount),
    studentId: studentData.id,
    studentName: studentData.name,
    admissionNumber: studentData.admissionNumber
      ? studentData.admissionNumber
      : null,
    total: Number(totalAmount),
  });
}

function updateInvoice(invoiceObject, studentId, invoiceId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf
    .ref(branchPath + "/invoices/" + studentId + "/" + invoiceId)
    .update(invoiceObject);
}

function recordPayment(selectedInvoice, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  // let newPaymentRecord = [];
  // if (selectedInvoice.paymentRecords) {
  //   newPaymentRecord = selectedInvoice.paymentRecords
  // }

  // newPaymentRecord.push({
  //   afterDiscountValue: 0,
  //   amount: values.amount,
  //   description: "Paid by " + values.mode + " on",
  //   discountValue: 0,
  //   mode: values.mode,
  //   timestamp: new Date().getTime(),
  //   referenceNumber: values.referenceNumber ? values.referenceNumber : null
  // })
  rsf
    .ref(
      branchPath +
        "/invoices/" +
        selectedInvoice.studentId +
        "/" +
        selectedInvoice.id
    )
    .update(selectedInvoice);
  //   {
  //   paid: (selectedInvoice.paid + values.amount),
  //   pending: (selectedInvoice.pending - values.amount),
  //   paymentRecords: newPaymentRecord
  // });
}

function getInvoiceById(studentId, invoiceId, firebase) {
  let invoice = {};
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(
    branchPath + "/invoices/" + studentId + "/" + invoiceId
  );
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      if (snap.val() !== null) {
        invoice = snap.val();
      }
      resolve(invoice);
    });
  });
  return promise1;
}

function deleteInvoice(studentId, invoiceId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/invoices/" + studentId + "/" + invoiceId).set(null);
}

function sendEmailOfInvoiceToParent(invoiceObject, firebase, noParentEmail) {
  let endPointUrl = firebase.endPointUrl;
  let sendInviteEndPointUrl = endPointUrl + "invoiceEmail";

  let schoolConfiguration = localStorage.getItem("schoolConfig");
  let config = JSON.parse(schoolConfiguration);

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(sendInviteEndPointUrl)
      .send({
        schoolName: config.schoolName,
        schoolAdress: config.address,
        bucket: config.storageBucket,
        schoolLogoImage: config.logoUrl,
        ccEmail: config.adminEmail,
        branchPath: firebase.sbp,
        accountName: firebase.sbDbName,
        timezone: moment.tz.guess(),
        currency: config.currency,
        currencyCode: config.currencyCode,
        email: config.adminEmail,
        invoice: invoiceObject,
        timezone: localTimezone,
        sendEmail: noParentEmail ? false : true,
      })
      .set("accept", "json")
      .end((err, res) => {
        //console.log("res", res.status);
        resolve(res);
      });
  });
  return p1;
}

function sendEmailOfInvoiceReceiptToParent(transaction, firebase) {
  let endPointUrl = firebase.endPointUrl;
  let sendInviteEndPointUrl = endPointUrl + "receiptEmail";

  let schoolConfiguration = localStorage.getItem("schoolConfig");
  let config = JSON.parse(schoolConfiguration);

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(sendInviteEndPointUrl)
      .send({
        schoolName: config.schoolName,
        schoolAdress: config.address,
        bucket: config.storageBucket,
        schoolLogoImage: config.logoUrl,
        ccEmail: config.adminEmail,
        branchPath: firebase.sbp,
        accountName: firebase.sbDbName,
        timezone: moment.tz.guess(),
        currency: config.currency,
        currencyCode: config.currencyCode,
        email: config.adminEmail,
        transaction: transaction,
        timezone: localTimezone,
      })
      .set("accept", "json")
      .end((err, res) => {
        //console.log("res", res.status);
        resolve(res);
      });
  });
  return p1;
}

function createTransactionUniqueId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/transactions/").push().key;
  return key;
}

function recordTransaction(transaction, nodeId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/transactions/" + nodeId).update(transaction);
}

function getInvoiceReceiptDownloadUrl(record, firebase) {
  let downloadUrl = {};
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/receiptPdf/" + record.id);
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      if (snap.val() !== null) {
        downloadUrl = snap.val();
      }
      resolve(downloadUrl);
    });
  });
  return promise1;
}

function getInvoiceReceiptDownloadUrlAlt(record, firebase) {
  let downloadUrl = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/receiptPdf/" + record.id);
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      if (snap.val() !== null) {
        downloadUrl.push(snap.val());
      }
      resolve(downloadUrl);
    });
  });
  return promise1;
}

function getInvoiceByDateRange(dateRange, firebase) {
  let startTime = moment(dateRange[0])
    .startOf("day")
    .valueOf();
  let endTime = moment(dateRange[1])
    .endOf("day")
    .valueOf();

  let invoices = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/invoices");
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((element) => {
        element.forEach((newEle) => {
          if (
            newEle !== null &&
            newEle.val().invoiceRaiseDate >= startTime &&
            newEle.val().invoiceRaiseDate < endTime
          ) {
            invoices.push(newEle.val());
          }
        });
      });
      resolve(invoices);
    });
  });
  return promise1;
}

function updateAggregatedInvoiceAfterPaymentRecordDeletion(
  studentId,
  record,
  firebase
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/aggregratedInvoices/" + studentId).update(record);
}

function deleteTransactionRecord(transactionId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/transactions/" + transactionId).set(null);
}

function getInvoiceCounter(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var counter = rsf
    .ref(branchPath + "/invoiceCounter")
    .once("value")
    .then(function(snap) {
      return snap.val();
    });
  return counter;
}

function incrementCounter(prevCounter, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/invoiceCounter").set(prevCounter + 1);
}

function getInvoiceByFeePlanId(feePlanId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let invoices = [];
  const myRef = rsf.ref(branchPath + "/invoices");
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((element) => {
        element.forEach((newEle) => {
          if (
            newEle !== null &&
            newEle.val().feePlanId &&
            newEle.val().feePlanId === feePlanId
          ) {
            invoices.push(newEle.val());
          }
        });
      });
      resolve(invoices);
    });
  });
  return promise1;
}

function getInvoiceByFeePlanIdAndDateRange(
  feePlanId,
  startDate,
  endDate,
  firebase,
  componentName
) {
  let startTime = moment(startDate)
    .startOf("month")
    .valueOf();

  let endTime = moment(endDate)
    .endOf("month")
    .valueOf();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let invoices = [];
  const myRef = rsf.ref(branchPath + "/invoices");
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((element) => {
        element.forEach((newEle) => {
          if (
            newEle !== null &&
            newEle.val().feePlanId &&
            newEle.val().feePlanId === feePlanId &&
            newEle.val().invoiceRaiseDate > startTime &&
            newEle.val().invoiceRaiseDate < endTime
          ) {
            if (newEle.val().lineItems) {
              let items = newEle.val().lineItems;
              for (let l in items) {
                if (
                  items[l].description.toLowerCase() ===
                  componentName.toLowerCase()
                ) {
                  invoices.push(newEle.val());
                  break;
                }
              }
            }
          }
        });
      });
      resolve(invoices);
    });
  });
  return promise1;
}

function getInvoiceReceiptList(record, firebase) {
  let downloadUrl = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/receiptPdfUrls/" + record.id);
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snapshot) {
      snapshot.forEach((snap) => {
        if (snap.val() !== null) {
          downloadUrl.push(snap.val());
        }
      });
      resolve(downloadUrl);
    });
  });
  return promise1;
}

function getStudentAggregatedInvoices(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/aggregratedInvoices");

  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      var aggregatedInvoices = [];
      snap.forEach((element) => {
        if (element !== null) {
          let classList =
            firebase && firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            if (classList.length > 0) {
              if (
                UserImageAction.ifClassAccess(
                  classList,
                  element.val().studentId
                )
              ) {
                aggregatedInvoices.push(element.val());
              }
            } else {
              aggregatedInvoices.push(element.val());
            }
          } else {
            aggregatedInvoices.push(element.val());
          }
        }
      });
      emit(aggregatedInvoices);
    });
    return () => {
      myRef.off();
      console.log("unsubscribe student billing aggregated invoices");
    };
  });
}

function getInvoiceByStudentId(startDate, endDate, studentId, firebase) {
  let start = moment(startDate).valueOf();
  let end = moment(endDate).valueOf();
  let invoices = [];

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let invoiceRef;
  if (startDate && endDate) {
    invoiceRef = rsf
      .ref(branchPath + "/invoices/" + studentId)
      .orderByChild("invoiceRaiseDate")
      .startAt(start)
      .endAt(end);
  } else {
    invoiceRef = rsf
      .ref(branchPath + "/invoices/" + studentId)
      .orderByChild("inverseDate");
  }
  var promise1 = new Promise(function(resolve, reject) {
    invoiceRef.once("value").then(function(snap) {
      snap.forEach((ele) => {
        if (ele.val() !== null) {
          invoices.push(ele.val());
        }
      });
      resolve(invoices);
    });
  });
  return promise1;
}

function getTransactionById(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let transaction = {};
  var promise1 = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/transactions/" + id)
      .once("value")
      .then(function(snap) {
        if (snap.val() !== null) {
          transaction = snap.val();
        }
        resolve(transaction);
      });
  });
  return promise1;
}

function generateAggInvoiceNode(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/aggregratedInvoices").push().key;
  return key;
}

function generatedCreditNoteId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/creditHistory").push().key;
  return key;
}

function updateCreditAddHistory(
  id,
  student,
  amount,
  firebase,
  updatePdf,
  approvedBy,
  reason
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let creditObj = {
    id: id,
    studentId: student.id,
    studentName: student.name,
    description: "Amount credited",
    amountAdded: Number(amount),
    amountWithdrawn: 0,
    lastUpdatedBy: firebase.teacher.name,
    lastUpdatedOn: moment().valueOf(),
    platform: "web",
    inverseDate: -moment().valueOf(),
    approvedBy: approvedBy,
    reason: reason,
  };

  rsf
    .ref(branchPath + "/creditHistory/" + student.id + "/" + id)
    .update(creditObj);

  if (updatePdf) {
    updateCreditAdditionPdd(firebase, creditObj);
  }
}

function updateCreditAdditionPdd(firebase, obj) {
  let endPointUrl = firebase.endPointUrl + "creditNoteEmail";

  let config = firebase.schoolConfig;

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(endPointUrl)
      .send({
        schoolName: config.schoolName,
        schoolAdress: config.address,
        bucket: config.storageBucket,
        schoolLogoImage: config.logoUrl,
        ccEmail: config.adminEmail,
        branchPath: firebase.sbp,
        accountName: firebase.sbDbName,
        timezone: moment.tz.guess(),
        currency: config.currency,
        currencyCode: config.currencyCode,
        email: config.adminEmail,
        creditNote: obj,
        timezone: localTimezone,
      })
      .set("accept", "json")
      .end((err, res) => {
        console.log("credit note pdf error -----", err);
        console.log("credit note pdf response -----", res);
        if (res) {
          resolve(res);
        } else {
          if (err) {
            reject(err);
          }
        }
      });
  });
  return p1;
}

function updateCreditWithdrawnHistory(id, student, amount, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/creditHistory/" + student.id + "/" + id).update({
    id: id,
    studentId: student.id,
    studentName: student.name,
    description: "Amount withdrawn",
    amountAdded: 0,
    amountWithdrawn: Number(amount),
    lastUpdatedBy: firebase.teacher.name,
    lastUpdatedOn: moment().valueOf(),
    platform: "web",
    inverseDate: -moment().valueOf(),
  });
}

function getCreditHistory(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let history = [];
  var promise1 = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/creditHistory/" + id)
      .once("value")
      .then(function(snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            history.push(snap.val());
          }
        });
        resolve(history);
      });
  });
  return promise1;
}

function getAllCreditHistory(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let history = [];
  var promise1 = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/creditHistory")
      .once("value")
      .then(function(snapshot) {
        snapshot.forEach((element) => {
          element.forEach((snap) => {
            if (snap.val() !== null) {
              let tempVal = snap.val();
              tempVal.studentId = element.key;
              history.push(tempVal);
            }
          });
        });
        resolve(history);
      });
  });
  return promise1;
}

function deleteCreditHistory(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/creditHistory/" + record.studentId + "/" + record.id)
    .set(null);
}

function saveCardDetails(record, firebase, country) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/savedCardDetails/" + record.studentId).set({
    address: record.address ? record.address : null,
    cardHolderName: record.name ? record.name : null,
    postalCode: record.postalCode ? record.postalCode : null,
    city: record.city ? record.city : null,
    state: record.state ? record.state : null,
    country: country ? country : null,
  });
}

function getCardDetails(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let cardDetail = {};
  var promise1 = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/savedCardDetails/" + studentId)
      .once("value")
      .then(function(snap) {
        if (snap.val() !== null) {
          cardDetail = snap.val();
        }
        resolve(cardDetail);
      });
  });
  return promise1;
}

function getTaxReference(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let taxRef = [];
  var promise1 = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/taxReference")
      .once("value")
      .then(function(snap) {
        snap.forEach((ele) => {
          if (ele) {
            taxRef.push(ele.val());
          }
        });
        resolve(taxRef);
      });
  });
  return promise1;
}

function generateInvoiceReceipt(invoice, firebase) {
  let endPointUrl = firebase.endPointUrl;
  let sendInviteEndPointUrl = endPointUrl + "duplicateReciept";

  let schoolConfiguration = localStorage.getItem("schoolConfig");
  let config = JSON.parse(schoolConfiguration);

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(sendInviteEndPointUrl)
      .send({
        schoolName: config.schoolName,
        schoolAdress: config.address,
        bucket: config.storageBucket,
        schoolLogoImage: config.logoUrl,
        ccEmail: config.adminEmail,
        branchPath: firebase.sbp,
        accountName: firebase.sbDbName,
        timezone: moment.tz.guess(),
        currency: config.currency,
        currencyCode: config.currencyCode,
        email: config.adminEmail,
        invoice: invoice,
        timezone: localTimezone,
      })
      .set("accept", "json")
      .end((err, res) => {
        //console.log("res", res.status);
        resolve(res);
      });
  });
  return p1;
}

function getCreditPdf(id, firebase) {
  let downloadUrl = {};
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/creditNotePdf/" + id);
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      if (snap.val() !== null) {
        downloadUrl.pdfUrl = snap.val();
      }

      resolve(downloadUrl);
    });
  });
  return promise1;
}

export const InvoiceApi = {
  getAllInvoice,
  getAllAggregatedInvoice,
  getInvoiceDownloadUrl,
  getInvoiceReceiptDownloadUrl,
  generateInvoiceNode,
  addNewInvoice,
  getAggregatedInvoice,
  addAmountToNewAggregatedInvoice,
  updatedAmountToAggregatedInvoice,
  updateInvoice,
  recordPayment,
  getInvoiceById,
  deleteInvoice,
  sendEmailOfInvoiceToParent,
  sendEmailOfInvoiceReceiptToParent,
  createTransactionUniqueId,
  recordTransaction,
  getInvoiceByDateRange,
  updateAggregatedInvoiceAfterPaymentRecordDeletion,
  deleteTransactionRecord,
  getInvoiceCounter,
  incrementCounter,
  getInvoiceByFeePlanId,
  getInvoiceByFeePlanIdAndDateRange,
  getInvoiceReceiptList,
  getStudentAggregatedInvoices,
  getInvoiceByStudentId,
  getTransactionById,
  getInvoiceReceiptDownloadUrlAlt,
  generatedCreditNoteId,
  updateCreditAddHistory,
  updateCreditWithdrawnHistory,
  getCreditHistory,
  generateAggInvoiceNode,
  getAllCreditHistory,
  deleteCreditHistory,
  saveCardDetails,
  getCardDetails,
  getTaxReference,
  generateInvoiceReceipt,
  getCreditPdf,
};
