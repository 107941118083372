import actions from "./actions";
const initState = {
  students: [],
  studentAttendance: [],
  staffs: [],
  staffAttendance: [],
  aggregatedInvoice: [],
  dailyReport: [],
  todaysPost: [],
  classrooms: [],
  activityStat: [],
  totalInvoice: [],
  invoices: [],
  alertNotification: [],
  allAlerts: [],
  amountDueToday: 0,
  totalPaymentCollected: 0,
  totalPaymentCollectedLifetime: 0,
  totalPaymentPendingLifetime: 0,
  concerns: [],
  notes: [],
  parentUnreadMsgCount: 0,
  isLoading: false,
  dashboardStudentAtdChannel: undefined,
  dashboardStaffAtdChannel: undefined,
  alertNotificationChan: undefined,
  leadsCreatedToday: 0,
  followUpDueToday: 0,
  followUpOverdue: 0,
  dashboardData: [],
  configChan: undefined,
  teacherChan: undefined,
  error: false,
  upcomingMeeting: [],
  upcomingMeetingChan: undefined,

  dashboardMeetingActivity: [],
  dashboardClasses: [],
  dashboardStudents: [],
  dashboardTeachers: [],
  dashboardEvent: [],
  dashboardBirthdays: [],
  dashboardStudentLeaves: [],
  dashboardStaffLeaves: [],
  dashboardParentCommunication: {},
  dashboardStudentAttendance: {},
  dashboardStaffAttendance: {},
  dashboardActivity: {},
  dashboardLearning: {},
  dashboardLearningPlan: {},
  dashboardLeads: {},
  dashboardFinance: {},
  newDashboardStatsChannel: undefined,
  dashboardActivityChannel: undefined,
  dashboardLearningChan: undefined,
  dashboardLeadsChan: undefined,
  dashboardFinanceChan: undefined,
};
export default function landingPageReducer(
  state = initState,
  { type, ...action }
) {
  switch (type) {
    case actions.GET_STUDENT:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_STUDENT_SUCCESSFUL:
      return {
        ...state,
        students: action.students,
        isLoading: false,
      };

    case actions.GET_STUDENT_ATTENDANCE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_STUDENT_ATTENDANCE_SUCCESSFUL:
      return {
        ...state,
        studentAttendance: action.studentAttendance,
        dashboardStudentAtdChannel: action.dashboardStudentAtdChannel,
        isLoading: false,
      };

    case actions.GET_STAFF:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_STAFF_SUCCESSFUL:
      return {
        ...state,
        staffs: action.staffs,
        isLoading: false,
      };

    case actions.GET_STAFF_ATTENDANCE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_STAFF_ATTENDANCE_SUCCESSFUL:
      return {
        ...state,
        staffAttendance: action.staffAttendance,
        dashboardStaffAtdChannel: action.dashboardStaffAtdChannel,
        isLoading: false,
      };

    case actions.GET_AGGREGATED_INVOICE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_AGGREGATED_INVOICE_SUCCESSFUL:
      return {
        ...state,
        aggregatedInvoice: action.aggregatedInvoice,
        totalPaymentCollectedLifetime: action.totalPaymentCollectedLifetime,
        totalPaymentPendingLifetime: action.totalPaymentPendingLifetime,
        isLoading: false,
      };

    case actions.GET_DAILY_REPORT:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_DAILY_REPORT_SUCCESSFUL:
      return {
        ...state,
        dailyReport: action.dailyReport,
        isLoading: false,
      };

    case actions.GET_TODAYS_POST:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_TODAYS_POST_SUCCESSFUL:
      return {
        ...state,
        todaysPost: action.todaysPost,
        isLoading: false,
      };

    case actions.GET_CLASSROOM:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_CLASSROOM_SUCCESSFUL:
      return {
        ...state,
        classrooms: action.classrooms,
        isLoading: false,
      };

    case actions.GET_ACTIVITY_STAT:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_ACTIVITY_STAT_SUCCESSFUL:
      return {
        ...state,
        dashboardData: action.dashboardData,
        studentAttendance: action.studentAttendance,
        staffAttendance: action.staffAttendance,
        isLoading: false,
      };

    case actions.GET_INVOICE_STATS_SUCCESSFUL:
      return {
        ...state,
        totalInvoice: action.totalInvoice,
        invoices: action.invoices,
        amountDueToday: action.amountDueToday,
        totalPaymentCollected: action.amountCollectedToday,
        isLoading: false,
      };

    case actions.GET_OPEN_CONCERNS_SUCCESSFFUL:
      return {
        ...state,
        concerns: action.openConcerns,
        isLoading: false,
      };

    case actions.GET_OPEN_NOTES_SUCCESSFFUL:
      return {
        ...state,
        notes: action.openNotes,
        isLoading: false,
      };

    case actions.GET_UNREAD_PARENT_MSG_SUCCESSFFUL:
      return {
        ...state,
        parentUnreadMsgCount: action.parentUnreadMsgCount,
        isLoading: false,
      };

    case actions.GET_UNREAD_ALERT_NOTIFICATION:
      return {
        ...state,
      };

    case actions.GET_UNREAD_ALERT_NOTIFICATION_SUCCESSFUL:
      return {
        ...state,
        alertNotification: action.alertNotification,
        alertNotificationChan: action.alertNotificationChan,
      };

    case actions.GET_ALERT_NOTIFICATIONS:
      return {
        ...state,
      };

    case actions.GET_ALERT_NOTIFICATIONS_SUCCESSFUL:
      return {
        ...state,
        allAlerts: action.allAlerts,
      };

    case actions.GET_DASHBOARD_LEADS_SUCCESSFUL:
      return {
        ...state,
        leadsCreatedToday: action.leadsCreatedToday,
        followUpDueToday: action.followUpDueToday,
        followUpOverdue: action.followUpOverdue,
      };

    case actions.INITIAL_FIREBASE_CONFIG_SUCCESS:
      return {
        ...state,
        configChan: action.configChan,
      };

    case actions.GET_INIT_TEACHER_SUCCESS:
      return {
        ...state,
        teacherChan: action.teacherChan,
      };

    case actions.DASHBOARD_REQ_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case actions.GET_UPCOMING_MEETING_SUCCESS:
      return {
        ...state,
        upcomingMeeting: action.upcomingMeeting,
        upcomingMeetingChan: action.upcomingMeetingChan,
      };

    case actions.GET_DASHBOARD_MEETINGS:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_DASHBOARD_MEETINGS_SUCCESS:
      return {
        ...state,
        dashboardMeetingActivity: action.dashboardMeetingActivity,
        isLoading: false,
      };

    case actions.GET_DASHBOARD_STATS:
      return {
        ...state,
      };

    case actions.GET_DASHBOARD_STATS_SUCCESS:
      return {
        ...state,
        dashboardClasses: action.dashboardClasses,
        dashboardStudents: action.dashboardStudents,
        dashboardTeachers: action.dashboardTeachers,
      };

    case actions.GET_DASHBOARD_EVENT_SUCCESS:
      return {
        ...state,
        dashboardEvent: action.dashboardEvent,
      };

    case actions.GET_DASHBOARD_BIRTHDAY_SUCCESS:
      return {
        ...state,
        dashboardBirthdays: action.dashboardBirthdays,
      };

    case actions.GET_DASHBOARD_STUDENT_LEAVES:
      return {
        ...state,
        dashboardStudentLeaves: action.dashboardStudentLeaves,
      };

    case actions.GET_DASHBOARD_STAFF_LEAVES:
      return {
        ...state,
        dashboardStaffLeaves: action.dashboardStaffLeaves,
      };

    case actions.GET_NEW_DASHBOARD_STATS:
      return {
        ...state,
        dashboardParentCommunication: action.dashboardParentCommunication,
        newDashboardStatsChannel: action.newDashboardStatsChannel,
      };

    case actions.GET_NEW_DASHBOARD_ATTENDANCE_STATS:
      return {
        ...state,
        dashboardStudentAttendance: action.dashboardStudentAttendance,
        newDashboardAttendanceStatsChannel:
          action.newDashboardAttendanceStatsChannel,
      };

    case actions.GET_NEW_DASHBOARD_STAFF_ATTENDANCE_STATS:
      return {
        ...state,
        dashboardStaffAttendance: action.dashboardStaffAttendance,
        newDashboardStaffAttendanceStatsChannel:
          action.newDashboardStaffAttendanceStatsChannel,
      };

    case actions.GET_NEW_DASHBOARD_ACTIVITY_STATS:
      return {
        ...state,
        dashboardActivity: {},
      };

    case actions.GET_NEW_DASHBOARD_ACTIVITY_STATS_SUCCESS:
      return {
        ...state,
        dashboardActivity: action.dashboardActivity,
        dashboardActivityChannel: action.dashboardActivityChannel,
      };

    case actions.GET_NEW_DASHBOARD_LEARNING_STATS:
      return {
        ...state,
      };

    case actions.GET_NEW_DASHBOARD_LEARNING_STATS_SUCCESS:
      return {
        ...state,
        dashboardLearning: action.dashboardLearning,
        dashboardLearningChan: action.dashboardLearningChan,
      };

    case actions.GET_NEW_DASHBOARD_LEADS_STATS:
      return {
        ...state,
      };

    case actions.GET_NEW_DASHBOARD_LEADS_STATS_SUCCESS:
      return {
        ...state,
        dashboardLeads: action.dashboardLeads,
        dashboardLeadsChan: action.dashboardLeadsChan,
      };

    case actions.GET_NEW_DASHBOARD_LEARNING_PLAN_SUCCESS:
      return {
        ...state,
        dashboardLearningPlan: action.dashboardLearningPlan,
      };

    case actions.GET_NEW_DASHBOARD_FINANCE_STATS:
      return {
        ...state,
      };

    case actions.GET_NEW_DASHBOARD_FINANCE_STATS_SUCCESS:
      return {
        ...state,
        dashboardFinance: action.dashboardFinance,
        dashboardFinanceChan: action.dashboardFinanceChan,
      };

    default:
      return state;
  }
}
