// import { classroom } from "./reducer";
const actions = {
  LIST_CLASSROOMS: 'LIST_CLASSROOMS',
  LIST_CLASSROOM: 'LIST_CLASSROOM',
  UPDATE_CLASSROOM: 'UPDATE_CLASSROOM',
  UPDATE_CLASSROOM_SUCCESSFUL: 'UPDATE_CLASSROOM_SUCCESSFUL',
  ADD_CLASSROOM: 'ADD_CLASSROOM',
  ADD_CLASSROOM_COMPLETED: 'ADD_CLASSROOM_COMPLETED',
  GET_CENTER_DATA: 'GET_CENTER_DATA',
  GET_CENTER_DATA_SUCCESSFUL: 'GET_CENTER_DATA_SUCCESSFUL',
  AUTOSEARCH_CENTER_NAME: 'AUTOSEARCH_CENTER_NAME',
  TOGGLE_DATA: 'TOGGLE_DATA',
  TOGGLE_DATA_SUCCESSFUL: 'TOGGLE_DATA_SUCCESSFUL',
  REQUEST_FAILED: 'REQUEST_FAILED',
  DELETE_CLASS: "DELETE_CLASS",
  DELETE_CLASS_SUCCESS: "DELETE_CLASS_SUCCESS",
  START_CLASS_LOADER: "START_CLASS_LOADER",

  initClassroomData: (firebase) => ({ type: actions.LIST_CLASSROOMS, firebase }),

  // fetchAutoCompleteCenter: () => ({type: actions.GET_CENTER_DATA}),

  addClassroom: (name, firebase) => ({
    type: actions.ADD_CLASSROOM,
    name,
    firebase
  }),

  updateClassroom: (updatedName, classroomId, firebase) => ({
    type: actions.UPDATE_CLASSROOM,
    updatedName,
    classroomId,
    firebase
  }),

  updateToggleData: (disableStatus, docId) => ({
    type: actions.TOGGLE_DATA,
    disableStatus,
    docId
  }),

  deleteClassroom: (record, firebase) => ({
    type: actions.DELETE_CLASS,
    record,
    firebase
  })
};

export default actions;
