import "@firebase/firestore"; // 👈 If you're using firestore
import { eventChannel } from "redux-saga";
import moment from "moment-timezone";
const superagent = require("superagent");

function getLessonStats(firebase) {
  let endPointUrl = firebase.endPointUrl;
  let lessonStatsEndpoint = endPointUrl + "updateLessonStatsTeacher";

  let schoolConfiguration = localStorage.getItem("schoolConfig");
  let config = JSON.parse(schoolConfiguration);

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(lessonStatsEndpoint)
      //.set('Authorization', Utility.messagingToken)
      .send({
        schoolName: config.schoolName,
        branchPath: firebase.sbp,
        accountName: firebase.sbDbName,
        classList: firebase.teacher.classList,
        timezone: localTimezone,
      })
      .set("accept", "json")

      .end((err, res) => {
        console.log("res lesson stats ------", res);
        resolve(res);
      });
  });
  return p1;
}

function getAllAssignment(firebase, classroom, limit) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var myRef;
  if (limit && classroom === "All Room") {
    myRef = rsf.ref(branchPath + "/schoolAssignment").limitToLast(limit);
  } else if (limit && classroom !== "All Room") {
    myRef = rsf.ref(branchPath + "/schoolAssignment");
  } else if (!limit && classroom) {
    myRef = rsf.ref(branchPath + "/schoolAssignment");
  }

  return eventChannel((emit) => {
    myRef.on("value", (snapshot) => {
      var lessonRef = [];
      snapshot.forEach((snap) => {
        if (classroom === "All Room") {
          if (snap.val() !== null && snap.val().delete === false) {
            lessonRef.push(snap.val());
          }
        } else if (classroom !== "All Room") {
          if (
            snap.val() !== null &&
            snap.val().delete === false &&
            (snap.val().classroom === classroom ||
              snap.val().classroom === "All Classrooms")
          ) {
            lessonRef.push(snap.val());
          }
        }
      });
      emit(lessonRef);
    });
    return () => {
      rsf.ref(branchPath + "/schoolAssignment").off();
      console.log("unsubscribe schoolAssignment ");
    };
  });
}

function getLessonSubmission(assignmentId, firebase, studentId) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var myRef;
  if (studentId) {
    myRef = rsf
      .ref(branchPath + "/lessonSubmissions/" + assignmentId)
      .orderByChild("studentId")
      .equalTo(studentId);
  } else {
    myRef = rsf.ref(branchPath + "/lessonSubmissions/" + assignmentId);
  }
  let promise = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snapshot) {
      let value = [];
      snapshot.forEach((snap) => {
        if (snap.val() !== null) {
          value.push(snap.val());
        }
      });
      resolve(value);
    });
  });
  return promise;
}

function getCommentsCount(activityId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var myRef = rsf.ref(branchPath + "/comments/" + activityId);
  let promise = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snapshot) {
      let value = [];
      snapshot.forEach((snap) => {
        if (snap.val() !== null && !snap.val().deleted) {
          value.push(snap.val());
        }
      });
      resolve(value);
    });
  });
  return promise;
}

function getSchoolAssignmentById(assignmentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var myRef = rsf.ref(branchPath + "/schoolAssignment/" + assignmentId);
  let promise = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snapshot) {
      let value = {};
      if (snapshot.val() !== null && snapshot.val().delete === false) {
        value = snapshot.val();
      }
      resolve(value);
    });
  });
  return promise;
}

function getUnfilteredSchoolAssignment(assignmentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var myRef = rsf.ref(branchPath + "/schoolAssignment/" + assignmentId);
  let promise = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snapshot) {
      let value = {};
      if (snapshot.val() !== null) {
        value = snapshot.val();
      }
      resolve(value);
    });
  });
  return promise;
}

function getAllSchoolAssignment(
  firebase,
  classroom,
  limit,
  startDate,
  endDate
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let startTime = moment(startDate)
    .startOf("day")
    .valueOf();
  let endTime = moment(endDate)
    .endOf("day")
    .valueOf();

  let myRef;
  if (limit && classroom === "All Room") {
    myRef = rsf
      .ref(branchPath + "/schoolAssignment")
      .orderByChild("inverseDate")
      .startAt(-endTime)
      .endAt(-startTime)
      .limitToFirst(limit);
  } else if (limit && classroom !== "All Room") {
    myRef = rsf
      .ref(branchPath + "/schoolAssignment")
      .orderByChild("inverseDate")
      .startAt(-endTime)
      .endAt(-startTime);
  } else if (!limit && classroom) {
    myRef = rsf
      .ref(branchPath + "/schoolAssignment")
      .orderByChild("inverseDate")
      .startAt(-endTime)
      .endAt(-startTime);
  }

  var lessonRef = [];
  let promise = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snapshot) {
      snapshot.forEach((snap) => {
        if (classroom === "All Room") {
          if (snap.val() !== null && snap.val().delete === false) {
            if (firebase.teacher && !firebase.teacher.superUser) {
              let classList =
                firebase && firebase.teacher && firebase.teacher.classList
                  ? firebase.teacher.classList
                  : [];
              if (classList.length > 0) {
                if (snap.val().classNames) {
                  let assignmentClasses = snap.val().classNames;
                  for (let index in classList) {
                    let filteredVal = assignmentClasses.filter((f) => {
                      return f.toLowerCase() === classList[index].toLowerCase();
                    });

                    if (filteredVal && filteredVal.length > 0) {
                      lessonRef.push(snap.val());
                      break;
                    }
                  }
                }
              } else {
                lessonRef.push(snap.val());
              }
            } else {
              lessonRef.push(snap.val());
            }
          }
        } else if (classroom !== "All Room") {
          if (
            snap.val() !== null &&
            snap.val().delete === false &&
            snap.val().classNames
          ) {
            if (snap.val().classNames.includes(classroom)) {
              lessonRef.push(snap.val());
            }
          }
        }
      });
      resolve(lessonRef);
    });
  });
  return promise;
}

function updateSchoolAssignment(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/schoolAssignment/" + record.id).update(record);
}

function getStudentLessonStats(firebase, studentId, startDate, endDate) {
  let startTime = moment(startDate)
    .startOf("day")
    .valueOf();
  let endTime = moment(endDate)
    .endOf("day")
    .valueOf();

  let endPointUrl = firebase.endPointUrl;
  let lessonStatsEndpoint = endPointUrl + "updateLessonStats";

  let schoolConfiguration = localStorage.getItem("schoolConfig");
  let config = JSON.parse(schoolConfiguration);

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(lessonStatsEndpoint)
      .send({
        schoolName: config.schoolName,
        branchPath: firebase.sbp,
        accountName: firebase.sbDbName,
        studentId: studentId,
        startDate: startTime,
        endDate: endTime,
        timezone: localTimezone,
      })
      .set("accept", "json")

      .end((err, res) => {
        console.log("res lesson stats ------", res);
        resolve(res);
      });
  });
  return p1;
}

function getStudentLessonStatsData(firebase, studentId) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let myRef = rsf.ref(branchPath + "/parentDashboard/" + studentId);
  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      let stats = {};
      if (snap.val() !== null) {
        stats = snap.val();
      }
      emit(stats);
    });
    return () => {
      rsf.ref(branchPath + "/parentDashboard").off();
      console.log("unsubscribe parent dashboard ");
    };
  });
}

function getAssignmentRef(
  firebase,
  studentId,
  limit,
  startDate,
  endDate,
  filterBy
) {
  let startTime = moment(startDate)
    .startOf("day")
    .valueOf();
  let endTime = moment(endDate)
    .endOf("day")
    .valueOf();
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var myRef;
  if (limit) {
    myRef = rsf
      .ref(branchPath + "/studentAssignments/" + studentId)
      .orderByChild("inversedSharedDate")
      .startAt(-endTime)
      .endAt(-startTime)
      .limitToFirst(limit);
  } else {
    myRef = rsf
      .ref(branchPath + "/studentAssignments/" + studentId)
      .orderByChild("inversedSharedDate")
      .startAt(-endTime)
      .endAt(-startTime);
  }

  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      var lessonRef = [];
      snap.forEach((child) => {
        if (child.val() !== null) {
          if (filterBy) {
            if (filterBy === "Completed" && child.val().done) {
              lessonRef.push(child.val());
            }
            if (filterBy === "Pending" && !child.val().done) {
              lessonRef.push(child.val());
            } else if (filterBy === "Stars" && child.val().star) {
              lessonRef.push(child.val());
            } else if (filterBy === "All") {
              lessonRef.push(child.val());
            }
          }
        } else {
          lessonRef.push(child.val());
        }
      });
      emit(lessonRef);
    });
    return () => {
      rsf.ref(branchPath + "/studentAssignments/" + studentId).off();
      console.log("unsubscribe schoolAssignment ");
    };
  });
}

function getLessonSubmissionByStudentId(assignmentId, studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var myRef = rsf.ref(
    branchPath + "/lessonSubmissions/" + assignmentId + "/" + studentId
  );
  let promise = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snapshot) {
      let value = {};
      if (snapshot.val() !== null) {
        value = snapshot.val();
      }
      resolve(value);
    });
  });
  return promise;
}

function createStudentSubmission(
  assignmentId,
  activityId,
  studentId,
  activityDate,
  parentName,
  firebase
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let createdOn = {
    date: activityDate.getDate(),
    day: activityDate.getDay(),
    hours: activityDate.getHours(),
    minutes: activityDate.getMinutes(),
    month: activityDate.getMonth(),
    seconds: activityDate.getSeconds(),
    time: activityDate.getTime(),
    timezoneOffset: activityDate.getTimezoneOffset(),
    year: activityDate.getFullYear() - 1900,
  };

  rsf
    .ref(branchPath + "/lessonSubmissions/" + assignmentId + "/" + studentId)
    .update({
      activityId: activityId,
      createdBy: parentName ? parentName : "Parent",
      date: createdOn,
      inverseDate: -activityDate.getTime(),
      studentId: studentId,
    });
}

function getStudentAssignmentById(id, studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var myRef = rsf
    .ref(branchPath + "/studentAssignments/" + studentId)
    .orderByChild("assignmentId")
    .equalTo(id);
  let promise = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snapshot) {
      let value = [];
      snapshot.forEach((ele) => {
        if (ele.val() !== null) {
          value.push(ele.val());
        }
      });
      resolve(value);
    });
  });
  return promise;
}

function updateStudentAssignment(studentId, id, obj, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/studentAssignments/" + studentId + "/" + id)
    .update(obj);
}

function getTeachersByStudentClass(className, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var myRef = rsf
    .ref(branchPath + "/teachers")
    .orderByChild("deleted")
    .equalTo(false);
  let allTeachers = [];

  let promise = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snapshot) {
      snapshot.forEach((element) => {
        if (
          element.val() !== null &&
          element.val().deleted === false &&
          element.val().classList
        ) {
          if (element.val().classList.includes(className)) {
            allTeachers.push(element.val());
          }
        }
      });
      resolve(allTeachers);
    });
  });
  return promise;
}

function getAssignmentByLessonId(lessonId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let myRef = rsf
    .ref(branchPath + "/schoolAssignment")
    .orderByChild("lessonId")
    .equalTo(lessonId);
  let assignments = [];
  let promise = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snapshot) {
      snapshot.forEach((snap) => {
        if (snap.val() !== null && !snap.val().delete) {
          assignments.push(snap.val());
        }
      });
      resolve(assignments);
    });
  });
  return promise;
}

function getAllStudentLessonStatsData(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let myRef = rsf.ref(branchPath + "/parentDashboard");
  return eventChannel((emit) => {
    myRef.on("value", (snapshot) => {
      let stats = new Map();
      snapshot.forEach((snap) => {
        if (snap.val() !== null) {
          stats.set(snap.key, snap.val());
        }
      });
      emit(stats);
    });
    return () => {
      rsf.ref(branchPath + "/parentDashboard").off();
      console.log("unsubscribe parent dashboard ");
    };
  });
}

function fetchStudentAssignmentById(id, studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var myRef = rsf
    .ref(branchPath + "/studentAssignments/" + studentId)
    .orderByChild("assignmentId")
    .equalTo(id);

  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      var lessonRef = [];
      snap.forEach((child) => {
        if (child.val()) {
          lessonRef.push(child.val());
        }
      });
      emit(lessonRef);
    });
    return () => {
      myRef.off();
    };
  });
}

export const lessonAssignmentApi = {
  getLessonStats,
  getAllAssignment,
  getLessonSubmission,
  getCommentsCount,
  getSchoolAssignmentById,
  getUnfilteredSchoolAssignment,
  getLessonSubmissionByStudentId,
  getAllSchoolAssignment,
  updateSchoolAssignment,
  getStudentLessonStats,
  getStudentLessonStatsData,
  getAssignmentRef,
  createStudentSubmission,
  getStudentAssignmentById,
  updateStudentAssignment,
  getTeachersByStudentClass,
  getAssignmentByLessonId,
  getAllStudentLessonStatsData,
  fetchStudentAssignmentById,
};
