import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import '@firebase/firestore' // 👈 If you're using firestore
import { ClassroomApi } from '../../firestore-api/classroom';
import { TeacherApi } from "../../firestore-api/teacher";
import bugsnagClient from '@bugsnag/js';

function* listClassrooms({ firebase }) {
  let fullClassroomData = JSON.parse(localStorage.getItem("classList"));
  yield put({
    type: actions.LIST_CLASSROOM,
    classrooms: fullClassroomData,
    classChannel: undefined
  });

  // let fullClassroomData = [];
  // const chan = yield call(ClassroomApi.getAllClassrooms, firebase);
  // try {
  //   while (true) {
  //     let data = yield take(chan);
  //     fullClassroomData = data;
  //     fullClassroomData.sort((a, b) => a.className.localeCompare(b.className));
  //     yield put({
  //       type: actions.LIST_CLASSROOM,
  //       classrooms: fullClassroomData,
  //       classChannel: chan
  //     });
  //   }
  // } finally {
  //   console.log("close channel");
  // }
}

function* addClassroom({ name, firebase }) {
  var uniqueId = null;
  try {
    uniqueId = yield call(ClassroomApi.createClassroomUniqueNodeId, firebase);
    if (uniqueId !== null) {
      yield call(ClassroomApi.addNewClassroom, name, uniqueId, firebase);
      yield put({
        type: actions.ADD_CLASSROOM_COMPLETED,
        operationType: "ADD_CLASSROOM"
      });

      if (firebase && firebase.teacher) {
        let teacher = JSON.parse(JSON.stringify(firebase.teacher));
        let newClassList = JSON.parse(JSON.stringify(teacher.classList));
        newClassList.push(name);
        teacher.classList = newClassList;
        yield call(
          TeacherApi.updateTeacherObject,
          teacher,
          teacher.id,
          firebase
        );
      }
    }
  } catch (error) {
    console.log(error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.REQUEST_FAILED,
      errorInfo: error
    });
  }
}

export function* updateClassroom({ updatedName, classroomId, firebase }) {
  try {
    yield call(ClassroomApi.updateExistingClassroom, updatedName, classroomId, firebase);
    yield put({
      type: actions.UPDATE_CLASSROOM_SUCCESSFUL,
      operationType: "UPDATE_CLASSROOM"
    })
  } catch (error) {
    console.log(error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.REQUEST_FAILED,
      errorInfo: error
    });
  }
}

function* deleteClass({ record, firebase }) {
  try {
    yield call(ClassroomApi.deleteClassroom, record, firebase);
    yield put({
      type: actions.DELETE_CLASS_SUCCESS,
    })
  } catch (error) {
    console.log(error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.REQUEST_FAILED,
      errorInfo: error.message ? error.message : error
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.LIST_CLASSROOMS, listClassrooms),
    yield takeEvery(actions.ADD_CLASSROOM, addClassroom),
    yield takeEvery(actions.UPDATE_CLASSROOM, updateClassroom),
    yield takeEvery(actions.DELETE_CLASS, deleteClass),
  ]);
}
