import { all, put, call, take, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore";
import { TimelineApi } from "../../firestore-api/timeline";
import { ActivityApi } from "../../firestore-api/activity";
import { StudentApi } from "../../firestore-api/student";
import bugsnagClient from "@bugsnag/js";
import { lessonAssignmentApi } from "../../firestore-api/lessonAssignment";
import { DashboardApi } from "../../firestore-api/dashboard";
import { NotificationApi } from "../../firestore-api/notification";
import moment from "moment-timezone";
import notification from "../../components/notification";
import ReactPixel from "react-facebook-pixel";

function* fetchStudentTimeline({
  studentId,
  firebase,
  date,
  prevTimeline,
  activityType,
  toggleVal,
  limit,
}) {
  if (prevTimeline && prevTimeline.length > 0) {
    yield put({
      type: actions.GET_STUDENT_TIMELINE_SUCCESSFUL,
      studentTimeline: prevTimeline,
      timelineChannel: undefined,
      previousTimelineMessage: undefined,
    });
  }
  let chan;
  if (activityType) {
    chan = yield call(
      TimelineApi.getStudentTimelineByActivity,
      studentId,
      firebase,
      date,
      activityType === "Notification" ? "Announcement" : activityType
    );
  } else if (toggleVal === "Auto") {
    chan = yield call(
      TimelineApi.getStudentTimelineByLimit,
      studentId,
      firebase,
      limit
    );
  } else {
    chan = yield call(
      TimelineApi.getStudentTimeline,
      studentId,
      firebase,
      date
    );
  }
  try {
    while (true) {
      let data = yield take(chan);

      let activityIds = data;

      let activitiesTask = [];
      for (const item in activityIds) {
        let task = call(
          TimelineApi.fetchAltTimelineActivities,
          activityIds[item],
          firebase
        );
        activitiesTask.push(task);
      }

      let newVal = yield all([activitiesTask]);
      var timelineActivityData = [];
      for (let i in newVal[0]) {
        let singleActivity = newVal[0][i];
        if (singleActivity) {
          timelineActivityData.push(singleActivity);
        }
      }

      timelineActivityData.sort(function(a, b) {
        var dateA = a.date.time,
          dateB = b.date.time;
        return dateB - dateA;
      });

      // console.log("timelineActivityData ---", timelineActivityData);

      yield put({
        type: actions.GET_STUDENT_TIMELINE_SUCCESSFUL,
        studentTimeline: timelineActivityData,
        timelineChannel: chan,
        previousTimelineMessage:
          activityIds.length > 0
            ? undefined
            : !activityType
            ? "show"
            : undefined,
      });

      if (timelineActivityData.length === 0) {
        if (!activityType && (!toggleVal || toggleVal !== "Auto")) {
          yield fork(fetchPrevTimeline, studentId, firebase);
        }
      } else if (timelineActivityData.length > 0) {
        yield fork(updateSeenCount, timelineActivityData, studentId, firebase);
        yield fork(fetchComments, timelineActivityData, firebase);
      }
    }
  } finally {
    console.log("terminating student timeline request");
  }
}

function* fetchPrevTimeline(studentId, firebase) {
  try {
    yield put({
      type: actions.GET_PREVIOUS_TIMELINE,
    });
    let activityIds = yield call(
      TimelineApi.getPreviousTimeline,
      studentId,
      firebase
    );

    let activitiesTask = [];
    for (const item in activityIds) {
      let task = call(
        TimelineApi.fetchAltTimelineActivities,
        activityIds[item],
        firebase
      );
      activitiesTask.push(task);
    }

    let newVal = yield all([activitiesTask]);
    var timelineActivityData = [];

    for (let i in newVal[0]) {
      let singleActivity = newVal[0][i];
      if (singleActivity) {
        timelineActivityData.push(singleActivity);
      }
    }

    yield put({
      type: actions.GET_PREVIOUS_TIMELINE_SUCCESS,
      studentTimeline: timelineActivityData,
      previousTimelineMessage: "show",
    });

    if (timelineActivityData.length > 0) {
      yield fork(updateSeenCount, timelineActivityData, studentId, firebase);
      yield fork(fetchComments, timelineActivityData, firebase);
    }
  } catch (err) {
    console.log("failed to fetch previous timeline", err);
    bugsnagClient.notify(err);
  }
}

function* updateSeenCount(timelineActivity, studentId, firebase) {
  try {
    if (firebase && firebase.isParent && !firebase.isGod) {
      let parentUserId = firebase.user.id;

      let studentDetail = yield call(
        StudentApi.getStudentById,
        studentId,
        firebase
      );

      if (studentDetail) {
        var userObject = {
          className: studentDetail.classroomName,
          classList: studentDetail.classList ? studentDetail.classList : [],
          date: moment().valueOf(),
          fatherId: studentDetail.fatherProfileId
            ? studentDetail.fatherProfileId
            : null,
          gender: studentDetail.gender ? studentDetail.gender : null,
          motherId: studentDetail.motherProfileId
            ? studentDetail.motherProfileId
            : null,
          parentName:
            studentDetail.fatherProfileId &&
            studentDetail.fatherProfileId === parentUserId
              ? studentDetail.fatherName
              : studentDetail.motherName,
          profileImageUrl: studentDetail.profileImageUrl
            ? studentDetail.profileImageUrl
            : null,
          relation:
            studentDetail.fatherProfileId &&
            studentDetail.fatherProfileId === parentUserId
              ? "Father"
              : "Mother",
          studentId: studentDetail.id,
          studentName: studentDetail.name,
        };

        let viewTask = [];
        for (let index in timelineActivity) {
          let activityId = timelineActivity[index].id;
          let task = call(
            TimelineApi.getActivityViewCount,
            activityId,
            firebase
          );
          viewTask.push(task);
        }

        let newVal = yield all([viewTask]);
        for (let i in newVal[0]) {
          let userNames = null;
          let viewCountNode;
          let activityViewCount = newVal[0][i];
          if (activityViewCount.id) {
            userNames = activityViewCount.names ? activityViewCount.names : [];
            let x = userNames.filter((ele) => {
              return (
                ele.studentId === userObject.studentId &&
                ele.relation === userObject.relation
              );
            });

            if (x.length === 0) {
              userNames.push(userObject);
              viewCountNode = {
                id: activityViewCount.id,
                names: userNames,
              };
            } else {
              viewCountNode = {
                id: activityViewCount.id,
                names: userNames,
              };
            }
            if (viewCountNode && viewCountNode.id && viewCountNode.names) {
              yield fork(
                TimelineApi.updateActivityViewCount,
                activityViewCount.id,
                viewCountNode,
                firebase
              );
            }
          }
        }
      }
    }
  } catch (err) {
    console.log("failed to update seen count", err);
    bugsnagClient.notify(err);
  }
}

function* fetchComments(timelineActivity, firebase) {
  try {
    let activities = timelineActivity;
    var commentsCount = new Map();

    let activitiesTask = [];
    for (const item in activities) {
      let task = call(
        lessonAssignmentApi.getCommentsCount,
        activities[item].id,
        firebase
      );
      activitiesTask.push(task);
    }

    let newVal = yield all([activitiesTask]);
    for (let i in newVal[0]) {
      let commentCountObj = newVal[0][i];
      if (commentCountObj && commentCountObj.length > 0) {
        commentsCount.set(commentCountObj[0].sourceId, commentCountObj);
      }
    }

    yield put({
      type: actions.GET_ACTIVITY_COMMENTS_SUCCESS,
      activityComments: commentsCount,
    });
  } catch (err) {
    console.log("failed to fetch timeline activity", err);
    bugsnagClient.notify(err);
  }
}

function* fetchStudentVirtualClass({ studentId, firebase }) {
  try {
    const chan = yield call(
      TimelineApi.getUpcomingVirtualClass,
      studentId,
      firebase
    );
    while (true) {
      let data = yield take(chan);

      let activityIds = {};
      let activities = [];

      for (let index in data) {
        let val = yield call(
          ActivityApi.getActivityById,
          data[index].activityId,
          firebase
        );
        if (
          val.id &&
          val.studentIds &&
          val.studentIds.includes(studentId) &&
          !activityIds[val.id]
        ) {
          activities.push(val);
        }
        activityIds[val.id] = val;
      }

      yield put({
        type: actions.GET_STUDENT_VIRTUAL_CLASS_SUCCESSFUL,
        studentVirtualClass: activities,
        virtualClassChannel: chan,
      });
    }
  } finally {
    console.log("terminating student virtual class");
  }
}

function* fetchStudentProfile({
  studentId,
  studentObj,
  firebase,
  operationType,
}) {
  try {
    if (!studentObj) {
      studentObj = yield call(StudentApi.getStudentById, studentId, firebase);
      if (studentObj) {
        firebase.student = studentObj;
        localStorage.setItem("student", JSON.stringify(studentObj));
        yield put({
          type: actions.GET_STUDENT_DETAIL_SUCCESS,
          studentProfile: studentObj,
          studentTimelineOperation: operationType ? operationType : undefined,
        });
      }
    } else {
      yield put({
        type: actions.GET_STUDENT_DETAIL_SUCCESS,
        studentProfile: studentObj,
        studentTimelineOperation: operationType ? operationType : undefined,
      });
    }

    if (firebase && firebase.isParent && !firebase.isGod) {
      yield fork(updateLastAccess, studentObj, firebase);
    }
  } catch (err) {
    console.log("failed to fetch student profile", err);
    bugsnagClient.notify(err);
  }
}

function* updateLastAccess(studentObj, firebase) {
  try {
    let parentId = firebase.user.id;
    let student = studentObj;
    if (student.fatherProfileId && student.fatherProfileId === parentId) {
      student.lastFatherAccess = moment().valueOf();
      yield fork(
        StudentApi.updateStudentWithUpdatedFormFields,
        student,
        firebase
      );
    } else if (
      student.motherProfileId &&
      student.motherProfileId === parentId
    ) {
      student.lastMotherAccess = moment().valueOf();
      yield fork(
        StudentApi.updateStudentWithUpdatedFormFields,
        student,
        firebase
      );
    }
  } catch (err) {
    console.log("failed to update last access of parent", err);
    bugsnagClient.notify(err);
  }
}

function* fetchStudentActivityById({ id, firebase }) {
  const chan = yield call(DashboardApi.getActivityPostById, id, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_STUDENT_TIMELINE_SUCCESSFUL,
        studentTimeline: data,
        timelineChannel: chan,
        previousTimelineMessage: undefined,
      });
      if (data && data.length > 0) {
        yield fork(fetchComments, data, firebase);
      }
    }
  } finally {
    console.log("terminating student timeline request");
  }
}

function* fetchBookableMeetings({
  startDate,
  endDate,
  student,
  firebase,
  fetchType,
  onlyBookingRef,
  selectedTimezone,
}) {
  try {
    let startTime = moment().valueOf();
    let bookings = yield call(
      TimelineApi.getBookings,
      startDate,
      endDate,
      student.classroomName,
      firebase,
      fetchType,
      selectedTimezone
    );
    console.log("bookings -> ", bookings);

    // check if booking ref has all data
    if (bookings) {
      if (onlyBookingRef) {
        let bookingsMap = new Map();

        let activityMap = new Map();
        const activitiesTask = [];
        for (let index in bookings) {
          let activityId = bookings[index].activityId;

          let task = call(
            TimelineApi.fetchAltTimelineActivities,
            activityId,
            firebase
          );
          activitiesTask.push(task);
        }

        let newVal = yield all([activitiesTask]);
        for (let i in newVal[0]) {
          let val = newVal[0][i];

          if (val && val.id) {
            activityMap.set(val.id, val);
          }
        }

        for (let index in bookings) {
          let booking = bookings[index];

          let tempActivityId = bookings[index].activityId;
          let tempActivity = activityMap.get(tempActivityId);
          if (
            tempActivity &&
            tempActivity.id &&
            (tempActivity.deleted || !tempActivity.meetingId)
          ) {
            console.log("booking without activity ---", tempActivity, booking);
          }

          let activityId = bookings[index].activityId;
          let activity = activityMap.get(activityId);

          //booking Ref meeting time is today's and updated field absent - ignore
          if (activity && activity.id && activity.meetingId) {
            let totalStudentIds = activity.studentIds
              ? activity.studentIds.length
              : 0;
            totalStudentIds =
              totalStudentIds +
              (booking.studentIds ? booking.studentIds.length : 0);

            if (activity.meetingCapacity > totalStudentIds) {
              let availableCapacity = Number(
                activity.meetingCapacity - totalStudentIds
              );

              let bookingTimeString = moment
                .tz(booking.time, selectedTimezone)
                .format("hh:mm A");

              booking.timeString = bookingTimeString;
              booking.availableCapacity = availableCapacity;
              booking.createdBy = activity.createdBy;
              booking.message = activity.message ? activity.message : undefined;

              if (bookingsMap.has(bookingTimeString)) {
                let bookingVal = bookingsMap.get(bookingTimeString);
                bookingVal.push(booking);
                bookingsMap.set(bookingTimeString, bookingVal);
              } else {
                let bookingVal = [];
                bookingVal.push(booking);
                bookingsMap.set(bookingTimeString, bookingVal);
              }
            } else {
              // console.log("meeting capacity full", activity, totalStudentIds);
            }
          } else {
            // console.log("booking ref ---", booking);
          }
          // }
        }
        yield put({
          type: actions.GET_BOOKABLE_MEETINGS_SUCCESS,
          bookableMeetings: [],
          bookingReferences: bookings,
          bookingReferenceMap: bookingsMap,
        });

        // //fetch repeat nodes also

        // if (moment(startDate).isAfter(moment(), 'day')) {
        //     for (let index in bookings) {
        //         let activityId = bookings[index].activityId;

        //         let rActivity = yield call(TimelineApi.fetchRepeatedActivities, activityId, firebase);
        //         if (rActivity && rActivity.id) {
        //             if (activityMap.has(activityId)) {
        //                 let tempVal = activityMap.get(activityId);
        //                 console.log("originalActivity ---", activityId, tempVal.studentIds ? tempVal.studentIds.length : 0, rActivity.studentIds ? rActivity.studentIds.length : 0);

        //                 const rsf = firebase.secondaryDb;
        //                 let branchPath = firebase.sbp;
        //                 // rsf.ref(branchPath + "/activities/" + tempVal.id).update({
        //                 //     studentIds: rActivity.studentIds ? rActivity.studentIds : null
        //                 // });
        //             }
        //         }
        //     }
        // }
      } else {
        let activityIds = bookings;
        let activitiesTask = new Map();
        for (const item in activityIds) {
          let task = call(
            TimelineApi.fetchAltTimelineActivities,
            activityIds[item].activityId,
            firebase
          );
          activitiesTask.set(activityIds[item].id, task);
        }

        var bookingActivityData = [];
        for (let [key, value] of activitiesTask) {
          let newVal = yield all([value]);

          let singleActivity = newVal[0];
          if (singleActivity && singleActivity.id) {
            singleActivity.bookingRefId = key;
            bookingActivityData.push(singleActivity);
          }
        }

        yield put({
          type: actions.GET_BOOKABLE_MEETINGS_SUCCESS,
          bookableMeetings: bookingActivityData,
          bookingReferences: bookings,
          bookingReferenceMap: new Map(),
        });
      }
    }
    if (firebase && firebase.isParent && !firebase.isGod) {
      yield fork(updateLastAccess, student, firebase);
    }
  } catch (err) {
    console.log("failed to fetch bookable meetings", err);
    bugsnagClient.notify(err);
  }
}

function getHourAndMinute(m) {
  return m.minutes() + m.hours() * 60;
}

function* updateBookingAvailability({
  status,
  item,
  firebase,
  selectedTimezone,
}) {
  try {
    //let tempActivity = JSON.parse(JSON.stringify(item));
    let studentIds = [];
    let studentObj = firebase.student;
    let studentId = studentObj.id;
    let parentName =
      firebase.user && firebase.user.id === studentObj.fatherProfileId
        ? studentObj.fatherName
        : studentObj.motherName;

    {
      /**fetched booking ref object */
    }
    let refreshedBookingRef = yield call(
      ActivityApi.getBookingRefById,
      item.bookingRefId,
      firebase
    );

    if (status) {
      // to cancel booked class
      if (refreshedBookingRef.studentIds) {
        // remove studentIds from bookingRefObj
        studentIds = refreshedBookingRef.studentIds.filter((s) => {
          return s !== studentId;
        });
      }

      refreshedBookingRef.studentIds = studentIds; //update bookingRef with studentIds
      yield call(ActivityApi.updatedBookingRef, refreshedBookingRef, firebase);

      //fetch studentBookingRef by bookingRefId and remove
      let studentBookingRef = yield call(
        ActivityApi.getStudentBookingRef,
        studentObj.id,
        refreshedBookingRef.id,
        firebase
      );

      if (studentBookingRef && studentBookingRef.id) {
        yield call(
          ActivityApi.removeStudentBookingRef,
          studentObj.id,
          studentBookingRef.id,
          firebase
        );
      }

      studentObj.meetingDate = null;
      studentObj.meetingDuration = null;
      studentObj.activityId = null;
      studentObj.meetingJoinedDate = null;
      studentObj.trialCount = studentObj.trialCount
        ? Number(studentObj.trialCount + 1)
        : 1;
      studentObj.updatedOn = moment().valueOf();
      studentObj.timezone = null;

      yield fork(TimelineApi.cancelMeetingReminder, studentId, firebase);

      yield call(
        NotificationApi.updateJoinedMeeting,
        item,
        firebase.student,
        firebase,
        "TRIAL_CANCELLED",
        refreshedBookingRef,
        selectedTimezone
      );
      notification("success", "Booking cancelled");
    } else {
      if (
        refreshedBookingRef &&
        refreshedBookingRef.id &&
        refreshedBookingRef.capacity
      ) {
        //get activity students along with students in booking ref
        let activityId = refreshedBookingRef.activityId;
        let activity = yield call(
          TimelineApi.fetchAltTimelineActivities,
          activityId,
          firebase
        );
        let studentIdAArr = [];
        if (activity.studentIds) {
          studentIdAArr = [...studentIdAArr, ...activity.studentIds];
        }

        if (refreshedBookingRef.studentIds) {
          studentIdAArr = [...studentIdAArr, ...refreshedBookingRef.studentIds];
        }

        let totalStudentId = [...new Set(studentIdAArr)];

        let vacantSeats = Number(
          refreshedBookingRef.capacity - totalStudentId.length
        );
        if (vacantSeats === 0) {
          notification("error", "Oops! All seats are booked in this class.");
          yield put({
            type: actions.RESET_STUDENT_TIMELINE_OPERATION,
          });
          return;
        }
      }

      studentIds = [];
      studentIds.push(studentId);
      if (refreshedBookingRef.studentIds) {
        refreshedBookingRef.studentIds = [
          ...refreshedBookingRef.studentIds,
          ...studentIds,
        ];
      } else {
        refreshedBookingRef.studentIds = studentIds;
      }

      yield call(ActivityApi.updatedBookingRef, refreshedBookingRef, firebase);

      studentObj.trialCount = studentObj.trialCount
        ? Number(studentObj.trialCount - 1)
        : 0;
      // studentObj.attendedTrial = false;
      studentObj.meetingDate = refreshedBookingRef.time;
      studentObj.meetingDuration = refreshedBookingRef.meetingDuration
        ? Number(refreshedBookingRef.meetingDuration)
        : 40;
      studentObj.activityId = refreshedBookingRef.activityId;
      studentObj.updatedOn = moment().valueOf();
      studentObj.timezone = selectedTimezone ? selectedTimezone : null;

      let studentBookingNode = yield call(
        ActivityApi.generateStudentBookingNode,
        firebase
      );
      let obj = {
        meetingTime: refreshedBookingRef.time,
        message: item.message ? item.message : "",
        bookingRefId: refreshedBookingRef.id,
        id: studentBookingNode,
      };
      yield call(
        ActivityApi.updatedStudentBookingRef,
        obj,
        studentObj.id,
        studentBookingNode,
        firebase
      );

      yield fork(
        updateMeetingReminderTime,
        item,
        parentName,
        firebase,
        refreshedBookingRef
      );
      notification(
        "success",
        "Booking confirmed. You will get a reminder in your email."
      );
      yield call(
        NotificationApi.updateJoinedMeeting,
        { createdBy: refreshedBookingRef.createdBy },
        studentObj,
        firebase,
        "TRIAL_BOOKED",
        refreshedBookingRef,
        selectedTimezone
      );
      // pixel
      ReactPixel.init("2786923028084527");
      ReactPixel.track("FTscheduled");
      // ReactPixel.track(‘Purchase’);
    }

    yield call(
      StudentApi.updateStudentWithUpdatedFormFields,
      studentObj,
      firebase
    );
    firebase.student = studentObj;
    localStorage.setItem("student", JSON.stringify(studentObj));

    yield put({
      type: actions.UPDATE_BOOKING_STATUS_SUCCESS,
    });
  } catch (err) {
    console.log("failed to updated booking addition or cancellation", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TIMELINE_REQUEST_FAILED,
      errorMessage: err.message
        ? err.message
        : "Failed. Please contact Illumine",
    });
  }

  function* updateMeetingReminderTime(
    item,
    parentName,
    firebase,
    bookingRefItem
  ) {
    try {
      let firstReminderTime = moment(bookingRefItem.time)
        .subtract(20, "hours")
        .valueOf();
      let secondReminderTime = moment(bookingRefItem.time)
        .subtract(30, "minutes")
        .valueOf();
      let thirdReminderTime = moment(bookingRefItem.time)
        .subtract(5, "minutes")
        .valueOf();

      let reminderTimestamps = [
        firstReminderTime,
        secondReminderTime,
        thirdReminderTime,
      ];
      let student = firebase.student;
      // let htmlContent = `<div><p>Online Class Reminder</p></div>`
      // let subject = "Online class reminder";
      let parentEmails = [];
      let parentNumbers = [];
      let sendTextMessage = true;

      if (student.fatherEmail) {
        parentEmails.push(student.fatherEmail);
      }

      if (student.motherEmail) {
        parentEmails.push(student.motherEmail);
      }

      if (student.fatherNumber) {
        parentNumbers.push(student.fatherNumber);
      }

      if (student.motherNumber) {
        parentNumbers.push(student.motherNumber);
      }

      // yield fork(NotificationApi.sendActivityEmails,
      //     htmlContent,
      //     subject,
      //     parentEmails,
      //     firebase,
      //     reminderTimestamps,
      //     parentNumbers,
      //     sendTextMessage
      // )

      let obj = {
        parentEmails: parentEmails,
        parentNumbers: parentNumbers,
        meetingTime: bookingRefItem.time,
        schoolName: firebase.schoolName,
        sendTextMessage: sendTextMessage,
        reminderTimestamps: reminderTimestamps,
        parentName: parentName,
        teacherName: item.createdBy,
        timezone: moment.tz.guess(),
        studentName: student.name,
        meetingDuration: Number(bookingRefItem.meetingDuration),
      };

      yield call(TimelineApi.addMeetingReminder, student.id, obj, firebase);
    } catch (err) {
      console.log("failed to request meeting reminder time", err);
      bugsnagClient.notify(err);
    }
  }
}

function* updateMeetingJoinedData({ activity, firebase }) {
  try {
    yield call(
      NotificationApi.updateJoinedMeeting,
      activity,
      firebase.student,
      firebase,
      "TRIALCLASS_JOINED"
    );

    ReactPixel.init("2786923028084527");
    ReactPixel.track("FTShowed");

    if (
      firebase.student &&
      firebase.student.status &&
      firebase.student.status.toLowerCase() === "trial"
    ) {
      let tempStudentObj = JSON.parse(JSON.stringify(firebase.student));
      // tempStudentObj.attendedTrial = true;
      tempStudentObj.updatedOn = moment().valueOf();
      tempStudentObj.meetingJoinedDate = moment().valueOf();
      firebase.student = tempStudentObj;
      localStorage.setItem("student", JSON.stringify(tempStudentObj));
      yield fork(
        StudentApi.updateStudentWithUpdatedFormFields,
        tempStudentObj,
        firebase
      );
    }
  } catch (err) {
    console.log("failed to update joined meeting data", err);
    bugsnagClient.notify(err);
  }
}

function* fetchStudentVirtualClasses({ studentId, date, firebase }) {
  try {
    let activityIds = yield call(
      TimelineApi.getStudentVirtualClassActivity,
      studentId,
      date,
      firebase
    );

    let activitiesTask = [];
    var timelineActivityData = [];
    if (activityIds && activityIds.length > 0) {
      for (const item in activityIds) {
        let task = call(
          TimelineApi.fetchAltTimelineActivities,
          activityIds[item],
          firebase
        );
        activitiesTask.push(task);
      }

      let newVal = yield all([activitiesTask]);
      for (let i in newVal[0]) {
        let singleActivity = newVal[0][i];
        if (singleActivity && singleActivity.id) {
          timelineActivityData.push(singleActivity);
        }
      }
    }

    console.log("studentVirtualClass ----", timelineActivityData);
    yield put({
      type: actions.GET_STUDENT_VIRTUAL_CLASS_LIST_SUCCESS,
      studentVirtualClass: timelineActivityData,
    });
  } catch (err) {
    console.log("failed to fetch student virtual classes", err);
    bugsnagClient.notify(err);
  }
}

function* fetchStudentOnlineClassesReminder({
  studentId,
  startDate,
  firebase,
}) {
  try {
    let bookingRef = [];
    let activities = [];
    let endTime = moment()
      .endOf("day")
      .valueOf();

    let studentBookingRef = yield call(
      ActivityApi.getStudentBookingReferenceWithTime,
      studentId,
      startDate,
      firebase
    );

    if (studentBookingRef && studentBookingRef.length > 0) {
      for (let index in studentBookingRef) {
        let bookingRefData = yield call(
          ActivityApi.getBookingRefById,
          studentBookingRef[index].bookingRefId,
          firebase,
          undefined
        );
        if (
          bookingRefData &&
          bookingRefData.id &&
          bookingRefData.studentIds &&
          bookingRefData.studentIds.includes(studentId)
        ) {
          bookingRef.push(bookingRefData);
        }
      }

      for (let i in bookingRef) {
        if (bookingRef[i].time < endTime) {
          let activity = yield call(
            TimelineApi.fetchAltTimelineActivities,
            bookingRef[i].activityId,
            firebase
          );
          if (activity && activity.id) {
            //use booking ref here instead of activity
            activity.bookingRefId = bookingRef[i].id;
            activity.meetingTime = bookingRef[i].time;
            activities.push(activity);
          }
        } else {
          let obj = {
            activityType: "Virtual Class",
            name: "Virtual Class",
            message: "",
            meetingTime: bookingRef[i].time,
            bookingRefId: bookingRef[i].id,
          };
          activities.push(obj);
        }
      }
    }

    yield put({
      type: actions.GET_ONLINE_CLASS_REMINDER_SUCCESS,
      studentOnlineClasses: activities,
    });
  } catch (err) {
    console.log("failed to fetchStudentOnlineClassesReminder", err);
    bugsnagClient.notify(err);
  }
}

function* fetchSchoolSettingActivities({ firebase }) {
  try {
    let data = yield call(ActivityApi.getActivitySetting, firebase);
    if (data) {
      data = data.filter((item) => {
        return (
          item.activityType !== "Attendance" &&
          item.activityType !== "Kid Attendance" &&
          item.activityType !== "StaffAttendance" &&
          item.activityType !== "Student Attendance"
        );
      });
      yield put({
        type: actions.GET_SCHOOL_SETTING_ACTIVITIES_SUCCESS,
        schoolSettingActivities: data,
      });
    }

    let customData = yield call(ActivityApi.getCustomActivitySetting, firebase);
    if (customData) {
      let allActivites = [];
      if (data && data.length > 0) {
        allActivites = data;
      }

      for (let [key, val] of customData) {
        let existingActivity = allActivites.filter((act) => {
          return act.activityType.toLowerCase() === key.toLowerCase();
        });

        if (existingActivity && existingActivity.length > 0) {
          let index = allActivites.indexOf(existingActivity[0]);
          allActivites[index] = val;
        } else {
          allActivites.push(val);
        }
      }

      allActivites.sort((a, b) =>
        a.priority && b.priority ? a.priority - b.priority : -1
      );

      yield put({
        type: actions.GET_SCHOOL_SETTING_ACTIVITIES_SUCCESS,
        schoolSettingActivities: allActivites,
      });
    }
  } catch (err) {
    console.log("failed to fetch school setting activities", err);
    bugsnagClient.notify(err);
  }
}

function* uploadStudentDisplayPic({ info, student, firebase }) {
  try {
    let storagePath = firebase.sbp + "/media/profileimages/";
    let urls = yield call(
      ActivityApi.getMediaPath,
      storagePath,
      info,
      firebase
    );
    if (urls) {
      if (urls.length > 0) {
        student.profileImageUrl = urls[0];
        let profileImageUrlObj = {
          profileImageUrl: urls[0],
          platform: "web",
          updatedOn: moment().valueOf(),
          updatedBy: "Parent",
        };
        yield call(
          StudentApi.updateAdditionalInfo,
          profileImageUrlObj,
          student.id,
          firebase
        );
        yield put({
          type: actions.UPLOAD_PROFILE_PIC_SUCCESS,
        });
      }
    } else {
      yield put({
        type: actions.TIMELINE_REQUEST_FAILED,
        errorMessage: "Failed. Please contact Illumine",
      });
    }
  } catch (err) {
    console.log("failed to upload profile pic", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TIMELINE_REQUEST_FAILED,
      errorMessage: err.message
        ? err.message
        : "Failed. Please contact Illumine",
    });
  }
}

function* fetchStudentHolidays({ firebase }) {
  try {
    let holidays = yield call(StudentApi.getStudentHolidays, firebase);
    if (holidays) {
      console.log("holidays", holidays);
      yield put({
        type: actions.GET_STUDENT_HOLIDAYS_SUCCESS,
        studentHolidays: holidays,
      });
    }
  } catch (err) {
    console.log("failed to fetch student holidays", err);
    bugsnagClient.notify(err);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_STUDENT_TIMELINE, fetchStudentTimeline),
    yield takeLatest(
      actions.GET_STUDENT_VIRTUAL_CLASS,
      fetchStudentVirtualClass
    ),
    yield takeLatest(actions.GET_STUDENT_DETAIL, fetchStudentProfile),
    yield takeLatest(
      actions.GET_STUDENT_TIMELINE_BY_ID,
      fetchStudentActivityById
    ),
    yield takeLatest(actions.GET_BOOKABLE_MEETINGS, fetchBookableMeetings),
    yield takeLatest(actions.UPDATE_BOOKING_STATUS, updateBookingAvailability),
    yield takeLatest(actions.UPDATE_JOINED_MEETING, updateMeetingJoinedData),
    yield takeLatest(
      actions.GET_STUDENT_VIRTUAL_CLASS_LIST,
      fetchStudentVirtualClasses
    ),
    yield takeLatest(
      actions.GET_ONLINE_CLASS_REMINDER,
      fetchStudentOnlineClassesReminder
    ),
    yield takeLatest(
      actions.GET_SCHOOL_SETTING_ACTIVITIES,
      fetchSchoolSettingActivities
    ),
    yield takeLatest(actions.UPLOAD_PROFILE_PIC, uploadStudentDisplayPic),
    yield takeLatest(actions.GET_STUDENT_HOLIDAYS, fetchStudentHolidays),
  ]);
}
