const actions = {
  LIST_TEACHERS: "LIST_TEACHERS",
  LIST_TEACHER: "LIST_TEACHER",
  LIST_TEACHER_SUCCESSFUL: "LIST_TEACHER_SUCCESSFUL",
  SEARCH_TEACHER: "SEARCH_TEACHER",
  SEARCH_TEACHER_SUCCESSFUL: "SEARCH_TEACHER_SUCCESSFUL",
  FILTER_TEACHER_BY_CENTER: "FILTER_TEACHER_BY_CENTER",
  FILTER_TEACHER_BY_CENTER_SUCCESSFUL: "FILTER_TEACHER_BY_CENTER_SUCCESSFUL",
  FILTER_TEACHER_BY_CLASSROOM: "FILTER_TEACHER_BY_CLASSROOM",
  FILTER_TEACHER_BY_CLASSROOM_SUCCESSFUL:
    "FILTER_TEACHER_BY_CLASSROOM_SUCCESSFUL",
  ADD_TEACHER: "ADD_TEACHER",
  ADD_TEACHER_SUCCESSFUL: "ADD_TEACHER_SUCCESSFUL",
  UPDATE_TEACHER: "UPDATE_TEACHER",
  UPDATE_TEACHER_SUCCESSFUL: "UPDATE_TEACHER_SUCCESSFUL",
  TEACHER_REQUEST_FAILED: "TEACHER_REQUEST_FAILED",
  GET_AUTOCOMPLETE_CENTER_DATA: "GET_AUTOCOMPLETE_CENTER_DATA",
  GET_AUTOCOMPLETE_CENTER_DATA_SUCCESSFUL:
    "GET_AUTOCOMPLETE_CENTER_DATA_SUCCESSFUL",
  GET_TEACHER_CLASSROOM_DATA: "GET_TEACHER_CLASSROOM_DATA",
  GET_TEACHER_CLASSROOM_DATA_SUCCESSFUL:
    "GET_TEACHER_CLASSROOM_DATA_SUCCESSFUL",
  DELETE_TEACHER: "DELETE_TEACHER",
  DELETE_TEACHER_SUCCESSFUL: "DELETE_TEACHER_SUCCESSFUL",
  GET_TEACHER_DETAIL: "GET_TEACHER_DETAIL",
  GET_TEACHER_DETAIL_SUCCESSFUL: "GET_TEACHER_DETAIL_SUCCESSFUL",
  CHANGE_STAFF_ROLE: "CHANGE_STAFF_ROLE",
  CHANGE_STAFF_ROLE_SUCCESSFUL: "CHANGE_STAFF_ROLE_SUCCESSFUL",
  CHANGE_PERMISSION: "CHANGE_PERMISSION",
  CHANGE_PERMISSION_SUCCESSFUL: "CHANGE_PERMISSION_SUCCESSFUL",
  RESET_STAFF_OPERATION_TYPE: "RESET_STAFF_OPERATION_TYPE",
  UPLOAD_TEACHER_PROFILE: "UPLOAD_TEACHER_PROFILE",
  UPLOAD_TEACHER_PROFILE_SUCCESSFUL: "UPLOAD_TEACHER_PROFILE_SUCCESSFUL",

  GET_TEACHER_FILES: "GET_TEACHER_FILES",
  GET_TEACHER_FILES_SUCCESS: "GET_TEACHER_FILES_SUCCESS",

  UPLOAD_TEACHER_FILE: "UPLOAD_TEACHER_FILE",
  UPLOAD_TEACHER_FILE_SUCCESS: "UPLOAD_TEACHER_FILE_SUCCESS",

  DELETE_TEACHER_FILE: "DELETE_TEACHER_FILE",
  DELETE_TEACHER_FILE_SUCCESS: "DELETE_TEACHER_FILE_SUCCESS",

  SET_TEMP_TEACHER_DETAIL: "SET_TEMP_TEACHER_DETAIL",
  SET_TEMP_TEACHER_DETAIL_SUCCESS: "SET_TEMP_TEACHER_DETAIL_SUCCESS",

  START_TEACHER_LOADER: "START_TEACHER_LOADER",

  GET_TEACHER_ATD: "GET_TEACHER_ATD",
  GET_TEACHER_ATD_SUCCESS: "GET_TEACHER_ATD_SUCCESS",

  UPDATE_TEACHER_STATUS: "UPDATE_TEACHER_STATUS",
  UPDATE_TEACHER_STATUS_SUCCESS: "UPDATE_TEACHER_STATUS_SUCCESS",

  SEND_TEACHER_INVITE: "SEND_TEACHER_INVITE",
  SEND_TEACHER_INVITE_SUCCESS: "SEND_TEACHER_INVITE_SUCCESS",

  SAVE_TEACHER_CHILD: "SAVE_TEACHER_CHILD",
  SAVE_TEACHER_CHILD_SUCCESS: "SAVE_TEACHER_CHILD_SUCCESS",

  ADD_DOC_FOLDER_TEACHER: "ADD_DOC_FOLDER_TEACHER",
  ADD_DOC_FOLDER_TEACHER_SUCCESS: "ADD_DOC_FOLDER_TEACHER_SUCCESS",

  RENAME_TEACHER_DOC: "RENAME_TEACHER_DOC",
  RENAME_TEACHER_DOC_SUCCESS: "RENAME_TEACHER_DOC_SUCCESS",

  initTeacherData: (firebase, initialCall) => ({
    type: actions.LIST_TEACHERS,
    firebase,
    initialCall,
  }),

  getCenterDetails: () => ({ type: actions.GET_AUTOCOMPLETE_CENTER_DATA }),

  getClassroomDetails: (firebase) => ({
    type: actions.GET_TEACHER_CLASSROOM_DATA,
    firebase,
  }),

  fetchSearchedTeacher: (name, firebase) => ({
    type: actions.SEARCH_TEACHER,
    name,
    firebase,
  }),

  filterTeacherByCenter: (center) => ({
    type: actions.FILTER_TEACHER_BY_CENTER,
    center,
  }),

  filterTeacherByClassroom: (room, firebase) => ({
    type: actions.FILTER_TEACHER_BY_CLASSROOM,
    room,
    firebase,
  }),

  submitTeacher: (
    name,
    gender,
    classroom,
    contact,
    address,
    email,
    role,
    encodedEmail,
    password,
    firebase,
    countryCode,
    branchAccess,
    groups,
    birthDate
  ) => ({
    type: actions.ADD_TEACHER,
    name,
    gender,
    classroom,
    contact,
    address,
    email,
    role,
    encodedEmail,
    password,
    firebase,
    countryCode,
    branchAccess,
    groups,
    birthDate,
  }),

  updateTeacher: (
    name,
    gender,
    classroom,
    contact,
    address,
    email,
    role,
    record,
    firebase,
    countryCode,
    branchAccess,
    groups,
    birthDate
  ) => ({
    type: actions.UPDATE_TEACHER,
    name,
    gender,
    classroom,
    contact,
    address,
    email,
    role,
    record,
    firebase,
    countryCode,
    branchAccess,
    groups,
    birthDate,
  }),

  deleteTeacher: (teacherRecord, firebase) => ({
    type: actions.DELETE_TEACHER,
    teacherRecord,
    firebase,
  }),

  getTeacherDetails: (teacherId, firebase) => ({
    type: actions.GET_TEACHER_DETAIL,
    teacherId,
    firebase,
  }),

  changeStaffRole: (role, teacherId, firebase) => ({
    type: actions.CHANGE_STAFF_ROLE,
    role,
    teacherId,
    firebase,
  }),

  changePermission: (category, status, teacherId, firebase) => ({
    type: actions.CHANGE_PERMISSION,
    category,
    status,
    teacherId,
    firebase,
  }),

  resetStaffOperation: () => ({
    type: actions.RESET_STAFF_OPERATION_TYPE,
  }),

  uploadTeacherProfilePic: (file, teacherDetail, firebase) => ({
    type: actions.UPLOAD_TEACHER_PROFILE,
    file,
    teacherDetail,
    firebase,
  }),

  getTeacherPersonalDocument: (id, firebase) => ({
    type: actions.GET_TEACHER_FILES,
    id,
    firebase,
  }),

  uploadTeacherUserDocument: (fileList, mediaType, id, firebase, filePath) => ({
    type: actions.UPLOAD_TEACHER_FILE,
    fileList,
    mediaType,
    id,
    firebase,
    filePath,
  }),

  deleteTeacherUserDocument: (record, id, firebase) => ({
    type: actions.DELETE_TEACHER_FILE,
    record,
    id,
    firebase,
  }),

  setTeacherDetail: (teacherDetail) => ({
    type: actions.SET_TEMP_TEACHER_DETAIL,
    teacherDetail,
  }),

  getTeacherAttendance: (date, teacherId, firebase) => ({
    type: actions.GET_TEACHER_ATD,
    date,
    teacherId,
    firebase,
  }),

  updateSelectedTeacherStatus: (teacher, firebase) => ({
    type: actions.UPDATE_TEACHER_STATUS,
    teacher,
    firebase,
  }),

  sendTeacherInvite: (teacher, firebase) => ({
    type: actions.SEND_TEACHER_INVITE,
    teacher,
    firebase,
  }),

  submitChild: (values, teacher, firebase) => ({
    type: actions.SAVE_TEACHER_CHILD,
    values,
    teacher,
    firebase,
  }),

  addNewFolderTeacher: (folderName, filePath, firebase, id) => ({
    type: actions.ADD_DOC_FOLDER_TEACHER,
    folderName,
    filePath,
    firebase,
    id,
  }),

  renameTeacherDocument: (docName, record, firebase, id) => ({
    type: actions.RENAME_TEACHER_DOC,
    docName,
    record,
    firebase,
    id,
  }),
};

export default actions;
