const actions = {
    GET_SCHEDULES: "GET_SCHEDULES",
    GET_SCHEDULES_SUCCESSFUL: "GET_SCHEDULES_SUCCESSFUL",
    SCHEDULE_REQUEST_FAILED: 'SCHEDULE_REQUEST_FAILED',
    GET_SCHEDULE_CLASSROOM: "GET_SCHEDULE_CLASSROOM",
    GET_SCHEDULE_CLASSROOM_SUCCESSFUL: "GET_SCHEDULE_CLASSROOM_SUCCESSFUL",
    DELETE_SCHEDULE_ITEM: "DELETE_SCHEDULE_ITEM",
    DELETE_SCHEDULE_ITEM_SUCCESSFUL: "DELETE_SCHEDULE_ITEM_SUCCESSFUL",
    RESET_SCHEDULE_OPERATION_TYPE: "RESET_SCHEDULE_OPERATION_TYPE",
    ADD_SCHEDULE: "ADD_SCHEDULE",
    ADD_SCHEDULE_SUCCESSFUL: "ADD_SCHEDULE_SUCCESSFUL",
    EDIT_SCHEDULE: "EDIT_SCHEDULE",
    EDIT_SCHEDULE_SUCCESSFUL: "EDIT_SCHEDULE_SUCCESSFUL",

    getSchedule: (date, firebase) => ({
        type: actions.GET_SCHEDULES,
        date,
        firebase
    }),

    getClassroomForSchedule: (firebase) => ({
        type: actions.GET_SCHEDULE_CLASSROOM,
        firebase
    }),

    deleteSchedule: (scheduleItem, date, className, firebase) => ({
        type: actions.DELETE_SCHEDULE_ITEM,
        scheduleItem,
        date,
        className,
        firebase
    }),

    resetScheduleOperation: () => ({
        type: actions.RESET_SCHEDULE_OPERATION_TYPE
    }),

    addSchedule: (values, selectedDate, selectedClass, classrooms, firebase) => ({
        type: actions.ADD_SCHEDULE,
        values,
        selectedDate,
        selectedClass,
        classrooms,
        firebase
    }),

    editSchedule: (values, selectedDate, selectedClass, editableRecord, firebase) => ({
        type: actions.EDIT_SCHEDULE,
        values,
        selectedDate,
        selectedClass,
        editableRecord,
        firebase
    })
};
export default actions;    