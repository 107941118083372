import { all, call, takeLatest, put } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { ProgramApi } from "../../firestore-api/program";
import moment from "moment";
import bugsnagClient from '@bugsnag/js';
const { Parser } = require('json2csv');

function* deleteSelectedStudentFromProgram({ selectedProgram, firebase }) {
  try {
    yield call(ProgramApi.deleteStudentFromProgram, selectedProgram, firebase);
  } catch (err) {
    console.log("failed to delete student from program", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.DETAILED_PROGRAM_REQUEST_FAILED
    });
  }
}

function* fetchStudentListForDetailedProgram({ firebase }) {
  try {
    //var students = yield call(AssessmentApi.getAllStudents, firebase);
    let students = JSON.parse(localStorage.getItem("studentList"));
    if (students) {
      yield put({
        type: actions.DETAILED_PROGRAM_FETCH_STUDENT_SUCCESSFFUL,
        detailedProgramStudent: students
      });
    }
  } catch (error) {
    console.log("failed to fetch student for program", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.DETAILED_PROGRAM_REQUEST_FAILED
    });
  }
}

function* assignNewStudentToProgram({ values, selectedStudentCheckbox, selectedProgram, firebase }) {
  try {
    yield call(
      ProgramApi.assignStudentsFromDetailedProgram,
      selectedProgram,
      firebase
    );
    yield put({
      type: actions.ASSIGN_STUDENT_FROM_DETAILED_PROGRAM_SUCCESSFUL
      // programOperationType: 'ASSIGN_STUDENT'
    });
  } catch (error) {
    console.log("failed to assign plan to program", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.DETAILED_PROGRAM_REQUEST_FAILED
    });
  }
}

function* fetchDetailedProgramFeePlan({ firebase }) {
  try {
    var feePlan = yield call(ProgramApi.getAllFeePlan, firebase);
    if (feePlan) {
      yield put({
        type: actions.DETAILED_PROGRAM_FETCH_FEE_PLAN_SUCCESSFUL,
        detailedProgramFeePlan: feePlan
      });
    }
  } catch (error) {
    console.log("failed to fetch fee plan for program", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.DETAILED_PROGRAM_REQUEST_FAILED
    });
  }
}

function* addNewFeePlan({ selectedProgram, firebase }) {
  try {
    yield call(
      ProgramApi.addFeePlanToDetailedProgram,
      selectedProgram,
      firebase
    );
    yield put({
      type: actions.ADD_FEE_PLAN_FROM_DETAILED_VIEW_SUCCESSFUL
    });
  } catch (error) {
    console.log("failed to add fee plan to program detailed view", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.DETAILED_PROGRAM_REQUEST_FAILED
    });
  }
}

function* downloadSingleProgramExcel({ selectedProgram, firebase }) {
  try {

    const fields = ["programName", "studentName", "startDate", "endDate"];
    const opts = { fields };
    let programList = [];
    let i = selectedProgram;
    if (i.student) {
      i.student.forEach(stu => {
        var row = {};
        row.programName = i.name;
        row.startDate = moment(stu.startDate).format('DD-MM-YYYY');
        row.endDate = moment(stu.endDate).format('DD-MM-YYYY');
        row.studentName = stu.name;
        programList.push(row);
      })

    }


    const parser = new Parser(opts);
    const csv = parser.parse(programList);
    console.log(csv);
    var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    var csvURL = window.URL.createObjectURL(csvData);
    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'program_students.csv');
    tempLink.click();
    yield put({
      type: actions.DOWNLOAD_SINGLE_PROGRAM_EXCEL_SUCCESS
    });
  } catch (err) {
    console.log("failed to download all program excel sheet", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.PROGRAM_REQUEST_FAILED
    });
  }
}

function* fetchSelectedProgramDetail({ id, firebase, selectedProgram }) {
  try {
    if (selectedProgram) {
      yield put({
        type: actions.GET_SELECTED_PROGRAM_DETAIL_SUCCESS,
        selectedProgram: selectedProgram
      });
    } else {
      let data = yield call(ProgramApi.fetchProgramById, id, firebase);
      if (data) {
        yield put({
          type: actions.GET_SELECTED_PROGRAM_DETAIL_SUCCESS,
          selectedProgram: data
        });
      }
    }
  } catch (err) {
    console.log("failed to fetch selected program detail view", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.PROGRAM_REQUEST_FAILED
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(
      actions.DELETE_STUDENT_FROM_PROGRAM,
      deleteSelectedStudentFromProgram
    ),
    yield takeLatest(
      actions.DETAILED_PROGRAM_FETCH_STUDENT,
      fetchStudentListForDetailedProgram
    ),
    yield takeLatest(
      actions.ASSIGN_STUDENT_FROM_DETAILED_PROGRAM,
      assignNewStudentToProgram
    ),
    yield takeLatest(
      actions.DETAILED_PROGRAM_FETCH_FEE_PLAN,
      fetchDetailedProgramFeePlan
    ),
    yield takeLatest(actions.ADD_FEE_PLAN_FROM_DETAILED_VIEW, addNewFeePlan),
    yield takeLatest(actions.DOWNLOAD_SINGLE_PROGRAM_EXCEL, downloadSingleProgramExcel),
    yield takeLatest(actions.GET_SELECTED_PROGRAM_DETAIL, fetchSelectedProgramDetail)
  ]);
}
