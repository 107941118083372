import actions from "./actions";
const initState = {
  queryList: [],
  isLoading: false,
  queryListChannel: undefined,
  queryOperation: undefined,
  error: false,
  errorMessage: undefined,
  unreadMessages: new Map(),
  parentChat: [],
  parentChatChannel: undefined,
  schoolChat: [],
  schoolChatChannel: undefined,

  staffQueryList: [],
  staffQueryListChannel: undefined,
  teacherChat: [],
  teacherChatChannel: undefined,
};
export default function queryReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_QUERYLIST:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_QUERYLIST_SUCCESS:
      return {
        ...state,
        queryList: action.queryList,
        queryListChannel: action.queryListChannel,
        isLoading: false,
      };

    case actions.QUERYLIST_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actions.RESET_QUERYLIST_OPERATION:
      return {
        ...state,
        isLoading: false,
        queryOperation: undefined,
        error: false,
        errorMessage: undefined,
      };

    case actions.GET_UNREAD_MESSAGE_COUNT:
      return {
        ...state,
        unreadMessages: action.unreadMessages,
      };

    case actions.GET_PARENT_CHAT:
      return {
        ...state,
        isLoading: true,
        parentChat: [],
      };
    case actions.GET_PARENT_CHAT_SUCCESS:
      return {
        ...state,
        parentChat: action.parentChat,
        parentChatChannel: action.parentChatChannel,
        isLoading: false,
      };

    case actions.SEND_MESSAGE:
      return {
        ...state,
      };
    case actions.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
      };

    case actions.RESET_TEACHER_UNREAD_COUNT:
      return {
        ...state,
      };

    case actions.GET_SCHOOL_MSG:
      return {
        ...state,
        isLoading: true,
        schoolChat: [],
      };
    case actions.GET_SCHOOL_MSG_SUCCESS:
      return {
        ...state,
        schoolChat: action.schoolChat,
        schoolChatChannel: action.schoolChatChannel,
        isLoading: false,
      };

    case actions.SEND_SCHOOL_MSG:
      return {
        ...state,
      };
    case actions.SEND_SCHOOL_MSG_SUCCESS:
      return {
        ...state,
      };

    case actions.GET_STAFF_QUERYLIST:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_STAFF_QUERYLIST_SUCCESS:
      return {
        ...state,
        staffQueryList: action.staffQueryList,
        staffQueryListChannel: action.staffQueryListChannel,
        isLoading: false,
      };

    case actions.GET_TEACHER_CHAT:
      return {
        ...state,
        isLoading: true,
        teacherChat: [],
      };
    case actions.GET_TEACHER_CHAT_SUCCESS:
      return {
        ...state,
        teacherChat: action.teacherChat,
        teacherChatChannel: action.teacherChatChannel,
        isLoading: false,
      };

    case actions.SEND_TEACHER_MESSAGE:
      return {
        ...state,
      };
    case actions.SEND_TEACHER_MESSAGE_SUCCESS:
      return {
        ...state,
      };

    case actions.RESET_STAFF_CHAT_UNREAD_COUNT:
      return {
        ...state,
      };

    default:
      return state;
  }
}
