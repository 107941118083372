import { Layout } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Debounce } from "react-throttle";
import WindowResizeListener from "react-window-size-listener";
import { compose } from "redux";
import { ThemeProvider } from "styled-components";
import appActions from "../../redux/app/actions";
import authAction from "../../redux/auth/actions";
import { siteConfig, themeConfig } from "../../settings";
import themes from "../../settings/themes";
import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar/Topbar";
import { withFirebase } from "./../../components/firebase/context";
import AppRouter from "./AppRouter";
import AppHolder from "./commonStyle";
import "./global.css";
import packageJson from "../../../package.json";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";

const { Content, Footer } = Layout;
const { logout } = authAction;
const { toggleAll } = appActions;
export class App extends Component {
  componentDidMount() {}

  render() {
    const { url } = this.props.match;
    const urlParams = new URLSearchParams(window.location.search);
    let displayReq = urlParams.get("display");

    const { height, firebase } = this.props;
    let liveKey = "pk_live_xIfhpuHTuPVjmDFaOIohvv5w00wg9qeO8K";
    let trialKey = "pk_test_1d4UnXyNsg6SDyeGQUmGd83r00tmomQ1n8";
    var stripeAccount = firebase.schoolConfig
      ? firebase.schoolConfig.stripeAccountId
        ? firebase.schoolConfig.stripeAccountId
        : "acct_1G7xwDKqSjVJ3K0R"
      : "acct_1G7xwDKqSjVJ3K0R";

    const stripePromise = loadStripe(liveKey, { stripeAccount: stripeAccount });

    const appHeight = window.innerHeight;
    return (
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <AppHolder>
          <Layout style={{ height: appHeight }}>
            <Debounce time="1000" handler="onResize">
              <WindowResizeListener
                onResize={(windowSize) =>
                  this.props.toggleAll(
                    windowSize.windowWidth,
                    windowSize.windowHeight
                  )
                }
              />
            </Debounce>
            {displayReq && displayReq === "no" ? null : (
              <Topbar url={url} height={height} />
            )}
            <Layout style={{ flexDirection: "row", overflowX: "hidden" }}>
              <Sidebar
                noDisplay={displayReq && displayReq === "no" ? true : false}
                url={url}
              />
              <Layout
                className="isoContentMainLayout"
                style={{
                  height: height,
                }}
              >
                <Content
                  className="isomorphicContent"
                  style={{
                    padding: "70px 0 0",
                    flexShrink: "0",
                    background: "#f1f3f6",
                    position: "relative",
                  }}
                >
                  <Elements stripe={stripePromise}>
                    <AppRouter url={url} firebase={this.props.firebase} />
                  </Elements>
                </Content>
                <Footer
                  style={{
                    backgroundColor: "#ffffff",
                    textAlign: "center",
                    borderTop: "1px solid #ededed",
                  }}
                >
                  {siteConfig.footerText}, Curr. Version: {packageJson.version}
                </Footer>
              </Layout>
            </Layout>
          </Layout>
        </AppHolder>
      </ThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.Auth,
    height: state.App.height,
  };
}
const a = compose(
  connect(mapStateToProps, { logout, toggleAll }),
  withFirebase
);

export default a(App);
