import React from "react";
import ReactDOM from "react-dom";
import DashApp from "./dashApp";
import "./styles/antd.css";
import Firebase, { FirebaseContext } from "./components/firebase";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import LogRocket from "logrocket";
import packageJson from "./../package.json";
import { confirmAlert } from "react-confirm-alert";
import "./settings/react-confirm-alert.css";
import colors from "./Utility/colorFactory";
import { Spin } from "antd";
export var dir = localStorage.getItem("lng") === "ar" ? "rtl" : "ltr";
LogRocket.init("4uhz2h/illumine_web_v2");

Bugsnag.start({
  apiKey: "c8ac62ad74bd33660b10083b6b55f89f",
  plugins: [new BugsnagPluginReact()],
  appVersion: packageJson.version,
  releaseStage: process.env.REACT_APP_BUILD_ENV,
  onError: function(event) {
    event.addMetadata("user", {
      email: localStorage.getItem("loggedInEmail"),
      schoolName: localStorage.getItem("schoolName", ""),
      userName: localStorage.getItem("teacher", ""),
    });
  },
});
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const firebasePromise = new Firebase();
firebasePromise
  .then(function(firebase) {
    if (!firebase) {
      Bugsnag.notify("failed to get firebase --->>>");
    }

    ReactDOM.render(
      <ErrorBoundary
        onError={(err) => onError(err)}
        FallbackComponent={ErrorScreen}
      >
        <FirebaseContext.Provider value={firebase}>
          <Spin spinning={!firebase ? true : false}>
            <DashApp></DashApp>
          </Spin>
        </FirebaseContext.Provider>
      </ErrorBoundary>,
      document.getElementById("root")
    );
  })
  .catch(function(error) {
    Bugsnag.notify(error);
  });
document
  .getElementsByTagName("html")[0]
  .setAttribute("dir", localStorage.getItem("lng") === "ar" ? "rtl" : "ltr");
document.documentElement.dir=localStorage.getItem("lng") === "ar" ? "rtl" : "ltr";

const onError = (event) => {
  console.log("Error boundary error caught", event.errors[0].errorMessage);
  // window.location.reload(false);
};

const ErrorScreen = ({ clearError }) => {
  clearError();
  return (
    <div>
      {confirmAlert({
        title: (
          <p style={{ fontSize: 20, color: colors.v2_fiord }}>
            Uh oh, there was an error on our side!
          </p>
        ),
        message:
          "Please refresh and try again, if still continues, please reach out to Illumine support.",
        buttons: [
          {
            label: "OK",
            onClick: () => {
              window.location.reload(false);
            },
          },
        ],
      })}
    </div>
  );
};

// registerServiceWorker();
// if (process.env.REACT_APP_BUILD_ENV === "development")
//serviceWorker.unregister({});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// if(process.env.REACT_APP_BUILD_ENV === "dev")
//     serviceWorker.register();
