import "@firebase/firestore"; // 👈 If you're using firestore
import { eventChannel } from "redux-saga";
import moment from "moment-timezone";

function createDocumentNode(bPath, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var key = rsf.ref(branchPath + "/documents/teacher").push().key;
  return key;
}

function getAllDocuments(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/documents/teacher")
      .orderByChild("inverseDate")
      .on("value", (snap) => {
        var x = [];
        snap.forEach((element) => {
          if (element.val() !== null) {
            // x.push(element.val());
            if (element.val().type.toLowerCase() === "folder") {
              x.push(element.val());
            } else if (element.val().downloadUrl) {
              x.push(element.val());
            }
          }
        });
        emit(x);
      });
    return () => {
      rsf.ref(branchPath + "/documents/teacher").off();
      console.log("unsubscribe documents");
    };
  });
}

// function addDocument(files, uniqueNodeId, firebase) {
//   const rsf = firebase.secondaryDb;
//   let branchPath = firebase.sbp;

//   let attachmentRef;

//   if (files !== undefined) {
//     var file = files[0].originFileObj;
//     var fileName = file.name + new Date().getTime();
//     var metadata = {
//       contentType: files[0].type
//     };
//     const storage = firebase.secondaryStorage;
//     const storageRef = storage.ref();
//     if (metadata.contentType.match("image")) {
//       attachmentRef = storageRef.child(branchPath + "/media/images/" + fileName);
//     } else {
//       attachmentRef = storageRef.child(branchPath + "/media/file/" + fileName);
//     }

//     attachmentRef.put(file, metadata).then(function (snapshot) {
//       attachmentRef.getDownloadURL().then(url => {
//         rsf.ref(branchPath + "/documents/teacher/" + uniqueNodeId).update({
//           createdBy: firebase.teacher.name,
//           createdOn: new Date().getTime(),
//           downloadUrl: url,
//           id: uniqueNodeId,
//           inverseDate: -new Date().getTime(),
//           lastModifiedBy: firebase.teacher.name,
//           name: files[0].name,
//           nameWithExtension: files[0].name + " " + files[0].type,
//           type: metadata.contentType.match("image") ? "image" : "doc"
//         });
//       });
//     });
//   }
// }

function addDocument(url, uniqueNodeId, mediaType, firebase, files, filePath) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/documents/teacher/" + uniqueNodeId).update({
    filePath: filePath ? filePath : null,
    createdBy: firebase.teacher.name,
    createdOn: moment().valueOf(),
    downloadUrl: url,
    id: uniqueNodeId,
    inverseDate: -moment().valueOf(),
    lastModifiedBy: firebase.teacher.name,
    name: files[0].name,
    nameWithExtension: files[0].name + " " + files[0].type,
    type: mediaType === "Photo" ? "image" : "doc",
  });
}

function deleteDocument(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/documents/teacher/" + record.id).set(null);
}

function createDocumentNodeByCenter(firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath;
  var key = rsf.ref(branchPath + "/documents/teacher").push().key;
  return key;
}

function uploadDocumentByCenter(files, bPath, firebase) {
  let branchPath = bPath;
  let attachmentRef;

  if (files !== undefined) {
    var file = files[0].originFileObj;
    var fileName = new Date().getTime() + file.name;
    var metadata = {
      contentType: files[0].type,
    };
    const storage = firebase.secondaryStorage;
    const storageRef = storage.ref();
    if (metadata.contentType.match("image")) {
      attachmentRef = storageRef.child(
        branchPath + "/media/images/" + fileName
      );
    } else {
      attachmentRef = storageRef.child(branchPath + "/media/file/" + fileName);
    }

    var p = new Promise(function(resolve, reject) {
      attachmentRef.put(file, metadata).then(function(snapshot) {
        attachmentRef.getDownloadURL().then((url) => {
          resolve(url);
        });
      });
    });
  }
  return p;
}

function addDocumentByCenter(
  url,
  files,
  uniqueNodeId,
  bPath,
  firebase,
  mediaType,
  filePath
) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath;
  if (files !== undefined) {
    rsf.ref(branchPath + "/documents/teacher/" + uniqueNodeId).update({
      filePath: filePath ? filePath : null,
      createdBy: firebase.teacher.name,
      createdOn: moment().valueOf(),
      downloadUrl: url,
      id: uniqueNodeId,
      inverseDate: -moment().valueOf(),
      lastModifiedBy: firebase.teacher.name,
      name: files[0].name,
      nameWithExtension: files[0].name + " " + files[0].type,
      type: mediaType === "Photo" ? "image" : "doc",
    });
  }
}

export const DocumentApi = {
  getAllDocuments,
  addDocument,
  createDocumentNode,
  deleteDocument,
  addDocumentByCenter,
  createDocumentNodeByCenter,
  uploadDocumentByCenter,
};
