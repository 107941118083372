import { all, put, call, take, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { ProgramApi } from "../../firestore-api/program";
import { StudentApi } from "../../firestore-api/student";
import bugsnagClient from "@bugsnag/js";
import moment from "moment-timezone";
const { Parser } = require("json2csv");

function* fetchPrograms({ firebase }) {
  const chan = yield call(ProgramApi.getAllProgram, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      if (data && data.length > 0) {
        data.forEach((d) => {
          let startDate = moment(d.startDate);
          let endDate = moment(d.endDate);
          if (moment().isBetween(startDate, endDate, undefined, "[]")) {
            d.status = "Active";
          } else {
            d.status = "Expired";
          }
        });
      }

      data = data.sort((a, b) => a.status.localeCompare(b.status));
      yield put({
        type: actions.LIST_PROGRAMS_SUCCESSFUL,
        programs: data,
        programsChannel: chan,
      });
    }
  } finally {
    console.log("END CHANNEL PROGRAM");
  }
}

function* fetchProgramClassroom({ firebase }) {
  try {
    //var classroom = yield call(ProgramApi.getProgramClassroom, firebase);
    let classroom = JSON.parse(localStorage.getItem("classList"));
    if (classroom) {
      yield put({
        type: actions.GET_PROGRAM_CLASSROOMS_SUCCESSFFUL,
        programClassroom: classroom,
      });
    }
  } catch (err) {
    console.log("falied to fetch program classroom", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.PROGRAM_REQUEST_FAILED,
    });
  }
}

function* addNewProgram({ values, classroom, firebase }) {
  try {
    var nodeId = yield call(ProgramApi.createNewProgramNode, firebase);
    if (nodeId) {
      yield call(ProgramApi.addProgram, values, classroom, nodeId, firebase);
    }
    yield put({
      type: actions.ADD_PROGRAM_SUCCESSFUL,
      programOperationType: "ADD_PROGRAM",
    });
  } catch (err) {
    console.log("failed to add program", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.PROGRAM_REQUEST_FAILED,
    });
  }
}

function* updateExistingProgram({ values, classroom, record, firebase }) {
  try {
    yield call(ProgramApi.updateProgram, values, classroom, record, firebase);
    yield put({
      type: actions.UPDATE_PROGRAM_SUCCESSFUL,
      programOperationType: "UPDATE_PROGRAM",
    });
  } catch (error) {
    console.log("failed to update program", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.PROGRAM_REQUEST_FAILED,
    });
  }
}

function* fetchFeePlanForProgram({ firebase }) {
  try {
    var feePlan = yield call(ProgramApi.getAllFeePlan, firebase);
    if (feePlan) {
      yield put({
        type: actions.PROGRAM_FETCH_FEE_PLAN_SUCCESSFUL,
        programFeePlans: feePlan,
      });
    }
  } catch (error) {
    console.log("failed to fetch fee plan for program", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.PROGRAM_REQUEST_FAILED,
    });
  }
}

function* fetchStudentForProgram({ firebase }) {
  try {
    // var students = yield call(AssessmentApi.getAllStudents, firebase);
    let students = JSON.parse(localStorage.getItem("studentList"));
    if (students) {
      yield put({
        type: actions.PROGRAM_FETCH_STUDENT_SUCCESSFFUL,
        programStudents: students,
      });
    }
  } catch (error) {
    console.log("failed to fetch student for program", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.PROGRAM_REQUEST_FAILED,
    });
  }
}

function* assignStudentToProgram({
  values,
  selectedStudentCheckbox,
  selectedProgram,
  firebase,
}) {
  try {
    yield call(
      ProgramApi.assignStudentsToProgram,
      values,
      selectedStudentCheckbox,
      selectedProgram,
      firebase
    );
    yield put({
      type: actions.ASSIGN_STUDENT_TO_PROGRAM_SUCCESSFUL,
      programOperationType: "ASSIGN_STUDENT",
    });
  } catch (error) {
    console.log("failed to assign student to program", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.PROGRAM_REQUEST_FAILED,
    });
  }
}

function* addNewFeePlanToProgram({
  values,
  selectedFeePlan,
  selectedProgram,
  firebase,
}) {
  try {
    yield call(
      ProgramApi.addFeePlanToProgram,
      values,
      selectedFeePlan,
      selectedProgram,
      firebase
    );
    yield put({
      type: actions.ADD_FEE_PLAN_TO_PROGRAM_SUCCESSFUL,
    });
  } catch (error) {
    console.log("failed to add fee plan to program", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.PROGRAM_REQUEST_FAILED,
    });
  }
}

function* downloadAllProgramExcel({ programs, firebase }) {
  try {
    const fields = [
      "programName",
      "programStartDate",
      "programEndDate",
      "programDays",
      "startTime",
      "endTime",
      "hours",
      "studentName",
      "startDate",
      "endDate",
      "classroomName",
      "admissionNo",
      "status",
      "deactivationDate",
      "birthDate",
      "bloodGroup",
      "allergies",
      "fatherName",
      "fatherEmail",
      "fatherNumber",
      "motherName",
      "motherEmail",
      "motherNumber",
      "address",
    ];

    const opts = { fields };
    let programList = [];

    for (var k in programs) {
      var i = programs[k];

      //   if(i.weekDay){
      //     row.days=''
      //       i.weekDay.forEach(day=>{
      //           row.days += day+',';
      //       })
      //   }
      //fetch student

      if (programs[k].student) {
        for (var j in programs[k].student) {
          var stu = programs[k].student[j];

          var student = yield call(
            StudentApi.getStudentById,
            stu.studentId,
            firebase
          );

          var row = {};
          row.programName = i.name;
          row.programStartDate = moment(i.startDate).format("DD-MM-YYYY");
          row.programEndDate = moment(i.endDate).format("DD-MM-YYYY");
          if (i.weekDay) {
            row.programDays = "";
            i.weekDay.forEach((day) => {
              row.programDays += day + ",";
            });
          }

          row.startTime = moment(i.startTime).format("hA");
          row.endTime = moment(i.endTime).format("hA");
          row.hours = moment(i.endTime).diff(moment(i.startTime), "hours");
          row.startDate = moment(stu.startDate).format("DD-MM-YYYY");
          row.endDate = moment(stu.endDate).format("DD-MM-YYYY");
          row.studentName = stu.name;
          row.admissionNumber = student.admissionNumber;
          row.birthDate = student.birthDate;
          row.bloodGroup = student.bloodGroup;
          row.classroomName = student.classList
            ? student.classList.toString()
            : student.classroomName;
          row.fatherName = student.fatherName;
          row.fatherEmail = student.fatherEmail;
          row.fatherNumber = student.fatherNumber;
          row.motherName = student.motherName;
          row.motherEmail = student.motherEmail;
          row.motherNumber = student.motherNumber;
          row.address = student.address;
          if (student.deactived) {
            row.status = "Deactivated";
            row.deactivationDate = student.deactivationDate;
          } else {
            row.status = "Active";
          }
          programList.push(row);
        }
      } else {
        let row = {};
        row.programName = i.name;
        row.programStartDate = moment(i.startDate).format("DD-MM-YYYY");
        row.programEndDate = moment(i.endDate).format("DD-MM-YYYY");
        row.startTime = moment(i.startTime).format("hA");
        row.endTime = moment(i.endTime).format("hA");
        row.hours = moment(i.endTime).diff(moment(i.startTime), "hours");
        if (i.weekDay) {
          row.programDays = "";
          i.weekDay.forEach((day) => {
            row.programDays += day + ",";
          });
        }
        programList.push(row);
        // if(i.classroom){
        //     row.classrooms=''
        //     i.classroom.forEach(classname=>{
        //         row.classrooms += classname.name+',';
        //     })
        // }
      }
    }

    const parser = new Parser(opts);
    const csv = parser.parse(programList);
    console.log(csv);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = window.URL.createObjectURL(csvData);
    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "programs.csv");
    tempLink.click();
    yield put({
      type: actions.DOWNLOAD_ALL_PROGRAM_EXCEL_SUCCESS,
    });
  } catch (err) {
    console.log("failed to download all program excel sheet", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.PROGRAM_REQUEST_FAILED,
    });
  }
}

function* deleteSelectedProgram({ record, firebase }) {
  try {
    yield call(ProgramApi.deleteProgram, record, firebase);
    yield put({
      type: actions.DELETE_SELECTED_PROGRAM_SUCCESS,
    });
  } catch (err) {
    console.log("failed to delete program", err);
    bugsnagClient.notify(err);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.LIST_PROGRAMS, fetchPrograms),
    yield takeLatest(actions.GET_PROGRAM_CLASSROOMS, fetchProgramClassroom),
    yield takeLatest(actions.ADD_PROGRAM, addNewProgram),
    yield takeLatest(actions.UPDATE_PROGRAM, updateExistingProgram),
    yield takeLatest(actions.PROGRAM_FETCH_FEE_PLAN, fetchFeePlanForProgram),
    yield takeLatest(actions.PROGRAM_FETCH_STUDENT, fetchStudentForProgram),
    yield takeLatest(actions.ASSIGN_STUDENT_TO_PROGRAM, assignStudentToProgram),
    yield takeLatest(actions.ADD_FEE_PLAN_TO_PROGRAM, addNewFeePlanToProgram),
    yield takeLatest(
      actions.DOWNLOAD_ALL_PROGRAM_EXCEL,
      downloadAllProgramExcel
    ),
    yield takeLatest(actions.DELETE_SELECTED_PROGRAM, deleteSelectedProgram),
  ]);
}
