import actions from './actions';
const initState = {
    assessmentMilestones: new Map(),
    studentAssessmentRecord: [],
    isLoading: false,
    assessmentMilestoneChannel: undefined,
    studentAssessmentChannel: undefined,
    uniqueNodeId: undefined,
    labels: [],
    labelChan: undefined,
    error: false,
    operationType: undefined,
    savedAssessments: [],
    developmentAreas: [],
    developmentAreasMap: new Map(),
    savedAssessmentId: undefined,
    progressParams: []
}
export default function selectedStudentAssessmentReducer(state = initState, { type, ...action }) {
    switch (type) {
        case actions.LIST_ASSESSMENT_MILESTONES:
            return {
                ...state,
                isLoading: true
            };
        case actions.LIST_ASSESSMENT_MILESTONES_SUCCESSFUL:
            return {
                ...state,
                assessmentMilestones: action.assessmentMilestones,
                assessmentMilestoneChannel: action.assessmentMilestoneChannel,
                isLoading: false,
            };
        case actions.GENERATE_STUDENT_ASSESSMENT_NODE_ID:
            return {
                ...state,
            };
        case actions.GENERATE_STUDENT_ASSESSMENT_NODE_ID_SUCCESSFUL:
            return {
                ...state,
                uniqueNodeId: action.uniqueNodeId,
            };
        case actions.SAVE_SELECTED_DOMAIN_TO_STUDENT_ASSESSMENT:
            return {
                ...state,
                isLoading: true
            };
        case actions.SAVE_SELECTED_DOMAIN_TO_STUDENT_ASSESSMENT_SUCCESSFUL:
            return {
                ...state,
                isLoading: false
            };
        case actions.SAVE_SELECTED_MILESTONE_TO_STUDENT_ASSESSMENT:
            return {
                ...state,
                isLoading: true
            };
        case actions.SAVE_SELECTED_MILESTONE_TO_STUDENT_ASSESSMENT_SUCCESSFUL:
            return {
                ...state,
                isLoading: false
            };

        case actions.GET_LABELS_FOR_ASSESSMENT:
            return {
                ...state,
                isLoading: true
            };
        case actions.GET_LABELS_FOR_ASSESSMENT_SUCCESSFFUL:
            return {
                ...state,
                isLoading: false,
                labels: action.labels,
                labelChan: action.labelChan
            };

        case actions.GET_STUDENT_ASSESSMENT:
            return {
                ...state,
                isLoading: true
            };
        case actions.GET_STUDENT_ASSESSMENT_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                studentAssessmentRecord: action.studentAssessmentRecord,
                studentAssessmentChannel: action.studentAssessmentChannel,
                savedAssessmentId: action.savedAssessmentId
            };

        case actions.STUDENT_ASSESSMENT_REQUEST_FAILED:
            return {
                ...state,
                isLoading: false,
                error: true
            };

        case actions.SAVE_STUDENT_ASSESSMENT:
            return {
                ...state,
                isLoading: true,
            };

        case actions.SAVE_STUDENT_ASSESSMENT_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                operationType: "CHILD_ASSESSMENT_SAVED",
                savedAssessmentId: action.savedAssessmentId
            };

        case actions.RESET_OPERATION_TYPE:
            return {
                ...state,
                isLoading: false,
                operationType: undefined,
            };

        case actions.GET_SAVED_ASSESSMENT_RECORD:
            return {
                ...state,
                isLoading: true,
            };

        case actions.GET_SAVED_ASSESSMENT_RECORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                savedAssessments: action.savedAssessments
            };

        case actions.SAVE_STUDENT_ASSESSMENT_NOTE:
            return {
                ...state,
                isLoading: true,
            };

        case actions.SAVE_STUDENT_ASSESSMENT_NOTE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                operationType: "CHILD_ASSESSMENT_SAVED"
            };

        case actions.SAVE_STUDENT_ASSESSMENT_ATTACHMENT:
            return {
                ...state,
                isLoading: true,
            };

        case actions.SAVE_STUDENT_ASSESSMENT_ATTACHMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                operationType: "CHILD_ASSESSMENT_SAVED"
            };

        case actions.GET_STD_ASS_DEV_AREAS:
            return {
                ...state,
            };
        case actions.GET_STD_ASS_DEV_AREAS_SUCCESS:
            return {
                ...state,
                developmentAreas: action.developmentAreas,
                developmentAreasMap: action.developmentAreasMap
            };

        case actions.RESET_SAVED_ASSESSMENT_ID:
            return {
                ...state,
                savedAssessmentId: undefined
            };

        case actions.DELETE_SAVED_ASSESSMENT:
            return {
                ...state,
                isLoading: true,
            };

        case actions.DELETE_SAVED_ASSESSMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                operationType: "DELETE_SAVED_ASSESSMENT"
            };

        case actions.SAVE_PROGRESS_PARAM:
            return {
                ...state,
            };

        case actions.SAVE_PROGRESS_PARAM_SUCCESS:
            return {
                ...state,
                operationType: "PROGRESS_PARAM_ADD"
            };

        case actions.GET_PROGRESS_PARAM:
            return {
                ...state,
            };

        case actions.GET_PROGRESS_PARAM_SUCCESS:
            return {
                ...state,
                progressParams: action.progressParams
            };

        case actions.DELETE_PROGRESS_PARAM:
            return {
                ...state,
            };

        case actions.DELETE_PROGRESS_PARAM_SUCCESS:
            return {
                ...state,
                operationType: "PROGRESS_PARAM_DELETE"
            };

        case actions.RESET_LAST_ASSESSMENT_FETCHED:
            return {
                ...state,
                studentAssessmentRecord: []
            };

        case actions.SAVE_ASSESSMENT_TITLE:
            return {
                ...state,
                isLoading: true
            };

        case actions.SAVE_ASSESSMENT_TITLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                operationType: "TITLE_SAVED"
            };

        default:
            return state;
    }
}