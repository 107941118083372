import { all, put, call, takeLatest, fork, take } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore";
import { DashboardApi } from "../../firestore-api/dashboard";
import { lessonAssignmentApi } from '../../firestore-api/lessonAssignment';
import { ActivityApi } from "../../firestore-api/activity";
import { NotificationApi } from "../../firestore-api/notification";
import bugsnagClient from '@bugsnag/js';
import moment from 'moment-timezone';


function* fetchAllSubmissionActivity({ assignmentId, firebase }) {
    try {
        let lessonSub = yield call(lessonAssignmentApi.getLessonSubmission, assignmentId, firebase);

        let activities = [];
        let activityTask = [];
        if (lessonSub && lessonSub.length > 0) {
            for (let index in lessonSub) {
                let task = yield call(ActivityApi.getActivityById, lessonSub[index].activityId, firebase);
                activityTask.push(task);
            }

            let newVal = yield all([activityTask]);
            for (let i in newVal[0]) {
                let val = newVal[0][i];
                if (val && val.id) {
                    activities.push(val);
                }
            }
        }

        yield put({
            type: actions.GET_ALL_SUBMISSION_ACTIVITY_SUCCESSFUL,
            allSubmissionActivity: activities,
            // submissionsOperationType: initialCall,
            // showAllSubmissionFlag: showAllSubmissionFlag
        });



        // if (assignmentId) {
        //     let lessonSub = yield call(lessonAssignmentApi.getLessonSubmission, assignmentId, firebase);
        //     let activities = [];
        //     if (lessonSub && lessonSub.length > 0) {
        //         for (let index in lessonSub) {
        //             let val = yield call(ActivityApi.getActivityById, lessonSub[index].activityId, firebase);
        //             if (val && val.id) {
        //                 activities.push(val);
        //             }
        //         }
        //     }
        //     yield put({
        //         type: actions.GET_ALL_SUBMISSION_ACTIVITY_SUCCESSFUL,
        //         allSubmissionActivity: activities,
        //         submissionsOperationType: initialCall,
        //         showAllSubmissionFlag: showAllSubmissionFlag
        //     });
        // } else {
        //     let data = yield call(DashboardApi.getAllSubmissionActivity, startDate, endDate, firebase);
        //     var activities = data;
        //     activities = activities.filter(act => {
        //         return act.studentIds && act.studentIds.includes(studentId);
        //     });

        //     activities.sort(function (a, b) {
        //         var dateA = a.date.time,
        //             dateB = b.date.time;
        //         return dateB - dateA;
        //     });

        //     yield put({
        //         type: actions.GET_ALL_SUBMISSION_ACTIVITY_SUCCESSFUL,
        //         allSubmissionActivity: activities,
        //         submissionsOperationType: initialCall,
        //         showAllSubmissionFlag: showAllSubmissionFlag
        //     });
        // }
    } catch (err) {
        console.log("failed to fetch all submission", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.ALL_SUBMISSION_REQUEST_FAIL
        })
    }
}

function* fetchSubmissionsClassList({ firebase }) {
    try {
        //const data = yield call(TeacherApi.getClassroomsForTeacher, firebase);
        let data = JSON.parse(localStorage.getItem("classList"));
        if (data) {
            yield put({
                type: actions.GET_SUBMISSIONS_CLASSES_SUCCESS,
                submissionsClasses: data
            });
        }
    } catch (err) {
        console.log("failed to fetch submission classroom dropdown", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.ALL_SUBMISSION_REQUEST_FAIL,
        })
    }
}

function* fetchSubmissionsStudentList({ firebase }) {
    try {
        // var data = yield call(AssessmentApi.getAllStudents, firebase);
        let data = JSON.parse(localStorage.getItem("studentList"));
        if (data) {
            yield put({
                type: actions.GET_SUBMISSIONS_STUDENT_SUCCESS,
                submissionsStudents: data
            });
        }
    } catch (err) {
        console.log("failed to fetch submission students dropdown", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.ALL_SUBMISSION_REQUEST_FAIL,
        })
    }
}

function* fetchSubmissionListComment({ submissionList, firebase }) {
    try {
        let activities = submissionList;
        let commentTaskList = [];
        var commentsCount = new Map();
        for (const item in activities) {
            let task = call(lessonAssignmentApi.getCommentsCount, activities[item].id, firebase);
            commentTaskList.push(task);
            commentsCount.set(activities[item].id, []);
        }

        let newVal = yield all([commentTaskList]);
        for (let i in newVal[0]) {
            let val = newVal[0][i];
            for (let j in val) {
                if (commentsCount.has(val[j].sourceId)) {
                    commentsCount.set(val[j].sourceId, val);
                    break;
                }
            }
        }
        yield put({
            type: actions.GET_SUBMISSION_LIST_COMMENT_SUCCESS,
            submissionComments: commentsCount
        });
    } catch (err) {
        bugsnagClient.notify(err);
        console.log("failed to fetch submission list comment", err);
    }
}

function* markAssignmentActivityStar({ activity, firebase }) {
    try {
        let activityObj = activity;
        activityObj.updatedBy = firebase.teacher.name;
        activityObj.updatedOn = moment().valueOf();
        activityObj.favt = true;
        activityObj.platform = "web";
        yield call(ActivityApi.updateActivityObj, activityObj, activityObj.id, firebase);
        yield put({
            type: actions.MARK_ASSIGNMENT_STAR_SUCCESS
        });



        if (activityObj.studentIds) {
            let studentId = activityObj.studentIds[0];
            let studentAssignment = yield call(lessonAssignmentApi.getStudentAssignmentById, activityObj.lessonId, studentId, firebase);
            if (studentAssignment && studentAssignment.length > 0 && studentAssignment[0] && studentAssignment[0].id) {
                let tempAssignment = studentAssignment[0];
                tempAssignment.star = true;
                yield fork(lessonAssignmentApi.updateStudentAssignment, studentId, tempAssignment.id, tempAssignment, firebase);
            }

            let allStudents = JSON.parse(localStorage.getItem("studentList"));

            let selectedStudent = allStudents.filter(s => {
                return s.id === studentId
            });

            if (selectedStudent && selectedStudent.length > 0) {
                for (let i = 0; i < selectedStudent.length; i++) {
                    let selectedActivity = "Assignment";
                    let activityId = activityObj.id;

                    if (selectedStudent[i].fatherProfileId) {
                        let alertNode = yield call(
                            NotificationApi.createSimpleAlertReferenceNode,
                            selectedStudent[i].fatherProfileId,
                            undefined,
                            firebase
                        );
                        yield fork(
                            NotificationApi.createAlertNotification,
                            selectedActivity,
                            activityId,
                            selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
                            selectedStudent[i].name + " has been awarded a star for the assignment",
                            alertNode,
                            selectedStudent[i].ios_fatherUUid
                                ? selectedStudent[i].ios_fatherUUid
                                : null,
                            selectedStudent[i].id,
                            selectedStudent[i].fatherProfileId,
                            firebase,
                            undefined
                        );

                        if (selectedStudent[i].fatherUUid !== undefined || selectedStudent[i].ios_fatherUUid !== undefined) {
                            yield fork(
                                NotificationApi.sendPushNotification,
                                selectedActivity,
                                activityId,
                                selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
                                selectedStudent[i].name + " has been awarded a star for the assignment",
                                alertNode,
                                selectedStudent[i].ios_fatherUUid
                                    ? selectedStudent[i].ios_fatherUUid
                                    : null,
                                selectedStudent[i].id,
                                selectedStudent[i].fatherProfileId,
                                firebase,
                                undefined
                            );
                        }
                    }

                    if (selectedStudent[i].motherProfileId) {
                        let alertNode = yield call(
                            NotificationApi.createSimpleAlertReferenceNode,
                            selectedStudent[i].motherProfileId,
                            undefined,
                            firebase
                        );
                        yield fork(
                            NotificationApi.createAlertNotification,
                            selectedActivity,
                            activityId,
                            selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
                            selectedStudent[i].name + " has been awarded a star for the assignment",
                            alertNode,
                            selectedStudent[i].ios_motherUUid
                                ? selectedStudent[i].ios_motherUUid
                                : null,
                            selectedStudent[i].id,
                            selectedStudent[i].motherProfileId,
                            firebase,
                            undefined
                        );

                        if (selectedStudent[i].motherUUid !== undefined || selectedStudent[i].ios_motherUUid !== undefined) {
                            yield fork(
                                NotificationApi.sendPushNotification,
                                selectedActivity,
                                activityId,
                                selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
                                selectedStudent[i].name + " has been awarded a star for the assignment",
                                alertNode,
                                selectedStudent[i].ios_motherUUid
                                    ? selectedStudent[i].ios_motherUUid
                                    : null,
                                selectedStudent[i].id,
                                selectedStudent[i].motherProfileId,
                                firebase,
                                undefined
                            );
                        }
                    }
                }
            }


        }
    } catch (err) {
        console.log("failed to mark assignment star", err);
        bugsnagClient.notify(err);
    }
}

function* fetchSubmissionListStats({ startDate, endDate, firebase }) {
    try {
        let data = JSON.parse(localStorage.getItem("studentList"));
        let filteredStudent = [];
        if (data && data.length > 0) {
            filteredStudent = data.filter(s => {
                return !s.status || (s.status && s.status.toLowerCase() === "active")
            });
        }

        let fullClassroomData = JSON.parse(localStorage.getItem("classList"));
        yield put({
            type: actions.GET_SUBMISSION_STUDENTS_SUCCESS,
            submissionStudentList: filteredStudent,
            submissionClassroom: fullClassroomData
        })

        for (let index in filteredStudent) {
            yield fork(lessonAssignmentApi.getStudentLessonStats, firebase, filteredStudent[index].id, startDate, endDate);
        }

        yield fork(fetchSubmissionStatsData, firebase);
    } catch (err) {
        console.log("failed to fetch submission list stats", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.ALL_SUBMISSION_REQUEST_FAIL
        })
    }
}

function* fetchSubmissionStatsData(firebase) {
    try {
        const chan = yield call(lessonAssignmentApi.getAllStudentLessonStatsData, firebase);
        while (true) {
            let data = yield take(chan);
            yield put({
                type: actions.GET_STUDENT_SUBMISSION_LIST_STATS_SUCCESS,
                submissionStatsMap: data,
                submissionStatsMapChannel: chan
            });
        }
    } finally {
        console.log("terminating submission stats data");
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(actions.GET_ALL_SUBMISSION_ACTIVITY, fetchAllSubmissionActivity),
        yield takeLatest(actions.GET_SUBMISSIONS_CLASSES, fetchSubmissionsClassList),
        yield takeLatest(actions.GET_SUBMISSIONS_STUDENT, fetchSubmissionsStudentList),
        yield takeLatest(actions.GET_SUBMISSION_LIST_COMMENT, fetchSubmissionListComment),
        yield takeLatest(actions.MARK_ASSIGNMENT_STAR, markAssignmentActivityStar),
        yield takeLatest(actions.GET_STUDENT_SUBMISSION_LIST_STATS, fetchSubmissionListStats),
    ]);
}