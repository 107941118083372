const actions = {
  LIST_DOCUMENTS: "LIST_DOCUMENTS",
  LIST_DOCUMENTS_SUCCESSFUL: "LIST_DOCUMENTS_SUCCESSFUL",

  SAVE_DOCUMENT: "SAVE_DOCUMENT",
  SAVE_DOCUMENT_SUCCESSFUL: "SAVE_DOCUMENT_SUCCESSFUL",

  SAVE_DOCUMENT_IN_ALL_CENTERS: "SAVE_DOCUMENT_IN_ALL_CENTERS",
  SAVE_DOCUMENT_IN_ALL_CENTERS_SUCCESSFUL: "SAVE_DOCUMENT_IN_ALL_CENTERS_SUCCESSFUL",

  DELETE_DOCUMENT: "DELETE_DOCUMENT",
  DELETE_DOCUMENT_SUCCESSFUL: "DELETE_DOCUMENT_SUCCESSFUL",

  DOCUMENT_REQUEST_FAILED: "DOCUMENT_REQUEST_FAILED",

  RESET_DOCUMENT_OPERATION_TYPE: "RESET_DOCUMENT_OPERATION_TYPE",

  ADD_COMMON_TEACHER_DOC_FOLDER: "ADD_COMMON_TEACHER_DOC_FOLDER",
  ADD_COMMON_TEACHER_DOC_FOLDER_SUCCESS: "ADD_COMMON_TEACHER_DOC_FOLDER_SUCCESS",

  RENAME_DOC: "RENAME_DOC",
  RENAME_DOC_SUCCESS: "RENAME_DOC_SUCCESS",

  initDocumentData: firebase => ({ type: actions.LIST_DOCUMENTS, firebase }),

  saveDocument: (fileList, firebase, mediaType, filePath) => ({
    type: actions.SAVE_DOCUMENT,
    fileList,
    firebase,
    mediaType,
    filePath
  }),

  deleteDocument: (record, firebase) => ({
    type: actions.DELETE_DOCUMENT,
    record,
    firebase
  }),

  saveDocumentInAllCenters: (fileList, firebase, branches, mediaType, filePath) => ({
    type: actions.SAVE_DOCUMENT_IN_ALL_CENTERS,
    fileList,
    firebase,
    branches,
    mediaType,
    filePath
  }),

  resetDocumentOperationType: () => ({
    type: actions.RESET_DOCUMENT_OPERATION_TYPE
  }),

  addCommonTeacherFolder: (folderName, filePath, firebase, id) => ({
    type: actions.ADD_COMMON_TEACHER_DOC_FOLDER,
    folderName,
    filePath,
    firebase,
    id
  }),

  renameDocument: (docName, record, firebase) => ({
    type: actions.RENAME_DOC,
    docName,
    record,
    firebase
  })
};
export default actions;
