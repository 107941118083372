import actions from './actions';
const initState = {
    schoolDetails: [],
    isLoading: false,
    error: false,
    schoolDetailsChannel: undefined,
    operationRequest: ""
}
export default function schoolDetailsReducer(state = initState, { type, ...action }) {
    switch (type) {
        case actions.GET_SCHOOL_DETAILS:
            return {
                ...state,
                isLoading: true,
                schoolDetails: [],
                operationRequest: ''
            };

        case actions.GET_SCHOOL_DETAILS_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                schoolDetails: action.schoolDetails,
                schoolDetailsChannel: action.schoolDetailsChannel,
                operationRequest: ''
            };

        case actions.SAVE_SCHOOL_DETAILS:
            return {
                ...state,
                isLoading: true,
            };

        case actions.SAVE_SCHOOL_DETAILS_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                operationRequest: "SAVE_DETAIL"
            };


        case actions.SCHOOL_DETAILS_REQUEST_FAILED:
            return {
                ...state,
                isLoading: false,
                error: true,
            };

        default:
            return state;
    }
}