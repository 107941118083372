import colors from "../../Utility/colorFactory";
import dashboardIcon from "../../image/sidebar_dashboard_1.png";
import mySchool from "../../image/sidebar_dashboard.png";
import analytics from "../../image/sidebar_analytics.png";
import learning from "../../image/sidebar_learning.png";
import management from "../../image/sidebar_management.png";
import billing from "../../image/sidebar_billing.png";
import parentCommunication from "../../image/sidebar_parentCommunication.png";
import crm from "../../image/sidebar_crm.png";
import calendar from "../../image/sidebar_calendar.png";
import attendance from "../../image/sidebar_attendance.png";
import virtualClass from "../../image/sidebar-virtualClass.png";
import support from "../../image/v2_support.png";

const options = [
  {
    key: "",
    label: "sidebar.dashboard",
    leftImage: dashboardIcon,
  },
  // {
  //   key: "multiCenterDashboard",
  //   label: "sidebar.multiCenterDashboard",
  //   leftImage: dashboardIcon,
  // },
  {
    key: "management",
    label: "sidebar.management",
    leftImage: management,
    children: [
      {
        key: "students",
        label: "sidebar.students",
      },
      {
        key: "staff",
        label: "sidebar.staff",
      },
      {
        key: "rooms",
        label: "sidebar.rooms",
      },
      {
        key: "program",
        label: "sidebar.program",
      },
      {
        key: "tags",
        label: "sidebar.tags",
      },
      {
        key: "customLabels",
        label: "sidebar.customLabels",
      },
    ],
  },
  {
    key: "bookingReport",
    label: "sidebar.booking",
    leftImage: analytics,
    children: [
      {
        key: "bookingReport",
        label: "sidebar.bookingReport",
      },
      {
        key: "meetingReport",
        label: "sidebar.bookableMeeting",
      },
      {
        key: "meetingSummary",
        label: "sidebar.nonBookableMeeting",
      },
    ],
  },
  {
    key: "godMode",
    label: "sidebar.godMode",
    leftIcon: "ion-stats-bars",
  },
  {
    key: "mySchool",
    label: "sidebar.mySchool",
    leftIcon: "ion-home",
    leftImage: mySchool,
    children: [
      {
        key: "activities",
        label: "sidebar.activities",
      },
      {
        key: "studentLevelView",
        label: "sidebar.studentLevelView",
      },
    ],
  },
  {
    key: "attendance",
    label: "sidebar.attendance",
    leftImage: attendance,
    children: [
      {
        key: "studentAttendance",
        label: "sidebar.studentAttendance",
      },
      {
        key: "staffAttendance",
        label: "sidebar.staffAttendance",
      },
    ],
  },
  {
    key: "virtualClasses",
    label: "sidebar.virtualClasses",
    leftImage: virtualClass,
  },
  {
    key: "learning",
    label: "sidebar.learning",
    leftImage: learning,
    children: [
      {
        key: "lessons",
        label: "sidebar.schoolLessons",
      },
      {
        key: "assignments",
        label: "sidebar.assignments",
      },
      {
        key: "submissionStats",
        label: "sidebar.submissions",
      },
      {
        key: "lessonPlans",
        label: "sidebar.weeklyLessonPlan",
      },
      {
        key: "assessments",
        label: "sidebar.studentAssessments",
      },
      {
        key: "milestones",
        label: "sidebar.milestones",
      },
      {
        key: "document",
        label: "sidebar.document",
      },
    ],
  },
  {
    key: "fee",
    label: "sidebar.fee",
    leftImage: billing,
    children: [
      {
        key: "studentBilling",
        label: "sidebar.studentBilling",
      },
      {
        key: "invoice",
        label: "sidebar.invoice",
      },
      {
        key: "feeStructure",
        label: "sidebar.feeStructure",
      },
      {
        key: "invoiceTemplate",
        label: "sidebar.feeTemplate",
      },
    ],
  },
  {
    key: "connect",
    label: "sidebar.parentCommunication",
    leftImage: parentCommunication,
    children: [
      {
        key: "queryList",
        label: "sidebar.Messages",
      },
      {
        key: "complains",
        label: "sidebar.Concern",
      },
      {
        key: "leaves",
        label: "sidebar.Leaves",
      },
      {
        key: "parentNotes",
        label: "sidebar.ParentNotes",
      },
      {
        key: "draftNewsLetter",
        label: "sidebar.draftNewsetter",
        leftIcon: "ion-android-create",
      },
    ],
  },
  {
    key: "staffCommunication",
    label: "sidebar.staffCommunication",
    leftImage: parentCommunication,
    children: [
      {
        key: "staffLeaves",
        label: "sidebar.staffLeaves",
      },
      {
        key: "staffActivities",
        label: "activity.staffAnnouncement",
      },
      {
        key: "staffMessages",
        label: "sidebar.staffMessages",
      },
    ],
  },
  {
    key: "calendar",
    label: "sidebar.calendar",
    leftImage: calendar,
    children: [
      {
        key: "schedule",
        label: "sidebar.schedule",
      },
      {
        key: "events",
        label: "sidebar.events",
      },
      {
        key: "holiday",
        label: "sidebar.holiday",
      },
    ],
  },
  {
    key: "leads",
    label: "sidebar.leads",
    leftImage: crm,
  },
  {
    key: "schoolDetails",
    label: "sidebar.schoolDetails",
    leftIcon: "ion-android-list",
    iconColor: colors.v2_Cerulean,
  },
  {
    key: "installations",
    label: "sidebar.installationReport",
    leftIcon: "ion-android-phone-portrait",
  },
  {
    key: "parentMode",
    label: "sidebar.parentMode",
    leftIcon: "ion-android-person",
  },
  {
    key: "permissions",
    label: "sidebar.accessControl",
    leftIcon: "ion-android-settings",
  },
  {
    key: "help",
    label: "sidebar.help",
    leftIcon: "ion-help",
  },
  {
    key: "virtualLibrary",
    label: "sidebar.virtualLibrary",
    leftIcon: "ion-ios-videocam",
  },
  // {
  //   key: "contactUs",
  //   label: "sidebar.contactUs",
  //   leftIcon: "ion-ios-telephone",
  // },
  {
    key: "support",
    label: "sidebar.support",
    leftImage: support,
  },
];
export default options;
