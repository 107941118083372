import actions from './actions';
const initState = {
    staffAttendance: [],
    allStaffs: [],
    classrooms: [],
    isLoading: false,
    error: false,
    staffAttendanceChannel: undefined,
    operationType: undefined,
    staffMonthlyAttendance: []
}
export default function staffAttendanceReducer(state = initState, { type, ...action }) {
    switch (type) {
        case actions.FETCH_STAFF_ATTENDANCE:
            return {
                ...state,
                isLoading: true,
                operationType: undefined

            };
        case actions.FETCH_STAFF_ATTENDANCE_SUCCESSFUL:
            return {
                ...state,
                staffAttendance: action.staffAttendance,
                isLoading: false,
                staffAttendanceChannel: action.staffAttendanceChannel,
                operationType: action.operationType
            };

        case actions.FETCH_STAFF_ATTENDANCE_BY_CLASSNAME:
            return {
                ...state,
                isLoading: true,
                operationType: undefined
            };
        case actions.FETCH_STAFF_ATTENDANCE_BY_CLASSNAME_SUCCESSFUL:
            return {
                ...state,
                staffAttendance: action.staffAttendance,
                isLoading: false,
                staffAttendanceChannel: action.staffAttendanceChannel,
                operationType: undefined
            };

        case actions.FETCH_ALL_STAFF:
            return {
                ...state,
                isLoading: true,
                operationType: undefined
            };
        case actions.FETCH_ALL_STAFF_SUCCESSFUL:
            return {
                ...state,
                allStaffs: action.allStaffs,
                isLoading: false,
                operationType: undefined
            };

        case actions.MARK_STAFF_PRESENT:
            return {
                ...state,
                isLoading: true,
                operationType: undefined

            };
        case actions.MARK_STAFF_PRESENT_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                operationType: "REFRESH_COMPONENT"
            };

        case actions.MARK_STAFF_ABSENT:
            return {
                ...state,
                isLoading: true,
                operationType: undefined

            };
        case actions.MARK_STAFF_ABSENT_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                operationType: "REFRESH_COMPONENT"
            };

        case actions.MARK_STAFF_CHECKOUT:
            return {
                ...state,
                isLoading: true,
                operationType: undefined
            };
        case actions.MARK_STAFF_CHECKOUT_SUCCESSFUL:
            return {
                ...state,
                operationType: "REFRESH_COMPONENT"
            };

        case actions.FETCH_ALL_CLASSROOMS_FOR_STAFF_ATTENDANCE:
            return {
                ...state,
                operationType: undefined
            };
        case actions.FETCH_ALL_CLASSROOMS_FOR_STAFF_ATTENDANCE_SUCCESSFUL:
            return {
                ...state,
                classrooms: action.classrooms,
                operationType: undefined
            };

        case actions.FETCH_STAFF_BY_CLASSNAME:
            return {
                ...state,
                isLoading: true,
                operationType: undefined
            };
        case actions.FETCH_STAFF_BY_CLASSNAME_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                allStaffs: action.allStaffs,
                operationType: undefined
            };

        case actions.STAFF_ATTENDANCE_REQUEST_FAILED:
            return {
                ...state,
                isLoading: false,
                error: true
            };

        case actions.RESET_STAFF_ATD_OPERATION:
            return {
                ...state,
                isLoading: false,
                operationType: undefined
            };

        case actions.EMAIL_STAFF_ATTENDANCE:
            return {
                ...state,
                isLoading: false,
                operationType: undefined
            };

        case actions.GET_STAFF_MONTHLY_ATD:
            return {
                ...state,
                isLoading: true,
                operationType: undefined
            };

        case actions.GET_STAFF_MONTHLY_ATD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                staffMonthlyAttendance: action.staffMonthlyAttendance
            };

        case actions.MARK_STAFF_PENDING:
            return {
                ...state,
                isLoading: true,
                operationType: undefined

            };
        case actions.MARK_STAFF_PENDING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                operationType: "REFRESH_COMPONENT"
            };

        default:
            return state;
    }
}