import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment-timezone";
import { eventChannel } from "redux-saga";
import { call } from "redux-saga/effects";
import notification from "../components/notification";
const superagent = require("superagent");

function createStudentNodeId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/students").push().key;
  return key;
}

function getAllStudents(fetchLimit, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  // let start = new Date().getTime();
  return eventChannel((emitter) => {
    rsf
      .ref(branchPath + "/students")
      // .limitToFirst(fetchLimit)
      .on("value", (snap) => {
        const studentData = [];
        snap.forEach((element) => {
          if (element.val() !== null && element.val().deleted === false) {
            studentData.push(element.val());
          }
        });
        emitter(studentData);
      });
    return () => {
      rsf.ref(branchPath + "/students").off();
      console.log("END");
    };
  });
}

function* getAllParents(students, firebase) {
  var allStudent = students;
  var fatherDataPromise = [];
  var motherDataPromise = [];

  // for (var i = 0; i < allStudent.length; i++) {
  if (allStudent.fatherProfileId !== undefined) {
    var fatherDetail = yield call(
      getFatherDetail,
      allStudent.fatherProfileId,
      allStudent,
      firebase
    );
    fatherDataPromise.push(fatherDetail);
  }
  if (allStudent.motherProfileId !== undefined) {
    var motherDetail = yield call(
      getMotherDetail,
      allStudent.motherProfileId,
      allStudent,
      firebase
    );
    motherDataPromise.push(motherDetail);
  }
  // }
  Promise.all(fatherDataPromise).then(function(values) {
    console.log("father promise", values);
  });

  Promise.all(motherDataPromise).then(function(values) {
    console.log("mother promise", values);
  });

  return allStudent;
}

function getFatherDetail(fatherId, student, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return rsf
    .ref(branchPath + "/parents/" + fatherId)
    .once("value")
    .then(function(snapshot) {
      if (snapshot.val() !== null) {
        student.fatherName = snapshot.val().name;
        student.address = snapshot.val().address;
      }
    });
}

function getMotherDetail(motherId, student, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return rsf
    .ref(branchPath + "/parents/" + motherId)
    .once("value")
    .then(function(snap) {
      if (snap.val() !== null) {
        student.motherName = snap.val().name;
      }
    });
}

function getAutoCompleteClassrooms(firebase) {
  let classroomData = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/classrooms");
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((element) => {
        if (element.val() !== null) {
          classroomData.push(element.val());
        }
      });
      resolve(classroomData);
    });
  });
  return promise1;
}

function getAllStudentTags(firebase) {
  var x = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var tagPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/tags")
      .once("value")
      .then(function(snap) {
        snap.forEach((item) => {
          if (item.val() !== null) {
            x.push(item.val());
          }
        });
        resolve(x);
      });
  });
  return tagPromise;
}

function addStudentData(
  values,
  tags,
  studentNodeId,
  fatherNodeId,
  motherNodeId,
  firebase
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/students/" + studentNodeId).set({
    address: values.address !== undefined ? values.address : null,
    birthDate: moment(new Date(values.birthDate)).format("DD[, ]MMMM[, ]YYYY"),
    //classId: values.classroom[1],
    classroomName: values.classroom,
    dateCreated: new Date().getTime(),
    deactivated: false,
    deleted: false,
    fatherEmail: values.fatherEmail !== undefined ? values.fatherEmail : null,
    fatherName: values.father ? values.father : null,
    fatherNumber:
      values.fatherContact !== undefined ? Number(values.fatherContact) : null,
    fatherProfileId: fatherNodeId !== undefined ? fatherNodeId : null,
    gender: values.gender,
    id: studentNodeId,
    motherEmail: values.motherEmail !== undefined ? values.motherEmail : null,
    motherName: values.mother ? values.mother : null,
    motherNumber:
      values.motherContact !== undefined ? Number(values.motherContact) : null,
    motherProfileId: motherNodeId !== undefined ? motherNodeId : null,
    name: values.name,
    chatPermission: false,
    tags: tags !== undefined && tags.length > 0 ? tags : null,
    admissionNumber: values.admissionNumber ? values.admissionNumber : null,
    fatherCompanyName: values.fatherCompanyName
      ? values.fatherCompanyName
      : null,
    motherCompanyName: values.motherCompanyName
      ? values.motherCompanyName
      : null,
    platform: "web",
    status: "Active",
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
  });
}

function updateStudentData(values, record, tags, firebase, config, updatedBy) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/students/" + record.id).update({
    childConfig:
      record.childConfig && record.childConfig.length > 0
        ? record.childConfig
        : null,
    address: values.address !== undefined ? values.address : null,
    birthDate: values.birthDate
      ? moment(new Date(values.birthDate)).format("DD[, ]MMMM[, ]YYYY")
      : null,
    chatPermission: false,
    classroomName:
      values.classroom && values.classroom.length > 0
        ? values.classroom[0]
        : null,
    classList: values.classroom,
    gender: values.gender,
    id: record.id,
    name: values.name,
    admissionNumber: values.admissionNumber ? values.admissionNumber : null,
    platform: "web",
    status: record.status ? record.status : "Active",
    admissionDate: values.admissionDate
      ? moment(values.admissionDate).valueOf()
      : null,

    updatedBy: firebase && firebase.teacher ? firebase.teacher.name : null,
    updatedOn: moment().valueOf(),
    preferredName: values.prefName ? values.prefName : null,
    tags: tags && tags.length > 0 ? tags : null,
  });
}

function updateFatherUser(values, record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/users/" + values.fatherContact).set({
    chatPermission: false,
    dateCreated: 0,
    deactivated: false,
    deleted: false,
    id: record.fatherProfileId,
    name: values.father,
    notification: false,
    phoneNumber: Number(values.fatherContact),
    studentId: record.id,
    userType: "STUDENT",
    platform: "web",
  });
}

function updateFatherUserWithoutNumberUpdate(values, record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/users/" + values.fatherContact).set({
    chatPermission: false,
    dateCreated: 0,
    deactivated: false,
    deleted: false,
    id: record.fatherProfileId,
    name: values.father,
    notification: false,
    // phoneNumber: Number(values.fatherContact),
    studentId: record.id,
    userType: "STUDENT",
    platform: "web",
  });
}

function updateMotherUser(values, record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/users/" + values.motherContact).set({
    chatPermission: false,
    dateCreated: 0,
    deactivated: false,
    deleted: false,
    id: record.motherProfileId,
    name: values.mother,
    notification: false,
    phoneNumber: Number(values.motherContact),
    studentId: record.id,
    userType: "STUDENT",
    platform: "web",
  });
}

function updateMotherUserWithoutNumberUpdate(values, record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/users/" + values.motherContact).set({
    chatPermission: false,
    dateCreated: 0,
    deactivated: false,
    deleted: false,
    id: record.motherProfileId,
    name: values.mother,
    notification: false,
    // phoneNumber: Number(values.motherContact),
    studentId: record.id,
    userType: "STUDENT",
    platform: "web",
  });
}

function deleteStudent(studentRecord, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/students/" + studentRecord.id).update({
    deleted: true,
    platform: "web",
    deletedOn: new Date().getTime(),
    updatedOn: new Date().getTime(),
    updatedBy: firebase.teacher.name,
  });
}

function* detailedStudentList(record) {}

function addStudentFromCsv(
  values,
  classroom,
  studentNodeId,
  fatherNodeId,
  motherNodeId,
  firebase
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let formattedDOB = moment(values.birthDate, [
    "DD/MM/YY",
    "DD-MM-YYYY",
    "DD, MMMM, YYYY",
    "DD.MM.yyyy",
    "YYYY-MM-DD",
    "DD[-]MM[-]YYYY",
  ]).toDate();
  rsf.ref(branchPath + "/students/" + studentNodeId).set(
    {
      address: values.address !== undefined ? values.address : null,
      //birthDate: moment(new Date(values.birthDate)).format("DD[, ]MMMM[, ]YYYY"),
      birthDate: moment(new Date(formattedDOB)).format("DD[, ]MMMM[, ]YYYY"),
      bloodGroup: values["bloodGroup(Optional)"]
        ? values["bloodGroup(Optional)"]
        : null,
      chatPermission: false,
      //classId: classroom[0].id,
      classroomName: values.classroom,
      dateCreated: new Date().getTime(),
      deactivated: false,
      deleted: false,
      fatherName: values.fatherName,
      fatherEmail: values.fatherEmail ? values.fatherEmail : null,
      fatherNumber: values.fatherNumber ? Number(values.fatherNumber) : null,
      fatherProfileId: fatherNodeId ? fatherNodeId : null,
      gender: values.gender.charAt(0).toUpperCase() + values.gender.slice(1),
      id: studentNodeId,
      motherName: values.motherName,
      motherEmail: values.motherEmail ? values.motherEmail : null,
      motherNumber: values.motherNumber ? Number(values.motherNumber) : null,
      motherProfileId: motherNodeId ? motherNodeId : null,
      name: values.studentName,
      platform: "web_onboarding",
      updatedBy: firebase.teacher.name,
      updatedOn: new Date().getTime(),
      status: "Active",
    },
    function(error) {
      if (error) {
        console.log("failed to add student");
      } else {
        console.log("student added successfully");
      }
    }
  );
}

function getClassroomByName(className, firebase) {
  // let newClassName = className.trim();
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var classroom = [];
  var classroomPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/classrooms/")
      .orderByChild("className")
      .equalTo(className)
      .once("value")
      .then(function(snapshot) {
        snapshot.forEach((item) => {
          if (item.val() !== undefined) {
            classroom.push(item.val());
          }
        });
        resolve(classroom);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
  return classroomPromise;
}

function addSecondChildOfExistingParent(
  values,
  tags,
  studentNodeId,
  fatherNodeId,
  motherNodeId,
  existingStudentName,
  existingStudentId,
  firebase
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/students/" + studentNodeId).set(
    {
      address: values.address !== undefined ? values.address : null,
      birthDate: moment(new Date(values.birthDate)).format(
        "DD[, ]MMMM[, ]YYYY"
      ),
      //classId: values.classroom[1],
      classroomName: values.classroom[0],
      dateCreated: new Date().getTime(),
      deactivated: false,
      deleted: false,
      fatherEmail: values.fatherEmail !== undefined ? values.fatherEmail : null,
      fatherName: values.father,
      fatherNumber:
        values.fatherContact !== undefined
          ? Number(values.fatherContact)
          : null,
      fatherProfileId: fatherNodeId !== undefined ? fatherNodeId : null,
      gender: values.gender,
      id: studentNodeId,
      motherEmail: values.motherEmail !== undefined ? values.motherEmail : null,
      motherName: values.mother,
      motherNumber:
        values.motherContact !== undefined
          ? Number(values.motherContact)
          : null,
      motherProfileId: motherNodeId !== undefined ? motherNodeId : null,
      name: values.name,
      chatPermission: false,
      tags: tags !== undefined && tags.length > 0 ? tags : null,
      siblingId: existingStudentId,
      siblingName: existingStudentName,
      platform: "web",
      status: "Active",
    },
    function(error) {
      if (error) {
        console.log("failed to add student");
      } else {
        console.log("student added successfully");
      }
    }
  );
}

function addSecondChildOfExistingParentUsingCsv(
  values,
  classroom,
  studentNodeId,
  fatherNodeId,
  motherNodeId,
  existingStudentName,
  existingStudentId,
  firebase
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let formattedDOB = moment(values.birthDate, [
    "DD/MM/YY",
    "DD-MM-YYYY",
    "DD, MMMM, YYYY",
    "DD.MM.yyyy",
    "YYYY-MM-DD",
    "DD[-]MM[-]YYYY",
  ]).toDate();
  rsf.ref(branchPath + "/students/" + studentNodeId).set(
    {
      address: values.address !== undefined ? values.address : null,
      birthDate: moment(new Date(formattedDOB)).format("DD[, ]MMMM[, ]YYYY"),
      bloodGroup: values["bloodGroup(Optional)"]
        ? values["bloodGroup(Optional)"]
        : null,
      chatPermission: false,
      //classId: classroom[0].id,
      classroomName: values.classroom,
      dateCreated: new Date().getTime(),
      deactivated: false,
      deleted: false,
      fatherName: values.fatherName,
      fatherEmail: values.fatherEmail ? values.fatherEmail : null,
      fatherNumber: values.fatherNumber ? Number(values.fatherNumber) : null,
      fatherProfileId: fatherNodeId ? fatherNodeId : null,
      gender: values.gender,
      id: studentNodeId,
      motherName: values.motherName,
      motherEmail: values.motherEmail ? values.motherEmail : null,
      motherNumber: values.motherNumber ? Number(values.motherNumber) : null,
      motherProfileId: motherNodeId ? motherNodeId : null,
      name: values.studentName,
      siblingId: existingStudentId,
      siblingName: existingStudentName,
      platform: "web",
      status: "Active",
    },
    function(error) {
      if (error) {
        console.log("failed to update student");
      } else {
        console.log("student updated successfully");
      }
    }
  );
}

function addSibling(studentData, siblingId, siblingName, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/students/" + studentData.id).update(
    {
      siblingId: siblingId,
      siblingName: siblingName,
      platform: "web",
    },
    function(error) {
      if (error) {
        console.log("failed to add student");
      } else {
        console.log("student added successfully");
      }
    }
  );
}

function generateUniqueCalendarReferenceId(className, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/calendarReference/" + className).push().key;
  return key;
}

function addCsvHolidayToClass(className, holidayNodeId, values, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  //let startDate = new Date(values.startDate);
  let startDate = moment(values.startDate, [
    "DD/MM/YY",
    "DD-MM-YYYY",
    "DD/MM/YYYY",
    "DD, MMMM, YYYY",
    "DD.MM.yyyy",
    "YYYY-MM-DD",
    "DD[-]MM[-]YYYY",
  ]).toDate();
  let endDate = null;
  let fromDate = {
    date: startDate.getDate(),
    day: startDate.getDay(),
    hours: startDate.getHours(),
    minutes: startDate.getMinutes(),
    month: startDate.getMonth(),
    seconds: startDate.getSeconds(),
    time: startDate.getTime(),
    timezoneOffset: startDate.getTimezoneOffset(),
    year: startDate.getFullYear() - 1900,
  };
  if (values["endDate(Optional)"]) {
    endDate = moment(values["endDate(Optional)"], "DD[/]MM[/]YYYY").toDate();
    endDate = {
      date: endDate.getDate(),
      day: endDate.getDay(),
      hours: endDate.getHours(),
      minutes: endDate.getMinutes(),
      month: endDate.getMonth(),
      seconds: endDate.getSeconds(),
      time: endDate.getTime(),
      timezoneOffset: endDate.getTimezoneOffset(),
      year: endDate.getFullYear() - 1900,
    };
  }
  rsf
    .ref(branchPath + "/calendarReference/" + className + "/" + holidayNodeId)
    .update({
      classroomName: className,
      fromDate: fromDate,
      endDate: endDate ? endDate : null,
      id: holidayNodeId,
      name: values.holidayName,
      timestamp: startDate.getTime(),
      platform: "web",
      updatedBy: firebase.teacher.name,
      updatedOn: moment().valueOf(),
    });
}

function getStudentDetail(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emitter) => {
    rsf.ref(branchPath + "/students/" + studentId).on("value", (snap) => {
      const studentData = [];
      if (snap.val() !== null) {
        studentData.push(snap.val());
      }
      emitter(studentData);
    });
    return () => {
      rsf.ref(branchPath + "/students/" + studentId).off();
      console.log("END");
    };
  });
}

function getUserVersions(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var users = [];
  var userPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/userVersion")
      .once("value")
      .then(function(snapshot) {
        // snapshot.forEach(item => {
        if (snapshot.val() !== null) {
          users.push(snapshot.val());
        }
        // });
        resolve(users);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
  return userPromise;
}

function sendInviteToParents(parentList, firebase) {
  let emails = [];
  for (let i = 0; i < parentList.length; i++) {
    if (parentList[i].fatherEmail) {
      emails.push(parentList[i].fatherEmail);
    }

    if (parentList[i].motherEmail) {
      emails.push(parentList[i].motherEmail);
    }
  }

  let config = firebase.schoolConfig;
  let schoolName = config.schoolName;
  let accountName = firebase.sbDbName;
  let teacherEmail = firebase.schoolConfig.adminEmail;
  let inviteEndPointUrl = firebase.endPointUrl + "sendParentInvite";

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();
  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(inviteEndPointUrl)
      .send({
        parentEmails: emails,
        ccEmail: teacherEmail,
        schoolName: schoolName,
        accountName: accountName,
        timezone: localTimezone,
        platform: "web",
        updatedBy: firebase.teacher.name,
        updatedOn: moment().valueOf(),
      })
      .set("accept", "json")

      .end((err, res) => {
        console.log("send invite response --------", res);
        resolve(res);
      });
  });
  return p1;
}

function generateUserNode(firebase) {
  const rsf = firebase.db;
  var key = rsf.ref("newUser").push().key;
  return key;
}

function addToNewUser(userId, userContact, childId, userType, firebase) {
  const rsf = firebase.db;
  rsf.ref("/newUser/" + userContact).set(
    {
      id: userId,
      phoneNumber: Number(userContact),
      schoolName: firebase.sbDbName,
      studentId: childId,
      userType: userType,
      platform: "web",
    },
    (error) => {
      if (error) {
        notification("error", "Session expired. Please re-login");
        firebase.signOut();
      } else {
        // Data saved successfully!
      }
    }
  );
}

function updateSecondChildToNewUser(user, firebase) {
  const rsf = firebase.db;
  rsf.ref("/newUser/" + user.phoneNumber).update(
    {
      child2Branch: firebase.schoolConfig.branchPath,
      child2Id: user.child2Id,
      child2inactive: user.child2inactive,
      child1inactive: user.child1inactive,
      platform: "web",
    },
    (error) => {
      if (error) {
        notification("error", "Session expired. Please re-login");
        firebase.signOut();
      } else {
        // Data saved successfully!
      }
    }
  );
}

function updateUserForTeacherChild(contact, student, firebase) {
  const rsf = firebase.db;
  rsf.ref("/newUser/" + contact).update(
    {
      studentId: student.id,
      platform: "web",
    },
    (error) => {
      if (error) {
        notification("error", "Session expired. Please re-login");
        firebase.signOut();
      } else {
        // Data saved successfully!
      }
    }
  );
}

function getStudentById(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var student = {};
  var studentPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/students/" + studentId)
      .once("value")
      .then(function(snapshot) {
        if (snapshot.val() !== null && snapshot.val().deleted === false) {
          student = snapshot.val();
        }
        resolve(student);
      });
  });
  return studentPromise;
}

function getSelectedStudentAttendance(studentId, month, firebase) {
  let searchableMonth = moment(month).format("MMMM[ ]YYYY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var studentAttendance = [];
  var studentAttendancePromise = new Promise(function(resolve, reject) {
    rsf
      .ref(
        branchPath +
          "/" +
          "student-attendance/" +
          studentId +
          "/" +
          searchableMonth
      )
      .once("value")
      .then(function(snapshot) {
        snapshot.forEach((item) => {
          if (item.val() !== null && item.val().id) {
            studentAttendance.push(item.val());
          }
        });
        resolve(studentAttendance);
      });
  });
  return studentAttendancePromise;
}

function getSelectedStudentLeave(firstDay, lastDay, studentId, firebase) {
  var firstDayTime = new Date(firstDay).getTime();
  var lastDayTime = new Date(lastDay).getTime();
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var studentLeave = [];
  var studentLeavePromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/leaves/")
      .orderByChild("startDate")
      .startAt(firstDayTime)
      .endAt(lastDayTime)
      .once("value")
      .then(function(snapshot) {
        snapshot.forEach((item) => {
          if (
            item.val() !== null &&
            item.val().studentId === studentId &&
            item.val().status.toLowerCase() === "approved"
          ) {
            studentLeave.push(item.val());
          }
        });
        resolve(studentLeave);
      });
  });
  return studentLeavePromise;
}

function updateAdditionalInfo(additionalObj, studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/students/" + studentId).update(additionalObj);
}

function updateAdditionalFields(userType, updatedObject, studentId, firebase) {
  let config;
  if (userType === "Father") {
    config = "fatherConfig";
  } else if (userType === "Mother") {
    config = "motherConfig";
  } else if (userType === "Child") {
    config = "childConfig";
  }
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/students/" + studentId + "/" + config)
    .update(updatedObject);
}

function getAllFormFields(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var formField = [];
  var formPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/fieldRef")
      .orderByChild("deleted")
      .equalTo(false)
      .once("value")
      .then(function(snapshot) {
        snapshot.forEach((item) => {
          if (item.val() !== null) {
            formField.push(item.val());
          }
        });
        resolve(formField);
      });
  });
  return formPromise;
}

function updateStudentWithUpdatedFormFields(studentData, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/students/" + studentData.id).update(studentData);
}

function addNewStudent(values, id, firebase, admissionNumber) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/students/" + id)
    .update({
      classroomName: values.classroom[0],
      classList: values.classroom,
      dateCreated: new Date().getTime(),
      deactivated: false,
      deactivationDate: 0,
      deleted: false,
      deletedOn: 0,
      // fatherNumber: 0,
      gender: values.gender,
      id: id,
      // motherNumber: 0,
      name: values.studentName,
      napEndTime: 0,
      napStartTime: 0,
      present: false,
      section: false,
      platform: "web",
      status: values.status ? values.status : "Active",
      updatedBy: firebase.teacher.name,
      updatedOn: new Date().getTime(),
      admissionNumber: admissionNumber ? admissionNumber : null,
      preferredName: values.prefName ? values.prefName : null,
    })
    .then((res) => {
      firebase.refreshStudents();
    });
}

function updateNewUser(contact, userObj, firebase) {
  const rsf = firebase.db;
  //change here
  rsf.ref("newUser/" + contact).set(userObj, (error) => {
    if (error) {
      notification("error", "Session expired. Please re-login");
      firebase.signOut();
    } else {
      // Data saved successfully!
    }
  });
}

function getAllStudentByCenter(firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath;
  var student = [];
  var studentPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/students")
      .once("value")
      .then(function(snapshot) {
        snapshot.forEach((snap) => {
          if (
            snap.val() !== null &&
            snap.val().deleted === false &&
            snap.val().deactivated === false
          ) {
            if (
              snap.val().status &&
              snap.val().status.toLowerCase() === "active"
            ) {
              student.push(snap.val());
            } else if (!snap.val().status) {
              student.push(snap.val());
            }
          }
        });
        resolve(student);
      });
  });
  return studentPromise;
}

function updateStudentClassChange(studentsList, classroom, firebase) {
  let studentChangeUrl = firebase.endPointUrl + "updateClassroom";

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(studentChangeUrl)
      .send({
        branchPath: firebase.sbp,
        accountName: firebase.sbDbName,
        newClassroom: classroom,
        studentList: studentsList,
        timezone: localTimezone,
      })
      .set("accept", "json")

      .end((err, res) => {
        console.log("error", err);
        console.log("res", res);
        if (res) {
          resolve(res);
        } else if (err) {
          reject(err);
        }
      });
  });
  return p1;
}

function getUserDocument(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var docs = [];
  var studentPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/documents/" + studentId)
      .orderByChild("inverseDate")
      .once("value")
      .then(function(snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            if (snap.val().type.toLowerCase() === "folder") {
              docs.push(snap.val());
            } else if (snap.val().downloadUrl) {
              docs.push(snap.val());
            }
          }
        });
        resolve(docs);
      });
  });
  return studentPromise;
}

function getStudentDocNode(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/documents").push().key;
  return key;
}

function uploadDocument(id, obj, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/documents/" + id + "/" + obj.id).set(obj);
}

function deleteDocument(record, id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/documents/" + id + "/" + record.id).set(null);
}

function getStudentByIdFromMainBranch(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.schoolConfig.branchPath;
  var student = {};
  var studentPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/students/" + studentId)
      .once("value")
      .then(function(snapshot) {
        if (snapshot.val() !== null && snapshot.val().deleted === false) {
          student = snapshot.val();
        }
        resolve(student);
      });
  });
  return studentPromise;
}

function sendLoginInviteToParents(emails, firebase) {
  let schoolName = localStorage.getItem("schoolName");
  let teacherEmail = firebase.schoolConfig.adminEmail;
  let inviteEndPointUrl = firebase.endPointUrl + "sendParentInvite";

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(inviteEndPointUrl)
      .send({
        parentEmails: emails,
        ccEmail: teacherEmail,
        schoolName: schoolName,
        timezone: localTimezone,
        platform: "web",
        updatedBy: firebase.teacher.name,
        updatedOn: moment().valueOf(),
      })
      .set("accept", "json")

      .end((err, res) => {
        console.log("send invite response --------", res);
        resolve(res);
      });
  });
  return p1;
}

function getStudentByClassroomName(classroom, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var students = [];
  var studentPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/students")
      .orderByChild("deleted")
      .equalTo(false)
      .once("value")
      .then(function(snapshot) {
        snapshot.forEach((snap) => {
          if (
            snap.val() !== null &&
            snap.val().classList &&
            snap.val().classList.includes(classroom) &&
            !snap.val().deactivated
          ) {
            students.push(snap.val());
          }
        });
        resolve(students);
      });
  });
  return studentPromise;
}

function createNewNoteId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/studentNotes").push().key;
  return key;
}

function addStudentNotes(value, id, studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/studentNotes/" + studentId + "/" + id).update({
    id: id,
    description: value.additionalDescription
      ? value.additionalDescription
      : null,
    createdBy: firebase.teacher.name,
    teacherId: firebase.teacher.id,
    updatedBy: firebase.teacher.name,
    updatedOn: new Date().getTime(),
    profileImage: firebase.teacher.profileImageUrl
      ? firebase.teacher.profileImageUrl
      : null,
    platform: "web",
  });
}

function getStudentNotes(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let myRef = rsf.ref(branchPath + "/studentNotes/" + studentId);

  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      var x = [];
      snap.forEach((element) => {
        if (element.val() !== null) {
          x.push(element.val());
        }
      });
      emit(x);
    });
    return () => {
      myRef.off();
      console.log("unsubscribe student notes");
    };
  });
}

function updateStudentNotes(value, studentId, firebase, record) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let myRef = rsf.ref(
    branchPath + "/studentNotes/" + studentId + "/" + record.id
  );
  myRef.update({
    description: value.additionalDescription
      ? value.additionalDescription
      : null,
    updatedBy: firebase.teacher.name,
    updatedOn: new Date().getTime(),
    platform: "web",
  });
}

function deleteStudentNotes(studentId, firebase, record) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/studentNotes/" + studentId + "/" + record.id)
    .set(null);
}

function getAdmissionCounter(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var counter = rsf
    .ref(branchPath + "/admissionNumberCounter")
    .once("value")
    .then(function(snap) {
      return snap.val();
    });
  return counter;
}

function incrementAdmissionCounter(prevCounter, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/admissionNumberCounter").set(prevCounter + 1);
}

function getStudentHolidays(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let holidays = [];

  let promise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/calendarReference/All Classrooms")
      .once("value")
      .then((snapshot) => {
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            holidays.push(snap.val());
          }
        });
        resolve(holidays);
      });
  });
  return promise;
}

function getStripeCustomer(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let obj = {};

  let promise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/stripeCustomer/" + studentId)
      .once("value")
      .then((snapshot) => {
        if (snapshot.val() !== null) {
          obj = snapshot.val();
        }
        resolve(obj);
      });
  });
  return promise;
}

function verifyParentApi(
  firebase,
  studentName,
  studentId,
  parentNumber,
  parentName,
  parentEmail,
  parentType
) {
  let endPointUrl = firebase.endPointUrl + "userApi/verifyParent";

  let accountName = firebase.sbDbName;
  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(endPointUrl)
      .send({
        accountName: accountName,
        studentName: studentName,
        studentId: studentId,
        parentName: parentName,
        parentNumber: parentNumber,
        parentEmail: parentEmail ? parentEmail : null,
        parentType: parentType,
      })
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("verify parent error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function addParentApi(
  firebase,
  studentName,
  studentId,
  parentName,
  parentNumber,
  parentEmail,
  parentType,
  studentRecord,
  countryCode
) {
  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl + "woodlandApi/parents/?centerId=" + accountName;

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(endPointUrl)
      .set({ Authorization: "Bearer y2jKtvNGNPm9HFDTE3shfUd49jzaKhzK" })
      .send({
        name: parentName,
        countryCode: countryCode,
        phoneNumber: parentNumber,
        email: parentEmail ? parentEmail : null,
        relation: parentType,
        studentIds: [studentId],
        studentId: studentId,

        // accountName: accountName,
        // studentName: studentName,
        // studentId: studentId,
        // parentName: parentName,
        // parentNumber: parentNumber,
        // parentEmail: parentEmail ? parentEmail : null,
        // parentType: parentType,
        // branchPath: firebase.sbp,
        // student: studentRecord,
        // platform: "web",
        // updatedBy: firebase.teacher.name,
        // updatedOn: moment().valueOf(),
      })
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("add parent error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function addSiblingApi(
  firebase,
  studentObj,
  siblingId,
  siblingName,
  dbName,
  parentIds
) {
  let accountName = dbName ? dbName : firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl + "woodlandApi/siblings/?centerId=" + accountName;

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(endPointUrl)
      .set({ Authorization: "Bearer y2jKtvNGNPm9HFDTE3shfUd49jzaKhzK" })
      .send({
        student: studentObj,
        siblingId: siblingId,
        siblingBranchPath: firebase.sbp,
        parentIds: parentIds,
      })
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("add sibling error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function updateParentApi(
  firebase,
  studentName,
  studentId,
  parentName,
  parentNumber,
  parentEmail,
  parentCompanyName,
  parentConfig,
  countryCode,
  parentType,
  oldPhoneNumber,
  oldEmail,
  studentRecord,
  parentId
) {
  // let endPointUrl = firebase.endPointUrl + "userApi/updateParent";
  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl +
    "woodlandApi/parents/" +
    parentId +
    "?centerId=" +
    accountName;

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(endPointUrl)
      .set({ Authorization: "Bearer y2jKtvNGNPm9HFDTE3shfUd49jzaKhzK" })
      .send({
        id: parentId,
        name: parentName,
        relation: parentType,
        phoneNumber: parentNumber,
        email: parentEmail,
        additionalFields: parentConfig,
        countryCode: countryCode,
        companyName: parentCompanyName,

        // accountName: accountName,
        // branchPath: firebase.sbp,
        // studentName: studentName,
        // studentId: studentId,
        // parentName: parentName,
        // parentNumber: parentNumber,
        // parentEmail: parentEmail,
        // parentCompanyName: parentCompanyName,
        // parentConfig: parentConfig,
        // code: countryCode,
        // parentType: parentType,
        // oldPhoneNumber: oldPhoneNumber,
        // oldEmail: oldEmail,
        // student: studentRecord,
        // platform: "web",
        // updatedBy: firebase.teacher.name,
        // updatedOn: moment().valueOf(),
      })
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("update parent error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function deleteStudentApi(firebase, studentObj) {
  let endPointUrl = firebase.endPointUrl + "userApi/deleteStudent";

  let accountName = firebase.sbDbName;
  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(endPointUrl)
      .send({
        accountName: accountName,
        branchPath: firebase.sbp,
        student: studentObj,
        platform: "web",
        updatedBy: firebase.teacher.name,
        updatedOn: moment().valueOf(),
      })
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("delete student error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function addDocumentFolder(nodeId, id, folderName, filePath, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let obj = {
    createdBy: firebase.teacher.name,
    createdOn: new Date().getTime(),
    filePath: filePath ? filePath : "",
    downloadUrl: null,
    id: nodeId,
    inverseDate: -new Date().getTime(),
    folder: true,
    name: folderName,
    nameWithExtension: folderName,
    type: "folder",
    platform: "web",
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
  };
  rsf.ref(branchPath + "/documents/" + id + "/" + obj.id).set(obj);
}

function renameDoc(docName, id, firebase, userId) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/documents/" + userId + "/" + id).update({
    name: docName,
    platform: "web",
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
  });
}

function getStudentByGroup(groupName, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  let students = [];
  let promise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/students")
      .orderByChild("deleted")
      .equalTo(false)
      .once("value")
      .then(function(snapshot) {
        snapshot.forEach((snap) => {
          if (
            snap.val() !== null &&
            !snap.val().deactivated &&
            snap.val().tags
          ) {
            let studentTags = snap.val().tags;
            let status = false;
            for (let index in studentTags) {
              if (studentTags[index].name === groupName) {
                status = true;
                break;
              }
            }

            if (status) {
              students.push(snap.val());
            }
          }
        });
        resolve(students);
      });
  });
  return promise;
}

function addAuthorizedPickupApi(firebase, data, studentId) {
  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl + "woodlandApi/parents/?centerId=" + accountName;

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(endPointUrl)
      .set({ Authorization: "Bearer y2jKtvNGNPm9HFDTE3shfUd49jzaKhzK" })
      .send({
        name: data.name,
        phoneNumber: data.phoneNumner ? data.phoneNumner : null,
        email: data.email ? data.email : null,
        relation: "pickup",
        studentIds: [studentId],
        relationship: data.realtion,
        url: data.url ? data.url : null,
      })
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("add auth pickup error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function deleteAuthorizedPickupApi(firebase, pickupId, studentId) {
  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl +
    "woodlandApi/parents/" +
    pickupId +
    "?centerId=" +
    accountName;

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .delete(endPointUrl)
      .set({ Authorization: "Bearer y2jKtvNGNPm9HFDTE3shfUd49jzaKhzK" })
      .send({
        studentIds: [studentId],
      })
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("add auth pickup error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function updateAuthorizedPickupApi(firebase, pickupId, data) {
  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl +
    "woodlandApi/parents/" +
    pickupId +
    "?centerId=" +
    accountName;

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(endPointUrl)
      .set({ Authorization: "Bearer y2jKtvNGNPm9HFDTE3shfUd49jzaKhzK" })
      .send({
        id: pickupId,
        name: data.name,
        phoneNumber: data.phoneNumner ? data.phoneNumner : null,
        email: data.email ? data.email : null,
        relation: "pickup",
        relationship: data.realtion,
        url: data.url ? data.url : null,
      })
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("add auth pickup error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function addStudentApi(firebase, values) {
  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl + "woodlandApi/students/?centerId=" + accountName;

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(endPointUrl)
      .set({ Authorization: "Bearer y2jKtvNGNPm9HFDTE3shfUd49jzaKhzK" })
      .send({
        classroomName: values.classroom[0],
        classList: values.classroom,
        gender: values.gender,
        name: values.studentName,
        platform: "web",
        status: values.status ? values.status : "Active",
        updatedBy: firebase.teacher.name,
        updatedOn: new Date().getTime(),
        preferredName: values.prefName ? values.prefName : null,
      })
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("add student error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function deleteStudentNewApi(firebase, id) {
  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl +
    "woodlandApi/students/" +
    id +
    "?centerId=" +
    accountName;

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .delete(endPointUrl)
      .set({ Authorization: "Bearer y2jKtvNGNPm9HFDTE3shfUd49jzaKhzK" })
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("delete student error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function updateStudentApi(firebase, id, data) {
  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl +
    "woodlandApi/students/" +
    id +
    "?centerId=" +
    accountName;

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(endPointUrl)
      .set({ Authorization: "Bearer y2jKtvNGNPm9HFDTE3shfUd49jzaKhzK" })
      .send(data)
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("add student error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function deleteParentApi(firebase, parentId) {
  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl +
    "woodlandApi/parents/" +
    parentId +
    "?centerId=" +
    accountName;

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .delete(endPointUrl)
      .set({ Authorization: "Bearer y2jKtvNGNPm9HFDTE3shfUd49jzaKhzK" })
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("delete parent error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function unlinkParentApi(firebase, parentId, studentId, relation) {
  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl + "woodlandApi/unlink/?centerId=" + accountName;

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(endPointUrl)
      .set({ Authorization: "Bearer y2jKtvNGNPm9HFDTE3shfUd49jzaKhzK" })
      .send({
        id: studentId,
        studentId: studentId,
        parentId: parentId,
        relation: relation ? relation : null,
      })
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("unlink parent error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function linkParentApi(firebase, parentId, studentId, relation, relationship) {
  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl + "woodlandApi/link/?centerId=" + accountName;

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(endPointUrl)
      .set({ Authorization: "Bearer y2jKtvNGNPm9HFDTE3shfUd49jzaKhzK" })
      .send({
        id: studentId,
        studentId: studentId,
        parentId: parentId,
        relation: relation ? relation : null,
        relationship: relationship ? relationship : null,
      })
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("link parent error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

export const StudentApi = {
  getAllStudents,
  getAllParents,
  addStudentData,
  detailedStudentList,
  getAllStudentTags,
  getAutoCompleteClassrooms,
  createStudentNodeId,
  updateStudentData,
  updateFatherUser,
  updateFatherUserWithoutNumberUpdate,
  updateMotherUser,
  updateMotherUserWithoutNumberUpdate,
  deleteStudent,
  addStudentFromCsv,
  getClassroomByName,
  addSecondChildOfExistingParent,
  addSecondChildOfExistingParentUsingCsv,
  addSibling,
  addCsvHolidayToClass,
  generateUniqueCalendarReferenceId,
  getStudentDetail,
  getUserVersions,
  sendInviteToParents,
  generateUserNode,
  addToNewUser,
  updateSecondChildToNewUser,
  getStudentById,
  getSelectedStudentAttendance,
  getSelectedStudentLeave,
  updateAdditionalInfo,
  updateAdditionalFields,
  getAllFormFields,
  updateStudentWithUpdatedFormFields,
  addNewStudent,
  updateUserForTeacherChild,
  updateNewUser,
  getAllStudentByCenter,
  updateStudentClassChange,
  getUserDocument,
  getStudentDocNode,
  uploadDocument,
  deleteDocument,
  getStudentByIdFromMainBranch,
  sendLoginInviteToParents,
  getStudentByClassroomName,
  createNewNoteId,
  addStudentNotes,
  getStudentNotes,
  updateStudentNotes,
  deleteStudentNotes,
  getAdmissionCounter,
  incrementAdmissionCounter,
  getStudentHolidays,
  getStripeCustomer,
  verifyParentApi,
  addParentApi,
  addSiblingApi,
  updateParentApi,
  deleteStudentApi,
  addDocumentFolder,
  renameDoc,
  getStudentByGroup,
  addAuthorizedPickupApi,
  deleteAuthorizedPickupApi,
  updateAuthorizedPickupApi,
  addStudentApi,
  deleteStudentNewApi,
  updateStudentApi,
  deleteParentApi,
  unlinkParentApi,
  linkParentApi,
};
