const actions = {
    VIEW_LESSON: "VIEW_LESSON",
    VIEW_LESSON_SUCCESSFUL: "VIEW_LESSON_SUCCESSFUL",
    VIEW_REQUEST_FAILED: "VIEW_REQUEST_FAILED",
    GET_VIEW_LESSON_STUDENT: "GET_VIEW_LESSON_STUDENT",
    GET_VIEW_LESSON_STUDENT_SUCCESS: "GET_VIEW_LESSON_STUDENT_SUCCESS",

    getLessonDetail: (lessonId, firebase) => ({
        type: actions.VIEW_LESSON,
        lessonId,
        firebase
    }),

    getViewLessonStudent: () => ({
        type: actions.GET_VIEW_LESSON_STUDENT
    })


}
export default actions;