import actions from "./actions";
const initState = {
  documents: [],
  isLoading: false,
  documentChannel: undefined,
  documentOperationType: "",
  error: false
};

export default function tagReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.LIST_DOCUMENTS:
      return {
        ...state,
        isLoading: true,
        documentOperationType: ""
      };
    case actions.LIST_DOCUMENTS_SUCCESSFUL:
      return {
        ...state,
        documents: action.documents,
        isLoading: false,
        documentChannel: action.documentChannel,
        documentOperationType: ""
      };

    case actions.SAVE_DOCUMENT:
      return {
        ...state,
        isLoading: true
      };
    case actions.SAVE_DOCUMENT_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        documentOperationType: "ADD_DOCUMENT"
      };

    case actions.SAVE_DOCUMENT_IN_ALL_CENTERS:
      return {
        ...state,
        isLoading: true
      };
    case actions.SAVE_DOCUMENT_IN_ALL_CENTERS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        documentOperationType: "ALL_CENTER_ADD_DOCUMENT"
      };

    case actions.DOCUMENT_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true
      };

    case actions.DELETE_DOCUMENT:
      return {
        ...state,
        isLoading: true
      };
    case actions.DELETE_DOCUMENT_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        documentOperationType: "DELETE_DOCUMENT"
      };

    case actions.RESET_DOCUMENT_OPERATION_TYPE:
      return {
        ...state,
        isLoading: false,
        documentOperationType: undefined,
        error: false
      };

    case actions.ADD_COMMON_TEACHER_DOC_FOLDER:
      return {
        ...state,
        isLoading: true
      };
    case actions.ADD_COMMON_TEACHER_DOC_FOLDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        documentOperationType: "ADD_DOC_FOLDER"
      };

    case actions.RENAME_DOC:
      return {
        ...state,
        isLoading: true
      };
    case actions.RENAME_DOC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        documentOperationType: "RENAME_DOC_FOLDER"
      };

    default:
      return state;
  }
}
