import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment-timezone";
import { eventChannel } from "redux-saga";
import actions from "../redux/app/actions";
import { store } from "../redux/store";
import FilterPermission from "../Utility/FilterPermission";
import PermissionStrings from "../Utility/PermissionStrings";
import imageCompression from "../../node_modules/browser-image-compression/dist/browser-image-compression";

const superagent = require("superagent");
var Firebase = require("firebase");

function getStudentUpdated(activityId, firebase) {
  let students = {};
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var myPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/studentsUpdated/" + activityId)
      .once("value")
      .then(function(snap) {
        if (snap.val() !== null) {
          students = snap.val();
        }
        resolve(students);
      });
  });

  return myPromise;
}

function approveSelectedActivity(item, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/activities/" + item.id).update({
    approved: true,
  });
}

function updateActivity(values, record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/activities/" + record.id).update({
    message: values.note,
    htmlText: null,
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
  });

  if (
    record.activityType === "Virtual Class" &&
    record.repeatStartDate > 0 &&
    record.repeatEndDate > 0
  ) {
    if (record.parentActivityId) {
      rsf.ref(branchPath + "/repeatClasses/" + record.parentActivityId).update({
        message: values.note,
        htmlText: null,
        updatedBy: firebase.teacher.name,
        updatedOn: moment().valueOf(),
      });
    } else {
      rsf.ref(branchPath + "/repeatClasses/" + record.id).update({
        message: values.note,
        htmlText: null,
        updatedBy: firebase.teacher.name,
        updatedOn: moment().valueOf(),
      });
    }
  }
}

function deleteActivity(item, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/activities/" + item.id).update({
    deleted: true,
    repeatStartDate: 0,
    repeatEndDate: 0,
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
  });

  if (
    item.activityType === "Virtual Class" &&
    item.repeatStartDate > 0 &&
    item.repeatEndDate > 0
  ) {
    if (item.parentActivityId) {
      rsf.ref(branchPath + "/repeatClasses/" + item.parentActivityId).update({
        deleted: true,
        repeatStartDate: 0,
        repeatEndDate: 0,
        updatedBy: firebase.teacher.name,
        updatedOn: moment().valueOf(),
      });

      rsf.ref(branchPath + "/activities/" + item.parentActivityId).update({
        deleted: true,
        repeatStartDate: 0,
        repeatEndDate: 0,
        updatedBy: firebase.teacher.name,
        updatedOn: moment().valueOf(),
      });
    } else {
      rsf.ref(branchPath + "/repeatClasses/" + item.id).update({
        deleted: true,
        repeatStartDate: 0,
        repeatEndDate: 0,
        updatedBy: firebase.teacher.name,
        updatedOn: moment().valueOf(),
      });
    }
  }

  deleteStudentMedia(item, firebase);
}

function deleteStudentMedia(item, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let studentIds = item.studentIds ? item.studentIds : [];
  let activityId = item.id;

  if (item.mediaProfiles) {
    for (let index in studentIds) {
      let studentId = studentIds[index];
      rsf
        .ref(branchPath + "/photos/" + studentId)
        .orderByChild("activityId")
        .equalTo(activityId)
        .once("value")
        .then(function(snapshot) {
          if (snapshot.val()) {
            snapshot.forEach((snap) => {
              let nodeId = snap.key;

              rsf
                .ref(branchPath + "/photos/" + studentId + "/" + nodeId)
                .set(null);
            });
          }
        });
    }
  }
}

function getActivityTemplate(activityName, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let templates = [];
  const myRef = rsf.ref(branchPath + "/activityTemplateValues/" + activityName);
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((element) => {
        if (element.val() !== null) {
          templates.push(element.val());
        }
      });
      resolve(templates);
    });
  });
  return promise1;
}

function generateNewActivityNode(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/activities").push().key;
  return key;
}

function generateNewStaffActivityNode(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/staffActivities").push().key;
  return key;
}

function getPathToStorage(contentType, firebase) {
  let storagePath;
  switch (contentType) {
    case "application/octet-stream":
      storagePath = firebase.sbp + "/media/file/";
      break;
    case "text/csv":
      storagePath = firebase.sbp + "/media/file/";
      break;
    case "image/jpeg":
      storagePath = firebase.sbp + "/media/images/";
      break;
    case "image/png":
      storagePath = firebase.sbp + "/media/images/";
      break;
    case "image/bmp":
      storagePath = firebase.sbp + "/media/images/";
      break;
    case "image/gif":
      storagePath = firebase.sbp + "/media/images/";
      break;
    case "image/webp":
      storagePath = firebase.sbp + "/media/images/";
      break;
    case "video/mp4":
      storagePath = firebase.sbp + "/media/videos/";
      break;
    case "video/3gpp":
      storagePath = firebase.sbp + "/media/videos/";
      break;
    case "video/mpeg":
      storagePath = firebase.sbp + "/media/videos/";
      break;
    case "video/quicktime":
      storagePath = firebase.sbp + "/media/videos/";
      break;
    default:
      storagePath = firebase.sbp + "/media/images/";
  }
  return storagePath;
}

function getMediaPath(pathToStorage, files, firebase, activityId, bPath) {
  var urls = [];
  var promiseList = [];
  let branchPath = bPath ? bPath : firebase.sbp;

  files.fileList.forEach(function(singleFile, index) {
    var file = singleFile.originFileObj ? singleFile.originFileObj : singleFile;
    var metadata = {
      contentType: singleFile.type,
      cacheControl: "public,max-age=604800",
    };
    const storage = firebase.secondaryStorage;
    const storageRef = storage.ref();
    let fileName = new Date().getTime() + file.name;
    const attachmentRef = storageRef.child(
      getPathToStorage(singleFile.type, firebase) + fileName
    );

    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    let compressedFilePromise = imageCompression(file, options);

    var p2 = compressedFilePromise
      .then((fileRes) => {
        let file = fileRes;
        return getMediaDownloadUrl(
          attachmentRef,
          file,
          metadata,
          activityId,
          urls,
          singleFile,
          firebase,
          branchPath,
          fileName,
          index
        );
      })
      .catch((err) => {
        return getMediaDownloadUrl(
          attachmentRef,
          file,
          metadata,
          activityId,
          urls,
          singleFile,
          firebase,
          branchPath,
          fileName,
          index
        );
      });

    promiseList.push(p2);
  });
  return Promise.all(promiseList)
    .then(function() {
      store.dispatch({
        type: actions.ATTACHHMENT_UPLOAD_PROGRESS,
        attachmentProgress: 100,
        allFilesUploaded: true,
      });
      return urls;
    })
    .catch((error) => {
      throw error;
    });
}

function getMediaDownloadUrl(
  attachmentRef,
  file,
  metadata,
  activityId,
  urls,
  singleFile,
  firebase,
  branchPath,
  fileName,
  index
) {
  return new Promise(function(resolve, reject) {
    attachmentRef.put(file, metadata).on(
      Firebase.default.storage.TaskEvent.STATE_CHANGED,
      function(snapshot) {
        var progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        store.dispatch({
          type: actions.ATTACHHMENT_UPLOAD_PROGRESS,
          attachmentProgress: progress,
        });
      },
      function(error) {
        console.log("error", error);
        reject(error);
      },
      function() {
        var p2 = attachmentRef.getDownloadURL().then(function(downloadURL) {
          urls.push(downloadURL);
          resolve(p2);
        });
      }
    );
  });
}

function addNewActivityToActivities(
  activityDate,
  activityType,
  classNames,
  mediaPaths,
  meetingId,
  meetingTime,
  message,
  name,
  templateMessage,
  staffOnly,
  studentIds,
  activityId,
  foodSource,
  meal,
  quantity,
  foodMenu,
  pottyDestination,
  pottyType,
  napStart,
  firebase,
  bPath,
  meetingUrl,
  joinUrl,
  mediaType,
  thumbNail,
  youtubeUrlCode,
  repeatStartDate,
  repeatEndDate,
  includeSaturday,
  daysOfWeek,
  meetingCapacity,
  meetingBookable,
  meetingDuration,
  htmlText,
  enableParentComments,
  activityLabel,
  taggedTeachers
) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;

  let createdOn = {
    date: activityDate.getDate(),
    day: activityDate.getDay(),
    hours: activityDate.getHours(),
    minutes: activityDate.getMinutes(),
    month: activityDate.getMonth(),
    seconds: activityDate.getSeconds(),
    time: activityDate.getTime(),
    timezoneOffset: activityDate.getTimezoneOffset(),
    year: activityDate.getFullYear() - 1900,
  };

  var data = {
    activityType: activityType,
    approved: FilterPermission.checkIfPermission(
      PermissionStrings.POST_ACTIVITY_POST_WITHOUT_APPROVAL,
      firebase
    ),
    classNames: classNames,
    createdBy: firebase.teacher.name,
    date: createdOn,
    dateString: moment(activityDate).format("YYYY[-]MM[-]DD"),
    deleted: false,
    dirty: false,
    foodSource: foodSource,
    id: activityId,
    inverseDate: -activityDate.getTime(),
    likesCount: 0,
    longDate: 0,
    meal: meal,
    foodMenu: foodMenu,
    // mediaType: mediaType,
    // mediaPaths: mediaPaths,
    meetingId: meetingId ? meetingId : null,
    meetingTime: meetingTime ? meetingTime : null,
    inverseMeetingtTime: meetingTime ? -meetingTime : null,
    message: message ? message : null,
    name: name,
    napStart: napStart,
    pottyDestination: pottyDestination,
    pottyType: pottyType,
    published: false,
    quantity: quantity,
    seen: false,
    staffOnly: staffOnly,
    studentIds: studentIds,
    teacherId: firebase.teacher.id,
    teacherUid: firebase.teacher.uid ? firebase.teacher.uid : "",
    templateMessage: templateMessage,
    platform: "web",
    meetingStartUrl: meetingUrl,
    joinUrl: joinUrl,
    thumbNail: thumbNail,
    youtubeUrl: youtubeUrlCode,
    repeatStartDate: repeatStartDate,
    repeatEndDate: repeatEndDate,
    includeSaturday: includeSaturday,
    daysOfWeek: daysOfWeek,
    meetingCapacity: Number(meetingCapacity),
    meetingBookable: meetingBookable ? meetingBookable : false,
    meetingDuration: meetingDuration ? Number(meetingDuration) : null,
    htmlText: htmlText ? htmlText : null,
    enableParentComments: enableParentComments,
    labels: activityLabel,
    teacherBranchPath: firebase.branchPath,
    taggedTeachers:
      taggedTeachers && taggedTeachers.length > 0 ? taggedTeachers : null,
  };

  rsf.ref(branchPath + "/activities/" + activityId).update(data);

  if (
    activityType === "Virtual Class" &&
    repeatStartDate > 0 &&
    repeatEndDate > 0
  ) {
    rsf.ref(branchPath + "/repeatClasses/" + activityId).update(data);
  }
}

function addNewActivityToStaffActivities(
  activityDate,
  activityType,
  classNames,
  mediaPaths,
  meetingId,
  meetingTime,
  message,
  name,
  templateMessage,
  staffOnly,
  studentIds,
  activityId,
  foodSource,
  meal,
  quantity,
  foodMenu,
  pottyDestination,
  pottyType,
  napStart,
  firebase,
  bPath,
  meetingUrl,
  joinUrl,
  mediaType,
  thumbNail,
  youtubeUrlCode,
  repeatStartDate,
  repeatEndDate,
  includeSaturday,
  daysOfWeek,
  meetingCapacity,
  meetingBookable,
  meetingDuration,
  htmlText,
  enableParentComments,
  activityLabel
) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;

  let createdOn = {
    date: activityDate.getDate(),
    day: activityDate.getDay(),
    hours: activityDate.getHours(),
    minutes: activityDate.getMinutes(),
    month: activityDate.getMonth(),
    seconds: activityDate.getSeconds(),
    time: activityDate.getTime(),
    timezoneOffset: activityDate.getTimezoneOffset(),
    year: activityDate.getFullYear() - 1900,
  };

  var data = {
    activityType: activityType,
    approved: FilterPermission.checkIfPermission(
      PermissionStrings.POST_ACTIVITY_POST_WITHOUT_APPROVAL,
      firebase
    ),
    classNames: classNames,
    createdBy: firebase.teacher.name,
    date: createdOn,
    dateString: moment(activityDate).format("YYYY[-]MM[-]DD"),
    deleted: false,
    dirty: false,
    foodSource: foodSource,
    id: activityId,
    inverseDate: -activityDate.getTime(),
    likesCount: 0,
    longDate: 0,
    meal: meal,
    foodMenu: foodMenu,
    // mediaType: mediaType,
    // mediaPaths: mediaPaths,
    meetingId: meetingId ? meetingId : null,
    meetingTime: meetingTime ? meetingTime : null,
    inverseMeetingtTime: meetingTime ? -meetingTime : null,
    message: message ? message : null,
    name: name,
    napStart: napStart,
    pottyDestination: pottyDestination,
    pottyType: pottyType,
    published: false,
    quantity: quantity,
    seen: false,
    staffOnly: staffOnly,
    studentIds: studentIds,
    teacherId: firebase.teacher.id,
    teacherUid: firebase.teacher.uid ? firebase.teacher.uid : "",
    templateMessage: templateMessage,
    platform: "web",
    meetingStartUrl: meetingUrl,
    joinUrl: joinUrl,
    thumbNail: thumbNail,
    youtubeUrl: youtubeUrlCode,
    repeatStartDate: repeatStartDate,
    repeatEndDate: repeatEndDate,
    includeSaturday: includeSaturday,
    daysOfWeek: daysOfWeek,
    meetingCapacity: Number(meetingCapacity),
    meetingBookable: meetingBookable ? meetingBookable : false,
    meetingDuration: meetingDuration ? Number(meetingDuration) : null,
    htmlText: htmlText ? htmlText : null,
    enableParentComments: enableParentComments,
    labels: activityLabel,
    teacherBranchPath: firebase.branchPath,
  };

  rsf.ref(branchPath + "/staffActivities/" + activityId).update(data);
}

function getStudentTimeline(date, studentId, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var searchableDate = moment(new Date(date)).format("D[ ]MMMM[ ]YYYY");
  var timelineActivity = {};
  var timelineActivityPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/timeline/" + studentId + "/" + searchableDate)
      .once("value")
      .then(function(snapshot) {
        if (snapshot.val() !== null) {
          timelineActivity = snapshot.val();
        }
        resolve(timelineActivity);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return timelineActivityPromise;
}

function updateStudentTimeline(
  date,
  studentId,
  updatedTimelineObject,
  firebase,
  bPath
) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var searchableDate = moment(new Date(date)).format("D[ ]MMMM[ ]YYYY");
  rsf
    .ref(branchPath + "/timeline/" + studentId + "/" + searchableDate)
    .update(updatedTimelineObject);
}

function generateActivityTypeNode(activityType, studentId, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var key = rsf.ref(branchPath + "/" + activityType + "/" + studentId).push()
    .key;
  return key;
}

function updateActivityType(
  date,
  studentId,
  activityId,
  activityTypeNodeId,
  activityType,
  firebase,
  bPath,
  meetingTime,
  createdBy
) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var activityDate = new Date(date);

  let createdOn = {
    date: activityDate.getDate(),
    day: activityDate.getDay(),
    hours: activityDate.getHours(),
    minutes: activityDate.getMinutes(),
    month: activityDate.getMonth(),
    seconds: activityDate.getSeconds(),
    time: activityDate.getTime(),
    timezoneOffset: activityDate.getTimezoneOffset(),
    year: activityDate.getFullYear() - 1900,
  };

  rsf
    .ref(
      branchPath +
        "/" +
        activityType +
        "/" +
        studentId +
        "/" +
        activityTypeNodeId
    )
    .update({
      activityId: activityId,
      createdBy: createdBy ? createdBy : firebase.teacher.name,
      date: createdOn,
      inverseDate: -new Date(activityDate).getTime(),
      inverseMeetingId: meetingTime ? meetingTime : null,
      newDate: 0,
      id: activityTypeNodeId,
      platform: "web",
      updatedOn: moment().valueOf(),
    });
}

function getActivitySetting(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let settings = [];
  const myRef = rsf.ref(branchPath + "/schoolSettingActivity/");
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((item) => {
        // if (item.val() !== null && item.val().activityType !== "Nap (Time Mode)") {
        if (
          item.val() !== null &&
          item.val().activityType.toLowerCase() !== "attendance" &&
          item.val().activityType.toLowerCase() !== "kid attendance" &&
          item.val().activityType.toLowerCase() !== "staffattendance"
        ) {
          let actVal = item.val();
          if (actVal.activityType === "Virtual Class") {
            actVal.priority = 0;
          } else if (actVal.activityType === "Attendance") {
            actVal.priority = 1;
          } else if (actVal.activityType === "Kid Attendance") {
            actVal.priority = 1;
          } else if (actVal.activityType === "StaffAttendance") {
            actVal.priority = 2;
          } else if (actVal.activityType === "Notification") {
            actVal.priority = 3;
          } else if (actVal.activityType === "Photo") {
            actVal.priority = 4;
          } else if (actVal.activityType === "Video") {
            actVal.priority = 5;
          } else if (actVal.activityType === "Diary") {
            actVal.priority = 6;
          } else if (actVal.activityType === "Food") {
            actVal.priority = 7;
          } else if (actVal.activityType === "Nap") {
            actVal.priority = 8;
          } else if (actVal.activityType === "Potty") {
            actVal.priority = 9;
          } else if (actVal.activityType === "Medical Log") {
            actVal.priority = 10;
          } else if (actVal.activityType === "Today's Mood") {
            actVal.priority = 11;
          } else if (actVal.activityType === "Reminder") {
            actVal.priority = 12;
          } else if (actVal.activityType === "File Upload") {
            actVal.priority = 13;
          } else if (actVal.activityType === "Nap (Time Mode)") {
            actVal.priority = 14;
          } else if (actVal.activityType === "Incident") {
            actVal.priority = 15;
          } else {
            actVal.priority = 111;
          }
          settings.push(actVal);
        }
      });
      resolve(settings);
    });
  });
  return promise1;
}

function getCustomActivitySetting(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let settings = new Map();
  const myRef = rsf.ref(branchPath + "/customActivities/");
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((item) => {
        if (item.val() !== null) {
          // settings.push(item.val());
          settings.set(item.val().activityType, item.val());
        }
      });
      resolve(settings);
    });
  });
  return promise1;
}

function addUpdatedStudent(activityId, names, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  rsf.ref(branchPath + "/studentsUpdated/" + activityId).update({
    id: activityId,
    names: names,
  });
}

function generateNewActivityNodeByCenter(firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath;
  var key = rsf.ref(branchPath + "/activities").push().key;
  return key;
}

function getSeenCount(activityId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let myRef = rsf.ref(branchPath + "/viewCount/" + activityId);

  let seenCount = [];

  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      if (snap.val() !== null) {
        seenCount.push(snap.val());
      }
      resolve(seenCount);
    });
  });
  return promise1;
}

function getMeetingRef(startDate, endDate, firebase, onlyPresentDay) {
  let startTime = moment(startDate)
    .startOf("day")
    .valueOf();
  let endTime = moment(endDate)
    .endOf("day")
    .valueOf();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let myRef;
  if (onlyPresentDay) {
    myRef = rsf
      .ref(branchPath + "/meetingRef")
      .orderByChild("time")
      .startAt(startTime)
      .endAt(endTime);
  } else {
    myRef = rsf
      .ref(branchPath + "/meetingRef")
      .orderByChild("time")
      .startAt(startTime);
  }

  return eventChannel((emitter) => {
    myRef.on("value", (snap) => {
      var activities = [];
      snap.forEach((element) => {
        if (element.val() !== null) {
          activities.push(element.val());
        }
      });
      emitter(activities);
    });
    return () => {
      myRef.off();
      console.log("END");
    };
  });
}

function getActivityById(id, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var value = {};
  var studentPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/activities/" + id)
      .once("value")
      .then(function(snapshot) {
        if (snapshot.val() !== null && snapshot.val().deleted === false) {
          let classList =
            firebase && firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            if (classList.length > 0) {
              let activityClasses = snapshot.val().classNames;
              if (!activityClasses) {
                console.log("clasroom not populated", snapshot.val());
              }

              if (activityClasses) {
                for (let index in classList) {
                  let filteredVal = activityClasses.filter((f) => {
                    return f.toLowerCase() === classList[index].toLowerCase();
                  });

                  if (filteredVal && filteredVal.length > 0) {
                    if (snapshot.val().activityType === "Virtual Class") {
                      if (snapshot.val().meetingId) {
                        value = snapshot.val();
                      }
                    } else {
                      value = snapshot.val();
                    }
                    break;
                  }
                }
              }
            } else {
              if (snapshot.val().activityType === "Virtual Class") {
                if (snapshot.val().meetingId) {
                  value = snapshot.val();
                }
              } else {
                value = snapshot.val();
              }
            }
          } else {
            if (snapshot.val().activityType === "Virtual Class") {
              if (snapshot.val().meetingId) {
                value = snapshot.val();
              }
            } else {
              value = snapshot.val();
            }
          }
        }
        resolve(value);
      });
  });
  return studentPromise;
}

function getVirtualClassActivity(limit, firebase, date) {
  let startTime = moment(date)
    .startOf("day")
    .valueOf();

  let endTime = moment(date)
    .endOf("day")
    .valueOf();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var activityRef;

  activityRef = rsf
    .ref(branchPath + "/meetingRef")
    .orderByChild("time")
    .startAt(startTime)
    .endAt(endTime);

  return eventChannel((emitter) => {
    activityRef.on("value", (snap) => {
      var activities = [];
      snap.forEach((element) => {
        if (element.val() !== null) {
          activities.push(element.val());
        }
      });
      emitter(activities);
    });
    return () => {
      activityRef.off();
    };
  });
}

function getVirtualClassActivityRef(limit, firebase, date, bPath) {
  let startTime = moment(date)
    .startOf("day")
    .valueOf();

  let endTime = moment(date)
    .endOf("day")
    .valueOf();

  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;

  var activityRef;
  activityRef = rsf
    .ref(branchPath + "/meetingRef")
    .orderByChild("time")
    .startAt(startTime)
    .endAt(endTime);
  var meetingRefs = [];

  var promise1 = new Promise(function(resolve, reject) {
    activityRef.once("value").then(function(snap) {
      snap.forEach((element) => {
        if (element.val() !== null) {
          meetingRefs.push(element.val());
        }
      });
      resolve(meetingRefs);
    });
  });
  return promise1;
}

function editActivityObj(record, firebase, virtualClassUpdate) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/activities/" + record.id).update({
    htmlText: null,
    updatedBy: firebase.teacher.name,
    message: record.message,
    updatedOn: moment().valueOf(),
    platform: "web",
  });

  // if (virtualClassUpdate) {
  //   if (record.parentActivityId) {
  //     rsf.ref(branchPath + "/repeatClasses/" + record.parentActivityId).update(record);
  //   } else {
  //     rsf.ref(branchPath + "/repeatClasses/" + record.id).update(record);
  //   }
  // }
}

function updateMedia(mediaPaths, mediaType, activityId, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;

  let urlList = [];
  let tempMediaType = "none";
  mediaPaths.map((m) => {
    urlList.push(m.path);
    tempMediaType = m.type;
  });

  rsf.ref(branchPath + "/activities/" + activityId).update({
    mediaProfiles: mediaPaths,
    mediaPaths: urlList,
    platform: "web",
    mediaType: tempMediaType,
  });
}

function updateStaffMedia(mediaPaths, mediaType, activityId, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;

  let urlList = [];
  let tempMediaType = "none";
  mediaPaths.map((m) => {
    urlList.push(m.path);
    tempMediaType = m.type;
  });

  rsf.ref(branchPath + "/staffActivities/" + activityId).update({
    mediaProfiles: mediaPaths,
    mediaPaths: urlList,
    platform: "web",
    mediaType: tempMediaType,
  });
}

function requestThumbNail(file, activityId, type, firebase) {
  let endPointUrl = firebase.endPointUrl;
  let thumbEndPointUrl = endPointUrl + "generateThumbnail";
  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(thumbEndPointUrl)
      .send({
        schoolName: firebase.schoolName,
        branchPath: firebase.sbp,
        accountName: firebase.sbDbName,
        fileObject: file,
        type: type,
        activityId: activityId,
        timezone: localTimezone,
      })
      .set("accept", "json")

      .end((err, res) => {
        console.log("thumb res --->>>>>", res);
        resolve(res);
      });
  });
  return p1;
}

function getMedicalField(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/medfieldRef");
  var templates = [];

  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((element) => {
        if (element.val() !== null) {
          templates.push(element.val());
        }
      });
      resolve(templates);
    });
  });
  return promise1;
}

function getPottyField(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/pottyRef");
  var templates = [];

  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((element) => {
        if (element.val() !== null) {
          templates.push(element.val());
        }
      });
      resolve(templates);
    });
  });
  return promise1;
}

function createMeetingRefNode(firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var key = rsf.ref(branchPath + "/meetingRef").push().key;
  return key;
}

function updateMeetingRef(
  meetingTime,
  activityId,
  meetingRefNode,
  firebase,
  bPath
) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  rsf.ref(branchPath + "/meetingRef/" + meetingRefNode).set({
    activityId: activityId,
    inverseDate: -meetingTime,
    time: meetingTime,
  });
}

function addStudentSubmissionActivity(
  activityDate,
  activityType,
  classNames,
  mediaPaths,
  meetingId,
  meetingTime,
  message,
  name,
  templateMessage,
  staffOnly,
  studentIds,
  activityId,
  foodMenu,
  foodSource,
  meal,
  quantity,
  pottyDestination,
  pottyType,
  napStart,
  medicineGivenBy,
  thumbNail,
  youtubeUrl,
  enableParentComments,
  assignmentId,
  firebase
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let createdOn = {
    date: activityDate.getDate(),
    day: activityDate.getDay(),
    hours: activityDate.getHours(),
    minutes: activityDate.getMinutes(),
    month: activityDate.getMonth(),
    seconds: activityDate.getSeconds(),
    time: activityDate.getTime(),
    timezoneOffset: activityDate.getTimezoneOffset(),
    year: activityDate.getFullYear() - 1900,
  };

  var data = {
    activityType: activityType,
    approved: true,
    classNames: classNames,
    createdBy: medicineGivenBy,
    date: createdOn,
    dateString: moment(activityDate).format("YYYY[-]MM[-]DD"),
    deleted: false,
    dirty: false,
    enableParentComments: enableParentComments,
    foodMenu: foodMenu,
    foodSource: foodSource,
    id: activityId,
    inverseDate: -activityDate.getTime(),
    likesCount: 0,
    longDate: 0,
    meal: meal,
    mediaPaths: mediaPaths ? mediaPaths : null,
    meetingId: meetingId ? meetingId : null,
    meetingTime: meetingTime ? meetingTime : null,
    inverseMeetingtTime: meetingTime ? -meetingTime : null,
    message: message,
    name: name,
    napStart: napStart,
    pottyDestination: pottyDestination,
    pottyType: pottyType,
    published: false,
    quantity: quantity,
    seen: true,
    staffOnly: staffOnly,
    studentIds: studentIds,
    templateMessage: templateMessage,
    thumbNail: thumbNail,
    youtubeUrl: youtubeUrl,
    lessonId: assignmentId,
    platform: "web_parent_submission",
  };

  rsf.ref(branchPath + "/activities/" + activityId).update(data);
}

function updateActivityObj(obj, id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/activities/" + id).update(obj);
}

function getStudentSubmissionActivityById(activityId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emitter) => {
    rsf
      .ref(branchPath + "/activities/" + activityId)
      .on("value", (snapshot) => {
        var activity = {};
        if (snapshot.val() !== null && snapshot.val().deleted === false) {
          activity = snapshot.val();
        }
        emitter(activity);
      });
    return () => {
      rsf.ref(branchPath + "/activities/" + activityId).off();
      console.log("END");
    };
  });
}

function getFoodMenuToday(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var scheduleRef = rsf.ref(branchPath + "/schedule");

  let date = moment(new Date().getTime()).format("YYYY[-]MM[-]DD");

  var allSchedule = new Map();
  var promise1 = new Promise(function(resolve, reject) {
    scheduleRef.once("value").then(function(snap) {
      snap.forEach((item) => {
        allSchedule.set(item.key, []);
        let scheduleValue = [];
        item.forEach((ele) => {
          if (ele.key === date) {
            ele.forEach((newEle) => {
              if (newEle.val() !== null) {
                scheduleValue.push(newEle.val());
              }
            });
          }
        });
        allSchedule.set(item.key, scheduleValue);
      });
      resolve(allSchedule);
    });
  });
  return promise1;
}

function getMeetingRefById(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var activityRef = rsf
    .ref(branchPath + "/meetingRef")
    .orderByChild("activityId")
    .equalTo(id);
  let data = [];

  let promise = new Promise(function(resolve, reject) {
    activityRef.once("value").then((snapshot) => {
      snapshot.forEach((snap) => {
        if (snap.val() !== null) {
          let tempVal = snap.val();
          tempVal.id = snap.key;
          data.push(tempVal);
        }
      });
      resolve(data);
    });
  });
  return promise;
}

function deleteMeetingReference(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/meetingRef/" + id).set(null);
}

function createBookingRefNode(firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var key = rsf.ref(branchPath + "/bookingRef").push().key;
  return key;
}

function updateTrialBookingRef(
  meetingTime,
  activityId,
  meetingRefNode,
  classNames,
  firebase,
  bPath,
  capacity,
  studentIds,
  meetingDuration,
  createdBy,
  markUpdate
) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;

  rsf.ref(branchPath + "/bookingRef/" + meetingRefNode).set({
    activityId: activityId,
    inverseDate: -meetingTime,
    time: meetingTime,
    classNames: classNames,
    id: meetingRefNode,
    capacity: capacity ? Number(capacity) : 0,
    studentIds: studentIds && studentIds.length > 0 ? studentIds : [],
    meetingDuration: Number(meetingDuration),
    createdBy: createdBy ? createdBy : firebase.teacher.name,
    dateString: moment(meetingTime).format("DD[-]MM[-]YY"),
    updated: markUpdate ? markUpdate : null,
    parentActivityId: markUpdate ? activityId : null,
  });
}

function getBookingRefById(id, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  let bookingRefVal = {};
  let promise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/bookingRef/" + id)
      .once("value")
      .then((snap) => {
        if (snap.val() !== null) {
          bookingRefVal = snap.val();
        }
        resolve(bookingRefVal);
      });
  });
  return promise;
}

function getFilterNode(activityId, studentId, activityName, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let filterNode = {};
  var filterPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/" + activityName + "/" + studentId)
      .orderByChild("activityId")
      .equalTo(activityId)
      .once("value")
      .then(function(snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            filterNode = snap.val();
          }
        });
        resolve(filterNode);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return filterPromise;
}

function removeFilterNode(studentId, filterNodeId, activityName, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/" + activityName + "/" + studentId + "/" + filterNodeId)
    .set(null);
}

function getCheckInOutRefVal(activityName, date, studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let inVal = {};

  var filterPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/" + activityName + "/" + studentId)
      .orderByChild("dateText")
      .equalTo(date)
      .once("value")
      .then(function(snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            inVal = snap.val();
            inVal.id = snap.key;
          }
        });
        resolve(inVal);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return filterPromise;
}

function deleteInOutRef(activityName, record, studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/" + activityName + "/" + studentId + "/" + record.id)
    .set(null);
}

function resetStudentLastAtd(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/students/" + studentId).update({
    lastAttendanceDate: null,
    lastAttendanceCheckout: null,
    platform: "web",
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
  });
}

function getAttachmentMediaPath(
  pathToStorage,
  files,
  firebase,
  activityId,
  bPath,
  thumbnailSource,
  studentIds
) {
  var urls = [];
  var promiseList = [];
  let branchPath = bPath ? bPath : firebase.sbp;

  files.fileList.forEach(function(singleFile, index) {
    var originalFile = singleFile.originFileObj
      ? singleFile.originFileObj
      : singleFile;

    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    var metadata = {
      contentType: singleFile.type,
      cacheControl: "public,max-age=604800",
    };

    const storage = firebase.secondaryStorage;
    const storageRef = storage.ref();
    let fileName = new Date().getTime() + originalFile.name;
    const attachmentRef = storageRef.child(
      getPathToStorage(singleFile.type, firebase) + fileName
    );

    let compressedFilePromise = imageCompression(originalFile, options);
    var p2 = compressedFilePromise
      .then((fileRes) => {
        let file = fileRes;
        return getAttachmentDownloadUrl(
          attachmentRef,
          file,
          metadata,
          activityId,
          urls,
          singleFile,
          firebase,
          branchPath,
          fileName,
          thumbnailSource,
          index,
          studentIds
        );
      })
      .catch((err) => {
        return getAttachmentDownloadUrl(
          attachmentRef,
          originalFile,
          metadata,
          activityId,
          urls,
          singleFile,
          firebase,
          branchPath,
          fileName,
          thumbnailSource,
          index,
          studentIds
        );
      });

    promiseList.push(p2);
  });

  return Promise.all(promiseList)
    .then(function() {
      store.dispatch({
        type: actions.ATTACHHMENT_UPLOAD_PROGRESS,
        attachmentProgress: 100,
        allFilesUploaded: true,
      });
      return urls;
    })
    .catch((error) => {
      throw error;
    });
}

function getAttachmentDownloadUrl(
  attachmentRef,
  file,
  metadata,
  activityId,
  urls,
  singleFile,
  firebase,
  branchPath,
  fileName,
  thumbnailSource,
  index,
  studentIds
) {
  return new Promise(function(resolve, reject) {
    let mediaType;
    attachmentRef.put(file, metadata).on(
      Firebase.default.storage.TaskEvent.STATE_CHANGED,
      function(snapshot) {
        // console.log("snapshot -------", snapshot);
        if (snapshot.metadata.contentType) {
          switch (snapshot.metadata.contentType) {
            case "application/octet-stream":
              mediaType = "file";
              break;
            case "text/csv":
              mediaType = "file";
              break;
            case "image/jpeg":
              mediaType = "image";
              break;
            case "image/png":
              mediaType = "image";
              break;
            case "image/bmp":
              mediaType = "image";
              break;
            case "image/gif":
              mediaType = "image";
              break;
            case "image/webp":
              mediaType = "image";
              break;
            case "video/mp4":
              mediaType = "video";
              break;
            case "video/3gpp":
              mediaType = "video";
              break;
            case "video/mpeg":
              mediaType = "video";
              break;
            case "video/quicktime":
              mediaType = "video";
              break;
          }
        }

        if (
          snapshot.metadata.contentType &&
          snapshot.metadata.contentType.includes("application")
        ) {
          mediaType = "file";
        }

        var progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        store.dispatch({
          type: actions.ATTACHHMENT_UPLOAD_PROGRESS,
          attachmentProgress: progress,
        });
      },
      function(error) {
        console.log("error", error);
        reject(error);
      },
      function() {
        var p2 = attachmentRef.getDownloadURL().then(function(downloadURL) {
          urls.push({
            path: downloadURL,
            type: mediaType,
            name: getPathToStorage(singleFile.type, firebase) + fileName,
          });

          resolve(p2);
        });
      }
    );
  });
}

function generateThumbnail(
  mediaPaths,
  activityId,
  thumbnailSource,
  studentIds,
  firebase
) {
  if (activityId) {
    for (let index in mediaPaths) {
      let profile = mediaPaths[index];
      if (profile.type && profile.type.toLowerCase() === "video") {
        var thumbObj = {};
        thumbObj.name = profile.name;
        thumbObj.contentType = "video/mp4";
        thumbObj.bucket = firebase.schoolConfig.storageBucket;
        thumbObj.position = index;
        thumbObj.downloadUrl = profile.path;
        thumbObj.studentIds = studentIds ? studentIds : undefined;
        if (thumbnailSource && thumbnailSource.toLowerCase() === "event") {
          thumbObj.position = undefined;
          thumbObj.rootId = activityId;
        }
        requestThumbNail(
          thumbObj,
          activityId,
          thumbnailSource ? thumbnailSource : "activity",
          firebase
        );
      }
    }
  }
}

function getMeetingRefByMeetingTime(time, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var activityRef = rsf
    .ref(branchPath + "/meetingRef")
    .orderByChild("time")
    .equalTo(time);
  let data = [];

  let promise = new Promise(function(resolve, reject) {
    activityRef.once("value").then((snapshot) => {
      snapshot.forEach((snap) => {
        if (snap.val() !== null) {
          let tempVal = snap.val();
          tempVal.id = snap.key;
          data.push(tempVal);
        }
      });
      resolve(data);
    });
  });
  return promise;
}

function updatedBookingRef(obj, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/bookingRef/" + obj.id).update(obj);
}

function generateStudentBookingNode(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/studentBookingRef").push().key;
  return key;
}

function updatedStudentBookingRef(obj, studentId, nodeId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/studentBookingRef/" + studentId + "/" + nodeId)
    .update(obj);
}

function getStudentBookingRef(studentId, bookingRefId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let data = {};
  let promise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/studentBookingRef/" + studentId)
      .once("value")
      .then((snapshot) => {
        snapshot.forEach((snap) => {
          if (snap.val() !== null && snap.val().bookingRefId === bookingRefId) {
            data = snap.val();
          }
        });
        resolve(data);
      });
  });
  return promise;
}

function removeStudentBookingRef(studentId, nodeId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/studentBookingRef/" + studentId + "/" + nodeId)
    .set(null);
}

function getStudentBookingReferenceWithTime(studentId, date, firebase) {
  let startTime = moment(date)
    .startOf("day")
    .valueOf();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let bookingRef = rsf
    .ref(branchPath + "/studentBookingRef/" + studentId)
    .orderByChild("meetingTime");
  // .startAt(startTime);

  let data = [];

  let promise = new Promise(function(resolve, reject) {
    bookingRef.once("value").then((snapshot) => {
      snapshot.forEach((snap) => {
        if (snap.val() !== null && snap.val().bookingRefId) {
          data.push(snap.val());
        }
      });
      resolve(data);
    });
  });
  return promise;
}

function getCommentSettings(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let commentSettingRef = rsf.ref(branchPath + "/commentSettings");

  let commentMap = new Map();

  let promise = new Promise(function(resolve, reject) {
    commentSettingRef.once("value").then((snap) => {
      snap.forEach((ele) => {
        if (ele.val() !== null) {
          commentMap.set(ele.val().activityType, ele.val());
        }
      });
      resolve(commentMap);
    });
  });
  return promise;
}

function getRepeatClass(date, firebase) {
  let time = moment(date)
    .startOf("day")
    .valueOf();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let data = [];

  let repeatClassRef = rsf.ref(branchPath + "/repeatClasses");
  let promise = new Promise(function(resolve, reject) {
    repeatClassRef.once("value").then((snapshot) => {
      snapshot.forEach((snap) => {
        if (
          snap.val() !== null &&
          moment(time).isBetween(
            moment(snap.val().repeatStartDate),
            moment(snap.val().repeatEndDate),
            "[]"
          )
        ) {
          data.push(snap.val());
        }
      });
      resolve(data);
    });
  });
  return promise;
}

function getBookingRefByTime(activityId, meetingTime, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;

  let data = {};
  let promise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/bookingRef")
      .orderByChild("activityId")
      .equalTo(activityId)
      .once("value")
      .then((snapshot) => {
        snapshot.forEach((snap) => {
          if (snap.val() !== null && snap.val().time === meetingTime) {
            data = snap.val();
          }
        });
        resolve(data);
      });
  });
  return promise;
}

function deleteBookingRef(booking, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/bookingRef/" + booking.id).set(null);
}

function getStudentFilterNodeByActivityId(
  activityType,
  studentId,
  activityId,
  firebase
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let data = [];
  let promise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/" + activityType + "/" + studentId)
      .orderByChild("activityId")
      .equalTo(activityId)
      .once("value")
      .then((snapshot) => {
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            let tempVal = snap.val();
            tempVal.id = snap.key;
            data.push(tempVal);
          }
        });
        resolve(data);
      });
  });
  return promise;
}

function getRepeatClassById(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let data = {};
  let promise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/repeatClasses/" + id)
      .once("value")
      .then((snapshot) => {
        if (snapshot.val() !== null && !snapshot.val().deleted) {
          data = snapshot.val();
        }
        resolve(data);
      });
  });
  return promise;
}

function* updateIndRepeatClass(obj, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/repeatClasses/" + obj.id).update({
    htmlText: null,
    updatedBy: firebase.teacher.name,
    message: obj.message,
    updatedOn: moment().valueOf(),
    platform: "web",
  });
}

function getAllRepeatClasses(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let repeatedClass = [];
  let promise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/repeatClasses")
      .once("value")
      .then((snapshot) => {
        snapshot.forEach((snap) => {
          if (
            snap.val() !== null &&
            !snap.val().deleted &&
            snap.val().repeatStartDate > 0 &&
            snap.val().repeatEndDate > 0 &&
            moment(snap.val().repeatEndDate).isSameOrAfter(moment(), "day")
          ) {
            repeatedClass.push(snap.val());
          }
        });
        resolve(repeatedClass);
      });
  });
  return promise;
}

function removeCompleteStudentBookingRef(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/studentBookingRef/" + studentId).set(null);
}

function getStudentBookingById(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let data = [];
  let promise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/studentBookingRef/" + studentId)
      .once("value")
      .then((snapshot) => {
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            data.push(snap.val());
          }
        });
        resolve(data);
      });
  });
  return promise;
}

function getBookingRefByActivityId(activityId, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;

  let data = [];
  let promise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/bookingRef")
      .orderByChild("activityId")
      .equalTo(activityId)
      .once("value")
      .then((snapshot) => {
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            data.push(snap.val());
          }
        });
        resolve(data);
      });
  });
  return promise;
}

function getCustomLabels(firebase, category) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  return eventChannel((emitter) => {
    rsf.ref(branchPath + "/labelRef/" + category).on("value", (snapshot) => {
      let labelsVal = [];
      if (snapshot.val() !== null) {
        labelsVal = snapshot.val();
      }
      emitter(labelsVal);
    });
    return () => {
      rsf.ref(branchPath + "/labelRef/" + category).off();
      console.log("END");
    };
  });
}

function updateCustomLabels(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/labelRef/" + record.name)
    .set(record.labels ? record.labels : []);
}

function getCustomLabelByCategory(category, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let data = [];

  let promise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/labelRef/" + category)
      .once("value")
      .then((snapshot) => {
        if (snapshot.val() !== null) {
          data = snapshot.val();
        }
        resolve(data);
      });
  });
  return promise;
}

function getAllRepeatClass(date, firebase) {
  let time = moment(date)
    .startOf("day")
    .valueOf();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let data = [];

  let repeatClassRef = rsf
    .ref(branchPath + "/repeatClasses")
    .orderByChild("deleted")
    .equalTo(false);
  let promise = new Promise(function(resolve, reject) {
    repeatClassRef.once("value").then((snapshot) => {
      snapshot.forEach((snap) => {
        if (
          snap.val() !== null &&
          snap.val().id &&
          snap.val().studentIds &&
          !snap.val().meetingBookable &&
          moment(snap.val().repeatStartDate).isAfter(moment(time), "day")
        ) {
          data.push(snap.val());
        }
      });
      resolve(data);
    });
  });
  return promise;
}

function createStudentMediaNode(firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var key = rsf.ref(branchPath + "/photos").push().key;
  return key;
}

function updateStudentMediaAttachment(
  photoObj,
  nodeId,
  studentId,
  firebase,
  bPath
) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  rsf.ref(branchPath + "/photos/" + studentId + "/" + nodeId).update(photoObj);
}

function getStaffActivityById(id, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var value = {};
  var studentPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/staffActivities/" + id)
      .once("value")
      .then(function(snapshot) {
        if (snapshot.val() !== null && snapshot.val().deleted === false) {
          value = snapshot.val();
        }
        resolve(value);
      });
  });
  return studentPromise;
}

export const ActivityApi = {
  getStudentUpdated,
  approveSelectedActivity,
  updateActivity,
  deleteActivity,
  getActivityTemplate,
  generateNewActivityNode,
  generateNewStaffActivityNode,
  getMediaPath,
  addNewActivityToActivities,
  addNewActivityToStaffActivities,
  getStudentTimeline,
  updateStudentTimeline,
  generateActivityTypeNode,
  updateActivityType,
  getActivitySetting,
  addUpdatedStudent,
  generateNewActivityNodeByCenter,
  getSeenCount,
  getMeetingRef,
  getActivityById,
  getVirtualClassActivity,
  editActivityObj,
  updateMedia,
  updateStaffMedia,
  getMedicalField,
  getPottyField,
  createMeetingRefNode,
  updateMeetingRef,
  addStudentSubmissionActivity,
  updateActivityObj,
  getStudentSubmissionActivityById,
  getFoodMenuToday,
  getVirtualClassActivityRef,
  getMeetingRefById,
  deleteMeetingReference,
  createBookingRefNode,
  updateTrialBookingRef,
  getFilterNode,
  removeFilterNode,
  getCheckInOutRefVal,
  deleteInOutRef,
  resetStudentLastAtd,
  getAttachmentMediaPath,
  getMeetingRefByMeetingTime,
  getBookingRefById,
  updatedBookingRef,
  generateStudentBookingNode,
  updatedStudentBookingRef,
  getStudentBookingRef,
  removeStudentBookingRef,
  getStudentBookingReferenceWithTime,
  getCommentSettings,
  getRepeatClass,
  getBookingRefByTime,
  deleteBookingRef,
  getStudentFilterNodeByActivityId,
  getRepeatClassById,
  updateIndRepeatClass,
  getAllRepeatClasses,
  removeCompleteStudentBookingRef,
  getStudentBookingById,
  getBookingRefByActivityId,
  getCustomLabels,
  updateCustomLabels,
  getCustomLabelByCategory,
  getAllRepeatClass,
  getCustomActivitySetting,
  createStudentMediaNode,
  updateStudentMediaAttachment,
  generateThumbnail,
  getStaffActivityById,
};
