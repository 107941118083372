// import clone from 'clone';
import actions from './actions';

const initState = {
  foods: [],
  initialFoods: false,
  editableFood: {},
  isNewFood: false,
  enableEditView: false,
  isModalVisible: false,
  isUpdateModalVisible: false,
  autoCompleteData: [],
  foodMenuLayout: '',
  error: false,
  errorInfo: '',
  isLoading: false
};

export default function menuReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.LIST_FOODS:
      return {
        ...state,
        isLoading: true
      };
    case actions.LIST_FOOD:
      return {
        ...state,
        foods: action.foods,
        initialFoods: true,
        enableEditView: false,
        foodMenuLayout: action.foodMenuLayout,
        isLoading: false
      };
    case actions.ADD_FOOD:
      return {
        ...state,
        isLoading: true
      };
      case actions.ADD_FOOD_SUCCESSFUL:
      return {
        ...state,
        initialFoods: true,
        isLoading: false
      };

    case actions.UPDATE_FOOD:
      return {
        ...state,
        isLoading: true
      };
      case actions.UPDATE_FOOD_SUCCESSFUL:
      return {
        ...state,
        initialFoods: true,
        isLoading: false
      };
    case actions.ADD_CSV_MENU:
      return {
        ...state,
        initialFoods: true
      };
      case actions.FOOD_MENU_REQUEST_FAILED:
      return {
        ...state,
        error: true,
        errorInfo: action.errorInfo
      };
    default:
      return state;
  }
}
