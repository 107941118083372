const actions = {
    GET_STUDENT_TIMELINE: 'GET_STUDENT_TIMELINE',
    GET_STUDENT_TIMELINE_SUCCESSFUL: 'GET_STUDENT_TIMELINE_SUCCESSFUL',
    GET_STUDENT_VIRTUAL_CLASS: 'GET_STUDENT_VIRTUAL_CLASS',
    GET_STUDENT_VIRTUAL_CLASS_SUCCESSFUL: 'GET_STUDENT_VIRTUAL_CLASS_SUCCESSFUL',

    GET_STUDENT_DETAIL: "GET_STUDENT_DETAIL",
    GET_STUDENT_DETAIL_SUCCESS: "GET_STUDENT_DETAIL_SUCCESS",

    GET_PREVIOUS_TIMELINE: "GET_PREVIOUS_TIMELINE",
    GET_PREVIOUS_TIMELINE_SUCCESS: "GET_PREVIOUS_TIMELINE_SUCCESS",

    GET_ACTIVITY_COMMENTS_SUCCESS: "GET_ACTIVITY_COMMENTS_SUCCESS",

    GET_STUDENT_TIMELINE_BY_ID: 'GET_STUDENT_TIMELINE_BY_ID',

    GET_BOOKABLE_MEETINGS: "GET_BOOKABLE_MEETINGS",
    GET_BOOKABLE_MEETINGS_SUCCESS: "GET_BOOKABLE_MEETINGS_SUCCESS",

    UPDATE_BOOKING_STATUS: "UPDATE_BOOKING_STATUS",
    UPDATE_BOOKING_STATUS_SUCCESS: "UPDATE_BOOKING_STATUS_SUCCESS",

    RESET_STUDENT_TIMELINE_OPERATION: "RESET_STUDENT_TIMELINE_OPERATION",

    UPDATE_JOINED_MEETING: "UPDATE_JOINED_MEETING",

    GET_STUDENT_VIRTUAL_CLASS_LIST: "GET_STUDENT_VIRTUAL_CLASS_LIST",
    GET_STUDENT_VIRTUAL_CLASS_LIST_SUCCESS: "GET_STUDENT_VIRTUAL_CLASS_LIST_SUCCESS",

    GET_SCHOOL_SETTING_ACTIVITIES: "GET_SCHOOL_SETTING_ACTIVITIES",
    GET_SCHOOL_SETTING_ACTIVITIES_SUCCESS: "GET_SCHOOL_SETTING_ACTIVITIES_SUCCESS",

    GET_ONLINE_CLASS_REMINDER: "GET_ONLINE_CLASS_REMINDER",
    GET_ONLINE_CLASS_REMINDER_SUCCESS: "GET_ONLINE_CLASS_REMINDER_SUCCESS",

    TIMELINE_REQUEST_FAILED: "TIMELINE_REQUEST_FAILED",

    UPLOAD_PROFILE_PIC: "UPLOAD_PROFILE_PIC",
    UPLOAD_PROFILE_PIC_SUCCESS: "UPLOAD_PROFILE_PIC_SUCCESS",

    GET_STUDENT_HOLIDAYS: "GET_STUDENT_HOLIDAYS",
    GET_STUDENT_HOLIDAYS_SUCCESS: "GET_STUDENT_HOLIDAYS_SUCCESS",

    getStudentTimeline: (studentId, firebase, date, prevTimeline, activityType, toggleVal, limit) => ({
        type: actions.GET_STUDENT_TIMELINE,
        studentId,
        firebase,
        date,
        prevTimeline,
        activityType,
        toggleVal,
        limit
    }),

    getSchoolSettingActivities: (firebase) => ({
        type: actions.GET_SCHOOL_SETTING_ACTIVITIES,
        firebase
    }),

    upcomingStudentVirtualClass: (studentId, firebase) => ({
        type: actions.GET_STUDENT_VIRTUAL_CLASS,
        studentId,
        firebase
    }),

    getStudentDetail: (studentId, studentObj, firebase, operationType) => ({
        type: actions.GET_STUDENT_DETAIL,
        studentId,
        studentObj,
        firebase,
        operationType
    }),

    getStudentActivityById: (id, firebase) => ({
        type: actions.GET_STUDENT_TIMELINE_BY_ID,
        id,
        firebase
    }),

    getBookableMeetings: (startDate, endDate, student, firebase, fetchType, onlyBookingRef, selectedTimezone) => ({
        type: actions.GET_BOOKABLE_MEETINGS,
        startDate,
        endDate,
        student,
        firebase,
        fetchType,
        onlyBookingRef,
        selectedTimezone
    }),

    updateBooking: (status, item, firebase, selectedTimezone) => ({
        type: actions.UPDATE_BOOKING_STATUS,
        status,
        item,
        firebase,
        selectedTimezone
    }),

    resetTimelineOperation: () => ({
        type: actions.RESET_STUDENT_TIMELINE_OPERATION
    }),

    updatedJoinedMeeting: (activity, firebase) => ({
        type: actions.UPDATE_JOINED_MEETING,
        activity,
        firebase
    }),

    getStudentVirtualClasses: (studentId, date, firebase) => ({
        type: actions.GET_STUDENT_VIRTUAL_CLASS_LIST,
        studentId,
        date,
        firebase
    }),

    getOnlineClassReminder: (studentId, startDate, firebase) => ({
        type: actions.GET_ONLINE_CLASS_REMINDER,
        studentId,
        startDate,
        firebase
    }),

    uploadStudentProfilePicture: (info, student, firebase) => ({
        type: actions.UPLOAD_PROFILE_PIC,
        info,
        student,
        firebase
    }),

    getAllHolidays: (firebase) => ({
        type: actions.GET_STUDENT_HOLIDAYS,
        firebase
    })

}
export default actions;