import { all, put, call, takeLatest, fork, take } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore";
import { lessonAssignmentApi } from "../../firestore-api/lessonAssignment";
import { LearningApi } from "../../firestore-api/learning";
import { ActivityApi } from "../../firestore-api/activity";
import { NotificationApi } from "../../firestore-api/notification";
import { StudentApi } from "../../firestore-api/student";
import { TimelineApi } from "../../firestore-api/timeline";
import bugsnagClient from "@bugsnag/js";
import moment from "moment-timezone";

function* fetchStudentLessonStats({ firebase, studentId, startDate, endDate }) {
  yield fork(initLessonStats, firebase, studentId, startDate, endDate);
  yield fork(getLessonStatsData, firebase, studentId);
}

function* initLessonStats(firebase, studentId, startDate, endDate) {
  try {
    yield call(
      lessonAssignmentApi.getStudentLessonStats,
      firebase,
      studentId,
      startDate,
      endDate
    );
  } catch (err) {
    console.log("failed to get student lesson stats", err);
    bugsnagClient.notify(
      "Failed to initialise lesson stats ----->>>" + err.message
        ? err.message
        : err
    );
    yield put({
      type: actions.STUDENT_LESSON_STAT_REQUEST_FAIL,
      errorMessage: "Failed to initialise lesson stats",
    });
  }
}

function* getLessonStatsData(firebase, studentId) {
  try {
    const chan = yield call(
      lessonAssignmentApi.getStudentLessonStatsData,
      firebase,
      studentId
    );
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_STUDENT_LESSON_STATS_SUCCESSFUL,
        studentLessonStats: data,
        statsChan: chan,
      });
    }
  } finally {
    console.log("terminating parent dashboard stats");
  }
}

function* fetchLearningExp({
  firebase,
  studentId,
  limit,
  startDate,
  endDate,
  selectedDropdown,
  allLessonList,
  schoolAssignmentRef,
  fetchSubmissionActivity,
  checkPlanSubmission,
  assignmentId,
}) {
  try {
    let chan;
    if (assignmentId) {
      chan = yield call(
        lessonAssignmentApi.fetchStudentAssignmentById,
        assignmentId,
        studentId,
        firebase
      );
    } else {
      chan = yield call(
        lessonAssignmentApi.getAssignmentRef,
        firebase,
        studentId,
        limit,
        startDate,
        endDate,
        selectedDropdown
      );
    }
    while (true) {
      /**student assignments */
      let data = yield take(chan);
      console.log("studentAssignment", data);

      /**call to get school assignment for above student assigments */
      let schoolAssignments = [];
      let schoolAssignmentTask = [];

      var lessons = [];
      let lessonTaskMap = new Map();

      let studentAssignmentMap = new Map();
      let schoolAssignmentMap = new Map();

      for (let i in data) {
        studentAssignmentMap.set(data[i].assignmentId, data[i]);
        let preVal = [];
        if (schoolAssignmentRef) {
          let preSchoolAssignments = schoolAssignmentRef;
          preVal = preSchoolAssignments.filter((a) => {
            return a.id === data[i].assignmentId;
          });
        }

        if (preVal && preVal.length > 0 && preVal[0].id) {
          schoolAssignments.push(preVal[0]);
          schoolAssignmentMap.set(preVal[0].id, preVal[0]);
        } else {
          let scaTask = call(
            lessonAssignmentApi.getUnfilteredSchoolAssignment,
            data[i].assignmentId,
            firebase
          );
          schoolAssignmentTask.push(scaTask);
        }

        if (data[i].lessonId && checkPlanSubmission) {
          let taskL = call(
            LearningApi.getLessonById,
            data[i].lessonId,
            firebase
          );
          lessonTaskMap.set(data[i].assignmentId, taskL);
        }
      }

      let newSchoolAssignmentVal = yield all([schoolAssignmentTask]);
      for (let i in newSchoolAssignmentVal[0]) {
        let val = newSchoolAssignmentVal[0][i];
        if (val && val.lessonId && !val.delete) {
          schoolAssignments.push(val);
          schoolAssignmentMap.set(val.id, val);
        } else {
          if (val && val.lessonId && val.delete) {
            schoolAssignmentMap.set(val.id, val);
          }
        }
      }

      for (let [key, val] of studentAssignmentMap) {
        if (!schoolAssignmentMap.has(key)) {
          let tempSchoolAssignment = {
            allowDownload: "Yes",
            //   classNames: classNames,
            //   classroom: selectedClass,
            delete: false,
            dueDate: 0,
            extraNote: null,
            id: key,
            inverseDate: -new Date().getTime(),
            lessonId: val.lessonId,
            shareOn: val.sharedOn,
            sharedBy: null,
            studentIds: [],
            submissionRequired: "Yes",
            type: val.type,
          };
          schoolAssignments.push(tempSchoolAssignment);
        }
      }

      console.log("schoolAssignment", schoolAssignments);

      /**fetch lessons in the school assignments */
      // var lessons = [];
      // let lessonTaskMap = new Map();
      let preLessons = allLessonList
        ? JSON.parse(JSON.stringify(allLessonList))
        : undefined;
      for (let index in schoolAssignments) {
        let preLessonVal = [];
        if (preLessons) {
          preLessonVal = preLessons.filter((a) => {
            return a.assignmentId === schoolAssignments[index].id;
          });
        }

        if (preLessonVal && preLessonVal.length > 0 && preLessonVal[0].id) {
          lessons.push(preLessonVal[0]);
        } else {
          let taskL = call(
            LearningApi.getLessonById,
            schoolAssignments[index].lessonId,
            firebase
          );
          lessonTaskMap.set(schoolAssignments[index].id, taskL);
        }
      }

      for (let [key, value] of lessonTaskMap) {
        let newLessonVal = yield all([value]);
        let lesson = newLessonVal[0];
        if (lesson && lesson.id) {
          lesson.assignmentId = key;
          lessons.push(lesson);

          yield put({
            type: actions.GET_LEARNING_EXP_SUCCESS,
            assignmentRef: data,
            schoolAssignmentRef: schoolAssignments,
            lessons: lessons,
            lessonSubmission: new Map(),
            learningChannel: chan,
            showLoader:
              allLessonList && allLessonList.length !== lessons.length
                ? true
                : false,
          });
        }
      }

      /**fetch student submission */
      let lessonSubmission = new Map();
      let lessonSubmissionTaskMap = new Map();

      if (fetchSubmissionActivity) {
        for (let index in data) {
          let taskSubmission = call(
            lessonAssignmentApi.getLessonSubmissionByStudentId,
            data[index].assignmentId,
            studentId,
            firebase
          );
          lessonSubmissionTaskMap.set(data[index].assignmentId, taskSubmission);
        }
      } else {
        for (let index in schoolAssignments) {
          let taskSubmission = call(
            lessonAssignmentApi.getLessonSubmissionByStudentId,
            schoolAssignments[index].id,
            studentId,
            firebase
          );
          lessonSubmissionTaskMap.set(
            schoolAssignments[index].id,
            taskSubmission
          );
        }
      }

      yield put({
        type: actions.GET_LEARNING_EXP_SUCCESS,
        assignmentRef: data,
        schoolAssignmentRef: schoolAssignments,
        lessons: lessons,
        lessonSubmission: lessonSubmissionTaskMap,
        learningChannel: chan,
        showLoader:
          allLessonList && allLessonList.length !== lessons.length
            ? true
            : false,
      });

      let submissionActivityMap = new Map();
      let submissionActivityMapTask = new Map();

      for (let [key, value] of lessonSubmissionTaskMap) {
        let newSubmissionVal = yield all([value]);
        let lessonSub = newSubmissionVal[0];
        if (lessonSub) {
          if (lessonSub.activityId) {
            lessonSub.assignmentId = key;
            lessonSubmission.set(key, lessonSub);

            if (fetchSubmissionActivity) {
              let activityTask = call(
                TimelineApi.fetchAltTimelineActivities,
                lessonSub.activityId,
                firebase
              );
              submissionActivityMapTask.set(lessonSub.activityId, activityTask);
            }
          }
        }
      }

      if (fetchSubmissionActivity) {
        for (let [key, value] of submissionActivityMapTask) {
          let newSubmissionActivityVal = yield all([value]);
          let activity = newSubmissionActivityVal[0];
          if (activity && activity.id) {
            submissionActivityMap.set(key, activity);
          }
        }
      }

      // console.log("submissionActivityMap", submissionActivityMap);
      console.log("lessons", lessons);

      yield put({
        type: actions.GET_LEARNING_EXP_SUCCESS,
        assignmentRef: data,
        schoolAssignmentRef: schoolAssignments,
        lessons: lessons,
        lessonSubmission: lessonSubmission,
        learningChannel: chan,
        showLoader:
          allLessonList && allLessonList.length !== lessons.length
            ? true
            : false,
        submissionActivityMap: submissionActivityMap,
      });
    }
  } finally {
    console.log("terminating student lesson experience ref");
  }
}

function* fetchAssignmentComment({ assignments, firebase }) {
  try {
    let activities = assignments;
    let commentsCount = new Map();
    let commentTask = new Map();

    for (const item in activities) {
      let task = call(
        lessonAssignmentApi.getCommentsCount,
        activities[item].assignmentId,
        firebase
      );
      commentTask.set(activities[item].assignmentId, task);
    }

    for (let [key, value] of commentTask) {
      let newCommentVal = yield all([value]);
      let commentCountObj = newCommentVal[0];
      if (commentCountObj) {
        commentsCount.set(key, commentCountObj);
      }
    }
    yield put({
      type: actions.GET_STUDENT_ASSIGNMENT_COMMENT_SUCCESS,
      lessonComment: commentsCount,
    });
  } catch (err) {
    console.log("failed to fetch student assignments comment", err);
    bugsnagClient.notify(
      "failed to fetch student assignments comment ----->>>" + err.message
        ? err.message
        : err
    );
  }
}

function* submitStudentAssignment({
  note,
  attachedFile,
  mediaType,
  selectedLesson,
  firebase,
  disableNotification,
}) {
  try {
    let activityNodeId = yield call(
      ActivityApi.generateNewActivityNode,
      firebase
    );

    let lessonId = selectedLesson.lesson.id;
    let lessonName = selectedLesson.lesson.name;
    let assignmentId = selectedLesson.lesson.assignmentId;
    let studentId = selectedLesson.studentId;

    let studentDetail = {};
    if (
      selectedLesson.studentProfile &&
      selectedLesson.studentProfile.id &&
      selectedLesson.studentProfile.name
    ) {
      studentDetail = selectedLesson.studentProfile;
    } else {
      let student = yield call(StudentApi.getStudentById, studentId, firebase);
      if (student) {
        studentDetail = student;
      }
    }

    let studentClass = studentDetail.classroomName;

    let lessonSubmission = selectedLesson.lessonSubmission;
    let lessonType = selectedLesson.lessonType;

    let parentName =
      firebase.user && firebase.user.id === studentDetail.fatherProfileId
        ? studentDetail.fatherName
        : studentDetail.motherName;
    let idArr = [];
    idArr.push(studentId);
    let activityDate = new Date();

    yield call(
      lessonAssignmentApi.createStudentSubmission,
      assignmentId,
      activityNodeId,
      studentId,
      activityDate,
      parentName,
      firebase
    );

    let parentId = firebase.user.id;
    // let relation = "";
    // if (studentDetail && studentDetail.name && parentId) {
    //     relation = studentDetail.fatherProfileId && studentDetail.fatherProfileId === parentId ? "Father" : "Mother";
    // }

    let activityType = "Assignment";
    let classNames = [studentClass];
    let mediaPaths = [];
    let meetingId;
    let meetingTime;
    let message = note ? note : null;
    let name = "Assignment";
    //check fro lesson type
    let templateMessage =
      "\n Lesson Name" +
      "\n" +
      "\n" +
      lessonName +
      (lessonType ? " was marked done " : "");
    let staffOnly = false;
    let studentIds = idArr;
    let activityId = activityNodeId;
    let foodMenu = null;
    let foodSource = null;
    let meal = null;
    let quantity = null;
    let pottyDestination = null;
    let pottyType = null;
    let napStart = null;
    let createdBy = parentName ? parentName : "Parent";
    let thumbNail = null;
    let youtubeUrlCode = null;
    let enableParentComments = true;

    yield call(
      ActivityApi.addStudentSubmissionActivity,
      activityDate,
      activityType,
      classNames,
      mediaPaths,
      meetingId,
      meetingTime,
      message,
      name,
      templateMessage,
      staffOnly,
      studentIds,
      activityId,
      foodMenu,
      foodSource,
      meal,
      quantity,
      pottyDestination,
      pottyType,
      napStart,
      createdBy,
      thumbNail,
      youtubeUrlCode,
      enableParentComments,
      assignmentId,
      firebase
    );

    let lessonSub = yield call(
      lessonAssignmentApi.getLessonSubmissionByStudentId,
      assignmentId,
      studentId,
      firebase
    );
    if (lessonSub && lessonSub.activityId) {
      lessonSub.assignmentId = assignmentId;
      lessonSubmission.set(assignmentId, lessonSub);
    }

    let studentAssignment = yield call(
      lessonAssignmentApi.getStudentAssignmentById,
      assignmentId,
      studentId,
      firebase
    );
    if (studentAssignment && studentAssignment.length > 0) {
      for (let i in studentAssignment) {
        let obj = studentAssignment[i];
        obj.done = true;
        obj.activityId = activityId;
        obj.submissionDate = moment().valueOf();
        obj.platform = "web";
        yield call(
          lessonAssignmentApi.updateStudentAssignment,
          studentId,
          studentAssignment[i].id,
          obj,
          firebase
        );
      }
    } else {
      let obj = {};
      obj.done = true;
      obj.inversedSharedDate = -moment().valueOf();
      obj.activityId = activityId;
      obj.submissionDate = moment().valueOf();
      obj.platform = "web";
      obj.assignmentId = assignmentId;
      obj.id = assignmentId;
      obj.lessonId = lessonId;

      yield call(
        lessonAssignmentApi.updateStudentAssignment,
        studentId,
        assignmentId,
        obj,
        firebase
      );
    }

    yield put({
      type: actions.SUBMIT_ASSIGNMENT_SUCCESS,
    });

    if (attachedFile && attachedFile.length > 0) {
      let attachmentList = {
        fileList: attachedFile,
      };

      let storagePath = firebase.sbp + "/media/mediaProfiles/";
      let urls = yield call(
        ActivityApi.getAttachmentMediaPath,
        storagePath,
        attachmentList,
        firebase,
        undefined
      );

      if (urls) {
        mediaPaths = urls;
        yield call(
          ActivityApi.updateMedia,
          mediaPaths,
          mediaType,
          activityId,
          firebase
        );
      }
    }

    let studentTimeline = yield call(
      ActivityApi.getStudentTimeline,
      activityDate,
      studentId,
      firebase
    );

    if (studentTimeline) {
      let newActivityIds =
        studentTimeline.activityIds && studentTimeline.activityIds.length > 0
          ? studentTimeline.activityIds
          : [];
      newActivityIds.push(activityId);

      let newTimelineData = {
        activityIds: newActivityIds,
        date: moment().valueOf(),
        inverseDate: -moment().valueOf(),
      };

      yield call(
        ActivityApi.updateStudentTimeline,
        activityDate,
        studentId,
        newTimelineData,
        firebase
      );
    } else {
      console.log("studentTimeline --------------", studentTimeline);
      bugsnagClient.notify(
        "failed to upload activity to student timeline" + " --->>"
      );
    }

    let activityTypeNodeId = yield call(
      ActivityApi.generateActivityTypeNode,
      activityType,
      studentId,
      firebase
    );

    if (activityTypeNodeId) {
      let creator = parentName;
      yield call(
        ActivityApi.updateActivityType,
        activityDate,
        studentId,
        activityId,
        activityTypeNodeId,
        activityType,
        firebase,
        undefined,
        meetingTime ? meetingTime : undefined,
        creator
      );
    }

    let names = [];
    names.push({
      className: studentDetail.classroomName,
      classList: studentDetail.classList ? studentDetail.classList : [],
      date: moment().valueOf(),
      fatherAndroidId: studentDetail.fatherUUid
        ? studentDetail.fatherUUid
        : null,
      fatherId: studentDetail.fatherProfileId
        ? studentDetail.fatherProfileId
        : null,
      fatherIosId: studentDetail.ios_fatherUUid
        ? studentDetail.ios_fatherUUid
        : null,
      gender: studentDetail.gender ? studentDetail.gender : "Male",
      motherId: studentDetail.motherProfileId
        ? studentDetail.motherProfileId
        : null,
      parentName: parentName,
      profileImageUrl: studentDetail.profileImageUrl
        ? studentDetail.profileImageUrl
        : null,
      studentId: studentDetail.id,
      studentName: studentDetail.name,
    });
    yield fork(ActivityApi.addUpdatedStudent, activityId, names, firebase);

    if (disableNotification !== "yes") {
      let teachers = yield call(
        lessonAssignmentApi.getTeachersByStudentClass,
        studentDetail.classroomName,
        firebase
      );
      if (teachers && teachers.length > 0) {
        for (let index in teachers) {
          let teacher = teachers[index];
          let alertActivityName = "Assignment Submission";
          let alertMessage = "Assignment submitted by " + studentDetail.name;
          let alertActivityType = "Assignment Submission";
          let userType = "teacher";

          let permissionStatus = yield call(
            NotificationApi.receiveNotificationPermission,
            alertActivityName,
            alertActivityName,
            teacher
          );
          if (permissionStatus) {
            let sendNotification = false;
            if (teacher.groups) {
              sendNotification = yield call(
                NotificationApi.checkIfStudentInGroup,
                teacher,
                studentDetail
              );
            } else {
              sendNotification = true;
            }

            if (sendNotification) {
              let alertNode = yield call(
                NotificationApi.createAlertReferenceNode,
                teacher.id,
                firebase
              );

              yield fork(
                NotificationApi.createSimpleAlertNotification,
                alertActivityName,
                assignmentId,
                teacher.uid ? teacher.uid : null,
                alertMessage,
                alertNode,
                teacher.ios_uid ? teacher.ios_uid : null,
                studentId,
                teacher.id,
                alertActivityType,
                undefined,
                firebase,
                parentName,
                userType
              );

              if (teacher.uid !== undefined || teacher.ios_uid !== undefined) {
                yield fork(
                  NotificationApi.sendSimplePushNotification,
                  alertActivityName,
                  assignmentId,
                  teacher.uid ? teacher.uid : null,
                  alertMessage,
                  alertNode,
                  teacher.ios_uid ? teacher.ios_uid : null,
                  studentId,
                  teacher.id,
                  alertActivityType,
                  undefined,
                  firebase,
                  parentName,
                  userType
                );
              }
            }
          }
        }
      }
    }
    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "lessons",
      new Date()
    );
  } catch (err) {
    console.log("failed to submit assignment", err);
    bugsnagClient.notify(
      "failed to submit assignment ----->>>" + err.message ? err.message : err
    );
    yield put({
      type: actions.STUDENT_LESSON_STAT_REQUEST_FAIL,
      errorMessage: "failed to submit assignments",
    });
  }
}

function* fetchStudentSingleSubmission({ selectedLesson, firebase }) {
  let id;
  if (selectedLesson.nodeId) {
    id = selectedLesson.nodeId;
  } else {
    let submission = yield call(
      lessonAssignmentApi.getLessonSubmissionByStudentId,
      selectedLesson.assignmentId,
      selectedLesson.studentId,
      firebase
    );
    if (submission && submission.activityId) {
      id = submission.activityId;
    }
  }
  try {
    const chan = yield call(
      ActivityApi.getStudentSubmissionActivityById,
      id,
      firebase
    );
    while (true) {
      let val = yield take(chan);

      let activities = [];
      if (val && val.id) {
        activities.push(val);
        yield put({
          type: actions.GET_STUDENT_SINGLE_SUBMISSION_SUCCESS,
          singleSubmission: activities,
          submissionActivityChan: chan,
        });

        yield fork(fetchActivityComments, activities, firebase);
      } else {
        yield put({
          type: actions.STUDENT_LESSON_STAT_REQUEST_FAIL,
          errorMessage: "failed to fetch single submission activity",
        });
      }
    }
  } finally {
    console.log("terminating submission activity channel");
  }
}

function* fetchActivityComments(timelineActivity, firebase) {
  try {
    let activities = timelineActivity;
    var commentsCount = new Map();

    let activitiesTask = [];
    for (const item in activities) {
      let task = call(
        lessonAssignmentApi.getCommentsCount,
        activities[item].id,
        firebase
      );
      activitiesTask.push(task);
    }

    let newVal = yield all([activitiesTask]);
    for (let i in newVal[0]) {
      let commentCountObj = newVal[0][i];
      if (commentCountObj && commentCountObj.length > 0) {
        commentsCount.set(commentCountObj[0].sourceId, commentCountObj);
      }
    }

    yield put({
      type: actions.GET_STUDENT_SINGLE_ACTIVITY_COMMENTS_SUCCESS,
      studentSingleSubmissionComments: commentsCount,
    });
  } catch (err) {
    console.log("failed to fetch timeline activity", err);
    bugsnagClient.notify(err);
  }
}

function* updateStudentAssignmentActivity({
  note,
  attachedFile,
  mediaType,
  selectedLesson,
  firebase,
  disableNotification,
}) {
  try {
    let activityNodeId = selectedLesson.nodeId;
    let assignmentId = selectedLesson.assignmentId;
    let activityObj = {
      message: note ? note : null,
      mediaPaths: null,
      mediaType: null,
      platform: "web_parent",
    };

    yield call(
      ActivityApi.updateActivityObj,
      activityObj,
      activityNodeId,
      firebase
    );
    yield put({
      type: actions.UPDATE_ASSIGNMENT_ACTIVITY_SUCCESS,
    });

    let activityId = activityNodeId;
    let mediaPaths = [];
    if (attachedFile && attachedFile.length > 0) {
      let attachmentList = {
        fileList: attachedFile,
      };

      let storagePath = firebase.sbp + "/media/mediaProfiles/";
      let urls = yield call(
        ActivityApi.getAttachmentMediaPath,
        storagePath,
        attachmentList,
        firebase,
        undefined
      );

      if (urls) {
        mediaPaths = urls;
        yield call(
          ActivityApi.updateMedia,
          mediaPaths,
          mediaType,
          activityId,
          firebase
        );
      }
    }

    let studentId = selectedLesson.studentId;

    let studentDetail = {};
    if (
      selectedLesson.studentProfile &&
      selectedLesson.studentProfile.id &&
      selectedLesson.studentProfile.name
    ) {
      studentDetail = selectedLesson.studentProfile;
    } else {
      let student = yield call(StudentApi.getStudentById, studentId, firebase);
      if (student) {
        studentDetail = student;
      }
    }

    let parentName =
      firebase.user && firebase.user.id === studentDetail.fatherProfileId
        ? studentDetail.fatherName
        : studentDetail.motherName;

    if (disableNotification !== "yes") {
      let teachers = yield call(
        lessonAssignmentApi.getTeachersByStudentClass,
        studentDetail.classroomName,
        firebase
      );
      if (teachers && teachers.length > 0) {
        for (let index in teachers) {
          let teacher = teachers[index];
          let alertActivityName = "Assignment Submission";
          let alertMessage = "Assignment updated by " + studentDetail.name;
          let alertActivityType = "Assignment Submission";
          let userType = "teacher";

          let permissionStatus = yield call(
            NotificationApi.receiveNotificationPermission,
            alertActivityName,
            alertActivityName,
            teacher
          );
          if (permissionStatus) {
            let sendNotification = false;
            if (teacher.groups) {
              sendNotification = yield call(
                NotificationApi.checkIfStudentInGroup,
                teacher,
                studentDetail
              );
            } else {
              sendNotification = true;
            }

            if (sendNotification) {
              let alertNode = yield call(
                NotificationApi.createAlertReferenceNode,
                teacher.id,
                firebase
              );

              yield fork(
                NotificationApi.createSimpleAlertNotification,
                alertActivityName,
                assignmentId,
                teacher.uid ? teacher.uid : null,
                alertMessage,
                alertNode,
                teacher.ios_uid ? teacher.ios_uid : null,
                studentId,
                teacher.id,
                alertActivityType,
                undefined,
                firebase,
                parentName,
                userType
              );

              if (teacher.uid !== undefined || teacher.ios_uid !== undefined) {
                yield fork(
                  NotificationApi.sendSimplePushNotification,
                  alertActivityName,
                  assignmentId,
                  teacher.uid ? teacher.uid : null,
                  alertMessage,
                  alertNode,
                  teacher.ios_uid ? teacher.ios_uid : null,
                  studentId,
                  teacher.id,
                  alertActivityType,
                  undefined,
                  firebase,
                  parentName,
                  userType
                );
              }
            }
          }
        }
      }
    }
    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "lessons",
      new Date()
    );
  } catch (err) {
    console.log("failed to update assignment activity", err);
    bugsnagClient.notify(
      "failed to update assignment activity ----->>>" + err.message
        ? err.message
        : err
    );
    yield put({
      type: actions.STUDENT_LESSON_STAT_REQUEST_FAIL,
      errorMessage: "failed to update assignment activity",
    });
  }
}

function* fetchStudentWeeklyPlan({ firebase }) {
  let selectedRoom = firebase.student.classroomName;
  let studentId = firebase.student.id;
  const chan = yield call(
    LearningApi.getSharedWeeklyLesson,
    selectedRoom,
    firebase,
    studentId
  );
  try {
    while (true) {
      let weeklyPlan = yield take(chan);
      yield put({
        type: actions.GET_STUDENT_WEEKLY_PLAN_SUCCESS,
        studentWeeklyPlan: weeklyPlan,
        studentWeeklyPlanChan: chan,
      });
    }
  } finally {
    console.log("end weekly plan channel");
  }
}

function* getPlanLessons({ lessonIds, lessonList, firebase }) {
  try {
    let lessonMap = new Map();
    // for (let index in lessonList) {
    //     lessonMap.set(lessonList[index].id, lessonList[index]);
    // }

    let list = [];
    let lessonsTask = [];
    for (let index in lessonIds) {
      let lessonId = lessonIds[index];
      if (lessonMap.has(lessonId)) {
        list.push(lessonMap.get(lessonId));
      } else {
        let task = call(LearningApi.getLessonById, lessonId, firebase);
        lessonsTask.push(task);
      }
    }

    let newVal = yield all([lessonsTask]);
    for (let i in newVal[0]) {
      let lesson = newVal[0][i];
      list.push(lesson);
    }

    yield put({
      type: actions.GET_PLAN_LESSONS_SUCCESS,
      lessonList: list,
    });
  } catch (err) {
    console.log("failed to fetch plan lessons", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_LESSON_STAT_REQUEST_FAIL,
      errorMessage: "failed to lessons of weekly plan",
    });
  }
}

function* getStudentPlanAssignment({ studentId, plan, firebase }) {
  try {
    let schoolAssignments = [];
    let assignmentTask = [];
    for (let index in plan) {
      if (plan[index].lessons) {
        let lessonIds = plan[index].lessons;
        for (let i in lessonIds) {
          let assignmentId =
            lessonIds[i] + moment(plan[index].date).format("DD[]MM[]YYYY");
          let task = call(
            lessonAssignmentApi.getStudentAssignmentById,
            assignmentId,
            studentId,
            firebase
          );
          assignmentTask.push(task);
        }
      }
    }

    let newAssignmentVal = yield all([assignmentTask]);
    let assignmentArr = newAssignmentVal[0];
    for (let j in assignmentArr) {
      if (assignmentArr[j][0]) {
        schoolAssignments.push(assignmentArr[j][0]);
      }
    }

    yield put({
      type: actions.GET_PLAN_ASSIGNMENT_SUCCESS,
      planAssignmentRef: schoolAssignments,
    });
  } catch (err) {
    console.log("failed to fetch student learning plan assignment", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_LESSON_STAT_REQUEST_FAIL,
      errorMessage: "failed to fetch learning plan assignment",
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_STUDENT_LESSON_STATS, fetchStudentLessonStats),
    yield takeLatest(actions.GET_LEARNING_EXP, fetchLearningExp),
    yield takeLatest(
      actions.GET_STUDENT_ASSIGNMENT_COMMENT,
      fetchAssignmentComment
    ),
    yield takeLatest(actions.SUBMIT_ASSIGNMENT, submitStudentAssignment),
    yield takeLatest(
      actions.GET_STUDENT_SINGLE_SUBMISSION,
      fetchStudentSingleSubmission
    ),
    yield takeLatest(
      actions.UPDATE_ASSIGNMENT_ACTIVITY,
      updateStudentAssignmentActivity
    ),
    yield takeLatest(actions.GET_STUDENT_WEEKLY_PLAN, fetchStudentWeeklyPlan),
    yield takeLatest(actions.GET_PLAN_LESSONS, getPlanLessons),
    yield takeLatest(actions.GET_PLAN_ASSIGNMENT, getStudentPlanAssignment),
  ]);
}
