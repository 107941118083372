const actions = {
  LIST_FEE_COMPONENTS: "LIST_FEE_COMPONENTS",
  LIST_FEE_COMPONENTS_SUCCESSFFUL: "LIST_FEE_COMPONENTS_SUCCESSFFUL",
  LIST_FEE_PLANS: "LIST_FEE_PLANS",
  LIST_FEE_PLANS_SUCCESSFFUL: "LIST_FEE_PLANS_SUCCESSFFUL",
  ADD_FEE_PLAN: "ADD_FEE_PLAN",
  ADD_FEE_PLAN_SUCCESSFFUL: "ADD_FEE_PLAN_SUCCESSFFUL",
  UPDATE_FEE_PLAN: "UPDATE_FEE_PLAN",
  UPDATE_FEE_PLAN_SUCCESSFFUL: "UPDATE_FEE_PLAN_SUCCESSFFUL",
  ADD_FEE_COMPONENT: "ADD_FEE_COMPONENT",
  ADD_FEE_COMPONENT_SUCCESSFFUL: "ADD_FEE_COMPONENT_SUCCESSFFUL",
  UPDATE_FEE_COMPONENT: "UPDATE_FEE_COMPONENT",
  UPDATE_FEE_COMPONENT_SUCCESSFFUL: "UPDATE_FEE_COMPONENT_SUCCESSFFUL",
  ATTACH_FEE_COMPONENT: "ATTACH_FEE_COMPONENT",
  ATTACH_FEE_COMPONENT_SUCCESSFUL: "ATTACH_FEE_COMPONENT_SUCCESSFUL",
  POP_EDIT: "POP_EDIT",
  POP_EDIT_SUCCESSFFUL: "POP_EDIT_SUCCESSFFUL",
  RESET_POP_EDIT: "RESET_POP_EDIT",
  RESET_POP_EDIT_SUCCESSFFUL: "RESET_POP_EDIT_SUCCESSFFUL",
  FEE_REQUEST_FAILED: "FEE_REQUEST_FAILED",
  FEE_PLAN_FETCH_STUDENT: "FEE_PLAN_FETCH_STUDENT",
  FEE_PLAN_FETCH_STUDENT_SUCCESSFFUL: "FEE_PLAN_FETCH_STUDENT_SUCCESSFFUL",
  ASSIGN_STUDENT_TO_FEE_PLAN: "ASSIGN_STUDENT_TO_FEE_PLAN",
  ASSIGN_STUDENT_TO_FEE_PLAN_SUCCESSFUL:
    "ASSIGN_STUDENT_TO_FEE_PLAN_SUCCESSFUL",
  GET_COMPLETE_FEE_COMPONENTS: 'GET_COMPLETE_FEE_COMPONENTS',
  GET_COMPLETE_FEE_COMPONENTS_SUCCESSFUL: 'GET_COMPLETE_FEE_COMPONENTS_SUCCESSFUL',
  GET_FEE_PROGRAMS: 'GET_FEE_PROGRAMS',
  GET_FEE_PROGRAMS_SUCCESSFUL: 'GET_FEE_PROGRAMS_SUCCESSFUL',
  DELETE_FEE_PLAN: "DELETE_FEE_PLAN",
  DELETE_FEE_PLAN_SUCCESS: "DELETE_FEE_PLAN_SUCCESS",

  RESET_FEE_OPERATION: "RESET_FEE_OPERATION",

  GET_SELECTED_COMPONENT_AMOUNT_COLLECTED: "GET_SELECTED_COMPONENT_AMOUNT_COLLECTED",
  GET_SELECTED_COMPONENT_AMOUNT_COLLECTED_SUCCESS: "GET_SELECTED_COMPONENT_AMOUNT_COLLECTED_SUCCESS",

  getFeePlan: firebase => ({ type: actions.LIST_FEE_PLANS, firebase }),

  getFeePrograms: firebase => ({ type: actions.GET_FEE_PROGRAMS, firebase }),

  addFeePlan: (value, customDates, firebase) => ({
    type: actions.ADD_FEE_PLAN,
    value,
    customDates,
    firebase
  }),

  updateFeePlan: (value, editableFeePlan, customDateArr, firebase) => ({
    type: actions.UPDATE_FEE_PLAN,
    value,
    editableFeePlan,
    customDateArr,
    firebase
  }),

  getFeeComponent: firebase => ({
    type: actions.LIST_FEE_COMPONENTS,
    firebase
  }),

  fetchFeeComponent: firebase => ({
    type: actions.GET_COMPLETE_FEE_COMPONENTS,
    firebase
  }),

  addFeeComponent: (value, firebase) => ({
    type: actions.ADD_FEE_COMPONENT,
    value,
    firebase
  }),

  updateFeeComponent: (value, editableFeeComponent, firebase) => ({
    type: actions.UPDATE_FEE_COMPONENT,
    value,
    editableFeeComponent,
    firebase
  }),

  saveAttachedFeeComponent: (selectedComponent, record, firebase) => ({
    type: actions.ATTACH_FEE_COMPONENT,
    selectedComponent,
    record,
    firebase
  }),

  updateEditableInitialState: feeComponent => ({
    type: actions.POP_EDIT,
    feeComponent
  }),

  resetEditableInitialState: () => ({
    type: actions.RESET_POP_EDIT
  }),

  getStudentsToAssignFeePlan: firebase => ({
    type: actions.FEE_PLAN_FETCH_STUDENT,
    firebase
  }),

  assignStudent: (formValue, studentList, selectedFeePlan, rows, total, showGeneratedInvoice, firebase) => ({
    type: actions.ASSIGN_STUDENT_TO_FEE_PLAN,
    formValue,
    studentList,
    selectedFeePlan,
    rows,
    total,
    showGeneratedInvoice,
    firebase
  }),

  deleteFeePlan: (record, firebase) => ({
    type: actions.DELETE_FEE_PLAN,
    record,
    firebase
  }),

  resetFeeOperation: () => ({
    type: actions.RESET_FEE_OPERATION
  }),

  getComponentAmmountCollection: (startDate, endDate, feeComponent, firebase) => ({
    type: actions.GET_SELECTED_COMPONENT_AMOUNT_COLLECTED,
    startDate,
    endDate,
    feeComponent,
    firebase
  })
};
export default actions;
