import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore"; // 👈 If you're using firestore
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { ActivityApi } from "../../firestore-api/activity";
import { ComplainsApi } from "../../firestore-api/consult";
import { EventsApi } from "../../firestore-api/event";
import { NotificationApi } from "../../firestore-api/notification";
import { StudentApi } from "../../firestore-api/student";
import actions from "./actions";
import moment from "moment-timezone";

function* fetchAllEvents({ firebase }) {
  const chan = yield call(EventsApi.getAllEvents, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_ALL_EVENTS_SUCCESSFUL,
        events: data,
        eventsChannel: chan,
      });
    }
  } finally {
    console.log("end events channel");
  }
}

function* fetchNotificationEvent({ id, firebase }) {
  const chan = yield call(EventsApi.getNotificationEvent, id, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_ALL_EVENTS_SUCCESSFUL,
        events: data,
        eventsChannel: chan,
      });
    }
  } finally {
    console.log("end events channel");
  }
}

function* fetchCommentsForSelectedEvent({ recordId, firebase }) {
  try {
    let data = yield call(ComplainsApi.getComment, recordId, firebase);
    if (data) {
      yield put({
        type: actions.GET_COMMENT_FOR_EVENT_SUCCESSFUL,
        eventComments: data,
      });
    }
  } catch (error) {
    console.log("failed to fetch comment for selected event", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.EVENT_REQUEST_FAILED,
    });
  }
}

function* fetchEventPhotos({ recordId, firebase }) {
  try {
    let data = yield call(EventsApi.getEventPhotos, recordId, firebase);
    if (data) {
      yield put({
        type: actions.GET_PHOTOS_FOR_EVENT_SUCCESSFUL,
        eventPhotos: data,
      });
    }
  } catch (error) {
    console.log("failed to fetch photos for selected event", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.EVENT_REQUEST_FAILED,
    });
  }
}

function* addEvent({ formValue, firebase }) {
  try {
    let mediaPath = [];
    let myDate = new Date(formValue.eventDate);

    let selectedStudent = [];
    let allStudents = JSON.parse(localStorage.getItem("studentList"));

    let classNames = formValue.classrooms;
    for (let index in classNames) {
      let filteredStudent = allStudents.filter((s) => {
        return (
          s.classList.includes(classNames[index]) &&
          (!s.status || s.status.toLowerCase() === "active")
        );
      });
      selectedStudent = [...selectedStudent, ...filteredStudent];
    }

    let key = yield call(EventsApi.getUniqueId, firebase, undefined);
    if (formValue.banner) {
      let storagePath = firebase.sbp + "/media/images/";
      let urls = yield call(
        ActivityApi.getMediaPath,
        storagePath,
        formValue.banner,
        firebase
      );
      if (urls) {
        mediaPath = urls;
      }
    }

    let createdAt = {
      date: myDate.getDate(),
      day: myDate.getDay(),
      hours: myDate.getHours(),
      minutes: myDate.getMinutes(),
      month: myDate.getMonth(),
      seconds: myDate.getSeconds(),
      time: myDate.getTime(),
      timezoneOffset: myDate.getTimezoneOffset(),
      year: myDate.getFullYear() - 1900,
    };

    let eventObj = {
      description: formValue.description ? formValue.description : null,
      eventDate: createdAt,
      eventImage: mediaPath[0] ? mediaPath[0] : null,
      eventName: formValue.name,
      id: key,
      inverseTime: -myDate.getTime(),
      parentsInvited: false,
      published: true,
      classRoomNames: classNames,
    };

    yield fork(EventsApi.addNewEvent, eventObj, key, firebase);
    yield put({
      type: actions.ADD_EVENT_SUCCESSFUL,
    });

    if (formValue.sendNotification && selectedStudent.length > 0) {
      for (let i = 0; i < selectedStudent.length; i++) {
        let selectedActivity = "Events";
        if (selectedStudent[i].fatherProfileId) {
          let alertNode = yield call(
            NotificationApi.createAlertReferenceNode,
            selectedStudent[i].fatherProfileId,
            firebase
          );
          yield fork(
            NotificationApi.createAlertNotification,
            selectedActivity,
            key,
            selectedStudent[i].fatherUUid
              ? selectedStudent[i].fatherUUid
              : null,
            "Event published: " + formValue.name,
            alertNode,
            selectedStudent[i].ios_fatherUUid
              ? selectedStudent[i].ios_fatherUUid
              : null,
            selectedStudent[i].id,
            selectedStudent[i].fatherProfileId,
            firebase
          );

          if (
            selectedStudent[i].fatherUUid !== undefined ||
            selectedStudent[i].ios_fatherUUid !== undefined
          ) {
            yield fork(
              NotificationApi.sendPushNotification,
              selectedActivity,
              key,
              selectedStudent[i].fatherUUid
                ? selectedStudent[i].fatherUUid
                : null,
              "Event published: " + formValue.name,
              alertNode,
              selectedStudent[i].ios_fatherUUid
                ? selectedStudent[i].ios_fatherUUid
                : null,
              selectedStudent[i].id,
              selectedStudent[i].fatherProfileId,
              firebase
            );
          }
        }

        if (selectedStudent[i].motherProfileId) {
          let alertNode = yield call(
            NotificationApi.createAlertReferenceNode,
            selectedStudent[i].motherProfileId,
            firebase
          );
          yield fork(
            NotificationApi.createAlertNotification,
            selectedActivity,
            key,
            selectedStudent[i].motherUUid
              ? selectedStudent[i].motherUUid
              : null,
            "Event published: " + formValue.name,
            alertNode,
            selectedStudent[i].ios_motherUUid
              ? selectedStudent[i].ios_motherUUid
              : null,
            selectedStudent[i].id,
            selectedStudent[i].motherProfileId,
            firebase
          );

          if (
            selectedStudent[i].motherUUid !== undefined ||
            selectedStudent[i].ios_motherUUid !== undefined
          ) {
            yield fork(
              NotificationApi.sendPushNotification,
              selectedActivity,
              key,
              selectedStudent[i].motherUUid
                ? selectedStudent[i].motherUUid
                : null,
              "Event published: " + formValue.name,
              alertNode,
              selectedStudent[i].ios_motherUUid
                ? selectedStudent[i].ios_motherUUid
                : null,
              selectedStudent[i].id,
              selectedStudent[i].motherProfileId,
              firebase
            );
          }
        }
      }
    }
  } catch (err) {
    console.log("failed to add event", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.EVENT_REQUEST_FAILED,
    });
  }
}

function* updateExistingEvent({
  formValue,
  firebase,
  selectedEvent,
  eventPhotos,
}) {
  try {
    let mediaPath = "";
    let myDate = new Date(formValue.eventDate);
    // let selectedStudent = yield call(AssessmentApi.getAllStudents, firebase);
    let selectedStudent = [];
    let allStudents = JSON.parse(localStorage.getItem("studentList"));

    let classNames = formValue.classrooms;
    for (let index in classNames) {
      let filteredStudent = allStudents.filter((s) => {
        return (
          s.classList.includes(classNames[index]) &&
          (!s.status || s.status.toLowerCase() === "active")
        );
      });
      selectedStudent = [...selectedStudent, ...filteredStudent];
    }

    let key = selectedEvent.id;

    if (formValue.banner) {
      let storagePath = firebase.sbp + "/media/images/";
      let urls = yield call(
        ActivityApi.getMediaPath,
        storagePath,
        formValue.banner,
        firebase
      );
      if (urls) {
        mediaPath = urls[0];
      }
    } else {
      mediaPath = selectedEvent.eventImage;
    }

    let createdAt = {
      date: myDate.getDate(),
      day: myDate.getDay(),
      hours: myDate.getHours(),
      minutes: myDate.getMinutes(),
      month: myDate.getMonth(),
      seconds: myDate.getSeconds(),
      time: myDate.getTime(),
      timezoneOffset: myDate.getTimezoneOffset(),
      year: myDate.getFullYear() - 1900,
    };

    let eventObj = {
      description: formValue.description ? formValue.description : null,
      eventDate: createdAt,
      eventImage: mediaPath ? mediaPath : null,
      eventName: formValue.name,
      id: key,
      inverseTime: -myDate.getTime(),
      parentsInvited: false,
      published: true,
      classRoomNames: classNames,
      updatedBy: firebase.teacher.name,
      updatedOn: moment().valueOf(),
    };
    yield fork(EventsApi.addNewEvent, eventObj, key, firebase);

    if (eventPhotos) {
      yield fork(updateEventPhotos, selectedEvent, eventPhotos, firebase);
    }
    yield put({
      type: actions.UPDATE_EVENT_SUCCESSFUL,
    });

    if (formValue.sendNotification && selectedStudent.length > 0) {
      for (let i = 0; i < selectedStudent.length; i++) {
        let selectedActivity = "Events";
        if (selectedStudent[i].fatherProfileId) {
          let alertNode = yield call(
            NotificationApi.createAlertReferenceNode,
            selectedStudent[i].fatherProfileId,
            firebase
          );
          yield fork(
            NotificationApi.createAlertNotification,
            selectedActivity,
            key,
            selectedStudent[i].fatherUUid
              ? selectedStudent[i].fatherUUid
              : null,
            "Event published: " + formValue.name,
            alertNode,
            selectedStudent[i].ios_fatherUUid
              ? selectedStudent[i].ios_fatherUUid
              : null,
            selectedStudent[i].id,
            selectedStudent[i].fatherProfileId,
            firebase
          );

          if (
            selectedStudent[i].fatherUUid !== undefined ||
            selectedStudent[i].ios_fatherUUid !== undefined
          ) {
            yield fork(
              NotificationApi.sendPushNotification,
              selectedActivity,
              key,
              selectedStudent[i].fatherUUid
                ? selectedStudent[i].fatherUUid
                : null,
              "Event published: " + formValue.name,
              alertNode,
              selectedStudent[i].ios_fatherUUid
                ? selectedStudent[i].ios_fatherUUid
                : null,
              selectedStudent[i].id,
              selectedStudent[i].fatherProfileId,
              firebase
            );
          }
        }

        if (selectedStudent[i].motherProfileId) {
          let alertNode = yield call(
            NotificationApi.createAlertReferenceNode,
            selectedStudent[i].motherProfileId,
            firebase
          );
          yield fork(
            NotificationApi.createAlertNotification,
            selectedActivity,
            key,
            selectedStudent[i].motherUUid
              ? selectedStudent[i].motherUUid
              : null,
            "Event published: " + formValue.name,
            alertNode,
            selectedStudent[i].ios_motherUUid
              ? selectedStudent[i].ios_motherUUid
              : null,
            selectedStudent[i].id,
            selectedStudent[i].motherProfileId,
            firebase
          );

          if (
            selectedStudent[i].motherUUid !== undefined ||
            selectedStudent[i].ios_motherUUid !== undefined
          ) {
            yield fork(
              NotificationApi.sendPushNotification,
              selectedActivity,
              key,
              selectedStudent[i].motherUUid
                ? selectedStudent[i].motherUUid
                : null,
              "Event published: " + formValue.name,
              alertNode,
              selectedStudent[i].ios_motherUUid
                ? selectedStudent[i].ios_motherUUid
                : null,
              selectedStudent[i].id,
              selectedStudent[i].motherProfileId,
              firebase
            );
          }
        }
      }
    }
  } catch (error) {
    console.log("failed to edit event", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.EVENT_REQUEST_FAILED,
    });
  }
}

function* addEventForAllCenter({ formValue, firebase, branches }) {
  try {
    let centerClasses = JSON.parse(localStorage.getItem("classmap"));
    let tempBranchMap = new Map();
    for (let i in branches) {
      let branchClassName = branches[i];
      if (centerClasses[branchClassName]) {
        let allClasses = centerClasses[branchClassName];
        tempBranchMap.set(branchClassName, allClasses);
      } else {
        let singleClass = branchClassName.split("*")[0];
        let branchName = branchClassName.split("*")[1];
        if (tempBranchMap.has(branchName)) {
          let allClasses = tempBranchMap.get(branchName);
          allClasses.push({ className: singleClass });
          tempBranchMap.set(branchName, allClasses);
        } else {
          let allClasses = [];
          allClasses.push({ className: singleClass });
          tempBranchMap.set(branchName, allClasses);
        }
      }
    }

    for (let [k, value] of tempBranchMap) {
      let bPath = k;

      let mediaPath = "";
      let myDate = new Date(formValue.eventDate);
      let key = yield call(EventsApi.getUniqueId, firebase, bPath);
      if (formValue.banner) {
        let storagePath = bPath + "/media/images/";
        let urls = yield call(
          ActivityApi.getMediaPath,
          storagePath,
          formValue.banner,
          firebase
        );
        if (urls) {
          mediaPath = urls;
        }
      }

      let classList = value;
      let classNames = [];
      if (classList.length > 0) {
        classList.map((c) => {
          classNames.push(c.className);
        });
      }

      let createdAt = {
        date: myDate.getDate(),
        day: myDate.getDay(),
        hours: myDate.getHours(),
        minutes: myDate.getMinutes(),
        month: myDate.getMonth(),
        seconds: myDate.getSeconds(),
        time: myDate.getTime(),
        timezoneOffset: myDate.getTimezoneOffset(),
        year: myDate.getFullYear() - 1900,
      };

      let eventObj = {
        description: formValue.description ? formValue.description : null,
        eventDate: createdAt,
        eventImage: mediaPath[0] ? mediaPath[0] : null,
        eventName: formValue.name,
        id: key,
        inverseTime: -myDate.getTime(),
        parentsInvited: false,
        published: true,
        classRoomNames: classNames,
        updatedBy: firebase.teacher.name,
        updatedOn: moment().valueOf(),
      };

      yield fork(EventsApi.addNewEvent, eventObj, key, firebase, bPath);

      let selectedStudent = [];
      if (formValue.sendNotification) {
        for (let i in classNames) {
          let students = yield call(
            StudentApi.getStudentByClassroomName,
            classNames[i],
            firebase,
            bPath
          );
          if (students) {
            selectedStudent = [...selectedStudent, ...students];
          }
        }
      }

      if (formValue.sendNotification && selectedStudent.length > 0) {
        for (let i = 0; i < selectedStudent.length; i++) {
          let selectedActivity = "Events";
          if (selectedStudent[i].fatherProfileId) {
            let alertNode = yield call(
              NotificationApi.createAlertReferenceNode,
              selectedStudent[i].fatherProfileId,
              firebase
            );
            yield fork(
              NotificationApi.createAlertNotification,
              selectedActivity,
              key,
              selectedStudent[i].fatherUUid
                ? selectedStudent[i].fatherUUid
                : null,
              "Event published: " + formValue.name,
              alertNode,
              selectedStudent[i].ios_fatherUUid
                ? selectedStudent[i].ios_fatherUUid
                : null,
              selectedStudent[i].id,
              selectedStudent[i].fatherProfileId,
              firebase
            );

            if (
              selectedStudent[i].fatherUUid !== undefined ||
              selectedStudent[i].ios_fatherUUid !== undefined
            ) {
              yield fork(
                NotificationApi.sendPushNotification,
                selectedActivity,
                key,
                selectedStudent[i].fatherUUid
                  ? selectedStudent[i].fatherUUid
                  : null,
                "Event published: " + formValue.name,
                alertNode,
                selectedStudent[i].ios_fatherUUid
                  ? selectedStudent[i].ios_fatherUUid
                  : null,
                selectedStudent[i].id,
                selectedStudent[i].fatherProfileId,
                firebase
              );
            }
          }

          if (selectedStudent[i].motherProfileId) {
            let alertNode = yield call(
              NotificationApi.createAlertReferenceNode,
              selectedStudent[i].motherProfileId,
              firebase
            );
            yield fork(
              NotificationApi.createAlertNotification,
              selectedActivity,
              key,
              selectedStudent[i].motherUUid
                ? selectedStudent[i].motherUUid
                : null,
              "Event published: " + formValue.name,
              alertNode,
              selectedStudent[i].ios_motherUUid
                ? selectedStudent[i].ios_motherUUid
                : null,
              selectedStudent[i].id,
              selectedStudent[i].motherProfileId,
              firebase
            );

            if (
              selectedStudent[i].motherUUid !== undefined ||
              selectedStudent[i].ios_motherUUid !== undefined
            ) {
              yield fork(
                NotificationApi.sendPushNotification,
                selectedActivity,
                key,
                selectedStudent[i].motherUUid
                  ? selectedStudent[i].motherUUid
                  : null,
                "Event published: " + formValue.name,
                alertNode,
                selectedStudent[i].ios_motherUUid
                  ? selectedStudent[i].ios_motherUUid
                  : null,
                selectedStudent[i].id,
                selectedStudent[i].motherProfileId,
                firebase
              );
            }
          }
        }
      }
    }
    yield put({
      type: actions.ADD_EVENT_FOR_ALL_CENTERS_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to add event to all centers", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.EVENT_REQUEST_FAILED,
    });
  }
}

function* addEventPhotos({
  attachment,
  dataSource,
  sendNotification,
  firebase,
}) {
  try {
    let storagePath = firebase.sbp + "/media/images/";

    let eventDate = new Date();
    let imageDate = {
      date: eventDate.getDate(),
      day: eventDate.getDay(),
      hours: eventDate.getHours(),
      minutes: eventDate.getMinutes(),
      month: eventDate.getMonth(),
      seconds: eventDate.getSeconds(),
      time: eventDate.getTime(),
      timezoneOffset: eventDate.getTimezoneOffset(),
      year: eventDate.getFullYear() - 1900,
    };

    let files = {};
    files.fileList = attachment;

    let urls = yield call(
      ActivityApi.getAttachmentMediaPath,
      storagePath,
      files,
      firebase,
      dataSource.id,
      undefined,
      "event"
    );

    if (urls) {
      yield fork(
        ActivityApi.generateThumbnail,
        urls,
        dataSource.id,
        "event",
        undefined,
        firebase
      );

      for (const i in urls) {
        let node = yield call(EventsApi.createPhotoNode, firebase);
        let imgObj = {
          date: imageDate,
          downloadUrl: urls[i].path,
          id: node,
          inverseDate: -eventDate.getTime(),
          mediaType: urls[i].type.toLowerCase() === "video" ? "VIDEO" : "PHOTO",
        };

        if (imgObj.mediaType && imgObj.mediaType.toLowerCase() !== "video") {
          yield call(
            EventsApi.addPhotosToEvent,
            imgObj,
            node,
            dataSource,
            firebase
          );
        }
      }

      let classNames = dataSource.classRoomNames;
      let allStudents = JSON.parse(localStorage.getItem("studentList"));
      let selectedStudent = [];
      if (sendNotification) {
        for (let i in classNames) {
          let students = allStudents.filter((s) => {
            return s.classList.includes(classNames[i]);
          });
          if (students && students.length > 0) {
            selectedStudent = [...selectedStudent, ...students];
          }
        }
      }

      if (sendNotification && selectedStudent.length > 0) {
        let key = dataSource.id;
        for (let i = 0; i < selectedStudent.length; i++) {
          let selectedActivity = "Events";
          if (selectedStudent[i].fatherProfileId) {
            let alertNode = yield call(
              NotificationApi.createAlertReferenceNode,
              selectedStudent[i].fatherProfileId,
              firebase
            );
            yield fork(
              NotificationApi.createAlertNotification,
              selectedActivity,
              key,
              selectedStudent[i].fatherUUid
                ? selectedStudent[i].fatherUUid
                : null,
              "Event photos added",
              alertNode,
              selectedStudent[i].ios_fatherUUid
                ? selectedStudent[i].ios_fatherUUid
                : null,
              selectedStudent[i].id,
              selectedStudent[i].fatherProfileId,
              firebase
            );

            if (
              selectedStudent[i].fatherUUid !== undefined ||
              selectedStudent[i].ios_fatherUUid !== undefined
            ) {
              yield fork(
                NotificationApi.sendPushNotification,
                selectedActivity,
                key,
                selectedStudent[i].fatherUUid
                  ? selectedStudent[i].fatherUUid
                  : null,
                "Event photos added",
                alertNode,
                selectedStudent[i].ios_fatherUUid
                  ? selectedStudent[i].ios_fatherUUid
                  : null,
                selectedStudent[i].id,
                selectedStudent[i].fatherProfileId,
                firebase
              );
            }
          }

          if (selectedStudent[i].motherProfileId) {
            let alertNode = yield call(
              NotificationApi.createAlertReferenceNode,
              selectedStudent[i].motherProfileId,
              firebase
            );
            yield fork(
              NotificationApi.createAlertNotification,
              selectedActivity,
              key,
              selectedStudent[i].motherUUid
                ? selectedStudent[i].motherUUid
                : null,
              "Event photos added",
              alertNode,
              selectedStudent[i].ios_motherUUid
                ? selectedStudent[i].ios_motherUUid
                : null,
              selectedStudent[i].id,
              selectedStudent[i].motherProfileId,
              firebase
            );

            if (
              selectedStudent[i].motherUUid !== undefined ||
              selectedStudent[i].ios_motherUUid !== undefined
            ) {
              yield fork(
                NotificationApi.sendPushNotification,
                selectedActivity,
                key,
                selectedStudent[i].motherUUid
                  ? selectedStudent[i].motherUUid
                  : null,
                "Event photos added",
                alertNode,
                selectedStudent[i].ios_motherUUid
                  ? selectedStudent[i].ios_motherUUid
                  : null,
                selectedStudent[i].id,
                selectedStudent[i].motherProfileId,
                firebase
              );
            }
          }
        }
      }
    }
    yield put({
      type: actions.ADD_EVENT_IMAGES_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to add event images", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.EVENT_REQUEST_FAILED,
    });
  }
}

function* updateEventPhotos(event, eventPhotos, firebase) {
  try {
    yield call(EventsApi.deleteAllEventPhotos, event, firebase);

    let eventDate = new Date(event.eventDate.time);
    let imageDate = {
      date: eventDate.getDate(),
      day: eventDate.getDay(),
      hours: eventDate.getHours(),
      minutes: eventDate.getMinutes(),
      month: eventDate.getMonth(),
      seconds: eventDate.getSeconds(),
      time: eventDate.getTime(),
      timezoneOffset: eventDate.getTimezoneOffset(),
      year: eventDate.getFullYear() - 1900,
    };

    for (let i in eventPhotos) {
      let node = eventPhotos[i].id;
      let imgObj = {
        date: imageDate,
        downloadUrl: eventPhotos[i].downloadUrl,
        id: node,
        inverseDate: -eventDate.getTime(),
        mediaType: "PHOTO",
      };
      yield call(EventsApi.addPhotosToEvent, imgObj, node, event, firebase);
    }
  } catch (err) {
    console.log("failed to delete event photos", err);
    bugsnagClient.notify(err);
  }
}

function* deleteSelectedEvent({ event, firebase }) {
  try {
    yield call(EventsApi.deleteEvent, event, firebase);
    yield put({
      type: actions.DELETE_EVENT_SUCCESS,
    });
  } catch (err) {
    console.log("failed to delete event", err);
    bugsnagClient.notify(err);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_ALL_EVENTS, fetchAllEvents),
    yield takeLatest(
      actions.GET_COMMENT_FOR_EVENT,
      fetchCommentsForSelectedEvent
    ),
    yield takeLatest(actions.ADD_EVENT, addEvent),
    yield takeLatest(actions.ADD_EVENT_FOR_ALL_CENTERS, addEventForAllCenter),
    yield takeLatest(actions.UPDATE_EVENT, updateExistingEvent),
    yield takeLatest(actions.GET_PHOTOS_FOR_EVENT, fetchEventPhotos),
    yield takeLatest(actions.ADD_EVENT_IMAGES, addEventPhotos),
    yield takeLatest(actions.DELETE_EVENT, deleteSelectedEvent),
    yield takeLatest(actions.GET_EVENT_NOTIFICATION, fetchNotificationEvent),
  ]);
}
