import "@firebase/firestore"; // 👈 If you're using firestore
import { eventChannel } from "redux-saga";

function getAllHolidays(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel(emit => {
    rsf.ref(branchPath + "/calendarReference/").on("value", snap => {
      var x = [];
      snap.forEach(element => {
        element.forEach(item => {
          if (item.val() !== null) {
            let classList = firebase && firebase.teacher && firebase.teacher.classList ? firebase.teacher.classList : [];
            if (firebase.teacher && !firebase.teacher.superUser) {
              if (classList.length > 0) {
                if (classList.includes(item.val().classroomName)) {
                  x.push(item.val());
                }
              } else {
                x.push(item.val());
              }
            } else {
              x.push(item.val());
            }
          }
        });
      });
      emit(x);
    });
    return () => {
      rsf.ref(branchPath + "/calendarReference/").off();
      console.log("unsubscribe holidays");
    };
  });
}

function* getHolidayList(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var holidayRef = rsf.ref(branchPath + "/calendarReference");
  return eventChannel(emit => {
    holidayRef.on("value", snap => {
      var allHoliday = [];
      let holidayClassMapArray = new Map();


      snap.forEach(item => {
        let classList = firebase && firebase.teacher && firebase.teacher.classList ? firebase.teacher.classList : [];
        if (firebase.teacher && !firebase.teacher.superUser) {
          if (classList.length > 0) {
            let className = item.key;
            if (classList.includes(className)) {

              let tempVal = [];
              item.forEach(ele => {
                if (ele.val() !== null) {
                  allHoliday.push(ele.val())
                  tempVal.push(ele.val());
                }
              });
              holidayClassMapArray.set(item.key, tempVal);
            }
          } else {
            let tempVal = [];
            item.forEach(ele => {
              if (ele.val() !== null) {
                allHoliday.push(ele.val());
                tempVal.push(ele.val());
              }
            });
            holidayClassMapArray.set(item.key, tempVal);
          }
        } else {
          let tempVal = [];
          item.forEach(ele => {
            if (ele.val() !== null) {
              allHoliday.push(ele.val());
              tempVal.push(ele.val());
            }
          });
          holidayClassMapArray.set(item.key, tempVal);
        }

      })
      let y = [allHoliday, holidayClassMapArray];
      emit(y);
    })
    return () => {
      holidayRef.off();
      console.log("unsubscribing holiday list");
    }
  });
}

function createHolidayNode(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/calendarReference").push().key;
  return key;
}

function addHoliday(className, key, holidayObj, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/calendarReference/" + className + "/" + key)
    .update(holidayObj);
}

function deleteHoliday(className, key, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/calendarReference/" + className + "/" + key)
    .set(null);
}

export const HolidayApi = {
  getAllHolidays,
  createHolidayNode,
  addHoliday,
  getHolidayList,
  deleteHoliday
};

// snap.forEach(item => {
//   let classList = firebase && firebase.teacher && firebase.teacher.classList ? firebase.teacher.classList : [];
//   if (firebase.teacher && !firebase.teacher.superUser) {
//     if (classList.length > 0) {
//       let className = item.key;
//       if (classList.includes(className)) {
//         let c = item.key;
//         c = new Map();
//         c.set(item.key, item.val());
//         holidayClassMapArray.push(c);
//         item.forEach(ele => {
//           if (ele.val() !== null) {
//             allHoliday.push(ele.val())
//           }
//         })
//       }
//     } else {
//       let c = item.key;
//       c = new Map();
//       c.set(item.key, item.val());
//       holidayClassMapArray.push(c);
//       item.forEach(ele => {
//         if (ele.val() !== null) {
//           allHoliday.push(ele.val())
//         }
//       })
//     }
//   } else {
//     let c = item.key;
//     c = new Map();
//     c.set(item.key, item.val());
//     holidayClassMapArray.push(c);
//     item.forEach(ele => {
//       if (ele.val() !== null) {
//         allHoliday.push(ele.val())
//       }
//     })
//   }

// })