import "@firebase/firestore";
import { all, call, put, takeLatest, take, fork } from "redux-saga/effects";
import { ComplainsApi } from "../../firestore-api/consult";
import { StudentApi } from "../../firestore-api/student";
import { NotificationApi } from "../../firestore-api/notification";
import { CommentApi } from "../../firestore-api/comment";
import actions from "./actions";
import { lessonAssignmentApi } from '../../firestore-api/lessonAssignment';
import bugsnagClient from '@bugsnag/js';
import FilterPermission from "../../Utility/FilterPermission";
import PermissionStrings from "../../Utility/PermissionStrings";
import moment from 'moment-timezone';

function* fetchAllComplains({ firebase, startTime, endTime, initialCall, selectedFrequency }) {
    const chan = yield call(ComplainsApi.getAllComplains, firebase, startTime, endTime, selectedFrequency);
    try {
        while (true) {
            let data = yield take(chan);
            let x = hierarchyLevelComplaints(data, firebase);

            x.sort(function (a, b) {
                var dateA = (a.appliedDate), dateB = (b.appliedDate)
                return dateB - dateA
            });

            yield put({
                type: actions.GET_ALL_COMPLAINS_SUCCESSFUL,
                complains: x,
                complainsChannel: chan,
                operationType: initialCall ? "INITIAL_CALL" : undefined
            })
        }
    } finally {
        console.log("end complains channel")
    }
}

function* fetchNotificationComplaint({ id, firebase }) {
    const chan = yield call(ComplainsApi.getComplaintById, id, firebase);
    try {
        while (true) {
            let data = yield take(chan);
            let x = hierarchyLevelComplaints(data, firebase);
            yield put({
                type: actions.GET_ALL_COMPLAINS_SUCCESSFUL,
                complains: x,
                complainsChannel: chan,
                operationType: undefined
            })
        }
    } finally {
        console.log("terminating notification complaint");
    }
}

function hierarchyLevelComplaints(data, firebase) {
    let teacher = firebase.teacher;
    let x = [];
    let role = teacher.role;
    data.map(d => {
        if (role && role.toLowerCase() === "teacher" || role.toLowerCase() === "center head" || role.toLowerCase() === "administrator" || role.toLowerCase() === "principal") {
            if (teacher && role.toLowerCase() === "teacher" && d.consultTo && d.consultTo.toLowerCase() === "teacher") {
                x.push(d);
            } else if (teacher && role.toLowerCase() === "center head" && d.consultTo && (d.consultTo.toLowerCase() === "teacher" || d.consultTo.toLowerCase() === "center head")) {
                x.push(d)
            } else if (teacher && role.toLowerCase() === "administrator" && d.consultTo) {
                x.push(d);
            } else if (teacher && role.toLowerCase() === "principal" && d.consultTo) {
                x.push(d);
            }
        } else {
            if (teacher && FilterPermission.checkIfPermission(PermissionStrings.CONCERN_VIEW_COMMENT, firebase)) {
                x.push(d);
            }
        }
    });
    return x;
}

function* fetchCommentsForComplain({ complains, firebase }) {
    try {
        let activities = complains;
        var commentsCount = new Map();
        for (const item in activities) {
            var commentCountObj = yield call(lessonAssignmentApi.getCommentsCount, activities[item].id, firebase);
            if (commentCountObj) {
                commentsCount.set(activities[item].id, commentCountObj);
            }
        }
        yield put({
            type: actions.GET_COMMENT_FOR_COMPLAIN_SUCCESSFUL,
            complaintComment: commentsCount
        });
    } catch (error) {
        console.log("failed to fetch comment for selected complain", error);
        bugsnagClient.notify(error);
        yield put({
            type: actions.COMPLAIN_REQUEST_FAILED
        })
    }
}

function* resolveSelectedComplaint({ record, firebase, resolution }) {
    try {
        let activityId = record.id;
        let activityName = "CONCERN";
        let body = "Resolution added to your concern ";
        let task = record;

        task.status = "approved";

        if (resolution) {
            task.resolution = "Resolution: " + resolution;
        }

        yield fork(ComplainsApi.updateComplaint, task, firebase);
        let selectedStudent = yield call(StudentApi.getStudentById, record.studentId, firebase);
        if (selectedStudent && selectedStudent.id) {

            if (selectedStudent.fatherProfileId) {
                let alertNode = yield call(
                    NotificationApi.createAlertReferenceNode,
                    selectedStudent.fatherProfileId,
                    firebase
                );
                yield fork(
                    NotificationApi.createAlertNotification,
                    activityName,
                    activityId,
                    selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
                    body,
                    alertNode,
                    selectedStudent.ios_fatherUUid
                        ? selectedStudent.ios_fatherUUid
                        : null,
                    selectedStudent.id,
                    selectedStudent.fatherProfileId,
                    firebase
                );

                if (selectedStudent.fatherUUid !== undefined || selectedStudent.ios_fatherUUid !== undefined) {
                    yield fork(
                        NotificationApi.sendPushNotification,
                        activityName,
                        activityId,
                        selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
                        body,
                        alertNode,
                        selectedStudent.ios_fatherUUid
                            ? selectedStudent.ios_fatherUUid
                            : null,
                        selectedStudent.id,
                        selectedStudent.fatherProfileId,
                        firebase
                    );
                }
            }

            if (selectedStudent.motherProfileId) {
                let alertNode = yield call(
                    NotificationApi.createAlertReferenceNode,
                    selectedStudent.motherProfileId,
                    firebase
                );
                yield fork(
                    NotificationApi.createAlertNotification,
                    activityName,
                    activityId,
                    selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
                    body,
                    alertNode,
                    selectedStudent.ios_motherUUid
                        ? selectedStudent.ios_motherUUid
                        : null,
                    selectedStudent.id,
                    selectedStudent.motherProfileId,
                    firebase
                );

                if (selectedStudent.motherUUid !== undefined || selectedStudent.ios_motherUUid !== undefined) {
                    yield fork(
                        NotificationApi.sendPushNotification,
                        activityName,
                        activityId,
                        selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
                        body,
                        alertNode,
                        selectedStudent.ios_motherUUid
                            ? selectedStudent.ios_motherUUid
                            : null,
                        selectedStudent.id,
                        selectedStudent.motherProfileId,
                        firebase
                    );
                }
            }
        }

        yield fork(NotificationApi.callDashboardRefreshApi, firebase, "communication", moment());
        yield put({
            type: actions.RESOLVE_COMPLAINT_SUCCESSFUL
        })

    } catch (err) {
        console.log("failed to resolve concern", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.COMPLAIN_REQUEST_FAILED
        })
    }
}

function* addComplaintComment({ comment, dataSource, firebase }) {
    try {
        var commentObject = {
            author: firebase.teacher.name,
            authorId: firebase.teacher.id,
            id: '',
            gender: firebase.teacher.gender ? firebase.teacher.gender : null,
            inverseTimestamp: -(moment().valueOf()),
            sourceId: dataSource.id,
            src: "COMMENT",
            text: comment.comment,
            timestamp: moment().valueOf(),
            userType: "TEACHER"
        }
        let nodeId = yield call(CommentApi.createCommentNode, dataSource.id, firebase);
        commentObject.id = nodeId;
        yield call(CommentApi.updateComment, commentObject, dataSource.id, nodeId, firebase);
        yield put({
            type: actions.SAVE_COMPLAINT_COMMENT_SUCCESSFUL
        })

    } catch (err) {
        console.log("failed to add complaint comment", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.COMPLAIN_REQUEST_FAILED
        })
    }
}

function* fetchPendingQueries({ firebase, existingPendingQueries }) {
    try {
        if (existingPendingQueries) {
            yield put({
                type: actions.GET_PENDING_QUERIES_SUCCESS,
                pendingQueries: existingPendingQueries
            })
        }

        let data = {};
        let pendingComplaints = yield call(ComplainsApi.getPendingQueries, firebase, "complaints");

        let pendingLeaves = yield call(ComplainsApi.getPendingQueries, firebase, "leaves");
        let pendingNotes = yield call(ComplainsApi.getPendingQueries, firebase, "noteReference");

        let parentUnreadMessages = yield call(ComplainsApi.getUnreadParentMsgCounter, firebase);

        data.pendingComplaints = pendingComplaints.length;
        data.pendingLeaves = pendingLeaves.length;
        data.pendingNotes = pendingNotes.length;
        data.unreadMessages = parentUnreadMessages;

        yield put({
            type: actions.GET_PENDING_QUERIES_SUCCESS,
            pendingQueries: data
        })
    } catch (err) {
        bugsnagClient.notify(err);
        yield put({
            type: actions.COMPLAIN_REQUEST_FAILED,
        });
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(actions.GET_ALL_COMPLAINS, fetchAllComplains),
        yield takeLatest(actions.GET_COMMENT_FOR_COMPLAIN, fetchCommentsForComplain),
        yield takeLatest(actions.RESOLVE_COMPLAINT, resolveSelectedComplaint),
        yield takeLatest(actions.SAVE_COMPLAINT_COMMENT, addComplaintComment),
        yield takeLatest(actions.GET_PENDING_QUERIES, fetchPendingQueries),
        yield takeLatest(actions.GET_NOTIFICATION_COMPLAINT, fetchNotificationComplaint)
    ])
}