import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment-timezone";
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import notification from "../../components/notification";
import { InvoiceApi } from "../../firestore-api/invoice";
import { InvoiceTemplateApi } from "../../firestore-api/invoiceTemplate";
import { NotificationApi } from "../../firestore-api/notification";
import actions from "./actions";
import { FeeApi } from "../../firestore-api/fee";
import { ActivityApi } from "../../firestore-api/activity";
import ReactPixel from "react-facebook-pixel";
import { StudentApi } from "../../firestore-api/student";
import { ProgramApi } from "../../firestore-api/program";
import { TagApi } from "../../firestore-api/tag";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const { Parser } = require("json2csv");
const superagent = require("superagent");

function* fetchInvoices({ firebase }) {
  try {
    var data = yield call(InvoiceApi.getAllInvoice, firebase);
    if (data) {
      var allData = data;

      allData.sort(function(a, b) {
        var dateA = a.inverseDate,
          dateB = b.inverseDate;
        return dateA - dateB;
      });

      allData.sort((a, b) =>
        a.studentName && b.studentName
          ? a.studentName.localeCompare(b.studentName)
          : null
      );

      yield put({
        type: actions.LIST_INVOICE_SUCCESSFULL,
        invoice: allData,
      });
    }
    yield fork(fetchTaxRef, firebase);
  } catch (err) {
    console.log("failed to fetch invoices", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* fetchTaxRef(firebase) {
  try {
    let data = yield call(InvoiceApi.getTaxReference, firebase);
    if (data) {
      yield put({
        type: actions.GET_TAX_REF_SUCCESS,
        taxReference: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch tax ref", err);
    bugsnagClient.notify(err);
  }
}

/**code to populate admission number */
// function* updateAdmissionNumber(invoices, firebase) {
//   for (let i = 0; i < invoices.length; i++) {
//     console.log("--- invoice at index ----", invoices[i]);
//     let studentId = invoices[i].studentId;
//     if (studentId) {
//       let studentObj = yield call(StudentApi.getStudentById, studentId, firebase);
//       if (studentObj && studentObj.id) {
//         let invoiceObj = invoices[i];
//         let invoiceId = invoices[i].id;
//         invoiceObj.admissionNumber = studentObj.admissionNumber ? studentObj.admissionNumber : null;
//         console.log("--- invoice object after admission number ----", invoiceObj);
//         // yield call(
//         //   InvoiceApi.updateInvoice,
//         //   invoiceObj,
//         //   studentId,
//         //   invoiceId,
//         //   firebase
//         // );
//       }
//     }
//   }
// }

function* fetchAggregatedInvoices({ firebase }) {
  try {
    var data = yield call(InvoiceApi.getAllAggregatedInvoice, firebase);
    if (data) {
      yield put({
        type: actions.GET_INVOICE_AGGREGATED_SUCCESSFULL,
        aggregatedInvoice: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch aggregated invoices", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* fetchInvoiceDownloadUrl({ record, firebase, userType }) {
  try {
    let data;
    if (userType && userType.toLowerCase() === "student") {
      if (record.pending === 0) {
        data = yield call(
          InvoiceApi.getInvoiceReceiptDownloadUrl,
          record,
          firebase
        );
      } else if (record.pending !== 0 && record.paid !== 0) {
        data = yield call(
          InvoiceApi.getInvoiceReceiptDownloadUrl,
          record,
          firebase
        );
      } else {
        data = yield call(InvoiceApi.getInvoiceDownloadUrl, record, firebase);
      }
    } else {
      data = yield call(InvoiceApi.getInvoiceDownloadUrl, record, firebase);
    }
    if (data) {
      yield put({
        type: actions.GET_INVOICE_DOWNLOAD_URL_SUCCESSFUL,
        invoiceDownloadUrl: data,
      });
    }
  } catch (error) {
    console.log("failed to fetch download url", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* fetchReceiptList({ record, firebase }) {
  try {
    let data = yield call(InvoiceApi.getInvoiceReceiptList, record, firebase);

    if (data && data.length > 0) {
      yield put({
        type: actions.GET_RECEIPT_LIST_SUCCESS,
        receiptList: data,
      });
    } else {
      let data = yield call(
        InvoiceApi.getInvoiceReceiptDownloadUrlAlt,
        record,
        firebase
      );

      if (data) {
        yield put({
          type: actions.GET_RECEIPT_LIST_SUCCESS,
          receiptList: data,
        });
      }
    }
  } catch (err) {
    console.log("failed to fetch receipt list", err);
    bugsnagClient.notify(
      "failed to fetch receipt list --->>>>" + err.message ? err.message : err
    );
  }
}

function* fetchStudentForInvoice({ firebase }) {
  try {
    let students = JSON.parse(localStorage.getItem("studentList"));
    if (students) {
      students = students.filter((std) => {
        return !std.status || std.status.toLowerCase() === "active";
      });

      students = students.sort((a, b) => a.name.localeCompare(b.name));

      yield put({
        type: actions.GET_STUDENT_FOR_INVOICE_SUCCESSFUL,
        studentData: students,
      });

      let data = yield call(ProgramApi.fetchPrograms, firebase);
      if (data) {
        yield put({
          type: actions.GET_PROGRAMS_FOR_INVOICE_SUCCESSFUL,
          invoicePrograms: data,
        });
      }
    }
  } catch (error) {
    console.log("failed to fetch student for program", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* addInvoice({
  raisedOn,
  billingPeriod,
  dueDate,
  schoolNote,
  rows,
  totalAmount,
  selectedStudentCheckbox,
  firebase,
  selectedFeeTemplates,
  autoChargeEnabled,
  subTotal,
  taxDescription,
  tax,
}) {
  let receivedDueDate;
  if (dueDate) {
    receivedDueDate = new Date(dueDate).setHours(0, 0, 0, 0);
  } else {
    let dayDiff = 7;
    if (
      firebase &&
      firebase.schoolConfig &&
      firebase.schoolConfig.dueDateDayDifference !== undefined
    ) {
      dayDiff = firebase.schoolConfig.dueDateDayDifference;
    }

    var date = new Date();
    var modDate = date.setTime(date.getTime() + dayDiff * 24 * 60 * 60 * 1000);
    receivedDueDate = new Date(modDate).setHours(0, 0, 0, 0);
  }

  let modRaisedDate = new Date(raisedOn).setHours(0, 0, 0, 0);
  try {
    for (let i = 0; i < selectedStudentCheckbox.length; i++) {
      let invoiceCounter = yield call(InvoiceApi.getInvoiceCounter, firebase);
      var nodeId = yield call(
        InvoiceApi.generateInvoiceNode,
        selectedStudentCheckbox[i].id,
        firebase
      );
      let studentData = selectedStudentCheckbox[i];

      var newLineItems = [];
      rows.map((item) => {
        newLineItems.push({
          afterDiscountValue: Number(item.netValue),
          amount: Number(item.grossValue),
          description: item.description,
          discountType:
            item.discountType === "Percentage" ? "PERCENTAGE" : "NUMBER",
          discountValue: Number(item.discount),
          timestamp: 0,
        });
      });

      var newSelectedFeeTemplates = [];
      if (selectedFeeTemplates && selectedFeeTemplates.length > 0) {
        selectedFeeTemplates.map((newItem) => {
          newSelectedFeeTemplates.push({
            name: newItem.name,
            id: newItem.id,
          });
        });
      }

      var invoiceObject = {
        billingPeriod: billingPeriod
          ? moment(new Date(billingPeriod[0])).format("DD[ ]MMMM[ ]YYYY") +
            " - " +
            moment(new Date(billingPeriod[1])).format("DD[ ]MMMM[ ]YYYY")
          : null,
        classname: studentData.classroomName,
        classList: studentData.classList ? studentData.classList : [],
        dueDate: new Date(receivedDueDate).getTime(),
        endPeriod: billingPeriod ? new Date(billingPeriod[1]).getTime() : 0,
        fatherEmail: studentData.fatherEmail ? studentData.fatherEmail : null,
        fatherNumber: studentData.fatherNumber ? studentData.fatherNumber : 0,
        gender: studentData.gender,
        id: nodeId,
        ignoreLateFee: false,
        inverseDate: -new Date().getTime(),
        invoiceRaiseDate: new Date(modRaisedDate).getTime(),
        lateFee: 0,
        lineItems: newLineItems,
        motherEmail: studentData.motherEmail ? studentData.motherEmail : null,
        motherNumber: studentData.motherNumber ? studentData.motherNumber : 0,
        paid: 0,
        pending: Number(totalAmount),
        profileImageUrl: studentData.profileImageUrl
          ? studentData.profileImageUrl
          : null,
        startPeriod: billingPeriod ? new Date(billingPeriod[0]).getTime() : 0,
        studentId: studentData.id,
        studentName: studentData.name,
        admissionNumber: studentData.admissionNumber
          ? studentData.admissionNumber
          : null,
        total: Number(totalAmount),
        totalWithoutTax: subTotal ? Number(subTotal) : null,
        taxRate: tax ? Number(tax) : null,
        taxDescription: taxDescription ? taxDescription : null,
        schoolNote: schoolNote,
        feeTemplateList:
          newSelectedFeeTemplates.length > 0 ? newSelectedFeeTemplates : null,
        invoiceNumber:
          firebase.schoolConfig && firebase.schoolConfig.invoicePrefix
            ? (
                firebase.schoolConfig.invoicePrefix +
                "-" +
                (invoiceCounter + 1)
              ).toString()
            : null,
        platform: "web",
        updatedBy: firebase.teacher.name,
        updatedOn: new Date().getTime(),
      };
      yield call(
        InvoiceApi.addNewInvoice,
        invoiceObject,
        studentData,
        nodeId,
        firebase
      );
      yield call(InvoiceApi.incrementCounter, invoiceCounter, firebase);
      let aggregatedInvoice = yield call(
        InvoiceApi.getAggregatedInvoice,
        selectedStudentCheckbox[i].id,
        firebase
      );
      if (aggregatedInvoice !== false) {
        yield call(
          InvoiceApi.updatedAmountToAggregatedInvoice,
          aggregatedInvoice,
          selectedStudentCheckbox[i].id,
          totalAmount,
          firebase,
          "addInvoice",
          receivePayment
        );
      } else {
        yield call(
          InvoiceApi.addAmountToNewAggregatedInvoice,
          selectedStudentCheckbox[i],
          nodeId,
          totalAmount,
          firebase
        );
      }
      yield fork(
        InvoiceApi.sendEmailOfInvoiceToParent,
        invoiceObject,
        firebase
      );

      /**call to auto charge payment if stripe enabled */
      if (autoChargeEnabled) {
        let response = yield call(
          NotificationApi.callAutoChargePaymentApi,
          invoiceObject,
          firebase
        );
        if (response && response.status === 200) {
          notification("success", "Auto payment successful");
        } else {
          notification("error", "Attempt to auto-pay failed");
        }
      }

      yield fork(
        sendInvoiceNotification,
        "Payment",
        nodeId,
        "Invoice raised for",
        studentData,
        firebase
      );
    }

    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "finance",
      moment()
    );
    yield put({
      type: actions.ADD_NEW_INVOICE_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to add invoice", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* updateSelectedInvoice({
  raisedOn,
  billingPeriod,
  dueDate,
  schoolNote,
  rows,
  totalAmount,
  studentId,
  invoiceId,
  firebase,
  selectedFeeTemplates,
  subTotal,
  taxDescription,
  tax,
}) {
  try {
    let receivedDueDate;
    if (dueDate) {
      receivedDueDate = new Date(dueDate).setHours(0, 0, 0, 0);
    } else {
      let dayDiff = 7;
      if (
        firebase &&
        firebase.schoolConfig &&
        firebase.schoolConfig.dueDateDayDifference !== undefined
      ) {
        dayDiff = firebase.schoolConfig.dueDateDayDifference;
      }

      var date = new Date();
      var modDate = date.setTime(
        date.getTime() + dayDiff * 24 * 60 * 60 * 1000
      );
      receivedDueDate = new Date(modDate).setHours(0, 0, 0, 0);
    }

    let modRaisedDate = new Date(raisedOn).setHours(0, 0, 0, 0);

    let existingInvoice = yield call(
      InvoiceApi.getInvoiceById,
      studentId,
      invoiceId,
      firebase
    );
    let newTotalAmount = totalAmount - existingInvoice.total;

    var newLineItems = [];
    rows.map((item) => {
      newLineItems.push({
        afterDiscountValue: Number(item.netValue),
        amount: Number(item.grossValue),
        description: item.description,
        discountType:
          item.discountType === "Percentage" ? "PERCENTAGE" : "NUMBER",
        discountValue: Number(item.discount),
        timestamp: 0,
      });
    });

    var newSelectedFeeTemplates = [];
    if (selectedFeeTemplates && selectedFeeTemplates.length > 0) {
      selectedFeeTemplates.map((newItem) => {
        newSelectedFeeTemplates.push({
          name: newItem.name,
          id: newItem.id,
        });
      });
    }

    existingInvoice.billingPeriod = billingPeriod
      ? moment(new Date(billingPeriod[0])).format("DD[ ]MMMM[ ]YYYY") +
        " - " +
        moment(new Date(billingPeriod[1])).format("DD[ ]MMMM[ ]YYYY")
      : null;
    existingInvoice.lineItems = newLineItems;
    existingInvoice.dueDate = new Date(receivedDueDate).getTime();
    existingInvoice.endPeriod = billingPeriod
      ? new Date(billingPeriod[1]).getTime()
      : 0;
    existingInvoice.invoiceRaiseDate = new Date(modRaisedDate).getTime();
    existingInvoice.paid = 0;
    existingInvoice.pending = Number(totalAmount);
    existingInvoice.startPeriod = billingPeriod
      ? new Date(billingPeriod[0]).getTime()
      : 0;
    existingInvoice.total = Number(totalAmount);
    existingInvoice.schoolNote = schoolNote;
    existingInvoice.platform = "web";
    existingInvoice.updatedBy = firebase.teacher.name;
    existingInvoice.updatedOn = new Date().getTime();
    existingInvoice.totalWithoutTax = subTotal ? Number(subTotal) : null;
    existingInvoice.taxDescription = taxDescription ? taxDescription : null;
    existingInvoice.taxRate = tax ? Number(tax) : null;
    if (newSelectedFeeTemplates.length > 0) {
      existingInvoice.feeTemplateList = newSelectedFeeTemplates;
    }

    yield call(
      InvoiceApi.updateInvoice,
      existingInvoice,
      studentId,
      invoiceId,
      firebase
    );
    let aggregatedInvoice = yield call(
      InvoiceApi.getAggregatedInvoice,
      studentId,
      firebase
    );
    if (aggregatedInvoice) {
      yield call(
        InvoiceApi.updatedAmountToAggregatedInvoice,
        aggregatedInvoice,
        studentId,
        newTotalAmount,
        firebase,
        "addInvoice",
        receivePayment
      );
    }
    yield fork(
      InvoiceApi.sendEmailOfInvoiceToParent,
      existingInvoice,
      firebase
    );

    yield put({
      type: actions.UPDATE_SELECTED_INVOICE_SUCCESSFFUL,
    });
  } catch (err) {
    console.log("failed to update invoice", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* receivePayment({
  values,
  selectedInvoice,
  firebase,
  originalSelectedInvoice,
  creditRequested,
  creditAmountUsed,
}) {
  try {
    let newPaymentRecord = [];
    if (selectedInvoice.paymentRecords) {
      newPaymentRecord = selectedInvoice.paymentRecords;
    }

    let transactionId = yield call(
      InvoiceApi.createTransactionUniqueId,
      firebase
    );

    if (Number(values.amount) > 0) {
      newPaymentRecord.push({
        afterDiscountValue: 0,
        amount: Number(values.amount),
        description: "Paid by " + values.mode + " on",
        discountValue: 0,
        mode: values.mode,
        timestamp: new Date(values.paymentDate).getTime(),
        referenceNumber: values.referenceNumber ? values.referenceNumber : null,
        transactionId: transactionId,
      });
    }

    let transactionId2 = yield call(
      InvoiceApi.createTransactionUniqueId,
      firebase
    );
    if (creditRequested === true) {
      newPaymentRecord.push({
        afterDiscountValue: 0,
        amount: Number(creditAmountUsed),
        description: "Paid by school's credit amount on",
        discountValue: 0,
        mode: "School credit amount",
        timestamp: new Date(values.paymentDate).getTime(),
        referenceNumber: values.referenceNumber ? values.referenceNumber : null,
        transactionId: transactionId2,
      });
    }

    //for get ready
    selectedInvoice.name = null;
    selectedInvoice.address = null;
    selectedInvoice.postalCode = null;
    selectedInvoice.city = null;
    selectedInvoice.state = null;
    selectedInvoice.country = null;

    selectedInvoice.paid = Number(
      (selectedInvoice.paid ? selectedInvoice.paid : 0) + values.amount
    );
    if (creditRequested) {
      selectedInvoice.paid = Number(
        (selectedInvoice.paid ? selectedInvoice.paid : 0) +
          Number(creditAmountUsed)
      );
    }
    selectedInvoice.pending = Number(
      (originalSelectedInvoice.pending ? originalSelectedInvoice.pending : 0) -
        values.amount
    );
    if (creditRequested) {
      let totalAmountToDeduct = Number(values.amount + creditAmountUsed);
      selectedInvoice.pending = Number(
        (originalSelectedInvoice.pending
          ? originalSelectedInvoice.pending
          : 0) - Number(totalAmountToDeduct)
      );
    }
    selectedInvoice.paymentRecords = newPaymentRecord;
    selectedInvoice.platform = "web";
    selectedInvoice.updatedBy = firebase.teacher.name;
    selectedInvoice.updatedOn = new Date().getTime();

    yield call(InvoiceApi.recordPayment, selectedInvoice, firebase);
    let aggregatedInvoice = yield call(
      InvoiceApi.getAggregatedInvoice,
      selectedInvoice.studentId,
      firebase
    );
    if (aggregatedInvoice) {
      yield call(
        InvoiceApi.updatedAmountToAggregatedInvoice,
        aggregatedInvoice,
        selectedInvoice.studentId,
        undefined,
        firebase,
        "receivePayment",
        values.amount
      );

      if (creditRequested) {
        let newAggInvoice = yield call(
          InvoiceApi.getAggregatedInvoice,
          selectedInvoice.studentId,
          firebase
        );

        if (newAggInvoice) {
          newAggInvoice.paid = Number(newAggInvoice.paid + creditAmountUsed);
          newAggInvoice.pending = Number(
            newAggInvoice.pending - creditAmountUsed
          );

          newAggInvoice.creditAmt = aggregatedInvoice.creditAmt
            ? Number(aggregatedInvoice.creditAmt) - Number(creditAmountUsed)
            : 0;
          yield fork(
            InvoiceApi.updateAggregatedInvoiceAfterPaymentRecordDeletion,
            selectedInvoice.studentId,
            newAggInvoice,
            firebase
          );

          let creditNodeId = yield call(
            InvoiceApi.generatedCreditNoteId,
            firebase
          );
          let studentObj = {
            name: newAggInvoice.studentName,
            id: newAggInvoice.studentId,
          };
          yield fork(
            InvoiceApi.updateCreditWithdrawnHistory,
            creditNodeId,
            studentObj,
            creditAmountUsed,
            firebase
          );
        }
      }
    }

    var transactionObject = {
      // actualTransferAmount: Number(selectedInvoice.pending) * 100,
      actualTransferAmount: Number(values.amount) * 100,
      amount: Number(values.amount) * 100,
      date: new Date(values.paymentDate).getTime(),
      errorCode: 0,
      id: transactionId,
      invoiceId: selectedInvoice.id,
      paymentMode: values.mode,
      status: "successful",
      studentId: selectedInvoice.studentId,
      userId: firebase.user.id,
    };

    yield fork(
      InvoiceApi.recordTransaction,
      transactionObject,
      transactionId,
      firebase
    );
    yield fork(
      InvoiceApi.sendEmailOfInvoiceReceiptToParent,
      transactionObject,
      firebase
    );

    if (creditRequested) {
      var transactionObject2 = {
        actualTransferAmount: Number(creditAmountUsed) * 100,
        amount: Number(creditAmountUsed) * 100,
        date: new Date(values.paymentDate).getTime(),
        errorCode: 0,
        id: transactionId2,
        invoiceId: selectedInvoice.id,
        paymentMode: "School credit amount",
        status: "successful",
        studentId: selectedInvoice.studentId,
        userId: firebase.user.id,
      };

      yield fork(
        InvoiceApi.recordTransaction,
        transactionObject2,
        transactionId2,
        firebase
      );
      yield fork(
        InvoiceApi.sendEmailOfInvoiceReceiptToParent,
        transactionObject2,
        firebase
      );
    }

    if (firebase.dbName == "GetReadyEdu_Master-Branch") {
      let studentList = JSON.parse(localStorage.getItem("studentList"));
      let student = studentList.filter((std) => {
        return std.id === selectedInvoice.studentId;
      });

      if (student && student.length > 0) {
        let tempStudent = student[0];
        tempStudent.status = "Active";
        tempStudent.updatedBy = firebase.teacher ? firebase.teacher.name : "";
        tempStudent.updatedOn = new Date().getTime();
        yield fork(
          StudentApi.updateStudentWithUpdatedFormFields,
          tempStudent,
          firebase
        );
        callStatusChangeWebHook(tempStudent, firebase, "ENROLLED");
      }
    }

    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "finance",
      moment()
    );
    yield put({
      type: actions.RECEIVE_PAYMENT_SUCCESSFUL,
    });
  } catch (error) {
    console.log("failed to record payment", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* refundPaidAmount({ values, selectedInvoice, firebase }) {
  try {
    let newRefundRecord = [];
    let initialRefund = 0;
    if (selectedInvoice.refundRecords) {
      newRefundRecord = selectedInvoice.refundRecords;
    }

    if (selectedInvoice.totalRefund) {
      initialRefund = selectedInvoice.totalRefund;
    }

    let transactionId = yield call(
      InvoiceApi.createTransactionUniqueId,
      firebase
    );

    newRefundRecord.push({
      afterDiscountValue: 0,
      amount: Number(values.amount),
      description: "Refunded by " + values.mode + " on",
      discountValue: 0,
      mode: values.mode,
      timestamp: new Date(values.paymentDate).getTime(),
      referenceNumber: values.referenceNumber ? values.referenceNumber : null,
      transactionId: transactionId,
    });
    selectedInvoice.refundRecords = newRefundRecord;
    selectedInvoice.totalRefund = initialRefund + Number(values.amount);
    selectedInvoice.platform = "web";
    yield call(InvoiceApi.recordPayment, selectedInvoice, firebase);

    let aggregatedInvoice = yield call(
      InvoiceApi.getAggregatedInvoice,
      selectedInvoice.studentId,
      firebase
    );
    if (aggregatedInvoice) {
      yield call(
        InvoiceApi.updatedAmountToAggregatedInvoice,
        aggregatedInvoice,
        selectedInvoice.studentId,
        undefined,
        firebase,
        "refund",
        values.amount
      );
    }

    var transactionObject = {
      actualTransferAmount: Number(values.amount) * 100,
      amount: Number(values.amount) * 100,
      date: new Date(values.paymentDate).getTime(),
      errorCode: 0,
      id: transactionId,
      invoiceId: selectedInvoice.id,
      paymentMode: values.mode,
      status: "successful",
      studentId: selectedInvoice.studentId,
      userId: firebase.user.id,
    };

    yield fork(
      InvoiceApi.recordTransaction,
      transactionObject,
      transactionId,
      firebase
    );

    yield fork(
      InvoiceApi.sendEmailOfInvoiceReceiptToParent,
      transactionObject,
      firebase
    );

    yield put({
      type: actions.REFUND_AMT_SUCCESS,
    });
  } catch (err) {
    console.log("failed to refund payment", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* deleteSelectedInvoice({ invoiceRecord, firebase }) {
  try {
    let tempRecord = JSON.parse(JSON.stringify(invoiceRecord));
    yield call(
      InvoiceApi.deleteInvoice,
      invoiceRecord.studentId,
      invoiceRecord.id,
      firebase
    );
    let aggregatedInvoice = yield call(
      InvoiceApi.getAggregatedInvoice,
      tempRecord.studentId,
      firebase
    );
    if (aggregatedInvoice) {
      yield call(
        InvoiceApi.updatedAmountToAggregatedInvoice,
        aggregatedInvoice,
        tempRecord.studentId,
        tempRecord.total,
        firebase,
        "deleteInvoice",
        tempRecord.paid ? tempRecord.paid : 0
      );
    }
    yield put({
      type: actions.DELETE_INVOICE_SUCCESSFFUL,
    });
  } catch (err) {
    console.log("failed to delete invoice", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* fetchOneTimeInvoiceTemplates({ firebase }) {
  try {
    let data = yield call(
      InvoiceTemplateApi.getOneTimeInvoiceTemplate,
      firebase
    );
    if (data) {
      yield put({
        type: actions.GET_INVOICE_TEMPLATES_SUCCESSFUL,
        invoiceTemplates: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch one time invoice templates", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* fetchAndDownloadExcelSheet({ formValue, firebase }) {
  try {
    let data = yield call(
      InvoiceApi.getInvoiceByDateRange,
      formValue.dateRange,
      firebase
    );
    if (data) {
      const fields = [
        "invoiceId",
        "invoiceRaiseDate",
        "invoiceRaisedBy",
        "dueDate",
        "billingPeriod",
        "className",
        "studentName",
        "admissionNumber",
        "invoiceNumber",
        "total",
        "mode",
        "receiptNumber",
        "paymentDate",
        "paymentAmount",
        "paymentReferenceNo",
        "pending",
        "schoolNote",
        "refundAmount",
        "refundMode",
        "refundDate",
        "refundReferenceNo",
      ];
      const opts = { fields };
      let invoices = [];
      let timezone = moment.tz.guess();
      let studentList = JSON.parse(localStorage.getItem("studentList"));
      data.forEach((i) => {
        var student = studentList.filter((student) => {
          if (student.id === i.studentId) {
            return student;
          }
        });

        var paymentRecords = i.paymentRecords ? i.paymentRecords : undefined;
        if (paymentRecords) {
          paymentRecords.forEach(function(record) {
            var row = {};
            row.invoiceId = "inv" + i.id;
            if (i.invoiceRaiseDate) {
              row.invoiceRaiseDate = moment
                .tz(i.invoiceRaiseDate, timezone)
                .format("DD-MM-YY");
            }
            if (i.dueDate) {
              row.dueDate = moment.tz(i.dueDate, timezone).format("DD-MM-YY");
            }
            row.className = i.classList ? i.classList.toString() : i.classname;
            row.studentName = i.studentName;
            row.total = i.total;
            row.mode = record.mode;
            row.paymentReferenceNo = record.referenceNumber
              ? record.referenceNumber
              : "";
            row.paymentDate = moment
              .tz(record.timestamp, timezone)
              .format("DD-MM-YY");
            row.paymentAmount = record.amount;
            row.pending = i.pending;
            row.receiptNumber = record.receiptNumber
              ? record.receiptNumber
              : "";
            row.invoiceNumber = i.invoiceNumber;
            row.admissionNumber =
              student[0] && student[0].admissionNumber
                ? student[0].admissionNumber
                : "";
            row.billingPeriod = i.billingPeriod;
            row.invoiceRaisedBy = i.updatedBy
              ? i.updatedBy
              : i.feePlanId
              ? "Fee Plan"
              : i.templateId
              ? "Fee Template"
              : "";
            invoices.push(row);
          });

          if (i.refundRecords) {
            let refundRecords = i.refundRecords;
            refundRecords.forEach(function(r) {
              var row = {};
              row.invoiceId = "inv" + i.id;
              if (i.invoiceRaiseDate) {
                row.invoiceRaiseDate = moment
                  .tz(i.invoiceRaiseDate, timezone)
                  .format("DD-MM-YY");
              }
              if (i.dueDate) {
                row.dueDate = moment.tz(i.dueDate, timezone).format("DD-MM-YY");
              }
              row.className = i.classname;
              row.studentName = i.studentName;
              row.total = i.total;
              row.pending = i.pending;
              row.refundReferenceNo = r.referenceNumber
                ? r.referenceNumber
                : "";
              row.receiptNumber = i.receiptNumber;
              row.invoiceRaisedBy = i.updatedBy
                ? i.updatedBy
                : i.feePlanId
                ? "Fee Plan"
                : i.templateId
                ? "Fee Template"
                : "";
              row.refundAmount = r.amount;
              row.refundMode = r.mode;
              row.refundDate = moment
                .tz(r.timestamp, timezone)
                .format("DD-MM-YY");

              invoices.push(row);
            });
          }
        } else {
          var row = {};
          row.invoiceId = "inv" + i.id;
          if (i.invoiceRaiseDate)
            row.invoiceRaiseDate = moment
              .tz(i.invoiceRaiseDate, timezone)
              .format("DD-MM-YY");
          if (i.dueDate) {
            row.dueDate = moment.tz(i.dueDate, timezone).format("DD-MM-YY");
          }
          row.schoolNote = i.schoolNote;
          row.invoiceNumber = i.invoiceNumber;
          row.billingPeriod = i.billingPeriod;
          row.admissionNumber =
            student[0] && student[0].admissionNumber
              ? student[0].admissionNumber
              : "";
          row.className = i.classList ? i.classList.toString() : i.classname;
          row.studentName = i.studentName;
          row.total = i.total;
          row.pending = i.pending;
          row.receiptNumber = "N/A";
          row.mode = "N/A";
          row.paymentDate = "N/A";
          row.paymentAmount = "N/A";
          row.invoiceRaisedBy = i.updatedBy
            ? i.updatedBy
            : i.feePlanId
            ? "Fee Plan"
            : i.templateId
            ? "Fee Template"
            : "";
          invoices.push(row);
        }
      });

      // if (i.refundRecords) {
      //   let refundRecords = i.refundRecords;
      //   refundRecords.forEach(function (r) {
      //     row.refundAmount = r.amount;
      //     row.refundMode = r.mode;
      //     row.refundDate = moment.tz(r.timestamp, timezone).format("DD-MM-YY")
      //   })
      // }

      try {
        const parser = new Parser(opts);
        const csv = parser.parse(invoices);
        console.log(csv);

        var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        var csvURL = window.URL.createObjectURL(csvData);
        var tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", "invoices.csv");
        tempLink.click();
      } catch (err) {
        console.error("failed to parse csv", err);
      }
    }
    yield put({
      type: actions.DOWNLOAD_INVOICE_EXCEL_SHEET_SUCCESSFUL,
    });
  } catch (err) {
    notification("error", "failed to fetch invoice for date range");
    console.log("failed to fetch invoice for date range", err);
    bugsnagClient.notify(
      "failed to fetch invoice for date range" + err.message ? err.message : err
    );
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* sendFeePaymentReminder({ email, sms, invoice, firebase }) {
  try {
    var currency = firebase.schoolConfig.currency
      ? firebase.schoolConfig.currency
      : "Rs. ";
    let message =
      firebase.schoolName +
      " reminder. Payment of amount " +
      currency +
      ". " +
      invoice.pending +
      " is due.";
    if (firebase.schoolConfig.lateFee !== 0) {
      let lateFeeMessage =
        "Late fee of " +
        currency +
        ". " +
        firebase.schoolConfig.lateFee +
        " is applicable on late payments.";
      message = message + lateFeeMessage;
    }
    let addMessage =
      "Please install the app for latest update and payment history.";
    if (sms === true) {
      let contact = [];
      if (invoice.fatherNumber) {
        contact.push(invoice.fatherNumber);
      }

      if (invoice.motherNumber) {
        contact.push(invoice.motherNumber);
      }

      if (contact.length > 0) {
        yield fork(NotificationApi.sendReminderMessage, contact, message);
      }
    }

    if (email === true) {
      let parentEmails = [];

      if (invoice.fatherEmail) {
        parentEmails.push(invoice.fatherEmail);
      }

      if (invoice.motherEmail) {
        parentEmails.push(invoice.motherEmail);
      }

      var htmlBody = `<div><p>${message}</p><p>${addMessage}</p></div>`;
      yield fork(
        NotificationApi.sendActivityEmails,
        htmlBody,
        firebase.schoolName + " payment reminder.",
        parentEmails,
        firebase
      );
    }

    let updatedInvoice = JSON.parse(JSON.stringify(invoice));
    let reminders = [];

    let obj = {
      name: firebase.teacher.name,
      timestamp: new Date().getTime(),
    };

    if (
      updatedInvoice.lastReminderSent &&
      updatedInvoice.lastReminderSent.length > 0
    ) {
      reminders = updatedInvoice.lastReminderSent;
      // reminders.unshift(new Date().getTime());
      reminders.unshift(obj);
    } else {
      // reminders.push(new Date().getTime());
      reminders.push(obj);
    }
    updatedInvoice.lastReminderSent = reminders;
    yield call(
      InvoiceApi.updateInvoice,
      updatedInvoice,
      invoice.studentId,
      invoice.id,
      firebase
    );
    yield put({
      type: actions.SEND_PAYMENT_REMINDER_SUCCESSFUL,
    });

    let allStudents = JSON.parse(localStorage.getItem("studentList"));
    let studentId = invoice.studentId;
    let filteredStudent = allStudents.filter((s) => {
      return s.id === studentId;
    });

    if (filteredStudent && filteredStudent.length > 0) {
      yield fork(
        sendInvoiceNotification,
        "Payment",
        invoice.id,
        "Payment reminder for",
        filteredStudent[0],
        firebase
      );
    }
  } catch (err) {
    console.log("failed to send fee payment reminder", err);
    bugsnagClient.notify(
      "failed to send fee payment reminder" + err.message ? err.message : err
    );
  }
}

function* sendInvoiceNotification(
  activityN,
  node,
  upcomingMessage,
  selectedStudent,
  firebase
) {
  let selectedActivity = activityN;
  let activityId = node;
  let message = upcomingMessage + " " + selectedStudent.name;

  try {
    if (selectedStudent.fatherProfileId) {
      let alertNode = yield call(
        NotificationApi.createAlertReferenceNode,
        selectedStudent.fatherProfileId,
        firebase
      );
      yield fork(
        NotificationApi.createAlertNotification,
        selectedActivity,
        activityId,
        selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
        message,
        alertNode,
        selectedStudent.ios_fatherUUid ? selectedStudent.ios_fatherUUid : null,
        selectedStudent.id,
        selectedStudent.fatherProfileId,
        firebase
      );

      if (
        selectedStudent.fatherUUid !== undefined ||
        selectedStudent.ios_fatherUUid !== undefined
      ) {
        yield fork(
          NotificationApi.sendPushNotification,
          selectedActivity,
          activityId,
          selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
          message,
          alertNode,
          selectedStudent.ios_fatherUUid
            ? selectedStudent.ios_fatherUUid
            : null,
          selectedStudent.id,
          selectedStudent.fatherProfileId,
          firebase
        );
      }
    }

    if (selectedStudent.motherProfileId) {
      let alertNode = yield call(
        NotificationApi.createAlertReferenceNode,
        selectedStudent.motherProfileId,
        firebase
      );
      yield fork(
        NotificationApi.createAlertNotification,
        selectedActivity,
        activityId,
        selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
        message,
        alertNode,
        selectedStudent.ios_motherUUid ? selectedStudent.ios_motherUUid : null,
        selectedStudent.id,
        selectedStudent.motherProfileId,
        firebase
      );

      if (
        selectedStudent.motherUUid !== undefined ||
        selectedStudent.ios_motherUUid !== undefined
      ) {
        yield fork(
          NotificationApi.sendPushNotification,
          selectedActivity,
          activityId,
          selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
          message,
          alertNode,
          selectedStudent.ios_motherUUid
            ? selectedStudent.ios_motherUUid
            : null,
          selectedStudent.id,
          selectedStudent.motherProfileId,
          firebase
        );
      }
    }
  } catch (err) {
    bugsnagClient.notify(
      "failed to send invoice notification" + err.message ? err.message : err
    );
  }
}

function* deleteSelectedPaymentRecord({ lineItem, invoice, firebase }) {
  try {
    let selectedInvoice = invoice;
    selectedInvoice.paid = selectedInvoice.paid - Number(lineItem.amount);
    selectedInvoice.pending = selectedInvoice.pending + Number(lineItem.amount);
    selectedInvoice.platform = "web";

    let prevPaymentRecord = selectedInvoice.paymentRecords;
    let filteredPaymentRecord = prevPaymentRecord.filter((item) => {
      return (
        item.amount !== lineItem.amount && item.timestamp !== lineItem.timestamp
      );
    });
    selectedInvoice.paymentRecords = filteredPaymentRecord;
    yield call(
      InvoiceApi.updateInvoice,
      selectedInvoice,
      selectedInvoice.studentId,
      selectedInvoice.id,
      firebase
    );

    let aggregatedInvoice = yield call(
      InvoiceApi.getAggregatedInvoice,
      selectedInvoice.studentId,
      firebase
    );

    if (aggregatedInvoice) {
      let aggInv = aggregatedInvoice;
      aggInv.paid = aggInv.paid - Number(lineItem.amount);
      aggInv.pending = aggInv.pending + Number(lineItem.amount);

      yield call(
        InvoiceApi.updateAggregatedInvoiceAfterPaymentRecordDeletion,
        selectedInvoice.studentId,
        aggInv,
        firebase
      );
    }

    if (prevPaymentRecord.length > 0 && prevPaymentRecord[0].transactionId) {
      yield call(
        InvoiceApi.deleteTransactionRecord,
        prevPaymentRecord[0].transactionId,
        firebase
      );
    }

    yield put({
      type: actions.DELETE_PAYMENT_RECORD_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to delete payment record", err);
    bugsnagClient.notify(err);
  }
}

function getStudentListArr() {
  let students = JSON.parse(localStorage.getItem("studentList"));
  let studentIds = [];
  if (students && students.length > 0) {
    students.forEach((std) => {
      studentIds.push(std.id);
    });
  }
  return studentIds;
}

function* fetchStudentAggregatedInvoice({
  startDate,
  endDate,
  firebase,
  initialCall,
}) {
  try {
    let studentIds = getStudentListArr();
    const chan = yield call(InvoiceApi.getStudentAggregatedInvoices, firebase);
    while (true) {
      let data = yield take(chan);

      if (startDate && endDate) {
        let aggMap = new Map();
        let finalAggregatedInvoice = [];
        let invoiceTask = [];
        for (let i in data) {
          let studentId = data[i].studentId;
          if (studentIds.includes(studentId)) {
            let task = call(
              InvoiceApi.getInvoiceByStudentId,
              startDate,
              endDate,
              studentId,
              firebase
            );
            invoiceTask.push(task);

            let aggData = {};
            aggData.studentId = studentId;
            aggData.studentName = data[i].studentName;
            aggData.classname = data[i].classname;
            aggData.classList = data[i].classList ? data[i].classList : [];
            aggData.gender = data[i].gender;
            aggData.fatherNumber = data[i].fatherNumber;
            aggData.motherNumber = data[i].motherNumber;
            aggData.pending = 0;
            aggData.paid = 0;
            aggData.total = 0;
            aggData.creditAmt = data[i].creditAmt
              ? data[i].creditAmt
              : undefined;
            aggMap.set(studentId, aggData);
          }
        }

        let newVal = yield all([invoiceTask]);

        for (let i in newVal[0]) {
          let invoices = newVal[0][i];
          if (invoices && invoices.length > 0) {
            let paid = 0;
            let pending = 0;
            let total = 0;
            let totalCreditAmt = 0;
            if (aggMap.has(invoices[0].studentId)) {
              let aggVal = aggMap.get(invoices[0].studentId);
              if (aggVal.creditAmt) {
                totalCreditAmt = aggVal.creditAmt;
              }
            }
            for (let ind in invoices) {
              paid =
                paid +
                (invoices[ind].paid && isNaN(invoices[ind].paid) === false
                  ? invoices[ind].paid
                  : 0);
              pending =
                pending +
                (isNaN(invoices[ind].pending) === false
                  ? invoices[ind].pending
                  : 0);
              total =
                total +
                (isNaN(invoices[ind].total) === false
                  ? invoices[ind].total
                  : 0);
            }

            var myData = {};
            myData.studentId = invoices[0].studentId;
            myData.studentName = invoices[0].studentName;
            myData.classname = invoices[0].classname;
            myData.classList = invoices[0].classList
              ? invoices[0].classList
              : [];
            myData.gender = invoices[0].gender;
            myData.fatherNumber = invoices[0].fatherNumber;
            myData.motherNumber = invoices[0].motherNumber;
            myData.pending = pending;
            myData.paid = paid;
            myData.total = total;
            myData.creditAmt = totalCreditAmt;
            finalAggregatedInvoice.push(myData);

            aggMap.set(invoices[0].studentId, undefined);
          }
        }

        for (let [key, value] of aggMap) {
          if (value) {
            finalAggregatedInvoice.push(value);
          }
        }

        yield put({
          type: actions.GET_STUDENT_AGGREAGTED_INVOICES_SUCCESS,
          studentAggregatedInvoice: finalAggregatedInvoice,
          studentAggregatedInvoiceChan: chan,
          operationType: initialCall,
        });
      } else {
        yield put({
          type: actions.GET_STUDENT_AGGREAGTED_INVOICES_SUCCESS,
          studentAggregatedInvoice: data,
          studentAggregatedInvoiceChan: chan,
          operationType: initialCall,
        });
      }
    }
  } finally {
    console.log("terminating student billing aggregated invoices");
  }
}

// function* fetchStudentAggregatedInvoice({
//   startDate,
//   endDate,
//   firebase,
//   initialCall,
// }) {
//   try {
//     const chan = yield call(InvoiceApi.getStudentAggregatedInvoices, firebase);
//     while (true) {
//       let data = yield take(chan);

//       if (startDate && endDate) {
//         let aggMap = new Map();
//         let finalAggregatedInvoice = [];
//         let invoiceTask = [];
//         for (let i in data) {
//           let studentId = data[i].studentId;
//           let task = call(
//             InvoiceApi.getInvoiceByStudentId,
//             startDate,
//             endDate,
//             studentId,
//             firebase
//           );
//           invoiceTask.push(task);

//           let aggData = {};
//           aggData.studentId = studentId;
//           aggData.studentName = data[i].studentName;
//           aggData.classname = data[i].classname;
//           aggData.gender = data[i].gender;
//           aggData.fatherNumber = data[i].fatherNumber;
//           aggData.motherNumber = data[i].motherNumber;
//           aggData.pending = 0;
//           aggData.paid = 0;
//           aggData.total = 0;
//           aggData.creditAmt = data[i].creditAmt ? data[i].creditAmt : undefined;
//           aggMap.set(studentId, aggData);
//         }

//         let newVal = yield all([invoiceTask]);

//         for (let i in newVal[0]) {
//           let invoices = newVal[0][i];
//           if (invoices && invoices.length > 0) {
//             let paid = 0;
//             let pending = 0;
//             let total = 0;
//             let totalCreditAmt = 0;
//             if (aggMap.has(invoices[0].studentId)) {
//               let aggVal = aggMap.get(invoices[0].studentId);
//               if (aggVal.creditAmt) {
//                 totalCreditAmt = aggVal.creditAmt;
//               }
//             }
//             for (let ind in invoices) {
//               paid =
//                 paid +
//                 (invoices[ind].paid && isNaN(invoices[ind].paid) === false
//                   ? invoices[ind].paid
//                   : 0);
//               pending =
//                 pending +
//                 (isNaN(invoices[ind].pending) === false
//                   ? invoices[ind].pending
//                   : 0);
//               total =
//                 total +
//                 (isNaN(invoices[ind].total) === false
//                   ? invoices[ind].total
//                   : 0);
//             }

//             var myData = {};
//             myData.studentId = invoices[0].studentId;
//             myData.studentName = invoices[0].studentName;
//             myData.classname = invoices[0].classname;
//             myData.gender = invoices[0].gender;
//             myData.fatherNumber = invoices[0].fatherNumber;
//             myData.motherNumber = invoices[0].motherNumber;
//             myData.pending = pending;
//             myData.paid = paid;
//             myData.total = total;
//             myData.creditAmt = totalCreditAmt;
//             finalAggregatedInvoice.push(myData);

//             aggMap.set(invoices[0].studentId, undefined);
//           }
//         }

//         for (let [key, value] of aggMap) {
//           if (value) {
//             finalAggregatedInvoice.push(value);
//           }
//         }

//         yield put({
//           type: actions.GET_STUDENT_AGGREAGTED_INVOICES_SUCCESS,
//           studentAggregatedInvoice: finalAggregatedInvoice,
//           studentAggregatedInvoiceChan: chan,
//           operationType: initialCall,
//         });
//       } else {
//         yield put({
//           type: actions.GET_STUDENT_AGGREAGTED_INVOICES_SUCCESS,
//           studentAggregatedInvoice: data,
//           studentAggregatedInvoiceChan: chan,
//           operationType: initialCall,
//         });
//       }
//     }
//   } finally {
//     console.log("terminating student billing aggregated invoices");
//   }
// }

function* fetchSingleStudentInvoices({ studentId, firebase }) {
  try {
    yield fork(fetchTaxRef, firebase);
    let data = yield call(
      InvoiceApi.getInvoiceByStudentId,
      undefined,
      undefined,
      studentId,
      firebase
    );
    if (data) {
      yield fork(addLateFee, data, firebase, "checkIgnoreBoolean");
      yield put({
        type: actions.GET_SINGLE_STUDENT_INVOICES_SUCCESS,
        invoice: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch invoices for a single student", err);
    bugsnagClient.notify(
      "failed to fetch invoices for a single student" + err.message
        ? err.message
        : err
    );
  }
}

function* addLateFee(invoices, firebase, checkIgnoreBoolean) {
  try {
    for (let index in invoices) {
      let selectedInvoice = invoices[index];
      let startTime = new Date().setHours(0, 0, 0, 0);
      if (
        selectedInvoice.pending > 0 &&
        selectedInvoice.dueDate < startTime &&
        firebase.schoolConfig &&
        firebase.schoolConfig.lateFee &&
        checkIgnoreBoolean &&
        !selectedInvoice.ignoreLateFee
      ) {
        let prevLineItems = [];
        if (selectedInvoice.lineItems) {
          prevLineItems = selectedInvoice.lineItems;
        }

        let ifLateFeeIncluded = prevLineItems.filter((f) => {
          return f.description.toLowerCase() === "late fee";
        });

        if (ifLateFeeIncluded.length === 0) {
          selectedInvoice.ignoreLateFee = false;
          selectedInvoice.lateFee = Number(firebase.schoolConfig.lateFee);
          selectedInvoice.pending =
            selectedInvoice.pending + Number(firebase.schoolConfig.lateFee);
          selectedInvoice.total =
            selectedInvoice.total + Number(firebase.schoolConfig.lateFee);

          prevLineItems.push({
            afterDiscountValue: Number(firebase.schoolConfig.lateFee),
            amount: Number(firebase.schoolConfig.lateFee),
            description: "Late Fee",
            discountValue: 0,
            timestamp: 0,
          });

          selectedInvoice.lineItems = prevLineItems;
          yield call(
            InvoiceApi.updateInvoice,
            selectedInvoice,
            selectedInvoice.studentId,
            selectedInvoice.id,
            firebase
          );

          let aggregatedInvoice = yield call(
            InvoiceApi.getAggregatedInvoice,
            selectedInvoice.studentId,
            firebase
          );
          if (aggregatedInvoice) {
            let prevAggregatedInvoice = aggregatedInvoice;
            prevAggregatedInvoice.total =
              aggregatedInvoice.total + Number(firebase.schoolConfig.lateFee);
            prevAggregatedInvoice.pending =
              aggregatedInvoice.pending + Number(firebase.schoolConfig.lateFee);
            yield fork(
              InvoiceApi.updateAggregatedInvoiceAfterPaymentRecordDeletion,
              selectedInvoice.studentId,
              prevAggregatedInvoice,
              firebase
            );
          }

          let allStudents = JSON.parse(localStorage.getItem("studentList"));
          let studentId = selectedInvoice.studentId;
          let filteredStudent = allStudents.filter((s) => {
            return s.id === studentId;
          });

          if (filteredStudent && filteredStudent.length > 0) {
            yield fork(
              sendInvoiceNotification,
              "Payment",
              selectedInvoice.id,
              "Invoice raised for",
              filteredStudent[0],
              firebase
            );
          }
        }
      } else if (
        selectedInvoice.pending > 0 &&
        selectedInvoice.dueDate < startTime &&
        firebase.schoolConfig &&
        firebase.schoolConfig.lateFee
      ) {
        let prevLineItems = [];
        if (selectedInvoice.lineItems) {
          prevLineItems = selectedInvoice.lineItems;
        }

        let ifLateFeeIncluded = prevLineItems.filter((f) => {
          return f.description.toLowerCase() === "late fee";
        });

        if (ifLateFeeIncluded.length === 0) {
          selectedInvoice.ignoreLateFee = false;
          selectedInvoice.lateFee = Number(firebase.schoolConfig.lateFee);
          selectedInvoice.pending =
            selectedInvoice.pending + Number(firebase.schoolConfig.lateFee);
          selectedInvoice.total =
            selectedInvoice.total + Number(firebase.schoolConfig.lateFee);

          prevLineItems.push({
            afterDiscountValue: Number(firebase.schoolConfig.lateFee),
            amount: Number(firebase.schoolConfig.lateFee),
            description: "Late Fee",
            discountValue: 0,
            timestamp: 0,
          });

          selectedInvoice.lineItems = prevLineItems;
          yield call(
            InvoiceApi.updateInvoice,
            selectedInvoice,
            selectedInvoice.studentId,
            selectedInvoice.id,
            firebase
          );

          let aggregatedInvoice = yield call(
            InvoiceApi.getAggregatedInvoice,
            selectedInvoice.studentId,
            firebase
          );
          if (aggregatedInvoice) {
            let prevAggregatedInvoice = aggregatedInvoice;
            prevAggregatedInvoice.total =
              aggregatedInvoice.total + Number(firebase.schoolConfig.lateFee);
            prevAggregatedInvoice.pending =
              aggregatedInvoice.pending + Number(firebase.schoolConfig.lateFee);
            yield fork(
              InvoiceApi.updateAggregatedInvoiceAfterPaymentRecordDeletion,
              selectedInvoice.studentId,
              prevAggregatedInvoice,
              firebase
            );
          }

          let allStudents = JSON.parse(localStorage.getItem("studentList"));
          let studentId = selectedInvoice.studentId;
          let filteredStudent = allStudents.filter((s) => {
            return s.id === studentId;
          });

          if (filteredStudent && filteredStudent.length > 0) {
            yield fork(
              sendInvoiceNotification,
              "Payment",
              selectedInvoice.id,
              "Invoice raised for",
              filteredStudent[0],
              firebase
            );
          }
        }
      }
    }

    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "finance",
      moment()
    );
  } catch (err) {
    console.log("failed to add late fee to invoices", err);
    bugsnagClient.notify(
      "failed to add late fee to invoices" + err.message ? err.message : err
    );
  }
}

function* fetchSingleStudentAggregatedInvoice({ studentId, firebase }) {
  try {
    let aggregatedInvoice = yield call(
      InvoiceApi.getAggregatedInvoice,
      studentId,
      firebase
    );

    if (aggregatedInvoice) {
      let data = [];
      data.push(aggregatedInvoice);
      yield put({
        type: actions.GET_SINGLE_STUDENT_AGGREGATED_INVOICE_SUCCESS,
        aggregatedInvoice: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch aggregated invoice for a single student", err);
    bugsnagClient.notify(
      "failed to  fetch aggregated invoice for a single student" + err.message
        ? err.message
        : err
    );
  }
}

function* ignoreInvoiceLateFee({ selectedInvoice, firebase }) {
  try {
    let invoice = selectedInvoice;
    if (invoice.ignoreLateFee) {
      yield call(addLateFee, [invoice], firebase);
      yield put({
        type: actions.IGNORE_LATE_FEE_SUCCESS,
      });
    } else if (!invoice.ignoreLateFee) {
      yield call(removeLateFee, invoice, firebase);
      yield put({
        type: actions.IGNORE_LATE_FEE_SUCCESS,
      });
    }
  } catch (err) {
    console.log("failed to ignore late fee", err);
    bugsnagClient.notify(
      "failed to ignore late fee" + err.message ? err.message : err
    );
  }
}

function* removeLateFee(invoice, firebase) {
  try {
    let selectedInvoice = invoice;

    selectedInvoice.ignoreLateFee = true;
    selectedInvoice.pending = invoice.pending - Number(invoice.lateFee);
    selectedInvoice.total = invoice.total - Number(invoice.lateFee);

    let prevLineItems = [];
    if (invoice.lineItems) {
      prevLineItems = invoice.lineItems;
    }

    let newLineItems = prevLineItems.filter((l) => {
      return (
        l.description.toLowerCase() !== "late fee" &&
        l.amount !== invoice.lateFee
      );
    });

    selectedInvoice.lineItems = newLineItems;
    yield call(
      InvoiceApi.updateInvoice,
      selectedInvoice,
      selectedInvoice.studentId,
      selectedInvoice.id,
      firebase
    );

    let aggregatedInvoice = yield call(
      InvoiceApi.getAggregatedInvoice,
      selectedInvoice.studentId,
      firebase
    );
    if (aggregatedInvoice) {
      let prevAggregatedInvoice = aggregatedInvoice;
      prevAggregatedInvoice.total =
        aggregatedInvoice.total - Number(invoice.lateFee);
      prevAggregatedInvoice.pending =
        aggregatedInvoice.pending - Number(invoice.lateFee);
      yield fork(
        InvoiceApi.updateAggregatedInvoiceAfterPaymentRecordDeletion,
        selectedInvoice.studentId,
        prevAggregatedInvoice,
        firebase
      );

      let allStudents = JSON.parse(localStorage.getItem("studentList"));
      let studentId = selectedInvoice.studentId;
      let filteredStudent = allStudents.filter((s) => {
        return s.id === studentId;
      });

      if (filteredStudent && filteredStudent.length > 0) {
        yield fork(
          sendInvoiceNotification,
          "Payment",
          selectedInvoice.id,
          "Invoice raised for",
          filteredStudent[0],
          firebase
        );
      }
    }

    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "finance",
      moment()
    );
  } catch (err) {
    console.log("failed to update -> ignore late fee", err);
    bugsnagClient.notify(
      "failed to update -> ignore late fee" + err.message ? err.message : err
    );
  }
}

function* removeRefundedAmt({ record, index, invoice, firebase }) {
  try {
    let selectedInvoice = invoice;
    selectedInvoice.platform = "web";

    let prevRecord = selectedInvoice.refundRecords;
    const currIndex = prevRecord.indexOf(record);
    let filteredPaymentRecord = prevRecord.filter((item, i) => {
      return item.timestamp !== record.timestamp && i !== currIndex;
    });

    selectedInvoice.refundRecords = filteredPaymentRecord;
    selectedInvoice.totalRefund =
      invoice.totalRefund > 0 ? invoice.totalRefund - record.amount : 0;

    yield call(
      InvoiceApi.updateInvoice,
      selectedInvoice,
      invoice.studentId,
      invoice.id,
      firebase
    );

    let transactionId;
    if (
      selectedInvoice.refundRecords &&
      selectedInvoice.refundRecords.length > 0 &&
      selectedInvoice.refundRecords[0].transactionId
    ) {
      transactionId = selectedInvoice.refundRecords[0].transactionId;
    } else if (
      selectedInvoice.paymentRecords &&
      selectedInvoice.paymentRecords.length > 0 &&
      selectedInvoice.paymentRecords[0].transactionId
    ) {
      transactionId = selectedInvoice.paymentRecords[0].transactionId;
    }

    if (transactionId) {
      let transaction = yield call(
        InvoiceApi.getTransactionById,
        transactionId,
        firebase
      );
      if (transaction && transaction.id) {
        yield fork(
          InvoiceApi.sendEmailOfInvoiceReceiptToParent,
          transaction,
          firebase
        );
      }
    }
    yield put({
      type: actions.REMOVE_REFUND_SUCCESS,
    });
  } catch (err) {
    console.log("failed to remove refunded amount", err);
    bugsnagClient.notify(err);
  }
}

function* creditAmount({ amount, students, firebase, approvedBy, reason }) {
  try {
    let updatePdf = true;
    for (let index in students) {
      let student = students[index];
      let creditNodeId = yield call(InvoiceApi.generatedCreditNoteId, firebase);
      let aggInvoice = yield call(
        InvoiceApi.getAggregatedInvoice,
        student.id,
        firebase
      );
      if (aggInvoice && aggInvoice.id) {
        let updatedAggInvoice = aggInvoice;
        updatedAggInvoice.creditAmt = aggInvoice.creditAmt
          ? aggInvoice.creditAmt + Number(amount)
          : Number(amount);
        yield fork(
          InvoiceApi.updateAggregatedInvoiceAfterPaymentRecordDeletion,
          student.id,
          updatedAggInvoice,
          firebase
        );
        yield fork(
          InvoiceApi.updateCreditAddHistory,
          creditNodeId,
          student,
          amount,
          firebase,
          updatePdf,
          approvedBy,
          reason
        );
      } else {
        let nodeId = yield call(InvoiceApi.generateAggInvoiceNode, firebase);
        let data = {};
        data.classname = student.classroomName;
        data.classList = student.classList ? student.classList : [];
        data.fatherNumber = student.fatherNumber ? student.fatherNumber : 0;
        data.gender = student.gender;
        data.id = nodeId;
        data.motherNumber = student.motherNumber ? student.motherNumber : 0;
        data.paid = 0;
        data.pending = 0;
        data.studentId = student.id;
        data.studentName = student.name;
        data.admissionNumber = student.admissionNumber
          ? student.admissionNumber
          : null;
        data.total = 0;
        data.creditAmt = Number(amount);
        yield fork(
          InvoiceApi.updateAggregatedInvoiceAfterPaymentRecordDeletion,
          student.id,
          data,
          firebase
        );
        yield fork(
          InvoiceApi.updateCreditAddHistory,
          creditNodeId,
          student,
          amount,
          firebase,
          updatePdf,
          approvedBy,
          reason
        );
      }
    }

    yield put({
      type: actions.SAVE_CREDIT_SUCCESS,
    });
  } catch (err) {
    console.log(
      "failed to add credit amount to student's aggregated invoice",
      err
    );
    bugsnagClient.notify(err);
  }
}

function* getStudentCreditHistory({ studentId, firebase }) {
  try {
    let data = yield call(InvoiceApi.getCreditHistory, studentId, firebase);
    if (data) {
      data.sort(function(a, b) {
        var dateA = a.lastUpdatedOn,
          dateB = b.lastUpdatedOn;
        return dateB - dateA;
      });
      yield put({
        type: actions.GET_CREDIT_HISTORY_SUCCESS,
        creditHistory: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch credit history", err);
    bugsnagClient.notify(err);
  }
}

function* getTotalCreditHistory({ firebase }) {
  try {
    let data = yield call(InvoiceApi.getAllCreditHistory, firebase);
    if (data) {
      data.sort(function(a, b) {
        var dateA = a.lastUpdatedOn,
          dateB = b.lastUpdatedOn;
        return dateB - dateA;
      });

      yield put({
        type: actions.GET_ALL_CREDIT_HISTORY_SUCCESS,
        totalCreditHistory: data,
      });
    }
  } catch (err) {
    console.log("failed to get total credit history", err);
    bugsnagClient.notify(err);
  }
}

function* downloadTotalCreditHistory({ creditHistory, firebase }) {
  try {
    const fields = [
      "id",
      "studentName",
      "description",
      "amountAdded",
      "amountWithdrawn",
      "dateAdded",
      "addedBy",
      "approvedBy",
      "reason",
    ];
    let report = [];
    for (let index in creditHistory) {
      let singleRecord = creditHistory[index];
      var row = {};
      row.studentName = singleRecord.studentName;
      row.description = singleRecord.description;
      row.amountAdded = singleRecord.amountAdded;
      row.id = singleRecord.id;
      row.dateAdded = moment(-singleRecord.inverseDate).format("DD-MMM-YYYY");
      row.amountWithdrawn = singleRecord.amountWithdrawn;
      row.addedBy = singleRecord.lastUpdatedBy;
      row.approvedBy = singleRecord.approvedBy;
      row.reason = singleRecord.reason;
      report.push(row);
    }

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "CreditStatement";

    var ws = XLSX.utils.json_to_sheet(report, { header: fields });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    yield put({
      type: actions.DOWNLOAD_ALL_CREDIT_HISTORY_SUCCESS,
    });
  } catch (err) {
    console.log("failed to download credit statement", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.DOWNLOAD_ALL_CREDIT_HISTORY_FAILED,
    });
  }
}

function* requestCreditDelete({ record, firebase }) {
  try {
    let aggInvoice = yield call(
      InvoiceApi.getAggregatedInvoice,
      record.studentId,
      firebase
    );
    if (aggInvoice && aggInvoice.id && aggInvoice.creditAmt) {
      let amount = record.amountAdded;
      if (Number(amount) > Number(aggInvoice.creditAmt)) {
        notification(
          "error",
          "Can't delete as the total credit amount is less than the amount requested to delete"
        );
        yield put({
          type: actions.DELETE_CREDIT_DENIED,
        });
        return;
      } else {
        let updatedAggInvoice = aggInvoice;
        updatedAggInvoice.creditAmt = Number(aggInvoice.creditAmt - amount);
        yield fork(
          InvoiceApi.updateAggregatedInvoiceAfterPaymentRecordDeletion,
          record.studentId,
          updatedAggInvoice,
          firebase
        );
        yield fork(InvoiceApi.deleteCreditHistory, record, firebase);
        yield put({
          type: actions.DELETE_CREDIT_SUCCESS,
        });
      }
    } else {
      notification(
        "error",
        "Can't delete as the total credit amount is either unavailable or less than the amount requested to delete"
      );
      yield put({
        type: actions.DELETE_CREDIT_DENIED,
      });
    }
  } catch (err) {
    console.log("failed to delete credit", err);
    bugsnagClient.notify(err);
  }
}

function* fetchStudentOnlineClassInvoices({ studentId, firebase }) {
  try {
    let data = yield call(
      InvoiceApi.getInvoiceByStudentId,
      undefined,
      undefined,
      studentId,
      firebase
    );
    if (data) {
      yield put({
        type: actions.GET_STUDENT_ONLINE_CLASS_INVOICE_SUCCESS,
        studentOnlineClassInvoice: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch student online class invoices", err);
    bugsnagClient.notify(err);
  }
}

function* startTransaction({
  actualTransferAmount,
  amount,
  invoiceId,
  studentId,
  userParentId,
  firebase,
}) {
  try {
    var transactionId = yield call(
      InvoiceApi.createTransactionUniqueId,
      firebase
    );
    var transactionObject = {
      actualTransferAmount: Number(actualTransferAmount),
      amount: Number(amount),
      date: new Date().getTime(),
      errorCode: 0,
      id: transactionId,
      invoiceId: invoiceId,
      paymentMode: "online-stripe",
      status: "pending",
      studentId: studentId,
      userId: userParentId,
    };

    yield call(
      InvoiceApi.recordTransaction,
      transactionObject,
      transactionId,
      firebase
    );
    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "finance",
      moment()
    );
    yield put({
      type: actions.GENERATE_PAYMENT_TRANSACTION_ID_SUCCESS,
      transactionId: transactionId,
    });
  } catch (err) {
    console.log("failed to generate initiate transaction", err);
    bugsnagClient.notify(err);
  }
}

function getDiscountedValue(val) {
  if (val.discountType.toLowerCase() === "number") {
    let finalAmount = val.amount - val.discount;
    return finalAmount;
  } else if (val.discountType.toLowerCase() === "percentage") {
    let finalAmount = val.amount - (val.amount * val.discount) / 100;
    return finalAmount;
  }
}

function createBillingPeriod(feePlan, firebase) {
  let timezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();
  var startDate = moment.tz(timezone).format("DD/MM/YYYY");
  var endDate;
  var endDateEpoch;
  if (feePlan.frequency == "Monthly") {
    endDate = moment
      .tz(timezone)
      .add(1, "months")
      .subtract(1, "days")
      .format("DD/MM/YYYY");
    endDateEpoch = moment
      .tz(timezone)
      .add(1, "months")
      .subtract(1, "days");
  } else if (feePlan.frequency == "Quaterly") {
    endDate = moment
      .tz(timezone)
      .add(3, "months")
      .subtract(1, "days")
      .format("DD/MM/YYYY");
    endDateEpoch = moment
      .tz(timezone)
      .add(3, "months")
      .subtract(1, "days");
  } else if (feePlan.frequency == "Quarterly") {
    endDate = moment
      .tz(timezone)
      .add(3, "months")
      .subtract(1, "days")
      .format("DD/MM/YYYY");
    endDateEpoch = moment
      .tz(timezone)
      .add(3, "months")
      .subtract(1, "days");
  } else if (feePlan.frequency == "Half Yearly") {
    endDate = moment
      .tz(timezone)
      .add(6, "months")
      .subtract(1, "days")
      .format("DD/MM/YYYY");
    endDateEpoch = moment
      .tz(timezone)
      .add(6, "months")
      .subtract(1, "days");
  } else if (feePlan.frequency == "Weekly") {
    endDate = moment
      .tz(timezone)
      .add(1, "weeks")
      .subtract(1, "days")
      .format("DD/MM/YYYY");
    endDateEpoch = moment
      .tz(timezone)
      .add(1, "weeks")
      .subtract(1, "days");
  } else if (feePlan.frequency == "Yearly") {
    endDate = moment
      .tz(timezone)
      .add(1, "years")
      .subtract(1, "days")
      .format("DD/MM/YYYY");
    endDateEpoch = moment
      .tz(timezone)
      .add(1, "years")
      .subtract(1, "days");
  } else if (feePlan.frequency == "Custom Date") {
    var sequence = feePlan.sequence ? feePlan.sequence + 1 : 0;
    endDate = moment
      .tz(feePlan.generationDates[sequence], timezone)
      .subtract(1, "days")
      .format("DD/MM/YYYY");
    endDateEpoch = moment
      .tz(feePlan.generationDates[sequence], timezone)
      .subtract(1, "days");
  } else {
    console.log("invalid frequency");
  }

  return { startDate: startDate, endDate: endDate };
}

function* settleInvoicePayment({ invoice, paymentMode, parentName, firebase }) {
  try {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;

    let studentData = firebase.student;
    let transactionId = yield call(
      InvoiceApi.createTransactionUniqueId,
      firebase
    );
    let selectedInvoice;

    if (invoice.feePlan) {
      let invoiceCounter = yield call(InvoiceApi.getInvoiceCounter, firebase);
      var nodeId = yield call(
        InvoiceApi.generateInvoiceNode,
        studentData.id,
        firebase
      );

      let rows = invoice.feePlan.feeComponentDetails;
      var newLineItems = [];
      rows.map((item) => {
        newLineItems.push({
          afterDiscountValue: getDiscountedValue(item),
          amount: Number(item.amount),
          description: item.name,
          discountType:
            item.discountType === "Percentage" ? "PERCENTAGE" : "NUMBER",
          discountValue: Number(item.discount),
          timestamp: 0,
        });
      });

      var invoiceObject = {
        billingPeriod:
          createBillingPeriod(invoice.feePlan, firebase).startDate &&
          createBillingPeriod(invoice.feePlan, firebase).endDate
            ? createBillingPeriod(invoice.feePlan, firebase).startDate +
              "-" +
              createBillingPeriod(invoice.feePlan, firebase).endDate
            : null,
        classname: studentData.classroomName,
        classList: studentData.classList ? studentData.classList : [],
        dueDate: new Date().getTime(),
        endPeriod: 0,
        fatherEmail: studentData.fatherEmail ? studentData.fatherEmail : null,
        fatherNumber: studentData.fatherNumber ? studentData.fatherNumber : 0,
        gender: studentData.gender,
        id: nodeId,
        ignoreLateFee: false,
        inverseDate: -new Date().getTime(),
        invoiceRaiseDate: new Date().getTime(),
        lateFee: 0,
        lineItems: newLineItems,
        motherEmail: studentData.motherEmail ? studentData.motherEmail : null,
        motherNumber: studentData.motherNumber ? studentData.motherNumber : 0,
        paid: 0,
        pending: Number(invoice.pending),
        profileImageUrl: studentData.profileImageUrl
          ? studentData.profileImageUrl
          : null,
        startPeriod: 0,
        studentId: studentData.id,
        studentName: studentData.name,
        admissionNumber: studentData.admissionNumber
          ? studentData.admissionNumber
          : null,
        total: Number(invoice.pending),
        schoolNote: null,
        feeTemplateList: null,
        invoiceNumber:
          firebase.schoolConfig && firebase.schoolConfig.invoicePrefix
            ? (
                firebase.schoolConfig.invoicePrefix +
                "-" +
                (invoiceCounter + 1)
              ).toString()
            : null,
        platform: "web",
        updatedBy: parentName ? parentName : null,
        updatedOn: new Date().getTime(),
      };

      selectedInvoice = invoiceObject;

      yield call(
        InvoiceApi.addNewInvoice,
        invoiceObject,
        studentData,
        nodeId,
        firebase
      );

      yield call(InvoiceApi.incrementCounter, invoiceCounter, firebase);
      let aggregatedInvoice = yield call(
        InvoiceApi.getAggregatedInvoice,
        studentData.id,
        firebase
      );

      if (aggregatedInvoice !== false) {
        yield call(
          InvoiceApi.updatedAmountToAggregatedInvoice,
          aggregatedInvoice,
          studentData.id,
          invoice.pending,
          firebase,
          "addInvoice",
          receivePayment
        );
      } else {
        yield call(
          InvoiceApi.addAmountToNewAggregatedInvoice,
          studentData,
          nodeId,
          invoice.pending,
          firebase
        );
      }

      //update student to fee plan
      yield fork(
        assignStudentToFeePlan,
        [studentData],
        invoice.feePlan,
        invoice.feePlan.feeComponentDetails,
        firebase
      );
    } else {
      selectedInvoice = JSON.parse(JSON.stringify(invoice));
    }

    let inputAmt = JSON.parse(JSON.stringify(Number(selectedInvoice.pending)));
    let newPaymentRecord = [];
    if (selectedInvoice.paymentRecords) {
      newPaymentRecord = selectedInvoice.paymentRecords;
    }

    newPaymentRecord.push({
      afterDiscountValue: 0,
      amount: Number(inputAmt),
      description: "Paid online on ",
      discountValue: 0,
      mode: "Online",
      timestamp: new Date().getTime(),
      transactionId: transactionId,
    });

    selectedInvoice.paid = Number(
      (selectedInvoice.paid ? selectedInvoice.paid : 0) + inputAmt
    );
    selectedInvoice.pending = Number(0);
    selectedInvoice.paymentRecords = newPaymentRecord;
    selectedInvoice.platform = "web";
    selectedInvoice.updatedBy = parentName;
    selectedInvoice.updatedOn = new Date().getTime();
    yield call(InvoiceApi.recordPayment, selectedInvoice, firebase);

    yield put({
      type: actions.SETTLE_PAYMENT_SUCCESS,
    });

    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "finance",
      moment()
    );

    let aggregatedInvoice = yield call(
      InvoiceApi.getAggregatedInvoice,
      selectedInvoice.studentId,
      firebase
    );
    if (aggregatedInvoice) {
      yield call(
        InvoiceApi.updatedAmountToAggregatedInvoice,
        aggregatedInvoice,
        selectedInvoice.studentId,
        undefined,
        firebase,
        "receivePayment",
        inputAmt
      );
    }

    var transactionObject = {
      // actualTransferAmount: Number(selectedInvoice.pending) * 100,
      actualTransferAmount: Number(inputAmt) * 100,
      amount: Number(inputAmt) * 100,
      date: new Date().getTime(),
      errorCode: 0,
      id: transactionId,
      invoiceId: selectedInvoice.id,
      paymentMode: paymentMode,
      status: "successful",
      studentId: selectedInvoice.studentId,
      userId: parentName,
    };

    yield fork(
      InvoiceApi.recordTransaction,
      transactionObject,
      transactionId,
      firebase
    );

    yield fork(
      InvoiceApi.sendEmailOfInvoiceReceiptToParent,
      transactionObject,
      firebase
    );

    if (invoice.feePlan) {
      //pixel purchase
      ReactPixel.init("2786923028084527");
      ReactPixel.track("IlluminePurchase");

      //class, address & status of  student
      studentData.status = "Active";
      studentData.updatedBy = parentName
        ? parentName
        : firebase.teacher
        ? firebase.teacher.name
        : "";
      studentData.updatedOn = new Date().getTime();
      studentData.address = invoice.address ? invoice.address : "";

      {
        /**check if student object has activity id. Fetch and update if recurring*/
      }
      if (studentData.activityId) {
        let activityId = studentData.activityId;
        let activity = yield call(
          ActivityApi.getActivityById,
          activityId,
          firebase
        );
        if (activity && activity.id) {
          if (
            activity.studentIds &&
            !activity.studentIds.includes(studentData.id)
          ) {
            activity.studentIds.push(studentData.id);
          } else {
            activity.studentIds = [studentData.id];
          }

          //update activity node
          rsf.ref(branchPath + "/activities/" + activity.id).update({
            studentIds: activity.studentIds,
          });

          let repeatVirtualClass =
            activity.repeatStartDate > 0 && activity.repeatEndDate > 0
              ? true
              : false;
          if (repeatVirtualClass) {
            let repeatClassId;
            if (activity.parentActivityId) {
              repeatClassId = activity.parentActivityId;
            } else {
              repeatClassId = activity.id;
            }

            if (repeatClassId) {
              let repeatClass = yield call(
                ActivityApi.getRepeatClassById,
                repeatClassId,
                firebase
              );
              if (repeatClass && repeatClass.id) {
                repeatClass.updatedBy = firebase.teacher.name;
                repeatClass.updatedOn = new Date().getTime();
                repeatClass.studentIds = activity.studentIds
                  ? activity.studentIds
                  : [];
                yield call(
                  ActivityApi.updateIndRepeatClass,
                  repeatClass,
                  firebase
                );
              }
            }
          }

          if (activity.classNames && activity.classNames.length > 0) {
            studentData.classroomName = activity.classNames[0];
            studentData.classList = activity.classNames;
          }
        }
      }

      firebase.student = studentData;
      localStorage.setItem("student", JSON.stringify(studentData));
      yield fork(
        StudentApi.updateStudentWithUpdatedFormFields,
        studentData,
        firebase
      );
      callStatusChangeWebHook(studentData, firebase, "ENROLLED");
      yield fork(addGroupToStudent, firebase);
    }
    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "finance",
      moment()
    );
  } catch (err) {
    console.log("failed to settle invoice payment", err);
    bugsnagClient.notify(err);
  }
}

function* addGroupToStudent(firebase) {
  try {
    let student = firebase.student;
    if (student.meetingDate) {
      let meetingTime = student.meetingDate;
      let timeString = moment(meetingTime).format("ha");

      let groups = JSON.parse(localStorage.getItem("groupList"));
      if (groups) {
        for (let index in groups) {
          let group = groups[index];
          if (group.name.toLowerCase().includes(timeString.toLowerCase())) {
            let studentIds = [];
            if (group.studentIds) {
              studentIds = [...studentIds, ...group.studentIds];
            }
            studentIds.push(student.id);
            yield call(
              TagApi.assignStudentToTag,
              group.id,
              [...new Set(studentIds)],
              firebase
            );

            let studentTag = student.tags ? student.tags : [];
            studentTag.push({
              id: group.id,
              name: group.name,
            });
            student.tags = studentTag;
            firebase.student = student;
            localStorage.setItem("student", JSON.stringify(student));
            yield fork(
              StudentApi.updateStudentWithUpdatedFormFields,
              student,
              firebase
            );
          }
        }
      }
    }
  } catch (err) {
    console.log("failed to add groups to student detail", err);
    bugsnagClient.notify(err);
  }
}

function* assignStudentToFeePlan(
  selectedStudentCheckbox,
  selectedPlan,
  rows,
  firebase
) {
  try {
    let studentList = selectedStudentCheckbox;
    var studentArray = [];
    if (selectedPlan.student !== undefined) {
      studentArray = selectedPlan.student;
    }

    for (let index in studentList) {
      let filterVal = studentArray.filter((i) => {
        return i.studentId === studentList[index].id;
      });

      if (filterVal === undefined || filterVal.length === 0) {
        studentArray.push({
          name: studentList[index].name,
          studentId: studentList[index].id,
          startDate: moment()
            .startOf("day")
            .valueOf(),
          endDate: moment()
            .add(1, "year")
            .valueOf(),
          classroomName: studentList[index].classroomName,
          classList: studentList[index].classList
            ? studentList[index].classList
            : [],
          active: true,
        });
      }
    }

    let presentFeePlan = JSON.parse(JSON.stringify(selectedPlan));
    presentFeePlan.student = studentArray;

    presentFeePlan.feeComponentDetails = null;

    yield call(FeeApi.assignStudentsToFeePlan, presentFeePlan, firebase);

    let lineItems = [];
    for (let ind in rows) {
      lineItems.push({
        amount: Number(rows[ind].amount),
        discount: Number(rows[ind].discount),
        discountType: rows[ind].discountType,
        id: rows[ind].id,
        inverseDate: -new Date(),
        isRefundable: rows[ind].isRefundable,
        name: rows[ind].name,
        paymentFrequency: rows[ind].paymentFrequency,
        paymentSchedule: rows[ind].paymentSchedule,
      });
    }

    presentFeePlan.studentFeeComponent = lineItems;
    for (let i in studentList) {
      yield call(
        FeeApi.updateStudentFeePlan,
        studentList[i].id,
        presentFeePlan,
        firebase
      );
    }
  } catch (error) {
    console.log("failed to assign trial student to fee plan", error);
    bugsnagClient.notify(error);
  }
}

function callStatusChangeWebHook(student, firebase, event) {
  if (firebase.dbName == "GetReadyEdu_Master-Branch") {
    var studentBody = {};
    let parentEmail;
    let parentName;
    let parentNumber;
    if (student.fatherProfileId) {
      parentEmail = student.fatherEmail ? student.fatherEmail : undefined;
      parentName = student.fatherName;
      parentNumber = student.fatherNumber;
    } else if (student.motherProfileId) {
      parentEmail = student.motherEmail ? student.motherEmail : undefined;
      parentName = student.motherName;
      parentNumber = student.motherNumber;
    }
    studentBody.studentId = student.id;
    studentBody.studentName = student.name;
    studentBody.event = event;
    studentBody.enrolledDate = moment().valueOf();
    studentBody.email = parentEmail;
    studentBody.phoneNumber = parentNumber;
    studentBody.parentName = parentName;
    studentBody.meetingTime = student.meetingDate
      ? student.meetingDate
      : undefined;
    studentBody.classroomName = student.classroomName;
    // studentBody.teacherName = firebase.teacher ? firebase.teacher.name : undefined;
    studentBody.birthDate = student.birthDate;
    studentBody.timezone = moment.tz.guess();
    let endPoint =
      "https://hook.integromat.com/fsqrbavgku7hmtabdnx9rxy959lhhmox";

    var p1 = new Promise(function(resolve, reject) {
      superagent
        .post(endPoint)
        .send(studentBody)
        .set("accept", "json")
        .end((err, res) => {
          console.log("error status change -----", err);
          if (err) {
            bugsnagClient.notify(err);
          }
          console.log("res status change -----", res);
          resolve(res);
        });
    });
    return p1;
  }
}

function* recordFailedPayment({ invoice, parentName, firebase }) {
  try {
    if (invoice.id) {
      let transactionId = yield call(
        InvoiceApi.createTransactionUniqueId,
        firebase
      );
      let selectedInvoice = JSON.parse(JSON.stringify(invoice));
      let inputAmt = JSON.parse(
        JSON.stringify(Number(selectedInvoice.pending))
      );

      var transactionObject = {
        actualTransferAmount: Number(inputAmt) * 100,
        amount: Number(inputAmt) * 100,
        date: new Date().getTime(),
        errorCode: 0,
        errorResponse: "Payment Cancelled",
        id: transactionId,
        invoiceId: selectedInvoice.id,
        status: "pending",
        studentId: selectedInvoice.studentId,
        userId: parentName,
      };

      yield fork(
        InvoiceApi.recordTransaction,
        transactionObject,
        transactionId,
        firebase
      );
    }

    yield put({
      type: actions.UPDATED_FAILED_PAYMENT_SUCCESS,
    });
  } catch (err) {
    console.log("failed to update failed payment status", err);
    bugsnagClient.notify(err);
  }
}

function* getOnlineClassPlanDetail({ feePlanId, firebase }) {
  try {
    let data = yield call(
      FeeApi.getSelectedFeePlanDetailById,
      feePlanId,
      firebase
    );
    if (data) {
      if (data && data.id && data.feeComponent) {
        let feeComponents = data.feeComponent;
        let feeComponentDetails = [];
        let total = 0;
        for (let index in feeComponents) {
          let val = yield call(
            FeeApi.getAssignedFeeComponentById,
            feeComponents[index],
            firebase
          );
          if (val && val.id) {
            feeComponentDetails.push(val);
            if (val.discountType.toLowerCase() === "number") {
              let finalAmount = val.amount - val.discount;
              total = total + finalAmount;
            } else if (val.discountType.toLowerCase() === "percentage") {
              let finalAmount = val.amount - (val.amount * val.discount) / 100;
              total = total + finalAmount;
            }
          }
        }

        data.feeComponentDetails = feeComponentDetails;
        data.pending = total;

        yield put({
          type: actions.GET_ONLINE_CLASS_PLAN_DETAIL_SUCCESS,
          feePlanDetail: data,
        });
      } else {
        yield put({
          type: actions.GET_ONLINE_CLASS_PLAN_DETAIL_SUCCESS,
          feePlanDetail: undefined,
        });
      }
    }
  } catch (err) {
    console.log("failed to fetch online class plan detail", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* saveParentCardDetails({ record, firebase, country }) {
  try {
    if (record.studentId) {
      yield call(InvoiceApi.saveCardDetails, record, firebase, country);
    }
  } catch (err) {
    console.log("failed to save card details", err);
    bugsnagClient.notify(err);
  }
}

function* fetchSavedCardDetail({ studentId, firebase }) {
  try {
    let data = yield call(InvoiceApi.getCardDetails, studentId, firebase);
    if (data && data.cardHolderName) {
      yield put({
        type: actions.FETCH_CARD_DETAILS_SUCCESS,
        savedCardDetail: data,
      });
    } else {
      yield put({
        type: actions.FETCH_CARD_DETAILS_SUCCESS,
        savedCardDetail: undefined,
      });
    }
  } catch (err) {
    console.log("failed to fetch saved card details", err);
    bugsnagClient.notify(err);
  }
}

function* autoChargeInvoicePayment({ invoice, firebase }) {
  try {
    let response = yield call(
      NotificationApi.callAutoChargePaymentApi,
      invoice,
      firebase
    );
    if (response && response.status === 200) {
      yield put({
        type: actions.AUTO_CHARGE_PAYMENT_SUCCESS,
      });
    } else {
      notification("error", "Auto payment failed");
      yield put({
        type: actions.INVOICE_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to auto charge payment", err);
    bugsnagClient.notify(err);
  }
}

function* getStudentsFeePlan({ firebase }) {
  try {
    yield fork(fetchTaxRef, firebase);
    if (
      firebase.schoolConfig &&
      firebase.schoolConfig.billingMode &&
      firebase.schoolConfig.billingMode === "Fee Plan"
    ) {
      let students = JSON.parse(localStorage.getItem("studentList"));
      if (students && students.length > 0) {
        let activeStudents = students.filter((std) => {
          return std.status && std.status.toLowerCase() === "active";
        });

        let studentFeePlanMap = new Map();
        let feePlanTask = new Map();
        for (let index in activeStudents) {
          let studentId = activeStudents[index].id;
          let task = call(FeeApi.getStudentFeePlan, studentId, firebase);
          feePlanTask.set(studentId, task);
        }

        for (let [key, value] of feePlanTask) {
          let newVal = yield all([value]);
          let feePlan = newVal[0];
          if (feePlan && feePlan.length > 0) {
            studentFeePlanMap.set(key, feePlan);
          } else {
            studentFeePlanMap.set(key, undefined);
          }
        }

        yield put({
          type: actions.GET_STUDENTS_FEE_PLAN_SUCCESS,
          studentFeePlanMap: studentFeePlanMap,
        });
      }
    }
  } catch (err) {
    console.log("failed to fetch students fee plan", err);
    bugsnagClient.notify(err);
  }
}

function* refreshPdfRequest({ invoice, firebase }) {
  try {
    yield fork(
      InvoiceApi.sendEmailOfInvoiceToParent,
      invoice,
      firebase,
      "sendNoEmail"
    );

    if (invoice.pending === 0) {
      yield fork(InvoiceApi.generateInvoiceReceipt, invoice, firebase);
    }

    yield put({ type: actions.REFRESH_PDF_SUCCESS });
  } catch (err) {
    console.log("failed to refresh pdf", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* fetchCreditPdf({ record, firebase }) {
  try {
    let pdf = yield call(InvoiceApi.getCreditPdf, record.id, firebase);
    console.log("pdf ------", pdf);
    if (pdf && pdf.pdfUrl) {
      yield put({
        type: actions.GET_CREDIT_PDF_SUCCESS,
        creditPdf: pdf,
      });
    } else {
      notification("error", "Pdf not found");
    }
  } catch (err) {
    console.log("failed to fetch credit pdf", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.LIST_INVOICE, fetchInvoices),
    yield takeLatest(actions.GET_INVOICE_AGGREGATED, fetchAggregatedInvoices),
    yield takeLatest(actions.GET_INVOICE_DOWNLOAD_URL, fetchInvoiceDownloadUrl),
    yield takeLatest(actions.GET_STUDENT_FOR_INVOICE, fetchStudentForInvoice),
    yield takeLatest(actions.ADD_NEW_INVOICE, addInvoice),
    yield takeLatest(actions.UPDATE_SELECTED_INVOICE, updateSelectedInvoice),
    yield takeLatest(actions.RECEIVE_PAYMENT, receivePayment),
    yield takeLatest(actions.DELETE_INVOICE, deleteSelectedInvoice),
    yield takeLatest(
      actions.GET_INVOICE_TEMPLATES,
      fetchOneTimeInvoiceTemplates
    ),
    yield takeLatest(
      actions.DOWNLOAD_INVOICE_EXCEL_SHEET,
      fetchAndDownloadExcelSheet
    ),
    yield takeLatest(actions.SEND_PAYMENT_REMINDER, sendFeePaymentReminder),
    yield takeLatest(
      actions.DELETE_PAYMENT_RECORD,
      deleteSelectedPaymentRecord
    ),
    yield takeLatest(actions.REFUND_AMT, refundPaidAmount),
    yield takeLatest(actions.GET_RECEIPT_LIST, fetchReceiptList),
    yield takeLatest(
      actions.GET_STUDENT_AGGREAGTED_INVOICES,
      fetchStudentAggregatedInvoice
    ),
    yield takeLatest(
      actions.GET_SINGLE_STUDENT_INVOICES,
      fetchSingleStudentInvoices
    ),
    yield takeLatest(
      actions.GET_SINGLE_STUDENT_AGGREGATED_INVOICE,
      fetchSingleStudentAggregatedInvoice
    ),
    yield takeLatest(actions.IGNORE_LATE_FEE, ignoreInvoiceLateFee),
    yield takeLatest(actions.REMOVE_REFUND, removeRefundedAmt),
    yield takeLatest(actions.SAVE_CREDIT, creditAmount),
    yield takeLatest(actions.GET_CREDIT_HISTORY, getStudentCreditHistory),
    yield takeLatest(actions.GET_ALL_CREDIT_HISTORY, getTotalCreditHistory),
    yield takeLatest(
      actions.DOWNLOAD_ALL_CREDIT_HISTORY,
      downloadTotalCreditHistory
    ),
    yield takeLatest(actions.DELETE_CREDIT, requestCreditDelete),
    yield takeLatest(
      actions.GET_STUDENT_ONLINE_CLASS_INVOICE,
      fetchStudentOnlineClassInvoices
    ),
    yield takeLatest(actions.GENERATE_PAYMENT_TRANSACTION_ID, startTransaction),
    yield takeLatest(actions.SETTLE_PAYMENT, settleInvoicePayment),
    yield takeLatest(actions.UPDATED_FAILED_PAYMENT, recordFailedPayment),
    yield takeLatest(
      actions.GET_ONLINE_CLASS_PLAN_DETAIL,
      getOnlineClassPlanDetail
    ),
    yield takeLatest(actions.SAVE_CARD_DETAILS, saveParentCardDetails),
    yield takeLatest(actions.FETCH_CARD_DETAILS, fetchSavedCardDetail),
    yield takeLatest(actions.AUTO_CHARGE_PAYMENT, autoChargeInvoicePayment),
    yield takeLatest(actions.GET_STUDENTS_FEE_PLAN, getStudentsFeePlan),
    yield takeLatest(actions.REFRESH_PDF, refreshPdfRequest),
    yield takeLatest(actions.GET_CREDIT_PDF, fetchCreditPdf),
  ]);
}
