import actions from "./actions";
const initState = {
    // schedules: [],
    schedules: new Map(),
    classrooms: [],
    schedulesChannel: undefined,
    isLoading: false,
    operationType: undefined,
    error: false
};

export default function schedulesReducer(
    state = initState,
    { type, ...action }
) {
    switch (type) {
        case actions.GET_SCHEDULES:
            return {
                ...state,
                isLoading: true,
                operationType: undefined
            };
        case actions.GET_SCHEDULES_SUCCESSFUL:
            return {
                ...state,
                schedules: action.schedules,
                schedulesChannel: action.schedulesChannel,
                isLoading: false,
                operationType: "INITIAL_FETCH"
            };

        case actions.SCHEDULE_REQUEST_FAILED:
            return {
                ...state,
                error: true,
                isLoading: false,
            };

        case actions.GET_SCHEDULE_CLASSROOM:
            return {
                ...state,
            };
        case actions.GET_SCHEDULE_CLASSROOM_SUCCESSFUL:
            return {
                ...state,
                classrooms: action.classrooms,
                operationType: "CLASS_FETCH"
            };

        case actions.RESET_SCHEDULE_OPERATION_TYPE:
            return {
                ...state,
                operationType: undefined
            };

        case actions.DELETE_SCHEDULE_ITEM:
            return {
                ...state,
                isLoading: true,
            };
        case actions.DELETE_SCHEDULE_ITEM_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                operationType: "DELETE_SCHEDULE_ITEM"
            };

        case actions.ADD_SCHEDULE:
            return {
                ...state,
                isLoading: true,
            };
        case actions.ADD_SCHEDULE_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                operationType: "ADD_SCHEDULE_ITEM"
            };

        case actions.EDIT_SCHEDULE:
            return {
                ...state,
                isLoading: true,
            };
        case actions.EDIT_SCHEDULE_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                operationType: "EDIT_SCHEDULE_ITEM"
            };

        default:
            return state;
    }
}