const actions = {
  GET_ALL_DRAFT_NEWSLETTER: "GET_ALL_DRAFT_NEWSLETTER",
  GET_ALL_NS_TEMPLATES: "GET_ALL_NS_TEMPLATES",
  GET_ALL_DRAFT_NEWSLETTER_SUCCESS: "GET_ALL_DRAFT_NEWSLETTER_SUCCESS",
  GET_ALL_NS_TEMPLATES_SUCCESS: "GET_ALL_NS_TEMPLATES_SUCCESS",
  GET_ALL_NS_TEMPLATES_FAILED: "GET_ALL_NS_TEMPLATES_FAILED",
  GET_ALL_DRAFT_NEWSLETTER_FAILED: "GET_ALL_DRAFT_NEWSLETTER_FAILED",
  SEND_NEWSLETTER: "SEND_NEWSLETTER",
  SEND_NEWSLETTER_SUCCESS: "SEND_NEWSLETTER_SUCCESS",
  SEND_NEWSLETTER_FAILED: "SEND_NEWSLETTER_FAILED",
  SAVE_NEWSLETTER: "SAVE_NEWSLETTER",
  UPDATE_NEWSLETTER: "UPDATE_NEWSLETTER",
  SAVE_NS_TEMPLATE: "SAVE_NS_TEMPLATE",
  UPDATE_NS_TEMPLATE: "UPDATE_NS_TEMPLATE",
  NEWSLETTER_SAVE_REQUEST_FAILED: "NEWSLETTER_SAVE_REQUEST_FAILED",
  NEWSLETTER_SAVE_REQUEST_SUCCESS: "NEWSLETTER_SAVE_REQUEST_SUCCESS",
  NEWSLETTER_UPDATE_REQUEST_SUCCESS: "NEWSLETTER_UPDATE_REQUEST_SUCCESS",
  NS_TEMPLATE_SAVE_REQUEST_FAILED: "NS_TEMPLATE_SAVE_REQUEST_FAILED",
  NS_TEMPLATE_SAVE_REQUEST_SUCCESS: "NS_TEMPLATE_SAVE_REQUEST_SUCCESS",
  NS_TEMPLATE_UPDATE_REQUEST_SUCCESS: " NS_TEMPLATE_UPDATE_REQUEST_SUCCESS",
  NS_TEMPLATE_UPDATE_REQUEST_FAILED: "NS_TEMPLATE_UPDATE_REQUEST_FAILED",
  GET_STUDENT_BY_CENTER_NAME: "GET_STUDENT_BY_CENTER_NAME",
  GET_STUDENT_BY_CENTER_NAME_SUCCESSFUL:
    "GET_STUDENT_BY_CENTER_NAME_SUCCESSFUL",
  GET_STUDENT_BY_CENTER_NAME_FAILED: "GET_STUDENT_BY_CENTER_NAME_FAILED",
  GET_STUDENT_BY_ROOM_NAME: "GET_STUDENT_BY_ROOM_NAME",
  GET_STUDENT_BY_ROOM_NAME_SUCCESSFUL: "GET_STUDENT_BY_ROOM_NAME_SUCCESSFUL",
  GET_STUDENT_BY_ROOM_NAME_FAILED: "GET_STUDENT_BY_ROOM_NAME_FAILED",
  GET_SEARCHED_STUDENT: "GET_SEARCHED_STUDENT",
  GET_SEARCHED_STUDENT_SUCCESSFUL: "GET_SEARCHED_STUDENT_SUCCESSFUL",
  GET_SEARCHED_STUDENT_FAILED: "GET_SEARCHED_STUDENT_FAILED",
  GET_STUDENT_DATA: "GET_STUDENT_DATA",
  GET_STUDENT_DATA_SUCCESSFUL: "GET_STUDENT_DATA_SUCCESSFUL",
  GET_STUDENT_DATA_FAILED: "GET_STUDENT_DATA_FAILED",
  FILE_UPLOAD_START: "FILE_UPLOAD_START",
  FILE_UPLOAD_COMPLETED: "FILE_UPLOAD_COMPLETED",
  FILE_UPLOAD_PROGRESS: "FILE_UPLOAD_PROGRESS",
  FILE_UPLOAD_FAILED: "FILE_UPLOAD_FAILED",
  FETCH_CLASSROOM: "FETCH_CLASSROOM",
  FETCH_CLASSROOM_SUCCESSFUL: "FETCH_CLASSROOM_SUCCESSFUL",
  NEWSLETTER_REQUEST_FAILED: "NEWSLETTER_REQUEST_FAILED",
  RESET_ERROR: "RESET_ERROR",
  SEND_NEWSLETTER_ALL_CENTER: "SEND_NEWSLETTER_ALL_CENTER",
  SEND_NEWSLETTER_ALL_CENTER_SUCCESS: "SEND_NEWSLETTER_ALL_CENTER_SUCCESS",
  GET_ALL_SENT_NEWSLETTER: "GET_ALL_SENT_NEWSLETTER",
  GET_ALL_SENT_NEWSLETTER_SUCCESS: "GET_ALL_SENT_NEWSLETTER_SUCCESS",

  initData: () => ({ type: actions.GET_ALL_DRAFT_NEWSLETTER }),

  uploadImage: (fileUrl, firebase, newsletterType) => ({
    type: actions.FILE_UPLOAD_START,
    fileUrl,
    firebase,
    newsletterType
  }),
  saveNewsletter: (newsletter, firebase) => ({
    type: actions.SAVE_NEWSLETTER,
    newsletter,
    firebase
  }),

  updateNewsletter: (newsletter, firebase, uploadFileUrl) => ({
    type: actions.UPDATE_NEWSLETTER,
    newsletter,
    firebase,
    uploadFileUrl
  }),
  saveTemplate: (newsletter, firebase) => ({
    type: actions.SAVE_NS_TEMPLATE,
    newsletter,
    firebase
  }),

  updateTemplate: (newsletter, firebase, uploadFileUrl) => ({
    type: actions.UPDATE_NS_TEMPLATE,
    newsletter,
    firebase,
    uploadFileUrl
  }),

  sendNewsletter: (newsletter, firebase) => ({
    type: actions.SEND_NEWSLETTER,
    newsletter,
    firebase
  }),

  getAllNewsLetter: firebase => ({
    type: actions.GET_ALL_DRAFT_NEWSLETTER,
    firebase
  }),
  getAllTemplates: firebase => ({
    type: actions.GET_ALL_NS_TEMPLATES,
    firebase
  }),
  getStudentByCenterName: (centerName, option) => ({
    type: actions.GET_STUDENT_BY_CENTER_NAME,
    centerName,
    option
  }),

  getStudentByRoomName: (roomName, option) => ({
    type: actions.GET_STUDENT_BY_ROOM_NAME,
    roomName,
    option
  }),

  getStudentByName: studentName => ({
    type: actions.GET_SEARCHED_STUDENT,
    studentName
  }),

  fetchStudentData: firebase => ({ type: actions.GET_STUDENT_DATA, firebase }),

  fetchClassrooms: firebase => ({
    type: actions.FETCH_CLASSROOM,
    firebase
  }),

  resetError: () => ({
    type: actions.RESET_ERROR
  }),

  sendNewsletterToAllCenter: (newsletter, firebase, filteredBranch) => ({
    type: actions.SEND_NEWSLETTER_ALL_CENTER,
    newsletter,
    firebase,
    filteredBranch
  }),

  initNewsletterData: firebase => ({
    type: actions.GET_ALL_SENT_NEWSLETTER,
    firebase
  }),
};
export default actions;
