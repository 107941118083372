const actions = {
    LIST_PROGRAMS: 'LIST_PROGRAMS',
    LIST_PROGRAMS_SUCCESSFUL: 'LIST_PROGRAMS_SUCCESSFUL',
    GET_PROGRAM_CLASSROOMS: 'GET_PROGRAM_CLASSROOMS',
    GET_PROGRAM_CLASSROOMS_SUCCESSFFUL: 'GET_PROGRAM_CLASSROOMS_SUCCESSFFUL',
    'ADD_PROGRAM': "ADD_PROGRAM",
    'ADD_PROGRAM_SUCCESSFUL': 'ADD_PROGRAM_SUCCESSFUL',
    'UPDATE_PROGRAM': 'UPDATE_PROGRAM',
    'UPDATE_PROGRAM_SUCCESSFUL': 'UPDATE_PROGRAM_SUCCESSFUL',
    'PROGRAM_REQUEST_FAILED': 'PROGRAM_REQUEST_FAILED',
    'PROGRAM_FETCH_STUDENT': 'PROGRAM_FETCH_STUDENT',
    'PROGRAM_FETCH_STUDENT_SUCCESSFFUL': 'PROGRAM_FETCH_STUDENT_SUCCESSFFUL',
    'PROGRAM_FETCH_FEE_PLAN': 'PROGRAM_FETCH_FEE_PLAN',
    'PROGRAM_FETCH_FEE_PLAN_SUCCESSFUL': 'PROGRAM_FETCH_FEE_PLAN_SUCCESSFUL',
    'ASSIGN_STUDENT_TO_PROGRAM': 'ASSIGN_STUDENT_TO_PROGRAM',
    'ASSIGN_STUDENT_TO_PROGRAM_SUCCESSFUL': 'ASSIGN_STUDENT_TO_PROGRAM_SUCCESSFUL',
    'ADD_FEE_PLAN_TO_PROGRAM': 'ADD_FEE_PLAN_TO_PROGRAM',
    'ADD_FEE_PLAN_TO_PROGRAM_SUCCESSFUL': 'ADD_FEE_PLAN_TO_PROGRAM_SUCCESSFUL',
    'DOWNLOAD_ALL_PROGRAM_EXCEL': 'DOWNLOAD_ALL_PROGRAM_EXCEL',
    'DOWNLOAD_ALL_PROGRAM_EXCEL_SUCCESS': 'DOWNLOAD_ALL_PROGRAM_EXCEL_SUCCESS',
    RESET_PROGRAM_OPERATION: "RESET_PROGRAM_OPERATION",
    DELETE_SELECTED_PROGRAM: "DELETE_SELECTED_PROGRAM",
    DELETE_SELECTED_PROGRAM_SUCCESS: "DELETE_SELECTED_PROGRAM_SUCCESS",

    initProgramData: (firebase) => ({ type: actions.LIST_PROGRAMS, firebase }),

    getClassroomDataForProgram: (firebase) => ({
        type: actions.GET_PROGRAM_CLASSROOMS,
        firebase
    }),

    addProgram: (values, classroom, firebase) => ({
        type: actions.ADD_PROGRAM,
        values,
        classroom,
        firebase
    }),

    updateProgram: (values, classroom, record, firebase) => ({
        type: actions.UPDATE_PROGRAM,
        values,
        classroom,
        record,
        firebase
    }),

    getStudentsToAssignProgram: (firebase) => ({
        type: actions.PROGRAM_FETCH_STUDENT,
        firebase
    }),

    getFeePlanToAssignProgram: (firebase) => ({
        type: actions.PROGRAM_FETCH_FEE_PLAN,
        firebase
    }),

    assignStudentToProgram: (values, selectedStudentCheckbox, selectedProgram, firebase) => ({
        type: actions.ASSIGN_STUDENT_TO_PROGRAM,
        values,
        selectedStudentCheckbox,
        selectedProgram,
        firebase
    }),

    addFeePlanToProgram: (values, selectedFeePlan, selectedProgram, firebase) => ({
        type: actions.ADD_FEE_PLAN_TO_PROGRAM,
        values,
        selectedFeePlan,
        selectedProgram,
        firebase
    }),

    downloadProgramExcel: (programs, firebase) => ({
        type: actions.DOWNLOAD_ALL_PROGRAM_EXCEL,
        programs,
        firebase
    }),

    resetProgramOperation: () => ({
        type: actions.RESET_PROGRAM_OPERATION
    }),

    deletedProgram: (record, firebase) => ({
        type: actions.DELETE_SELECTED_PROGRAM,
        record,
        firebase
    })
}
export default actions;