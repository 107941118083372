import { all, put, call, take, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { LearningApi } from "../../firestore-api/learning";
import { AssessmentApi } from "../../firestore-api/studentAssessment";
import bugsnagClient from "@bugsnag/js";
import { ActivityApi } from "../../firestore-api/activity";

function* fetchAllAssessmentMilestones({ firebase, assessmentMilestones }) {
  try {
    firebase.getMilestones();
    let data = new Map(JSON.parse(localStorage.getItem("milestones")));

    if (data) {
      yield put({
        type: actions.LIST_ASSESSMENT_MILESTONES_SUCCESSFUL,
        assessmentMilestones: data,
        assessmentMilestoneChannel: undefined,
      });
    }
  } catch (err) {
    console.log("failed to fetch curriculum milestones", err);
    bugsnagClient.notify(err);
  }
}

function* fetchAssessmentDevAreas({ firebase }) {
  try {
    firebase.getDevelopmentAreas();

    let data = JSON.parse(localStorage.getItem("developmentAreas"));
    let developmentAreasMap = new Map();
    for (let index in data) {
      developmentAreasMap.set(data[index].id, data[index]);
    }

    if (data) {
      yield put({
        type: actions.GET_STD_ASS_DEV_AREAS_SUCCESS,
        developmentAreas: data,
        developmentAreasMap: developmentAreasMap,
      });
    }
  } catch (err) {
    console.log("failed to fetch development areas", err);
    bugsnagClient.notify(err);
  }
}

function* createNewStudentAssessmentNode({ firebase }) {
  try {
    var key = yield call(AssessmentApi.generateStudentAssessmentNode, firebase);
    yield put({
      type: actions.GENERATE_STUDENT_ASSESSMENT_NODE_ID_SUCCESSFUL,
      uniqueNodeId: key,
    });
  } catch (err) {
    console.log("failed to create node id", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_ASSESSMENT_REQUEST_FAILED,
    });
  }
}

function* saveNewDomainToStudentAssessment({
  selectedStatus,
  selectedDomain,
  selectedStudent,
  uniqueNodeId,
  assessmentMilestones,
  firebase,
}) {
  try {
    yield call(
      AssessmentApi.saveDomainToStudentAssessment,
      selectedStatus,
      selectedDomain,
      selectedStudent,
      uniqueNodeId,
      assessmentMilestones,
      firebase
    );
    yield put({
      type: actions.SAVE_SELECTED_DOMAIN_TO_STUDENT_ASSESSMENT_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to save new domain to student assessment", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_ASSESSMENT_REQUEST_FAILED,
    });
  }
}

function* saveNewMilestoneToStudentAssessment({
  selectedStatus,
  selectedDomain,
  secondaryItem,
  selectedStudent,
  assessmentMilestones,
  uniqueNodeId,
  firebase,
}) {
  try {
    var key = yield call(AssessmentApi.generateStudentAssessmentNode, firebase);
    yield call(
      AssessmentApi.saveMilestoneToStudentAssessment,
      selectedStatus,
      selectedDomain,
      secondaryItem,
      selectedStudent,
      assessmentMilestones,
      key,
      firebase
    );
    yield put({
      type: actions.SAVE_SELECTED_MILESTONE_TO_STUDENT_ASSESSMENT_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to save new milestone to student assessment", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_ASSESSMENT_REQUEST_FAILED,
    });
  }
}

function* fetchLabelForAssessment({ firebase }) {
  const chan = yield call(LearningApi.getLabel, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_LABELS_FOR_ASSESSMENT_SUCCESSFFUL,
        labels: data,
        labelChan: chan,
      });
    }
  } finally {
    console.log("end label channel");
  }
}

function* fetchStudentAssessment({ studentId, savedAssessmentId, firebase }) {
  const chan = yield call(
    AssessmentApi.getSelectedStudentSavedAssessment,
    studentId,
    savedAssessmentId,
    firebase
  );
  try {
    while (true) {
      let data = yield take(chan);
      let selectedStudentAssessment = data;
      yield put({
        type: actions.GET_STUDENT_ASSESSMENT_SUCCESSFUL,
        studentAssessmentRecord: selectedStudentAssessment,
        studentAssessmentChannel: chan,
        savedAssessmentId: savedAssessmentId,
      });
    }
  } finally {
    console.log("end student assessment channel");
  }
}

function* saveSelectedStudentAssessment({
  assessmentObj,
  firebase,
  savedAssessmentId,
}) {
  try {
    console.log("ass obj", assessmentObj);
    let node;
    if (savedAssessmentId) {
      node = savedAssessmentId;
    } else {
      node = yield call(
        AssessmentApi.generateSaveStudentAssessmentNode,
        firebase
      );
    }
    let obj = assessmentObj;
    obj.id = node;
    yield call(AssessmentApi.saveChildAssessment, node, obj, firebase);
    yield put({
      type: actions.SAVE_STUDENT_ASSESSMENT_SUCCESSFUL,
      savedAssessmentId: node,
    });
  } catch (err) {
    console.log("failed to save child assessment", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_ASSESSMENT_REQUEST_FAILED,
    });
  }
}

function* fetchSavedAssessment({ studentId, firebase }) {
  try {
    let data = yield call(
      AssessmentApi.getSavedAssessments,
      studentId,
      firebase
    );
    if (data) {
      yield put({
        type: actions.GET_SAVED_ASSESSMENT_RECORD_SUCCESS,
        savedAssessments: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch student saved assessment", err);
    bugsnagClient.notify(err);
  }
}

function* saveAssessmentNote({
  assessment,
  firebase,
  studentId,
  savedAssessmentId,
}) {
  try {
    let node;
    if (savedAssessmentId) {
      node = savedAssessmentId;
    } else {
      node = yield call(
        AssessmentApi.generateSaveStudentAssessmentNode,
        firebase
      );
    }

    yield call(
      AssessmentApi.saveStudentAssessmentNote,
      assessment,
      firebase,
      studentId,
      node
    );
    yield put({
      type: actions.SAVE_STUDENT_ASSESSMENT_NOTE_SUCCESS,
    });
  } catch (err) {
    console.log("failed to save student assessment notes", err);
    bugsnagClient.notify(err);
  }
}

function* saveAssessmentAttachment({
  fileList,
  allMilestones,
  selectedItem,
  firebase,
  selectedType,
  mainIndex,
  studentId,
  savedAssessmentId,
}) {
  try {
    let attachment = { fileList: fileList };
    let storagePath = firebase.sbp + "/media/images/";
    let urls = [];

    if (attachment.fileList && attachment.fileList.length > 0) {
      urls = yield call(
        ActivityApi.getMediaPath,
        storagePath,
        attachment,
        firebase
      );
    }

    if (urls) {
      let mediaPaths = urls[0] ? urls[0] : null;
      let tempAllMilestones = allMilestones;
      if (selectedType === "DA") {
        let currentIndex = tempAllMilestones.milestones.indexOf(selectedItem);

        let tempSelectedItem = selectedItem;
        tempSelectedItem.mediaPath = mediaPaths;
        tempSelectedItem.mediaType = mediaPaths ? "photo" : null;
        tempAllMilestones.milestones[currentIndex] = tempSelectedItem;
      } else if (selectedType === "MI") {
        let tempAssessmentMilestones =
          tempAllMilestones.milestones[mainIndex].assessmentMilestones;
        let currentIndex = tempAssessmentMilestones.indexOf(selectedItem);

        let tempSelectedItem = selectedItem;
        tempSelectedItem.mediaPath = mediaPaths;
        tempSelectedItem.mediaType = mediaPaths ? "photo" : null;

        tempAllMilestones.milestones[mainIndex].assessmentMilestones[
          currentIndex
        ] = tempSelectedItem;
      }

      let node;
      if (savedAssessmentId) {
        node = savedAssessmentId;
      } else {
        node = yield call(
          AssessmentApi.generateSaveStudentAssessmentNode,
          firebase
        );
      }

      yield call(
        AssessmentApi.saveStudentAssessmentNote,
        tempAllMilestones,
        firebase,
        studentId,
        node
      );

      yield put({
        type: actions.SAVE_STUDENT_ASSESSMENT_ATTACHMENT_SUCCESS,
      });
    }
  } catch (err) {
    console.log("failed to save student assessment attachment", err);
    bugsnagClient.notify(err);
  }
}

function* deleteStudentSavedAssessment({ record, firebase }) {
  try {
    yield call(AssessmentApi.deleteSavedAssessment, record, firebase);
    yield put({
      type: actions.DELETE_SAVED_ASSESSMENT_SUCCESS,
    });
  } catch (err) {
    console.log("failed to delete saved assessment", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_ASSESSMENT_REQUEST_FAILED,
    });
  }
}

function* addNewProgressParam({ name, firebase }) {
  try {
    let nodeId = yield call(AssessmentApi.generateProgressParamNode, firebase);
    yield call(AssessmentApi.addProgressParam, nodeId, name, firebase);
    yield put({
      type: actions.SAVE_PROGRESS_PARAM_SUCCESS,
    });
  } catch (err) {
    console.log("failed to add new progress param", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_ASSESSMENT_REQUEST_FAILED,
    });
  }
}

function* fetchProgressParams({ firebase }) {
  try {
    let data = yield call(AssessmentApi.getProgressParams, firebase);
    if (data) {
      yield put({
        type: actions.GET_PROGRESS_PARAM_SUCCESS,
        progressParams: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch progress params", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_ASSESSMENT_REQUEST_FAILED,
    });
  }
}

function* deleteSelectedProgressParam({ record, firebase }) {
  try {
    yield call(AssessmentApi.deleteProgressParams, record, firebase);
    yield put({
      type: actions.DELETE_PROGRESS_PARAM_SUCCESS,
    });
  } catch (err) {
    console.log("failed to delete progress params", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_ASSESSMENT_REQUEST_FAILED,
    });
  }
}

function* saveSelectedAssessmentTitle({ record, firebase }) {
  try {
    let node;
    if (record.id) {
      node = record.id;
    } else {
      node = yield call(
        AssessmentApi.generateSaveStudentAssessmentNode,
        firebase
      );
    }

    yield call(AssessmentApi.saveChildAssessment, node, record, firebase);
    yield put({
      type: actions.SAVE_ASSESSMENT_TITLE_SUCCESS,
    });
  } catch (err) {
    console.log("failed to update assessment title", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_ASSESSMENT_REQUEST_FAILED,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(
      actions.LIST_ASSESSMENT_MILESTONES,
      fetchAllAssessmentMilestones
    ),
    yield takeLatest(
      actions.GENERATE_STUDENT_ASSESSMENT_NODE_ID,
      createNewStudentAssessmentNode
    ),
    yield takeLatest(
      actions.SAVE_SELECTED_DOMAIN_TO_STUDENT_ASSESSMENT,
      saveNewDomainToStudentAssessment
    ),
    yield takeLatest(
      actions.SAVE_SELECTED_MILESTONE_TO_STUDENT_ASSESSMENT,
      saveNewMilestoneToStudentAssessment
    ),
    yield takeLatest(
      actions.GET_LABELS_FOR_ASSESSMENT,
      fetchLabelForAssessment
    ),
    yield takeLatest(actions.GET_STUDENT_ASSESSMENT, fetchStudentAssessment),
    yield takeLatest(
      actions.SAVE_STUDENT_ASSESSMENT,
      saveSelectedStudentAssessment
    ),
    yield takeLatest(actions.GET_SAVED_ASSESSMENT_RECORD, fetchSavedAssessment),
    yield takeLatest(actions.SAVE_STUDENT_ASSESSMENT_NOTE, saveAssessmentNote),
    yield takeLatest(
      actions.SAVE_STUDENT_ASSESSMENT_ATTACHMENT,
      saveAssessmentAttachment
    ),
    yield takeLatest(actions.GET_STD_ASS_DEV_AREAS, fetchAssessmentDevAreas),
    yield takeLatest(
      actions.DELETE_SAVED_ASSESSMENT,
      deleteStudentSavedAssessment
    ),
    yield takeLatest(actions.SAVE_PROGRESS_PARAM, addNewProgressParam),
    yield takeLatest(actions.GET_PROGRESS_PARAM, fetchProgressParams),
    yield takeLatest(
      actions.DELETE_PROGRESS_PARAM,
      deleteSelectedProgressParam
    ),
    yield takeLatest(
      actions.SAVE_ASSESSMENT_TITLE,
      saveSelectedAssessmentTitle
    ),
  ]);
}
