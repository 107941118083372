import "@firebase/firestore"; // 👈 If you're using firestore
import { eventChannel } from "redux-saga";
import moment from "moment-timezone";

function createNewProgramNode(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/programs").push().key;
  return key;
}

function getAllProgram(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  return eventChannel((emit) => {
    rsf.ref(branchPath + "/programs").on("value", (snap) => {
      var x = [];
      snap.forEach((element) => {
        if (element.val() !== null) {
          let classList =
            firebase && firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            if (classList.length > 0) {
              let activityClasses = element.val().classroom;
              for (let index in classList) {
                let filteredVal = activityClasses.filter((f) => {
                  return (
                    f.name.toLowerCase() === classList[index].toLowerCase()
                  );
                });

                if (filteredVal && filteredVal.length > 0) {
                  x.push(element.val());
                  break;
                }
              }
            } else {
              x.push(element.val());
            }
          } else {
            x.push(element.val());
          }
        }
      });
      emit(x);
    });
    return () => {
      rsf.ref(branchPath + "/programs").off();
      console.log("unsubscribe programs");
    };
  });
}

function getProgramClassroom(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var programPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/classrooms")
      .once("value")
      .then(function(snap) {
        var programClassroom = [];
        snap.forEach((element) => {
          if (element !== null) {
            programClassroom.push(element.val());
          }
        });
        resolve(programClassroom);
      });
  });
  return programPromise;
}

function addProgram(value, classroom, nodeId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/programs/" + nodeId).set({
    name: value.name.charAt(0).toUpperCase() + value.name.slice(1),
    startDate: moment(value.dateRange[0]).valueOf(),
    endDate: moment(value.dateRange[1]).valueOf(),
    weekDay: value.dayOfWeek,
    startTime: moment(value.startTime).valueOf(),
    endTime: moment(value.endTime).valueOf(),
    classroom: classroom,
    id: nodeId,
    minCapacity: value.minCapacity ? value.minCapacity : null,
    maxCapacity: value.maxCapacity ? value.maxCapacity : null,
  });
}

function updateProgram(value, classroom, record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/programs/" + record.id).update({
    name: value.name.charAt(0).toUpperCase() + value.name.slice(1),
    startDate: moment(value.dateRange[0]).valueOf(),
    endDate: moment(value.dateRange[1]).valueOf(),
    weekDay: value.dayOfWeek,
    startTime: moment(value.startTime).valueOf(),
    endTime: moment(value.endTime).valueOf(),
    classroom: classroom,
    minCapacity: value.minCapacity ? value.minCapacity : null,
    maxCapacity: value.maxCapacity ? value.maxCapacity : null,
  });
}

function getAllFeePlan(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var plan = [];
  var planPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/feePlan")
      .once("value")
      .then(function(snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            plan.push(snap.val());
          }
        });
        resolve(plan);
      });
  });
  return planPromise;
}

function assignStudentsToProgram(
  value,
  selectedStudentCheckbox,
  selectedProgram,
  firebase
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var studentArray = [];
  if (selectedProgram.student !== undefined) {
    studentArray = selectedProgram.student;
  }
  selectedStudentCheckbox.forEach((item) => {
    studentArray.push({
      name: item.name,
      studentId: item.id,
      startDate: moment(value.dateRange[0]).valueOf(),
      endDate: moment(value.dateRange[1]).valueOf(),
      classroomName: item.classroomName,
      classList: item.classList ? item.classList : [],
      classroomId: item.classId ? item.classId : null,
    });
  });
  rsf.ref(branchPath + "/programs/" + selectedProgram.id).update({
    student: studentArray,
  });
}

function assignStudentsFromDetailedProgram(selectedProgram, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/programs/" + selectedProgram.id).update({
    student: selectedProgram.student,
  });
}

function deleteStudentFromProgram(selectedProgram, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/programs/" + selectedProgram.id)
    .update(selectedProgram);
}

function addFeePlanToProgram(
  values,
  selectedFeePlan,
  selectedProgram,
  firebase
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/programs/" + selectedProgram.id).update({
    feePlan: selectedFeePlan,
  });
}

function addFeePlanToDetailedProgram(selectedProgram, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/programs/" + selectedProgram.id).update({
    feePlan: selectedProgram.feePlan,
  });
}

function fetchPrograms(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var plan = [];
  var planPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/programs")
      .once("value")
      .then(function(snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            let classList =
              firebase && firebase.teacher && firebase.teacher.classList
                ? firebase.teacher.classList
                : [];
            if (firebase.teacher && !firebase.teacher.superUser) {
              if (classList.length > 0) {
                let activityClasses = snap.val().classroom;
                for (let index in classList) {
                  let filteredVal = activityClasses.filter((f) => {
                    return (
                      f.name.toLowerCase() === classList[index].toLowerCase()
                    );
                  });

                  if (filteredVal && filteredVal.length > 0) {
                    plan.push(snap.val());
                    break;
                  }
                }
              } else {
                plan.push(snap.val());
              }
            } else {
              plan.push(snap.val());
            }
          }
        });
        resolve(plan);
      });
  });
  return planPromise;
}

function fetchProgramById(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var plan = {};
  var planPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/programs/" + id)
      .once("value")
      .then(function(snapshot) {
        if (snapshot.val() !== null) {
          let classList =
            firebase && firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            if (classList.length > 0) {
              let activityClasses = snapshot.val().classroom;
              for (let index in classList) {
                let filteredVal = activityClasses.filter((f) => {
                  return (
                    f.name.toLowerCase() === classList[index].toLowerCase()
                  );
                });

                if (filteredVal && filteredVal.length > 0) {
                  plan = snapshot.val();
                  break;
                }
              }
            } else {
              plan = snapshot.val();
            }
          } else {
            plan = snapshot.val();
          }
        }
        resolve(plan);
      });
  });
  return planPromise;
}

function deleteProgram(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/programs/" + record.id).set(null);
}

export const ProgramApi = {
  createNewProgramNode,
  addProgram,
  getAllProgram,
  getProgramClassroom,
  updateProgram,
  getAllFeePlan,
  assignStudentsToProgram,
  deleteStudentFromProgram,
  assignStudentsFromDetailedProgram,
  addFeePlanToProgram,
  addFeePlanToDetailedProgram,
  fetchPrograms,
  fetchProgramById,
  deleteProgram,
};
