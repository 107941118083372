import { all, put, call, take, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { FeeApi } from "../../firestore-api/fee";
import { ProgramApi } from "../../firestore-api/program";
import { InvoiceApi } from "../../firestore-api/invoice";
import bugsnagClient from "@bugsnag/js";
import { NotificationApi } from "../../firestore-api/notification";
import moment from "moment-timezone";

function* fetchFeeComponent({ firebase }) {
  const chan = yield call(FeeApi.getFeeComponent, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.LIST_FEE_COMPONENTS_SUCCESSFFUL,
        feeComponent: data,
        feeComponentChannel: chan,
      });
    }
  } finally {
    console.log("end fee component channel");
  }
}

function* fetchFeePlan({ firebase }) {
  const chan = yield call(FeeApi.getFeePlan, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.LIST_FEE_PLANS_SUCCESSFFUL,
        feePlan: data,
        feePlanChannel: chan,
      });
    }
  } finally {
    console.log("end fee plan channel");
  }
}

function* addNewFeePlan({ value, customDates, firebase }) {
  try {
    var key = yield call(FeeApi.createNewFeePlanNode, firebase);
    yield call(FeeApi.addFeePlan, value, key, firebase, customDates);
    yield put({
      type: actions.ADD_FEE_PLAN_SUCCESSFFUL,
    });
  } catch (error) {
    console.log("failed to add new fee plan", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

function* updateExistingFeePlan({
  value,
  editableFeePlan,
  customDateArr,
  firebase,
}) {
  try {
    yield call(
      FeeApi.updateFeePlan,
      value,
      editableFeePlan,
      firebase,
      customDateArr
    );
    let students = editableFeePlan.student ? editableFeePlan.student : [];
    let feePlanId = editableFeePlan.id;
    for (let index in students) {
      let stdFeePlan = yield call(
        FeeApi.getStudentFeePlanByPlanId,
        students[index].studentId,
        feePlanId,
        firebase
      );
      if (stdFeePlan) {
        let newStudentFeePlan = stdFeePlan;
        newStudentFeePlan.planName =
          value.name.charAt(0).toUpperCase() + value.name.slice(1);
        newStudentFeePlan.generationDate = value.generationDate
          ? moment(value.generationDate).valueOf()
          : null;
        newStudentFeePlan.startDate = value.dateRange
          ? moment(value.dateRange[0])
              .startOf("day")
              .valueOf()
          : null;
        newStudentFeePlan.endDate = value.dateRange
          ? moment(value.dateRange[1])
              .startOf("day")
              .valueOf()
          : null;
        yield call(
          FeeApi.updateStudentFeePlan,
          students[index].studentId,
          newStudentFeePlan,
          firebase
        );
      }
    }
    yield put({
      type: actions.UPDATE_FEE_PLAN_SUCCESSFFUL,
    });
  } catch (error) {
    console.log("failed to update fee plan", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

function* addNewFeeComponent({ value, firebase }) {
  try {
    var key = yield call(FeeApi.createNewFeeComponentNode, firebase);
    yield call(FeeApi.addFeeComponent, value, key, firebase);
    yield put({
      type: actions.ADD_FEE_COMPONENT_SUCCESSFFUL,
    });
  } catch (error) {
    console.log("failed to add new fee component", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

function* updateExistingFeeComponent({
  value,
  editableFeeComponent,
  firebase,
}) {
  try {
    yield call(
      FeeApi.updateFeeComponent,
      value,
      editableFeeComponent,
      firebase
    );
    yield put({
      type: actions.UPDATE_FEE_COMPONENT_SUCCESSFFUL,
    });
  } catch (error) {
    console.log("failed to update fee component", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

function* attachFeeComponentToFeePlan({ selectedComponent, record, firebase }) {
  try {
    yield call(
      FeeApi.attachFeeComponentToFeePlan,
      selectedComponent,
      record.id,
      firebase
    );

    if (record.student) {
      let students = record.student;
      let feePlanId = record.id;
      for (let index in students) {
        let stdFeePlan = yield call(
          FeeApi.getStudentFeePlanByPlanId,
          students[index].studentId,
          feePlanId,
          firebase
        );
        if (stdFeePlan) {
          let newStudentFeePlan = stdFeePlan;
          let prevStudentFeeComponent = [];

          for (let i in selectedComponent) {
            let feeComponent = yield call(
              FeeApi.getFeeComponentById,
              selectedComponent[i],
              firebase
            );
            if (feeComponent && feeComponent.id) {
              var data = {};
              data.amount = feeComponent.amount;
              data.discount = feeComponent.discount;
              data.discountType = feeComponent.discountType;
              data.id = feeComponent.id;
              data.inverseDate = -moment().valueOf();
              data.isRefundable = feeComponent.isRefundable;
              data.name = feeComponent.name;
              data.paymentFrequency = feeComponent.paymentFrequency;
              data.paymentSchedule = feeComponent.paymentSchedule;
              prevStudentFeeComponent.push(data);
            }
          }
          newStudentFeePlan.studentFeeComponent = prevStudentFeeComponent;
          newStudentFeePlan.feeComponent = selectedComponent;
          yield call(
            FeeApi.updateStudentFeePlan,
            students[index].studentId,
            newStudentFeePlan,
            firebase
          );
        }
      }
    }
  } catch (error) {
    console.log("failed to attach fee component", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

function* editPop({ feeComponent }) {
  yield put({
    type: actions.POP_EDIT_SUCCESSFFUL,
    editableSelectedComponent: feeComponent,
  });
}

function* resetPop() {
  yield put({
    type: actions.RESET_POP_EDIT_SUCCESSFFUL,
    editableSelectedComponent: undefined,
  });
}

function* getStudentsForFeePlan({ firebase }) {
  try {
    //let data = yield call(AssessmentApi.getAllStudents, firebase);
    let data = JSON.parse(localStorage.getItem("studentList"));
    if (data) {
      yield put({
        type: actions.FEE_PLAN_FETCH_STUDENT_SUCCESSFFUL,
        students: data,
      });
    }
  } catch (error) {
    console.log("failed to fetch students for fee plan", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

function* assignSelectedStudentToFeePlan({
  formValue,
  studentList,
  selectedFeePlan,
  rows,
  total,
  showGeneratedInvoice,
  firebase,
}) {
  console.log("formValue", formValue);
  console.log("studentList", studentList);
  console.log("selectedFeePlan", selectedFeePlan);
  console.log("rows", rows);
  console.log("total", total);
  console.log("firebase", firebase);
  try {
    var invoiceStudentArr = [];
    var studentArray = [];
    if (selectedFeePlan.student !== undefined) {
      studentArray = selectedFeePlan.student;
    }

    for (let index in studentList) {
      let filterVal = studentArray.filter((i) => {
        return i.studentId === studentList[index].id;
      });
      if (filterVal === undefined || filterVal.length === 0) {
        studentArray.push({
          name: studentList[index].name,
          studentId: studentList[index].id,
          startDate: moment(formValue.dateRange[0]).valueOf(),
          endDate: moment(formValue.dateRange[1]).valueOf(),
          classroomName: studentList[index].classroomName,
          classList: studentList[index].classList
            ? studentList[index].classList
            : [],
          active: true,
        });

        invoiceStudentArr.push({
          name: studentList[index].name,
          studentId: studentList[index].id,
          startDate: moment(formValue.dateRange[0]).valueOf(),
          endDate: moment(formValue.dateRange[1]).valueOf(),
          classroomName: studentList[index].classroomName,
          classList: studentList[index].classList
            ? studentList[index].classList
            : [],
          active: true,
        });
      }
    }

    let presentFeePlan = JSON.parse(JSON.stringify(selectedFeePlan));
    presentFeePlan.student = studentArray;

    yield call(
      FeeApi.assignStudentsToFeePlan,
      // formValue,
      // studentList,
      // selectedFeePlan,
      presentFeePlan,
      firebase
    );

    let lineItems = [];
    for (let ind in rows) {
      lineItems.push({
        amount: Number(rows[ind].grossValue),
        discount: Number(rows[ind].discount),
        discountType: rows[ind].discountType,
        id: rows[ind].id,
        inverseDate: -new Date(),
        isRefundable: rows[ind].isRefundable,
        name: rows[ind].description,
        paymentFrequency: rows[ind].paymentFrequency,
        paymentSchedule: rows[ind].paymentSchedule,
      });
    }

    if (
      selectedFeePlan.paymentMode &&
      selectedFeePlan.paymentMode === "Subscription"
    ) {
      presentFeePlan.generationDate = moment(formValue.dateRange[0]).valueOf();
    }

    presentFeePlan.studentFeeComponent = lineItems;
    for (let i in studentList) {
      yield call(
        FeeApi.updateStudentFeePlan,
        studentList[i].id,
        presentFeePlan,
        firebase
      );
      yield call(
        NotificationApi.callFeePlanStudentAssignWebHook,
        studentList[i],
        presentFeePlan,
        moment(formValue.dateRange[0]).valueOf(),
        moment(formValue.dateRange[1]).valueOf(),
        firebase,
        "Subscription_Added"
      );
    }

    if (showGeneratedInvoice) {
      let feePlanObject = JSON.parse(JSON.stringify(presentFeePlan));
      feePlanObject.student = invoiceStudentArr;
      yield call(
        NotificationApi.invoiceGenerationRequest,
        feePlanObject,
        firebase
      );
    }

    yield put({
      type: actions.ASSIGN_STUDENT_TO_FEE_PLAN_SUCCESSFUL,
      programOperationType: "ASSIGN_STUDENT",
    });
  } catch (error) {
    console.log("failed to assign student to program", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

function* getCompleteFeeComponent({ firebase }) {
  try {
    let data = yield call(FeeApi.getAllFeeComponent, firebase);
    if (data) {
      yield put({
        type: actions.GET_COMPLETE_FEE_COMPONENTS_SUCCESSFUL,
        feeComponent: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch fee components", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

function* fetchFeePrograms({ firebase }) {
  try {
    let data = yield call(ProgramApi.fetchPrograms, firebase);
    yield put({
      programs: data,
      type: actions.GET_FEE_PROGRAMS_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to fetch fee programs", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

function* deleteSelectedFeePlan({ record, firebase }) {
  try {
    yield call(FeeApi.deleteFeePlan, record, firebase);
    yield put({
      type: actions.DELETE_FEE_PLAN_SUCCESS,
    });
  } catch (err) {
    console.log("failed to delete fee plan", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

function* fetchComponentAmountCollection({
  startDate,
  endDate,
  feeComponent,
  firebase,
}) {
  try {
    let allInvoices = [];
    let feePlans = yield call(FeeApi.getFeeAllPlans, firebase);
    if (feePlans && feePlans.length > 0) {
      for (let f in feePlans) {
        if (
          feePlans[f].feeComponent &&
          feePlans[f].feeComponent.includes(feeComponent.id)
        ) {
          let invoice = yield call(
            InvoiceApi.getInvoiceByFeePlanIdAndDateRange,
            feePlans[f].id,
            startDate,
            endDate,
            firebase,
            feeComponent.name
          );
          allInvoices = [...allInvoices, ...invoice];
        }
      }
    }
    yield put({
      type: actions.GET_SELECTED_COMPONENT_AMOUNT_COLLECTED_SUCCESS,
      componentInvoices: allInvoices,
    });
  } catch (err) {
    console.log("failed to fee component amount collection", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.LIST_FEE_COMPONENTS, fetchFeeComponent),
    yield takeLatest(actions.LIST_FEE_PLANS, fetchFeePlan),
    yield takeLatest(actions.ADD_FEE_PLAN, addNewFeePlan),
    yield takeLatest(actions.UPDATE_FEE_PLAN, updateExistingFeePlan),
    yield takeLatest(actions.ADD_FEE_COMPONENT, addNewFeeComponent),
    yield takeLatest(actions.UPDATE_FEE_COMPONENT, updateExistingFeeComponent),
    yield takeLatest(actions.ATTACH_FEE_COMPONENT, attachFeeComponentToFeePlan),
    yield takeLatest(actions.POP_EDIT, editPop),
    yield takeLatest(actions.RESET_POP_EDIT, resetPop),
    yield takeLatest(actions.FEE_PLAN_FETCH_STUDENT, getStudentsForFeePlan),
    yield takeLatest(
      actions.ASSIGN_STUDENT_TO_FEE_PLAN,
      assignSelectedStudentToFeePlan
    ),
    yield takeLatest(
      actions.GET_COMPLETE_FEE_COMPONENTS,
      getCompleteFeeComponent
    ),
    yield takeLatest(actions.GET_FEE_PROGRAMS, fetchFeePrograms),
    yield takeLatest(actions.DELETE_FEE_PLAN, deleteSelectedFeePlan),
    yield takeLatest(
      actions.GET_SELECTED_COMPONENT_AMOUNT_COLLECTED,
      fetchComponentAmountCollection
    ),
  ]);
}
