import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import '@firebase/firestore' // 👈 If you're using firestore
import { SignupApi } from '../../firestore-api/signup';
import bugsnagClient from '@bugsnag/js';



function* signUpAccount({ email, password, countryCode, phoneNumber, name, address, schoolName, firebase }) {
    try {
        var status = yield call(SignupApi.signupAccount, email, password, countryCode, phoneNumber, name, address, schoolName, firebase);
        if (status) {
            yield put({
                type: actions.SIGNUP_REQUEST_SUCCESS,
                signupStatus: status
            })
        }
    } catch (error) {
        console.log("error", error);
        bugsnagClient.notify(error);
    }


}

export default function* rootSaga() {
    yield all([
        yield takeEvery(actions.SIGNUP_REQUEST, signUpAccount),
    ]);
}
