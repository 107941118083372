import actions from "./actions";
const initState = {
    singleSubmissionLoading: false,
    submissionActivity: [],
    taggedStudent: [],
    singleAssignmentSubmission: [],
    kidsLoading: false,
    pendingStudents: [],
    singleSubmissionComments: new Map(),
    singleSubmissionOperation: undefined,
    submissionActivityLesson: {}
}

export default function singleSubmissionReducer(
    state = initState,
    { type, ...action }
) {
    switch (type) {


        case actions.GET_SUBMISSION_ACTIVITY:
            return {
                ...state,
                singleSubmissionLoading: true,
                submissionActivity: [],
                taggedStudent: [],
                singleAssignmentSubmission: [],
                submissionActivityLesson: {}
            };

        case actions.GET_SUBMISSION_ACTIVITY_SUCCESSFUL:
            return {
                ...state,
                singleSubmissionLoading: false,
                submissionActivity: action.submissionActivity,
                submissionActivityLesson: action.submissionActivityLesson
            };

        case actions.GET_PENDING_STUDENT_FOR_SUBMISSION:
            return {
                ...state,
                kidsLoading: true,
                pendingStudents: []
            };

        case actions.GET_PENDING_STUDENT_FOR_SUBMISSION_SUCCESSFUL:
            return {
                ...state,
                taggedStudent: action.taggedStudent,
                singleAssignmentSubmission: action.singleAssignmentSubmission,
                kidsLoading: false,
                pendingStudents: action.pendingStudents
            };

        case actions.SINGLE_SUBMISSION_REQUEST_FAIL:
            return {
                ...state,
                singleSubmissionLoading: false,
                error: true
            };

        case actions.GET_SINGLE_SUBMISSION_COMMENT:
            return {
                ...state,
                singleSubmissionComments: new Map()
            };

        case actions.GET_SINGLE_SUBMISSION_COMMENT_SUCCESS:
            return {
                ...state,
                singleSubmissionComments: action.singleSubmissionComments
            };

        case actions.MARK_SINGLE_ASSIGNMENT_STAR:
            return {
                ...state,
            };

        case actions.MARK_SINGLE_ASSIGNMENT_STAR_SUCCESS:
            return {
                ...state,
                singleSubmissionOperation: "star_marked"
            };

        case actions.RESET_SINGLE_SUBMISSION_OPERATION:
            return {
                ...state,
                singleSubmissionOperation: undefined,
                singleSubmissionLoading: false,
                error: false
            };

        default:
            return state;
    }
}