import "@firebase/firestore";
import { all, call, put, takeLatest, take, fork } from "redux-saga/effects";
import { ComplainsApi } from "../../firestore-api/consult";
import { StudentApi } from "../../firestore-api/student";
import { NotificationApi } from "../../firestore-api/notification";
import { CommentApi } from "../../firestore-api/comment";
import actions from "./actions";
import { lessonAssignmentApi } from "../../firestore-api/lessonAssignment";
import bugsnagClient from "@bugsnag/js";
import moment from "moment-timezone";

function* fetchAllLeaves({
  firebase,
  startDate,
  endDate,
  initialCall,
  selectedFrequency,
}) {
  const chan = yield call(
    ComplainsApi.getAllLeaves,
    firebase,
    startDate,
    endDate,
    selectedFrequency
  );
  try {
    while (true) {
      let data = yield take(chan);
      let x = data;
      x.sort(function(a, b) {
        var dateA = a.appliedDate,
          dateB = b.appliedDate;
        return dateB - dateA;
      });
      yield put({
        type: actions.GET_ALL_LEAVES_SUCCESSFUL,
        leaves: x,
        leavesChannel: chan,
        operationType: initialCall ? "INITIAL_CALL" : undefined,
      });
    }
  } finally {
    console.log("end leaves channel");
  }
}

function* fetchNotificationLeave({ id, firebase }) {
  const chan = yield call(ComplainsApi.getLeaveById, id, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_ALL_LEAVES_SUCCESSFUL,
        leaves: data,
        leavesChannel: chan,
        operationType: undefined,
      });
    }
  } finally {
    console.log("terminating notification leaves");
  }
}

function* fetchCommentsForSelectedLeave({ leaves, firebase }) {
  try {
    let activities = leaves;
    var commentsCount = new Map();
    for (const item in activities) {
      var commentCountObj = yield call(
        lessonAssignmentApi.getCommentsCount,
        activities[item].id,
        firebase
      );
      if (commentCountObj) {
        commentsCount.set(activities[item].id, commentCountObj);
      }
    }

    yield put({
      type: actions.GET_COMMENT_FOR_LEAVE_SUCCESSFUL,
      leavesComment: commentsCount,
    });
  } catch (error) {
    console.log("failed to fetch comment for selected leave", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.LEAVE_REQUEST_FAILED,
    });
  }
}

function* resolveSelectedLeave({ record, status, firebase }) {
  try {
    let activityId = record.id;
    let activityName = "LEAVE";
    let body;
    let task = record;
    if (status === "deny") {
      body = "Admin denied  leave request";
      task.status = "denied";
    } else if (status === "approve") {
      body = "Admin updated  leave request";
      task.status = "approved";
    }

    yield fork(ComplainsApi.updateLeave, task, firebase);
    let selectedStudent = yield call(
      StudentApi.getStudentById,
      record.studentId,
      firebase
    );
    if (selectedStudent && selectedStudent.id) {
      if (selectedStudent.fatherProfileId) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          selectedStudent.fatherProfileId,
          firebase
        );
        yield fork(
          NotificationApi.createAlertNotification,
          activityName,
          activityId,
          selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
          body,
          alertNode,
          selectedStudent.ios_fatherUUid
            ? selectedStudent.ios_fatherUUid
            : null,
          selectedStudent.id,
          selectedStudent.fatherProfileId,
          firebase
        );

        if (
          selectedStudent.fatherUUid !== undefined ||
          selectedStudent.ios_fatherUUid !== undefined
        ) {
          yield fork(
            NotificationApi.sendPushNotification,
            activityName,
            activityId,
            selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
            body,
            alertNode,
            selectedStudent.ios_fatherUUid
              ? selectedStudent.ios_fatherUUid
              : null,
            selectedStudent.id,
            selectedStudent.fatherProfileId,
            firebase
          );
        }
      }

      if (selectedStudent.motherProfileId) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          selectedStudent.motherProfileId,
          firebase
        );
        yield fork(
          NotificationApi.createAlertNotification,
          activityName,
          activityId,
          selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
          body,
          alertNode,
          selectedStudent.ios_motherUUid
            ? selectedStudent.ios_motherUUid
            : null,
          selectedStudent.id,
          selectedStudent.motherProfileId,
          firebase
        );

        if (
          selectedStudent.motherUUid !== undefined ||
          selectedStudent.ios_motherUUid !== undefined
        ) {
          yield fork(
            NotificationApi.sendPushNotification,
            activityName,
            activityId,
            selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
            body,
            alertNode,
            selectedStudent.ios_motherUUid
              ? selectedStudent.ios_motherUUid
              : null,
            selectedStudent.id,
            selectedStudent.motherProfileId,
            firebase
          );
        }
      }
    }

    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "communication",
      moment()
    );
    yield put({
      type: actions.RESOLVE_LEAVE_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to resolve leave", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAVE_REQUEST_FAILED,
    });
  }
}

function* addLeaveComment({ comment, dataSource, firebase }) {
  try {
    var commentObject = {
      author: firebase.teacher.name,
      authorId: firebase.teacher.id,
      id: "",
      gender: firebase.teacher.gender ? firebase.teacher.gender : null,
      inverseTimestamp: -new Date().getTime(),
      sourceId: dataSource.id,
      src: "COMMENT",
      text: comment.comment,
      timestamp: new Date().getTime(),
      userType: "TEACHER",
    };
    let nodeId = yield call(
      CommentApi.createCommentNode,
      dataSource.id,
      firebase
    );
    commentObject.id = nodeId;
    yield call(
      CommentApi.updateComment,
      commentObject,
      dataSource.id,
      nodeId,
      firebase
    );
    yield put({
      type: actions.SAVE_LEAVE_COMMENT_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to add leave comment", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAVE_REQUEST_FAILED,
    });
  }
}

function* fetchStudentOnLeave({ firebase }) {
  try {
    let startTime = moment()
      .startOf("day")
      .valueOf();
    let endTime = moment()
      .endOf("week")
      .valueOf();
    let leaves = yield call(
      ComplainsApi.getAllPendingLeaves,
      firebase,
      startTime,
      endTime
    );

    let currentDayLeave = [];
    let currentWeekLeave = [];
    if (leaves) {
      currentWeekLeave = leaves;
      for (let index in leaves) {
        let singleLeave = leaves[index];
        if (
          moment(startTime).isSame(moment(singleLeave.startDate), "day") ||
          moment(startTime).isBetween(
            moment(singleLeave.startDate),
            moment(singleLeave.endTime),
            "day",
            "[]"
          )
        ) {
          currentDayLeave.push(singleLeave);
        }
      }
    }

    console.log("studentLeaveToday", currentDayLeave);
    console.log("studentLeaveWeek", currentWeekLeave);

    yield put({
      type: actions.GET_STUDENTS_ON_LEAVE_SUCCESS,
      studentLeaveToday: currentDayLeave,
      studentLeaveWeek: currentWeekLeave,
    });
  } catch (err) {
    console.log("failed to fetch student on leave", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAVE_REQUEST_FAILED,
    });
  }
}

function* fetchAllStaffLeaves({
  firebase,
  startDate,
  endDate,
  initialCall,
  selectedFrequency,
}) {
  const chan = yield call(
    ComplainsApi.getAllStaffLeaves,
    firebase,
    startDate,
    endDate,
    selectedFrequency
  );
  try {
    while (true) {
      let data = yield take(chan);
      let x = data;
      console.log("staff leave", x);
      x.sort(function(a, b) {
        var dateA = a.appliedDate,
          dateB = b.appliedDate;
        return dateB - dateA;
      });
      yield put({
        type: actions.GET_ALL_STAFF_LEAVES_SUCCESSFUL,
        staffLeaves: x,
        staffLeavesChannel: chan,
        operationType: initialCall ? "INITIAL_CALL" : undefined,
      });
    }
  } finally {
    console.log("end leaves channel");
  }
}

function* resolveSelectedStaffLeave({ record, status, firebase }) {
  try {
    let activityId = record.id;
    let activityName = "Staff Leave";
    let body;
    let task = record;
    if (status === "deny") {
      body = "Admin denied  leave request";
      task.status = "denied";
    } else if (status === "approve") {
      body = "Admin updated  leave request";
      task.status = "approved";
    }

    yield call(ComplainsApi.updateStaffLeave, task, firebase);
    yield fork(
      sendTeacherNotification,
      record,
      activityName,
      activityId,
      body,
      firebase
    );
    yield put({
      type: actions.RESOLVE_STAFF_LEAVE_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to resolve staff leave", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAVE_REQUEST_FAILED,
    });
  }
}

function* sendTeacherNotification(
  record,
  activityName,
  activityId,
  body,
  firebase
) {
  try {
    let activityType = "Staff Leave";
    let teachers = JSON.parse(localStorage.getItem("teacherList"));
    let teacher = teachers.filter((item) => {
      return item.id === record.studentId;
    });

    if (teacher && teacher.length > 0) {
      let singleTeacher = teacher[0];
      let alertNode = yield call(
        NotificationApi.createAlertReferenceNode,
        singleTeacher.id,
        firebase
      );

      let creator = undefined;
      yield fork(
        NotificationApi.createSimpleAlertNotification,
        activityName,
        activityId,
        singleTeacher.uid ? singleTeacher.uid : null,
        body,
        alertNode,
        singleTeacher.ios_uid ? singleTeacher.ios_uid : null,
        null,
        singleTeacher.id,
        activityType,
        undefined,
        firebase,
        creator
      );

      if (
        singleTeacher.uid !== undefined ||
        singleTeacher.ios_uid !== undefined
      ) {
        yield fork(
          NotificationApi.sendSimplePushNotification,
          activityName,
          activityId,
          singleTeacher.uid ? singleTeacher.uid : null,
          body,
          alertNode,
          singleTeacher.ios_uid ? singleTeacher.ios_uid : null,
          null,
          singleTeacher.id,
          activityType,
          undefined,
          firebase,
          creator
        );
      }
    }
  } catch (err) {
    console.log("failed to send staff leave notification", err);
    bugsnagClient.notify(err);
  }
}

function* fetchStaffOnLeave({ firebase }) {
  try {
    let startTime = moment()
      .startOf("day")
      .valueOf();
    let endTime = moment()
      .endOf("week")
      .valueOf();
    let leaves = yield call(
      ComplainsApi.getAllPendingStaffLeaves,
      firebase,
      startTime,
      endTime
    );

    let currentDayLeave = [];
    let currentWeekLeave = [];
    if (leaves) {
      currentWeekLeave = leaves;
      for (let index in leaves) {
        let singleLeave = leaves[index];
        if (
          moment(startTime).isSame(moment(singleLeave.startDate), "day") ||
          moment(startTime).isBetween(
            moment(singleLeave.startDate),
            moment(singleLeave.endTime),
            "day",
            "[]"
          )
        ) {
          currentDayLeave.push(singleLeave);
        }
      }
    }

    console.log("staffLeaveToday", currentDayLeave);
    console.log("staffLeaveWeek", currentWeekLeave);

    yield put({
      type: actions.GET_STAFFS_ON_LEAVE_SUCCESS,
      staffLeaveToday: currentDayLeave,
      staffLeaveWeek: currentWeekLeave,
    });
  } catch (err) {
    console.log("failed to fetch staffs on leave", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAVE_REQUEST_FAILED,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_ALL_LEAVES, fetchAllLeaves),
    yield takeLatest(
      actions.GET_COMMENT_FOR_LEAVE,
      fetchCommentsForSelectedLeave
    ),
    yield takeLatest(actions.RESOLVE_LEAVE, resolveSelectedLeave),
    yield takeLatest(actions.SAVE_LEAVE_COMMENT, addLeaveComment),
    yield takeLatest(actions.GET_NOTIFICATION_LEAVE, fetchNotificationLeave),
    yield takeLatest(actions.GET_STUDENTS_ON_LEAVE, fetchStudentOnLeave),
    yield takeLatest(actions.GET_ALL_STAFF_LEAVES, fetchAllStaffLeaves),
    yield takeLatest(actions.RESOLVE_STAFF_LEAVE, resolveSelectedStaffLeave),
    yield takeLatest(actions.GET_STAFFS_ON_LEAVE, fetchStaffOnLeave),
  ]);
}
