const actions = {
  LIST_MILESTONES: "LIST_MILESTONES",
  LIST_MILESTONES_SUCCESSFUL: "LIST_MILESTONES_SUCCESSFUL",

  LIST_DEV_AREA: "LIST_DEV_AREA",
  LIST_DEV_AREA_SUCCESS: "LIST_DEV_AREA_SUCCESS",

  LIST_LESSONS: "LIST_LESSONS",
  LIST_LESSONS_SUCCESSFUL: "LIST_LESSONS_SUCCESSFUL",
  SAVE_MILESTONE_DOMAIN: "SAVE_MILESTONE_DOMAIN",
  SAVE_MILESTONE_DOMAIN_SUCCESSFUL: "SAVE_MILESTONE_DOMAIN_SUCCESSFUL",
  SAVE_MILESTONE: "SAVE_MILESTONE",
  SAVE_MILESTONE_SUCCESSFUL: "SAVE_MILESTONE_SUCCESSFUL",
  GET_LABEL: "GET_LABEL",
  GET_LABEL_SUCCESSFUL: "GET_LABEL_SUCCESSFUL",
  ADD_LABEL: "ADD_LABEL",
  ADD_LABEL_SUCCESSFUL: "ADD_LABEL_SUCCESSFUL",
  UPDATE_MILESTONE_DOMAIN: "UPDATE_MILESTONE_DOMAIN",
  UPDATE_MILESTONE_DOMAIN_SUCCESSFUL: "UPDATE_MILESTONE_DOMAIN_SUCCESSFUL",
  UPDATE_MILESTONE: "UPDATE_MILESTONE",
  UPDATE_MILESTONE_SUCCESSFUL: "UPDATE_MILESTONE_SUCCESSFUL",
  LEARNING_REQUEST_FAILED: "LEARNING_REQUEST_FAILED",
  ADD_CATEGORY: "ADD_CATEGORY",
  ADD_CATEGORY_SUCCESSFUL: "ADD_CATEGORY_SUCCESSFUL",
  GET_CATEGORY: "GET_CATEGORY",
  GET_CATEGORY_SUCCESSFUL: "GET_CATEGORY_SUCCESSFUL",
  GET_LEARNING_CLASSROOM: "GET_LEARNING_CLASSROOM",
  GET_LEARNING_CLASSROOM_SUCCESSFUL: "GET_LEARNING_CLASSROOM_SUCCESSFUL",
  ADD_LESSON: "ADD_LESSON",
  ADD_LESSON_SUCCESSFUL: "ADD_LESSON_SUCCESSFUL",
  ADD_LESSON_TO_ALL_CENTERS: "ADD_LESSON_TO_ALL_CENTERS",
  ADD_LESSON_TO_ALL_CENTERS_SUCCESSFUL: "ADD_LESSON_TO_ALL_CENTERS_SUCCESSFUL",
  UPDATE_LESSON: "UPDATE_LESSON",
  UPDATE_LESSON_SUCCESSFUL: "UPDATE_LESSON_SUCCESSFUL",
  UPDATE_LESSON_TO_ALL_CENTERS: "UPDATE_LESSON_TO_ALL_CENTERS",
  UPDATE_LESSON_TO_ALL_CENTERS_SUCCESSFUL:
    "UPDATE_LESSON_TO_ALL_CENTERS_SUCCESSFUL",
  ADD_WEEKLY_PLAN: "ADD_WEEKLY_PLAN",
  ADD_WEEKLY_PLAN_SUCCESSFUL: "ADD_WEEKLY_PLAN_SUCCESSFUL",
  GET_WEEKLY_PLAN: "GET_WEEKLY_PLAN",
  GET_WEEKLY_PLAN_SUCCESSFUL: "GET_WEEKLY_PLAN_SUCCESSFUL",
  DELETE_PLAN: "DELETE_PLAN",
  DELETE_PLAN_SUCCESSFUL: "DELETE_PLAN_SUCCESSFUL",
  DELETE_LESSON_RECORD: "DELETE_LESSON_RECORD",
  DELETE_LESSON_RECORD_SUCCESSFUL: "DELETE_LESSON_RECORD_SUCCESSFUL",
  DELETE_LESSON_RECORD_FOR_ALL_CENTER: "DELETE_LESSON_RECORD_FOR_ALL_CENTER",
  DELETE_LESSON_RECORD_FOR_ALL_CENTER_SUCCESSFUL:
    "DELETE_LESSON_RECORD_FOR_ALL_CENTER_SUCCESSFUL",
  DELETE_SINGLE_MILESTONE: "DELETE_SINGLE_MILESTONE",
  DELETE_SINGLE_MILESTONE_SUCCESSFUL: "DELETE_SINGLE_MILESTONE_SUCCESSFUL",
  DELETE_SINGLE_DOMAIN: "DELETE_SINGLE_DOMAIN",
  DELETE_SINGLE_DOMAIN_SUCCESSFUL: "DELETE_SINGLE_DOMAIN_SUCCESSFUL",
  RESET_LEARNING_OPERATION_TYPE: "RESET_LEARNING_OPERATION_TYPE",
  GET_STUDENT_FOR_LESSON: "GET_STUDENT_FOR_LESSON",
  GET_STUDENT_FOR_LESSON_SUCCESSFUL: "GET_STUDENT_FOR_LESSON_SUCCESSFUL",
  SHARE_LESSON: "SHARE_LESSON",
  SHARE_LESSON_SUCCESSFUL: "SHARE_LESSON_SUCCESSFUL",
  UPLOAD_LESSON_DATA: "UPLOAD_LESSON_DATA",
  UPLOAD_LESSON_DATA_SUCCESSFUl: "UPLOAD_LESSON_DATA_SUCCESSFUl",

  UPLOAD_START: "UPLOAD_START",
  UPLOAD_FINISH: "UPLOAD_FINISH",
  UPLOAD_FAILED: "UPLOAD_FAILED",
  UPLOAD_RESET: "UPLOAD_RESET",

  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  UPDATE_CATEGORY_SUCCESS: "UPDATE_CATEGORY_SUCCESS",

  DELETE_CATEGORY: "DELETE_CATEGORY",
  DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",

  ADD_SUB_DOMAIN: "ADD_SUB_DOMAIN",
  ADD_SUB_DOMAIN_SUCCESS: "ADD_SUB_DOMAIN_SUCCESS",

  UPDATE_SUB_DOMAIN: "UPDATE_SUB_DOMAIN",
  UPDATE_SUB_DOMAIN_SUCCESS: "UPDATE_SUB_DOMAIN_SUCCESS",

  GET_ASSESSMENT_PROGRESS_PARAM: "GET_ASSESSMENT_PROGRESS_PARAM",
  GET_ASSESSMENT_PROGRESS_PARAM_SUCCESS:
    "GET_ASSESSMENT_PROGRESS_PARAM_SUCCESS",

  SAVE_ASS_PROGRESS_PARAM: "SAVE_ASS_PROGRESS_PARAM",
  SAVE_ASS_PROGRESS_PARAM_SUCCESS: "SAVE_ASS_PROGRESS_PARAM_SUCCESS",

  DELETE_ASS_PROGRESS_PARAM: "DELETE_ASS_PROGRESS_PARAM",
  DELETE_ASS_PROGRESS_PARAM_SUCCESS: "DELETE_ASS_PROGRESS_PARAM_SUCCESS",

  DELETE_LABEL: "DELETE_LABEL",
  DELETE_LABEL_SUCCESS: "DELETE_LABEL_SUCCESS",

  FETCH_COMPLETE_SHARE_HISTORY: "FETCH_COMPLETE_SHARE_HISTORY",
  FETCH_COMPLETE_SHARE_HISTORY_SUCCESS: "FETCH_COMPLETE_SHARE_HISTORY_SUCCESS",

  UPDATE_SHARE_STATUS: "UPDATE_SHARE_STATUS",
  UPDATE_SHARE_STATUS_SUCCESS: "UPDATE_SHARE_STATUS_SUCCESS",

  GET_PLAN_EXTRA_DETAIL: "GET_PLAN_EXTRA_DETAIL",
  GET_PLAN_COMMENT_SUCCESS: "GET_PLAN_COMMENT_SUCCESS",
  GET_PLAN_SUBMISSION_SUCCESS: "GET_PLAN_SUBMISSION_SUCCESS",

  COPY_LESSON: "COPY_LESSON",
  COPY_LESSON_SUCCESS: "COPY_LESSON_SUCCESS",

  REPLACE_STUDENT: "REPLACE_STUDENT",
  REPLACE_STUDENT_SUCCESS: "REPLACE_STUDENT_SUCCESS",

  getMilestones: (firebase, milestones) => ({
    type: actions.LIST_MILESTONES,
    firebase,
    milestones,
  }),

  getDevelopmentAreas: (firebase) => ({
    type: actions.LIST_DEV_AREA,
    firebase,
  }),

  getLessons: (firebase, lessons, operationType) => ({
    type: actions.LIST_LESSONS,
    firebase,
    lessons,
    operationType,
  }),

  saveMilestoneDomain: (values, firebase) => ({
    type: actions.SAVE_MILESTONE_DOMAIN,
    values,
    firebase,
  }),

  updateSubDomain: (values, record, firebase) => ({
    type: actions.UPDATE_SUB_DOMAIN,
    values,
    record,
    firebase,
  }),

  addSubDomain: (values, record, firebase) => ({
    type: actions.ADD_SUB_DOMAIN,
    values,
    record,
    firebase,
  }),

  saveMilestone: (values, selectedMilestoneData, firebase) => ({
    type: actions.SAVE_MILESTONE,
    values,
    selectedMilestoneData,
    firebase,
  }),

  addLabel: (value, firebase) => ({
    type: actions.ADD_LABEL,
    value,
    firebase,
  }),

  getLabel: (firebase) => ({
    type: actions.GET_LABEL,
    firebase,
  }),

  updateMilestoneDomain: (values, record, firebase) => ({
    type: actions.UPDATE_MILESTONE_DOMAIN,
    values,
    record,
    firebase,
  }),

  updateMilestone: (values, editableMilestone, index, firebase) => ({
    type: actions.UPDATE_MILESTONE,
    values,
    editableMilestone,
    index,
    firebase,
  }),

  addCategory: (value, firebase) => ({
    type: actions.ADD_CATEGORY,
    value,
    firebase,
  }),

  getCategory: (firebase) => ({
    type: actions.GET_CATEGORY,
    firebase,
  }),

  getClassroom: (firebase) => ({
    type: actions.GET_LEARNING_CLASSROOM,
    firebase,
  }),

  addLesson: (value, selectedMilestones, classroom, firebase) => ({
    type: actions.ADD_LESSON,
    value,
    selectedMilestones,
    classroom,
    firebase,
  }),

  addLessonToAllCenter: (value, selectedMilestones, branchList, firebase) => ({
    type: actions.ADD_LESSON_TO_ALL_CENTERS,
    value,
    selectedMilestones,
    branchList,
    firebase,
  }),

  updateLesson: (
    values,
    editableLessonRecord,
    selectedMilestones,
    classroom,
    attachedFilesUrl,
    firebase,
    updateStoreLast
  ) => ({
    type: actions.UPDATE_LESSON,
    values,
    editableLessonRecord,
    selectedMilestones,
    classroom,
    attachedFilesUrl,
    firebase,
    updateStoreLast,
  }),

  updateLessonToAllCenter: (
    values,
    editableLessonRecord,
    selectedMilestones,
    branchList,
    attachedFilesUrl,
    firebase
  ) => ({
    type: actions.UPDATE_LESSON_TO_ALL_CENTERS,
    values,
    editableLessonRecord,
    selectedMilestones,
    branchList,
    attachedFilesUrl,
    firebase,
  }),

  saveWeeklyPlan: (
    selectedLesson,
    selectedDate,
    weeklyPlanData,
    selectedDropdownValue,
    firebase
  ) => ({
    type: actions.ADD_WEEKLY_PLAN,
    selectedLesson,
    selectedDate,
    weeklyPlanData,
    selectedDropdownValue,
    firebase,
  }),

  getWeeklyLesson: (from, to, selectedRoom, firebase) => ({
    type: actions.GET_WEEKLY_PLAN,
    from,
    to,
    selectedRoom,
    firebase,
  }),

  deleteLesson: (
    selectedItem,
    item,
    weeklyPlan,
    classDropdown,
    firebase,
    studentList
  ) => ({
    type: actions.DELETE_PLAN,
    selectedItem,
    item,
    weeklyPlan,
    classDropdown,
    firebase,
    studentList,
  }),

  deleteLessonRecord: (value, firebase) => ({
    type: actions.DELETE_LESSON_RECORD,
    value,
    firebase,
  }),

  deleteLessonRecordAllCenter: (value, firebase, branchList) => ({
    type: actions.DELETE_LESSON_RECORD_FOR_ALL_CENTER,
    value,
    firebase,
    branchList,
  }),

  deleteSelectedMilestone: (element, index, item, firebase) => ({
    type: actions.DELETE_SINGLE_MILESTONE,
    element,
    index,
    item,
    firebase,
  }),

  deleteEntireSelectedDomain: (item, firebase) => ({
    type: actions.DELETE_SINGLE_DOMAIN,
    item,
    firebase,
  }),

  resetLearningOperation: () => ({
    type: actions.RESET_LEARNING_OPERATION_TYPE,
  }),

  getStudent: (firebase) => ({
    type: actions.GET_STUDENT_FOR_LESSON,
    firebase,
  }),

  shareLesson: (
    students,
    record,
    values,
    selectedClass,
    firebase,
    shareDate
  ) => ({
    type: actions.SHARE_LESSON,
    students,
    record,
    values,
    selectedClass,
    firebase,
    shareDate,
  }),

  uploadLessonCsvData: (data, category, firebase) => ({
    type: actions.UPLOAD_LESSON_DATA,
    data,
    category,
    firebase,
  }),

  resetUpload: () => ({
    type: actions.UPLOAD_RESET,
  }),

  updateCategory: (name, record, firebase) => ({
    type: actions.UPDATE_CATEGORY,
    name,
    record,
    firebase,
  }),

  deleteSelectedCategory: (record, firebase) => ({
    type: actions.DELETE_CATEGORY,
    record,
    firebase,
  }),

  getAssessmentProgressParam: (firebase) => ({
    type: actions.GET_ASSESSMENT_PROGRESS_PARAM,
    firebase,
  }),

  saveAssessmentProgressParam: (name, firebase, background, textColor) => ({
    type: actions.SAVE_ASS_PROGRESS_PARAM,
    name,
    firebase,
    background,
    textColor,
  }),

  deleteAssProgressParam: (record, firebase) => ({
    type: actions.DELETE_ASS_PROGRESS_PARAM,
    record,
    firebase,
  }),

  deleteSelectedLabel: (record, firebase) => ({
    type: actions.DELETE_LABEL,
    record,
    firebase,
  }),

  fetchShareHistory: (lessons, firebase) => ({
    type: actions.FETCH_COMPLETE_SHARE_HISTORY,
    lessons,
    firebase,
  }),

  updateShareStatus: (sharedStatus, lesson, classroom, firebase, students) => ({
    type: actions.UPDATE_SHARE_STATUS,
    sharedStatus,
    lesson,
    classroom,
    firebase,
    students,
  }),

  fetchPlanExtraDetails: (plan, firebase) => ({
    type: actions.GET_PLAN_EXTRA_DETAIL,
    plan,
    firebase,
  }),

  copyLesson: (lesson, firebase) => ({
    type: actions.COPY_LESSON,
    lesson,
    firebase,
  }),

  replacedStudent: (students) => ({
    type: actions.REPLACE_STUDENT,
    students,
  }),
};
export default actions;
