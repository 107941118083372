import boyIcon from "../image/boy.svg";
import girlIcon from "../image/girl.svg";
export default class UserImageAction {
  constructor() {}
}

UserImageAction.getStudentProfileImage = (studentId) => {
  let students = JSON.parse(localStorage.getItem("studentList"));
  let student = students.filter((item) => {
    return item.id === studentId;
  });

  if (student && student.length > 0) {
    let filteredStd = student[0];
    if (filteredStd.profileImageUrl) {
      return filteredStd.profileImageUrl;
    } else {
      if (filteredStd.gender.toLowerCase() === "male") {
        return boyIcon;
      } else {
        return girlIcon;
      }
    }
  } else {
    return girlIcon;
  }
};

UserImageAction.getStaffProfileImage = (teacherId) => {
  let teachers = JSON.parse(localStorage.getItem("teacherList"));
  let teacher = teachers.filter((item) => {
    return item.id === teacherId;
  });

  if (teacher && teacher.length > 0) {
    let filteredStd = teacher[0];
    if (filteredStd.profileImageUrl) {
      return filteredStd.profileImageUrl;
    } else {
      if (filteredStd.gender.toLowerCase() === "male") {
        return boyIcon;
      } else {
        return girlIcon;
      }
    }
  } else {
    return girlIcon;
  }
};

UserImageAction.ifClassAccess = (teacherClassList, studentId) => {
  let status = false;
  let students = JSON.parse(localStorage.getItem("studentList"));
  let student = students.filter((std) => {
    return std.id === studentId;
  });

  if (student && student.length > 0) {
    let classList = student[0].classList
      ? student[0].classList
      : [student[0].classroomName];
    for (let i in teacherClassList) {
      if (classList.includes(teacherClassList[i])) {
        status = true;
        break;
      }
    }
  }

  return status;
};

UserImageAction.ifClassExistsForStudent = (classname, studentId) => {
  let status = false;
  let students = JSON.parse(localStorage.getItem("studentList"));
  let student = students.filter((std) => {
    return std.id === studentId;
  });

  if (student && student.length > 0) {
    let classList = student[0].classList
      ? student[0].classList
      : [student[0].classroomName];

    if (classList.includes(classname)) {
      status = true;
    }
  }

  return status;
};

UserImageAction.getRelation = (relation, firebase) => {
  if (firebase && firebase.schoolConfig.genderNeutral) {
    if (relation.toLowerCase() === "mother") {
      return "Parent One";
    } else if (relation.toLowerCase() === "father") {
      return "Parent Two";
    } else {
      return relation;
    }
  } else {
    return relation;
  }
};
