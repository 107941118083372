import React from "react";
import { Button } from "antd";
import colors from "../../../Utility/colorFactory";
import webFont from "../../../Utility/fontSizes";

const PrefixIconButton = (props) => {
  return (
    <div>
      <Button
        onClick={() => props.onClick()}
        size="large"
        style={{
          borderRadius: props.borderRadius ? props.borderRadius : 4,
          marginRight: 8,
          fontWeight: props.fontWeight ? props.fontWeight : null,
          fontSize: props.fontSize ? props.fontSize : webFont.medium,
          color: props.fontColor ? props.fontColor : colors.v2_fiord,
          backgroundColor: props.backgroundColor
            ? props.backgroundColor
            : colors.white,
          border: props.border ? "1px solid" : "none",
          borderColor: props.borderColor ? props.borderColor : "none",
          paddingLeft: props.paddingLeft ? props.paddingLeft : "none",
          paddingRight: props.paddingRight ? props.paddingRight : "none",
        }}
      >
        <div style={styles.inline}>
          {props.imgSrc ? (
            <img
              src={props.imgSrc}
              alt="icon"
              style={
                props.imageStyle
                  ? { ...props.imageStyle, ...{ marginLeft: 5 } }
                  : { ...styles.image, ...{ marginLeft: 5 } }
              }
            />
          ) : null}

          {props.title}
        </div>
      </Button>
    </div>
  );
};
export default PrefixIconButton;
const styles = {
  inline: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  image: {
    height: 20,
    width: 20,
    marginRight: 8,
    objectFit: "contain",
  },
};
