const actions = {
    GET_SCHOOL_SETTING_DETAILS: 'GET_SCHOOL_SETTING_DETAILS',
    GET_SCHOOL_SETTING_DETAILS_SUCCESSFUL: 'GET_SCHOOL_SETTING_DETAILS_SUCCESSFUL',

    GET_USER_DETAILS: 'GET_USER_DETAILS',
    GET_USER_DETAILS_SUCCESSFUL: 'GET_USER_DETAILS_SUCCESSFUL',

    UPLOAD_SCHOOL_LOGO: 'UPLOAD_SCHOOL_LOGO',
    UPLOAD_SCHOOL_LOGO_SUCCESSFUL: 'UPLOAD_SCHOOL_LOGO_SUCCESSFUL',

    UPDATE_USER_INFO: 'UPDATE_USER_INFO',
    UPDATE_USER_INFO_SUCCESSFUL: 'UPDATE_USER_INFO_SUCCESSFUL',

    UPDATE_TEACHER_INFO: 'UPDATE_TEACHER_INFO',
    UPDATE_TEACHER_INFO_SUCCESSFUL: 'UPDATE_TEACHER_INFO_SUCCESSFUL',

    SCHOOL_SETTING_REQUEST_FAILED: 'SCHOOL_SETTING_REQUEST_FAILED',

    GET_CAMERA_DETAILS: "GET_CAMERA_DETAILS",
    GET_CAMERA_DETAILS_SUCCESSFUL: "GET_CAMERA_DETAILS_SUCCESSFUL",

    GET_CLASSROOM_FOR_CAMERA_ASSIGN: "GET_CLASSROOM_FOR_CAMERA_ASSIGN",
    GET_CLASSROOM_FOR_CAMERA_ASSIGN_SUCCESSFFUL: "GET_CLASSROOM_FOR_CAMERA_ASSIGN_SUCCESSFFUL",

    UPDATE_CAMERA_TO_CLASS: "UPDATE_CAMERA_TO_CLASS",
    UPDATE_CAMERA_TO_CLASS_SUCCESSFUL: "UPDATE_CAMERA_TO_CLASS_SUCCESSFUL",

    GET_SCHOOL_CONFIG: "GET_SCHOOL_CONFIG",
    GET_SCHOOL_CONFIG_SUCCESS: "GET_SCHOOL_CONFIG_SUCCESS",

    SAVE_CONFIG_SETTING: "SAVE_CONFIG_SETTING",
    SAVE_CONFIG_SETTING_SUCCESSFUL: "SAVE_CONFIG_SETTING_SUCCESSFUL",

    RESET_OPERATION_REQ: "RESET_OPERATION_REQ",

    SUBMIT_HANDBOOK: "SUBMIT_HANDBOOK",
    SUBMIT_HANDBOOK_SUCCESS: "SUBMIT_HANDBOOK_SUCCESS",
    SUBMIT_TERMS: "SUBMIT_TERMS",
    SUBMIT_TERMS_SUCCESS: "SUBMIT_TERMS_SUCCESS",

    GET_ACTIVE_ACTIVITY: "GET_ACTIVE_ACTIVITY",
    GET_ACTIVE_ACTIVITY_SUCCESS: "GET_ACTIVE_ACTIVITY_SUCCESS",

    SAVE_ACTIVE_ACTVITIES: "SAVE_ACTIVE_ACTVITIES",
    SAVE_ACTIVE_ACTVITIES_SUCCESS: "SAVE_ACTIVE_ACTVITIES_SUCCESS",



    updateSchoolLogo: (file, firebase) => ({
        type: actions.UPLOAD_SCHOOL_LOGO,
        file,
        firebase
    }),

    getSchoolSettingDetails: (firebase) => ({
        type: actions.GET_SCHOOL_SETTING_DETAILS,
        firebase
    }),

    updateSchoolInfo: (userInfo, firebase) => ({
        type: actions.UPDATE_USER_INFO,
        userInfo,
        firebase
    }),

    getUserDetails: (firebase) => ({
        type: actions.GET_USER_DETAILS,
        firebase
    }),

    updateUserInfo: (value, firebase) => ({
        type: actions.UPDATE_TEACHER_INFO,
        value,
        firebase
    }),

    getCameraReferences: (firebase) => ({
        type: actions.GET_CAMERA_DETAILS,
        firebase
    }),

    getClassroomForCamera: (firebase) => ({
        type: actions.GET_CLASSROOM_FOR_CAMERA_ASSIGN,
        firebase
    }),

    updateCameraToClass: (values, cameraId, firebase) => ({
        type: actions.UPDATE_CAMERA_TO_CLASS,
        values,
        cameraId,
        firebase
    }),

    schoolConfiguration: (firebase) => ({
        type: actions.GET_SCHOOL_CONFIG,
        firebase
    }),

    saveSchoolConfig: (config, firebase) => ({
        type: actions.SAVE_CONFIG_SETTING,
        config,
        firebase
    }),

    resetOperationRequest: () => ({
        type: actions.RESET_OPERATION_REQ
    }),

    submitHandbook: (attachedFile, config, firebase) => ({
        type: actions.SUBMIT_HANDBOOK,
        attachedFile,
        config,
        firebase
    }),
    submitTerms: (attachedFile, config, firebase) => ({
        type: actions.SUBMIT_TERMS,
        attachedFile,
        config,
        firebase
    }),

    getActiveActivities: (firebase) => ({
        type: actions.GET_ACTIVE_ACTIVITY,
        firebase
    }),

    saveActiveActivities: (activities, firebase) => ({
        type: actions.SAVE_ACTIVE_ACTVITIES,
        activities,
        firebase
    })

}
export default actions;