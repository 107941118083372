const actions = {
    GET_SCHOOL_ASSIGNMENT: "GET_SCHOOL_ASSIGNMENT",
    GET_SCHOOL_ASSIGNMENT_SUCCESSFUL: "GET_SCHOOL_ASSIGNMENT_SUCCESSFUL",

    GET_SUBMISSION: "GET_SUBMISSION",
    GET_SUBMISSION_SUCCESSFUL: "GET_SUBMISSION_SUCCESSFUL",

    GET_ASSIGNMENT_COMMENT: "GET_ASSIGNMENT_COMMENT",
    GET_ASSIGNMENT_COMMENT_SUCCESSFUL: "GET_ASSIGNMENT_COMMENT_SUCCESSFUL",

    REQUEST_FAIL: "REQUEST_FAIL",

    GET_CLASS_DROP_DOWN: "GET_CLASS_DROP_DOWN",
    GET_CLASS_DROP_DOWN_SUCCESS: "GET_CLASS_DROP_DOWN_SUCCESS",

    RESET_ASSIGNMENT_OPERATION: "RESET_ASSIGNMENT_OPERATION",

    DELETE_SCHOOL_ASSIGNMENT: "DELETE_SCHOOL_ASSIGNMENT",
    DELETE_SCHOOL_ASSIGNMENT_SUCCESS: "DELETE_SCHOOL_ASSIGNMENT_SUCCESS",

    GET_ASSIGNMENT_BY_LESSON_ID: "GET_ASSIGNMENT_BY_LESSON_ID",
    GET_ASSIGNMENT_BY_LESSON_ID_SUCCESS: "GET_ASSIGNMENT_BY_LESSON_ID_SUCCESS",

    GET_SHARED_ASSIGNMENT_SUBMISSION: "GET_SHARED_ASSIGNMENT_SUBMISSION",
    GET_SHARED_ASSIGNMENT_SUBMISSION_SUCCESS: "GET_SHARED_ASSIGNMENT_SUBMISSION_SUCCESS",

    GET_SHARED_ASSIGNMENT_COMMENT: "GET_SHARED_ASSIGNMENT_COMMENT",
    GET_SHARED_ASSIGNMENT_COMMENT_SUCCESS: "GET_SHARED_ASSIGNMENT_COMMENT_SUCCESS",

    DELETE_SHARED_SCHOOL_ASSIGNMENT: "DELETE_SHARED_SCHOOL_ASSIGNMENT",
    DELETE_SHARED_SCHOOL_ASSIGNMENT_SUCCESS: "DELETE_SHARED_SCHOOL_ASSIGNMENT_SUCCESS",

    RESET_SHARED_ASSIGNMENT_OPERATION: "RESET_SHARED_ASSIGNMENT_OPERATION",


    getAssignments: (firebase, classroom, limit, initialCall, prevAssignmentsSchool, startDate, endDate, assignmentId) => ({
        type: actions.GET_SCHOOL_ASSIGNMENT,
        firebase,
        classroom,
        limit,
        initialCall,
        prevAssignmentsSchool,
        startDate,
        endDate,
        assignmentId
    }),

    getSubmissions: (assignments, firebase) => ({
        type: actions.GET_SUBMISSION,
        assignments,
        firebase
    }),

    getLessonComment: (assignments, firebase) => ({
        type: actions.GET_ASSIGNMENT_COMMENT,
        assignments,
        firebase
    }),

    fetchAssignmentClass: (firebase) => ({
        type: actions.GET_CLASS_DROP_DOWN,
        firebase
    }),

    resetAssignmentOperation: () => ({
        type: actions.RESET_ASSIGNMENT_OPERATION
    }),

    deleteSchoolAssignment: (record, firebase) => ({
        type: actions.DELETE_SCHOOL_ASSIGNMENT,
        record,
        firebase
    }),

    getAssignmentsByLessonId: (lessonId, firebase) => ({
        type: actions.GET_ASSIGNMENT_BY_LESSON_ID,
        lessonId,
        firebase
    }),

    getSharedAssignmentSubmissions: (assignments, firebase) => ({
        type: actions.GET_SHARED_ASSIGNMENT_SUBMISSION,
        assignments,
        firebase
    }),

    getSharedAssignmentComment: (assignments, firebase) => ({
        type: actions.GET_SHARED_ASSIGNMENT_COMMENT,
        assignments,
        firebase
    }),

    deleteSharedSchoolAssignment: (record, firebase) => ({
        type: actions.DELETE_SHARED_SCHOOL_ASSIGNMENT,
        record,
        firebase
    }),

    resetSharedAssignmentOperation: () => ({
        type: actions.RESET_SHARED_ASSIGNMENT_OPERATION
    })
};
export default actions;