import { all, put, call, take, takeLatest, fork } from "redux-saga/effects";
import { delay } from "redux-saga";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { LeadsApi } from "../../firestore-api/leads";
import { StudentApi } from "../../firestore-api/student";
import { TeacherApi } from "../../firestore-api/teacher";
import { NotificationApi } from "../../firestore-api/notification";
import notification from "../../components/notification";
import bugsnagClient from "@bugsnag/js";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const { Parser } = require("json2csv");

function* fetchLeads({ startDate, endDate, firebase, frequency, initialCall }) {
  let chan;
  if (startDate && endDate) {
    chan = yield call(LeadsApi.getAllLeads, startDate, endDate, firebase);
  } else {
    chan = yield call(LeadsApi.getLifetimeLeads, firebase);
  }
  try {
    while (true) {
      let data = yield take(chan);

      let sourceOptions = [];
      if (data.length > 0) {
        data.map((d) => {
          if (d.source) {
            sourceOptions.push(d.source);
          }
        });
      }

      yield put({
        type: actions.LIST_LEADS_SUCCESSFULL,
        leads: data,
        leadsChannel: chan,
        prevFrequency: frequency,
        operationType: initialCall,
        sourceOptions: [...new Set(sourceOptions)],
      });
    }
  } finally {
    console.log("end leads channel");
  }
}

function* fetchFeePrograms({ firebase }) {
  try {
    var data = yield call(LeadsApi.getFeePrograms, firebase);
    if (data) {
      yield put({
        type: actions.GET_FEE_PROGRAM_FOR_LEADS_SUCCESSFUL,
        feeProgram: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch fee programs", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* addNewLead({ leadsValue, firebase, leadAdditionalField }) {
  try {
    let leadsConfig = leadAdditionalField ? leadAdditionalField : [];
    for (let i = 0; i < leadsConfig.length; i++) {
      Object.keys(leadsValue).map((item, index) => {
        if (leadsConfig[i].name === item) {
          if (leadsConfig[i].dataType === "Date") {
            leadsConfig[i].value = leadsValue[item]
              ? moment(new Date(leadsValue[item])).format("DD[, ]MMMM[, ]YYYY")
              : null;
          } else {
            leadsConfig[i].value = leadsValue[item] ? leadsValue[item] : null;
          }
        }
      });
    }

    let nodeId = yield call(LeadsApi.createNewLeadNode, firebase);
    yield call(LeadsApi.addLead, leadsValue, nodeId, firebase, leadsConfig);
    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "leads",
      new Date()
    );
    yield put({
      type: actions.SAVE_NEW_LEAD_SUCCESSFUL,
    });
  } catch (error) {
    console.log("failed to add new lead", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* updateExistingLead({ leadsValue, record, firebase }) {
  try {
    let leadsConfig = record.additionalProps ? record.additionalProps : [];
    for (let i = 0; i < leadsConfig.length; i++) {
      Object.keys(leadsValue).map((item, index) => {
        if (leadsConfig[i].name === item) {
          if (leadsConfig[i].dataType === "Date") {
            leadsConfig[i].value = leadsValue[item]
              ? moment(new Date(leadsValue[item])).format("DD[, ]MMMM[, ]YYYY")
              : null;
          } else {
            leadsConfig[i].value = leadsValue[item] ? leadsValue[item] : null;
          }
        }
      });
    }

    yield call(LeadsApi.updateLead, leadsValue, record, firebase, leadsConfig);
    yield put({
      type: actions.UPDATE_LEAD_SUCCESSFUL,
    });
    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "leads",
      new Date()
    );
  } catch (error) {
    console.log("faile to update lead", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* addNewStatus({ statusInput, firebase }) {
  try {
    var nodeId = yield call(LeadsApi.createNewLeadNode, firebase);
    yield call(LeadsApi.addStatus, statusInput, nodeId, firebase);
    yield put({
      type: actions.ADD_STATUS_SUCCESSFUL,
    });
  } catch (error) {
    bugsnagClient.notify(error);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* fetchLeadsStatus({ firebase }) {
  try {
    var data = yield call(LeadsApi.getLeadsStatus, firebase);
    if (data)
      yield put({
        type: actions.GET_STATUS_SUCCESSFUL,
        status: data,
      });
  } catch (error) {
    bugsnagClient.notify(error);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* fetchSelectedLeadDetail({ selectedLead, firebase }) {
  const chan = yield call(LeadsApi.getSelectedLead, selectedLead, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_LEAD_DETAIL_SUCCESSFUL,
        detailedLead: data,
        detailedLeadChannel: chan,
      });
    }
  } finally {
    console.log("end leads channel");
  }
}

function* addNewActivity({ value, leadRecord, firebase }) {
  try {
    var nodeId = yield call(
      LeadsApi.createNewActivityNode,
      leadRecord.id,
      firebase
    );
    yield call(LeadsApi.addActivity, value, leadRecord.id, nodeId, firebase);

    yield call(LeadsApi.updateFollowUpDate, value, leadRecord.id, firebase);

    yield put({
      type: actions.ADD_ACTIVITY_SUCCESSFUL,
    });
  } catch (error) {
    bugsnagClient.notify(error);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* fetchSelectedLeadActivity({ selectedLead, firebase }) {
  const chan = yield call(
    LeadsApi.getSelectedLeadActivity,
    selectedLead,
    firebase
  );
  try {
    while (true) {
      let data = yield take(chan);
      data.sort(function(a, b) {
        var dateA = a.date,
          dateB = b.date;
        return dateB - dateA;
      });
      yield put({
        type: actions.GET_LEAD_ACTIVITY_SUCCESSFUL,
        detailedLeadActivity: data,
        detailedLeadActivityChannel: chan,
      });
    }
  } finally {
    console.log("end leads channel");
  }
}

function* updateChangedLeadStatus({ selectedLeadStatus, lead, firebase }) {
  try {
    var nodeId = yield call(LeadsApi.createNewActivityNode, lead.id, firebase);
    yield call(
      LeadsApi.updateLeadStatusInLead,
      selectedLeadStatus,
      lead,
      firebase
    );
    yield call(
      LeadsApi.updateLeadStatusChange,
      selectedLeadStatus,
      lead,
      nodeId,
      firebase
    );
    yield put({
      type: actions.UPDATE_LEAD_STATUS_CHANGE_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to update lead status", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* addAccount({ record, firebase }) {
  try {
    let studentName = record.student.name;
    let studentGender = record.student.gender;
    let studentClass = record.classroomName;
    let birthDate = record.student.birthDate
      ? record.student.birthDate
      : undefined;
    let address =
      record.student && record.student.address ? record.student.address : null;
    let preferredName =
      record.student && record.student.preferredName
        ? record.student.preferredName
        : null;

    let studentId = yield call(StudentApi.createStudentNodeId, firebase);
    let studentObj = {
      name: studentName,
      birthDate: birthDate
        ? moment(birthDate).format("DD[, ]MMMM[, ]YYYY")
        : null,
      classroomName: studentClass,
      classList: [studentClass],
      gender: studentGender,
      id: studentId,
      platform: "web_leads",
      updatedBy: firebase.teacher.name,
      updatedOn: new Date().getTime(),
      dateCreated: new Date().getTime(),
      deactivated: false,
      deactivationDate: 0,
      deleted: false,
      status: "Active",
      present: false,
      section: false,
      address: address,
      preferredName: preferredName,
      leadRecordId: record.id,
    };

    let parentOnePhone =
      record.parentOne && record.parentOne.phone
        ? record.parentOne.phone
        : null;
    let parentOneEmail =
      record.parentOne && record.parentOne.email
        ? record.parentOne.email
        : null;
    let parentOneName =
      record.parentOne && record.parentOne.name ? record.parentOne.name : null;

    let parentTwoPhone =
      record.parentTwo && record.parentTwo.phone
        ? record.parentTwo.phone
        : null;
    let parentTwoEmail =
      record.parentTwo && record.parentTwo.email
        ? record.parentTwo.email
        : null;
    let parentTwoName =
      record.parentTwo && record.parentTwo.name ? record.parentTwo.name : null;

    if (
      (parentOnePhone || parentOneEmail) &&
      (parentTwoPhone || parentTwoEmail)
    ) {
      let parentOneStatus = false;
      let parentTwoStatus = false;

      let p1Response = yield call(
        StudentApi.verifyParentApi,
        firebase,
        studentObj.name,
        studentObj.id,
        parentOnePhone,
        parentOneName,
        parentOneEmail
      );
      if (p1Response && p1Response.status === 200) {
        parentOneStatus = true;
      }

      let p2Response = yield call(
        StudentApi.verifyParentApi,
        firebase,
        studentObj.name,
        studentObj.id,
        parentTwoPhone,
        parentTwoName,
        parentTwoEmail
      );
      if (p2Response && p2Response.status === 200) {
        parentTwoStatus = true;
      }

      let studentObjUpdated = false;
      let parentOneSuccess = false;
      let parentOneFailureMessage = "Failed to add parent one detail";

      let parentTwoSuccess = false;
      let parentTwoFailureMessage = "Failed to add parent two detail";

      if (parentOneStatus) {
        let admissionCounter = yield call(
          StudentApi.getAdmissionCounter,
          firebase
        );
        let admissionNumber;
        if (
          firebase.schoolConfig &&
          firebase.schoolConfig.admissionNumberPrefix
        ) {
          admissionNumber = (
            firebase.schoolConfig.admissionNumberPrefix +
            "-" +
            (admissionCounter + 1)
          ).toString();
        } else {
          admissionNumber = (admissionCounter + 1).toString();
        }

        studentObj.admissionNumber = admissionNumber;
        yield call(
          StudentApi.incrementAdmissionCounter,
          admissionCounter,
          firebase
        );
        yield call(
          StudentApi.updateStudentWithUpdatedFormFields,
          studentObj,
          firebase
        );
        yield fork(
          LeadsApi.updateLeadStudentAddition,
          record,
          studentObj.id,
          firebase
        );
        studentObjUpdated = true;

        let addResponse = yield call(
          StudentApi.addParentApi,
          firebase,
          studentObj.name,
          studentObj.id,
          parentOneName,
          parentOnePhone,
          parentOneEmail,
          "Father",
          studentObj
        );
        if (addResponse && addResponse.status === 200) {
          parentOneSuccess = true;
        } else {
          if (addResponse.body && addResponse.body.message) {
            parentOneFailureMessage = addResponse.body.message;
          }
        }
      }

      if (parentTwoStatus) {
        if (!studentObjUpdated) {
          let admissionCounter = yield call(
            StudentApi.getAdmissionCounter,
            firebase
          );
          let admissionNumber;
          if (
            firebase.schoolConfig &&
            firebase.schoolConfig.admissionNumberPrefix
          ) {
            admissionNumber = (
              firebase.schoolConfig.admissionNumberPrefix +
              "-" +
              (admissionCounter + 1)
            ).toString();
          } else {
            admissionNumber = (admissionCounter + 1).toString();
          }

          studentObj.admissionNumber = admissionNumber;
          yield call(
            StudentApi.incrementAdmissionCounter,
            admissionCounter,
            firebase
          );
          yield call(
            StudentApi.updateStudentWithUpdatedFormFields,
            studentObj,
            firebase
          );
          yield fork(
            LeadsApi.updateLeadStudentAddition,
            record,
            studentObj.id,
            firebase
          );
          studentObjUpdated = true;
        }

        let addResponse = yield call(
          StudentApi.addParentApi,
          firebase,
          studentObj.name,
          studentObj.id,
          parentTwoName,
          parentTwoPhone,
          parentTwoEmail,
          "Mother",
          studentObj
        );
        if (addResponse && addResponse.status === 200) {
          parentTwoSuccess = true;
        } else {
          if (addResponse.body && addResponse.body.message) {
            parentTwoFailureMessage = addResponse.body.message;
          }
        }
      }

      if (parentOneSuccess || parentOneSuccess) {
        yield put({ type: actions.CREATE_NEW_ACCOUNT_SUCCESS });
        if (!parentOneSuccess) {
          notification("error", parentOneFailureMessage);
        }

        if (!parentTwoSuccess) {
          notification("error", parentTwoFailureMessage);
        }
      } else if (!parentOneSuccess && !parentTwoSuccess) {
        yield put({
          type: actions.LEAD_REQUEST_FAILED,
          errorMessage: "Parent number already exists",
        });
      }
    } else if (parentOnePhone || parentOneEmail) {
      let p1Response = yield call(
        StudentApi.verifyParentApi,
        firebase,
        studentObj.name,
        studentObj.id,
        parentOnePhone,
        parentOneName,
        parentOneEmail
      );
      console.log("p1Response", p1Response);
      if (p1Response && p1Response.status === 200) {
        let admissionCounter = yield call(
          StudentApi.getAdmissionCounter,
          firebase
        );
        let admissionNumber;
        if (
          firebase.schoolConfig &&
          firebase.schoolConfig.admissionNumberPrefix
        ) {
          admissionNumber = (
            firebase.schoolConfig.admissionNumberPrefix +
            "-" +
            (admissionCounter + 1)
          ).toString();
        } else {
          admissionNumber = (admissionCounter + 1).toString();
        }

        studentObj.admissionNumber = admissionNumber;
        yield call(
          StudentApi.incrementAdmissionCounter,
          admissionCounter,
          firebase
        );
        yield call(
          StudentApi.updateStudentWithUpdatedFormFields,
          studentObj,
          firebase
        );
        yield fork(
          LeadsApi.updateLeadStudentAddition,
          record,
          studentObj.id,
          firebase
        );
        // yield delay(30000);
        let addResponse = yield call(
          StudentApi.addParentApi,
          firebase,
          studentObj.name,
          studentObj.id,
          parentOneName,
          parentOnePhone,
          parentOneEmail,
          "Father",
          studentObj
        );
        console.log("addResponse", addResponse);
        if (addResponse && addResponse.status === 200) {
          yield put({ type: actions.CREATE_NEW_ACCOUNT_SUCCESS });
        } else {
          yield put({
            type: actions.LEAD_REQUEST_FAILED,
            errorMessage:
              addResponse.body && addResponse.body.message
                ? addResponse.body.message
                : "Failed to add parent detail",
          });
        }
      } else {
        yield put({
          type: actions.LEAD_REQUEST_FAILED,
          errorMessage:
            p1Response.body && p1Response.body.message
              ? p1Response.body.message
              : "Failed to add parent detail",
        });
      }
    } else if (parentTwoPhone || parentTwoEmail) {
      let p2Response = yield call(
        StudentApi.verifyParentApi,
        firebase,
        studentObj.name,
        studentObj.id,
        parentTwoPhone,
        parentTwoName,
        parentTwoEmail
      );
      if (p2Response && p2Response.status === 200) {
        let admissionCounter = yield call(
          StudentApi.getAdmissionCounter,
          firebase
        );
        let admissionNumber;
        if (
          firebase.schoolConfig &&
          firebase.schoolConfig.admissionNumberPrefix
        ) {
          admissionNumber = (
            firebase.schoolConfig.admissionNumberPrefix +
            "-" +
            (admissionCounter + 1)
          ).toString();
        } else {
          admissionNumber = (admissionCounter + 1).toString();
        }

        studentObj.admissionNumber = admissionNumber;
        yield call(
          StudentApi.incrementAdmissionCounter,
          admissionCounter,
          firebase
        );
        yield call(
          StudentApi.updateStudentWithUpdatedFormFields,
          studentObj,
          firebase
        );

        yield fork(
          LeadsApi.updateLeadStudentAddition,
          record,
          studentObj.id,
          firebase
        );

        let addResponse = yield call(
          StudentApi.addParentApi,
          firebase,
          studentObj.name,
          studentObj.id,
          parentTwoName,
          parentTwoPhone,
          parentTwoEmail,
          "Mother",
          studentObj
        );
        if (addResponse && addResponse.status === 200) {
          yield put({ type: actions.CREATE_NEW_ACCOUNT_SUCCESS });
        } else {
          yield put({
            type: actions.LEAD_REQUEST_FAILED,
            errorMessage:
              addResponse.body && addResponse.body.message
                ? addResponse.body.message
                : "Failed to add parent detail",
          });
        }
      } else {
        yield put({
          type: actions.LEAD_REQUEST_FAILED,
          errorMessage:
            p2Response.body && p2Response.body.message
              ? p2Response.body.message
              : "Failed to add parent detail",
        });
      }
    }

    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "leads",
      new Date()
    );
  } catch (err) {
    console.log("Failed to add leads account to illumine", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
      errorMessage: err.message ? err.message : undefined,
    });
  }
}

function* updateParentEmailLogin(
  contact,
  parentEmail,
  studentRecord,
  firebase,
  relation
) {
  try {
    let email = parentEmail;
    let editedId = replaceAll(email, "@", "%40");
    let encodedEmail = replaceAll(editedId, ".", "%2E");

    let existingUser = yield call(
      TeacherApi.checkIfUserExistInNewUser,
      contact,
      firebase
    );
    if (existingUser.status) {
      let existingEmailUser = yield call(
        TeacherApi.checkIfUserExistInNewUser,
        encodedEmail,
        firebase
      );
      let ifUpdateNeeded = false;
      if (
        existingEmailUser.status &&
        existingEmailUser.id === existingUser.id
      ) {
        ifUpdateNeeded = true;
      } else if (!existingEmailUser.status) {
        ifUpdateNeeded = true;
      }

      if (ifUpdateNeeded) {
        let existingObj = JSON.parse(JSON.stringify(existingUser));
        existingObj.status = null;
        yield call(
          StudentApi.updateNewUser,
          encodedEmail,
          existingObj,
          firebase
        );
      }
    } else {
      bugsnagClient.notify(
        new Error(
          "Unable to create email login as no data was found with the registered number"
        )
      );
    }
  } catch (err) {
    notification(
      "error",
      "Failed to create or update" + relation + " email login",
      err.message ? err.message : ""
    );
    console.log("failed to create parent email login through leads", err);
    bugsnagClient.notify(
      "Failed to create parent email login through leads ---->>>" + err.message
        ? err.message
        : err
    );
  }
}

function replaceAll(str, term, replacement) {
  return str.replace(new RegExp(escapeRegExp(term), "g"), replacement);
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function* deleteSelectedLead({ record, firebase }) {
  try {
    yield call(LeadsApi.deleteLead, record, firebase);
    yield put({
      type: actions.DELETE_LEAD_SUCCESS,
    });
  } catch (err) {
    console.log("failed to delete lead", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
      errorMessage: err.message ? err.message : undefined,
    });
  }
}

function* deleteSelectedLeadStatus({ record, firebase }) {
  try {
    yield call(LeadsApi.deleteLeadStatus, record, firebase);
    yield put({
      type: actions.DELETE_LEAD_STATUS_SUCCESS,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    console.log("failed to delete lead status", err);
  }
}

function* fetchLeadAdditionalField({ firebase }) {
  try {
    let data = yield call(LeadsApi.getAdditionalFields, firebase);
    if (data) {
      yield put({
        type: actions.GET_LEADS_ADDITIONAL_FIELD_SUCCESS,
        leadAdditionalField: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch leads additional fields", err);
    bugsnagClient.notify(err);
  }
}

function* getLeadsExcel({ firebase, values }) {
  try {
    let data = yield call(LeadsApi.getLifetimeLeadsData, firebase);
    let startDate = values.dateRange[0];
    let endDate = values.dateRange[1];
    console.log("data ----", data);
    if (data) {
      let fields = [
        "StudentName",
        "StudentGender",
        "ParentOneName",
        "ParentOnePhone",
        "ParentOneEmail",
        "ParentTwoName",
        "ParentTwoPhone",
        "ParentTwoEmail",
        "LeadStatus",
        "Source",
        "SubSource",
        "Program",
        "CreatedOn",
        "ExpectedStartDate",
        "NextFollowUp",
      ];
      const opts = { fields };
      let leadsList = [];
      for (let index in data) {
        let val = data[index];
        if (
          moment(val.createdDate).isBetween(
            moment(startDate),
            moment(endDate),
            "day",
            "[]"
          )
        ) {
          var row = {};
          row.StudentName = val.student.name;
          row.StudentGender = val.student.gender ? val.student.gender : "";
          row.ParentOneName =
            val.parentOne && val.parentOne.name ? val.parentOne.name : "";
          row.ParentOnePhone =
            val.parentOne && val.parentOne.phone ? val.parentOne.phone : "";
          row.ParentOneEmail =
            val.parentOne && val.parentOne.email ? val.parentOne.email : "";

          row.ParentTwoName =
            val.parentTwo && val.parentTwo.name ? val.parentTwo.name : "";
          row.ParentTwoPhone =
            val.parentTwo && val.parentTwo.phone ? val.parentTwo.phone : "";
          row.ParentTwoEmail =
            val.parentTwo && val.parentTwo.email ? val.parentTwo.email : "";

          row.LeadStatus = val.leadStatus;
          row.Source = val.source ? val.source : "";
          row.SubSource = val.subSource ? val.subSource : "";
          row.Program = val.feeProgram ? val.feeProgram : "";
          row.CreatedOn = val.createdDate
            ? moment(val.createdDate).format("DD/MMM/YYYY")
            : "";
          row.ExpectedStartDate = val.expectedStartDate
            ? moment(val.expectedStartDate).format("DD/MMM/YYYY")
            : "";
          row.NextFollowUp = val.nextFollowUpDate
            ? moment(val.nextFollowUpDate).format("DD/MMM/YYYY")
            : "";

          if (val.additionalProps) {
            for (let i in val.additionalProps) {
              let propVal = val.additionalProps[i];
              let propsName = propVal.name;
              let propsValue = propVal.value;
              if (!fields.includes(propsName)) {
                fields.push(propsName);
              }

              if (propVal.dataType.toLowerCase() === "list") {
                row[propsName] = propsValue ? propsValue.toString() : "";
              } else {
                row[propsName] = propsValue ? propsValue : "";
              }
            }
          }

          leadsList.push(row);
        }
      }

      const parser = new Parser(opts);
      const csv = parser.parse(leadsList);
      var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      FileSaver.saveAs(csvData, "leads.csv");
      // const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      // const fileExtension = '.xlsx';
      // const fileName = 'LeadsList';

      // var ws = XLSX.utils.json_to_sheet(leadsList, { header: fields ,cellDates:true,dateNF:'DD-MM-YYYY'});

      // const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      // const newData = new Blob([excelBuffer], { type: fileType });
      // FileSaver.saveAs(newData, fileName + fileExtension);

      yield put({
        type: actions.DOWNLOAD_LEADS_EXCEL_SUCCESS,
      });
    }
  } catch (err) {
    console.log("failed to download leads excel", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
      errorMessage: err.message ? err.message : undefined,
    });
  }
}

function* deleteLeadsActivity({ item, leadId, firebase }) {
  try {
    yield call(LeadsApi.deleteLeadActivity, item, leadId, firebase);
    yield put({
      type: actions.DELETE_LEAD_ACTIVITY_SUCCESS,
    });
  } catch (err) {
    console.log("failed to delete leads activity", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
      errorMessage: err.message ? err.message : undefined,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.LIST_LEADS, fetchLeads),
    yield takeLatest(actions.GET_FEE_PROGRAM_FOR_LEADS, fetchFeePrograms),
    yield takeLatest(actions.GET_STATUS, fetchLeadsStatus),
    yield takeLatest(actions.SAVE_NEW_LEAD, addNewLead),
    yield takeLatest(actions.UPDATE_LEAD, updateExistingLead),
    yield takeLatest(actions.ADD_STATUS, addNewStatus),
    yield takeLatest(actions.GET_LEAD_DETAIL, fetchSelectedLeadDetail),
    yield takeLatest(actions.ADD_ACTIVITY, addNewActivity),
    yield takeLatest(actions.GET_LEAD_ACTIVITY, fetchSelectedLeadActivity),
    yield takeLatest(
      actions.UPDATE_LEAD_STATUS_CHANGE,
      updateChangedLeadStatus
    ),
    yield takeLatest(actions.CREATE_NEW_ACCOUNT, addAccount),
    yield takeLatest(actions.DELETE_LEAD, deleteSelectedLead),
    yield takeLatest(actions.DELETE_LEAD_STATUS, deleteSelectedLeadStatus),
    yield takeLatest(
      actions.GET_LEADS_ADDITIONAL_FIELD,
      fetchLeadAdditionalField
    ),
    yield takeLatest(actions.DOWNLOAD_LEADS_EXCEL, getLeadsExcel),
    yield takeLatest(actions.DELETE_LEAD_ACTIVITY, deleteLeadsActivity),
  ]);
}
