import "@firebase/firestore"; // 👈 If you're using firestore
import { eventChannel } from "redux-saga";
import moment from "moment-timezone";

function getAllEvents(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/events/")
      .orderByChild("inverseTime")
      .on("value", (snap) => {
        var x = [];
        snap.forEach((element) => {
          if (element.val() !== null) {
            let classList =
              firebase && firebase.teacher && firebase.teacher.classList
                ? firebase.teacher.classList
                : [];
            if (firebase.teacher && !firebase.teacher.superUser) {
              if (classList.length > 0) {
                let eventClasses = element.val().classRoomNames
                  ? element.val().classRoomNames
                  : [];
                for (let index in classList) {
                  let filteredVal = eventClasses.filter((f) => {
                    return f.toLowerCase() === classList[index].toLowerCase();
                  });

                  if (filteredVal && filteredVal.length > 0) {
                    x.push(element.val());
                    break;
                  }
                }
              } else {
                x.push(element.val());
              }
            } else {
              x.push(element.val());
            }
          }
        });
        emit(x);
      });
    return () => {
      rsf.ref(branchPath + "/events").off();
      console.log("unsubscribe events");
    };
  });
}

function getNotificationEvent(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let myRef = rsf.ref(branchPath + "/events/" + id);
  return eventChannel((emit) => {
    myRef.on("value", (element) => {
      var x = [];
      if (element.val() !== null) {
        let classList =
          firebase && firebase.teacher && firebase.teacher.classList
            ? firebase.teacher.classList
            : [];
        if (firebase.teacher && !firebase.teacher.superUser) {
          if (classList.length > 0) {
            let eventClasses = element.val().classRoomNames
              ? element.val().classRoomNames
              : [];
            for (let index in classList) {
              let filteredVal = eventClasses.filter((f) => {
                return f.toLowerCase() === classList[index].toLowerCase();
              });

              if (filteredVal && filteredVal.length > 0) {
                x.push(element.val());
                break;
              }
            }
          } else {
            x.push(element.val());
          }
        } else {
          x.push(element.val());
        }
      }
      emit(x);
    });
    return () => {
      myRef.off();
      console.log("unsubscribe events");
    };
  });
}

function getUniqueId(firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var key = rsf.ref(branchPath + "/events").push().key;
  return key;
}

function getEventMediaPath(pathToStorage, files, firebase) {
  var urls = [];
  var promiseList = [];

  files.fileList.forEach(function(singleFile) {
    var file = singleFile;
    var metadata = {
      contentType: singleFile.type,
    };
    const storage = firebase.secondaryStorage;
    const storageRef = storage.ref();
    let fileName = new Date().getTime() + file.name;
    const attachmentRef = storageRef.child(pathToStorage + fileName);

    var p2 = attachmentRef.put(file, metadata).then(function(snapshot) {
      return attachmentRef.getDownloadURL().then((url) => {
        urls.push(url);
      });
    });
    promiseList.push(p2);
  });
  return Promise.all(promiseList).then(function() {
    return urls;
  });
}

function addNewEvent(eventObj, eventId, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  rsf.ref(branchPath + "/events/" + eventId).update(eventObj);
}

function getEventPhotos(recordId, firebase) {
  let photos = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf
    .ref(branchPath + "/photos/" + recordId)
    .orderByChild("inverseDate");
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((element) => {
        if (element.val() !== null) {
          photos.push(element.val());
        }
      });
      resolve(photos);
    });
  });
  return promise1;
}

function createPhotoNode(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/photos").push().key;
  return key;
}

function addPhotosToEvent(imgObj, node, event, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/photos/" + event.id + "/" + node).update(imgObj);
}

function deleteEvent(event, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/events/" + event.id).set(null);
}

function deleteAllEventPhotos(event, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/photos/" + event.id).set(null);
}

function getUpcomingEvent(firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  let x = [];

  let startDate = moment()
    .startOf("day")
    .valueOf();
  let endDate = moment()
    .add(3, "day")
    .endOf("day")
    .valueOf();

  let promise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/events")
      .orderByChild("inverseTime")
      .startAt(-endDate)
      .endAt(-startDate)
      .once("value")
      .then((snap) => {
        snap.forEach((element) => {
          if (element.val() !== null) {
            let classList =
              firebase && firebase.teacher && firebase.teacher.classList
                ? firebase.teacher.classList
                : [];
            if (firebase.teacher && !firebase.teacher.superUser) {
              if (classList.length > 0) {
                let eventClasses = element.val().classRoomNames
                  ? element.val().classRoomNames
                  : [];
                for (let index in classList) {
                  let filteredVal = eventClasses.filter((f) => {
                    return f.toLowerCase() === classList[index].toLowerCase();
                  });

                  if (filteredVal && filteredVal.length > 0) {
                    x.push(element.val());
                    break;
                  }
                }
              } else {
                x.push(element.val());
              }
            } else {
              x.push(element.val());
            }
          }
        });
        resolve(x);
      });
  });
  return promise;
}

export const EventsApi = {
  getAllEvents,
  getNotificationEvent,
  getUniqueId,
  addNewEvent,
  getEventMediaPath,
  getEventPhotos,
  createPhotoNode,
  addPhotosToEvent,
  deleteEvent,
  deleteAllEventPhotos,
  getUpcomingEvent,
};
