let PermissionStrings = {
  /**Student Management */
  SM_View_Student_List: "Student Management View student list",
  SM_View_Profile: "Student Management View profile",
  SM_Document_Access: "Student Management Document Access",
  SM_ADD_UPDATE: "Student Management Add/Update",
  SM_DELETE: "Student Management Delete",
  SM_RECORD_ATTENDANCE: "Student Management Record Attendance",
  SM_UPDATE_ATTENDANCE: "Student Management Update Attendance",
  SM_ADD_UPDATE_NOTES: "Student Management Add/Update Notes",
  /**Staff Management */
  STAFF_M_DELETE: "Staff Management Delete",
  STAFF_M_Document_Access: "Staff Management Document Access",
  STAFF_M_ADD_EDIT: "Staff Management Add/Edit",
  STAFF_M_Staff_List: "Staff Management View",
  STAFF_M_RECORD_ATTENDANCE: "Staff Management Record Attendance",
  STAFF_M_UPDATE_ATTENDANCE: "Staff Management Update Attendance",
  STAFF_M_CURRICULUM_DOC_VIEW:
    "Staff Management" + " " + "Curriculum document view",
  STAFF_M_CURRICULUM_DOC_ADD:
    "Staff Management" + " " + "Curriculum document add",
  STAFF_M_CURRICULUM_DOC_DOWNLOAD:
    "Staff Management" + " " + "Curriculum document download",
  STAFF_M_VIEW_STAFF_LEAVES: "Staff Management" + " " + "View staff leaves",
  STAFF_M_APPROVE_STAFF_LEAVES:
    "Staff Management" + " " + "Approve staff leaves",
  /**Post Activity */
  POST_ACTIVITY_VIEW: "Post Activity View",
  POST_ACTIVITY_EDIT: "Post Activity Edit",
  POST_ACTIVITY_DELETE: "Post Activity Delete",
  POST_ACTIVITY_ADD_NEW_POST: "Post Activity Add new post",
  POST_ACTIVITY_POST_WITHOUT_APPROVAL: "Post Activity Post without approval",
  POST_ACTIVITY_STAFF_ANNOUNCEMENT:
    "Post Activity" + " " + "Post Staff Announcement",
  /**Events */
  EVENTS_VIEW: "Events View",
  EVENTS_ADD_EDIT_DELETE: "Events Add/Edit/Delete",
  EVENTS_ADD_DELETE_PHOTOS: "Events Add/Delete photos",
  /**Holidays */
  HOLIDAYS_VIEW: "Holidays View",
  HOLIDAYS_ADD: "Holidays Add",
  HOLIDAYS_EDIT_DELETE: "Holidays Edit/Delete",
  /**Lesson */
  LESSON_VIEW: "Lesson View",
  LESSON_ADD_EDIT: "Lesson Add/Edit",
  LESSON_DELETE: "Lesson Delete",
  LESSON_SHARE: "Lesson Share",
  LESSON_ADD_EDIT_W_PLAN: "Lesson Add/Edit to weekly plan",
  LESSON_VIEW_W_PLAN: "Lesson View weekly plan",
  /**Student Lesson */
  STUDENT_ATTENDANCE: "Student Attendance Record",
  STUDENT_ATTENDANCE: "Student Attendance Update",
  /**Student Lesson */
  STAFF_ATTENDANCE: "Staff Attendance Record",
  STAFF_ATTENDANCE: "Staff Attendance Update",
  /**Billing */
  BILLING_View: "Billing View",
  BILLING_Record_Payment: "Billing Record payment",
  BILLING_Create_Update_Inv: "Billing Create/Update invoice",
  BILLING_Delete_Inv: "Billing Delete invoice",
  BILLING_Update_Payment_Record: "Billing Update payment record",
  BILLING_Send_Reminder: "Billing Send reminder",
  BILLING_Create_Edit_Fee_Template: "Billing Create/Edit fee template",
  BILLING_Delete_Fee_Template: "Billing Delete fee template",
  BILLING_Add_Discount: "Billing Add discount",
  /**Schedule */
  SCHEDULE_VIEW: "Schedule View",
  SCHEDULE_ADD_EDIT: "Schedule Add/Edit",
  SCHEDULE_DELETE: "Schedule Delete",
  /**Leave */
  LEAVES_VIEW: "Leaves View",
  LEAVES_Approve: "Leaves Approve",
  LEAVES_Comment: "Leaves Comment",
  /**Concern */
  CONCERN_VIEW_COMMENT: "Concern View/Comment",
  CONCERN_RESOLVE: "Concern Resolve",
  /**Note */
  NOTES_Comment: "Notes Comment",
  NOTES_Approve: "Notes Approve",
  NOTES_View_Mark_Done: "Notes View/Mark Done",
  /**Queries */
  QUERIES_VIEW_REPLY: "Queries View and reply",
  /**Settings */
  SETTINGS_VIEW_CHANGE: "Settings View and change",
  /**Assessment */
  ASSESSMENT_CREATE: "Assessment Create",
  ASSESSMENT_VIEW: "Assessment View",
  ASSESSMENT_ADD_DEL_MILESTONE_CATEGORY:
    "Assessment Add/Delete milestones and category",
  /**Program */
  PROGRAM_View: "Programs View",
  PROGRAM_Create_Edit: "Programs Create/edit",
  PROGRAM_Assign_Student: "Programs Assign Students",
  PROGRAM_Export_Data: "Programs Export data",
  /**Lead */
  LEAD_View: "Leads View",
  LEAD_Delete: "Leads Delete",
  LEAD_Add_Edit: "Leads Add/Edit",
  /**Newsletter */
  NEWSLETTER_CREATE: "Newsletter Create",
  NEWSLETTER_SEND: "Newsletter Send",
  NEWSLETTER_ADD_TEMPLATE_EDIT_DEL: "Newsletter Add template/edit/delete",
  /**CCTV */
  CCTV_CONFIGURE: "CCTV Configure",
};
export default PermissionStrings;
