const actions = {
  LIST_INVOICE: "LIST_INVOICE",
  LIST_INVOICE_SUCCESSFULL: "LIST_INVOICE_SUCCESSFULL",

  GET_INVOICE_AGGREGATED: "GET_INVOICE_AGGREGATED",
  GET_INVOICE_AGGREGATED_SUCCESSFULL: "GET_INVOICE_AGGREGATED_SUCCESSFULL",

  GET_INVOICE_DOWNLOAD_URL: "GET_INVOICE_DOWNLOAD_URL",
  GET_INVOICE_DOWNLOAD_URL_SUCCESSFUL: "GET_INVOICE_DOWNLOAD_URL_SUCCESSFUL",

  GET_STUDENT_FOR_INVOICE: "GET_STUDENT_FOR_INVOICE",
  GET_STUDENT_FOR_INVOICE_SUCCESSFUL: "GET_STUDENT_FOR_INVOICE_SUCCESSFUL",

  GET_PROGRAMS_FOR_INVOICE_SUCCESSFUL: "GET_PROGRAMS_FOR_INVOICE_SUCCESSFUL",

  ADD_NEW_INVOICE: "ADD_NEW_INVOICE",
  ADD_NEW_INVOICE_SUCCESSFUL: "ADD_NEW_INVOICE_SUCCESSFUL",

  UPDATE_SELECTED_INVOICE: "UPDATE_SELECTED_INVOICE",
  UPDATE_SELECTED_INVOICE_SUCCESSFFUL: "UPDATE_SELECTED_INVOICE_SUCCESSFFUL",

  RECEIVE_PAYMENT: "RECEIVE_PAYMENT",
  RECEIVE_PAYMENT_SUCCESSFUL: "RECEIVE_PAYMENT_SUCCESSFUL",

  DELETE_INVOICE: "DELETE_INVOICE",
  DELETE_INVOICE_SUCCESSFFUL: "DELETE_INVOICE_SUCCESSFFUL",

  RESET_INVOICE_URL: "RESET_INVOICE_URL",

  INVOICE_REQUEST_FAILED: "INVOICE_REQUEST_FAILED",

  GET_INVOICE_TEMPLATES: "GET_INVOICE_TEMPLATES",
  GET_INVOICE_TEMPLATES_SUCCESSFUL: "GET_INVOICE_TEMPLATES_SUCCESSFUL",

  DOWNLOAD_INVOICE_EXCEL_SHEET: "DOWNLOAD_INVOICE_EXCEL_SHEET",
  DOWNLOAD_INVOICE_EXCEL_SHEET_SUCCESSFUL:
    "DOWNLOAD_INVOICE_EXCEL_SHEET_SUCCESSFUL",

  SEND_PAYMENT_REMINDER: "SEND_PAYMENT_REMINDER",
  SEND_PAYMENT_REMINDER_SUCCESSFUL: "SEND_PAYMENT_REMINDER_SUCCESSFUL",

  DELETE_PAYMENT_RECORD: "DELETE_PAYMENT_RECORD",
  DELETE_PAYMENT_RECORD_SUCCESSFUL: "DELETE_PAYMENT_RECORD_SUCCESSFUL",

  REFUND_AMT: "REFUND_AMT",
  REFUND_AMT_SUCCESS: "REFUND_AMT_SUCCESS",

  GET_RECEIPT_LIST: "GET_RECEIPT_LIST",
  GET_RECEIPT_LIST_SUCCESS: "GET_RECEIPT_LIST_SUCCESS",

  GET_STUDENT_AGGREAGTED_INVOICES: "GET_STUDENT_AGGREAGTED_INVOICES",
  GET_STUDENT_AGGREAGTED_INVOICES_SUCCESS:
    "GET_STUDENT_AGGREAGTED_INVOICES_SUCCESS",

  GET_SINGLE_STUDENT_INVOICES: "GET_SINGLE_STUDENT_INVOICES",
  GET_SINGLE_STUDENT_INVOICES_SUCCESS: "GET_SINGLE_STUDENT_INVOICES_SUCCESS",

  GET_SINGLE_STUDENT_AGGREGATED_INVOICE:
    "GET_SINGLE_STUDENT_AGGREGATED_INVOICE",
  GET_SINGLE_STUDENT_AGGREGATED_INVOICE_SUCCESS:
    "GET_SINGLE_STUDENT_AGGREGATED_INVOICE_SUCCESS",

  IGNORE_LATE_FEE: "IGNORE_LATE_FEE",
  IGNORE_LATE_FEE_SUCCESS: "IGNORE_LATE_FEE_SUCCESS",

  REMOVE_REFUND: "REMOVE_REFUND",
  REMOVE_REFUND_SUCCESS: "REMOVE_REFUND_SUCCESS",

  SAVE_CREDIT: "SAVE_CREDIT",
  SAVE_CREDIT_SUCCESS: "SAVE_CREDIT_SUCCESS",

  GET_CREDIT_HISTORY: "GET_CREDIT_HISTORY",
  GET_CREDIT_HISTORY_SUCCESS: "GET_CREDIT_HISTORY_SUCCESS",

  GET_ALL_CREDIT_HISTORY: "GET_ALL_CREDIT_HISTORY",
  GET_ALL_CREDIT_HISTORY_SUCCESS: "GET_ALL_CREDIT_HISTORY_SUCCESS",

  DOWNLOAD_ALL_CREDIT_HISTORY: "DOWNLOAD_ALL_CREDIT_HISTORY",
  DOWNLOAD_ALL_CREDIT_HISTORY_SUCCESS: "DOWNLOAD_ALL_CREDIT_HISTORY_SUCCESS",
  DOWNLOAD_ALL_CREDIT_HISTORY_FAILED: "DOWNLOAD_ALL_CREDIT_HISTORY_FAILED",

  DELETE_CREDIT: "DELETE_CREDIT",
  DELETE_CREDIT_SUCCESS: "DELETE_CREDIT_SUCCESS",
  DELETE_CREDIT_DENIED: "DELETE_CREDIT_DENIED",

  GET_STUDENT_ONLINE_CLASS_INVOICE: "GET_STUDENT_ONLINE_CLASS_INVOICE",
  GET_STUDENT_ONLINE_CLASS_INVOICE_SUCCESS:
    "GET_STUDENT_ONLINE_CLASS_INVOICE_SUCCESS",

  GENERATE_PAYMENT_TRANSACTION_ID: "GENERATE_PAYMENT_TRANSACTION_ID",
  GENERATE_PAYMENT_TRANSACTION_ID_SUCCESS:
    "GENERATE_PAYMENT_TRANSACTION_ID_SUCCESS",

  SETTLE_PAYMENT: "SETTLE_PAYMENT",
  SETTLE_PAYMENT_SUCCESS: "SETTLE_PAYMENT_SUCCESS",

  UPDATED_FAILED_PAYMENT: "UPDATED_FAILED_PAYMENT",
  UPDATED_FAILED_PAYMENT_SUCCESS: "UPDATED_FAILED_PAYMENT_SUCCESS",

  GET_ONLINE_CLASS_PLAN_DETAIL: "GET_ONLINE_CLASS_PLAN_DETAIL",
  GET_ONLINE_CLASS_PLAN_DETAIL_SUCCESS: "GET_ONLINE_CLASS_PLAN_DETAIL_SUCCESS",

  SAVE_CARD_DETAILS: "SAVE_CARD_DETAILS",

  FETCH_CARD_DETAILS: "FETCH_CARD_DETAILS",
  FETCH_CARD_DETAILS_SUCCESS: "FETCH_CARD_DETAILS_SUCCESS",

  AUTO_CHARGE_PAYMENT: "AUTO_CHARGE_PAYMENT",
  AUTO_CHARGE_PAYMENT_SUCCESS: "AUTO_CHARGE_PAYMENT_SUCCESS",

  GET_STUDENTS_FEE_PLAN: "GET_STUDENTS_FEE_PLAN",
  GET_STUDENTS_FEE_PLAN_SUCCESS: "GET_STUDENTS_FEE_PLAN_SUCCESS",

  GET_TAX_REF_SUCCESS: "GET_TAX_REF_SUCCESS",

  REFRESH_PDF: "REFRESH_PDF",
  REFRESH_PDF_SUCCESS: "REFRESH_PDF_SUCCESS",

  GET_CREDIT_PDF: "GET_CREDIT_PDF",
  GET_CREDIT_PDF_SUCCESS: "GET_CREDIT_PDF_SUCCESS",

  initInvoiceData: (firebase) => ({ type: actions.LIST_INVOICE, firebase }),

  getInvoiceAggregated: (firebase) => ({
    type: actions.GET_INVOICE_AGGREGATED,
    firebase,
  }),

  getInvoiceDownloadUrl: (record, firebase, userType) => ({
    type: actions.GET_INVOICE_DOWNLOAD_URL,
    record,
    firebase,
    userType,
  }),

  fetchStudentData: (firebase) => ({
    type: actions.GET_STUDENT_FOR_INVOICE,
    firebase,
  }),

  addNewInvoice: (
    raisedOn,
    billingPeriod,
    dueDate,
    schoolNote,
    rows,
    totalAmount,
    selectedStudentCheckbox,
    firebase,
    selectedFeeTemplates,
    autoChargeEnabled,
    subTotal,
    taxDescription,
    tax
  ) => ({
    type: actions.ADD_NEW_INVOICE,
    raisedOn,
    billingPeriod,
    dueDate,
    schoolNote,
    rows,
    totalAmount,
    selectedStudentCheckbox,
    firebase,
    selectedFeeTemplates,
    autoChargeEnabled,
    subTotal,
    taxDescription,
    tax,
  }),

  updateInvoice: (
    raisedOn,
    billingPeriod,
    dueDate,
    schoolNote,
    rows,
    totalAmount,
    studentId,
    invoiceId,
    firebase,
    selectedFeeTemplates,
    subTotal,
    taxDescription,
    tax
  ) => ({
    type: actions.UPDATE_SELECTED_INVOICE,
    raisedOn,
    billingPeriod,
    dueDate,
    schoolNote,
    rows,
    totalAmount,
    studentId,
    invoiceId,
    firebase,
    selectedFeeTemplates,
    subTotal,
    taxDescription,
    tax,
  }),

  addPayment: (
    values,
    selectedInvoice,
    firebase,
    originalSelectedInvoice,
    creditRequested,
    creditAmountUsed
  ) => ({
    type: actions.RECEIVE_PAYMENT,
    values,
    selectedInvoice,
    firebase,
    originalSelectedInvoice,
    creditRequested,
    creditAmountUsed,
  }),

  deleteInvoice: (invoiceRecord, firebase) => ({
    type: actions.DELETE_INVOICE,
    invoiceRecord,
    firebase,
  }),

  resetInvoiceUrl: (firebase) => ({
    type: actions.RESET_INVOICE_URL,
    firebase,
  }),

  getAllInvoiceTemplates: (firebase) => ({
    type: actions.GET_INVOICE_TEMPLATES,
    firebase,
  }),

  downloadExcelSheet: (formValue, firebase) => ({
    type: actions.DOWNLOAD_INVOICE_EXCEL_SHEET,
    formValue,
    firebase,
  }),

  sendPaymentReminder: (email, sms, invoice, firebase) => ({
    type: actions.SEND_PAYMENT_REMINDER,
    email,
    sms,
    invoice,
    firebase,
  }),

  deletePaymentRecord: (lineItem, invoice, firebase) => ({
    type: actions.DELETE_PAYMENT_RECORD,
    lineItem,
    invoice,
    firebase,
  }),

  refundPayment: (values, selectedInvoice, firebase) => ({
    type: actions.REFUND_AMT,
    values,
    selectedInvoice,
    firebase,
  }),

  getReceiptList: (record, firebase) => ({
    type: actions.GET_RECEIPT_LIST,
    record,
    firebase,
  }),

  getAggregatedInvoiceDetail: (startDate, endDate, firebase, initialCall) => ({
    type: actions.GET_STUDENT_AGGREAGTED_INVOICES,
    startDate,
    endDate,
    firebase,
    initialCall,
  }),

  getStudentInvoices: (studentId, firebase) => ({
    type: actions.GET_SINGLE_STUDENT_INVOICES,
    studentId,
    firebase,
  }),

  getAggregatedInvoiceByStudentId: (studentId, firebase) => ({
    type: actions.GET_SINGLE_STUDENT_AGGREGATED_INVOICE,
    studentId,
    firebase,
  }),

  onIgnoreLateFee: (selectedInvoice, firebase) => ({
    type: actions.IGNORE_LATE_FEE,
    selectedInvoice,
    firebase,
  }),

  removeRefund: (record, index, invoice, firebase) => ({
    type: actions.REMOVE_REFUND,
    record,
    index,
    invoice,
    firebase,
  }),

  saveCredit: (amount, students, firebase, approvedBy, reason) => ({
    type: actions.SAVE_CREDIT,
    amount,
    students,
    firebase,
    approvedBy,
    reason,
  }),

  getCreditHistory: (studentId, firebase) => ({
    type: actions.GET_CREDIT_HISTORY,
    studentId,
    firebase,
  }),

  getAllCreditHistory: (firebase) => ({
    type: actions.GET_ALL_CREDIT_HISTORY,
    firebase,
  }),

  downloadAllCreditHistory: (creditHistory, firebase) => ({
    type: actions.DOWNLOAD_ALL_CREDIT_HISTORY,
    creditHistory,
    firebase,
  }),

  deleteCredit: (record, firebase) => ({
    type: actions.DELETE_CREDIT,
    record,
    firebase,
  }),

  getStudentOnlineClassInvoice: (studentId, firebase) => ({
    type: actions.GET_STUDENT_ONLINE_CLASS_INVOICE,
    studentId,
    firebase,
  }),

  generateTransactionId: (
    actualTransferAmount,
    amount,
    invoiceId,
    studentId,
    userParentId,
    firebase
  ) => ({
    type: actions.GENERATE_PAYMENT_TRANSACTION_ID,
    actualTransferAmount,
    amount,
    invoiceId,
    studentId,
    userParentId,
    firebase,
  }),

  paymentSuccessful: (invoice, paymentMode, parentName, firebase) => ({
    type: actions.SETTLE_PAYMENT,
    invoice,
    paymentMode,
    parentName,
    firebase,
  }),

  paymentFailed: (invoice, parentName, firebase) => ({
    type: actions.UPDATED_FAILED_PAYMENT,
    invoice,
    parentName,
    firebase,
  }),

  getPlanDetail: (feePlanId, firebase) => ({
    type: actions.GET_ONLINE_CLASS_PLAN_DETAIL,
    feePlanId,
    firebase,
  }),

  saveCardDetails: (record, firebase, country) => ({
    type: actions.SAVE_CARD_DETAILS,
    record,
    firebase,
    country,
  }),

  fetchCardDetail: (studentId, firebase) => ({
    type: actions.FETCH_CARD_DETAILS,
    studentId,
    firebase,
  }),

  autoChargePayment: (invoice, firebase) => ({
    type: actions.AUTO_CHARGE_PAYMENT,
    invoice,
    firebase,
  }),

  fetchStudentsFeePlan: (firebase) => ({
    type: actions.GET_STUDENTS_FEE_PLAN,
    firebase,
  }),

  refreshPdf: (invoice, firebase) => ({
    type: actions.REFRESH_PDF,
    invoice,
    firebase,
  }),

  getCreditPdf: (record, firebase) => ({
    type: actions.GET_CREDIT_PDF,
    record,
    firebase,
  }),
};

export default actions;
