import React from "react";
import { Route, Redirect, Switch, BrowserRouter } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
// import { connect } from 'react-redux';
import App from "./containers/App/App";
import asyncComponent from "./helpers/AsyncFunc";
import { withFirebase } from "./components/firebase";
import { Spin } from "antd";
import componentLoader from "./componentLoader";

const RestrictedRoute = ({ component: Component, firebase, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      JSON.parse(localStorage.getItem("user")) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const attemptsLeft = 3;
const signinType = React.lazy(() =>
  componentLoader(() => import("./containers/Page/signinType"), attemptsLeft)
);
const phoneSignin = React.lazy(() =>
  componentLoader(() => import("./containers/Page/phoneSignin"), attemptsLeft)
);
const signin = React.lazy(() =>
  componentLoader(() => import("./containers/Page/signin"), attemptsLeft)
);
const userSelector = React.lazy(() =>
  componentLoader(() => import("./containers/Page/userSelector"), attemptsLeft)
);
const signup = React.lazy(() =>
  componentLoader(() => import("./containers/Page/signup"), attemptsLeft)
);
const forgotPassword = React.lazy(() =>
  componentLoader(
    () => import("./containers/Page/forgotPassword"),
    attemptsLeft
  )
);
const thankYou = React.lazy(() =>
  componentLoader(() => import("./containers/Page/thankYou"), attemptsLeft)
);
const signInLink = React.lazy(() =>
  componentLoader(() => import("./containers/Page/signInLink"), attemptsLeft)
);
const requestSignInLink = React.lazy(() =>
  componentLoader(
    () => import("./containers/Page/requestSignInLink"),
    attemptsLeft
  )
);
const godMode = React.lazy(() =>
  componentLoader(() => import("./containers/Page/godMode"), attemptsLeft)
);

const PublicRoutes = ({ history, firebase }) => {
  return (
    <React.Suspense
      fallback={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: window.innerHeight / 2,
          }}
        >
          <Spin />
        </div>
      }
    >
      <ConnectedRouter history={history}>
        <BrowserRouter>
          <Switch>
            <Route exact path={"/"} component={signinType} />
            <Route exact path={"/dashboard/zoom"} />
            <Route exact path={"/dashboard/firebase-messaging-sw.js"} />
            <Route exact path={"/signin"} component={signinType} />
            <Route exact path={"/phoneSignin"} component={phoneSignin} />
            <Route exact path={"/emailSignin"} component={signin} />
            <Route exact path={"/userType"} component={userSelector} />
            <Route exact path={"/signup"} component={signup} />
            <Route exact path={"/forgotPassword"} component={forgotPassword} />
            <Route exact path={"/thankYou"} component={thankYou} />

            <RestrictedRoute
              path="/dashboard"
              component={App}
              firebase={firebase}
            />

            <Route exact path={"/signInLink"} component={signInLink} />
            <Route exact path={"/teacherSigin"} component={signInLink} />
            <Route
              exact
              path={"/requestSigninLink"}
              component={requestSignInLink}
            />

            <Route exact path={"/godMode"} component={godMode} />
            <Route exact path={"/firebase-messaging-sw.js"} />
            <Route exact path={"/firebase-cloud-messaging-push-scope"} />
            <Route
              exact
              path={"*"}
              component={asyncComponent(() =>
                import("./containers/Page/FourZeroFour")
              )}
            />
          </Switch>
        </BrowserRouter>
      </ConnectedRouter>
    </React.Suspense>
  );

  /**original code */
  // return (
  //   <ConnectedRouter history={history}>
  //     <div>
  //       <Switch>
  //         <Route
  //           exact
  //           path={'/'}
  //           component={asyncComponent(() => signinType)}
  //         //component={asyncComponent(() => import('./containers/Page/signinType'))}
  //         />
  //         <Route
  //           exact
  //           path={'/dashboard/zoom'}
  //         />
  //         <Route
  //           exact
  //           path={'/signin'}
  //           component={asyncComponent(() => import('./containers/Page/signinType'))}
  //         />
  //         <Route
  //           exact
  //           path={'/phoneSignin'}
  //           component={asyncComponent(() => import('./containers/Page/phoneSignin'))}
  //         />
  //         <Route
  //           exact
  //           path={'/emailSignin'}
  //           component={asyncComponent(() => import('./containers/Page/signin'))}
  //         />
  //         <Route
  //           exact
  //           path={'/userType'}
  //           component={asyncComponent(() => import('./containers/Page/userSelector'))}
  //         />
  //         <Route
  //           exact
  //           path={'/signup'}
  //           component={asyncComponent(() => import('./containers/Page/signup'))}
  //         />
  //         <Route
  //           exact
  //           path={'/forgotPassword'}
  //           component={asyncComponent(() => import('./containers/Page/forgotPassword'))}
  //         />
  //         <Route
  //           exact
  //           path={"/forgotpassword"}
  //           component={asyncComponent(() =>
  //             import("./containers/Page/forgotPassword")
  //           )}
  //         />
  //         <Route
  //           exact
  //           path={"/thankYou"}
  //           component={asyncComponent(() =>
  //             import("./containers/Page/thankYou")
  //           )}
  //         />
  //         <RestrictedRoute
  //           path="/dashboard"
  //           component={App}
  //           firebase={firebase}
  //         />

  //         <Route
  //           exact
  //           path={"*"}
  //           component={asyncComponent(() =>
  //             import("./containers/Page/FourZeroFour")
  //           )}
  //         />
  //       </Switch>

  //     </div>
  //   </ConnectedRouter>
  // );
};

function isSignedIn(firebase) {
  var user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    return true;
  } else {
    return false;
  }
}
export default withFirebase(PublicRoutes);
