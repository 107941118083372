import '@firebase/firestore' // 👈 If you're using firestore

function getSchoolDetails(firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    var details = {};
    var detailsPromise = new Promise(function (resolve, reject) {
        rsf.ref(branchPath + "/schoolDetails").once('value').then(function (snap) {
            if (snap.val() !== null) {
                details = (snap.val());
            }
            resolve(details);
        });
    });
    return detailsPromise;
}

function updateSchoolDetail(values, firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    rsf.ref(branchPath + "/schoolDetails").update({
        address: values.schoolAddress ? values.schoolAddress : null,
        bankAccount: values.bankAccount ? values.bankAccount : null,
        contactNumber: values.contact ? values.contact : null,
        email: values.email ? values.email : null,
        fb: values.fbLink ? values.fbLink : null,
        insta: values.instaLink ? values.instaLink : null,
        web: values.websiteLink ? values.websiteLink : null
    })
}

export const SchoolDetailApi = {
    getSchoolDetails,
    updateSchoolDetail
}