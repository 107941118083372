import "@firebase/firestore"; // 👈 If you're using firestore
import { all, call, put, takeLatest, take, fork } from "redux-saga/effects";
import { StudentApi } from "../../firestore-api/student";
import { ScheduleApi } from "../../firestore-api/schedule";
import actions from "./actions";
import moment from "moment";
import bugsnagClient from "@bugsnag/js";

function* fetchSchedules({ date, firebase }) {
  const chan = yield call(ScheduleApi.getScheduleByDate, date, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      console.log("schedule saga ---", data);
      yield put({
        type: actions.GET_SCHEDULES_SUCCESSFUL,
        schedules: data,
        schedulesChannel: chan,
      });
    }
  } finally {
    console.log("end schedule channel");
  }
}

function* fetchScheduleClassrooms({ firebase }) {
  try {
    //const classroomData = yield call(StudentApi.getAutoCompleteClassrooms, firebase);
    let classroomData = JSON.parse(localStorage.getItem("classList"));
    if (classroomData) {
      yield put({
        type: actions.GET_SCHEDULE_CLASSROOM_SUCCESSFUL,
        classrooms: classroomData,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.SCHEDULE_REQUEST_FAILED,
    });
  }
}

function* deleteScheduleItem({ scheduleItem, date, className, firebase }) {
  try {
    yield call(
      ScheduleApi.deleteSchedule,
      scheduleItem,
      date,
      className,
      firebase
    );
    yield put({
      type: actions.DELETE_SCHEDULE_ITEM_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to delete schedule item", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SCHEDULE_REQUEST_FAILED,
    });
  }
}

function* addScheduleItem({
  values,
  selectedDate,
  selectedClass,
  classrooms,
  firebase,
}) {
  try {
    let repeatForAllClass = values.applyToAllClass;
    let repeatForWeek = values.repeatForRestOfTheWeek;
    var scheduleDateFormat = moment(selectedDate.getTime()).format(
      "YYYY[-]MM[-]DD"
    );
    if (!repeatForAllClass) {
      let node = yield call(
        ScheduleApi.createNewScheduleNode,
        selectedClass,
        selectedDate,
        firebase
      );
      yield call(
        ScheduleApi.addSchedule,
        selectedClass,
        scheduleDateFormat,
        values.type,
        values.title,
        values.description,
        values.time,
        node,
        firebase
      );

      if (repeatForWeek) {
        yield fork(
          repeatScheduleForWeek,
          selectedClass,
          scheduleDateFormat,
          values.type,
          values.title,
          values.description,
          values.time,
          firebase
        );
      }
    } else {
      if (!repeatForWeek) {
        let classes = classrooms;
        for (let i in classes) {
          let node = yield call(
            ScheduleApi.createNewScheduleNode,
            selectedClass,
            selectedDate,
            firebase
          );
          yield call(
            ScheduleApi.addSchedule,
            classes[i].className,
            scheduleDateFormat,
            values.type,
            values.title,
            values.description,
            values.time,
            node,
            firebase
          );
        }
      } else {
        yield fork(
          repeatScheduleForWeekForAllClasses,
          classrooms,
          scheduleDateFormat,
          values.type,
          values.title,
          values.description,
          values.time,
          firebase
        );
      }
    }
    yield put({
      type: actions.ADD_SCHEDULE_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to add schedule", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SCHEDULE_REQUEST_FAILED,
    });
  }
}

function* repeatScheduleForWeek(
  className,
  date,
  type,
  title,
  desc,
  time,
  firebase
) {
  let formattedDate = moment(date, "YYYY[-]MM[-]DD").toDate();
  let startDate = new Date(formattedDate);
  startDate.setDate(startDate.getDate() + 1);
  let presentDate = new Date(formattedDate);
  let dateNumber = presentDate.getDate();
  let endDate = new Date(presentDate.setDate(dateNumber + 7));
  while (startDate < endDate) {
    var scheduleDate = moment(startDate.getTime()).format("YYYY[-]MM[-]DD");
    let pDay = startDate.getDate();
    let weekDay = startDate.getDay();
    let startTime = new Date(time);
    startTime.setDate(pDay);
    if (weekDay === 6 || weekDay === 0) {
      console.log(" exclude saturday and sunday");
    } else {
      let node = yield call(
        ScheduleApi.createNewScheduleNode,
        className,
        startDate,
        firebase
      );
      yield call(
        ScheduleApi.addSchedule,
        className,
        scheduleDate,
        type,
        title,
        desc,
        startTime,
        node,
        firebase
      );
    }
    startDate.setDate(startDate.getDate() + 1);
  }
}

function* repeatScheduleForWeekForAllClasses(
  classrooms,
  date,
  type,
  title,
  desc,
  time,
  firebase
) {
  let formattedDate = moment(date, "YYYY[-]MM[-]DD").toDate();
  let startDate = new Date(formattedDate);
  let presentDate = new Date(formattedDate);
  let dateNumber = presentDate.getDate();
  let endDate = new Date(presentDate.setDate(dateNumber + 7));

  let classes = classrooms;
  for (let i in classes) {
    let loop = new Date(formattedDate);
    while (loop < endDate) {
      var scheduleDate = moment(loop.getTime()).format("YYYY[-]MM[-]DD");
      let pDay = loop.getDate();
      let weekDay = loop.getDay();
      let startTime = new Date(time);
      startTime.setDate(pDay);
      if (weekDay === 6 || weekDay === 0) {
        console.log(" exclude saturday and sunday");
      } else {
        let node = yield call(
          ScheduleApi.createNewScheduleNode,
          classes[i].className,
          startTime,
          firebase
        );
        yield call(
          ScheduleApi.addSchedule,
          classes[i].className,
          scheduleDate,
          type,
          title,
          desc,
          startTime,
          node,
          firebase
        );
      }
      var newDate = loop.setDate(loop.getDate() + 1);
      loop = new Date(newDate);
    }
  }
}

function* editScheduleItem({
  values,
  selectedDate,
  selectedClass,
  editableRecord,
  firebase,
}) {
  try {
    var scheduleDateFormat = moment(new Date(selectedDate).getTime()).format(
      "YYYY[-]MM[-]DD"
    );
    yield call(
      ScheduleApi.addSchedule,
      selectedClass,
      scheduleDateFormat,
      values.type,
      values.title,
      values.description,
      values.time,
      editableRecord.id,
      firebase
    );
    yield put({
      type: actions.EDIT_SCHEDULE_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to edit schedule", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SCHEDULE_REQUEST_FAILED,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_SCHEDULES, fetchSchedules),
    yield takeLatest(actions.GET_SCHEDULE_CLASSROOM, fetchScheduleClassrooms),
    yield takeLatest(actions.DELETE_SCHEDULE_ITEM, deleteScheduleItem),
    yield takeLatest(actions.ADD_SCHEDULE, addScheduleItem),
    yield takeLatest(actions.EDIT_SCHEDULE, editScheduleItem),
  ]);
}
