import actions from './actions';
const initState = {
    studentAssessment: new Map(),
    allMilestones: [],
    allStudents: [],
    developmentAreas: [],
    isLoading: false,
    studentAssessmentChannel: undefined,
    assessmentMilestoneChannel: undefined,
    error: false,
}
export default function assessmentReducer(state = initState, { type, ...action }) {
    switch (type) {
        case actions.LIST_ASSESSMENT_STUDENT_WITH_MILESTONES:
            return {
                ...state,
                isLoading: true
            };
        case actions.LIST_ASSESSMENT_STUDENT_WITH_MILESTONES_SUCCESSFUL:
            return {
                ...state,
                studentAssessment: action.studentAssessment,
                isLoading: false,
            };
        case actions.LIST_ALL_MILESTONES:
            return {
                ...state,
                isLoading: true
            };
        case actions.LIST_ALL_MILESTONES_SUCCESSFUL:
            return {
                ...state,
                allMilestones: action.allMilestones,
                assessmentMilestoneChannel: action.assessmentMilestoneChannel,
                isLoading: false,
            };
        case actions.GET_ALL_STUDENTS:
            return {
                ...state,
                isLoading: true
            };
        case actions.GET_ALL_STUDENTS_SUCCESSFUL:
            return {
                ...state,
                allStudents: action.allStudents,
                isLoading: false,
            };

        case actions.ASSESSMENT_REQUEST_FAILED:
            return {
                ...state,
                error: true,
                isLoading: false,
            };

        case actions.GET_DEVELOPMENT_AREAS:
            return {
                ...state,
            };

        case actions.GET_DEVELOPMENT_AREAS_SUCCESS:
            return {
                ...state,
                developmentAreas: action.developmentAreas
            };


        default:
            return state;
    }
}