import { all, put, call, takeLatest, fork } from 'redux-saga/effects';
import actions from './actions';
import '@firebase/firestore' // 👈 If you're using firestore
import { TagApi } from '../../firestore-api/tag';
// import { AssessmentApi } from '../../firestore-api/studentAssessment';
import { StudentApi } from "../../firestore-api/student";
import bugsnagClient from '@bugsnag/js'

function* fetchTags({ firebase }) {

    let data = JSON.parse(localStorage.getItem("groupList"));
    yield put({
        type: actions.LIST_TAGS_SUCCESSFUL,
        tags: data,
        tagsChannel: undefined
    })


    // const chan = yield call(TagApi.getAllTags, firebase);
    // try {
    //     while (true) {
    //         let data = yield take(chan);
    //         console.log("data tags", data);
    //         yield put({
    //             type: actions.LIST_TAGS_SUCCESSFUL,
    //             tags: data,
    //             tagsChannel: chan
    //         })
    //     }
    // } finally {
    //     console.log("end tag channel")
    // }
}

function* addNewTag({ value, firebase }) {
    try {
        var nodeId = yield call(TagApi.createNewTagNode, firebase);
        if (nodeId) {
            yield call(TagApi.addNewTag, value, nodeId, firebase);
            yield put({
                type: actions.ADD_TAG_SUCCESSFUL,
                tagOperationType: "ADD_TAG"
            })
        }
    } catch (err) {
        console.log("failed to add tag", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.TAG_REQUEST_FAILED
        })
    }

}

function* updateExistingTag({ value, record, firebase }) {
    try {
        if (record.id) {
            yield call(TagApi.updateTag, value, record, firebase);
            yield put({
                type: actions.UPDATE_TAG_SUCCESSFUL,
                tagOperationType: "UPDATE_TAG"
            })
        } else {
            yield put({
                type: actions.TAG_REQUEST_FAILED
            })
        }
    } catch (err) {
        console.log("failed to add tag", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.TAG_REQUEST_FAILED
        })
    }
}

function* fetchStudentForTag({ firebase }) {
    try {
        //var data = yield call(AssessmentApi.getAllStudents, firebase);
        let data = JSON.parse(localStorage.getItem("studentList"));
        if (data) {
            yield put({
                type: actions.GET_STUDENT_FOR_TAG_SUCCESSFUL,
                students: data
            })
        }

        /**do not delete this code */
        // yield callUpdateMissingStudentInGroups(data, firebase);
    } catch (err) {
        console.log("failed to fetch students", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.TAG_REQUEST_FAILED,
        })
    }
}

// function* callUpdateMissingStudentInGroups(students, firebase) {
//     try {
//         for (let index in students) {
//             let student = students[index];
//             if (student.tags) {
//                 let allTags = student.tags;
//                 for (let t in allTags) {
//                     let group = yield call(TagApi.getTagById, allTags[t].id, firebase);
//                     console.log("group fetched ------", group);
//                     if (group && group.id) {
//                         let tagStudentIds = group.studentIds ? group.studentIds : [];
//                         if (!tagStudentIds.includes(student.id)) {
//                             tagStudentIds.push(student.id);
//                         }
//                         group.studentIds = tagStudentIds;
//                         console.log("group --------", group);
//                         yield call(TagApi.updateTagWithStudents, group, firebase);
//                     }
//                 }
//             }
//         }

//     } catch (err) {
//         console.log("failed to upload missing student inn existing groups", err);
//         bugsnagClient.notify(err);
//     }
// }

function* addStudentToTag({ editableRecord, selectedStudentCheckbox, firebase }) {
    try {
        let studentIds = [];
        if (selectedStudentCheckbox.length > 0) {
            selectedStudentCheckbox.map(item => {
                studentIds.push(item.id)
            });
        };

        let prevStudentIds = editableRecord.studentIds;
        let filteredIds = [];
        for (let s in prevStudentIds) {
            if (!studentIds.includes(prevStudentIds[s])) {
                filteredIds.push(prevStudentIds[s]);
            }
        };

        console.log("filtered student ids -----", filteredIds);

        if (editableRecord && editableRecord.id) {
            yield call(TagApi.assignStudentToTag, editableRecord.id, studentIds, firebase);

            for (let i in selectedStudentCheckbox) {
                let tagObj = {
                    id: editableRecord.id,
                    name: editableRecord.name
                };

                let student = yield call(StudentApi.getStudentById, selectedStudentCheckbox[i].id, firebase);
                if (student && student.id) {
                    let studentTagArr = [];
                    if (student.tags) {
                        studentTagArr = student.tags;
                    }

                    let filterVal = studentTagArr.filter(t => {
                        return t.id === tagObj.id && t.name === tagObj.name
                    });

                    if (filterVal && filterVal.length === 0) {
                        studentTagArr.push(tagObj);
                    }

                    student.tags = studentTagArr;
                    yield fork(StudentApi.updateStudentWithUpdatedFormFields, student, firebase);
                }
            }

            for (let k in filteredIds) {
                let tagObj = {
                    id: editableRecord.id,
                    name: editableRecord.name
                };
                let student = yield call(StudentApi.getStudentById, filteredIds[k], firebase);
                if (student && student.id) {
                    let studentTagArr = [];
                    if (student.tags) {
                        studentTagArr = student.tags;
                    }

                    let removedGrpArr = studentTagArr.filter(m => {
                        return m.id !== tagObj.id && m.name !== tagObj.name
                    });

                    student.tags = removedGrpArr && removedGrpArr.length > 0 && removedGrpArr[0].id ? removedGrpArr : null;
                    yield fork(StudentApi.updateStudentWithUpdatedFormFields, student, firebase);
                }
            }


            yield put({
                type: actions.ASSIGN_STUDENT_TO_TAG_SUCCESSFUL,
                tagOperationType: "ASSIGN_STUDENT"
            })
        } else {
            yield put({
                type: actions.TAG_REQUEST_FAILED,
            });
            bugsnagClient.notify("Assign groups failed, possible cause may be undefined group object.")
        }
    } catch (err) {
        console.log("failed to add student to tag", err);
        yield put({
            type: actions.TAG_REQUEST_FAILED,
        });
        bugsnagClient.notify("ailed to add student to tag ----->>>", err.message);
    }
}

function* fetchTagClasses({ firebase }) {
    try {
        // const classroomData = yield call(
        //     StudentApi.getAutoCompleteClassrooms,
        //     firebase
        // );
        let classroomData = JSON.parse(localStorage.getItem("classList"));
        if (classroomData) {
            yield put({
                type: actions.GET_TAG_CLASSES_SUCCESSFUL,
                tagClasses: classroomData
            });
        }
    } catch (err) {
        console.log("failed to fetch tag classroom", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.TAG_REQUEST_FAILED
        });
    }
}

function* deleteSelectedTag({ record, firebase }) {
    try {
        yield call(TagApi.deleteTag, record, firebase);
        yield put({
            type: actions.DELETE_TAG_SUCCESS,
        });
    } catch (err) {
        bugsnagClient.notify(err);
        console.log("failed to delete tag", err);
        yield put({
            type: actions.TAG_REQUEST_FAILED
        });
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(actions.LIST_TAGS, fetchTags),
        yield takeLatest(actions.ADD_TAG, addNewTag),
        yield takeLatest(actions.UPDATE_TAG, updateExistingTag),
        yield takeLatest(actions.GET_STUDENT_FOR_TAG, fetchStudentForTag),
        yield takeLatest(actions.ASSIGN_STUDENT_TO_TAG, addStudentToTag),
        yield takeLatest(actions.GET_TAG_CLASSES, fetchTagClasses),
        yield takeLatest(actions.DELETE_TAG, deleteSelectedTag)
    ])
}