import "@firebase/firestore"; // 👈 If you're using firestore
import { eventChannel } from "redux-saga";
import moment from "moment-timezone";
import UserImageAction from "../Utility/userImageAction";
const superagent = require("superagent");

function getAttendance(date, firebase) {
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/attendanceUpdates/" + searchableDate)
      .on("value", (snap) => {
        let attendance = [];
        snap.forEach((element) => {
          if (element.val() !== null && element.val().id) {
            attendance.push(element.val());
          }
        });
        emit(attendance);
      });
    return () => {
      rsf.ref(branchPath + "/attendanceUpdates/" + searchableDate).off();
      console.log("unsubscribe checkInOut");
    };
  });
}

function fetchAttendanceData(date, firebase) {
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let myRef = rsf.ref(branchPath + "/attendanceUpdates/" + searchableDate);
  let attendanceMap = new Map();

  let promise = new Promise(function(resolve, reject) {
    myRef.once("value").then((snap) => {
      snap.forEach((element) => {
        if (
          element.val() !== null &&
          element.val().id &&
          !element.val().absent
        ) {
          attendanceMap.set(element.val().id, element.val());
        }
      });
      resolve(attendanceMap);
    });
  });
  return promise;
}

function getStudentByClassName(className, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var student = [];
  var studentPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/students")
      .orderByChild("deleted")
      .equalTo(false)
      .once("value")
      .then(function(snapshot) {
        snapshot.forEach((snap) => {
          if (
            snap.val() !== null &&
            snap.val().classList.includes(className) &&
            snap.val().deactivated === false
          ) {
            student.push(snap.val());
          }
        });
        resolve(student);
      });
  });
  return studentPromise;
}

function getStudentAttendanceByClassName(className, date, firebase) {
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/attendanceUpdates/" + searchableDate)
      .on("value", (snap) => {
        let attendance = [];
        snap.forEach((element) => {
          if (element.val() !== null) {
            if (
              UserImageAction.ifClassExistsForStudent(
                className,
                element.val().userId
              )
            ) {
              attendance.push(element.val());
            }
          }
        });
        emit(attendance);
      });
    return () => {
      rsf.ref(branchPath + "/attendanceUpdates/" + searchableDate).off();
      console.log("unsubscribe checkInOut");
    };
  });
}

function createUniqueNode(date, firebase) {
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/checkInOut/" + searchableDate).push().key;
  return key;
}

function markPresentToCheckInOut(
  selectedStudent,
  date,
  time,
  nodeId,
  firebase,
  checkInOutObject
) {
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(
      branchPath + "/checkInOut/" + searchableDate + "/" + selectedStudent.id
    )
    .update(checkInOutObject);
}

function markPresentToAttendanceUpdates(
  selectedStudent,
  date,
  time,
  nodeId,
  firebase,
  checkInOutObject
) {
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(
      branchPath +
        "/attendanceUpdates/" +
        searchableDate +
        "/" +
        selectedStudent.id
    )
    .update(checkInOutObject);
}

function addRecordToStudentAttendance(
  date,
  attendanceObj,
  selectedStudent,
  firebase
) {
  let searchableMonthYear = moment(date).format("MMMM[ ]YYYY");
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(
      branchPath +
        "/student-attendance/" +
        selectedStudent.id +
        "/" +
        searchableMonthYear +
        "/" +
        searchableDate
    )
    .update(attendanceObj);
}

function removeStudentRecordFromCheckInOut(selectedStudent, date, firebase) {
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(
      branchPath + "/checkInOut/" + searchableDate + "/" + selectedStudent.id
    )
    .set(null);
}

function updateRecordToStudentAttendance(selectedStudent, date, firebase, obj) {
  let searchableMonthYear = moment(date).format("MMMM[ ]YYYY");
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(
      branchPath +
        "/student-attendance/" +
        selectedStudent.id +
        "/" +
        searchableMonthYear +
        "/" +
        searchableDate
    )
    .update(obj);
}

function updateCheckOutTimeInCheckInOut(
  selectedStudent,
  date,
  time,
  firebase,
  checkInOutStatus
) {
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(
      branchPath +
        "/checkInOut/" +
        searchableDate +
        "/" +
        selectedStudent.userId
    )
    .update({
      checkOutTime: moment(time).format("h:mm a"),
      checkOutEpoch: moment(time).valueOf(),
      platform: "web",
      lateCheckout:
        checkInOutStatus && checkInOutStatus === "late" ? true : false,
      checkoutBy: firebase.teacher.name,
    });
}

function updateCheckOutTimeInAttendanceUpdates(
  selectedStudent,
  date,
  time,
  firebase,
  checkInOutStatus
) {
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(
      branchPath +
        "/attendanceUpdates/" +
        searchableDate +
        "/" +
        selectedStudent.userId
    )
    .update({
      checkOutTime: moment(time).format("h:mm a"),
      checkOutEpoch: moment(time).valueOf(),
      platform: "web",
      lateCheckout:
        checkInOutStatus && checkInOutStatus === "late" ? true : false,
      checkoutBy: firebase.teacher.name,
    });
}

function updateCheckOutTimeToStudentAttendance(
  selectedStudent,
  date,
  time,
  firebase,
  checkInOutStatus
) {
  let searchableMonthYear = moment(date).format("MMMM[ ]YYYY");
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(
      branchPath +
        "/student-attendance/" +
        selectedStudent.userId +
        "/" +
        searchableMonthYear +
        "/" +
        searchableDate
    )
    .update({
      checkOutTime: moment(time).format("h:mm a"),
      checkOutEpoch: moment(time).valueOf(),
      platform: "web",
      lateCheckout:
        checkInOutStatus && checkInOutStatus === "late" ? true : false,
      checkoutBy: firebase.teacher.name,
    });
}

function updatedAttendanceRecordApi(obj, firebase, apiEndPoint, atdAction) {
  let endPointUrl = firebase.endPointUrl;
  let attendanceApi = endPointUrl + apiEndPoint;

  let schoolConfiguration = localStorage.getItem("schoolConfig");
  let config = JSON.parse(schoolConfiguration);

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  var p1 = new Promise(function(resolve, reject) {
    superagent
      .post(attendanceApi)
      .send({
        branchPath: firebase.sbp,
        schoolName: config.schoolName,
        accountName: firebase.sbDbName,
        teacherId: firebase.teacher.id,
        timezone: localTimezone,
        attendanceRecord: obj,
        markPending: atdAction ? true : false,
      })
      .set("accept", "json")
      .end((err, res) => {
        console.log("updatedAttendanceRecordApi res ------", res);
        console.log("updatedAttendanceRecordApi err ------", err);
        resolve(res);
      });
  });
  return p1;
}

function updateStudentLastAtd(studentId, atdTime, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/students/" + studentId).update({
    lastAttendanceDate: atdTime ? atdTime : null,
    platform: "web",
  });
}

function updateStudentLastAtdCheckout(studentId, atdTime, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/students/" + studentId).update({
    lastAttendanceCheckout: atdTime ? atdTime : null,
    platform: "web",
  });
}

function lastUpdateTimestamp(time, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/lastUpdate").set(moment(time).valueOf());
}

function getStudentAttendanceById(date, id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let searchableMonthYear = moment(date).format("MMMM[ ]YYYY");
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");

  var atdRef = rsf.ref(
    branchPath +
      "/student-attendance/" +
      id +
      "/" +
      searchableMonthYear +
      "/" +
      searchableDate
  );

  var atd = {};

  var promise = new Promise(function(resolve, reject) {
    atdRef.once("value").then(function(snapshot) {
      if (snapshot.val() !== null) {
        atd = snapshot.val();
        resolve(atd);
      }
    });
  });
  return promise;
}

function getStudentAttendanceByMonth(date, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let searchableMonth = moment(date).format("MMM");
  let searchableYear = moment(date).format("YYYY");
  let atdRecord = new Map();
  var atdRef = rsf
    .ref(branchPath + "/studentMonthAttendance")
    .orderByChild("month")
    .equalTo(searchableMonth);
  var promise = new Promise(function(resolve, reject) {
    atdRef.once("value").then(function(snapshot) {
      snapshot.forEach((ele) => {
        if (
          ele.val() !== null &&
          moment(ele.val().date).format("YYYY") === searchableYear
        ) {
          let classList =
            firebase && firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            if (classList.length > 0) {
              if (ele.val().classList) {
                for (let i in classList) {
                  if (
                    ele.val().classList &&
                    ele.val().classList.includes(classList[i])
                  ) {
                    if (atdRecord.has(ele.val().userId)) {
                      let childVal = atdRecord.get(ele.val().userId);
                      childVal.push(ele.val());
                      atdRecord.set(ele.val().userId, childVal);
                    } else {
                      let childVal = [];
                      childVal.push(ele.val());
                      atdRecord.set(ele.val().userId, childVal);
                    }
                    break;
                  }
                }
              } else {
                if (classList.includes(ele.val().className)) {
                  if (atdRecord.has(ele.val().userId)) {
                    let childVal = atdRecord.get(ele.val().userId);
                    childVal.push(ele.val());
                    atdRecord.set(ele.val().userId, childVal);
                  } else {
                    let childVal = [];
                    childVal.push(ele.val());
                    atdRecord.set(ele.val().userId, childVal);
                  }
                }
              }
            } else {
              if (atdRecord.has(ele.val().userId)) {
                let childVal = atdRecord.get(ele.val().userId);
                childVal.push(ele.val());
                atdRecord.set(ele.val().userId, childVal);
              } else {
                let childVal = [];
                childVal.push(ele.val());
                atdRecord.set(ele.val().userId, childVal);
              }
            }
          } else {
            if (atdRecord.has(ele.val().userId)) {
              let childVal = atdRecord.get(ele.val().userId);
              childVal.push(ele.val());
              atdRecord.set(ele.val().userId, childVal);
            } else {
              let childVal = [];
              childVal.push(ele.val());
              atdRecord.set(ele.val().userId, childVal);
            }
          }
        }
      });
      resolve(atdRecord);
    });
  });
  return promise;
}

function markStudentPending(selectedStudent, date, firebase) {
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  let searchableMonthYear = moment(date).format("MMMM[ ]YYYY");

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf
    .ref(
      branchPath +
        "/attendanceUpdates/" +
        searchableDate +
        "/" +
        selectedStudent.id
    )
    .set(null);

  rsf
    .ref(
      branchPath +
        "/student-attendance/" +
        selectedStudent.id +
        "/" +
        searchableMonthYear +
        "/" +
        searchableDate
    )
    .set(null);
}

export const StudentAttendanceApi = {
  getAttendance,
  fetchAttendanceData,
  getStudentByClassName,
  getStudentAttendanceByClassName,
  createUniqueNode,
  markPresentToCheckInOut,
  markPresentToAttendanceUpdates,
  addRecordToStudentAttendance,
  removeStudentRecordFromCheckInOut,
  updateRecordToStudentAttendance,
  updateCheckOutTimeInCheckInOut,
  updateCheckOutTimeInAttendanceUpdates,
  updateCheckOutTimeToStudentAttendance,
  updatedAttendanceRecordApi,
  updateStudentLastAtd,
  lastUpdateTimestamp,
  getStudentAttendanceById,
  getStudentAttendanceByMonth,
  markStudentPending,
  updateStudentLastAtdCheckout,
};
