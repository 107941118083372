import "@firebase/firestore"; // 👈 If you're using firestore
import { eventChannel } from "redux-saga";
import moment from "moment-timezone";

function getAllInvoiceTemplates(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/feeTemplates/")
      .orderByChild("inverseDate")
      .on("value", (snap) => {
        var x = [];
        snap.forEach((element) => {
          if (element.val() !== null && element.val().deleted !== true) {
            x.push(element.val());
          }
        });
        emit(x);
      });
    return () => {
      rsf.ref(branchPath + "/feeTemplates").off();
      console.log("unsubscribe invoice templates");
    };
  });
}

function generateInvoiceTemplateNode(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/feeTemplates/").push().key;
  return key;
}

function addTemplate(values, rows, id, firebase, taxDescription, tax) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var newLineItems = [];

  rows.map((item) => {
    newLineItems.push({
      afterDiscountValue: Number(item.netValue),
      amount: Number(item.grossValue),
      description: item.description,
      discountType:
        item.discountType === "Percentage" ? "PERCENTAGE" : "NUMBER",
      discountValue: Number(item.discount),
      timestamp: 0,
    });
  });
  var invoiceObject = {
    activationDate: values.raisedFrom
      ? moment(values.raisedFrom).valueOf()
      : null,
    deactivationDate: values.raisedTo
      ? moment(values.raisedTo).valueOf()
      : null,
    deleted: false,
    dueDate: 0,
    frequency: values.frequency ? values.frequency : null,
    inverseDate: -moment().valueOf(),
    invoiceRaiseDate: 0,
    lastGenerationDate: 0,
    id: id,
    lineItems: newLineItems,
    name: values.feeTemplateName,
    nextGenerationDate: values.raisedFrom
      ? moment(values.raisedFrom).valueOf()
      : null,
    total: Number(values.total),
    totalWithoutTax: values.subTotal ? Number(values.subTotal) : null,
    taxRate: tax ? Number(tax) : null,
    taxDescription: taxDescription ? taxDescription : null,
    label: values.label ? values.label : null,
  };

  rsf.ref(branchPath + "/feeTemplates/" + id).update(invoiceObject);
}

function updateStudentInTemplate(
  templateRecord,
  selectedStudentCheckbox,
  firebase
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var newStudentIds = [];
  selectedStudentCheckbox.map((item) => {
    newStudentIds.push(item.id);
  });
  rsf.ref(branchPath + "/feeTemplates/" + templateRecord.id).update({
    studentIds: newStudentIds,
  });
}

function deleteInvoiceTemplate(templateRecord, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/feeTemplates/" + templateRecord.id).update({
    deleted: true,
  });
}

function getOneTimeInvoiceTemplate(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let templates = [];
  const myRef = rsf
    .ref(branchPath + "/feeTemplates")
    .orderByChild("deleted")
    .equalTo(false);
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((item) => {
        if (item.val() !== null) {
          templates.push(item.val());
        }
      });
      resolve(templates);
    });
  });
  return promise1;
}

export const InvoiceTemplateApi = {
  getAllInvoiceTemplates,
  generateInvoiceTemplateNode,
  addTemplate,
  updateStudentInTemplate,
  deleteInvoiceTemplate,
  getOneTimeInvoiceTemplate,
};
