// import clone from 'clone';
import actions from './actions';

const initState = {
  parents: [],
  editView: false,
  initialParents: false,
  editableParent: {},
  isNewParent: false,
  enableEditView: false,
  seectedId: []
};

export default function parentReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.LIST_PARENT:
      return {
        ...state,
        parents: action.parents,
        initialParents: true,
        enableEditView: false,
        seectedId: action.parents[0].id
      };
    case actions.CHANGE_PARENT:
      return {
        ...state,
        seectedId: action.id,
        editView: false
      };
    case actions.EDIT_VIEW:
      return {
        ...state,
        editView: action.view
      };
    default:
      return state;
  }
}
