const actions = {
    GET_PERMISSIONS: 'GET_PERMISSIONS',
    GET_PERMISSIONS_SUCCESS: 'GET_PERMISSIONS_SUCCESS',

    RESET_PERMISSION_OPERATION_TYPE: "RESET_PERMISSION_OPERATION_TYPE",

    SAVE_PERMISSIONS: 'SAVE_PERMISSIONS',
    SAVE_PERMISSIONS_SUCCESS: 'SAVE_PERMISSIONS_SUCCESS',

    PERMISSION_REQUEST_FAIL: "PERMISSION_REQUEST_FAIL",

    getPermissionList: (firebase) => ({
        type: actions.GET_PERMISSIONS,
        firebase,
    }),

    savePermissionList: (permissions, firebase) => ({
        type: actions.SAVE_PERMISSIONS,
        permissions,
        firebase,
    }),

    resetPermissionOperation: () => ({
        type: actions.RESET_PERMISSION_OPERATION_TYPE
    })

};
export default actions;    