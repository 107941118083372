import "@firebase/firestore"; // 👈 If you're using firestore
import { eventChannel } from "redux-saga";
import moment from "moment-timezone";

function createNewLeadNode(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/leads").push().key;
  return key;
}

function getAllLeads(startDate, endDate, firebase) {
  let startTime = startDate.getTime();
  let endTime = endDate.getTime();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/leads/")
      .orderByChild("createdDate")
      .startAt(startTime)
      .endAt(endTime)
      .on("value", (snap) => {
        var x = [];
        snap.forEach((element) => {
          if (element.val() !== null) {
            x.push(element.val());
          }
        });
        emit(x);
      });
    return () => {
      rsf.ref(branchPath + "/leads/").off();
      console.log("unsubscribe leads");
    };
  });
}

function getLifetimeLeads(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/leads/")
      .orderByChild("createdDate")
      .on("value", (snap) => {
        var x = [];
        snap.forEach((element) => {
          if (element.val() !== null) {
            x.push(element.val());
          }
        });
        x.reverse();
        emit(x);
      });
    return () => {
      rsf.ref(branchPath + "/leads/").off();
      console.log("unsubscribe leads");
    };
  });
}

// function getMonthDateRange(year, month) {
//   var moment = require('moment');

//   var startDate = moment([year, month - 1]);

//   var endDate = moment(startDate).endOf('month');

//   console.log(startDate.toDate());
//   console.log(endDate.toDate());

//   return { start: startDate, end: endDate };
// }

function getFeePrograms(firebase) {
  let feeProgram = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/programs");
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((element) => {
        if (element !== null) {
          let classList =
            firebase && firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            if (classList.length > 0) {
              let activityClasses = element.val().classroom;
              if (activityClasses) {
                for (let index in classList) {
                  let filteredVal = activityClasses.filter((f) => {
                    return (
                      f.name.toLowerCase() === classList[index].toLowerCase()
                    );
                  });

                  if (filteredVal && filteredVal.length > 0) {
                    feeProgram.push(element.val());
                    break;
                  }
                }
              } else {
                feeProgram.push(element.val());
              }
            } else {
              feeProgram.push(element.val());
            }
          } else {
            feeProgram.push(element.val());
          }
        }
      });
      resolve(feeProgram);
    });
  });
  return promise1;
}

function addLead(values, nodeId, firebase, leadsConfig) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/leads/" + nodeId).set({
    createdDate: moment().valueOf(),
    createdBy: firebase.teacher.name,
    id: nodeId,
    student: {
      name: values.studentName,
      gender: values.gender ? values.gender : null,
      birthDate: values.birthDate ? moment(values.birthDate).valueOf() : null,
      school: values.schoolName ? values.schoolName : null,
      preferredName: values.prefName ? values.prefName : null,
      address: values.leadAddress ? values.leadAddress : null,
    },
    feeProgram: values.feeProgram ? values.feeProgram : null,
    leadStatus: values.leadStatus ? values.leadStatus : null,
    parentOne: {
      name: values.fatherName ? values.fatherName : null,
      email: values.parentOneEmail ? values.parentOneEmail : null,
      phone: values.parentOnePhone ? Number(values.parentOnePhone) : null,
      organization: values.fatherOrganization
        ? values.fatherOrganization
        : null,
    },
    parentTwo: {
      name: values.motherName ? values.motherName : null,
      email: values.parentTwoEmail ? values.parentTwoEmail : null,
      phone: values.parentTwoPhone ? Number(values.parentTwoPhone) : null,
      organization: values.motherOrganization
        ? values.motherOrganization
        : null,
    },
    source: values.source ? values.source : null,
    subSource: values.subSource ? values.subSource : null,
    additionalDescription: values.additionalDescription
      ? values.additionalDescription
      : null,
    expectedStartDate: values.expectedStartDate
      ? moment(values.expectedStartDate).valueOf()
      : null,
    additionalProps: leadsConfig,
  });
}

function updateLead(values, record, firebase, leadsConfig) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/leads/" + record.id).update({
    student: {
      name: values.studentName,
      gender: values.gender ? values.gender : null,
      birthDate: values.birthDate ? moment(values.birthDate).valueOf() : null,
      school: values.schoolName ? values.schoolName : null,
      preferredName: values.prefName ? values.prefName : null,
      address: values.leadAddress ? values.leadAddress : null,
    },
    feeProgram: values.feeProgram ? values.feeProgram : null,
    leadStatus: values.leadStatus ? values.leadStatus : null,
    expectedStartDate: values.expectedStartDate
      ? moment(values.expectedStartDate).valueOf()
      : null,
    parentOne: {
      name: values.fatherName ? values.fatherName : null,
      email: values.parentOneEmail ? values.parentOneEmail : null,
      phone: values.parentOnePhone ? Number(values.parentOnePhone) : null,
      organization: values.fatherOrganization
        ? values.fatherOrganization
        : null,
    },
    parentTwo: {
      name: values.motherName ? values.motherName : null,
      email: values.parentTwoEmail ? values.parentTwoEmail : null,
      phone: values.parentTwoPhone ? Number(values.parentTwoPhone) : null,
      organization: values.motherOrganization
        ? values.motherOrganization
        : null,
    },
    source: values.source ? values.source : null,
    subSource: values.subSource ? values.subSource : null,
    additionalDescription: values.additionalDescription
      ? values.additionalDescription
      : null,
    additionalProps: leadsConfig,
  });
}

function createStatusNodeId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/leadsStatus/").push().key;
  return key;
}

function addStatus(value, nodeId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/leadsStatus/" + nodeId).set({
    id: nodeId,
    name: value,
  });
}

function getLeadsStatus(firebase) {
  let status = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/leadsStatus");
  var promise1 = new Promise(function(resolve, reject) {
    myRef.once("value").then(function(snap) {
      snap.forEach((element) => {
        if (element !== null) {
          status.push(element.val());
        }
      });
      resolve(status);
    });
  });
  return promise1;
}

function getSelectedLead(selectedLead, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf.ref(branchPath + "/leads/" + selectedLead.id).on("value", (snap) => {
      var x = [];
      if (snap.val() !== null) {
        x.push(snap.val());
      }
      emit(x);
    });
    return () => {
      rsf.ref(branchPath + "/leads/" + selectedLead.id).off();
      console.log("unsubscribe leads");
    };
  });
}

function createNewActivityNode(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/leadsActivity/" + id).push().key;
  return key;
}

function addActivity(value, leadId, nodeId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/leadsActivity/" + leadId + "/" + nodeId).set({
    id: nodeId,
    activityType: value.activityType,
    description: value.additionalDescription
      ? value.additionalDescription
      : null,
    date: value.date ? moment(value.date).valueOf() : null,
    time: value.time ? moment(value.time).valueOf() : null,
    createdBy: firebase.teacher.name,
    nextFollowUpDate: value.nextFollowUpDate
      ? moment(value.nextFollowUpDate).valueOf()
      : null,
  });
}

function getSelectedLeadActivity(selectedLead, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/leadsActivity/" + selectedLead.id)
      .on("value", (snap) => {
        var x = [];
        snap.forEach((item) => {
          if (item.val() !== null) {
            x.push(item.val());
          }
        });
        emit(x);
      });
    return () => {
      rsf.ref(branchPath + "/leadsActivity/" + selectedLead.id).off();
      console.log("unsubscribe leads");
    };
  });
}

function updateLeadStatusChange(selectedLeadStatus, lead, nodeId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/leadsActivity/" + lead.id + "/" + nodeId).update({
    id: nodeId,
    activityType: "Status Update",
    description: "Status changed to " + selectedLeadStatus.key,
    date: moment().valueOf(),
    time: moment().valueOf(),
  });
}

function updateLeadStatusInLead(selectedLeadStatus, lead, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/leads/" + lead.id).update({
    leadStatus: selectedLeadStatus.key,
  });
}

function updateFollowUpDate(value, leadId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/leads/" + leadId).update({
    nextFollowUpDate: value.nextFollowUpDate
      ? moment(value.nextFollowUpDate).valueOf()
      : null,
  });
}

function getLeadsForSingleDay(startDate, endDate, firebase) {
  let startTime = startDate.getTime();
  let endTime = endDate.getTime();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return rsf
    .ref(branchPath + "/leads/")
    .orderByChild("createdDate")
    .startAt(startTime)
    .endAt(endTime)
    .once("value")
    .then((snap) => {
      var x = [];
      snap.forEach((element) => {
        if (element.val() !== null) {
          x.push(element.val());
        }
      });
      return x;
    });
}

function getFollowUpDueToday(startDate, endDate, firebase) {
  let startTime = startDate.getTime();
  let endTime = endDate.getTime();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return rsf
    .ref(branchPath + "/leads/")
    .orderByChild("nextFollowUpDate")
    .startAt(startTime)
    .endAt(endTime)
    .once("value")
    .then((snap) => {
      var x = [];
      snap.forEach((element) => {
        if (element.val() !== null) {
          x.push(element.val());
        }
      });
      return x;
    });
}

function followUpOverdue(startDate, endDate, firebase) {
  let startTime = startDate.getTime();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return rsf
    .ref(branchPath + "/leads/")
    .orderByChild("nextFollowUpDate")
    .endAt(startTime)
    .once("value")
    .then((snap) => {
      var x = [];
      snap.forEach((element) => {
        if (
          element.val() !== null &&
          element.val().nextFollowUpDate &&
          (element.val().leadStatus !== "Admitted" ||
            element.val().leadStatus !== "Accepted")
        ) {
          x.push(element.val());
        }
      });
      return x;
    });
}

function deleteLead(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/leads/" + record.id).set(null);
}

function deleteLeadStatus(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/leadsStatus/" + record.id).set(null);
}

function getAdditionalFields(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var formField = [];
  var formPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/leadsFormRef")
      .orderByChild("deleted")
      .equalTo(false)
      .once("value")
      .then(function(snapshot) {
        snapshot.forEach((item) => {
          if (item.val() !== null) {
            formField.push(item.val());
          }
        });
        resolve(formField);
      });
  });
  return formPromise;
}

function getLifetimeLeadsData(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var leadsData = [];
  var leadPromise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/leads/")
      .once("value")
      .then((snap) => {
        snap.forEach((element) => {
          leadsData.push(element.val());
        });
        resolve(leadsData);
      });
  });
  return leadPromise;
}

function updateLeadStudentAddition(record, studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/leads/" + record.id).update({ studentId: studentId });
}

function deleteLeadActivity(item, leadId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/leadsActivity/" + leadId + "/" + item.id).set(null);
}

export const LeadsApi = {
  createNewLeadNode,
  getAllLeads,
  getLifetimeLeads,
  getFeePrograms,
  addLead,
  updateLead,
  createStatusNodeId,
  addStatus,
  getLeadsStatus,
  getSelectedLead,
  createNewActivityNode,
  addActivity,
  getSelectedLeadActivity,
  updateLeadStatusChange,
  updateLeadStatusInLead,
  updateFollowUpDate,
  getLeadsForSingleDay,
  getFollowUpDueToday,
  followUpOverdue,
  deleteLead,
  deleteLeadStatus,
  getAdditionalFields,
  getLifetimeLeadsData,
  updateLeadStudentAddition,
  deleteLeadActivity,
};
