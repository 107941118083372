import actions from './actions';
const initState = {
    schoolSettingDetails: [],
    userDetail: [],
    isLoading: false,
    error: false,
    schoolUserChannel: undefined,
    operationRequest: "",
    schoolConfig: undefined,
    activeActivities: []
}
export default function userSettingReducer(state = initState, { type, ...action }) {
    switch (type) {
        case actions.GET_SCHOOL_SETTING_DETAILS:
            return {
                ...state,
                isLoading: true,
                schoolSettingDetails: [],
                operationRequest: '',
                cameraDetails: [],
                classroomList: [],
                configLoading: false
            };
        case actions.GET_SCHOOL_SETTING_DETAILS_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                schoolSettingDetails: action.schoolSettingDetails,
                schoolUserChannel: action.schoolUserChannel,
                operationRequest: ''
            };
        case actions.UPLOAD_SCHOOL_LOGO:
            return {
                ...state,
                isLoading: true,
            };
        case actions.UPLOAD_SCHOOL_LOGO_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                operationRequest: 'UPLOAD_LOGO'
            };
        case actions.UPDATE_USER_INFO:
            return {
                ...state,
                isLoading: true,
            };
        case actions.UPDATE_USER_INFO_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                operationRequest: 'UPDATE_DETAIL'
            };

        case actions.GET_USER_DETAILS:
            return {
                ...state,
                isLoading: true,
            };
        case actions.GET_USER_DETAILS_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                userDetail: action.userDetail
            };

        case actions.UPDATE_TEACHER_INFO:
            return {
                ...state,
                // isLoading: true,
            };
        case actions.UPDATE_TEACHER_INFO_SUCCESSFUL:
            return {
                ...state,
                // isLoading: false,
                operationRequest: 'UPDATE_DETAIL'
            };

        case actions.GET_CAMERA_DETAILS:
            return {
                ...state,
            };
        case actions.GET_CAMERA_DETAILS_SUCCESSFUL:
            return {
                ...state,
                cameraDetails: action.cameraDetails
            };

        case actions.GET_CLASSROOM_FOR_CAMERA_ASSIGN:
            return {
                ...state,
            };
        case actions.GET_CLASSROOM_FOR_CAMERA_ASSIGN_SUCCESSFFUL:
            return {
                ...state,
                classroomList: action.classroomList
            };

        case actions.SCHOOL_SETTING_REQUEST_FAILED:
            return {
                ...state,
                isLoading: false,
                error: true,
            };

        case actions.UPDATE_CAMERA_TO_CLASS:
            return {
                ...state,
                isLoading: true,
            };
        case actions.UPDATE_CAMERA_TO_CLASS_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                operationRequest: 'UPDATE_DETAIL'
            };

        case actions.GET_SCHOOL_CONFIG:
            return {
                ...state,
                isLoading: true,
                configLoading: true
            };
        case actions.GET_SCHOOL_CONFIG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                configLoading: false,
                schoolConfig: action.schoolConfig
            };

        case actions.SAVE_CONFIG_SETTING:
            return {
                ...state,
                isLoading: true,
            };
        case actions.SAVE_CONFIG_SETTING_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                operationRequest: 'SETTINGS_UPDATED'
            };

        case actions.RESET_OPERATION_REQ:
            return {
                ...state,
                isLoading: false,
                operationRequest: '',
                error: false
            };

        case actions.SUBMIT_HANDBOOK:
            return {
                ...state,
                isLoading: true,
            };
        case actions.SUBMIT_HANDBOOK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                operationRequest: 'UPDATE_DETAIL'
            };
            case actions.SUBMIT_TERMS:
                return {
                    ...state,
                    isLoading: true,
                };
            case actions.SUBMIT_TERMS_SUCCESS:
                return {
                    ...state,
                    isLoading: false,
                    operationRequest: 'UPDATE_DETAIL'
                };

        case actions.GET_ACTIVE_ACTIVITY:
            return {
                ...state,
                isLoading: true,
            };
        case actions.GET_ACTIVE_ACTIVITY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                activeActivities: action.activeActivities
            };

        // case actions.SAVE_ACTIVE_ACTVITIES:
        //     return {
        //         ...state,
        //         isLoading: true,
        //     };
        // case actions.SAVE_ACTIVE_ACTVITIES_SUCCESS:
        //     return {
        //         ...state,
        //         isLoading: false,
        //     };

        default:
            return state;
    }
}