import actions from "./actions";
const initState = {
  feePlanComponent: [],
  isLoading: false,
  feePlanComponentChannel: undefined,
  operationType: "",
  feePlan: [],
  students: [],
  error: false,
  allFeeComponent: [],
  feePlanInvoice: [],
  planInvoiceDownloadUrl: {},
  planProgram: {}
};

export default function detailedFeePlanReducer(
  state = initState,
  { type, ...action }
) {
  switch (type) {
    case actions.LIST_SELECTED_FEE_PLAN_FEE_COMPONENTS:
      return {
        ...state,
        isLoading: true,
        operationType: ""
      };
    case actions.LIST_SELECTED_FEE_PLAN_FEE_COMPONENTS_SUCCESSFFUL:
      return {
        ...state,
        feePlanComponent: action.feePlanComponent,
        feePlan: action.feePlan,
        isLoading: false,
        feePlanComponentChannel: action.feePlanComponentChannel
      };
    case actions.ADD_FEE_COMPONENT_TO_FEE_PLAN:
      return {
        ...state,
        isLoading: true
      };
    case actions.ADD_FEE_COMPONENT_TO_FEE_PLAN_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "ADD_FEE_COMPONENT_TO_PLAN"
      };
    case actions.UPDATE_EXISTING_FEE_COMPONENT:
      return {
        ...state,
        isLoading: true
      };
    case actions.UPDATE_EXISTING_FEE_COMPONENT_SUCCESSFFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "UPDATE_FEE_COMPONENT_TO_PLAN"
      };
    case actions.DETAILED_FEE_FETCH_STUDENT:
      return {
        ...state,
        isLoading: true
      };
    case actions.DETAILED_FEE_FETCH_STUDENT_SUCCESSFFUL:
      return {
        ...state,
        isLoading: false,
        students: action.students
      };
    case actions.ASSIGN_STUDENT_TO_DETAILED_FEE_PLAN:
      return {
        ...state,
        isLoading: true
      };
    case actions.ASSIGN_STUDENT_TO_DETAILED_FEE_PLAN_SUCCESSFUL:
      return {
        ...state,
        isLoading: false
      };
    case actions.DELETE_STUDENT_FROM_DETAILED_FEE_PLAN:
      return {
        ...state,
        isLoading: true
      };
    case actions.DELETE_STUDENT_FROM_DETAILED_FEE_PLAN_SUCCESSFUL:
      return {
        ...state,
        isLoading: false
      };
    case actions.GET_ALL_FEE_COMPONENT_TO_ASSIGN:
      return {
        ...state,
        isLoading: true
      };
    case actions.GET_ALL_FEE_COMPONENT_TO_ASSIGN_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        allFeeComponent: action.allFeeComponent
      };
    case actions.SAVE_ATTACHED_FEE_COMPONENT:
      return {
        ...state,
        isLoading: true
      };
    case actions.SAVE_ATTACHED_FEE_COMPONENT_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
      };
    case actions.DETAILED_FEE_PLAN_REQUEST_FAILED:
      return {
        ...state,
        error: true,
        isLoading: false
      };

    case actions.GET_FEE_PLAN_INVOICE:
      return {
        ...state,
        isLoading: true
      };
    case actions.GET_FEE_PLAN_INVOICE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        feePlanInvoice: action.feePlanInvoice
      };

    case actions.GET_PLAN_INVOICE_DOWNLOAD_URL:
      return {
        ...state,
        isLoading: true,
        planInvoiceDownloadUrl: {},
        operationType: ""
      };
    case actions.GET_PLAN_INVOICE_DOWNLOAD_URL_SUCCESSFUL:
      return {
        ...state,
        planInvoiceDownloadUrl: action.planInvoiceDownloadUrl,
        isLoading: false,
        operationType: "DOWNLOAD_URL"
      };

    case actions.RESET_PLAN_OPERATION:
      return {
        ...state,
        operationType: "",
        error: false
      };

    case actions.UPDATE_STUDENT_PLAN_STATUS:
      return {
        ...state,
        isLoading: true,
      };

    case actions.UPDATE_STUDENT_PLAN_STATUS_SUCESS:
      return {
        ...state,
        isLoading: false
      };

    case actions.DELETE_COMPONENT_FROM_FEE_PLAN:
      return {
        ...state,
        isLoading: true,
      };

    case actions.DELETE_COMPONENT_FROM_FEE_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false
      };

    case actions.GET_PROGRAM_BY_ID:
      return {
        ...state,
      };

    case actions.GET_PROGRAM_BY_ID_SUCCESS:
      return {
        ...state,
        planProgram: action.planProgram
      };

    default:
      return state;
  }
}
