import "@firebase/firestore"; // 👈 If you're using firestore
import { eventChannel } from "redux-saga";
import moment from "moment";

function getStudentTimelineByLimit(studentId, firebase, limit) {
  const rsf = firebase.secondaryDb;
  let branchPath;
  // if (firebase.selectedMode && firebase.selectedMode === "teacher") {
  //     branchPath = firebase.branchPath;
  // } else {
  branchPath = firebase.sbp;
  // }

  let myRef = rsf
    .ref(branchPath + "/timeline/" + studentId)
    .orderByChild("inverseDate")
    .limitToFirst(limit);

  return eventChannel((emit) => {
    myRef.on("value", (snapshot) => {
      var ids = [];
      snapshot.forEach((snap) => {
        if (snap.val() !== null && snap.val().activityIds) {
          ids = [...ids, ...snap.val().activityIds];
        }
      });
      emit(ids);
    });
    return () => {
      myRef.off();
      console.log("Student timeline listener off");
    };
  });
}

function getStudentTimeline(studentId, firebase, date) {
  let myDate = new Date(date);
  let dateNow = moment(myDate).format("D[ ]MMMM[ ]YYYY");

  const rsf = firebase.secondaryDb;
  let branchPath;
  // if (firebase.selectedMode && firebase.selectedMode === "teacher") {
  //     branchPath = firebase.branchPath;
  // } else {
  branchPath = firebase.sbp;
  // }
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/timeline/" + studentId + "/" + dateNow)
      .on("value", (snap) => {
        var ids = [];
        if (snap.val() !== null && snap.val().activityIds) {
          ids = [...ids, ...snap.val().activityIds];
        }
        emit(ids);
      });
    return () => {
      rsf.ref(branchPath + "/timeline/" + studentId + "/" + dateNow).off();
      console.log("Student timeline listener off");
    };
  });
}

function getStudentTimelineByActivity(studentId, firebase, date, activityType) {
  const rsf = firebase.secondaryDb;
  let branchPath;
  // if (firebase.selectedMode && firebase.selectedMode === "teacher") {
  //     branchPath = firebase.branchPath;
  // } else {
  branchPath = firebase.sbp;
  // }

  let activityRef = rsf.ref(branchPath + "/" + activityType + "/" + studentId);
  return eventChannel((emit) => {
    activityRef
      .orderByChild("inverseDate")
      .limitToFirst(100)
      .on("value", (snapshot) => {
        var ids = [];
        snapshot.forEach((snap) => {
          if (snap.val() !== null && snap.val().activityId) {
            ids.push(snap.val().activityId);
          }
        });
        emit(ids);
      });
    return () => {
      activityRef.off();
      console.log("Student timeline listener off");
    };
  });
}

function fetchAltTimelineActivities(activityId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath;
  // if (firebase.selectedMode && firebase.selectedMode === "teacher") {
  //     branchPath = firebase.branchPath;
  // } else {
  branchPath = firebase.sbp;
  // }

  return rsf
    .ref(branchPath + "/activities/" + activityId)
    .once("value")
    .then(function(snapshot) {
      if (firebase.selectedMode === "parent") {
        if (
          snapshot.val() !== null &&
          !snapshot.val().deleted &&
          snapshot.val().approved &&
          !snapshot.val().staffOnly
        ) {
          return snapshot.val();
        }
      } else {
        if (
          snapshot.val() !== null &&
          !snapshot.val().deleted &&
          snapshot.val().approved
        ) {
          return snapshot.val();
        }
      }
    });
}

function* getUpcomingVirtualClass(studentId, firebase) {
  let startTime = moment()
    .subtract(60, "m")
    .valueOf();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/Virtual Class/" + studentId)
      .orderByChild("inverseMeetingId")
      .startAt(startTime)
      // .limitToFirst(3)
      //.endAt(endTime)
      .on("value", (snap) => {
        var ids = [];
        snap.forEach((child) => {
          if (child.val() !== null) {
            ids.push(child.val());
          }
        });
        emit(ids);
      });
    return () => {
      rsf.ref(branchPath + "/Virtual Class/" + studentId).off();
      console.log("Student virtual class listener off");
    };
  });
}

function getPreviousTimeline(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath;
  if (firebase.selectedMode && firebase.selectedMode === "teacher") {
    branchPath = firebase.branchPath;
  } else {
    branchPath = firebase.sbp;
  }

  var ids = [];
  var promise = new Promise(function(resolve, reject) {
    rsf
      .ref(branchPath + "/timeline/" + studentId)
      .orderByChild("inverseDate")
      .limitToFirst(5)
      .once("value")
      .then(function(snap) {
        console.log("snapshot ----", snap.val());
        snap.forEach((child) => {
          if (child.val() !== null && child.val().activityIds) {
            ids = [...ids, ...child.val().activityIds];
          }
        });
        resolve(ids);
      });
  });
  return promise;
}

function getActivityViewCount(activityId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var viewCountRef = rsf.ref(branchPath + "/viewCount/" + activityId);
  var promise = new Promise(function(resolve, reject) {
    let viewCount = {};
    viewCountRef.once("value").then((snap) => {
      if (snap.val() !== null) {
        viewCount = snap.val();
      } else {
        viewCount = {
          id: activityId,
        };
      }
      resolve(viewCount);
    });
  });
  return promise;
}

function updateActivityViewCount(activityId, userObject, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/viewCount/" + activityId).update(userObject);
}

function getBookings(
  startDate,
  endDate,
  classroomName,
  firebase,
  fetchType,
  selectedTimezone
) {
  let startTime = startDate;
  let endTime = endDate;

  let endMonthTime = moment(startDate)
    .endOf("month")
    .valueOf();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var bRef;
  if (fetchType && fetchType === "singleDate") {
    bRef = rsf
      .ref(branchPath + "/bookingRef")
      .orderByChild("time")
      .startAt(startTime)
      .endAt(endTime);
  } else {
    bRef = rsf
      .ref(branchPath + "/bookingRef")
      .orderByChild("time")
      .startAt(startTime)
      .endAt(endMonthTime);
  }
  var ids = [];

  var promise = new Promise(function(resolve, reject) {
    bRef.once("value").then((snapshot) => {
      snapshot.forEach((snap) => {
        if (snap.val() !== null) {
          ids.push(snap.val());
        }
      });
      resolve(ids);
    });
  });
  return promise;
}

function cancelMeetingReminder(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/emailReminder/" + studentId).set(null);
}

function addMeetingReminder(studentId, obj, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/emailReminder/" + studentId).update(obj);
}

function getStudentVirtualClassActivity(studentId, date, firebase) {
  let startTime = moment(date)
    .startOf("day")
    .valueOf();
  let endTime = moment(date)
    .endOf("day")
    .valueOf();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let timelineRef = rsf
    .ref(branchPath + "/Virtual Class/" + studentId)
    .orderByChild("inverseMeetingId")
    .startAt(startTime)
    .endAt(endTime);

  let activityIds = [];

  let promise = new Promise(function(resolve, reject) {
    timelineRef.once("value").then((snapshot) => {
      snapshot.forEach((snap) => {
        if (snap.val() !== null && snap.val().activityId) {
          activityIds.push(snap.val().activityId);
        }
      });
      resolve(activityIds);
    });
  });
  return promise;
}

function fetchRepeatedActivities(activityId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath;
  if (firebase.selectedMode && firebase.selectedMode === "teacher") {
    branchPath = firebase.branchPath;
  } else {
    branchPath = firebase.sbp;
  }
  // console.log("firebase selected mode ------", firebase.selectedMode);
  return rsf
    .ref(branchPath + "/repeatClasses/" + activityId)
    .once("value")
    .then(function(snapshot) {
      if (
        snapshot.val() !== null &&
        !snapshot.val().deleted &&
        snapshot.val().approved
      ) {
        return snapshot.val();
      }
    });
}

function getOnlineBookings(startDate, firebase, fetchType) {
  let startTime = moment(startDate)
    .startOf("day")
    .valueOf();
  let endTime = moment(startDate)
    .endOf("day")
    .valueOf();

  let endMonthTime = moment(startDate)
    .endOf("month")
    .valueOf();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var bRef;
  if (fetchType && fetchType === "singleDate") {
    bRef = rsf
      .ref(branchPath + "/bookingRef")
      .orderByChild("time")
      .startAt(startTime)
      .endAt(endTime);
  } else {
    bRef = rsf
      .ref(branchPath + "/bookingRef")
      .orderByChild("time")
      .startAt(startTime)
      .endAt(endMonthTime);
  }
  var ids = [];

  var promise = new Promise(function(resolve, reject) {
    bRef.once("value").then((snapshot) => {
      snapshot.forEach((snap) => {
        if (snap.val() !== null) {
          ids.push(snap.val());
        }
      });
      resolve(ids);
    });
  });
  return promise;
}

function getStaffTimelineByLimit(teacherId, firebase, startDate, endDate) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let startTime = moment(startDate).valueOf();
  let endTime = moment(endDate).valueOf();

  let myRef = rsf
    .ref(branchPath + "/timeline/" + teacherId)
    .orderByChild("date")
    .startAt(startTime)
    .endAt(endTime);

  return eventChannel((emit) => {
    myRef.on("value", (snapshot) => {
      var ids = [];
      snapshot.forEach((snap) => {
        if (snap.val() !== null && snap.val().activityIds) {
          ids = [...ids, ...snap.val().activityIds];
        }
      });
      emit(ids);
    });
    return () => {
      myRef.off();
      console.log("Staff timeline listener off");
    };
  });
}

export const TimelineApi = {
  getStudentTimeline,
  getStudentTimelineByActivity,
  fetchAltTimelineActivities,
  getUpcomingVirtualClass,
  getPreviousTimeline,
  getActivityViewCount,
  updateActivityViewCount,
  getBookings,
  cancelMeetingReminder,
  addMeetingReminder,
  getStudentVirtualClassActivity,
  fetchRepeatedActivities,
  getOnlineBookings,
  getStudentTimelineByLimit,
  getStaffTimelineByLimit,
};
