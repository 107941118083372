import actions from "./actions";
const initState = {
  leads: [],
  feeProgram: [],
  status: [],
  isLoading: false,
  error: false,
  operationType: undefined,
  leadsChannel: undefined,
  detailedLead: [],
  detailedLeadActivity: [],
  detailedLeadChannel: undefined,
  detailedLeadActivityChannel: undefined,
  prevFrequency: undefined,
  errorMessage: undefined,
  leadAdditionalField: [],
  sourceOptions: [],
};
export default function leadsReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.LIST_LEADS:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.LIST_LEADS_SUCCESSFULL:
      return {
        ...state,
        leads: action.leads,
        isLoading: false,
        operationType: action.operationType,
        leadsChannel: action.leadsChannel,
        prevFrequency: action.prevFrequency,
        sourceOptions: action.sourceOptions,
      };

    case actions.GET_FEE_PROGRAM_FOR_LEADS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_FEE_PROGRAM_FOR_LEADS_SUCCESSFUL:
      return {
        ...state,
        feeProgram: action.feeProgram,
        isLoading: false,
      };

    case actions.GET_STATUS:
      return {
        ...state,
        isLoading: true,
        operationType: "",
      };
    case actions.GET_STATUS_SUCCESSFUL:
      return {
        ...state,
        status: action.status,
        isLoading: false,
        operationType: undefined,
      };

    case actions.SAVE_NEW_LEAD:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.SAVE_NEW_LEAD_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "ADD_LEAD",
      };

    case actions.UPDATE_LEAD:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.UPDATE_LEAD_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "UPDATE_LEAD",
      };

    case actions.ADD_STATUS:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.ADD_STATUS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "ADD_STATUS",
      };

    case actions.GET_LEAD_DETAIL:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.GET_LEAD_DETAIL_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: undefined,
        detailedLead: action.detailedLead,
        detailedLeadChannel: action.detailedLeadChannel,
      };

    case actions.GET_LEAD_ACTIVITY:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.GET_LEAD_ACTIVITY_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: undefined,
        detailedLeadActivity: action.detailedLeadActivity,
        detailedLeadActivityChannel: action.detailedLeadActivityChannel,
      };

    case actions.ADD_ACTIVITY:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.ADD_ACTIVITY_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "ADD_ACTIVITY",
      };

    case actions.UPDATE_LEAD_STATUS_CHANGE:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.UPDATE_LEAD_STATUS_CHANGE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: undefined,
      };

    case actions.LEAD_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actions.RESET_LEADS_OPERATION_TYPE:
      return {
        ...state,
        isLoading: false,
        error: false,
        operationType: undefined,
        prevFrequency: undefined,
        errorMessage: undefined,
      };

    case actions.CREATE_NEW_ACCOUNT:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };

    case actions.CREATE_NEW_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "CREATE_ACCOUNNT_SUCCESS",
      };

    case actions.DELETE_LEAD:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };

    case actions.DELETE_LEAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "DELETE_LEAD",
      };

    case actions.DELETE_LEAD_STATUS:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };

    case actions.DELETE_LEAD_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "DELETE_LEAD_STATUS",
      };

    case actions.GET_LEADS_ADDITIONAL_FIELD:
      return {
        ...state,
      };

    case actions.GET_LEADS_ADDITIONAL_FIELD_SUCCESS:
      return {
        ...state,
        leadAdditionalField: action.leadAdditionalField,
      };

    case actions.DOWNLOAD_LEADS_EXCEL:
      return {
        ...state,
        isLoading: true,
      };

    case actions.DOWNLOAD_LEADS_EXCEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.DELETE_LEAD_ACTIVITY:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.DELETE_LEAD_ACTIVITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
