import { all, put, call, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore";
import { lessonAssignmentApi } from '../../firestore-api/lessonAssignment';
import bugsnagClient from '@bugsnag/js';

function* fetchLessonStats({ firebase }) {
    try {
        let response = yield call(lessonAssignmentApi.getLessonStats, firebase);
        if (response && response.body) {
            yield put({
                type: actions.GET_LESSON_STATS_SUCCESSFUL,
                lessonStats: response.body,
            });
        } else {
            yield put({
                type: actions.GET_LESSON_STATS_SUCCESSFUL,
                lessonStats: [],
            });
        }
    } catch (err) {
        console.log("failed to get lesson stats", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.LESSON_STAT_REQUEST_FAIL
        });
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(actions.GET_LESSON_STATS, fetchLessonStats),
    ]);
}