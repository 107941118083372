import { all, put, call, take, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { CommentApi } from "../../firestore-api/comment";
import { NotificationApi } from "../../firestore-api/notification";
import { lessonAssignmentApi } from "../../firestore-api/lessonAssignment";
import bugsnagClient from "@bugsnag/js";
import { ActivityApi } from "../../firestore-api/activity";
import UserImageAction from "../../Utility/userImageAction";

function* fetchComments({ id, firebase }) {
  const chan = yield call(CommentApi.getComments, id, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_COMMENTS_SUCCESSFULL,
        comments: data,
        commentsChannel: chan,
      });
    }
  } finally {
    console.log("end leads channel");
  }
}

function* addNewComment({
  commentObj,
  firebase,
  commentType,
  activityName,
  studentId,
  itemId,
  activityStudentIds,
  lessonId,
}) {
  try {
    let nodeId = yield call(
      CommentApi.createCommentNode,
      commentObj.sourceId,
      firebase
    );
    var newCommentObject = commentObj;
    newCommentObject.id = nodeId;

    let img = commentObj.uploadedImage ? commentObj.uploadedImage : undefined;
    if (img) {
      let storagePath = firebase.sbp + "/media/images/";
      let mediaPaths = yield call(
        ActivityApi.getMediaPath,
        storagePath,
        img,
        firebase
      );
      if (mediaPaths) {
        newCommentObject.uploadedImage = mediaPaths[0];
      }
    }
    yield call(
      CommentApi.updateComment,
      newCommentObject,
      commentObj.sourceId,
      nodeId,
      firebase
    );

    if (commentType) {
      let allStudents = JSON.parse(localStorage.getItem("studentList"));
      let studentIds = [];
      if (studentId && studentId !== "undefined") {
        studentIds = [studentId];
      } else if (activityStudentIds && activityStudentIds.length > 0) {
        studentIds = activityStudentIds;
      }

      for (let i = 0; i < studentIds.length; i++) {
        let selectedStudent = allStudents.filter((s) => {
          return s.id === studentIds[i];
        });

        if (selectedStudent !== undefined && selectedStudent.length > 0) {
          let senderName = firebase.teacher.name;
          let activityN = commentType;

          let messageEndActivityName = "";

          if (activityName === "StudentLearning") {
            messageEndActivityName = "The Learning Assignment";
          } else if (activityName === "Weekly Lesson") {
            messageEndActivityName = "Student Lesson Plan Shared";
          } else {
            messageEndActivityName = activityName;
          }

          let message = "";
          if (activityN === "EVENT") {
            message = "Teacher Has Added A Comment On Event";
          } else if (activityN === "Weekly lesson") {
            message =
              senderName +
              " commented on lesson plan shared Lesson Name: " +
              lessonId;
          } else {
            message = senderName + " commented on " + messageEndActivityName;
          }

          let activityId = itemId;
          let bPath = undefined;
          let planShareDate = undefined;
          let planStartDate = undefined;
          let planEndDate = undefined;

          if (selectedStudent[0].fatherProfileId) {
            let alertNode = yield call(
              NotificationApi.createAlertReferenceNode,
              selectedStudent[0].fatherProfileId,
              firebase
            );
            yield fork(
              NotificationApi.createAlertNotification,
              activityN,
              activityId,
              selectedStudent[0].fatherUUid
                ? selectedStudent[0].fatherUUid
                : null,
              message,
              alertNode,
              selectedStudent[0].ios_fatherUUid
                ? selectedStudent[0].ios_fatherUUid
                : null,
              selectedStudent[0].id,
              selectedStudent[0].fatherProfileId,
              firebase,
              bPath,
              planShareDate,
              planStartDate,
              planEndDate
            );

            if (
              selectedStudent[0].fatherUUid !== undefined ||
              selectedStudent[0].ios_fatherUUid !== undefined
            ) {
              yield fork(
                NotificationApi.sendPushNotification,
                activityN,
                activityId,
                selectedStudent[0].fatherUUid
                  ? selectedStudent[0].fatherUUid
                  : null,
                message,
                alertNode,
                selectedStudent[0].ios_fatherUUid
                  ? selectedStudent[0].ios_fatherUUid
                  : null,
                selectedStudent[0].id,
                selectedStudent[0].fatherProfileId,
                firebase,
                bPath,
                planShareDate,
                planStartDate,
                planEndDate
              );
            }
          }

          if (selectedStudent[0].motherProfileId) {
            let alertNode = yield call(
              NotificationApi.createAlertReferenceNode,
              selectedStudent[0].motherProfileId,
              firebase
            );
            yield fork(
              NotificationApi.createAlertNotification,
              activityN,
              activityId,
              selectedStudent[0].motherUUid
                ? selectedStudent[0].motherUUid
                : null,
              message,
              alertNode,
              selectedStudent[0].ios_motherUUid
                ? selectedStudent[0].ios_motherUUid
                : null,
              selectedStudent[0].id,
              selectedStudent[0].motherProfileId,
              firebase,
              bPath,
              planShareDate,
              planStartDate,
              planEndDate
            );

            if (
              selectedStudent[0].motherUUid !== undefined ||
              selectedStudent[0].ios_motherUUid !== undefined
            ) {
              yield fork(
                NotificationApi.sendPushNotification,
                activityN,
                activityId,
                selectedStudent[0].motherUUid
                  ? selectedStudent[0].motherUUid
                  : null,
                message,
                alertNode,
                selectedStudent[0].ios_motherUUid
                  ? selectedStudent[0].ios_motherUUid
                  : null,
                selectedStudent[0].id,
                selectedStudent[0].motherProfileId,
                firebase,
                bPath,
                planShareDate,
                planStartDate,
                planEndDate
              );
            }
          }
        }
      }
    }
  } catch (err) {
    console.log("failed to add comment", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.COMMENT_REQ_FAILED,
    });
  }
}

function* addNewParentComment({
  commentObj,
  firebase,
  commentType,
  selectedActivityName,
  itemId,
  student,
  disableNotification,
  lessonId,
}) {
  try {
    let nodeId = yield call(
      CommentApi.createCommentNode,
      commentObj.sourceId,
      firebase
    );
    var newCommentObject = commentObj;
    newCommentObject.id = nodeId;
    let img = commentObj.uploadedImage ? commentObj.uploadedImage : undefined;
    if (img) {
      let storagePath = firebase.sbp + "/media/images/";
      let mediaPaths = yield call(
        ActivityApi.getMediaPath,
        storagePath,
        img,
        firebase
      );
      if (mediaPaths) {
        newCommentObject.uploadedImage = mediaPaths[0];
      }
    }
    yield call(
      CommentApi.updateComment,
      newCommentObject,
      commentObj.sourceId,
      nodeId,
      firebase
    );
    if (commentType) {
      let activityName = commentType;
      let activityType = commentType;
      let parentName = newCommentObject.author;

      //let classTeacher = item.classTeacher;
      let activityNodeId = itemId;

      let studentDetail = student;
      let parentId = firebase.user.id;
      let studentId = student.id;
      let relation = "";

      if (firebase && firebase.schoolConfig.genderNeutral) {
        relation = "Parent";
      } else {
        if (studentDetail && studentDetail.name && parentId) {
          relation =
            studentDetail.fatherProfileId &&
            studentDetail.fatherProfileId === parentId
              ? "Father"
              : "Mother";
        } else {
          relation = "Parent";
        }
      }

      let alertMessage =
        UserImageAction.getRelation(relation, firebase) +
        " of " +
        studentDetail.name +
        " commented on " +
        selectedActivityName;
      if (activityName === "StudentLearning") {
        alertMessage =
          UserImageAction.getRelation(relation, firebase) +
          " of " +
          studentDetail.name +
          " commented on the learning assignment";
      } else if (activityName === "Weekly lesson") {
        alertMessage =
          UserImageAction.getRelation(relation, firebase) +
          " of " +
          studentDetail.name +
          " commented on lesson plan shared Lesson Name: " +
          lessonId;
      }

      if (disableNotification !== "yes") {
        let teachers = yield call(
          lessonAssignmentApi.getTeachersByStudentClass,
          studentDetail.classroomName,
          firebase
        );
        if (teachers && teachers.length > 0) {
          for (let index in teachers) {
            let teacher = teachers[index];
            let userType = "teacher";
            let permissionStatus = yield call(
              NotificationApi.receiveNotificationPermission,
              activityName,
              activityType,
              teacher
            );
            if (permissionStatus) {
              let alertNode = yield call(
                NotificationApi.createAlertReferenceNode,
                teacher.id,
                firebase
              );

              yield fork(
                NotificationApi.createSimpleAlertNotification,
                activityName,
                activityNodeId,
                teacher.uid ? teacher.uid : null,
                alertMessage,
                alertNode,
                teacher.ios_uid ? teacher.ios_uid : null,
                studentId,
                teacher.id,
                activityType,
                undefined,
                firebase,
                parentName,
                userType
              );

              if (teacher.uid !== undefined || teacher.ios_uid !== undefined) {
                yield fork(
                  NotificationApi.sendSimplePushNotification,
                  activityName,
                  activityNodeId,
                  teacher.uid ? teacher.uid : null,
                  alertMessage,
                  alertNode,
                  teacher.ios_uid ? teacher.ios_uid : null,
                  studentId,
                  teacher.id,
                  activityType,
                  undefined,
                  firebase,
                  parentName,
                  userType
                );
              }
            }
          }
        }
      }
    }
  } catch (err) {
    console.log("failed to add parent comment", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.COMMENT_REQ_FAILED,
    });
  }
}

function* deleteSelectedComment({ item, activityId, firebase }) {
  try {
    yield call(CommentApi.deleteComment, item.id, activityId, firebase);
  } catch (err) {
    console.log("failed to delete comment", err);
    bugsnagClient.notify(err);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_COMMENTS, fetchComments),
    yield takeLatest(actions.ADD_COMMENT, addNewComment),
    yield takeLatest(actions.ADD_PARENT_COMMENT, addNewParentComment),
    yield takeLatest(actions.DELETE_COMMENT, deleteSelectedComment),
  ]);
}
