import actions from './actions';
const initState = {
    tags: [],
    isLoading: false,
    tagsChannel: undefined,
    isTagAddRequestSuccessful: false,
    isTagUpdateRequestSuccessful: false,
    tagOperationType: '',
    tagClasses: [],
    error: false,
    students: []
}
export default function tagReducer(state = initState, { type, ...action }) {
    switch (type) {
        case actions.LIST_TAGS:
            return {
                ...state,
                isLoading: true,
                tagOperationType: undefined
            };
        case actions.LIST_TAGS_SUCCESSFUL:
            return {
                ...state,
                tags: action.tags,
                isLoading: false,
                tagsChannel: action.tagsChannel,
                tagOperationType: ''
            };

        case actions.ADD_TAG:
            return {
                ...state,
                isLoading: true,
                isTagAddRequestSuccessful: false,

            };
        case actions.ADD_TAG_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                isTagAddRequestSuccessful: true,
                tagOperationType: action.tagOperationType
            };

        case actions.UPDATE_TAG:
            return {
                ...state,
                isLoading: true,
                isTagUpdateRequestSuccessful: false
            };
        case actions.UPDATE_TAG_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                isTagUpdateRequestSuccessful: true,
                tagOperationType: action.tagOperationType
            };

        case actions.ASSIGN_STUDENT_TO_TAG:
            return {
                ...state,
                isLoading: true,
                isStudentAssignRequestSuccessful: false
            };
        case actions.ASSIGN_STUDENT_TO_TAG_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                isStudentAssignRequestSuccessful: true,
                tagOperationType: action.tagOperationType
            };

        case actions.TAG_REQUEST_FAILED:
            return {
                ...state,
                isLoading: false,
                error: true,
            };

        case actions.GET_STUDENT_FOR_TAG:
            return {
                ...state,
                isLoading: true,
                tagOperationType: undefined
            };
        case actions.GET_STUDENT_FOR_TAG_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                students: action.students,
                tagOperationType: undefined
            };

        case actions.GET_TAG_CLASSES:
            return {
                ...state,
            };
        case actions.GET_TAG_CLASSES_SUCCESSFUL:
            return {
                ...state,
                tagClasses: action.tagClasses,
            };

        case actions.DELETE_TAG:
            return {
                ...state,
                isLoading: true,
            };
        case actions.DELETE_TAG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                tagOperationType: "DELETE_SUCCESS"
            };

        case actions.RESET_TAG_OPERATION:
            return {
                ...state,
                isLoading: false,
                tagOperationType: undefined,
                error: false
            };

        case actions.START_TAG_LOADER:
            return {
                ...state,
                isLoading: true,
            };

        default:
            return state;
    }
}