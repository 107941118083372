const actions = {
  ADD_FEE_COMPONENT_TO_FEE_PLAN: "ADD_FEE_COMPONENT_TO_FEE_PLAN",
  ADD_FEE_COMPONENT_TO_FEE_PLAN_SUCCESSFUL:
    "ADD_FEE_COMPONENT_TO_FEE_PLAN_SUCCESSFUL",
  UPDATE_EXISTING_FEE_COMPONENT: "UPDATE_EXISTING_FEE_COMPONENT",
  UPDATE_EXISTING_FEE_COMPONENT_SUCCESSFFUL:
    "UPDATE_EXISTING_FEE_COMPONENT_SUCCESSFFUL",
  LIST_SELECTED_FEE_PLAN_FEE_COMPONENTS:
    "LIST_SELECTED_FEE_PLAN_FEE_COMPONENTS",
  LIST_SELECTED_FEE_PLAN_FEE_COMPONENTS_SUCCESSFFUL:
    "LIST_SELECTED_FEE_PLAN_FEE_COMPONENTS_SUCCESSFFUL",
  DETAILED_FEE_FETCH_STUDENT: "DETAILED_FEE_FETCH_STUDENT",
  DETAILED_FEE_FETCH_STUDENT_SUCCESSFFUL:
    "DETAILED_FEE_FETCH_STUDENT_SUCCESSFFUL",
  ASSIGN_STUDENT_TO_DETAILED_FEE_PLAN: "ASSIGN_STUDENT_TO_DETAILED_FEE_PLAN",
  ASSIGN_STUDENT_TO_DETAILED_FEE_PLAN_SUCCESSFUL:
    "ASSIGN_STUDENT_TO_DETAILED_FEE_PLAN_SUCCESSFUL",
  DELETE_STUDENT_FROM_DETAILED_FEE_PLAN:
    "DELETE_STUDENT_FROM_DETAILED_FEE_PLAN",
  DELETE_STUDENT_FROM_DETAILED_FEE_PLAN_SUCCESSFUL:
    "DELETE_STUDENT_FROM_DETAILED_FEE_PLAN_SUCCESSFUL",
  DETAILED_FEE_PLAN_REQUEST_FAILED: 'DETAILED_FEE_PLAN_REQUEST_FAILED',
  GET_ALL_FEE_COMPONENT_TO_ASSIGN: 'GET_ALL_FEE_COMPONENT_TO_ASSIGN',
  GET_ALL_FEE_COMPONENT_TO_ASSIGN_SUCCESSFUL: "GET_ALL_FEE_COMPONENT_TO_ASSIGN_SUCCESSFUL",
  SAVE_ATTACHED_FEE_COMPONENT: "SAVE_ATTACHED_FEE_COMPONENT",
  SAVE_ATTACHED_FEE_COMPONENT_SUCCESSFUL: "SAVE_ATTACHED_FEE_COMPONENT_SUCCESSFUL",

  GET_FEE_PLAN_INVOICE: "GET_FEE_PLAN_INVOICE",
  GET_FEE_PLAN_INVOICE_SUCCESSFUL: "GET_FEE_PLAN_INVOICE_SUCCESSFUL",

  GET_PLAN_INVOICE_DOWNLOAD_URL: "GET_PLAN_INVOICE_DOWNLOAD_URL",
  GET_PLAN_INVOICE_DOWNLOAD_URL_SUCCESSFUL: "GET_PLAN_INVOICE_DOWNLOAD_URL_SUCCESSFUL",

  RESET_PLAN_OPERATION: "RESET_PLAN_OPERATION",

  UPDATE_STUDENT_PLAN_STATUS: "UPDATE_STUDENT_PLAN_STATUS",
  UPDATE_STUDENT_PLAN_STATUS_SUCCESS: "UPDATE_STUDENT_PLAN_STATUS_SUCCESS",

  DELETE_COMPONENT_FROM_FEE_PLAN: "DELETE_COMPONENT_FROM_FEE_PLAN",
  DELETE_COMPONENT_FROM_FEE_PLAN_SUCCESS: "DELETE_COMPONENT_FROM_FEE_PLAN_SUCCESS",

  GET_PROGRAM_BY_ID: "GET_PROGRAM_BY_ID",
  GET_PROGRAM_BY_ID_SUCCESS: "GET_PROGRAM_BY_ID_SUCCESS",

  addFeeComponentToFeePlan: (value, selectedFeePlan, firebase) => ({
    type: actions.ADD_FEE_COMPONENT_TO_FEE_PLAN,
    value,
    selectedFeePlan,
    firebase
  }),

  updateExistingFeeComponent: (value, editableFeeComponent, firebase) => ({
    type: actions.UPDATE_EXISTING_FEE_COMPONENT,
    value,
    editableFeeComponent,
    firebase
  }),

  getAllFeeComponentOfSelectedPlan: (selectedFeePlanId, firebase) => ({
    type: actions.LIST_SELECTED_FEE_PLAN_FEE_COMPONENTS,
    selectedFeePlanId,
    firebase
  }),

  getStudentsToAssignFeePlan: firebase => ({
    type: actions.DETAILED_FEE_FETCH_STUDENT,
    firebase
  }),

  assignStudent: (values, selectedStudentCheckbox, selectedPlan, rows, total, showGeneratedInvoice, firebase) => ({
    type: actions.ASSIGN_STUDENT_TO_DETAILED_FEE_PLAN,
    values,
    selectedStudentCheckbox,
    selectedPlan,
    rows,
    total,
    showGeneratedInvoice,
    firebase
  }),

  deletedStudentFromDetailedFeePlan: (
    studentRecord,
    selectedFeePlan,
    firebase
  ) => ({
    type: actions.DELETE_STUDENT_FROM_DETAILED_FEE_PLAN,
    studentRecord,
    selectedFeePlan,
    firebase
  }),

  getAllFeeComponent: (firebase) => ({
    type: actions.GET_ALL_FEE_COMPONENT_TO_ASSIGN,
    firebase
  }),

  saveFeeComponent: (selectedComponent, feePlan, firebase) => ({
    type: actions.SAVE_ATTACHED_FEE_COMPONENT,
    selectedComponent,
    feePlan,
    firebase
  }),

  getFeePlanInvoices: (feePlanId, firebase) => ({
    type: actions.GET_FEE_PLAN_INVOICE,
    feePlanId,
    firebase
  }),

  getPlanInvoiceDownloadUrl: (record, firebase) => ({
    type: actions.GET_PLAN_INVOICE_DOWNLOAD_URL,
    record,
    firebase
  }),

  resetPlanOperation: () => ({
    type: actions.RESET_PLAN_OPERATION
  }),

  updateStudentPlanStatus: (record, feePlan, firebase) => ({
    type: actions.UPDATE_STUDENT_PLAN_STATUS,
    record,
    feePlan,
    firebase
  }),

  deleteFeeComponent: (record, feePlan, firebase) => ({
    type: actions.DELETE_COMPONENT_FROM_FEE_PLAN,
    record,
    feePlan,
    firebase
  }),

  getProgramById: (programId, firebase) => ({
    type: actions.GET_PROGRAM_BY_ID,
    programId,
    firebase
  })
};
export default actions;
