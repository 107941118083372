import { all, put, call, take, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { LearningApi } from "../../firestore-api/learning";
import { NotificationApi } from "../../firestore-api/notification";
import { AssessmentApi } from "../../firestore-api/studentAssessment";
import { ActivityApi } from "../../firestore-api/activity";
import bugsnagClient from "@bugsnag/js";
import milestoneValue from "./jsonFile";
import moment from "moment-timezone";
import { lessonAssignmentApi } from "../../firestore-api/lessonAssignment";

function* fetchMilestones({ firebase, milestones }) {
  try {
    firebase.getMilestones();
    let data = new Map(JSON.parse(localStorage.getItem("milestones")));
    if (data) {
      yield put({
        type: actions.LIST_MILESTONES_SUCCESSFUL,
        milestones: data,
        learningMilestoneChannel: undefined,
      });
    }
  } catch (err) {
    console.log("failed to fetch milestones", err);
    bugsnagClient.notify(err);
  }
}

function* fetchDevelopmentArea({ firebase }) {
  try {
    firebase.getDevelopmentAreas();
    let developmentAreasMap = new Map();
    let data = JSON.parse(localStorage.getItem("developmentAreas"));

    if (data) {
      for (let index in data) {
        developmentAreasMap.set(data[index].id, data[index]);
      }

      yield put({
        type: actions.LIST_DEV_AREA_SUCCESS,
        developmentAreas: data,
        developmentAreasMap: developmentAreasMap,
      });
    }
  } catch (err) {
    console.log("failed to fetch development", err);
    bugsnagClient.notify(err);
  }
}

function* fetchLessons({ firebase, lessons, operationType }) {
  if (lessons && lessons.length > 0) {
    yield put({
      type: actions.LIST_LESSONS_SUCCESSFUL,
      lessons: lessons,
      learningLessonChannel: operationType,
    });
  }

  const chan = yield call(LearningApi.getLessons, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      // console.log("lessons data", data);

      yield put({
        type: actions.LIST_LESSONS_SUCCESSFUL,
        lessons: data,
        learningLessonChannel: chan,
        learningOperationType: operationType,
      });
    }
  } finally {
    console.log("end tag channel");
  }
}

function* saveNewMilestoneDomain({ values, firebase }) {
  try {
    var nodeId = yield call(LearningApi.createMilestoneDomainNodeId, firebase);
    yield call(LearningApi.saveMilestoneDomain, values, nodeId, firebase);
    yield put({
      type: actions.SAVE_MILESTONE_DOMAIN_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to add milestone domain", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

/**migrate milestones */
// function* uploadExternalMilestones(firebase) {
//     let data = milestoneValue.values;

//     for (let i in data) {
//         let daId = yield call(LearningApi.createMilestoneDomainNodeId, firebase);
//         let domainName = data[i].name;
//         let shortName = domainName.substring(0, 3).trim();
//         let subDevelopmentArea = [];

//         let subDomainArr = data[i].values;
//         for (let index in subDomainArr) {
//             let sdaId = yield call(LearningApi.createMilestoneDomainNodeId, firebase);
//             subDevelopmentArea.push(sdaId);

//             let subDomainName = subDomainArr[index].name;

//             let subDomainShortName = subDomainName.substring(0, 3);
//             let subDomainMilestones = subDomainArr[index].values;

//             let milestoneIds = [];
//             for (let p in subDomainMilestones) {
//                 let mId = yield call(LearningApi.createMilestoneId, firebase);
//                 let singleMilestone = subDomainMilestones[p].name;

//                 let values = { description: singleMilestone };
//                 yield call(LearningApi.saveMilestoneAlt, values, firebase, mId);
//                 milestoneIds.push(mId);
//             }

//             let subDomainObj = {
//                 id: sdaId,
//                 name: subDomainName,
//                 shortName: subDomainShortName,
//                 milestones: milestoneIds,
//                 parentId: daId
//             }
//             yield call(LearningApi.updateDAreas, subDomainObj, firebase);

//         }

//         let daObj = {
//             id: daId,
//             name: domainName,
//             shortName: shortName,
//             subDevelopmentArea: subDevelopmentArea
//         }
//         yield call(LearningApi.updateDAreas, daObj, firebase);
//     }
// }

function* saveNewMilestoneSubDomain({ values, record, firebase }) {
  try {
    let parentId = record.id;
    var nodeId = yield call(LearningApi.createMilestoneDomainNodeId, firebase);
    yield call(
      LearningApi.saveMilestoneDomain,
      values,
      nodeId,
      firebase,
      parentId
    );

    let subDomainIds = [];
    if (record.subDevelopmentArea) {
      subDomainIds = record.subDevelopmentArea;
    }

    subDomainIds.push(nodeId);
    record.subDevelopmentArea = subDomainIds;
    yield call(
      LearningApi.updateMilestoneDomainWithSubDomain,
      record,
      firebase
    );
    yield put({
      type: actions.ADD_SUB_DOMAIN_SUCCESS,
    });
  } catch (err) {
    console.log("failed to add milestone sub domain", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* updateNewMilestoneSubDomain({ values, record, firebase }) {}

function* saveNewMilestone({ values, selectedMilestoneData, firebase }) {
  try {
    let milestonesId = yield call(LearningApi.createMilestoneId, firebase);
    yield call(LearningApi.saveMilestone, values, firebase, milestonesId);
    yield call(
      LearningApi.updatedMilestoneInDA,
      milestonesId,
      selectedMilestoneData,
      firebase
    );
    yield put({
      type: actions.SAVE_MILESTONE_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to add milestone", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* addNewLabel({ value, firebase }) {
  try {
    var nodeId = yield call(LearningApi.createLabelNodeId, firebase);
    yield call(LearningApi.addLabel, value, nodeId, firebase);
    yield put({
      type: actions.ADD_LABEL_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to add label", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* fetchLabel({ firebase }) {
  const chan = yield call(LearningApi.getLabel, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_LABEL_SUCCESSFUL,
        labels: data,
        labelChannel: chan,
      });
    }
  } finally {
    console.log("end label channel");
  }
}

function* updateExistingMilestoneDomain({ values, record, firebase }) {
  try {
    let subDomainIds = [];
    if (record.subDevelopmentArea) {
      subDomainIds = record.subDevelopmentArea;
    }

    yield call(
      LearningApi.updateMilestoneDomain,
      values,
      record,
      firebase,
      subDomainIds
    );
    yield put({
      type: actions.UPDATE_MILESTONE_DOMAIN_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to update milestone domain", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* updateExistingMilestone({
  values,
  editableMilestone,
  index,
  firebase,
}) {
  try {
    yield call(
      LearningApi.updateMilestone,
      values,
      editableMilestone,
      index,
      firebase
    );
    yield put({
      type: actions.UPDATE_MILESTONE_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to update milestone ", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* addNewCategory({ value, firebase }) {
  try {
    var nodeId = yield call(LearningApi.createCategoryNodeId, firebase);
    yield call(LearningApi.addCategory, value, nodeId, firebase);
    yield put({
      type: actions.ADD_CATEGORY_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to add category", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* updateExistingCategory({ name, record, firebase }) {
  try {
    var nodeId = record.id;
    yield call(LearningApi.addCategory, name, nodeId, firebase);
    yield put({
      type: actions.UPDATE_CATEGORY_SUCCESS,
    });
  } catch (err) {
    console.log("failed to update category", err);
    bugsnagClient.notify(
      "failed to update category " + err.message ? err.message : err
    );
  }
}

function* fetchCategory({ firebase }) {
  try {
    var category = yield call(LearningApi.getCategory, firebase);
    if (category) {
      yield put({
        type: actions.GET_CATEGORY_SUCCESSFUL,
        categories: category,
      });
    }
  } catch (err) {
    console.log("failed to get category", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* deleteCategory({ record, firebase }) {
  try {
    yield call(LearningApi.deleteCategory, record, firebase);
    yield put({
      type: actions.DELETE_CATEGORY_SUCCESS,
    });
  } catch (err) {
    console.log("failed to delete category", err);
    bugsnagClient.notify(
      "failed to delete category" + err.message ? err.message : err
    );
  }
}

function* fetchLearningClassroom({ firebase }) {
  try {
    //var classrooms = yield call(LearningApi.getLearningClassroom, firebase);
    let classrooms = JSON.parse(localStorage.getItem("classList"));
    if (classrooms) {
      yield put({
        type: actions.GET_LEARNING_CLASSROOM_SUCCESSFUL,
        classrooms: classrooms,
      });
    }
  } catch (err) {
    console.log("failed to get classrooms", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function getYoutubeCode(url) {
  var ID = "";
  url = url
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_\-]/i);
    ID = ID[0];
  } else {
    ID = "not valid";
  }
  return ID;
}

function* addNewLesson({ value, selectedMilestones, classroom, firebase }) {
  try {
    var nodeId = yield call(LearningApi.createLessonsNodeId, firebase);
    yield call(
      LearningApi.addLesson,
      value,
      selectedMilestones,
      classroom,
      nodeId,
      firebase
    );
    yield put({
      type: actions.ADD_LESSON_SUCCESSFUL,
    });
    if (
      value.attachment &&
      value.attachment.fileList &&
      value.attachment.fileList.length > 0
    ) {
      // let urls = yield call(LearningApi.getDownloadUrlForAttachment, value.attachment, firebase, undefined, value.mediaType);
      let storagePath = firebase.sbp + "/media/mediaProfiles/";
      let urls = yield call(
        ActivityApi.getAttachmentMediaPath,
        storagePath,
        value.attachment,
        firebase,
        nodeId,
        undefined,
        "lesson"
      );

      if (urls && urls.length > 0) {
        yield fork(
          ActivityApi.generateThumbnail,
          urls,
          nodeId,
          "lesson",
          undefined,
          firebase
        );

        yield call(
          LearningApi.updateLessonMedia,
          nodeId,
          urls,
          value.mediaType,
          firebase,
          undefined
        );
      } else {
        yield put({
          type: actions.LEARNING_REQUEST_FAILED,
          errorMessage: "Failed to upload attachment",
        });
        return;
      }
    }
    if (value.youtubeLink) {
      let youtubeUrlCode = getYoutubeCode(value.youtubeLink);
      if (youtubeUrlCode === "not valid") {
        yield put({
          type: actions.LEARNING_REQUEST_FAILED,
          errorMessage: "Invaid youtube url",
        });
        return;
      } else {
        yield call(
          LearningApi.updateYoutubeToLesson,
          nodeId,
          youtubeUrlCode,
          firebase,
          undefined
        );
      }
    }
  } catch (err) {
    console.log("failed to add lesson", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* addNewLessonToAllCenter({
  value,
  selectedMilestones,
  branchList,
  firebase,
}) {
  try {
    let centerClasses = JSON.parse(localStorage.getItem("classmap"));
    let tempBranchMap = new Map();
    let branches = branchList;
    for (let i in branches) {
      let branchClassName = branches[i];
      if (centerClasses[branchClassName]) {
        let allClasses = centerClasses[branchClassName];
        tempBranchMap.set(branchClassName, allClasses);
      } else {
        let singleClass = branchClassName.split("*")[0];
        let branchName = branchClassName.split("*")[1];
        if (tempBranchMap.has(branchName)) {
          let allClasses = tempBranchMap.get(branchName);
          allClasses.push({ className: singleClass });
          tempBranchMap.set(branchName, allClasses);
        } else {
          let allClasses = [];
          allClasses.push({ className: singleClass });
          tempBranchMap.set(branchName, allClasses);
        }
      }
    }

    var nodeId = yield call(LearningApi.createLessonsNodeId, firebase);
    // for (let i in branchList) {
    for (let [k, v] of tempBranchMap) {
      //let bPath = branchList[i].name;
      let bPath = k;
      let classList = v;
      let classNames = [];
      if (classList.length > 0) {
        classList.map((c) => {
          classNames.push({ id: c.id ? c.id : null, name: c.className });
        });
      }
      yield call(
        LearningApi.addLesson,
        value,
        selectedMilestones,
        classNames,
        nodeId,
        firebase,
        bPath
      );

      if (
        value.attachment &&
        value.attachment.fileList &&
        value.attachment.fileList.length > 0 &&
        (!value.mediaType || value.mediaType.toLowerCase() !== "youtube link")
      ) {
        let storagePath = firebase.sbp + "/media/mediaProfiles/";
        let urls = yield call(
          ActivityApi.getAttachmentMediaPath,
          storagePath,
          value.attachment,
          firebase,
          nodeId,
          bPath,
          "lesson"
        );

        // let urls = yield call(LearningApi.getDownloadUrlForAttachment, value.attachment, firebase, bPath, value.mediaType);
        if (urls && urls.length > 0) {
          yield fork(
            ActivityApi.generateThumbnail,
            urls,
            nodeId,
            "lesson",
            undefined,
            firebase
          );

          yield call(
            LearningApi.updateLessonMedia,
            nodeId,
            urls,
            value.mediaType,
            firebase,
            bPath
          );
        } else {
          yield put({
            type: actions.LEARNING_REQUEST_FAILED,
            errorMessage: "Failed to upload attachment",
          });
          return;
        }
      }
      if (value.youtubeLink) {
        let youtubeUrlCode = getYoutubeCode(value.youtubeLink);
        if (youtubeUrlCode === "not valid") {
          yield put({
            type: actions.LEARNING_REQUEST_FAILED,
            errorMessage: "Invaid youtube url",
          });
          return;
        } else {
          yield call(
            LearningApi.updateYoutubeToLesson,
            nodeId,
            youtubeUrlCode,
            firebase,
            bPath
          );
        }
      }
    }
    yield put({
      type: actions.ADD_LESSON_TO_ALL_CENTERS_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to add lesson to all centers", err);
    bugsnagClient.notify("lesson failed" + " ------>>>> " + err.message);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* updateExistingLesson({
  values,
  editableLessonRecord,
  selectedMilestones,
  classroom,
  attachedFilesUrl,
  firebase,
  updateStoreLast,
}) {
  try {
    yield call(
      LearningApi.updateLesson,
      values,
      editableLessonRecord,
      selectedMilestones,
      classroom,
      attachedFilesUrl,
      firebase
    );

    if (!updateStoreLast) {
      yield put({
        type: actions.UPDATE_LESSON_SUCCESSFUL,
      });
    }

    if (
      (values.attachment || attachedFilesUrl.length > 0) &&
      (!values.mediaType || values.mediaType.toLowerCase() !== "youtube link")
    ) {
      // let urls = yield call(LearningApi.updateLessonAttachment, values.attachment, editableLessonRecord, attachedFilesUrl, firebase, undefined, values.mediaType);
      let storagePath = firebase.sbp + "/media/mediaProfiles/";
      let prevUrls = [];
      if (attachedFilesUrl) {
        prevUrls = attachedFilesUrl;
      }

      let urls = [];
      if (values.attachment) {
        urls = yield call(
          ActivityApi.getAttachmentMediaPath,
          storagePath,
          values.attachment,
          firebase,
          editableLessonRecord.id,
          undefined,
          "lesson"
        );
      }

      if (urls) {
        urls = [...urls, ...prevUrls];
        yield call(
          LearningApi.updateLessonMedia,
          editableLessonRecord.id,
          urls,
          values.mediaType,
          firebase,
          undefined
        );

        yield fork(
          ActivityApi.generateThumbnail,
          urls,
          editableLessonRecord.id,
          "lesson",
          undefined,
          firebase
        );
      } else {
        yield put({
          type: actions.LEARNING_REQUEST_FAILED,
          errorMessage: "Failed to upload attachment",
        });
        return;
      }
    }

    if (values.youtubeLink) {
      let youtubeUrlCode = getYoutubeCode(values.youtubeLink);
      if (youtubeUrlCode === "not valid") {
        yield put({
          type: actions.LEARNING_REQUEST_FAILED,
          errorMessage: "Invaid youtube url",
        });
        return;
      } else {
        yield call(
          LearningApi.updateYoutubeToLesson,
          editableLessonRecord.id,
          youtubeUrlCode,
          firebase,
          undefined
        );
      }
    }

    if (updateStoreLast) {
      yield put({
        type: actions.UPDATE_LESSON_SUCCESSFUL,
      });
    }
  } catch (err) {
    console.log("failed to update lesson ", err);
    bugsnagClient.notify("lesson failed" + " -------->>>>> " + err.message);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* updateExistingLessonToAllCenter({
  values,
  editableLessonRecord,
  selectedMilestones,
  branchList,
  attachedFilesUrl,
  firebase,
}) {
  try {
    let centerClasses = JSON.parse(localStorage.getItem("classmap"));
    let tempBranchMap = new Map();
    let branches = branchList;
    for (let i in branches) {
      let branchClassName = branches[i];
      if (centerClasses[branchClassName]) {
        let allClasses = centerClasses[branchClassName];
        tempBranchMap.set(branchClassName, allClasses);
      } else {
        let singleClass = branchClassName.split("*")[0];
        let branchName = branchClassName.split("*")[1];
        if (tempBranchMap.has(branchName)) {
          let allClasses = tempBranchMap.get(branchName);
          allClasses.push({ className: singleClass });
          tempBranchMap.set(branchName, allClasses);
        } else {
          let allClasses = [];
          allClasses.push({ className: singleClass });
          tempBranchMap.set(branchName, allClasses);
        }
      }
    }

    // for (let i in branchList) {
    for (let [k, value] of tempBranchMap) {
      //let bPath = branchList[i].name;
      let bPath = k;

      let classList = value;
      let classNames = [];
      if (classList.length > 0) {
        classList.map((c) => {
          classNames.push({ id: c.id ? c.id : null, name: c.className });
        });
      }

      yield call(
        LearningApi.updateLesson,
        values,
        editableLessonRecord,
        selectedMilestones,
        classNames,
        attachedFilesUrl,
        firebase,
        bPath
      );

      yield put({
        type: actions.UPDATE_LESSON_TO_ALL_CENTERS_SUCCESSFUL,
      });

      if (
        values.attachment &&
        (!values.mediaType || values.mediaType.toLowerCase() !== "youtube link")
      ) {
        // let urls = yield call(LearningApi.updateLessonAttachment, values.attachment, editableLessonRecord, attachedFilesUrl, firebase, bPath, values.mediaType);
        let storagePath = firebase.sbp + "/media/mediaProfiles/";
        let prevUrls = [];
        if (attachedFilesUrl) {
          prevUrls = attachedFilesUrl;
        }

        let urls = [];

        if (value.attachment) {
          urls = yield call(
            ActivityApi.getAttachmentMediaPath,
            storagePath,
            values.attachment,
            firebase,
            editableLessonRecord.id,
            undefined,
            "lesson"
          );
        }

        if (urls && urls.length > 0) {
          urls = [...urls, ...prevUrls];
          yield call(
            LearningApi.updateLessonMedia,
            editableLessonRecord.id,
            urls,
            values.mediaType,
            firebase,
            bPath
          );

          yield fork(
            ActivityApi.generateThumbnail,
            urls,
            editableLessonRecord.id,
            "lesson",
            undefined,
            firebase
          );
        } else {
          yield put({
            type: actions.LEARNING_REQUEST_FAILED,
            errorMessage: "Failed to upload attachment",
          });
          return;
        }
      }
      if (values.youtubeLink) {
        let youtubeUrlCode = getYoutubeCode(values.youtubeLink);
        if (youtubeUrlCode === "not valid") {
          yield put({
            type: actions.LEARNING_REQUEST_FAILED,
            errorMessage: "Invaid youtube url",
          });
          return;
        } else {
          yield call(
            LearningApi.updateYoutubeToLesson,
            editableLessonRecord.id,
            youtubeUrlCode,
            firebase,
            bPath
          );
        }
      }
    }
  } catch (err) {
    console.log("failed to update lesson to all centers ", err);
    bugsnagClient.notify("lesson failed" + " ------>>>> " + err.message);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* addNewWeeklyPlan({
  selectedLesson,
  selectedDate,
  weeklyPlanData,
  selectedDropdownValue,
  firebase,
}) {
  try {
    yield call(
      LearningApi.addWeeklyPlan,
      selectedLesson,
      selectedDate,
      weeklyPlanData,
      selectedDropdownValue,
      firebase
    );
    if (firebase.schoolConfig.studentLessonShare) {
      yield put({
        type: actions.ADD_WEEKLY_PLAN_SUCCESSFUL,
      });
    } else {
      if (!firebase.schoolConfig.disableAutomaticLessonShare) {
        let obj = {
          date: selectedDate.toDateString(),
          lessons: [selectedLesson],
        };
        yield fork(
          manageStudentAssignment,
          obj,
          selectedDropdownValue,
          firebase,
          "add"
        );
      }

      yield put({
        type: actions.ADD_WEEKLY_PLAN_SUCCESSFUL,
      });
    }
  } catch (err) {
    console.log("failed to add weekly plan", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* fetchWeeklyPlan({ from, to, selectedRoom, firebase }) {
  const chan = yield call(
    LearningApi.getWeeklyLesson,
    from,
    to,
    selectedRoom,
    firebase
  );
  try {
    while (true) {
      let weeklyPlan = yield take(chan);
      yield put({
        type: actions.GET_WEEKLY_PLAN_SUCCESSFUL,
        weeklyPlan: weeklyPlan,
        weeklyPlanLessonChannel: chan,
      });
    }
  } finally {
    console.log("end weekly plan channel");
  }
}

function* deleteSelectedPlan({
  selectedItem,
  item,
  weeklyPlan,
  classDropdown,
  firebase,
  studentList,
}) {
  try {
    let removableId = selectedItem[0].id;
    let searchableDate = item.date.toDateString().trim();
    let lessons = [];
    let selectedWeeklyPlan;
    for (let i = 0; i < weeklyPlan.length; i++) {
      if (searchableDate === weeklyPlan[i].date.trim()) {
        selectedWeeklyPlan = weeklyPlan[i];
        let x = weeklyPlan[i].lessons;
        for (let j = 0; j < x.length; j++) {
          if (removableId !== x[j]) {
            lessons.push(x[j]);
          }
        }
      }
    }

    let plan = JSON.parse(JSON.stringify(selectedWeeklyPlan));
    plan.lessons = [];
    plan.lessons.push(selectedItem);

    yield call(
      LearningApi.deleteWeeklyPlan,
      searchableDate,
      classDropdown,
      lessons,
      firebase
    );
    yield fork(
      manageStudentAssignment,
      plan,
      classDropdown,
      firebase,
      "delete",
      studentList
    );
    yield put({
      type: actions.DELETE_PLAN_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to delete weekly plan", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* deleteSelectedLessonRecord({ value, firebase }) {
  try {
    yield call(LearningApi.deleteLessonRecord, value, firebase);
    yield put({
      type: actions.DELETE_LESSON_RECORD_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to delete lesson record", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* deleteSelectedLessonRecordForAllCenter({
  value,
  firebase,
  branchList,
}) {
  try {
    for (let i in branchList) {
      let bPath = branchList[i].name;
      yield call(LearningApi.deleteLessonRecord, value, firebase, bPath);
    }
    yield put({
      type: actions.DELETE_LESSON_RECORD_FOR_ALL_CENTER_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to delete lesson record for all center", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* deleteSelectedMilestone({ element, index, item, firebase }) {
  try {
    yield call(
      LearningApi.deleteSingleMilestone,
      element,
      index,
      item,
      firebase
    );
    yield put({
      type: actions.DELETE_SINGLE_MILESTONE_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to delete single milestone", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* deleteSelectedDomain({ item, firebase }) {
  try {
    yield call(LearningApi.deleteSingleDomain, item, firebase);
    yield put({
      type: actions.DELETE_SINGLE_DOMAIN_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to delete single domain", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* fetchStudentForLesson({ firebase }) {
  try {
    //var data = yield call(AssessmentApi.getAllStudents, firebase);
    let data = JSON.parse(localStorage.getItem("studentList"));
    if (data) {
      data = data.filter((std) => {
        return !std.status || std.status.toLowerCase() === "active";
      });
      yield put({
        type: actions.GET_STUDENT_FOR_LESSON_SUCCESSFUL,
        students: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch students", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* shareSelectedLesson({
  students,
  record,
  values,
  selectedClass,
  firebase,
  shareDate,
}) {
  // console.log("shareSelectedLesson ------", students, record, values, firebase);
  try {
    let schoolAssignmentId = yield call(
      LearningApi.generateSchoolAssignmentNode,
      firebase
    );

    let studentIds = [];
    let selectedStudent = students;
    for (let index in selectedStudent) {
      let id = selectedStudent[index].id;
      studentIds.push(id);
    }

    let classNames = [];
    if (selectedClass === "All Classrooms") {
      classNames = firebase.teacher.classList ? firebase.teacher.classList : [];
    } else {
      classNames.push(selectedClass);
    }

    let schoolAssignmentObj = {
      allowDownload: values.allowDownload,
      classNames: classNames,
      classroom: selectedClass,
      delete: false,
      dueDate: values.dueDate ? moment(values.dueDate).valueOf() : 0,
      extraNote: values.notes && values.notes !== "" ? values.notes : null,
      id: schoolAssignmentId,
      inverseDate: -new Date().getTime(),
      lessonId: record.id,
      shareOn: shareDate
        ? moment(shareDate)
            .startOf("day")
            .add(20, "second")
            .valueOf()
        : moment()
            .startOf("day")
            .add(20, "second")
            .valueOf(),
      sharedBy: firebase.teacher.name,
      studentIds: studentIds,
      submissionRequired: values.type === "Task" ? "Yes" : "No",
      type: values.type,
    };

    yield call(
      LearningApi.updateSchoolAssignment,
      schoolAssignmentObj,
      schoolAssignmentId,
      firebase
    );

    for (let i = 0; i < selectedStudent.length; i++) {
      let studentAssignmentId = yield call(
        LearningApi.generateStudentAssignmentNode,
        firebase
      );
      let studentAssignmentObj = {
        assignmentId: schoolAssignmentId,
        done: false,
        id: studentAssignmentId,
        inversedSharedDate: shareDate
          ? -moment(shareDate)
              .startOf("day")
              .add(20, "second")
              .valueOf()
          : -moment()
              .startOf("day")
              .add(20, "second")
              .valueOf(),
        lessonId: record.id,
        seen: false,
        sharedOn: shareDate
          ? moment(shareDate)
              .startOf("day")
              .add(20, "second")
              .valueOf()
          : moment()
              .startOf("day")
              .add(20, "second")
              .valueOf(),
        teacherId: firebase.teacher.id,
        type: values.type,
      };

      yield call(
        LearningApi.updateStudentAssignment,
        studentAssignmentObj,
        studentAssignmentId,
        selectedStudent[i].id,
        firebase
      );

      if (selectedStudent[i].fatherProfileId) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          selectedStudent[i].fatherProfileId,
          firebase
        );
        yield fork(
          NotificationApi.createAlertNotification,
          "StudentLearning",
          schoolAssignmentId,
          selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
          "New lesson plan added",
          alertNode,
          selectedStudent[i].ios_fatherUUid
            ? selectedStudent[i].ios_fatherUUid
            : null,
          selectedStudent[i].id,
          selectedStudent[i].fatherProfileId,
          firebase
        );

        if (
          selectedStudent[i].fatherUUid !== undefined ||
          selectedStudent[i].ios_fatherUUid !== undefined
        ) {
          yield fork(
            NotificationApi.sendPushNotification,
            "StudentLearning",
            schoolAssignmentId,
            selectedStudent[i].fatherUUid
              ? selectedStudent[i].fatherUUid
              : null,
            "New lesson plan added",
            alertNode,
            selectedStudent[i].ios_fatherUUid
              ? selectedStudent[i].ios_fatherUUid
              : null,
            selectedStudent[i].id,
            selectedStudent[i].fatherProfileId,
            firebase
          );
        }
      }

      if (selectedStudent[i].motherProfileId) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          selectedStudent[i].motherProfileId,
          firebase
        );
        yield fork(
          NotificationApi.createAlertNotification,
          "StudentLearning",
          schoolAssignmentId,
          selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
          "New lesson plan added",
          alertNode,
          selectedStudent[i].ios_motherUUid
            ? selectedStudent[i].ios_motherUUid
            : null,
          selectedStudent[i].id,
          selectedStudent[i].motherProfileId,
          firebase
        );

        if (
          selectedStudent[i].motherUUid !== undefined ||
          selectedStudent[i].ios_motherUUid !== undefined
        ) {
          yield fork(
            NotificationApi.sendPushNotification,
            "StudentLearning",
            schoolAssignmentId,
            selectedStudent[i].motherUUid
              ? selectedStudent[i].motherUUid
              : null,
            "New lesson plan added",
            alertNode,
            selectedStudent[i].ios_motherUUid
              ? selectedStudent[i].ios_motherUUid
              : null,
            selectedStudent[i].id,
            selectedStudent[i].motherProfileId,
            firebase
          );
        }
      }
    }
    yield fork(
      NotificationApi.sendStats,
      shareDate ? shareDate : new Date(),
      "updateDailyPostStats",
      firebase
    );
    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "lessons",
      shareDate ? shareDate : new Date()
    );
    yield put({
      type: actions.SHARE_LESSON_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to share lesson", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* uploadLessonCsv({ data, category, firebase }) {
  try {
    let initialCounter = data.length;
    let actualCounter = 0;
    for (let i = 0; i < data.length; i++) {
      let inputCat = data[i].category.trim();
      let filteredCategory = category.filter((ele) => {
        return ele.name.trim() === inputCat;
      });

      let lessonCategory = [];
      if (filteredCategory && filteredCategory.length > 0) {
        lessonCategory = [filteredCategory[0].name, filteredCategory[0].id];
      } else {
        var categoryId = yield call(LearningApi.createCategoryNodeId, firebase);
        yield call(LearningApi.addCategory, inputCat, categoryId, firebase);
        lessonCategory = [inputCat, categoryId];
      }

      let milestones = [];
      if (data[i].milestones) {
        let mArray = data[i].milestones.split(",");
        for (let index in mArray) {
          milestones.push({ name: mArray[index] });
        }
      }

      let classrooms = [];
      if (data[i].classroom) {
        let cArray = data[i].classroom.split(",");
        for (let index in cArray) {
          classrooms.push({ name: cArray[index] });
        }
      }

      actualCounter++;
      let lessonVal = {
        lessonTitle: data[i].title,
        description: data[i].description,
        lessonCategory: lessonCategory,
        duration: data[i].duration,
        label: [],
      };
      var nodeId = yield call(LearningApi.createLessonsNodeId, firebase);
      yield call(
        LearningApi.addLesson,
        lessonVal,
        milestones,
        classrooms,
        nodeId,
        firebase
      );
    }

    yield put({
      type: actions.UPLOAD_LESSON_DATA_SUCCESSFUl,
      feedbackMessage:
        actualCounter + " out of " + initialCounter + " lessons uploaded.",
    });
  } catch (err) {
    console.log("upload lesson csv failed", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* fetchAssessmentProgressParam({ firebase }) {
  try {
    let data = yield call(AssessmentApi.getProgressParams, firebase);
    if (data) {
      yield put({
        type: actions.GET_ASSESSMENT_PROGRESS_PARAM_SUCCESS,
        assessmentProgressParams: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch assessment progress param", err);
    bugsnagClient.notify(err);
  }
}

function* addNewAssProgressParam({ name, firebase, background, textColor }) {
  try {
    let nodeId = yield call(AssessmentApi.generateProgressParamNode, firebase);
    yield call(
      AssessmentApi.addProgressParam,
      nodeId,
      name,
      firebase,
      background,
      textColor
    );
    yield put({
      type: actions.SAVE_ASS_PROGRESS_PARAM_SUCCESS,
    });
  } catch (err) {
    console.log("failed to add new progress param", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* deleteSelectedAssProgressParam({ record, firebase }) {
  try {
    yield call(AssessmentApi.deleteProgressParams, record, firebase);
    yield put({
      type: actions.DELETE_ASS_PROGRESS_PARAM_SUCCESS,
    });
  } catch (err) {
    console.log("failed to delete progress params", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* deleteSelectedMilestoneLabel({ record, firebase }) {
  try {
    yield call(LearningApi.deleteLabel, record, firebase);
    yield put({
      type: actions.DELETE_LABEL_SUCCESS,
    });
  } catch (err) {
    console.log("failed to delete label", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* getLessonShareHistory({ lessons, firebase }) {
  try {
    let assignmentTask = [];
    let historyMap = new Map();
    for (let index in lessons) {
      let task = call(
        lessonAssignmentApi.getAssignmentByLessonId,
        lessons[index].id,
        firebase
      );
      assignmentTask.push(task);
      historyMap.set(lessons[index].id, 0);
    }

    let newVal = yield all([assignmentTask]);
    for (let i in newVal[0]) {
      let val = newVal[0][i];
      if (val && val.length > 0 && val[0].lessonId) {
        if (historyMap.has(val[0].lessonId)) {
          historyMap.set(val[0].lessonId, val.length);
        }
      }
    }
    console.log("historyMap", historyMap);
    yield put({
      type: actions.FETCH_COMPLETE_SHARE_HISTORY_SUCCESS,
      shareHistoryMap: historyMap,
    });
  } catch (err) {
    console.log("failed to fetch lesson share history", err);
    bugsnagClient.notify(err);
    yield put({ type: actions.LEARNING_REQUEST_FAILED });
  }
}

function* updateLessonShareStatus({
  sharedStatus,
  lesson,
  classroom,
  firebase,
  students,
}) {
  try {
    let studentLessonShare =
      firebase && firebase.schoolConfig.studentLessonShare
        ? firebase.schoolConfig.studentLessonShare
        : false;
    if (studentLessonShare) {
      if (sharedStatus) {
        let studentIds = [];
        if (lesson.students && lesson.students.length > 0) {
          studentIds = lesson.students;
        }

        students.forEach((std) => {
          if (!studentIds.includes(std.id)) {
            studentIds.push(std.id);
          }
        });

        yield call(
          LearningApi.updateweeklyPlanShareStatus,
          sharedStatus,
          lesson,
          classroom,
          firebase,
          studentIds
        );
        yield fork(
          manageStudentAssignment,
          lesson,
          classroom,
          firebase,
          "add",
          students
        );
      } else {
        let studentIds = [];
        if (lesson.students && lesson.students.length > 0) {
          studentIds = lesson.students;
        }

        students.forEach((std) => {
          if (studentIds.includes(std.id)) {
            studentIds = studentIds.filter((s) => {
              return s !== std.id;
            });
          }
        });

        yield call(
          LearningApi.updateweeklyPlanShareStatus,
          sharedStatus,
          lesson,
          classroom,
          firebase,
          studentIds
        );

        yield fork(
          manageStudentAssignment,
          lesson,
          classroom,
          firebase,
          "delete",
          students
        );
      }
    } else {
      yield call(
        LearningApi.updateweeklyPlanShareStatus,
        sharedStatus,
        lesson,
        classroom,
        firebase
      );

      if (!sharedStatus) {
        yield fork(manageStudentAssignment, lesson, classroom, firebase, "add");
      } else {
        yield fork(
          manageStudentAssignment,
          lesson,
          classroom,
          firebase,
          "delete"
        );
      }
    }
    yield put({
      type: actions.UPDATE_SHARE_STATUS_SUCCESS,
    });
  } catch (err) {
    console.log("failed to update share status", err);
    bugsnagClient.notify(err);
    yield put({ type: actions.LEARNING_REQUEST_FAILED });
  }
}

function* manageStudentAssignment(
  lesson,
  classroom,
  firebase,
  operationType,
  studentData
) {
  try {
    let selectedStudent = [];
    let students = JSON.parse(localStorage.getItem("studentList"));
    if (studentData) {
      selectedStudent = studentData;
    } else {
      selectedStudent = students.filter((s) => {
        if (
          s.classList.includes(classroom) &&
          (!s.status || s.status.toLowerCase() === "active")
        ) {
          return s.id;
        }
      });
    }
    console.log("selectedStudent ---- ", selectedStudent);

    let lessonArr = lesson.lessons;
    for (let i in lessonArr) {
      let lsnArr = lessonArr[i];
      for (let index in lsnArr) {
        let lessonId = lsnArr[index].id;
        let schoolAssignmentId =
          lessonId + moment(lesson.date).format("DD[]MM[]YYYY");

        let studentNotificationSent = {};
        for (let i = 0; i < selectedStudent.length; i++) {
          if (operationType === "add") {
            let studentAssignmentObj = {
              assignmentId: schoolAssignmentId,
              done: false,
              id: schoolAssignmentId,
              inversedSharedDate: -moment()
                .startOf("day")
                .add(20, "second")
                .valueOf(),
              lessonId: lessonId,
              seen: false,
              sharedOn: moment()
                .startOf("day")
                .add(20, "second")
                .valueOf(),
              teacherId: firebase.teacher.id,
              type: "Task",
              platform: "web",
            };
            yield fork(
              LearningApi.updateStudentAssignment,
              studentAssignmentObj,
              schoolAssignmentId,
              selectedStudent[i].id,
              firebase
            );

            let shareDate = moment()
              .startOf("day")
              .add(1, "minute")
              .valueOf();
            let planStartDate = moment(lesson.date)
              .startOf("week")
              .add(1, "minute")
              .valueOf();
            let planEndDate = moment(lesson.date)
              .endOf("week")
              .valueOf();
            let branchPath = undefined;

            if (!studentNotificationSent[selectedStudent[i].id]) {
              let bodyMessage =
                "Lesson plan shared for " +
                moment(lesson.date).format("DD[ ]MMMM");
              if (selectedStudent[i].fatherProfileId) {
                let alertNode = yield call(
                  NotificationApi.createAlertReferenceNode,
                  selectedStudent[i].fatherProfileId,
                  firebase
                );
                yield fork(
                  NotificationApi.createAlertNotification,
                  "StudentLessonPlanShared",
                  schoolAssignmentId,
                  selectedStudent[i].fatherUUid
                    ? selectedStudent[i].fatherUUid
                    : null,
                  bodyMessage,
                  alertNode,
                  selectedStudent[i].ios_fatherUUid
                    ? selectedStudent[i].ios_fatherUUid
                    : null,
                  selectedStudent[i].id,
                  selectedStudent[i].fatherProfileId,
                  firebase,
                  branchPath,
                  shareDate,
                  planStartDate,
                  planEndDate
                );

                if (
                  selectedStudent[i].fatherUUid !== undefined ||
                  selectedStudent[i].ios_fatherUUid !== undefined
                ) {
                  yield fork(
                    NotificationApi.sendPushNotification,
                    "StudentLessonPlanShared",
                    schoolAssignmentId,
                    selectedStudent[i].fatherUUid
                      ? selectedStudent[i].fatherUUid
                      : null,
                    bodyMessage,
                    alertNode,
                    selectedStudent[i].ios_fatherUUid
                      ? selectedStudent[i].ios_fatherUUid
                      : null,
                    selectedStudent[i].id,
                    selectedStudent[i].fatherProfileId,
                    firebase,
                    branchPath,
                    shareDate,
                    planStartDate,
                    planEndDate
                  );
                }
              }

              if (selectedStudent[i].motherProfileId) {
                let alertNode = yield call(
                  NotificationApi.createAlertReferenceNode,
                  selectedStudent[i].motherProfileId,
                  firebase
                );
                yield fork(
                  NotificationApi.createAlertNotification,
                  "StudentLessonPlanShared",
                  schoolAssignmentId,
                  selectedStudent[i].motherUUid
                    ? selectedStudent[i].motherUUid
                    : null,
                  bodyMessage,
                  alertNode,
                  selectedStudent[i].ios_motherUUid
                    ? selectedStudent[i].ios_motherUUid
                    : null,
                  selectedStudent[i].id,
                  selectedStudent[i].motherProfileId,
                  firebase,
                  branchPath,
                  shareDate,
                  planStartDate,
                  planEndDate
                );

                if (
                  selectedStudent[i].motherUUid !== undefined ||
                  selectedStudent[i].ios_motherUUid !== undefined
                ) {
                  yield fork(
                    NotificationApi.sendPushNotification,
                    "StudentLessonPlanShared",
                    schoolAssignmentId,
                    selectedStudent[i].motherUUid
                      ? selectedStudent[i].motherUUid
                      : null,
                    bodyMessage,
                    alertNode,
                    selectedStudent[i].ios_motherUUid
                      ? selectedStudent[i].ios_motherUUid
                      : null,
                    selectedStudent[i].id,
                    selectedStudent[i].motherProfileId,
                    firebase,
                    branchPath,
                    shareDate,
                    planStartDate,
                    planEndDate
                  );
                }
              }
            }
            studentNotificationSent[selectedStudent[i].id] = true;
          } else if (operationType === "delete") {
            yield fork(
              LearningApi.deleteStudentAssignment,
              schoolAssignmentId,
              selectedStudent[i].id,
              firebase
            );
          }
        }
      }
    }
  } catch (err) {
    console.log("failed to create school assignment", err);
    bugsnagClient.notify(err);
  }
}

function* fetchPlanExtraDetails({ plan, firebase }) {
  try {
    yield fork(fetchPlanComment, plan, firebase);
    yield fork(fetchPlanSubmission, plan, firebase);
  } catch (err) {
    console.log("failed to fetch plan extra details", err);
    bugsnagClient.notify(err);
  }
}

function* fetchPlanComment(plan, firebase) {
  try {
    let commentMap = new Map();
    let commentTask = [];
    for (let index in plan) {
      if (plan[index].shared) {
        let lessonArr = plan[index].lessons;
        for (let i in lessonArr) {
          let lsnArr = lessonArr[i];
          for (let ind in lsnArr) {
            let lessonId = lsnArr[ind].id;
            let schoolAssignmentId =
              lessonId + moment(plan[index].date).format("DD[]MM[]YYYY");
            let task = call(
              lessonAssignmentApi.getCommentsCount,
              schoolAssignmentId,
              firebase
            );
            commentTask.push(task);
          }
        }
      }
    }

    let newVal = yield all([commentTask]);
    for (let i in newVal[0]) {
      let singleActivity = newVal[0][i];
      if (singleActivity && singleActivity.length > 0) {
        commentMap.set(singleActivity[0].sourceId, singleActivity.length);
      }
    }
    yield put({
      type: actions.GET_PLAN_COMMENT_SUCCESS,
      planComment: commentMap,
    });

    console.log("commentMap", commentMap);
  } catch (err) {
    console.log("failed to fetch plan comment", err);
    bugsnagClient.notify(err);
  }
}

function* fetchPlanSubmission(plan, firebase) {
  try {
    let submissionMap = new Map();
    let submissionTask = new Map();
    for (let index in plan) {
      if (plan[index].shared) {
        let lessonArr = plan[index].lessons;
        for (let i in lessonArr) {
          let lsnArr = lessonArr[i];
          for (let ind in lsnArr) {
            let lessonId = lsnArr[ind].id;
            let schoolAssignmentId =
              lessonId + moment(plan[index].date).format("DD[]MM[]YYYY");
            let task = call(
              lessonAssignmentApi.getLessonSubmission,
              schoolAssignmentId,
              firebase
            );
            submissionTask.set(schoolAssignmentId, task);
          }
        }
      }
    }

    for (let [key, value] of submissionTask) {
      let newLessonVal = yield all([value]);
      let submission = newLessonVal[0];
      if (submission && submission.length > 0) {
        submissionMap.set(key, submission.length);
      }
    }

    console.log("submissionMap", submissionMap);
    yield put({
      type: actions.GET_PLAN_SUBMISSION_SUCCESS,
      planSubmission: submissionMap,
    });
  } catch (err) {
    console.log("failed to fetch plan assignment", err);
    bugsnagClient.notify(err);
  }
}

function* createLessonCopy({ lesson, firebase }) {
  try {
    let copiedLesson = JSON.parse(JSON.stringify(lesson));
    var nodeId = yield call(LearningApi.createLessonsNodeId, firebase);
    copiedLesson.id = nodeId;
    copiedLesson.name = lesson.name + "_Copy";
    yield call(LearningApi.updateShareLesson, copiedLesson, firebase);
    yield put({ type: actions.COPY_LESSON_SUCCESS });
  } catch (err) {
    console.log("failed to copy lesson", err);
    bugsnagClient.notify(err);
    yield put({ type: actions.LEARNING_REQUEST_FAILED });
  }
}

function* updateStudentList({ students }) {
  yield put({
    type: actions.REPLACE_STUDENT_SUCCESS,
    students: students,
  });
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.LIST_MILESTONES, fetchMilestones),
    yield takeLatest(actions.LIST_DEV_AREA, fetchDevelopmentArea),
    yield takeLatest(actions.LIST_LESSONS, fetchLessons),
    yield takeLatest(actions.SAVE_MILESTONE_DOMAIN, saveNewMilestoneDomain),
    yield takeLatest(actions.ADD_SUB_DOMAIN, saveNewMilestoneSubDomain),
    yield takeLatest(actions.UPDATE_SUB_DOMAIN, updateNewMilestoneSubDomain),
    yield takeLatest(actions.SAVE_MILESTONE, saveNewMilestone),
    yield takeLatest(actions.ADD_LABEL, addNewLabel),
    yield takeLatest(actions.GET_LABEL, fetchLabel),
    yield takeLatest(
      actions.UPDATE_MILESTONE_DOMAIN,
      updateExistingMilestoneDomain
    ),
    yield takeLatest(actions.UPDATE_MILESTONE, updateExistingMilestone),
    yield takeLatest(actions.ADD_CATEGORY, addNewCategory),
    yield takeLatest(actions.GET_CATEGORY, fetchCategory),
    yield takeLatest(actions.GET_LEARNING_CLASSROOM, fetchLearningClassroom),
    yield takeLatest(actions.ADD_LESSON, addNewLesson),
    yield takeLatest(
      actions.ADD_LESSON_TO_ALL_CENTERS,
      addNewLessonToAllCenter
    ),
    yield takeLatest(actions.UPDATE_LESSON, updateExistingLesson),
    yield takeLatest(
      actions.UPDATE_LESSON_TO_ALL_CENTERS,
      updateExistingLessonToAllCenter
    ),
    yield takeLatest(actions.ADD_WEEKLY_PLAN, addNewWeeklyPlan),
    yield takeLatest(actions.GET_WEEKLY_PLAN, fetchWeeklyPlan),
    yield takeLatest(actions.DELETE_PLAN, deleteSelectedPlan),
    yield takeLatest(actions.DELETE_LESSON_RECORD, deleteSelectedLessonRecord),
    yield takeLatest(
      actions.DELETE_LESSON_RECORD_FOR_ALL_CENTER,
      deleteSelectedLessonRecordForAllCenter
    ),
    yield takeLatest(actions.DELETE_SINGLE_MILESTONE, deleteSelectedMilestone),
    yield takeLatest(actions.DELETE_SINGLE_DOMAIN, deleteSelectedDomain),
    yield takeLatest(actions.GET_STUDENT_FOR_LESSON, fetchStudentForLesson),
    yield takeLatest(actions.SHARE_LESSON, shareSelectedLesson),
    yield takeLatest(actions.UPLOAD_LESSON_DATA, uploadLessonCsv),
    yield takeLatest(actions.UPDATE_CATEGORY, updateExistingCategory),
    yield takeLatest(actions.DELETE_CATEGORY, deleteCategory),
    yield takeLatest(
      actions.GET_ASSESSMENT_PROGRESS_PARAM,
      fetchAssessmentProgressParam
    ),
    yield takeLatest(actions.SAVE_ASS_PROGRESS_PARAM, addNewAssProgressParam),
    yield takeLatest(
      actions.DELETE_ASS_PROGRESS_PARAM,
      deleteSelectedAssProgressParam
    ),
    yield takeLatest(actions.DELETE_LABEL, deleteSelectedMilestoneLabel),
    yield takeLatest(
      actions.FETCH_COMPLETE_SHARE_HISTORY,
      getLessonShareHistory
    ),
    yield takeLatest(actions.UPDATE_SHARE_STATUS, updateLessonShareStatus),
    yield takeLatest(actions.GET_PLAN_EXTRA_DETAIL, fetchPlanExtraDetails),
    yield takeLatest(actions.COPY_LESSON, createLessonCopy),
    yield takeLatest(actions.REPLACE_STUDENT, updateStudentList),
  ]);
}
