import moment from "moment-timezone";
import { redirectUrl } from '../components/firebase/fbconfig';
const superagent = require("superagent");


function getZoomAccessAndRefreshToken(authToken, firebase) {
    let endPointUrl = firebase.endPointUrl + "getZoomToken";

    let localTimezone = firebase.schoolConfig.timezone ? firebase.schoolConfig.timezone : moment.tz.guess()

    var p1 = new Promise(function (resolve, reject) {
        superagent
            .post(endPointUrl)
            .send({
                schoolName: firebase.schoolName,
                accountName: firebase.sbDbName,
                code: authToken,
                teacherId: firebase.teacher.id,
                timezone: localTimezone,
                redirectUrl: redirectUrl
            })
            .set("accept", "json")
            .end((err, res) => {
                console.log("zoom err", err);
                console.log("res", res);
                resolve(res);
            });
    });
    return p1;
}

function createZoomMeetinng(startTime, note, activityNode, firebase, duration) {
    console.log(" moment(startTime).toISOString()", moment(startTime).format());

    let endPointUrl = firebase.endPointUrl + "createZoomMeeting";
    let localTimezone = firebase.schoolConfig.timezone ? firebase.schoolConfig.timezone : moment.tz.guess();

    var p1 = new Promise(function (resolve, reject) {
        superagent
            .post(endPointUrl)
            .send({
                schoolName: firebase.schoolName,
                accountName: firebase.sbDbName,
                teacherId: firebase.teacher.id,
                meetingObject:
                {
                    topic: note,
                    startTime: moment(startTime).format(),
                    duration: duration ? Number(duration) : 40,
                    timezone: localTimezone,
                    password: "illumine",
                    agenda: note
                },
                activityId: activityNode,
                zoomMeetingType: 'activity',
                teacherBranchPath: firebase.branchPath
            })
            .set("accept", "json")
            .end((err, res) => {
                console.log("create zoom meeting err", err);
                console.log("res", res);
                resolve(res);
            });
    });
    return p1;
}

function zoomAccountDisconnectCompliance(firebase) {

    let endPointUrl = firebase.endPointUrl + "createZoomMeeting";
    var p1 = new Promise(function (resolve, reject) {
        superagent
            .post(endPointUrl)
            .send({
                client_id: 'ABcDefGHIj12A',
                user_id: 'a8yBxjayaSiw02igC8p8l0',
                account_id: 'abcdEfghIJklMn00',
                deauthorization_event_received: {
                    user_data_retention: 'false',
                    account_id: 'abcdEfghIJklMn00',
                    user_id: 'a8yBxjayaSiw02igC8p8l0',
                    signature: '85f9dd5684aecfa97h7bc86b7edc345204467f2jfj4df1b290093cf73fd1e6b00',
                    deauthorization_time: '2019-06-17T13:52:28.632Z',
                    client_id: 'ABcDefGHIj12A'
                },
                compliance_completed: true
            })
            .set("accept", "json")
            .end((err, res) => {
                console.log("create zoom meeting err", err);
                console.log("res", res);
                resolve(res);
            });
    });
    return p1;

}
export const zoomSettingApi = {
    getZoomAccessAndRefreshToken,
    createZoomMeetinng,
    zoomAccountDisconnectCompliance
}