import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment-timezone";
import { eventChannel } from "redux-saga";

function createNewScheduleNode(classroom, date, firebase) {
  let newDate = moment(new Date(date)).format("YYYY[-]MM[-]DD");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf
    .ref(branchPath + "/schedule/" + classroom + "/" + newDate)
    .push().key;
  return key;
}

function uploadScheduleCsv(classroom, scheduleCsvData, date, nodeId, firebase) {
  let actDate = moment(date, [
    "D/MM/YYYY",
    "DD/MM/YY",
    "DD-MM-YYYY",
    "DD, MMMM, YYYY",
    "DD.MM.yyyy",
    "YYYY-MM-DD",
  ]);
  let newDate = moment(actDate).format("YYYY[-]MM[-]DD");

  //let newDate = moment(new Date(date)).format("YYYY[-]MM[-]DD");

  /**new change */

  let scheduleDate = new Date(actDate);
  let dateString = scheduleCsvData.startTime;

  let splittedDateString = dateString.split(":");
  let hour = splittedDateString[0];

  let formattedHour = hour === "12" ? "0" : hour;

  let isAMPM = splittedDateString[1].toLowerCase().includes("am") ? "am" : "pm";
  let splittedMinuteString = splittedDateString[1]
    .substring(0, splittedDateString[1].toLowerCase().indexOf(isAMPM))
    .trim();

  let minute = splittedMinuteString;
  //let amPm = splittedMinuteString[1].toLowerCase();

  /**added to check hour is AM or PM */
  let formattedhr = isAMPM === "pm" ? Number(formattedHour) + 12 : hour;

  // scheduleDate.setHours(formattedhr);
  // scheduleDate.setMinutes(minute);

  let scheduleTime = moment(newDate)
    .set("hour", formattedhr)
    .set("minute", minute)
    .valueOf();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/schedule/" + classroom + "/" + newDate + "/" + nodeId)
    .update({
      id: nodeId,
      description: scheduleCsvData.description,
      classname: classroom,
      name: scheduleCsvData.title,
      scheduleType: scheduleCsvData.category,
      timestamp: scheduleTime,
      // timestamp: new Date(scheduleDate).getTime(),
    });
}

function getScheduleByDate(date, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  return eventChannel((emitter) => {
    rsf.ref(branchPath + "/schedule").on("value", (snap) => {
      var allSchedule = new Map();
      snap.forEach((item) => {
        // let c = item.key;
        // c = new Map();
        // c.set(item.key, []);
        let scheduleValue = [];
        item.forEach((ele) => {
          if (ele.key === date) {
            ele.forEach((newEle) => {
              if (newEle.val() !== null) {
                scheduleValue.push(newEle.val());
              }
            });
          }
        });
        allSchedule.set(item.key, scheduleValue);
        //allSchedule.push(c);
      });
      emitter(allSchedule);
    });
    return () => {
      rsf.ref(branchPath + "/schedule").off();
      console.log("END");
    };
  });
}

function deleteSchedule(schedule, date, className, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/schedule/" + className + "/" + date + "/" + schedule.id)
    .set(null);
}

function addSchedule(
  className,
  date,
  type,
  title,
  description,
  startTime,
  id,
  firebase
) {
  // let t = new Date(startTime);
  let scheduleSelectedTime = moment(startTime);
  let scheduleTime = moment(date)
    .set("hour", scheduleSelectedTime.get("hour"))
    .set("minute", scheduleSelectedTime.get("minute"))
    .set("second", scheduleSelectedTime.get("second"))
    .valueOf();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/schedule/" + className + "/" + date + "/" + id)
    .update({
      classname: className,
      description: description ? description : null,
      id: id,
      name: title,
      scheduleType: type,
      timestamp: scheduleTime,
      lastUpdatedOn: moment().valueOf(),
      platform: "web",
    });
}

export const ScheduleApi = {
  uploadScheduleCsv,
  createNewScheduleNode,
  getScheduleByDate,
  deleteSchedule,
  addSchedule,
};
