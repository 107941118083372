const actions = {
    'LIST_ASSESSMENT_STUDENT_WITH_MILESTONES': 'LIST_ASSESSMENT_STUDENT_WITH_MILESTONES',
    'LIST_ASSESSMENT_STUDENT_WITH_MILESTONES_SUCCESSFUL': 'LIST_ASSESSMENT_STUDENT_WITH_MILESTONES_SUCCESSFUL',

    'LIST_ALL_MILESTONES': 'LIST_ALL_MILESTONES',
    'LIST_ALL_MILESTONES_SUCCESSFUL': 'LIST_ALL_MILESTONES_SUCCESSFUL',

    'GET_ALL_STUDENTS': 'GET_ALL_STUDENTS',
    'GET_ALL_STUDENTS_SUCCESSFUL': 'GET_ALL_STUDENTS_SUCCESSFUL',

    ASSESSMENT_REQUEST_FAILED: 'ASSESSMENT_REQUEST_FAILED',

    GET_DEVELOPMENT_AREAS: "GET_DEVELOPMENT_AREAS",
    GET_DEVELOPMENT_AREAS_SUCCESS: "GET_DEVELOPMENT_AREAS_SUCCESS",


    getStudentAssessment: (firebase) => ({
        type: actions.LIST_ASSESSMENT_STUDENT_WITH_MILESTONES,
        firebase
    }),

    getCurriculumMilestones: (firebase) => ({
        type: actions.LIST_ALL_MILESTONES,
        firebase
    }),

    getAllStudents: (firebase) => ({
        type: actions.GET_ALL_STUDENTS,
        firebase
    }),

    getAssessmentDevelopmentAreas: (firebase) => ({
        type: actions.GET_DEVELOPMENT_AREAS,
        firebase
    }),

}
export default actions;