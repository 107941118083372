const actions = {
    GET_STUDENT_LESSON_STATS: "GET_STUDENT_LESSON_STATS",
    GET_STUDENT_LESSON_STATS_SUCCESSFUL: "GET_STUDENT_LESSON_STATS_SUCCESSFUL",

    GET_LEARNING_EXP: "GET_LEARNING_EXP",
    GET_LEARNING_EXP_SUCCESS: "GET_LEARNING_EXP_SUCCESS",

    GET_STUDENT_WEEKLY_PLAN: "GET_STUDENT_WEEKLY_PLAN",
    GET_STUDENT_WEEKLY_PLAN_SUCCESS: "GET_STUDENT_WEEKLY_PLAN_SUCCESS",

    GET_LEARNING_TASK: "GET_LEARNING_TASK",
    GET_LEARNING_TASK_SUCCESS: "GET_LEARNING_TASK_SUCCESS",

    STUDENT_LESSON_STAT_REQUEST_FAIL: "STUDENT_LESSON_STAT_REQUEST_FAIL",

    GET_STUDENT_ASSIGNMENT_COMMENT: "GET_STUDENT_ASSIGNMENT_COMMENT",
    GET_STUDENT_ASSIGNMENT_COMMENT_SUCCESS: "GET_STUDENT_ASSIGNMENT_COMMENT_SUCCESS",

    SUBMIT_ASSIGNMENT: "SUBMIT_ASSIGNMENT",
    SUBMIT_ASSIGNMENT_SUCCESS: "SUBMIT_ASSIGNMENT_SUCCESS",

    RESET_OPERATION: "RESET_OPERATION",

    GET_STUDENT_SINGLE_SUBMISSION: "GET_STUDENT_SINGLE_SUBMISSION",
    GET_STUDENT_SINGLE_SUBMISSION_SUCCESS: "GET_STUDENT_SINGLE_SUBMISSION_SUCCESS",

    UPDATE_ASSIGNMENT_ACTIVITY: "UPDATE_ASSIGNMENT_ACTIVITY",
    UPDATE_ASSIGNMENT_ACTIVITY_SUCCESS: "UPDATE_ASSIGNMENT_ACTIVITY_SUCCESS",

    GET_STUDENT_SINGLE_ACTIVITY_COMMENTS_SUCCESS: "GET_STUDENT_SINGLE_ACTIVITY_COMMENTS_SUCCESS",

    GET_PLAN_LESSONS: "GET_PLAN_LESSONS",
    GET_PLAN_LESSONS_SUCCESS: "GET_PLAN_LESSONS_SUCCESS",

    GET_PLAN_ASSIGNMENT: "GET_PLAN_ASSIGNMENT",
    GET_PLAN_ASSIGNMENT_SUCCESS: "GET_PLAN_ASSIGNMENT_SUCCESS",


    getStudentLessonStats: (firebase, studentId, startDate, endDate) => ({
        type: actions.GET_STUDENT_LESSON_STATS,
        firebase,
        studentId,
        startDate,
        endDate
    }),

    getLearningExp: (firebase, studentId, limit, startDate, endDate, selectedDropdown, allLessonList, schoolAssignmentRef, fetchSubmissionActivity, checkPlanSubmission, assignmentId) => ({
        type: actions.GET_LEARNING_EXP,
        firebase,
        studentId,
        limit,
        startDate,
        endDate,
        selectedDropdown,
        allLessonList,
        schoolAssignmentRef,
        fetchSubmissionActivity,
        checkPlanSubmission,
        assignmentId
    }),

    getLearningTask: (firebase, studentId) => ({
        type: actions.GET_LEARNING_TASK,
        firebase,
        studentId
    }),

    getStudentLessonComment: (assignments, firebase) => ({
        type: actions.GET_STUDENT_ASSIGNMENT_COMMENT,
        assignments,
        firebase
    }),

    submitAssignment: (note, attachedFile, mediaType, selectedLesson, firebase, disableNotification) => ({
        type: actions.SUBMIT_ASSIGNMENT,
        note,
        attachedFile,
        mediaType,
        selectedLesson,
        firebase,
        disableNotification
    }),

    resetOperationFeedback: () => ({
        type: actions.RESET_OPERATION
    }),

    getSingleSubmission: (selectedLesson, firebase) => ({
        type: actions.GET_STUDENT_SINGLE_SUBMISSION,
        selectedLesson,
        firebase
    }),

    updateAssignmentActivity: (note, attachedFile, mediaType, selectedLesson, firebase, disableNotification) => ({
        type: actions.UPDATE_ASSIGNMENT_ACTIVITY,
        note,
        attachedFile,
        mediaType,
        selectedLesson,
        firebase,
        disableNotification
    }),

    getStudentWeeklyPlan: (firebase) => ({
        type: actions.GET_STUDENT_WEEKLY_PLAN,
        firebase
    }),

    fetchLessonList: (lessonIds, lessonList, firebase) => ({
        type: actions.GET_PLAN_LESSONS,
        lessonIds,
        lessonList,
        firebase
    }),

    fetchStudentPlanAssignment: (studentId, plan, firebase) => ({
        type: actions.GET_PLAN_ASSIGNMENT,
        studentId,
        plan,
        firebase
    })
};

export default actions;