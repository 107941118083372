import '@firebase/firestore' // 👈 If you're using firestore
import { eventChannel } from "redux-saga";

function createClassroomUniqueNodeId(firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    var key = rsf.ref(branchPath + "/classrooms").push().key;
    return key;
}

function getAllClassrooms(firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    return eventChannel(emitter => {
        rsf.ref(branchPath + "/classrooms").on('value', snap => {

            const fullClassroomData = [];
            snap.forEach(element => {
                if (element.val() !== null) {
                    fullClassroomData.push(element.val())
                }
            });
            emitter(fullClassroomData);
        })
        return () => {
            rsf.ref(branchPath + "/classrooms").off();
            console.log("END");
        }
    });
}

function addNewClassroom(name, nodeId, firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    rsf.ref(branchPath + "/classrooms/" + nodeId).set({
        className: name,
        id: nodeId
    });
}

function updateExistingClassroom(updatedName, classroomId, firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    rsf.ref(branchPath + "/classrooms/" + classroomId).update({
        className: updatedName,
    });
}

function* disableClassroom(disableStatus, docId) {
    // const firebaseName = localStorage.getItem('projectId');
    // const rsf = new ReduxSagaFirebase(firebase.app(firebaseName));

    // if (disableStatus === false) {
    //     yield call(rsf.firestore.updateDocument, 'room/' + docId, 'isDisable', true);
    // } else {
    //     yield call(rsf.firestore.updateDocument, 'room/' + docId, 'isDisable', false);
    // }
}

function getClassroomsByCenter(firebase, bPath) {
    let classroomData = [];
    const rsf = firebase.secondaryDb;
    let branchPath = bPath;
    const myRef = rsf.ref(branchPath + "/classrooms");
    var promise1 = new Promise(function (resolve, reject) {
        myRef.once("value").then(function (snap) {
            snap.forEach(element => {
                if (element.val() !== null) {
                    classroomData.push(element.val());
                }
            });
            resolve(classroomData);
        });
    });
    return promise1;
}

function deleteClassroom(record, firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    rsf.ref(branchPath + "/classrooms/" + record.id).set(null);
}

export const ClassroomApi = {
    getAllClassrooms,
    addNewClassroom,
    updateExistingClassroom,
    disableClassroom,
    createClassroomUniqueNodeId,
    getClassroomsByCenter,
    deleteClassroom
};