import actions from "./actions";
const initState = {
    lessonStats: [],
    statsLoading: false,
    // lessonStatsChannel: undefined,
    error: false,
};

export default function lessonStatsReducer(
    state = initState,
    { type, ...action }
) {
    switch (type) {

        case actions.GET_LESSON_STATS:
            return {
                ...state,
                statsLoading: true
            };
        case actions.GET_LESSON_STATS_SUCCESSFUL:
            return {
                ...state,
                lessonStats: action.lessonStats,
                // lessonStatsChannel: action.lessonStatsChannel,
                statsLoading: false
            };

        case actions.LESSON_STAT_REQUEST_FAIL:
            return {
                ...state,
                statsLoading: false
            }

        default:
            return state;
    }
}