import { call } from 'redux-saga/effects';
import firebase from 'firebase'
import '@firebase/firestore' // 👈 If you're using firestore
import ReduxSagaFirebase from 'redux-saga-firebase';
import moment from 'moment';

function* getAllFoodMenu() {
  const firebaseName = localStorage.getItem('projectId');
  const rsf = new ReduxSagaFirebase(firebase.app(firebaseName));
  //const firebaseRef = firebase.app(firebaseName);
  //const colRef = firebaseRef.firestore().collection('foodMenu');
  const fullFoodData = [];

  const snapshot = yield call(rsf.firestore.getCollection, 'foodMenu');
  // const snapshot = yield call(rsf.firestore.getCollection, colRef.where())
  snapshot.forEach(food => {
    var data = {};
    data.docId = food.id
    data.category = food.data().category
    data.date = food.data().date
    data.foodItem = food.data().foodItem
    data.nutrients = food.data().nutrients
    data.allergies = food.data().allergies
    fullFoodData.push(data);
  });
  return fullFoodData;
}

function* getFoodMenuFormat() {
  const firebaseName = localStorage.getItem('projectId');
  const rsf = new ReduxSagaFirebase(firebase.app(firebaseName));
  var csvFormat = '';

  const formatSnapshot = yield call(rsf.firestore.getCollection, 'foodMenuRefLayout');
  formatSnapshot.forEach(layout => {
    csvFormat = layout.data().formatUrl
  });

  return csvFormat;
}

function* addNewFoodMenu(category, date, foodItem, nutrient, allergy) {

  const firebaseName = localStorage.getItem('projectId');
  const rsf = new ReduxSagaFirebase(firebase.app(firebaseName));
  const newDate = new Date(date);

  yield call(
    rsf.firestore.addDocument,
    'foodMenu',
    {
      category: category,
      date: newDate.toDateString(),
      foodItem: foodItem,
      nutrients: nutrient,
      allergies: allergy
    }
  );
}

function* updateExistingFoodMenu(updatedCategory, updatedDate, updatedFoodItem, updatedNutrient, updatedAllergy, docId) {
  const firebaseName = localStorage.getItem('projectId');
  const rsf = new ReduxSagaFirebase(firebase.app(firebaseName));
  var newDate = new Date(updatedDate);

  yield call(
    rsf.firestore.updateDocument,
    'foodMenu/' + docId,
    {
      category: updatedCategory,
      date: newDate.toDateString(),
      foodItem: updatedFoodItem,
      nutrients: updatedNutrient,
      allergies: updatedAllergy
    }
  );
}

function* addNewMenuCsv(csvData) {
  const firebaseName = localStorage.getItem('projectId');
  const rsf = new ReduxSagaFirebase(firebase.app(firebaseName));

  for (var i = 1; i < csvData.length; i++) {

    var date = moment(csvData[i][0], "DD/MM/YYYY");
    var newDate = new Date(date).toDateString();
    var category = csvData[i][1];
    var foodItem = csvData[i][2];
    var nutrient = csvData[i][3];
    var allergy = csvData[i][4];

    yield call(
      rsf.firestore.addDocument,
      'foodMenu',
      {
        category: category,
        date: newDate,
        foodItem: foodItem,
        nutrients: nutrient,
        allergies: allergy
      }
    );
  }
}

export const FoodMenuApi = {
  getAllFoodMenu,
  getFoodMenuFormat,
  addNewFoodMenu,
  updateExistingFoodMenu,
  addNewMenuCsv
};