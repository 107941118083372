const actions = {
  FETCH_STUDENT_ATTENDANCE: "FETCH_STUDENT_ATTENDANCE",
  FETCH_STUDENT_ATTENDANCE_SUCCESSFUL: "FETCH_STUDENT_ATTENDANCE_SUCCESSFUL",

  FETCH_STUDENT_ATTENDANCE_BY_CLASSNAME:
    "FETCH_STUDENT_ATTENDANCE_BY_CLASSNAME",
  FETCH_STUDENT_ATTENDANCE_BY_CLASSNAME_SUCCESSFUL:
    "FETCH_STUDENT_ATTENDANCE_BY_CLASSNAME_SUCCESSFUL",

  FETCH_ALL_STUDENT: "FETCH_ALL_STUDENT",
  FETCH_ALL_STUDENT_SUCCESSFUL: "FETCH_ALL_STUDENT_SUCCESSFUL",

  MARK_PRESENT: "MARK_PRESENT",
  MARK_PRESENT_SUCCESSFUL: "MARK_PRESENT_SUCCESSFUL",

  MARK_ABSENT: "MARK_ABSENT",
  MARK_ABSENT_SUCCESSFUL: "MARK_ABSENT_SUCCESSFUL",

  MARK_CHECKOUT: "MARK_CHECKOUT",
  MARK_CHECKOUT_SUCCESSFUL: "MARK_CHECKOUT_SUCCESSFUL",

  FETCH_ALL_CLASSROOMS: "FETCH_ALL_CLASSROOMS",
  FETCH_ALL_CLASSROOMS_SUCCESSFUL: "FETCH_ALL_CLASSROOMS_SUCCESSFUL",

  FETCH_STUDENT_BY_CLASSNAME: "FETCH_STUDENT_BY_CLASSNAME",
  FETCH_STUDENT_BY_CLASSNAME_SUCCESSFUL:
    "FETCH_STUDENT_BY_CLASSNAME_SUCCESSFUL",

  STUDENT_ATTENDANCE_REQUEST_FAILED: "STUDENT_ATTENDANCE_REQUEST_FAILED",

  RESET_STUDENT_ATD_OPERATION: "RESET_STUDENT_ATD_OPERATION",

  EMAIL_STUDENT_ATTENDANCE: "EMAIL_STUDENT_ATTENDANCE",

  GET_MONTHLY_ATD: "GET_MONTHLY_ATD",
  GET_MONTHLY_ATD_SUCCESS: "GET_MONTHLY_ATD_SUCCESS",

  MARK_STUDENT_PENDING: "MARK_STUDENT_PENDING",
  MARK_STUDENT_PENDING_SUCCESS: "MARK_STUDENT_PENDING_SUCCESS",

  DOWNLOAD_MONTHLY_ATTENDANCE: "DOWNLOAD_MONTHLY_ATTENDANCE",
  GET_PROGRAMS_LIST: "GET_PROGRAMS_LIST",

  initStudentAttendanceData: (date, firebase, operationType) => ({
    type: actions.FETCH_STUDENT_ATTENDANCE,
    date,
    firebase,
    operationType,
  }),

  getAllStudentsForStudentAttendance: (firebase, date) => ({
    type: actions.FETCH_ALL_STUDENT,
    firebase,
    date,
  }),

  markStudentPresent: (
    selectedStudent,
    date,
    time,
    firebase,
    pending,
    checkInOutStatus,
    temperature
  ) => ({
    type: actions.MARK_PRESENT,
    selectedStudent,
    date,
    time,
    firebase,
    pending,
    checkInOutStatus,
    temperature,
  }),

  markStudentAbsent: (selectedStudent, date, firebase) => ({
    type: actions.MARK_ABSENT,
    selectedStudent,
    date,
    firebase,
  }),

  getAllClassroomsForStudentAttendance: (firebase) => ({
    type: actions.FETCH_ALL_CLASSROOMS,
    firebase,
  }),

  getStudentByClassname: (className, firebase, date) => ({
    type: actions.FETCH_STUDENT_BY_CLASSNAME,
    className,
    firebase,
    date,
  }),

  getStudentAttendanceData: (className, date, firebase) => ({
    type: actions.FETCH_STUDENT_ATTENDANCE_BY_CLASSNAME,
    className,
    date,
    firebase,
  }),

  markStudentCheckoutTime: (
    selectedStudent,
    date,
    time,
    firebase,
    checkInOutStatus
  ) => ({
    type: actions.MARK_CHECKOUT,
    selectedStudent,
    date,
    time,
    firebase,
    checkInOutStatus,
  }),

  resetStudentAtdOperation: () => ({
    type: actions.RESET_STUDENT_ATD_OPERATION,
  }),

  emailAttendanceSheet: (value, atdType, downloadType, firebase) => ({
    type: actions.EMAIL_STUDENT_ATTENDANCE,
    value,
    atdType,
    downloadType,
    firebase,
  }),

  getMonthlyAttendance: (date, firebase) => ({
    type: actions.GET_MONTHLY_ATD,
    date,
    firebase,
  }),

  markStudentPending: (selectedStudent, date, firebase) => ({
    type: actions.MARK_STUDENT_PENDING,
    selectedStudent,
    date,
    firebase,
  }),

  downloadMonthlyAttendance: (record, firebase) => ({
    type: actions.DOWNLOAD_MONTHLY_ATTENDANCE,
    record,
    firebase,
  }),
};
export default actions;
