import '@firebase/firestore' // 👈 If you're using firestore
import { eventChannel } from "redux-saga";


function generateStudentAssessmentNode(firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    var key = rsf.ref(branchPath + "/studentAssessment").push().key;
    return key;
}

function getStudentAssessment(firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    return eventChannel(emit => {
        rsf.ref(branchPath + "/studentAssessment").on('value', snap => {
            var x = [];
            snap.forEach(element => {
                if (element.val() !== null && !element.val().deleted) {
                    x.push(element.val());
                }
            });
            emit(x);
        })
        return () => {
            rsf.ref(branchPath + "/studentAssessment").off();
            console.log("unsubscribe student assessment");
        }
    })
}

function getAllMilestones(firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    var milestone = [];
    var milestonePromise = new Promise(function (resolve, reject) {
        rsf.ref(branchPath + "/curriculum-milestone").once('value').then(function (snapshot) {
            snapshot.forEach(snap => {
                if (snap.val() !== null) {
                    milestone.push(snap.val());
                }
            });
            resolve(milestone);
        });
    });
    return milestonePromise;
}

function getAllStudents(firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    var student = [];
    var studentPromise = new Promise(function (resolve, reject) {
        rsf.ref(branchPath + "/students").once('value').then(function (snapshot) {
            snapshot.forEach(snap => {
                if (snap.val() !== null && snap.val().deleted === false) {
                    student.push(snap.val());
                }
            });
            resolve(student);
        });
    });
    return studentPromise;
}

function saveDomainToStudentAssessment(selectedStatus, selectedDomain, selectedStudent, uniqueNodeId, assessmentMilestones, firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    var selectedStudentObject = selectedStudent;

    if (selectedStudentObject.milestones !== undefined) {
        var x = selectedStudentObject.milestones;
        var y = [];
        for (let i = 0; i < x.length; i++) {
            if (selectedDomain.name === x[i].name) {

                x[i].status = selectedStatus;
                y.push(x[i]);
                for (let k = 0; k < y.length; k++) {
                    let xx = y[k].assessmentMilestones;
                    let domName = y[k].name;
                    if (domName === selectedDomain.name) {
                        for (let j = 0; j < xx.length; j++) {
                            xx[j].status = selectedStatus;
                        }
                    }
                }
            } else if (x[i].status !== null) {
                y.push(x[i])
            }
        }
        selectedStudentObject.milestones = y;
        rsf.ref(branchPath + "/studentAssessment/" + selectedStudent.studentId).update(selectedStudentObject);
    } else {
        var y1 = selectedDomain.milestones.map(item =>
            ({
                name: item.name,
                status: selectedStatus
            })
        )
        var x1 = ([{
            assessmentMilestones: y1,
            name: selectedDomain.name,
            shortName: selectedDomain.shortName,
            status: selectedStatus
        }])
        for (let i = 0; i < assessmentMilestones.length; i++) {
            let domainMilestones = []
            if (selectedDomain.name !== assessmentMilestones[i].name) {
                if (assessmentMilestones[i].milestones !== undefined) {
                    domainMilestones = assessmentMilestones[i].milestones;
                }
                x1.push({
                    assessmentMilestones: domainMilestones,
                    name: assessmentMilestones[i].name,
                    shortName: assessmentMilestones[i].shortName,
                })
            }
        }
        selectedStudentObject.milestones = x1;
        selectedStudentObject.id = uniqueNodeId;
        rsf.ref(branchPath + "/studentAssessment/" + uniqueNodeId).update(selectedStudentObject);
    }
}

function saveMilestoneToStudentAssessment(selectedStatus, selectedMilestone, selectedDomain, selectedStudent, assessmentMilestones, uniqueNodeId, firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    if (selectedStudent.id !== undefined) {
        var x = selectedStudent.milestones;
        for (let i = 0; i < x.length; i++) {
            if (selectedDomain.name.trim() === x[i].name.trim()) {
                var y = x[i].assessmentMilestones;
                for (let j = 0; j < y.length; j++) {
                    if (selectedMilestone.name.trim() === y[j].name.trim()) {
                        y[j].status = selectedStatus;
                    }
                }
            }
        }
        selectedStudent.milestones = x;
        rsf.ref(branchPath + "/studentAssessment/" + selectedStudent.studentId).update(selectedStudent);
    } else {
        let existingMilestones = selectedStudent.milestones;
        var y1 = selectedDomain.assessmentMilestones.map(item =>
            item.name.trim() === selectedMilestone.name.trim() ? ({
                name: item.name,
                status: selectedStatus
            })
                : ({
                    name: item.name,
                    status: item.status ? item.status : null
                })
        )
        var x1 = ([{
            assessmentMilestones: y1,
            name: selectedDomain.name,
            shortName: selectedDomain.shortName,
            status: selectedDomain.status ? selectedDomain.status : null,
            labelName: selectedDomain.labelName ? selectedDomain.labelName : null
        }])

        for (let i = 0; i < existingMilestones.length; i++) {
            if (existingMilestones[i].name !== selectedDomain.name) {
                x1.push(existingMilestones[i])
            }
        }
        selectedStudent.milestones = x1;
        selectedStudent.id = uniqueNodeId;
        rsf.ref(branchPath + "/studentAssessment/" + selectedStudent.studentId).update(selectedStudent);
    }
}

function getMilestones(firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    var curriculum = [];
    var curriculumPromise = new Promise(function (resolve, reject) {
        rsf.ref(branchPath + "/curriculum-milestone").once('value').then(function (snap) {
            snap.forEach(item => {
                if (item.val() !== null) {
                    curriculum.push(item.val());
                }
            });
            resolve(curriculum);
        });
    });
    return curriculumPromise;
}

function getSelectedStudentSavedAssessment(studentId, savedAssessmentId, firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    let myRef = rsf.ref(branchPath + "/savedAssessment/" + studentId + "/" + savedAssessmentId);
    return eventChannel(emit => {
        myRef.on('value', snap => {
            var x = {};
            if (snap.val() !== null) {
                x = snap.val();
            }
            emit(x);
        })
        return () => {
            myRef.off();
            console.log("unsubscribe selected student assessment");
        }
    })
}

function generateSaveStudentAssessmentNode(firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    var key = rsf.ref(branchPath + "/savedAssessment").push().key;
    return key;
}

function saveChildAssessment(id, assObj, firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    rsf.ref(branchPath + "/savedAssessment/" + assObj.studentId + "/" + id).update(assObj);
}

function getSavedAssessments(studentId, firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;

    var data = [];
    var assessmentPromise = new Promise(function (resolve, reject) {
        rsf.ref(branchPath + "/savedAssessment/" + studentId).once('value').then(function (snapshot) {
            snapshot.forEach(item => {
                if (item.val() !== null) {
                    data.push(item.val());
                }
            });
            resolve(data);
        });
    });
    return assessmentPromise;
}

function saveStudentAssessmentNote(assObj, firebase, studentId, savedAssessmentId) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    rsf.ref(branchPath + "/savedAssessment/" + studentId + "/" + savedAssessmentId).update(assObj);
}

function savedAssessmentList(firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;

    var data = [];
    let dataMap = new Map();
    var assessmentPromise = new Promise(function (resolve, reject) {
        rsf.ref(branchPath + "/savedAssessment").once('value').then(function (snapshot) {
            snapshot.forEach(snap => {
                let singleStudentAssignments = [];
                snap.forEach(item => {
                    if (item.val() !== null) {
                        data.push(item.val());
                        singleStudentAssignments.push(item.val());
                    }
                });
                dataMap.set(snap.key, singleStudentAssignments);
            });

            let dataObj = {};
            dataObj.data = data;
            dataObj.dataMap = dataMap;
            resolve(dataObj);
        });
    });
    return assessmentPromise;
}

function deleteSavedAssessment(record, firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    rsf.ref(branchPath + "/savedAssessment/" + record.studentId + "/" + record.id).set(null);
}

function generateProgressParamNode(firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    var key = rsf.ref(branchPath + "/progressParams").push().key;
    return key;
}

function addProgressParam(id, name, firebase, background, textColor) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    rsf.ref(branchPath + "/progressParams/" + id).update({
        id: id,
        name: name,
        background: background,
        color: textColor
    })
}

function getProgressParams(firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;

    var data = [];
    var assessmentPromise = new Promise(function (resolve, reject) {
        rsf.ref(branchPath + "/progressParams").once('value').then(function (snapshot) {
            snapshot.forEach(item => {
                if (item.val() !== null) {
                    data.push(item.val());
                }
            });
            resolve(data);
        });
    });
    return assessmentPromise;
}

function deleteProgressParams(record, firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    rsf.ref(branchPath + "/progressParams/" + record.id).set(null);
}

export const AssessmentApi = {
    getStudentAssessment,
    getAllMilestones,
    getAllStudents,
    generateStudentAssessmentNode,
    saveDomainToStudentAssessment,
    saveMilestoneToStudentAssessment,
    getMilestones,
    getSelectedStudentSavedAssessment,
    generateSaveStudentAssessmentNode,
    saveChildAssessment,
    saveStudentAssessmentNote,
    getSavedAssessments,
    savedAssessmentList,
    deleteSavedAssessment,
    generateProgressParamNode,
    addProgressParam,
    getProgressParams,
    deleteProgressParams
}