import { all, put, call, take, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { InvoiceTemplateApi } from "../../firestore-api/invoiceTemplate";
import { AssessmentApi } from "../../firestore-api/studentAssessment";
import { InvoiceApi } from "../../firestore-api/invoice";
import { ActivityApi } from "../../firestore-api/activity";
import bugsnagClient from "@bugsnag/js";

function* fetchInvoiceTemplates({ firebase }) {
  const chan = yield call(InvoiceTemplateApi.getAllInvoiceTemplates, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.LIST_INVOICE_TEMPLATE_SUCCESSFUL,
        invoiceTemplate: data,
        invoiceTemplateChannel: chan,
      });
    }
  } finally {
    console.log("end invoice template channel");
  }
}

function* addInvoiceTemplate({ values, rows, firebase, taxDescription, tax }) {
  try {
    let nodeId = yield call(
      InvoiceTemplateApi.generateInvoiceTemplateNode,
      firebase
    );
    yield call(
      InvoiceTemplateApi.addTemplate,
      values,
      rows,
      nodeId,
      firebase,
      taxDescription,
      tax
    );
    yield put({
      type: actions.ADD_INVOICE_TEMPLATE_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to add fee template", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEMPLATE_REQUEST_FAILED,
    });
  }
}

function* updateInvoiceTemplate({
  values,
  rows,
  id,
  firebase,
  taxDescription,
  tax,
}) {
  try {
    yield call(
      InvoiceTemplateApi.addTemplate,
      values,
      rows,
      id,
      firebase,
      taxDescription,
      tax
    );
    yield put({
      type: actions.UPDATE_INVOICE_TEMPLATE_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to update fee template", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEMPLATE_REQUEST_FAILED,
    });
  }
}

function* fetchStudentForTemplate({ firebase }) {
  try {
    var students = yield call(AssessmentApi.getAllStudents, firebase);
    if (students) {
      yield put({
        type: actions.GET_STUDENT_FOR_TEMPLATE_SUCCESSFFUL,
        students: students,
      });
    }
  } catch (error) {
    console.log("failed to fetch student for template", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.TEMPLATE_REQUEST_FAILED,
    });
  }
}

function* updateStudentToTemplate({
  record,
  selectedStudentCheckbox,
  firebase,
}) {
  try {
    yield call(
      InvoiceTemplateApi.updateStudentInTemplate,
      record,
      selectedStudentCheckbox,
      firebase
    );
    yield put({
      type: actions.ASSIGN_STUDENT_TO_TEMPLATE_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to assign student to fee template", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEMPLATE_REQUEST_FAILED,
    });
  }
}

function* deleteInvoiceTemplate({ record, firebase }) {
  try {
    yield call(InvoiceTemplateApi.deleteInvoiceTemplate, record, firebase);
    yield put({
      type: actions.DELETE_INVOICE_TEMPLATE_SUCCESSFFUL,
    });
  } catch (err) {
    console.log("failed to delete fee template", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEMPLATE_REQUEST_FAILED,
    });
  }
}

function* getTemplateTaxRef({ firebase }) {
  try {
    let data = yield call(InvoiceApi.getTaxReference, firebase);
    if (data) {
      yield put({
        type: actions.FETCH_TEMPLATE_TAX_REF_SUCCESS,
        templateTaxRef: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch template tax ref", err);
    yield put({
      type: actions.TEMPLATE_REQUEST_FAILED,
    });
  }
}

function* fetchTemplateLabel({ firebase }) {
  try {
    let data = yield call(
      ActivityApi.getCustomLabelByCategory,
      "Fee Template",
      firebase
    );
    if (data) {
      console.log("template label", data);
      yield put({
        type: actions.GET_TEMPLATE_LABEL_SUCCESS,
        templateLabel: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch template label", err);
    yield put({
      type: actions.TEMPLATE_REQUEST_FAILED,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.LIST_INVOICE_TEMPLATE, fetchInvoiceTemplates),
    yield takeLatest(actions.ADD_INVOICE_TEMPLATE, addInvoiceTemplate),
    yield takeLatest(actions.UPDATE_INVOICE_TEMPLATE, updateInvoiceTemplate),
    yield takeLatest(actions.GET_STUDENT_FOR_TEMPLATE, fetchStudentForTemplate),
    yield takeLatest(
      actions.ASSIGN_STUDENT_TO_TEMPLATE,
      updateStudentToTemplate
    ),
    yield takeLatest(actions.DELETE_INVOICE_TEMPLATE, deleteInvoiceTemplate),
    yield takeLatest(actions.FETCH_TEMPLATE_TAX_REF, getTemplateTaxRef),
    yield takeLatest(actions.GET_TEMPLATE_LABEL, fetchTemplateLabel),
  ]);
}
