import { call } from 'redux-saga/effects';
import firebase from 'firebase'
import '@firebase/firestore' // 👈 If you're using firestore
import ReduxSagaFirebase from 'redux-saga-firebase';
import moment from 'moment-timezone';

function* getAllCenters() {
    const firebaseName = localStorage.getItem('projectId');
    const rsf = new ReduxSagaFirebase(firebase.app(firebaseName));
    const fullCenterData = [];

    const snapshot = yield call(rsf.firestore.getCollection, 'center');
    snapshot.forEach(center => {
        var data = {};
        //data.ref = center.ref
        data.id = center.id
        data.name = center.data().name
        data.address = center.data().address
        data.isDisable = center.data().isDisable
        fullCenterData.push(data);
    });
    return fullCenterData;
}

function* addNewCenter(name, address) {
    const firebaseName = localStorage.getItem('projectId');
    const rsf = new ReduxSagaFirebase(firebase.app(firebaseName));
    var createdOn = moment().valueOf();
    var modifiedOn = moment().valueOf();

    yield call(
        rsf.firestore.addDocument,
        'center',
        {
            name: name,
            address: address,
            createdOn: createdOn,
            lastModified: modifiedOn,
            isDisable: false
        }
    );
}

function* updateExistingCenter(updatedName, updatedAddress, docId) {
    const firebaseName = localStorage.getItem('projectId');
    const rsf = new ReduxSagaFirebase(firebase.app(firebaseName));
    var modifiedOn = moment().valueOf();

    yield call(
        rsf.firestore.updateDocument,
        'center/' + docId,
        {
            name: updatedName,
            address: updatedAddress,
            lastModified: modifiedOn
        }
    );
}

function* disableCenter(disableStatus, docId) {
    const firebaseName = localStorage.getItem('projectId');
    const rsf = new ReduxSagaFirebase(firebase.app(firebaseName));
    if (disableStatus === false) {
        yield call(rsf.firestore.updateDocument, 'center/' + docId, 'isDisable', true);
    } else {
        yield call(rsf.firestore.updateDocument, 'center/' + docId, 'isDisable', false);
    }
}

export const CenterApi = {
    getAllCenters,
    addNewCenter,
    updateExistingCenter,
    disableCenter
};
