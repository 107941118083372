import actions from "./actions";
const initState = {
    leaves: [],
    leavesChannel: undefined,
    isLoading: false,
    operationType: undefined,
    error: false,
    leavesComment: new Map(),
    studentLeaveToday: [],
    studentLeaveWeek: [],

    staffLeaves: [],
    staffLeavesChannel: undefined,
    staffLeaveToday: [],
    staffLeaveWeek: [],
};

export default function leavesReducer(
    state = initState,
    { type, ...action }
) {
    switch (type) {
        case actions.GET_ALL_LEAVES:
            return {
                ...state,
                isLoading: true,
                operationType: undefined
            };
        case actions.GET_ALL_LEAVES_SUCCESSFUL:
            return {
                ...state,
                leaves: action.leaves,
                leavesChannel: action.leavesChannel,
                isLoading: false,
                operationType: action.operationType
            };

        case actions.GET_NOTIFICATION_LEAVE:
            return {
                ...state,
                isLoading: true,
                operationType: undefined
            };

        case actions.GET_COMMENT_FOR_LEAVE:
            return {
                ...state,
                operationType: undefined
            };
        case actions.GET_COMMENT_FOR_LEAVE_SUCCESSFUL:
            return {
                ...state,
                leavesComment: action.leavesComment,
            };

        case actions.LEAVE_REQUEST_FAILED:
            return {
                ...state,
                error: true,
                isLoading: false,
            };

        case actions.RESOLVE_LEAVE:
            return {
                ...state,
                isLoading: true,
                operationType: undefined
            };
        case actions.RESOLVE_LEAVE_SUCCESSFUL:
            return {
                ...state,
                operationType: undefined,
                isLoading: false,
            };

        case actions.SAVE_LEAVE_COMMENT:
            return {
                ...state,
                isLoading: true,
                operationType: undefined
            };
        case actions.SAVE_LEAVE_COMMENT_SUCCESSFUL:
            return {
                ...state,
                operationType: "COMMENT_SAVED",
                isLoading: false,
            };

        case actions.RESET_COMPLAINT_OPERATION_TYPE:
            return {
                ...state,
                operationType: undefined,
                isLoading: false,
            };

        case actions.GET_STUDENTS_ON_LEAVE:
            return {
                ...state,
                studentLeaveToday: [],
                studentLeaveWeek: []
            };

        case actions.GET_STUDENTS_ON_LEAVE_SUCCESS:
            return {
                ...state,
                studentLeaveToday: action.studentLeaveToday,
                studentLeaveWeek: action.studentLeaveWeek
            };

        case actions.GET_ALL_STAFF_LEAVES:
            return {
                ...state,
                isLoading: true,
                operationType: undefined
            };
        case actions.GET_ALL_STAFF_LEAVES_SUCCESSFUL:
            return {
                ...state,
                staffLeaves: action.staffLeaves,
                staffLeavesChannel: action.staffLeavesChannel,
                isLoading: false,
                operationType: action.operationType
            };

        case actions.RESOLVE_STAFF_LEAVE:
            return {
                ...state,
                isLoading: true,
                operationType: undefined
            };
        case actions.RESOLVE_STAFF_LEAVE_SUCCESSFUL:
            return {
                ...state,
                operationType: undefined,
                isLoading: false,
            };

        case actions.GET_STAFFS_ON_LEAVE:
            return {
                ...state,
                staffLeaveToday: [],
                staffLeaveWeek: []
            };

        case actions.GET_STAFFS_ON_LEAVE_SUCCESS:
            return {
                ...state,
                staffLeaveToday: action.staffLeaveToday,
                staffLeaveWeek: action.staffLeaveWeek
            };

        default:
            return state;
    }
}