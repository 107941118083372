import actions from './actions';
const initState = {
    programs: [],
    programStudents: [],
    programFeePlans: [],
    isLoading: false,
    programClassroom: [],
    programsChannel: undefined,
    error: false,
    addProgramSuccessful: false,
    updateProgramSuccessful: false,
    assignProgramSuccessful: false,
    addFeePlanSuccessful: false,
    programOperationType: ''
}
export default function programReducer(state = initState, { type, ...action }) {
    switch (type) {
        case actions.LIST_PROGRAMS:
            return {
                ...state,
                isLoading: true,
                programOperationType: ''
            };
        case actions.LIST_PROGRAMS_SUCCESSFUL:
            return {
                ...state,
                programs: action.programs,
                isLoading: false,
                programsChannel: action.programsChannel,
                programOperationType: ''
            };
        case actions.GET_PROGRAM_CLASSROOMS:
            return {
                ...state,
                programOperationType: ''
            };
        case actions.GET_PROGRAM_CLASSROOMS_SUCCESSFFUL:
            return {
                ...state,
                programClassroom: action.programClassroom,
            };

        case actions.ADD_PROGRAM:
            return {
                ...state,
                isLoading: true,
                addProgramSuccessful: false,
                programOperationType: ''
            };
        case actions.ADD_PROGRAM_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                addProgramSuccessful: true,
                programOperationType: action.programOperationType
            };

        case actions.UPDATE_PROGRAM:
            return {
                ...state,
                isLoading: true,
                updateProgramSuccessful: false,
                programOperationType: ''
            };
        case actions.UPDATE_PROGRAM_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                updateProgramSuccessful: true,
                programOperationType: action.programOperationType
            };

        case actions.PROGRAM_REQUEST_FAILED:
            return {
                ...state,
                isLoading: false,
                error: true
            };
        case actions.PROGRAM_FETCH_FEE_PLAN:
            return {
                ...state,
                isLoading: true
            };
        case actions.PROGRAM_FETCH_FEE_PLAN_SUCCESSFUL:
            return {
                ...state,
                programFeePlans: action.programFeePlans,
                isLoading: false,
                programOperationType: ''
            };
        case actions.PROGRAM_FETCH_STUDENT:
            return {
                ...state,
                isLoading: true,
                programOperationType: ''
            };
        case actions.PROGRAM_FETCH_STUDENT_SUCCESSFFUL:
            return {
                ...state,
                programStudents: action.programStudents,
                isLoading: false,
            };
        case actions.ASSIGN_STUDENT_TO_PROGRAM:
            return {
                ...state,
                isLoading: true,
                assignProgramSuccessful: false,
                programOperationType: ''
            };
        case actions.ASSIGN_STUDENT_TO_PROGRAM_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                assignProgramSuccessful: true,
                programOperationType: action.programOperationType
            };
        case actions.ADD_FEE_PLAN_TO_PROGRAM:
            return {
                ...state,
                isLoading: true,
                addFeePlanSuccessful: false,
                programOperationType: ''
            };
        case actions.ADD_FEE_PLAN_TO_PROGRAM_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                addFeePlanSuccessful: true,
                programOperationType: 'ADD_FEE_PLAN'
            };

        case actions.DOWNLOAD_ALL_PROGRAM_EXCEL:
            return {
                ...state,
                isLoading: true,
            };

        case actions.DOWNLOAD_ALL_PROGRAM_EXCEL_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case actions.RESET_PROGRAM_OPERATION:
            return {
                ...state,
                isLoading: false,
                error: false,
                programOperationType: undefined
            };

        case actions.DELETE_SELECTED_PROGRAM:
            return {
                ...state,
                isLoading: true,
            };

        case actions.DELETE_SELECTED_PROGRAM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                programOperationType: "DELETE_PROGRAM"
            };

        default:
            return state;
    }
}