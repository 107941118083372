import actions from "./actions";

const initState = {
  allTemplates: [],
  allDraftNewspaper: [],
  isSaved: false,
  closeEditor: false,
  isLoading: false,
  studentList: [],
  autoCompleteCenter: [],
  autoCompleteClassroom: [],
  classrooms: [],
  isSent: false,
  uploadProgress: undefined,
  uploadFileUrl: undefined,
  isSpinning: false,
  newsletterDraftChannel: undefined,
  templateChannel: undefined,
  newsletterType: undefined,
  error: false,
  errorInfo: undefined,
  allSentNewspaper: [],
  sentNewsletterChannel: undefined
};

export default function newsletterReducer(
  state = initState,
  { type, ...action }
) {
  switch (type) {
    case actions.GET_ALL_DRAFT_NEWSLETTER:
      return {
        ...state,
        isLoading: true,
        isSaved: false,
        isSent: false,
        uploadFileUrl: undefined,
        uploadProgress: undefined
      };
    case actions.GET_ALL_NS_TEMPLATES:
      return {
        ...state,
        isLoading: true,
        isSaved: false,
        isSent: false,
        uploadFileUrl: undefined,
        uploadProgress: undefined
      };

    case actions.GET_ALL_NS_TEMPLATES_SUCCESS:
      return {
        ...state,
        allTemplates: action.allTemplates,
        isLoading: false,
        templateChannel: action.templateChannel,
        isSaved: false
      };
    case actions.GET_ALL_DRAFT_NEWSLETTER_SUCCESS:
      return {
        ...state,
        allDraftNewspaper: action.allDraftNewspaper,
        isLoading: false,
        autoCompleteClassroom: [],
        autoCompleteCenter: [],
        newsletterDraftChannel: action.newsletterDraftChannel,
        isSaved: false,
        isSent: false
      };
    case actions.SAVE_NEWSLETTER:
      return {
        ...state
      };
    case actions.UPDATE_NS_TEMPLATE:
      return {
        ...state,
        isLoading: true
      };
    case actions.SAVE_NS_TEMPLATE:
      return {
        ...state
      };
    // case actions.UPDATE_NEWSLETTER:
    //   return {
    //     ...state
    //   };
    case actions.SEND_NEWSLETTER:
      return {
        ...state,
        isLoading: true
      };
    case actions.SEND_NEWSLETTER_SUCCESS:
      return {
        ...state,
        isSent: true,
        isLoading: false
        //allDraftNewspaper: []
      };
    case actions.NEWSLETTER_REQUEST_FAILED:
      return {
        ...state,
        isSent: false,
        isLoading: false,
        error: true,
        errorInfo: action.errorInfo
      };
    case actions.NEWSLETTER_SAVE_REQUEST_SUCCESS:
      return {
        ...state,
        // allDraftNewspaper: [],
        isSaved: true,
        closeEditor: true,
        isLoading: false
      };
    case actions.NS_TEMPLATE_SAVE_REQUEST_SUCCESS:
      return {
        ...state,
        // allDraftNewspaper: [],
        isSaved: true,
        closeEditor: true,
        isLoading: false
      };
    case actions.UPDATE_NEWSLETTER:
      return {
        ...state,
        isLoading: true
      };
    case actions.NEWSLETTER_UPDATE_REQUEST_SUCCESS:
      return {
        ...state,
        // allDraftNewspaper: undefined,
        isSaved: true,
        closeEditor: true,
        isLoading: false
      };
    case actions.NS_TEMPLATE_UPDATE_REQUEST_SUCCESS:
      return {
        ...state,
        isSaved: true,
        closeEditor: true,
        isLoading: false
      };
    case actions.FILE_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: action.progress
      };
    case actions.FILE_UPLOAD_COMPLETED:
      return {
        ...state,
        uploadFileUrl: action.uploadFileUrl,
        newsletterType: action.newsletterType,
        uploadProgress: 100,
        isSaved: false
      };
    case actions.GET_STUDENT_BY_CENTER_NAME:
      return {
        ...state,
        isSpinning: true
      };
    case actions.GET_STUDENT_BY_CENTER_NAME_SUCCESSFUL:
      return {
        ...state,
        studentList: action.studentList,
        isSpinning: false
      };
    case actions.GET_STUDENT_BY_ROOM_NAME:
      return {
        ...state,
        isSpinning: true
      };
    case actions.GET_STUDENT_BY_ROOM_NAME_SUCCESSFUL:
      return {
        ...state,
        studentList: action.studentList,
        isSpinning: false
      };
    case actions.GET_SEARCHED_STUDENT:
      return {
        ...state,
        isSpinning: true
      };
    case actions.GET_SEARCHED_STUDENT_SUCCESSFUL:
      return {
        ...state,
        studentList: action.studentList,
        isSpinning: false
      };
    case actions.GET_STUDENT_DATA:
      return {
        ...state,
        isSpinning: true
      };
    case actions.GET_STUDENT_DATA_SUCCESSFUL:
      return {
        ...state,
        studentList: action.studentList,
        autoCompleteClassroom: action.autoCompleteClassroom,
        // autoCompleteCenter: action.autoCompleteCenter,
        isSpinning: false
      };

    case actions.FETCH_CLASSROOM:
      return {
        ...state,
        isSpinning: true
      };
    case actions.FETCH_CLASSROOM_SUCCESSFUL:
      return {
        ...state,
        classrooms: action.classrooms,
        isSpinning: false
      };

    case actions.RESET_ERROR:
      return {
        ...state,
        isSaved: false,
        isSpinning: false,
        isLoading: false,
        error: false,
        errorInfo: undefined,
        isSent: false
      };

    case actions.SEND_NEWSLETTER_ALL_CENTER:
      return {
        ...state,
        isLoading: true
      };
    case actions.SEND_NEWSLETTER_ALL_CENTER_SUCCESS:
      return {
        ...state,
        isSent: true,
        isLoading: false
      };

    case actions.GET_ALL_SENT_NEWSLETTER:
      return {
        ...state,
        isLoading: true
      };
    case actions.GET_ALL_SENT_NEWSLETTER_SUCCESS:
      return {
        ...state,
        allSentNewspaper: action.allSentNewspaper,
        isLoading: false,
        sentNewsletterChannel: action.sentNewsletterChannel
      };

    default:
      return state;
  }
}
