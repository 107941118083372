const actions = {
    GET_ALL_COMPLAINS: "GET_ALL_COMPLAINS",
    GET_ALL_COMPLAINS_SUCCESSFUL:
        "GET_ALL_COMPLAINS_SUCCESSFUL",

    GET_COMMENT_FOR_COMPLAIN: 'GET_COMMENT_FOR_COMPLAIN',
    GET_COMMENT_FOR_COMPLAIN_SUCCESSFUL: 'GET_COMMENT_FOR_COMPLAIN_SUCCESSFUL',

    COMPLAIN_REQUEST_FAILED: 'COMPLAIN_REQUEST_FAILED',

    RESOLVE_COMPLAINT: "RESOLVE_COMPLAINT",
    RESOLVE_COMPLAINT_SUCCESSFUL: "RESOLVE_COMPLAINT_SUCCESSFUL",

    SAVE_COMPLAINT_COMMENT: "SAVE_COMPLAINT_COMMENT",
    SAVE_COMPLAINT_COMMENT_SUCCESSFUL: "SAVE_COMPLAINT_COMMENT_SUCCESSFUL",

    RESET_COMPLAINT_OPERATION_TYPE: "RESET_COMPLAINT_OPERATION_TYPE",

    GET_PENDING_QUERIES: "GET_PENDING_QUERIES",
    GET_PENDING_QUERIES_SUCCESS: "GET_PENDING_QUERIES_SUCCESS",

    GET_NOTIFICATION_COMPLAINT: "GET_NOTIFICATION_COMPLAINT",

    getAllComplains: (firebase, startTime, endTime, initialCall, selectedFrequency) => ({
        type: actions.GET_ALL_COMPLAINS,
        firebase,
        startTime,
        endTime,
        initialCall,
        selectedFrequency
    }),

    getComplaintComments: (complains, firebase) => ({
        type: actions.GET_COMMENT_FOR_COMPLAIN,
        complains,
        firebase
    }),

    resolveComplain: (record, firebase, resolution) => ({
        type: actions.RESOLVE_COMPLAINT,
        record,
        firebase,
        resolution
    }),

    saveComplaintComment: (comment, dataSource, firebase) => ({
        type: actions.SAVE_COMPLAINT_COMMENT,
        comment,
        dataSource,
        firebase
    }),

    resetComplaintOperationType: () => ({
        type: actions.RESET_COMPLAINT_OPERATION_TYPE
    }),

    getPendingQueries: (firebase, existingPendingQueries) => ({
        type: actions.GET_PENDING_QUERIES,
        firebase,
        existingPendingQueries
    }),

    getNotificationConcern: (id, firebase) => ({
        type: actions.GET_NOTIFICATION_COMPLAINT,
        id,
        firebase
    })
};
export default actions;    