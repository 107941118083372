import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore";
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { ActivityApi } from "../../firestore-api/activity";
import { DashboardApi } from "../../firestore-api/dashboard";
import { InvoiceApi } from "../../firestore-api/invoice";
import { NotificationApi } from "../../firestore-api/notification";
import { AssessmentApi } from "../../firestore-api/studentAssessment";
import { TeacherApi } from "../../firestore-api/teacher";
import { EventsApi } from "../../firestore-api/event";
import { ComplainsApi } from "../../firestore-api/consult";
import { StudentApi } from "../../firestore-api/student";
import moment from "moment-timezone";
import actions from "./actions";
import { ClassroomApi } from "../../firestore-api/classroom";

function* fetchDashboardStatistics({ startDate, endDate, firebase, branches }) {
  yield fork(fetchDashboardData, startDate, endDate, firebase, branches);
  yield fork(fetchUpcomingMeeting, startDate, endDate, firebase);
  yield fork(updateTeacherLastLogin, firebase);
}

function* fetchDashboardData(startDate, endDate, firebase, branches) {
  try {
    var res = yield call(
      DashboardApi.getDashboardData,
      startDate,
      endDate,
      firebase,
      branches
    );
    if (res.body) {
      let data = res.body;

      let studentAttendance = [];
      let staffAttendance = [];
      if (data.attendanceReport && !data.attendanceReport.error) {
        let atd = data.attendanceReport;
        for (let index in atd) {
          studentAttendance.push(atd[index]);
        }
      }

      if (data.staffAttendanceReport && !data.staffAttendanceReport.error) {
        let stfAtd = data.staffAttendanceReport;
        for (let index in stfAtd) {
          staffAttendance.push(stfAtd[index]);
        }
      }

      console.log("attendance dashboard", studentAttendance, staffAttendance);

      yield put({
        type: actions.GET_ACTIVITY_STAT_SUCCESSFUL,
        dashboardData: data,
        studentAttendance: studentAttendance,
        staffAttendance: staffAttendance,
      });
    } else {
      yield put({
        type: actions.GET_ACTIVITY_STAT_SUCCESSFUL,
        dashboardData: [],
        studentAttendance: [],
        staffAttendance: [],
      });
    }
  } catch (err) {
    console.log("failed to fetch dashboard data", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.DASHBOARD_REQ_FAILED,
    });
  }
}

function* fetchUpcomingMeeting(start, end, firebase) {
  try {
    let startDate = new Date(start).setHours(0, 0, 0, 0);
    let endDate = new Date(end).setHours(23, 59, 59, 999);

    const chan = yield call(
      ActivityApi.getMeetingRef,
      startDate,
      endDate,
      firebase,
      "presentDay"
    );
    while (true) {
      let data = yield take(chan);
      let activities = [];
      let activityIds = {};
      for (let index in data) {
        let val = yield call(
          ActivityApi.getActivityById,
          data[index].activityId,
          firebase
        );
        if (
          val.id &&
          val.createdBy.toLowerCase() === firebase.teacher.name &&
          !activityIds[val.id]
        ) {
          activities.push(val);
        }
        activityIds[val.id] = val;
      }
      yield put({
        type: actions.GET_UPCOMING_MEETING_SUCCESS,
        upcomingMeeting: activities,
        upcomingMeetingChan: chan,
      });
    }
  } finally {
    console.log("terminating upcoming meeting");
  }
}

function* fetchStudents(firebase) {
  try {
    var data = yield call(AssessmentApi.getAllStudents, firebase);
    localStorage.setItem("studentList", JSON.stringify(data));
  } catch (err) {
    console.log("failed to fetch students", err);
    bugsnagClient.notify(err);
  }
}

function* fetchStudentsAttendance(date, firebase) {
  const chan = yield call(
    DashboardApi.getStudentAttendanceByClass,
    date,
    firebase
  );
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_STUDENT_ATTENDANCE_SUCCESSFUL,
        studentAttendance: data,
        dashboardStudentAtdChannel: chan,
      });
    }
  } finally {
    console.log("end dashboard student attendance channel");
  }
}

function* fetchStaffs({ firebase }) {
  try {
    var data = yield call(DashboardApi.getAllStaff, firebase);
    if (data) {
      yield put({
        type: actions.GET_STAFF_SUCCESSFUL,
        staffs: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch teachers", err);
    bugsnagClient.notify(err);
  }
}

function* fetchStaffsAttendance(date, firebase) {
  const chan = yield call(
    DashboardApi.getStaffAttendanceByClass,
    date,
    firebase
  );
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_STAFF_ATTENDANCE_SUCCESSFUL,
        staffAttendance: data,
        dashboardStaffAtdChannel: chan,
      });
    }
  } finally {
    console.log("end dashboard staff attendance channel");
  }
}

function* fetchAggregatedInvoice(date, firebase) {
  try {
    let data = yield call(InvoiceApi.getAllAggregatedInvoice, firebase);
    if (data) {
      let totalAmountCollected = 0;
      let totalAmountPending = 0;
      for (let i = 0; i < data.length; i++) {
        totalAmountCollected = totalAmountCollected + data[i].paid;
        totalAmountPending = totalAmountPending + data[i].pending;
      }
      yield put({
        type: actions.GET_AGGREGATED_INVOICE_SUCCESSFUL,
        aggregatedInvoice: data,
        totalPaymentCollectedLifetime: totalAmountCollected,
        totalPaymentPendingLifetime: totalAmountPending,
      });
    }
  } catch (error) {
    console.log("failed to fetch aggregated invoice", error);
    bugsnagClient.notify(error);
  }
}

function* fetchDailyReport({ firebase }) {
  /**logic will be changed */
  // try {
  //   let data = yield call(InvoiceApi.getAllAggregatedInvoice, firebase);
  //   if (data) {
  //     yield put({
  //       type: actions.GET_AGGREGATED_INVOICE_SUCCESSFUL,
  //       aggregatedInvoice: data
  //     });
  //   }
  // } catch (error) {
  //   console.log("failed to fetch aggregated invoice", error);
  // }
}

function* fetchTodaysPost({ date, firebase }) {
  try {
    let data = yield call(DashboardApi.getTodaysPost, date, firebase);
    if (data) {
      yield put({
        type: actions.GET_TODAYS_POST_SUCCESSFUL,
        todaysPost: data,
      });
    }
  } catch (error) {
    console.log("failed to fetch todays post", error);
    bugsnagClient.notify(error);
  }
}

function* fetchClassroom({ firebase }) {
  try {
    let data = yield call(TeacherApi.getClassroomsForTeacher, firebase);

    if (data) {
      yield put({
        type: actions.GET_CLASSROOM_SUCCESSFUL,
        classrooms: data,
      });
    }
  } catch (error) {
    console.log("failed to fetch classrooms", error);
    bugsnagClient.notify(error);
  }
}

function* requestForAttendanceStat({ date, firebase }) {
  try {
    yield fork(
      NotificationApi.sendStats,
      date,
      "updateDailyPostStats",
      firebase
    );
    yield fork(
      NotificationApi.sendStats,
      date,
      "updateAttendanceStatsStaff",
      firebase
    );
  } catch (err) {
    console.log("failed to request for attendance staff", err);
    bugsnagClient.notify(err);
  }
}

function* fetchAlertNotification({ firebase }) {
  if (firebase && firebase.teacher && firebase.teacher.id) {
    try {
      const chan = yield call(NotificationApi.getAlertNotification, firebase);

      while (true) {
        let data = yield take(chan);
        if (data) {
          yield put({
            type: actions.GET_UNREAD_ALERT_NOTIFICATION_SUCCESSFUL,
            alertNotification: data,
            alertNotificationChan: chan,
          });
        }
      }
    } finally {
      console.log("terminating unread alert notification");
    }
  }
  // try {
  //   if (firebase && firebase.teacher && firebase.teacher.id) {
  //     let data = yield call(NotificationApi.getAlertNotification, firebase);

  //     if (data) {
  //       yield put({
  //         type: actions.GET_UNREAD_ALERT_NOTIFICATION_SUCCESSFUL,
  //         alertNotification: data,
  //         alertNotificationChan: undefined,
  //       });
  //     }
  //   }
  // } catch (err) {
  //   console.log("failed to fetchAlertNotification", err);
  //   bugsnagClient.notify(err);
  // }
}

function* updateAlertNotificationReadStatus({ notification, firebase }) {
  try {
    if (firebase && !firebase.isGod) {
      yield call(
        NotificationApi.updateNotificationReadStatus,
        notification,
        firebase
      );
    }
  } catch (err) {
    console.log("failed to update read status of alert notification", err);
    bugsnagClient.notify(err);
  }
}

function* fetchAllAlertNotifications({ firebase }) {
  try {
    if (!firebase.user) {
      yield put({
        type: actions.GET_ALERT_NOTIFICATIONS_SUCCESSFUL,
        allAlerts: [],
      });
    } else {
      var data = yield call(NotificationApi.getAllAlerts, firebase);
      if (data) {
        yield put({
          type: actions.GET_ALERT_NOTIFICATIONS_SUCCESSFUL,
          allAlerts: data,
        });
      }
    }
  } catch (err) {
    console.log("failed to fetch alerts", err);
    bugsnagClient.notify(err);
  }
}

function* updateTeacherLastLogin(firebase) {
  try {
    if (
      firebase &&
      firebase.teacher &&
      firebase.teacher.id &&
      !firebase.isGod
    ) {
      let data = yield call(
        TeacherApi.getTeacherById,
        firebase.teacher.id,
        firebase
      );
      if (data && data.id) {
        yield fork(DashboardApi.updateTeacherLastAccess, firebase);
      }
    }
  } catch (err) {
    console.log("failed to update teacher last login time", err);
    bugsnagClient.notify(
      "failed to update teacher last login time --->>>>" + err.message
        ? err.message
        : err
    );
  }
}

function* fetchDashboardMeetings({ firebase, date, branchPaths }) {
  if (branchPaths && branchPaths.length > 0) {
    yield fork(getMulticenterMeetings, firebase, date, branchPaths);
  } else {
    try {
      let data;
      let activities = [];
      let activityIds = {};

      data = yield call(
        ActivityApi.getVirtualClassActivityRef,
        undefined,
        firebase,
        date
      );
      let activitiesTask = [];

      for (let index in data) {
        let task = call(
          ActivityApi.getActivityById,
          data[index].activityId,
          firebase
        );
        activitiesTask.push(task);
      }

      let newVal = yield all([activitiesTask]);

      for (let i in newVal[0]) {
        let val = newVal[0][i];
        if (val.id && !activityIds[val.id]) {
          activities.push(val);
        }
        activityIds[val.id] = val;
      }

      yield put({
        type: actions.GET_DASHBOARD_MEETINGS_SUCCESS,
        dashboardMeetingActivity: activities,
      });
    } catch (err) {
      console.log("failed to fetch dashboard meetings", err);
      bugsnagClient.notify(err);
    }
  }
}

function* getMulticenterMeetings(firebase, date, branchPaths) {
  try {
    let activities = [];
    for (let index in branchPaths) {
      let bp = branchPaths[index].name;
      let data;

      let activityIds = {};
      data = yield call(
        ActivityApi.getVirtualClassActivityRef,
        undefined,
        firebase,
        date,
        bp
      );
      let activitiesTask = [];

      for (let index in data) {
        let task = call(
          ActivityApi.getActivityById,
          data[index].activityId,
          firebase,
          bp
        );
        activitiesTask.push(task);
      }

      let newVal = yield all([activitiesTask]);

      for (let i in newVal[0]) {
        let val = newVal[0][i];
        if (val.id && !activityIds[val.id]) {
          activities.push(val);
        }
        activityIds[val.id] = val;
      }
    }
    yield put({
      type: actions.GET_DASHBOARD_MEETINGS_SUCCESS,
      dashboardMeetingActivity: activities,
    });
  } catch (err) {
    console.log("failed to fetch dashboard meetings", err);
    bugsnagClient.notify(err);
  }
}

function* fetchNewDashboardStatistics({ firebase, branchPaths }) {
  if (branchPaths && branchPaths.length > 0) {
    yield fork(fetchMulticenterNewDashboardStatistics, firebase, branchPaths);
  } else {
    try {
      let fullClassroomData = JSON.parse(localStorage.getItem("classList"));
      let students = JSON.parse(localStorage.getItem("studentList"));
      let teachers = JSON.parse(localStorage.getItem("teacherList"));

      let activeStudents = [];

      if (students && students.length > 0) {
        activeStudents = students.filter((std) => {
          return (
            (!std.status || std.status.toLowerCase() === "active") &&
            !std.deactivated
          );
        });
      }

      yield put({
        type: actions.GET_DASHBOARD_STATS_SUCCESS,
        dashboardClasses: fullClassroomData,
        dashboardStudents: activeStudents,
        dashboardTeachers: teachers,
      });

      yield fork(fetchUpcomingEvent, firebase);
      yield fork(fetchUpcomingBirthdays, students);
      yield fork(fetchStudentOnLeaves, firebase);
      yield fork(fetchStaffOnLeaves, firebase);
      yield fork(fetchParentCommunicationStats, firebase);
    } catch (err) {
      console.log("failed to fetch dashboard classes", err);
      bugsnagClient.notify(err);
    }
  }
}

function* fetchMulticenterNewDashboardStatistics(firebase, branchPaths) {
  try {
    let activeStudents = [];
    let teachers = [];
    let fullClassroomData = [];
    for (let index in branchPaths) {
      let bPath = branchPaths[index].name;
      let std = yield call(StudentApi.getAllStudentByCenter, firebase, bPath);
      if (std && std.length > 0) {
        activeStudents = [...activeStudents, ...std];
      }

      let staff = yield call(TeacherApi.getAllStaffByCenter, bPath, firebase);
      if (staff) {
        teachers = [...teachers, ...staff];
      }

      let classes = yield call(
        ClassroomApi.getClassroomsByCenter,
        firebase,
        bPath
      );
      if (classes && classes.length > 0) {
        fullClassroomData = [...fullClassroomData, ...classes];
      }
    }

    yield fork(fetchUpcomingEvent, firebase, branchPaths);
    yield fork(fetchUpcomingBirthdays, activeStudents, branchPaths);
    yield fork(fetchStudentOnLeaves, firebase, branchPaths);
    yield fork(fetchStaffOnLeaves, firebase, branchPaths);
    yield fork(fetchParentCommunicationStats, firebase, branchPaths);

    yield put({
      type: actions.GET_DASHBOARD_STATS_SUCCESS,
      dashboardClasses: fullClassroomData,
      dashboardStudents: activeStudents,
      dashboardTeachers: teachers,
    });
  } catch (err) {
    console.log("failed to fetch dashboard classes", err);
    bugsnagClient.notify(err);
  }
}

function* fetchParentCommunicationStats(firebase, branchPaths) {
  try {
    let currentDate = moment().format("YYYY[-]MM[-]DD");
    {
      /**temporary calls */
    }
    // yield fork(
    //   NotificationApi.callDashboardRefreshApi,
    //   firebase,
    //   "activity",
    //   currentDate
    // );
    // yield fork(
    //   NotificationApi.callDashboardRefreshApi,
    //   firebase,
    //   "attendance",
    //   currentDate
    // );
    // yield fork(
    //   NotificationApi.callDashboardRefreshApi,
    //   firebase,
    //   "staff-attendance",
    //   currentDate
    // );
    // yield fork(
    //   NotificationApi.callDashboardRefreshApi,
    //   firebase,
    //   "communication",
    //   currentDate
    // );
    // yield fork(
    //   NotificationApi.callDashboardRefreshApi,
    //   firebase,
    //   "finance",
    //   currentDate
    // );
    if (branchPaths && branchPaths.length > 0) {
      yield fork(
        fetchMulticenterNewDashboardStats,
        firebase,
        currentDate,
        branchPaths
      );
    } else {
      yield fork(getCommunicationStats, firebase);
      yield fork(getStudentAttendanceStats, firebase);
      yield fork(getStaffAttendanceStats, firebase);
      // const chan = yield call(DashboardApi.fetchNewDashboardStats, firebase);
      // while (true) {
      //   let data = yield take(chan);
      //   let parentCommunication = {};
      //   let studentAttendance = {};
      //   let staffAttendance = {};

      //   if (data) {
      //     data.forEach((item) => {
      //       item.forEach((ele) => {
      //         if (ele.key === currentDate) {
      //           if (item.key === "communication") {
      //             parentCommunication.pendingComplaints = ele.val();
      //           } else if (item.key === "attendance") {
      //             studentAttendance = ele.val();
      //           } else if (item.key === "staff-attendance") {
      //             staffAttendance = ele.val();
      //           }
      //         }
      //       });
      //     });
      //   }
      //   console.log("parentCommunication", parentCommunication);
      //   yield put({
      //     type: actions.GET_NEW_DASHBOARD_STATS,
      //     dashboardParentCommunication: parentCommunication,
      //     dashboardStudentAttendance: studentAttendance,
      //     dashboardStaffAttendance: staffAttendance,
      //     newDashboardStatsChannel: chan,
      //   });
      // }
    }
  } finally {
    console.log("terminating new dashboard stats");
  }
}

function* getCommunicationStats(firebase) {
  try {
    const chan = yield call(
      DashboardApi.fetchNewDashboardActivityStatsByCategory,
      "communication",
      moment(),
      firebase
    );

    let parentCommunication = {};

    while (true) {
      let data = yield take(chan);
      if (data) {
        parentCommunication = data;

        yield put({
          type: actions.GET_NEW_DASHBOARD_STATS,
          dashboardParentCommunication: parentCommunication,
          newDashboardStatsChannel: chan,
        });
      }
    }
  } finally {
    console.log("terminating communication stats");
  }
}

function* getStudentAttendanceStats(firebase) {
  try {
    const chan = yield call(
      DashboardApi.fetchNewDashboardActivityStatsByCategory,
      "attendance",
      moment(),
      firebase
    );

    let attendance = {};

    while (true) {
      let data = yield take(chan);
      if (data) {
        attendance = data;

        yield put({
          type: actions.GET_NEW_DASHBOARD_ATTENDANCE_STATS,
          dashboardStudentAttendance: attendance,
          newDashboardAttendanceStatsChannel: chan,
        });
      }
    }
  } finally {
    console.log("terminating student attendance stats");
  }
}

function* getStaffAttendanceStats(firebase) {
  try {
    const chan = yield call(
      DashboardApi.fetchNewDashboardActivityStatsByCategory,
      "staff-attendance",
      moment(),
      firebase
    );

    let staffAttendance = {};

    while (true) {
      let data = yield take(chan);
      if (data) {
        staffAttendance = data;

        yield put({
          type: actions.GET_NEW_DASHBOARD_STAFF_ATTENDANCE_STATS,
          dashboardStaffAttendance: staffAttendance,
          newDashboardStaffAttendanceStatsChannel: chan,
        });
      }
    }
  } finally {
    console.log("terminating staff attendance stats");
  }
}

function* fetchMulticenterNewDashboardStats(
  firebase,
  currentDate,
  branchPaths
) {
  try {
    let parentCommunication = {
      pendingComplaints: 0,
      pendingLeaves: 0,
      pendingNotes: 0,
      todayComplaints: 0,
      todayLeaves: 0,
      todayNotes: 0,
    };
    let studentAttendance = {
      absentList: 0,
      inList: 0,
      lateCheckIn: 0,
      lateCheckOut: 0,
      outList: 0,
      presentList: 0,
      total: 0,
    };
    let staffAttendance = {
      inList: [],
      outList: [],
      presentList: [],
      total: [],
      absentList: [],
    };

    for (let index in branchPaths) {
      let bPath = branchPaths[index].name;

      let data = yield call(
        DashboardApi.fetchNewDashboardStatsPromise,
        firebase,
        bPath
      );

      if (data) {
        data.forEach((item) => {
          item.forEach((ele) => {
            if (ele.key === currentDate) {
              if (item.key === "communication") {
                if (ele.val().total) {
                  let total = ele.val().total;
                  parentCommunication.pendingComplaints =
                    parentCommunication.pendingComplaints +
                    total.pendingComplaints
                      ? total.pendingComplaints
                      : 0;

                  parentCommunication.pendingLeaves =
                    parentCommunication.pendingLeaves +
                    (total.pendingLeaves ? total.pendingLeaves : 0);

                  parentCommunication.pendingNotes =
                    parentCommunication.pendingNotes +
                    (total.pendingNotes ? total.pendingNotes : 0);

                  parentCommunication.todayComplaints =
                    parentCommunication.todayComplaints +
                    (total.todayComplaints ? total.todayComplaints : 0);

                  parentCommunication.todayLeaves =
                    parentCommunication.todayLeaves +
                    (total.todayLeaves ? total.todayLeaves : 0);

                  parentCommunication.todayNotes =
                    parentCommunication.todayNotes +
                    (total.todayNotes ? total.todayNotes : 0);
                }
              } else if (item.key === "attendance") {
                if (ele.val().total) {
                  let total = ele.val().total;
                  studentAttendance.absentList =
                    studentAttendance.absentList +
                    (total.absentList ? total.absentList : 0);

                  studentAttendance.inList =
                    studentAttendance.inList +
                    (total.inList ? total.inList : 0);

                  studentAttendance.lateCheckIn =
                    studentAttendance.lateCheckIn +
                    (total.lateCheckIn ? total.lateCheckIn : 0);

                  studentAttendance.lateCheckOut =
                    studentAttendance.lateCheckOut +
                    (total.lateCheckOut ? total.lateCheckOut : 0);

                  studentAttendance.outList =
                    studentAttendance.outList +
                    (total.outList ? total.outList : 0);

                  studentAttendance.presentList =
                    studentAttendance.presentList +
                    (total.presentList ? total.presentList : 0);

                  studentAttendance.total =
                    studentAttendance.total + (total.total ? total.total : 0);
                }
              } else if (item.key === "staff-attendance") {
                if (ele.val().total) {
                  let total = ele.val().total;
                  staffAttendance.inList = [
                    ...staffAttendance.inList,
                    ...(total.inList ? total.inList : []),
                  ];

                  staffAttendance.outList = [
                    ...staffAttendance.outList,
                    ...(total.outList ? total.outList : []),
                  ];

                  staffAttendance.presentList = [
                    ...staffAttendance.presentList,
                    ...(total.presentList ? total.presentList : []),
                  ];

                  staffAttendance.total = [
                    ...staffAttendance.total,
                    ...(total.total ? total.total : []),
                  ];

                  staffAttendance.absentList = [
                    ...staffAttendance.absentList,
                    ...(total.absentList ? total.absentList : []),
                  ];
                }
              }
            }
          });
        });
      }
    }

    yield put({
      type: actions.GET_NEW_DASHBOARD_STATS,
      dashboardParentCommunication: { total: parentCommunication },
      dashboardStudentAttendance: { total: studentAttendance },
      dashboardStaffAttendance: { total: staffAttendance },
      newDashboardStatsChannel: undefined,
    });
  } catch (err) {
    console.log("failed to fetch dashboard stats", err);
    bugsnagClient.notify(err);
  }
}

function* fetchUpcomingEvent(firebase, branchPaths) {
  try {
    let event = [];
    if (branchPaths && branchPaths.length > 0) {
      for (let index in branchPaths) {
        let bPath = branchPaths[index].name;
        let centerEvent = yield call(
          EventsApi.getUpcomingEvent,
          firebase,
          bPath
        );
        if (centerEvent && centerEvent.length > 0) {
          event = [...event, ...centerEvent];
        }
      }
    } else {
      event = yield call(EventsApi.getUpcomingEvent, firebase);
      event.sort(function(a, b) {
        var dateA = a.eventDate.time,
          dateB = b.eventDate.time;
        return dateA - dateB;
      });
    }
    if (event) {
      yield put({
        type: actions.GET_DASHBOARD_EVENT_SUCCESS,
        dashboardEvent: event,
      });
    }
  } catch (err) {
    console.log("failed to fetch upcoming event", err);
    bugsnagClient.notify(err);
  }
}

function* fetchUpcomingBirthdays(students, branchPaths) {
  try {
    let dateSample = [
      moment().format("DD[ ]MMM"),
      moment()
        .add(1, "day")
        .format("DD[ ]MMM"),
      moment()
        .add(2, "day")
        .format("DD[ ]MMM"),
    ];

    let birthdays = [];

    if (students && students.length > 0) {
      students.forEach((item) => {
        if (
          item.birthDate &&
          dateSample.includes(
            moment(item.birthDate, "DD[, ]MMMM[, ]YYYY").format("DD[ ]MMM")
          )
        ) {
          birthdays.push(item);
        }
      });
    }

    yield put({
      type: actions.GET_DASHBOARD_BIRTHDAY_SUCCESS,
      dashboardBirthdays: birthdays,
    });
  } catch (err) {
    console.log("failed to fetch upcoming birthdays", err);
    bugsnagClient.notify(err);
  }
}

function* fetchStudentOnLeaves(firebase, branchPaths) {
  try {
    let startTime = moment()
      .startOf("day")
      .valueOf();
    let endTime = moment()
      .endOf("week")
      .valueOf();

    let currentDayLeave = [];
    let currentWeekLeave = [];

    if (branchPaths && branchPaths.length > 0) {
      for (let index in branchPaths) {
        let bPath = branchPaths[index].name;
        let leaves = yield call(
          ComplainsApi.getAllPendingLeaves,
          firebase,
          startTime,
          endTime,
          bPath
        );

        if (leaves) {
          currentWeekLeave = leaves;
          for (let index in leaves) {
            let singleLeave = leaves[index];
            if (
              moment(startTime).isSame(moment(singleLeave.startDate), "day") ||
              moment(startTime).isBetween(
                moment(singleLeave.startDate),
                moment(singleLeave.endTime),
                "day",
                "[]"
              )
            ) {
              currentDayLeave.push(singleLeave);
            }
          }
        }
      }
    } else {
      let leaves = yield call(
        ComplainsApi.getAllPendingLeaves,
        firebase,
        startTime,
        endTime
      );

      if (leaves) {
        currentWeekLeave = leaves;
        for (let index in leaves) {
          let singleLeave = leaves[index];
          if (
            moment(startTime).isSame(moment(singleLeave.startDate), "day") ||
            moment(startTime).isBetween(
              moment(singleLeave.startDate),
              moment(singleLeave.endTime),
              "day",
              "[]"
            )
          ) {
            currentDayLeave.push(singleLeave);
          }
        }
      }
    }

    yield put({
      type: actions.GET_DASHBOARD_STUDENT_LEAVES,
      dashboardStudentLeaves: currentDayLeave,
    });
  } catch (err) {
    console.log("failed to fetch dashboard student on leaves", err);
    bugsnagClient.notify(err);
  }
}

function* fetchStaffOnLeaves(firebase, branchPaths) {
  try {
    let startTime = moment()
      .startOf("day")
      .valueOf();
    let endTime = moment()
      .endOf("week")
      .valueOf();

    let currentDayLeave = [];
    let currentWeekLeave = [];

    if (branchPaths && branchPaths.length > 0) {
      for (let index in branchPaths) {
        let bPath = branchPaths[index].name;
        let leaves = yield call(
          ComplainsApi.getAllPendingStaffLeaves,
          firebase,
          startTime,
          endTime,
          bPath
        );

        if (leaves) {
          currentWeekLeave = leaves;
          for (let index in leaves) {
            let singleLeave = leaves[index];
            if (
              moment(startTime).isSame(moment(singleLeave.startDate), "day") ||
              moment(startTime).isBetween(
                moment(singleLeave.startDate),
                moment(singleLeave.endTime),
                "day",
                "[]"
              )
            ) {
              currentDayLeave.push(singleLeave);
            }
          }
        }
      }
    } else {
      let leaves = yield call(
        ComplainsApi.getAllPendingStaffLeaves,
        firebase,
        startTime,
        endTime
      );

      if (leaves) {
        currentWeekLeave = leaves;
        for (let index in leaves) {
          let singleLeave = leaves[index];
          if (
            moment(startTime).isSame(moment(singleLeave.startDate), "day") ||
            moment(startTime).isBetween(
              moment(singleLeave.startDate),
              moment(singleLeave.endTime),
              "day",
              "[]"
            )
          ) {
            currentDayLeave.push(singleLeave);
          }
        }
      }
    }

    yield put({
      type: actions.GET_DASHBOARD_STAFF_LEAVES,
      dashboardStaffLeaves: currentDayLeave,
    });
  } catch (err) {
    console.log("failed to fetch dashboard staff on leaves", err);
    bugsnagClient.notify(err);
  }
}

function* fetchDashboardActivityStats({ firebase, date, branchPaths }) {
  let currentDate = moment(date).format("YYYY[-]MM[-]DD");
  if (branchPaths && branchPaths.length > 0) {
    let activityStats = {
      Photo: 0,
      approvalPending: 0,
      Video: 0,
      Notification: 0,
      Incident: 0,
    };

    for (let index in branchPaths) {
      let bPath = branchPaths[index].name;

      let data = yield call(
        DashboardApi.fetchNewDashboardActivityStatsPromise,
        firebase,
        "activity",
        bPath
      );
      if (data) {
        data.forEach((item) => {
          if (item.key === currentDate) {
            let existingActivity = [];
            if (item.val().total) {
              let total = item.val().total;

              for (let k in total) {
                if (!existingActivity.includes(k.toLowerCase())) {
                  activityStats[k] =
                    (activityStats[k] ? activityStats[k] : 0) +
                    (total[k] ? total[k].count : 0);

                  existingActivity.push(k.toLowerCase());
                }
              }
            }
          }
        });
      }
    }

    yield put({
      type: actions.GET_NEW_DASHBOARD_ACTIVITY_STATS_SUCCESS,
      dashboardActivity: { total: activityStats },
      dashboardActivityChannel: undefined,
    });
  } else {
    try {
      const chan = yield call(
        DashboardApi.fetchNewDashboardActivityStats,
        firebase
      );
      while (true) {
        let data = yield take(chan);
        let activityStats = {};

        if (data) {
          data.forEach((item) => {
            if (item.key === currentDate) {
              activityStats = item.val();
            }
          });
        }

        yield put({
          type: actions.GET_NEW_DASHBOARD_ACTIVITY_STATS_SUCCESS,
          dashboardActivity: activityStats,
          dashboardActivityChannel: chan,
        });
      }
    } finally {
      console.log("terminating dashboard activity stats");
    }
  }
}

function* fetchDashboardLearningStats({ firebase, date, branchPaths }) {
  let currentDate = moment(date).format("YYYY[-]MM[-]DD");
  if (branchPaths && branchPaths.length > 0) {
    let learningStats = {
      assignmentShared: 0,
      assignmentSubmitted: 0,
      lessonShared: 0,
    };
    for (let index in branchPaths) {
      let bPath = branchPaths[index].name;
      let data = yield call(
        DashboardApi.fetchNewDashboardActivityStatsPromise,
        firebase,
        "lessons",
        bPath
      );
      if (data) {
        data.forEach((item) => {
          if (item.key === currentDate) {
            if (item.val().total) {
              let total = item.val().total;

              learningStats.assignmentShared =
                learningStats.assignmentShared +
                (total.assignmentShared ? total.assignmentShared : 0);

              learningStats.assignmentSubmitted =
                learningStats.assignmentSubmitted +
                (total.assignmentSubmitted ? total.assignmentSubmitted : 0);

              learningStats.lessonShared =
                learningStats.lessonShared +
                (total.lessonShared ? total.lessonShared : 0);
            }
          }
        });
      }
    }

    yield put({
      type: actions.GET_NEW_DASHBOARD_LEARNING_STATS_SUCCESS,
      dashboardLearning: { total: learningStats },
      dashboardLearningChan: undefined,
    });

    yield put({
      type: actions.GET_NEW_DASHBOARD_LEARNING_PLAN_SUCCESS,
      dashboardLearningPlan: {},
    });
  } else {
    try {
      const chan = yield call(
        DashboardApi.fetchNewDashboardLearningStats,
        firebase
      );

      while (true) {
        let data = yield take(chan);
        let learningStats = {};

        if (data) {
          data.forEach((item) => {
            if (item.key === currentDate) {
              learningStats = item.val();
            }
          });
        }

        yield fork(fetchDashboardLessonPlan, firebase, date);
        yield put({
          type: actions.GET_NEW_DASHBOARD_LEARNING_STATS_SUCCESS,
          dashboardLearning: learningStats,
          dashboardLearningChan: chan,
        });
      }
    } finally {
      console.log("terminating new dashboard learning stats");
    }
  }
}

function* fetchDashboardLessonPlan(firebase, date) {
  try {
    let classes = JSON.parse(localStorage.getItem("classList"));
    let lesssonPlans = {};

    for (let index in classes) {
      let data = yield call(
        DashboardApi.getNewDashboardLessonPlan,
        classes[index].className,
        date,
        firebase
      );
      if (data && data.lessons) {
        lesssonPlans[classes[index].className] = data.lessons.length;
      }
    }

    yield put({
      type: actions.GET_NEW_DASHBOARD_LEARNING_PLAN_SUCCESS,
      dashboardLearningPlan: lesssonPlans,
    });
  } catch (err) {
    console.log("failed to fetch dashboard lesson plan", err);
    bugsnagClient.notify(err);
  }
}

function* fetchDashboardLeadsStats({ firebase, date, branchPaths }) {
  let currentDate = moment(date).format("YYYY[-]MM[-]DD");
  if (branchPaths && branchPaths.length > 0) {
    let leadStats = {
      calls: 0,
      emails: 0,
      enrolled: 0,
      followupToday: 0,
      leadsCreatedToday: 0,
      overdueFollowup: 0,
      visits: 0,
    };
    for (let index in branchPaths) {
      let bPath = branchPaths[index].name;
      let data = yield call(
        DashboardApi.fetchNewDashboardActivityStatsPromise,
        firebase,
        "leads",
        bPath
      );

      if (data) {
        data.forEach((item) => {
          if (item.key === currentDate) {
            if (item.val().total) {
              let total = item.val().total;

              leadStats.calls =
                leadStats.calls + (total.calls ? total.calls : 0);

              leadStats.emails =
                leadStats.emails + (total.emails ? total.emails : 0);

              leadStats.enrolled =
                leadStats.enrolled + (total.enrolled ? total.enrolled : 0);

              leadStats.followupToday =
                leadStats.followupToday +
                (total.followupToday ? total.followupToday : 0);

              leadStats.leadsCreatedToday =
                leadStats.leadsCreatedToday +
                (total.leadsCreatedToday ? total.leadsCreatedToday : 0);

              leadStats.overdueFollowup =
                leadStats.overdueFollowup +
                (total.overdueFollowup ? total.overdueFollowup : 0);

              leadStats.visits =
                leadStats.visits + (total.visits ? total.visits : 0);
            }
          }
        });
      }
    }

    yield put({
      type: actions.GET_NEW_DASHBOARD_LEADS_STATS_SUCCESS,
      dashboardLeads: { total: leadStats },
      dashboardLeadsChan: undefined,
    });
  } else {
    try {
      const chan = yield call(
        DashboardApi.fetchNewDashboardLeadsStats,
        firebase
      );
      while (true) {
        let data = yield take(chan);
        let leadStats = {};

        if (data) {
          data.forEach((item) => {
            if (item.key === currentDate) {
              leadStats = item.val();
            }
          });
        }

        yield put({
          type: actions.GET_NEW_DASHBOARD_LEADS_STATS_SUCCESS,
          dashboardLeads: leadStats,
          dashboardLeadsChan: chan,
        });
      }
    } finally {
      console.log("terminating new dashboard leads stats");
    }
  }
}

function* fetchDashboardFinanceStats({ firebase, date, branchPaths }) {
  let currentDate = moment(date).format("YYYY[-]MM[-]DD");
  if (branchPaths && branchPaths.length > 0) {
    let financeStats = {
      paid: 0,
      pending: 0,
      total: 0,
    };
    for (let index in branchPaths) {
      let bPath = branchPaths[index].name;
      let data = yield call(
        DashboardApi.fetchNewDashboardActivityStatsPromise,
        firebase,
        "finance",
        bPath
      );

      if (data) {
        data.forEach((item) => {
          if (item.key === currentDate) {
            if (item.val().total) {
              let total = item.val().total;

              financeStats.paid =
                financeStats.paid + (total.paid ? total.paid : 0);

              financeStats.pending =
                financeStats.pending + (total.pending ? total.pending : 0);

              financeStats.total =
                financeStats.total + (total.total ? total.total : 0);
            }
          }
        });
      }
    }

    yield put({
      type: actions.GET_NEW_DASHBOARD_FINANCE_STATS_SUCCESS,
      dashboardFinance: { total: financeStats },
      dashboardFinanceChan: undefined,
    });
  } else {
    try {
      const chan = yield call(
        DashboardApi.fetchNewDashboardFinanceStats,
        firebase
      );

      while (true) {
        let data = yield take(chan);
        let financeStats = {};

        if (data) {
          data.forEach((item) => {
            if (item.key === currentDate) {
              financeStats = item.val();
            }
          });
        }

        yield put({
          type: actions.GET_NEW_DASHBOARD_FINANCE_STATS_SUCCESS,
          dashboardFinance: financeStats,
          dashboardFinanceChan: chan,
        });
      }
    } finally {
      console.log("terminating new dashboard finance stats");
    }
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_STUDENT, fetchStudents),
    yield takeLatest(actions.GET_STUDENT_ATTENDANCE, fetchStudentsAttendance),
    yield takeLatest(actions.GET_STAFF, fetchStaffs),
    yield takeLatest(actions.GET_STAFF_ATTENDANCE, fetchStaffsAttendance),
    yield takeLatest(actions.GET_AGGREGATED_INVOICE, fetchAggregatedInvoice),
    yield takeLatest(actions.GET_DAILY_REPORT, fetchDailyReport),
    yield takeLatest(actions.GET_TODAYS_POST, fetchTodaysPost),
    yield takeLatest(actions.GET_CLASSROOM, fetchClassroom),
    yield takeLatest(actions.GET_ACTIVITY_STAT, fetchDashboardStatistics),
    yield takeLatest(actions.REQUEST_ATTENDANCE, requestForAttendanceStat),
    yield takeLatest(
      actions.GET_UNREAD_ALERT_NOTIFICATION,
      fetchAlertNotification
    ),
    yield takeLatest(
      actions.UPDATE_ALERT_NOTIFICATION_READ_STATUS,
      updateAlertNotificationReadStatus
    ),
    yield takeLatest(
      actions.GET_ALERT_NOTIFICATIONS,
      fetchAllAlertNotifications
    ),
    yield takeLatest(actions.GET_DASHBOARD_MEETINGS, fetchDashboardMeetings),
    yield takeLatest(actions.GET_DASHBOARD_STATS, fetchNewDashboardStatistics),
    yield takeLatest(
      actions.GET_NEW_DASHBOARD_ACTIVITY_STATS,
      fetchDashboardActivityStats
    ),
    yield takeLatest(
      actions.GET_NEW_DASHBOARD_LEARNING_STATS,
      fetchDashboardLearningStats
    ),
    yield takeLatest(
      actions.GET_NEW_DASHBOARD_LEADS_STATS,
      fetchDashboardLeadsStats
    ),
    yield takeLatest(
      actions.GET_NEW_DASHBOARD_FINANCE_STATS,
      fetchDashboardFinanceStats
    ),
  ]);
}
