export default class FilterPermission {
    constructor() {
    }
}

FilterPermission.checkIfPermission = (key, firebase) => {
    let permissions = new Map(JSON.parse(localStorage.getItem("permissionList")));
    if (permissions.has(key)) {
        let value = permissions.get(key);
        if (firebase && firebase.teacher && firebase.teacher.role) {
            if (value.roles && value.roles.includes(firebase.teacher.role)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } else {
        return false;
    }
}

FilterPermission.checkIfPermissionByTeacherRole = (key, role) => {
    let permissions = new Map(JSON.parse(localStorage.getItem("permissionList")));
    if (permissions.has(key)) {
        let value = permissions.get(key);
        if (role) {
            if (value.roles && value.roles.includes(role)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } else {
        return false;
    }
}