import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import firebase from 'firebase'
import '@firebase/firestore' // 👈 If you're using firestore
import ReduxSagaFirebase from 'redux-saga-firebase'
// import moment from 'moment';


export function* listParents() {

  const rsf = new ReduxSagaFirebase(firebase.app());
  const fullParentData = [];

  const snapshot = yield call(rsf.firestore.getCollection, 'parent');
  snapshot.forEach(parent => {
    var data = {};
    data.id = parent.id
    data.name = parent.data().name
    data.avatar = parent.data().avatar
    data.address = parent.data().address
    data.email = parent.data().email
    data.phoneNumber = parent.data().phoneNumber
    data.studentName = parent.data().studentName
    data.relation = parent.data().relation
    data.gender = parent.data().gender
    fullParentData.push(data);
  });

  yield put({
    type: actions.LIST_PARENT,
    parents: fullParentData
  });
}


export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.LIST_PARENTS, listParents),
    // yield takeEvery(actions.ADD_CALENDAR, addCalendar),
    // yield takeEvery(actions.UPDATE_CALENDAR, updateCalendarDetails),
    // yield takeEvery(actions.AUTOSEARCH_HOLIDAY_CENTER_NAME, autoSearchHolidayCenterName)
  ]);
}
