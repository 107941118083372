import "@firebase/firestore"; // 👈 If you're using firestore
import { eventChannel } from "redux-saga";
import moment from 'moment-timezone';

function getFeePlan(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel(emit => {
    rsf.ref(branchPath + "/feePlan").orderByChild("inverseDate").on("value", snap => {
      var x = [];
      snap.forEach(element => {
        if (element.val() !== null) {
          x.push(element.val());
        }
      });
      emit(x);
    });
    return () => {
      rsf.ref(branchPath + "/feePlan").off();
      console.log("unsubscribe feeplan");
    };
  });
}

function getFeeComponent(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel(emit => {
    rsf.ref(branchPath + "/feeComponent").orderByChild("inverseDate").on("value", snap => {
      var x = [];
      snap.forEach(element => {
        if (element.val() !== null) {
          x.push(element.val());
        }
      });
      emit(x);
    });
    return () => {
      rsf.ref(branchPath + "/feeComponent").off();
      console.log("unsubscribe component");
    };
  });
}

function addFeePlan(value, nodeId, firebase, customDates) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let generationDates = [];
  if (value.frequency === "Custom Date") {
    customDates.map(d => {
      let formattedDate = moment(d).startOf('day').valueOf();
      generationDates.push(formattedDate);
    })
  }

  rsf.ref(branchPath + "/feePlan/" + nodeId).update({
    planName: value.name.charAt(0).toUpperCase() + value.name.slice(1),
    generationDate: value.generationDate ? moment(value.generationDate).valueOf() : null,
    generationDates: generationDates.length > 0 ? generationDates : null,
    frequency: value.frequency,
    paymentMode: value.paymentMode,
    id: nodeId,
    inverseDate: -moment().valueOf(),
    postPaidType: value.postPaidType ? value.postPaidType : null,
    programId: value.program ? value.program : null,
    startDate: value.dateRange ? moment(value.dateRange[0]).startOf('day').valueOf() : null,
    endDate: value.dateRange ? moment(value.dateRange[1]).startOf('day').valueOf() : null
  });
}

function updateFeePlan(value, editableFeePlan, firebase, customDates) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let generationDates = [];
  if (editableFeePlan.frequency === "Custom Date") {
    customDates.map(d => {
      let formattedDate = moment(d).startOf('day').valueOf();
      generationDates.push(formattedDate);
    });
  }


  rsf.ref(branchPath + "/feePlan/" + editableFeePlan.id).update({
    planName: value.name.charAt(0).toUpperCase() + value.name.slice(1),
    generationDate: value.generationDate ? moment(value.generationDate).valueOf() : null,
    generationDates: generationDates.length > 0 ? generationDates : null,
    // frequency: value.frequency,
    // paymentMode: value.paymentMode,
    // postPaidType: value.postPaidType ? value.postPaidType : null,
    // programId: value.program ? value.program : null,
    startDate: value.dateRange ? moment(value.dateRange[0]).startOf('day').valueOf() : null,
    endDate: value.dateRange ? moment(value.dateRange[1]).startOf('day').valueOf() : null
  });
}

function createNewFeePlanNode(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/feePlan/").push().key;
  return key;
}

function createNewFeeComponentNode(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/feeComponent/").push().key;
  return key;
}

function addFeeComponent(value, nodeId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/feeComponent/" + nodeId).update({
    name: value.name.charAt(0).toUpperCase() + value.name.slice(1),
    amount: Number(value.amount),
    discount: value.discount !== undefined ? Number(value.discount) : 0,
    discountType: value.discountType !== undefined ? value.discountType : null,
    paymentSchedule: value.paymentSchedule,
    paymentFrequency: value.paymentFrequency,
    isRefundable:
      value.paymentSchedule === "One Time"
        ? value.paymentFrequency
        : "Non Refundable",
    id: nodeId,
    inverseDate: -moment().valueOf()
  });
}

function updateFeeComponent(value, editableFeeComponent, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/feeComponent/" + editableFeeComponent.id).update({
    name: value.name.charAt(0).toUpperCase() + value.name.slice(1),
    amount: Number(value.amount),
    discount: value.discount !== undefined ? Number(value.discount) : 0,
    discountType: value.discountType !== undefined ? value.discountType : null,
    paymentSchedule: value.paymentSchedule,
    paymentFrequency: value.paymentFrequency,
    isRefundable:
      value.paymentSchedule === "One Time"
        ? value.paymentFrequency
        : "Non Refundable"
  });
}

function attachFeeComponentToFeePlan(selectedComponent, recordId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/feePlan/" + recordId).update({
    feeComponent: selectedComponent
  });
}

function getFeeComponentOfSelectedPlan(selectedFeePlan, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let x = [];
  if (selectedFeePlan.feeComponent !== undefined) {
    x = selectedFeePlan.feeComponent;
  } else {
    return;
  }

  return eventChannel(emit => {
    x.forEach(item => {
      rsf
        .ref(branchPath + "/feeComponent")
        .orderByChild("id")
        .equalTo(item)
        .on("value", snap => {
          var y = [];
          snap.forEach(element => {
            if (element.val() !== null) {
              y.push(element.val());
            }
          });
          emit(y);
        });
    });
    return () => {
      rsf.ref(branchPath + "/feeComponent").off();
      console.log("unsubscribe component");
    };
  });
}

function updateNewFeeComponentToFeePlan(selectedFeePlan, nodeId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var x = [];
  if (selectedFeePlan.feeComponent !== undefined) {
    x = selectedFeePlan.feeComponent;
  }
  x.push(nodeId);
  rsf.ref(branchPath + "/feePlan/" + selectedFeePlan.id).update({
    feeComponent: x
  });
}

function getFeePlanById(selectedFeePlan, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel(emit => {
    rsf
      .ref(branchPath + "/feePlan/" + selectedFeePlan.id)
      .on("value", snap => {
        var x = [];
        if (snap.val() !== null) {
          x.push(snap.val());
        }
        emit(x);
      });
    return () => {
      rsf.ref(branchPath + "/feePlan/" + selectedFeePlan.id).off();
    };
  });
}

function getSelectedFeePlan(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel(emit => {
    rsf.ref(branchPath + "/feePlan/" + id).on("value", snap => {
      var x = {};
      if (snap.val() !== null) {
        x = snap.val();
      }
      emit(x);
    });
    return () => {
      rsf.ref(branchPath + "/feePlan/" + id).off();
      console.log("unsubscribe fee plan");
    };
  });
}

function getAssignedFeeComponent(selectedFeePlan, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let allComponent = [];
  let x = [];
  if (selectedFeePlan.feeComponent !== undefined) {
    x = selectedFeePlan.feeComponent;
  }
  if (x.length > 0) {
    var p1 = new Promise(function (resolve, reject) {
      x.forEach(item => {
        rsf
          .ref(branchPath + "/feeComponent")
          .orderByChild("id")
          .equalTo(item)
          .once("value")
          .then(function (snap) {
            snap.forEach(ele => {
              if (ele.val() !== null) {
                allComponent.push(ele.val());
              }
            });
            resolve(allComponent);
          });
      });
    });
    return p1;
  } else {
    return x;
  }
}

function getAssignedFeeComponentById(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let component = {};
  var p1 = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/feeComponent/" + id)
      .once("value")
      .then(function (snap) {
        if (snap.val() !== null) {
          component = snap.val();
        }
        resolve(component);
      });
  });
  return p1;
}

function assignStudentsToFeePlan(
  // value,
  // selectedStudentCheckbox,
  selectedFeePlan,
  firebase
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/feePlan/" + selectedFeePlan.id).update(selectedFeePlan);
}

function deleteStudentFromFeePlan(selectedStudent, feePlan, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var x = feePlan.student;

  let y = x.filter(item => {
    return item.studentId !== selectedStudent.studentId;
  });

  rsf.ref(branchPath + "/feePlan/" + feePlan.id).update({
    student: y
  });
}

function getAllFeeComponent(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var x = [];
  var promise = new Promise(function (resolve, reject) {
    rsf.ref(branchPath + "/feeComponent").once("value", snap => {
      snap.forEach(element => {
        if (element.val() !== null) {
          x.push(element.val());
        }
      });
      resolve(x);
    });
  });
  return promise;
}

function updateStudentFeePlan(studentId, plan, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/studentFeePlan/" + studentId + "/" + plan.id).update(plan);
}

function getStudentFeePlan(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var x = [];
  var promise = new Promise(function (resolve, reject) {
    rsf.ref(branchPath + "/studentFeePlan/" + studentId).once("value", snap => {
      snap.forEach(element => {
        if (element.val() !== null) {
          x.push(element.val());
        }
      });
      resolve(x);
    });
  });
  return promise;
}

function deleteFeePlan(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/feePlan/" + record.id).set(null);
}

function deleteStudentFromStudentFeePlan(studentId, feePlanId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/studentFeePlan/" + studentId + "/" + feePlanId).set(null);
}

function getStudentFeePlanByPlanId(studentId, planId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var x = {};
  var promise = new Promise(function (resolve, reject) {
    rsf.ref(branchPath + "/studentFeePlan/" + studentId + "/" + planId).once("value", snap => {
      if (snap.val() !== null) {
        x = (snap.val());
      }
      resolve(x);
    });
  });
  return promise;
}

function getFeeComponentById(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var x = {};
  var promise = new Promise(function (resolve, reject) {
    rsf.ref(branchPath + "/feeComponent/" + id).once("value", snap => {
      if (snap.val() !== null) {
        x = (snap.val());
      }
      resolve(x);
    });
  });
  return promise;
}

function getFeeAllPlans(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var x = [];
  var promise = new Promise(function (resolve, reject) {
    rsf.ref(branchPath + "/feePlan").once("value", snap => {
      snap.forEach(element => {
        if (element.val() !== null) {
          x.push(element.val());
        }
      });
      resolve(x);
    });
  });
  return promise;
}

function getOneTimeCostRecord(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var x = [];
  var promise = new Promise(function (resolve, reject) {
    rsf.ref(branchPath + "/onetimeCostRecord/" + studentId).once("value", snap => {
      snap.forEach(element => {
        element.forEach(newEle => {
          if (newEle.val() !== null) {
            x.push(newEle.val());
          }
        });
      });
      resolve(x);
    });
  });
  return promise;
}

function getSelectedFeePlanDetailById(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let plan = {};
  var promise = new Promise(function (resolve, reject) {
    rsf.ref(branchPath + "/feePlan/" + id).once("value", snap => {
      if (snap.val() !== null) {
        plan = snap.val();
      }
      resolve(plan);
    })
  });
  return promise;
}



export const FeeApi = {
  createNewFeePlanNode,
  getFeePlan,
  addFeePlan,
  updateFeePlan,
  getFeeComponent,
  createNewFeeComponentNode,
  addFeeComponent,
  updateFeeComponent,
  attachFeeComponentToFeePlan,
  getFeeComponentOfSelectedPlan,
  updateNewFeeComponentToFeePlan,
  getFeePlanById,
  getSelectedFeePlan,
  getAssignedFeeComponent,
  assignStudentsToFeePlan,
  deleteStudentFromFeePlan,
  getAllFeeComponent,
  updateStudentFeePlan,
  getStudentFeePlan,
  deleteFeePlan,
  deleteStudentFromStudentFeePlan,
  getStudentFeePlanByPlanId,
  getFeeComponentById,
  getFeeAllPlans,
  getOneTimeCostRecord,
  getAssignedFeeComponentById,
  getSelectedFeePlanDetailById
};
