import '@firebase/firestore' // 👈 If you're using firestore
import { eventChannel } from "redux-saga";


function createNewTagNode(firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    var key = rsf.ref(branchPath + "/tags").push().key;
    return key;
}

function getAllTags(firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    return eventChannel(emit => {
        rsf.ref(branchPath + "/tags/").on('value', snap => {
            var x = [];
            snap.forEach(element => {
                if (element.val() !== null) {
                    x.push(element.val());
                }
            });
            emit(x);
        })
        return () => {
            rsf.ref(branchPath + "/tags").off();
            console.log("unsubscribe tags");
        }
    })
}

function addNewTag(value, nodeId, firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    rsf.ref(branchPath + "/tags/" + nodeId).set({
        id: nodeId,
        name: value
    })
}

function updateTag(value, record, firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    rsf.ref(branchPath + "/tags/" + record.id).update({
        id: record.id,
        name: value
    })
}

function assignStudentToTag(id, studentIds, firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    rsf.ref(branchPath + "/tags/" + id).update({
        studentIds: studentIds
    })
}

function getTagById(id, firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    let tag = {};
    let p1 = new Promise(function (resolve, reject) {
        rsf.ref(branchPath + "/tags/" + id).once('value').then(function (snapshot) {
            if (snapshot.val() !== null) {
                tag = snapshot.val();
            }
            resolve(tag);
        });
    });
    return p1;
}

function updateTagWithStudents(group, firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    rsf.ref(branchPath + "/tags/" + group.id).update(group);
}

function deleteTag(record, firebase) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    rsf.ref(branchPath + "/tags/" + record.id).set(null);
}

export const TagApi = {
    createNewTagNode,
    getAllTags,
    addNewTag,
    updateTag,
    assignStudentToTag,
    getTagById,
    updateTagWithStudents,
    deleteTag
}